import {
	ARE_CLINIC_AND_LAB_LINKED,
	CLEAR_REDUCER,
	CLEAR_SIGN_UP_USER_EMAIL,
	GET_ALL_RECEIVED_AND_PENDING_INVITATIONS,
	GET_ALL_SENT_USER_INVITATIONS,
	SET_SIGN_UP_USER_EMAIL
} from '../actions/types';
import { EMPTY_STRING } from '../utils/constants';

const initState = {
	receivedAndNotAccepted: [],
	sentToUser: [],
	clinicAndLabLinked: false,
	signUpUserEmail: EMPTY_STRING
};

const invitationReducer = (state = { ...initState }, action) => {
	if (action.type === GET_ALL_RECEIVED_AND_PENDING_INVITATIONS) {
		state.receivedAndNotAccepted = action.payload;
		return { ...state };
	}

	if (action.type === GET_ALL_SENT_USER_INVITATIONS) {
		state.sentToUser = action.payload;
		return { ...state };
	}

	if (action.type === ARE_CLINIC_AND_LAB_LINKED) {
		state.clinicAndLabLinked = action.payload;
		return { ...state };
	}

	if (action.type === SET_SIGN_UP_USER_EMAIL) {
		state.signUpUserEmail = action.payload;
		return { ...state };
	}

	if (action.type === CLEAR_SIGN_UP_USER_EMAIL) {
		state.signUpUserEmail = EMPTY_STRING;
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default invitationReducer;
