import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { radioInput } from '../../../common/Fields';
import i18n from '../../../../i18n';
import DefaultButton from '../../../common/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { EXPORT_PDF_FIELDS } from '../../../../utils/constants';
import { exportSingleDocumentToPdf, loadLanguage } from '../../../../actions';

class ExportPdfModal extends React.Component {
	state = {
		showSelectErrorMessage: false,
		showServerErrorMessage: false,
		serverErrorMessage: ''
	};

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { isModalOpen, formValues } = this.props;

		const isModalReopened = prevProps.isModalOpen !== isModalOpen && isModalOpen;
		const isFormValuesChanged = prevProps.formValues !== formValues;

		if (isModalReopened || isFormValuesChanged) {
			this.clearErrorMessage();
		}
	}

	render() {
		const exportButton = (
			<DefaultButton
				title={i18n.t('translation:billingModule.common.buttons.export')}
				type="submit"
				key={i18n.t('translation:billingModule.common.buttons.export')}
				onClick={this.onFormSubmit}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				type="button"
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const modalTitle = i18n.t('translation:billingModule.common.setDetailsModalTitle');

		return (
			<Modal
				className="custom-modal"
				overlayClassName="custom-overlay"
				isOpen={this.props.isModalOpen}>
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				<form className="mb-4" onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{this.renderInputs(this.props.type, this.props.language)}
				</form>
				{this.state.showSelectErrorMessage && (
					<div className="modal-error-message text-center">
						{i18n.t('translation:billingModule.documents.errors.selectExportOptions')}
					</div>
				)}
				{this.state.showServerErrorMessage && (
					<div className="modal-error-message text-center">
						{this.state.serverErrorMessage}
					</div>
				)}
				<div className="buttons">
					{exportButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	renderInputs = (type, language) => {
		return (
			<div className="d-flex mt-4">
				<div className="d-flex justify-content-center pdf-modal-input">
					{this.renderTypeInput(type)}
				</div>
				<div className="d-flex justify-content-center pdf-modal-input">
					{this.renderLanguageInput(language)}
				</div>
			</div>
		);
	};

	renderTypeInput = () => {
		return (
			<Field
				name={EXPORT_PDF_FIELDS.TYPE}
				customProps={{
					className: ' ',
					blueLabel: true,
					radioButtons: this.getTypeInputOptions()
				}}
				label={i18n.t('translation:billingModule.documents.exportPdfModal.documentType')}
				component={radioInput}
			/>
		);
	};

	getTypeInputOptions = () => {
		return [
			{
				name: i18n.t('translation:billingModule.common.exportDocumentModalOriginal'),
				value: 'ORIGINAL'
			},
			{
				name: i18n.t('translation:billingModule.common.exportDocumentModalCopy'),
				value: 'COPY'
			}
		];
	};

	renderLanguageInput = () => {
		return (
			<Field
				name={EXPORT_PDF_FIELDS.LANGUAGE}
				customProps={{
					className: ' ',
					blueLabel: true,
					radioButtons: this.getLanguageInputOptions()
				}}
				label={i18n.t('translation:billingModule.documents.exportPdfModal.language')}
				component={radioInput}
			/>
		);
	};

	getLanguageInputOptions = () => {
		return [
			{
				name: i18n.t('translation:billingModule.documents.exportPdfModal.languages.BG'),
				value: 'BG'
			},
			{
				name: i18n.t('translation:billingModule.documents.exportPdfModal.languages.EN'),
				value: 'EN'
			},
			{
				name: i18n.t('translation:billingModule.documents.exportPdfModal.languages.DE'),
				value: 'DE'
			},
			{
				name: i18n.t('translation:billingModule.documents.exportPdfModal.languages.TR'),
				value: 'TR'
			}
		];
	};

	onFormSubmit = () => {
		const { account, formValues, document } = this.props;

		if (
			!formValues ||
			!formValues[EXPORT_PDF_FIELDS.LANGUAGE] ||
			!formValues[EXPORT_PDF_FIELDS.LANGUAGE].trim().length ||
			!formValues[EXPORT_PDF_FIELDS.TYPE]
		) {
			this.setShowSelectErrorMessage();
			return;
		}

		const { type, language } = formValues;

		const exportPdfDocumentDto = {
			languageCode: language,
			pdfDocumentType: type
		};

		const { labId, clinicId } = account;

		if (labId) {
			exportPdfDocumentDto.labId = labId;
		} else {
			exportPdfDocumentDto.clinicId = clinicId;
		}

		this.props.exportSingleDocumentToPdf(
			exportPdfDocumentDto,
			this.closeModal,
			this.setServerErrorMessage,
			document
		);
	};

	closeModal = () => {
		this.props.destroy();
		this.props.closeModal();
	};

	setShowSelectErrorMessage = () => {
		this.setState({ showSelectErrorMessage: true });
	};

	setServerErrorMessage = (serverErrorMessage) => {
		this.setState({
			showServerErrorMessage: true,
			serverErrorMessage: serverErrorMessage
		});
	};

	clearErrorMessage = () => {
		this.setState({
			showSelectErrorMessage: false,
			showServerErrorMessage: false,
			serverErrorMessage: ''
		});
	};
}

const getInitialValues = (state, props) => {
	const documentLanguage = props.document?.language || loadLanguage();
	return {
		[EXPORT_PDF_FIELDS.LANGUAGE]: documentLanguage
	};
};

const exportPdfForm = reduxForm({
	form: 'exportPdfForm',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true
})(ExportPdfModal);

const mapStateToProps = (state, props) => {
	return {
		account: state.account,
		formValues: getFormValues('exportPdfForm')(state),
		initialValues: getInitialValues(state, props)
	};
};

export default connect(mapStateToProps, { exportSingleDocumentToPdf })(exportPdfForm);
