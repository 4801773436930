import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import bg from './resources/translations/bg.json';
import de from './resources/translations/de.json';
import en from './resources/translations/en.json';
import tr from './resources/translations/tr.json';
import { loadLanguage } from './actions';
import { parseFlagToLanguage } from './utils/appUtils';

const i18n = i18next.createInstance();
i18n.use(initReactI18next);

i18n.init({
	lng: 'en',
	fallbackLng: parseFlagToLanguage(loadLanguage()),
	ns: ['translation'],
	defaultNS: 'translation',
	debug: false,
	resources: {
		bg: bg,
		de: de,
		en: en,
		tr: tr
	},
	react: {
		wait: true
	},
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
