import React from 'react';
import { SLIDER_DEFAULT_VALUES } from '../../utils/constants';

class Slider extends React.Component {
	render() {
		return (
			<div className={this.props.className ? this.props.className : 'input-container'}>
				<input
					type={this.props.type ? this.props.type : 'range'}
					onChange={this.props.onChange}
					min={this.props.min ? this.props.min : SLIDER_DEFAULT_VALUES.MIN}
					max={this.props.max ? this.props.max : SLIDER_DEFAULT_VALUES.MAX}
					step={this.props.step ? this.props.step : SLIDER_DEFAULT_VALUES.STEP}
					defaultValue={
						this.props.value ? this.props.value : SLIDER_DEFAULT_VALUES.START_VALUE
					}
					className="slider"
				/>
			</div>
		);
	}
}

export default Slider;
