import { getAxiosWithToken } from '../../utils/webApi';
import { GET_HOLIDAYS } from '../types';
import { showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';

export const getHolidays = (labId, year) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/lab/${labId}/public-holiday`, {
		params: {
			year
		}
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_HOLIDAYS,
			payload: returnedData.data
		});
	}
};

export const setHolidays = (labId, data, year, closeModal) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().post(`/lab/${labId}/public-holiday`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.planner.successfullySavedHoliday'));
		dispatch(getHolidays(labId, year));
	}
};

export const editHolidays = (labId, data, year, closeModal, holidayId) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().put(
		`/lab/${labId}/public-holiday/${holidayId}`,
		data
	);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.planner.successfullySavedHoliday'));
		dispatch(getHolidays(labId, year));
	}
};

export const removeHolidays = (labId, year, closeModal, holidayId) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().delete(`/lab/${labId}/public-holiday/${holidayId}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.planner.successfullyDeletedHoliday'));
		dispatch(getHolidays(labId, year));
	}
};
