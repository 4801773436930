import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../common/Fields';
import DefaultButton from '../common/DefaultButton';
import i18n from '../../i18n';
import { isNew, isPracticeRoute } from '../../utils/appUtils';
import navigationUtils from '../../utils/navigationUtils';
import { addLink, getClinicsLegalNames, getLabsLegalNames } from '../../actions';
import _ from 'lodash';

class AddRelationsModal extends React.Component {
	state = {
		suggestions: [],
		refinedSuggestions: []
	};

	componentDidMount() {
		Modal.setAppElement('#root');
		if (!isNew(navigationUtils.getRoute())) {
			if (isPracticeRoute(navigationUtils.getRoute())) {
				getLabsLegalNames().then((data) => {
					this.setState({ suggestions: data });
				});
			} else {
				getClinicsLegalNames().then((data) => {
					this.setState({ suggestions: data });
				});
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			this.props.initialize({ fields: '' });
		}
	}

	render() {
		const filedName = isPracticeRoute(navigationUtils.getRoute()) ? 'labs' : 'clinics';
		const currentLink = this.props.formValues ? this.props.formValues[filedName] : null;
		const linkId = _.find(this.state.suggestions, function (suggestion) {
			return suggestion.legalName === currentLink;
		});

		const addButton = linkId ? (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				type="submit"
				key={i18n.t('translation:common.buttons.add')}
				onClick={this.props.handleSubmit(this.onFormSubmit)}
			/>
		) : (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				type="submit"
				key={i18n.t('translation:common.buttons.add')}
				disabled
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				type="submit"
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.props.destroy();
					this.props.closeModal();
				}}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.props.destroy();
						this.props.closeModal();
					}}
				/>
				<h2>{this.getTitle()}</h2>
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{this.renderField()}
				</form>
				<div className="buttons">
					{addButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	getTitle = () => {
		if (isPracticeRoute(navigationUtils.getRoute())) {
			return i18n.t('translation:clinics.addLab');
		} else {
			return i18n.t('translation:labs.addClinic');
		}
	};

	renderField = () => {
		if (isPracticeRoute(navigationUtils.getRoute())) {
			return Fields.commonFields.labs(
				this.onSuggestionsFetchRequested,
				this.onSuggestionsClearRequested,
				this.state.refinedSuggestions
			);
		} else {
			return Fields.commonFields.clinics(
				this.onSuggestionsFetchRequested,
				this.onSuggestionsClearRequested,
				this.state.refinedSuggestions
			);
		}
	};

	getSuggestions = (value) => {
		const languages = this.state.suggestions;

		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength < 2
			? []
			: languages.filter((lang) => lang.legalName.toLowerCase().includes(inputValue));
	};

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			refinedSuggestions: this.getSuggestions(value)
		});
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			refinedSuggestions: []
		});
	};

	onFormSubmit = (formValues) => {
		const route = navigationUtils.getRoute();
		const companyId = route.split('/').pop();
		const filedName = isPracticeRoute(route) ? 'labs' : 'clinics';
		const linkId = _.find(this.state.suggestions, function (suggestion) {
			return suggestion.legalName === formValues[filedName];
		}).id;
		if (isPracticeRoute(route)) {
			this.props.addLink(companyId, linkId);
		} else {
			this.props.addLink(linkId, companyId);
		}
		this.props.closeModal();
	};
}

const addRelationForm = reduxForm({ form: 'addRelationForm' })(AddRelationsModal);

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('addRelationForm')(state)
	};
};

export default connect(mapStateToProps, { addLink })(addRelationForm);
