import moment from '../../utils/moment';
import { getAxiosWithToken } from '../../utils/webApi';
import {
	GET_CURRENT_CREDIT_DOCUMENTS,
	GET_CURRENT_DEBIT_DOCUMENTS,
	SEARCH_TRANSACTIONS
} from '../types';
import { DOCUMENT_EXPORT_FILE_EXTENSION, DOCUMENT_EXPORT_TYPE } from '../../utils/constants';
import { createCsvFileUrl, createExcelFileUrl, getFileName } from '../common';
import { showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';

export const searchTransactions =
	({ page, filters, labId, type }) =>
	async (dispatch) => {
		page = page - 1;

		const filterData = Object.assign({}, filters);

		if (type) {
			filterData.type = type;
		}

		if (
			filterData.connectedToInvoicesOrDebitNotes &&
			filterData.connectedToInvoicesOrDebitNotes.length
		) {
			filterData.connectedToInvoicesOrDebitNotes =
				filterData.connectedToInvoicesOrDebitNotes[0];
		}

		filterData.labId = labId;

		if (filterData.transactionDateTo) {
			filterData.transactionDateTo = moment(filterData.transactionDateTo).format(
				'YYYY-MM-DD'
			);
		}
		if (filterData.transactionDateFrom) {
			filterData.transactionDateFrom = moment(filterData.transactionDateFrom).format(
				'YYYY-MM-DD'
			);
		}
		if (filterData.statuses && filterData.statuses.length === 0) {
			filterData.statuses = undefined;
		}

		const response = await getAxiosWithToken().post('/billing/transaction/search', filterData, {
			params: {
				page: page
			}
		});

		if (response.data.success) {
			dispatch({
				type: SEARCH_TRANSACTIONS,
				payload: response.data.data
			});
		}
	};

export const createTransaction = async (transaction) => {
	const response = await getAxiosWithToken().post('/billing/transaction', transaction);
};

export const setCurrentDebitDocuments = (documents) => async (dispatch) => {
	dispatch({
		type: GET_CURRENT_DEBIT_DOCUMENTS,
		payload: documents
	});
};

export const setCurrentCreditDocuments = (documents) => async (dispatch) => {
	dispatch({
		type: GET_CURRENT_CREDIT_DOCUMENTS,
		payload: documents
	});
};

export const getTransaction = async (id) => {
	const response = await getAxiosWithToken().get(`/billing/transaction/${id}`);

	if (response.data.success) {
		return response.data.data;
	}
};

export const updateTransaction = async ({
	id,
	type,
	billingDocuments,
	transactionDate,
	transactionMethod,
	amount,
	clinicId,
	referenceNotes,
	labId
}) => {
	const response = await getAxiosWithToken().put(`/billing/transaction/${id}`, {
		type,
		billingDocuments,
		transactionDate,
		transactionMethod,
		amount,
		clinicId,
		referenceNotes,
		labId
	});
};

export const searchAllTransactions = async (filters) => {
	const response = await getAxiosWithToken().post(`/billing/transaction/search-all`, filters);
	if (response.data.success) {
		return response.data.data;
	}
};

export const cancelTransaction = async (id) => {
	const response = await getAxiosWithToken().post(`/billing/transaction/${id}/cancel`);
};

export const exportTransactionsToExcelOrCsv =
	(exportTransactionsDto) => async (dispatch, getState) => {
		let exportFileType;
		let isExcelFormat;

		if (exportTransactionsDto.type === DOCUMENT_EXPORT_TYPE.EXCEL) {
			exportFileType = DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL;
			isExcelFormat = true;
		} else {
			exportFileType = DOCUMENT_EXPORT_FILE_EXTENSION.CSV;
			isExcelFormat = false;
		}

		const response = await getAxiosWithToken(false).post(
			'/billing/transaction/export',
			exportTransactionsDto,
			{ responseType: 'arraybuffer' }
		);

		const link = document.createElement('a');

		if (isExcelFormat) {
			link.href = createExcelFileUrl(response.data);
		} else {
			link.href = createCsvFileUrl(response.data);
		}

		link.setAttribute(
			'download',
			getFileName(getState().account, 'transactions', exportFileType)
		);

		document.body.appendChild(link);
		link.click();

		if (isExcelFormat) {
			showSuccessPopup(
				i18n.t('translation:billingModule.documents.successMessages.generatedExcel')
			);
		} else {
			showSuccessPopup(
				i18n.t('translation:billingModule.documents.successMessages.generatedCsv')
			);
		}
	};
