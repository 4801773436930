import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import i18n from '../../../i18n';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from '../../common/DefaultButton';
import { Fields } from '../../common/Fields';
import { formUtils } from '../../../utils/formUtils';
import { getFileExtension } from '../../../utils/fileUtils';
import { getAllSupportedFileExtensions, getSupportedPhotoExtensions } from '../../../actions';

class UploadFileModal extends React.Component {
	state = {
		file: null,
		fileName: '',
		fileError: ''
	};

	componentDidMount() {
		this.props.getSupportedPhotoExtensions();
		this.props.getAllSupportedFileExtensions();
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isOpen !== this.props.isOpen) {
			const initialValues = { description: '' };
			this.props.initialize(initialValues);
		}
	}

	render = () => {
		return (
			<Modal
				isOpen={this.props.isOpen}
				className="custom-modal photo-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>

				<h2 className="mb-xs">{i18n.t('translation:orders.addFile')}</h2>

				<form onSubmit={this.onFormSubmit}>
					{this.renderFileCell()}
					{Fields.orderFields.description()}
					{this.renderImageError()}
					<div className="buttons modal-dialog-buttons-below-error-message">
						<DefaultButton
							title={i18n.t('translation:common.buttons.save')}
							onPress={this.onFormSubmit}
						/>
						<DefaultButton
							title={i18n.t('translation:common.buttons.close')}
							onClick={() => {
								this.closeModal();
							}}
							secondary
						/>
					</div>
				</form>
			</Modal>
		);
	};

	renderImageError = () => {
		if (this.state.fileError) {
			return (
				<div className="align-items-start text-danger position-absolute modal-dialog-error-message">
					{this.state.fileError}
				</div>
			);
		}
	};

	renderFileCell() {
		const { file } = this.state;

		if (file !== undefined && file !== null) {
			return this.renderFileContents();
		} else {
			return this.renderFilePlaceholder();
		}
	}

	renderFileContents() {
		const { file } = this.state;
		const backgroundImage = URL.createObjectURL(file);

		return this.isImage(file) ? (
			<div className="photo photo-rectangle photo-no-border">
				<div
					style={{
						backgroundImage: `url(${backgroundImage})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat'
					}}></div>
			</div>
		) : (
			<div className="nonPhotoFileRegion">
				<div>
					<FontAwesomeIcon icon={faFile} size="7x" className="file-icon" />
				</div>
				<span>{this.state.fileName}</span>
			</div>
		);
	}

	isImage = (file) => {
		var fileName = file.name.toLowerCase();
		var extensions = this.props.photoFileExtensions;
		for (var index = 0; index < extensions.length; index++) {
			if (fileName.endsWith(extensions[index].toLowerCase())) {
				return true;
			}
		}

		return false;
	};

	renderFilePlaceholder() {
		return (
			<div className="photo photo-rectangle">
				<div>
					<input
						id="imageChooser"
						className="photo-file-input"
						type="file"
						name="imageChooser"
						onChange={(event) => {
							this.setFile(event);
						}}
					/>
					<label htmlFor="imageChooser" className="photo-label">
						<div className="photo-icon-container">
							<FontAwesomeIcon icon={faFile} size="3x" className="file-icon" />
							{i18n.t('translation:orders.selectFile')}
						</div>
					</label>
				</div>
			</div>
		);
	}

	setFile(event) {
		event.preventDefault();
		const file = event.target.files[0];
		if (file !== undefined) {
			if (this.isSupportedFile(file)) {
				this.setState({ file, fileName: file.name, fileError: '' });
			} else {
				const fileListText = this.props.allFileExtensions.join(', ');
				const errorMessage =
					i18n.t('translation:orders.errors.notSupportedFile') + fileListText + '.';

				this.setState({ fileError: errorMessage });
			}
		}
	}

	isSupportedFile(file) {
		return this.props.allFileExtensions.includes(getFileExtension(file.name).toLowerCase());
	}

	onFormSubmit = (e) => {
		e.preventDefault();
		const { file } = this.state;
		let fileError = false;
		if (!file) {
			this.setState({ fileError: i18n.t('translation:orders.errors.noImageError') });
			fileError = true;
		}
		this.props.handleSubmit((formValues) => {
			if (fileError || this.state.fileError) {
				return;
			}
			const description = formValues['description'];
			this.props.onUpload(file, description);
			this.closeModal();
		})();
	};

	closeModal = () => {
		this.setState({ file: null, fileError: '' });
		this.props.onClose();
		this.props.destroy();
	};
}

const uploadFileForm = reduxForm({
	form: 'uploadFileForm',
	validate: formUtils.validateAddOrEditOrderPhoto
})(UploadFileModal);

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		photoFileExtensions: state.orders.photoFileExtensions,
		allFileExtensions: state.orders.allFileExtensions
	};
};

export default connect(mapStateToProps, {
	getSupportedPhotoExtensions,
	getAllSupportedFileExtensions
})(uploadFileForm);
