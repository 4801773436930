import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../errors/NotFound';
import InternalServerError from '../errors/InternalServerError';
import UnhandledError from '../errors/UnhandledError';
import { PAGES_PATHS } from '../../utils/constants';
import UserDetails from '../pages/users/UserDetails';
import DashboardUser from '../pages/dashboards/DashboardUser';
import LabInventory from '../pages/inventory/LabInventory';
import OrderDetails from '../pages/orders/OrderDetails';
import Orders from '../pages/orders/Orders';
import WorkSchedule from '../pages/taskManager/planner/workSchedule/WorkSchedule';
import { isTaskManagerEnabled } from '../../utils/appUtils';
import DentalTechnicianCalendarDashboard from '../pages/taskManager/calendar/dashboard/DentalTechnicianCalendarDashboard';
import { connect } from 'react-redux';

class DentalTechnicianRoutes extends Component {
	render = () => (
		<Fragment>
			<Switch>
				<Route
					path={PAGES_PATHS.HOME}
					exact
					component={
						isTaskManagerEnabled(this.props.modules)
							? DentalTechnicianCalendarDashboard
							: DashboardUser
					}
				/>
				<Route path={PAGES_PATHS.MY_LABORATORY} exact component={DashboardUser} />
				<Route path={PAGES_PATHS.MY_ACCOUNT} exact component={UserDetails} />
				<Route path={PAGES_PATHS.USER_DETAILS} exact component={UserDetails} />
				<Route path={PAGES_PATHS.INVENTORY} exact component={LabInventory} />
				<Route path={PAGES_PATHS.ORDERS} exact component={Orders} />
				<Route path={PAGES_PATHS.ORDERS_DETAILS} exact component={OrderDetails} />
				<Route path={PAGES_PATHS.PLANNER} exact component={WorkSchedule} />
				<Route path={PAGES_PATHS.PLANNER_WORK_SCHEDULE} exact component={WorkSchedule} />
				<Route path="/404" component={NotFound} />
				<Route path="/500" exact component={InternalServerError} />
				<Route path="/unhandledError" exact component={UnhandledError} />
				<Route component={NotFound} />
			</Switch>
		</Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		modules: state.modules
	};
};

export default connect(mapStateToProps)(DentalTechnicianRoutes);
