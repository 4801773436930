import React, { Component } from 'react';
import SideMenuDropdownButton from './SideMenuDropdownButton';
import SideMenuDropdownItem from './SideMenuDropdownItem';
import navigationUtils from '../../../utils/navigationUtils';

class SideMenuDropdown extends Component {
	state = {
		open: false
	};

	render() {
		return (
			<div className={`side-bar-menu-dropdown ${this.getActiveClass(this.props.path)}`}>
				<SideMenuDropdownButton
					onClick={this.onClick}
					icon={this.props.icon}
					name={this.props.name}
				/>
				{this.state.open ? (
					<ul>
						{this.props.menuItems.map((menuItem) => (
							<SideMenuDropdownItem
								key={menuItem.name}
								item={menuItem}
								onClick={this.props.onClick}
							/>
						))}
					</ul>
				) : null}
			</div>
		);
	}

	onClick = () => {
		this.setState({ open: !this.state.open });
	};

	getActiveClass = (path) => {
		return path.split('/')[1] === navigationUtils.getRoute().split('/')[1] ? 'active' : '';
	};
}

export default SideMenuDropdown;
