import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { getFormSyncErrors, getFormValues, reset, SubmissionError, submit } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import DefaultButton from '../../../common/DefaultButton';
import {
	createLocalTemplates,
	getGlobalProductPhasesTemplates,
	updateGlobalProductPhasesTemplates,
	updateLocalTemplates
} from '../../../../actions';
import {
	addZeroIfOneDigit,
	calculatePhasesTotalTime,
	isEmptyObject,
	isRegisteredFieldValue
} from '../../../../utils/appUtils';
import Table from '../../../common/Table';
import { parseLocalPhases } from '../../../../utils/tableUtils';
import _ from 'lodash';
import LocalInventoryAddPhaseForm from './LocalInventoryAddPhaseForm';
import { commonValidation } from '../../../../utils/formUtils';
import { MAX_TEXT_FIELD_LENGTH } from '../../../../utils/constants';
import LocalInventoryEditTemplateForm from './LocalInventoryEditTemplateForm';

class LocalInventoryPhasesModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phaseCounter: 1,
			phases: [],
			serverError: ''
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const formValues = this.props.tableFormValues;

		const totalTime = calculatePhasesTotalTime(formValues, this.state.phases);
		if (this.state.totalTime !== totalTime) {
			this.setState({ totalTime });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const phases = nextProps.template.phases;

			if (this.state.phases.length === 0 && phases && phases.length > 0) {
				const refinedPhases = [];
				for (let phase of phases) {
					const currentPhase = {};
					const index = phase.id;
					for (let i in phase) {
						if (i === 'name') {
							currentPhase['name_' + index] = phase[i];
							if (phase['duration']) {
								currentPhase['minutes_' + index] = phase['duration'].split(':')[1];
								currentPhase['hours_' + index] = phase['duration'].split(':')[0];
							}
							currentPhase.id = index;
							refinedPhases.push(currentPhase);
						}
					}
				}
				this.setState({ phases: refinedPhases });
			}
		}
	}

	render() {
		const editButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				disabled={this.props.productLoader}
				onClick={() => {
					this.props.submit('tableForm');
				}}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const modalTitle = i18n.t('translation:taskManager.common.template');

		if (isEmptyObject(this.props.item)) {
			return <Fragment />;
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal teeth-map-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				<LocalInventoryEditTemplateForm initialValues={this.props.template} />
				<LocalInventoryAddPhaseForm onSubmit={this.onAddPhase} />
				<label className="blue-label mt-s">
					{i18n.t('translation:taskManager.inventory.phases')}
				</label>
				{this.renderPhases()}
				<span className="align-items-start text-danger">{this.state.serverError}</span>
				<div className="buttons">
					{editButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	onDeletePhase = (id) => {
		let phases = this.state.phases;
		_.remove(phases, { id });
		this.setState({ phases });
	};

	renderPhases = () => {
		const columns = [
			i18n.t('translation:common.name'),
			i18n.t('translation:taskManager.inventory.fields.time'),
			''
		];
		const data = parseLocalPhases(this.state.phases, this.onDeletePhase);
		const phases = this.state.phases;
		let initialValues = {};

		for (let phase of phases) {
			for (let key in phase) {
				const id = phase.id;

				if (key.length > 2) {
					if (id.toString().endsWith('c')) {
						initialValues[key.replace(0, id)] = phase[key];
					} else {
						initialValues[key] = phase[key];
					}
				}
			}
		}

		return (
			<Table
				columns={columns}
				data={data}
				onSubmit={this.onTableFormSubmit}
				className={'table-languages'}
				initialValues={{ ...initialValues, ...this.props.tableFormValues }}
				totalTime={this.state.totalTime}
				totalTimeColumn={1}
			/>
		);
	};

	onAddPhase = () => {
		const formValues = this.props.formValues;
		this.setState({
			phases: [...this.state.phases, { ...formValues, id: this.state.phaseCounter + 'c' }],
			phaseCounter: this.state.phaseCounter + 1,
			serverError: ''
		});
		this.props.reset('localInventoryPhasesForm');
	};

	setServerError = (serverError) => {
		this.setState({ serverError });
	};

	onTableFormSubmit = (formValues) => {
		let errors = {};
		let lastIndexErrors = '';
		for (let value in formValues) {
			let index = value.split('_');
			index = index[index.length - 1];
			if (lastIndexErrors !== index) {
				lastIndexErrors = index;
				if (isRegisteredFieldValue(this.state.phases, index)) {
					commonValidation(formValues, errors, 'name_' + index)
						.required()
						.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
						.validate();
				}
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		this.props.submit('localInventoryEditTemplateForm');

		// Submit form after validations
		const tableFormValues = this.props.tableFormValues;
		const phases = [];
		let lastIndex = '';
		for (let i in tableFormValues) {
			const currentPhase = {};
			if (i.startsWith('name_')) {
				lastIndex = i.split('_')[1];
				if (isRegisteredFieldValue(this.state.phases, lastIndex)) {
					currentPhase.name = tableFormValues[i];
					const hours = tableFormValues['hours_' + lastIndex];
					const minutes = tableFormValues['minutes_' + lastIndex];
					if (hours || minutes) {
						currentPhase.duration =
							hours && minutes
								? `${hours}:${addZeroIfOneDigit(minutes)}`
								: !minutes
								? `${hours}:00`
								: `00:${addZeroIfOneDigit(minutes)}`;
					}
					if (!lastIndex.endsWith('c')) {
						currentPhase.id = lastIndex;
					}
					phases.push(currentPhase);
				}
			}
		}

		if (!phases.length) {
			this.setServerError(i18n.t('translation:taskManager.inventory.noPhasesError'));
			return;
		}

		const templateFormErrors = this.props.templateFormErrors;

		if (isEmptyObject(templateFormErrors)) {
			const data = { ...this.props.templateFormValues, phases };

			delete data.totalDuration;
			delete data.isGlobalTemplate;

			if (!isEmptyObject(this.props.template)) {
				this.props.updateLocalTemplates(
					this.props.template.id,
					data,
					this.props.account.labId,
					this.props.item.id,
					this.setServerError,
					this.closeModal
				);
			} else {
				this.props.createLocalTemplates(
					this.props.account.labId,
					this.props.item.id,
					data,
					this.setServerError,
					this.closeModal
				);
			}
		}
	};

	closeModal = () => {
		this.props.closeModal();
		this.setState({ phases: [], serverError: '' });
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('localInventoryPhasesForm')(state) || {},
		tableFormValues: getFormValues('tableForm')(state) || {},
		templateFormValues: getFormValues('localInventoryEditTemplateForm')(state) || {},
		templateFormErrors: getFormSyncErrors('localInventoryEditTemplateForm')(state)
	};
};

export default connect(mapStateToProps, {
	getGlobalProductPhasesTemplates,
	updateGlobalProductPhasesTemplates,
	createLocalTemplates,
	updateLocalTemplates,
	submit,
	reset
})(LocalInventoryPhasesModal);
