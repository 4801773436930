import { GET_STATISTICS } from '../actions/types';

const initState = {};

const statisticsReducer = (state = { ...initState }, action) => {
	if (action.type === GET_STATISTICS) {
		state = action.payload;
		return { ...state };
	}

	return state;
};

export default statisticsReducer;
