import React, { Fragment } from 'react';
import SubHeader from '../../../common/SubHeader';
import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import {
	isGlobalAdmin,
	isLocalAdmin,
	isMyCompany,
	isTaskManagerEnabled
} from '../../../../utils/appUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faUsers } from '@fortawesome/free-solid-svg-icons';
import { getLabDetails, getLabModules, loadAccountLabId } from '../../../../actions';
import navigationUtils from '../../../../utils/navigationUtils';
import { PAGES_PATHS } from '../../../../utils/constants';

class CalendarSection extends React.Component {
	componentDidMount() {
		this.props.getLabModules(this.props.account.labId);
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <Fragment />;
		}

		if (
			!(isLocalAdmin(this.props.account) || isGlobalAdmin(this.props.account)) ||
			(!isGlobalAdmin(this.props.account) && !isMyCompany())
		) {
			return <Fragment />;
		}

		return (
			<div className="col-6">
				<SubHeader title={i18n.t('translation:taskManager.labs.calendar')} />
				<div className="col-12 data-section mt-s p-0">
					<div className="col-12">
						<div className="content row calendar">
							<div className="col-6 p-0" onClick={() => this.navigateTo('orders')}>
								<FontAwesomeIcon
									className="clickable-icon"
									icon={faList}
									size="lg"
								/>
								<span>{i18n.t('translation:taskManager.labs.orders')}</span>
							</div>
							<div className="col-6 p-0" onClick={() => this.navigateTo('staff')}>
								<FontAwesomeIcon
									className="clickable-icon"
									icon={faUsers}
									size="lg"
								/>
								<span>{i18n.t('translation:taskManager.labs.staff')}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	navigateTo = (place) => {
		const id = this.props.company.id;
		this.props.getLabDetails(id);
		this.props.loadAccountLabId(id);

		if (place == 'orders') {
			navigationUtils.navigate(PAGES_PATHS.CALENDAR_ORDERS, null, null, { id: id });
		}

		if (place == 'staff') {
			navigationUtils.navigate(PAGES_PATHS.CALENDAR_STAFF, null, null, { id: id });
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		modules: state.modules
	};
};

export default connect(mapStateToProps, { getLabModules, getLabDetails, loadAccountLabId })(
	CalendarSection
);
