import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { getFormValues, reduxForm, stopSubmit, SubmissionError, touch } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import { formUtils } from '../../../utils/formUtils';
import DataRow from '../../common/DataRow';
import moment from '../../../utils/moment';
import {
	createItem,
	getColorCodeEquivalent,
	getColors,
	getLabInventoryOnly,
	getOrderDetails,
	showMessageModal,
	updateItem
} from '../../../actions';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	EMPTY_STRING,
	GLOBAL_PRODUCT_CATEGORY,
	LOADER_TYPES,
	ORDER_FIELD_STATUSES,
	ORDER_ITEM_FIELDS
} from '../../../utils/constants';
import _ from 'lodash';
import { isEmptyObject, isLabAdmin, parseColorSchemeToConstant } from '../../../utils/appUtils';
import { preparePriceForDisplay, renderOrderItemCategoryIcon } from '../../../utils/tableUtils';
import TeethMap from './TeethMap';

class OrderItemModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			teeth: [],
			isTeethError: false,
			teethErrorMessage: '',
			colorCodeEquivalent: this.props.formValues.colorCode,
			shouldGetPropsFromInitValues: false,
			productChanged: false
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');
		this.props.getColors();
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const initialValues = nextProps.initialValues ? nextProps.initialValues : {};

			if (initialValues.metalTestDate) {
				initialValues.metalTestDate = this.parseDate(initialValues.metalTestDate);
			}
			if (initialValues.ceramicTestDate) {
				initialValues.ceramicTestDate = this.parseDate(initialValues.ceramicTestDate);
			}
			if (initialValues.individualSpoonTestDate) {
				initialValues.individualSpoonTestDate = this.parseDate(
					initialValues.individualSpoonTestDate
				);
			}
			if (initialValues.biteTestDate) {
				initialValues.biteTestDate = this.parseDate(initialValues.biteTestDate);
			}
			if (initialValues.arrangedTeethTestDate) {
				initialValues.arrangedTeethTestDate = this.parseDate(
					initialValues.arrangedTeethTestDate
				);
			}

			this.props.initialize(initialValues);
			if (!isEmptyObject(initialValues)) {
				this.setState({ shouldGetPropsFromInitValues: true, productChanged: false });
			} else {
				this.setState({ shouldGetPropsFromInitValues: false, productChanged: false });
			}

			if (
				this.state.teeth.length === 0 &&
				initialValues.teeth &&
				initialValues.teeth.length > 0
			) {
				const teeth = [];
				initialValues.teeth.forEach((tooth) =>
					teeth.push({ fdiPosition: tooth.fdiPosition, type: tooth.type })
				);
				this.setState({ teeth });
			}
		}
	}

	parseDate = (date) => {
		return moment(date, DEFAULT_BACKEND_DATE_FORMAT).toDate();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.formValues.isGroup !== prevProps.formValues.isGroup &&
			!this.props.formValues.isGroup &&
			this.state.teeth &&
			this.state.teeth.length > 1
		) {
			this.setState({ teeth: [], isTeethError: false });
		}
	}

	render() {
		const addButton = this.isNewProduct() ? (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				type="submit"
				key={i18n.t('translation:common.buttons.add')}
				disabled={this.props.productLoader}
				onClick={this.onFormSubmit}
			/>
		) : null;

		const editButton =
			!this.isNewProduct() && this.props.currentOrder.canUpdateOrderItems ? (
				<DefaultButton
					title={i18n.t('translation:common.buttons.edit')}
					type="submit"
					key={i18n.t('translation:common.buttons.edit')}
					disabled={this.props.productLoader}
					onClick={this.onFormSubmit}
				/>
			) : null;

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.closeModal();
				}}
				secondary={this.props.currentOrder.canUpdateOrderItems}
			/>
		);

		const modalTitle = this.isNewProduct()
			? i18n.t('translation:orders.addProduct')
			: i18n.t('translation:orders.product');

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal teeth-map-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{this.renderFirstRow()}
					{this.renderAdditionalColorSpecification()}
					<div className="mt-s">
						<TeethMap
							setTooth={this.setTooth}
							teeth={this.state.teeth}
							isError={this.state.isTeethError}
							teethErrorMessage={this.state.teethErrorMessage}
							readOnly={
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.TEETH
								).state === ORDER_FIELD_STATUSES.READ_ONLY
							}
						/>
					</div>
					<div className="row">
						<div className="col-12 mt-s d-flex justify-content-end align-items-start">
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.METAL_TEST_DATE
								),
								{ className: 'mr-l test-date' }
							)}
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.CERAMIC_TEST_DATE
								),
								{ className: 'mr-l test-date' }
							)}
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.INDIVIDUAL_SPOON_TEST_DATE
								),
								{ className: 'mr-l test-date' }
							)}
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.BITE_TEST_DATE
								),
								{ className: 'mr-l test-date' }
							)}
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.ARRANGED_TEETH_TEST_DATE
								),
								{ className: 'full-width test-date date-picker-aligned-left' }
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-12 mt-s d-flex justify-content-end align-items-start">
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.WARRANTY_IN_MONTHS
								),
								{
									label: i18n.t('translation:orders.fields.warranty'),
									className: 'mr-s warranty'
								}
							)}
							{formUtils.renderItemField(
								formUtils.getFieldByType(
									this.props.orderItems,
									ORDER_ITEM_FIELDS.NOTES
								),
								{ className: 'first full-width' }
							)}
						</div>
					</div>
				</form>
				<div className="buttons">
					{addButton}
					{editButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	renderAdditionalColorSpecification = () => {
		if (this.isProductHaveColor()) {
			return (
				<div>
					{formUtils.renderItemField(
						formUtils.getFieldByType(
							this.props.orderItems,
							ORDER_ITEM_FIELDS.NO_COLOR_SPECIFIED
						),
						{
							label: i18n.t('translation:orders.fields.additionalSpecifiedColor'),
							className: 'mt-1 full-width'
						}
					)}
				</div>
			);
		}
	};

	setTeethErrorMessage = (teethErrorMessage) => {
		this.setState({ teethErrorMessage, isTeethError: true });
	};

	setTooth = (fdiPosition, type, otherToothInMask) => {
		const { isGroup } = this.props.formValues;
		const toothIndex = this.state.teeth.findIndex(
			(tooth) => +tooth.fdiPosition === +fdiPosition
		);
		const otherToothIndex = this.state.teeth.findIndex(
			(tooth) => +tooth.fdiPosition === +otherToothInMask
		);
		let teeth = [...this.state.teeth];
		if (toothIndex >= 0) {
			if (type) {
				teeth[toothIndex].type = type;
				if (otherToothIndex >= 0) {
					teeth.splice(otherToothIndex, 1);
				}
				this.setState({ teeth, isTeethError: false, teethErrorMessage: '' });
			} else {
				teeth.splice(toothIndex, 1);
				this.setState({ teeth, isTeethError: false, teethErrorMessage: '' });
			}
		} else {
			if (type) {
				if (otherToothIndex >= 0) {
					teeth.splice(otherToothIndex, 1);
				}
				if (!isGroup && teeth.length > 0) {
					teeth = [{ fdiPosition, type }];
				} else {
					teeth.push({ fdiPosition, type });
				}
			}
			this.setState({ teeth, isTeethError: false, teethErrorMessage: '' });
		}
	};

	isColorSchemeDisabled = () => {
		return !this.isProductHaveColor();
	};

	renderFirstRow = () => {
		const priceValue = this.calculatePrice();
		const price = this.props.currentOrder.canAccessPaymentData
			? {
					title: i18n.t('translation:orders.priceWithoutDiscount'),
					value: preparePriceForDisplay(priceValue, this.props.lab)
			  }
			: null;

		const productTypeIsEditable =
			formUtils.getFieldByType(this.props.orderItems, ORDER_ITEM_FIELDS.PRODUCT).state !==
			ORDER_FIELD_STATUSES.READ_ONLY;

		const colorSchemeIsReadOnly =
			formUtils.getFieldByType(this.props.orderItems, ORDER_ITEM_FIELDS.COLOR_SCHEME)
				.state === ORDER_FIELD_STATUSES.READ_ONLY;
		const originalColorScheme = this.props.initialValues.colorScheme;

		return (
			<div className="flex-row d-flex">
				{this.renderProductDropdown()}

				{productTypeIsEditable ? (
					<span className="order-item-product-icon input-container">
						{this.renderSelectedProductIcon()}
					</span>
				) : null}

				<div className="d-flex">
					<DataRow
						data={[
							{
								value: Fields.orderItemFields.colorScheme({
									className: 'first ml-s',
									options: this.props.colors,
									disabled: this.isColorSchemeDisabled(),
									loader: this.props.colorLoader,
									colorCodeEquivalent: this.state.colorCodeEquivalent,
									onChange: this.onColorSchemeChange,
									isOriginalColorScheme: colorSchemeIsReadOnly
										? this.props.formValues.colorScheme === originalColorScheme
										: true,
									colorCode: this.props.initialValues.colorCode,
									initialValues: this.props.initialValues,
									readableInput: colorSchemeIsReadOnly
								})
							},
							{
								title: i18n.t('translation:orders.fields.inGroup'),
								value: Fields.orderItemFields.isGroup({
									disabled:
										formUtils.getFieldByType(
											this.props.orderItems,
											ORDER_ITEM_FIELDS.TEETH
										).state === ORDER_FIELD_STATUSES.READ_ONLY
								})
							},
							{
								title: i18n.t('translation:orders.fields.quantity'),
								value: this.state.teeth.length
							},
							price
						]}
						noColumns
					/>
				</div>
			</div>
		);
	};

	calculatePrice = () => {
		if (!this.props.currentOrder.canAddAndRemoveOrderItems) {
			return this.props.initialValues.price;
		}

		const product = this.getProduct();
		const price = this.state.shouldGetPropsFromInitValues
			? this.props.initialValues.price
			: product.price;
		// if (!product) {
		//     return 0;
		// }

		const productCategory = this.state.shouldGetPropsFromInitValues
			? this.props.initialValues.productCategory
			: product.category;

		const quantity = this.state.teeth.length;

		switch (productCategory) {
			case GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH:
				if (quantity === 0) {
					return price;
				}
				return quantity * price;

			case GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE:
			case GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE_CONSECUTIVE_TEETH:
				return price;

			default:
				return 0;
		}
	};

	renderSelectedProductIcon = () => {
		const product = this.getProduct();
		if (!product) {
			return null;
		}

		return renderOrderItemCategoryIcon(product.category);
	};

	getProduct = () => {
		const productId = this.props.formValues.productId;
		const product =
			_.find(this.props.inventory, function (item) {
				return item.id === +productId;
			}) || {};

		return product;
	};

	isProductHaveColor = () => {
		const product = this.getProduct();
		// if (this.isNewProduct()) {
		//     if (!isEmptyObject(product)) {
		//         return product.hasColor;
		//     } else {
		//         return true;
		//     }
		// } else {
		//     return this.props.initialValues.productHasColor;
		// }
		if (this.state.shouldGetPropsFromInitValues) {
			return this.props.initialValues.productHasColor;
		} else {
			if (!isEmptyObject(product)) {
				return product.hasColor;
			} else {
				return true;
			}
		}
	};

	setEquivalentColorCode = (colorCodeEquivalent) => {
		this.setState({ colorCodeEquivalent });
	};

	onColorSchemeChange = (event, value, previousValue) => {
		if (
			this.props.formValues.colorCode &&
			value &&
			previousValue &&
			!this.props.currentOrder.canAddAndRemoveOrderItems
		) {
			this.props
				.getColorCodeEquivalent(
					parseColorSchemeToConstant(this.props.initialValues.colorScheme),
					parseColorSchemeToConstant(value),
					this.props.formValues.colorCode
				)
				.then((data) => {
					this.setEquivalentColorCode(
						data.data.data || i18n.t('translation:orders.noConversion')
					);
				});
		} else {
			// Fixing a bug which allows us to save successfully (using the old color code value) when:
			// 1) Change the color scheme - this reloads the color code values and no value is selected in it now
			// 2) Click "Save" button of the modal, without doing anything else before this
			this.props.change('colorCode', EMPTY_STRING);
		}
	};

	isNewProduct = () => {
		return isEmptyObject(this.props.initialValues);
	};

	getProductById = (productId) => {
		const product =
			_.find(this.props.inventory, function (item) {
				return item.id === +productId;
			}) || {};
		return product;
	};

	getWarranty = (productId) => {
		const product = this.getProductById(productId);
		return product && product.warrantyInMonths ? product.warrantyInMonths.toString() : 0;
	};

	renderProductDropdown = () => {
		const { inventory, initialValues } = this.props;
		const field = formUtils.getFieldByType(this.props.orderItems, ORDER_ITEM_FIELDS.PRODUCT);

		let icon = null;
		const product = this.getProduct();
		if (product && field.state === ORDER_FIELD_STATUSES.READ_ONLY) {
			icon = (
				<span className="read-only-order-item-product-icon">
					{renderOrderItemCategoryIcon(product.category)}
				</span>
			);
		}
		const initProduct = this.getProductById(initialValues.productId);

		return formUtils.renderItemField(field, {
			className: 'order-item-modal-product input-container',
			icon: icon,
			description: initialValues.productName,
			options: (function () {
				let refinedInventory = inventory.map((item) => {
					return { name: `${item.type} (${item.material})`, value: item.id };
				});
				if (isEmptyObject(initProduct) && !isEmptyObject(initialValues)) {
					refinedInventory.push({
						name: `${initialValues.productType} (${
							initialValues.productMaterial
						}) (${i18n.t('translation:orders.notAvailable')})`,
						value: initialValues.productId,
						disabled: true
					});
				}
				refinedInventory.unshift({ name: '', value: '' });
				return refinedInventory;
			})(),
			onChange: (event, newValue, previousValue, name) => {
				const product = this.getProductById(newValue);
				if (product && !product.hasColor) {
					this.props.change('colorScheme', 'No color');
					this.props.change('colorCode', '');
				}
				this.props.change('warrantyInMonths', this.getWarranty(newValue));
				this.setState({ shouldGetPropsFromInitValues: false, productChanged: true });
			}
		});
	};

	onFormSubmit = () => {
		this.props.handleSubmit((formValues) => {
			const product = this.getProduct();
			const fields = this.props.currentOrder.orderItemFieldStates;
			const mandatoryFields = formUtils.getMandatoryFields(fields);
			const editableFields = formUtils.getEditableFields(fields);
			if (!formValues.noColorSpecified && this.isProductHaveColor()) {
				mandatoryFields.push('colorScheme');
				mandatoryFields.push('colorCode');
			}

			const errors = {
				...formUtils.getOrderItemFieldErrors(formValues, editableFields),
				...formUtils.getMandatoryFieldErrors(mandatoryFields, formValues)
			};

			if (!isEmptyObject(errors)) {
				throw new SubmissionError({
					...errors,
					_error: 'error'
				});
			}

			let refinedValues = {};
			for (let field in formValues) {
				if (editableFields.includes(field)) {
					const type = _.upperFirst(field);
					if (Object.values(ORDER_ITEM_FIELDS).includes(type)) {
						if (
							type === ORDER_ITEM_FIELDS.COLOR_SCHEME ||
							type === ORDER_ITEM_FIELDS.COLOR_CODE
						) {
							if (!formValues[field]) {
								refinedValues[_.lowerFirst(ORDER_ITEM_FIELDS.COLOR_SCHEME)] = null;
								refinedValues[_.lowerFirst(ORDER_ITEM_FIELDS.COLOR_CODE)] = null;
							} else {
								refinedValues[field] = formValues[field];
							}
						} else {
							refinedValues[field] = formValues[field];
						}
					}
				}
			}

			if (refinedValues.colorScheme) {
				refinedValues.colorScheme = parseColorSchemeToConstant(formValues.colorScheme);
			}

			if (refinedValues.metalTestDate) {
				refinedValues.metalTestDate = this.refineDate(refinedValues.metalTestDate);
			}
			if (refinedValues.ceramicTestDate) {
				refinedValues.ceramicTestDate = this.refineDate(refinedValues.ceramicTestDate);
			}
			if (refinedValues.individualSpoonTestDate) {
				refinedValues.individualSpoonTestDate = this.refineDate(
					refinedValues.individualSpoonTestDate
				);
			}
			if (refinedValues.biteTestDate) {
				refinedValues.biteTestDate = this.refineDate(refinedValues.biteTestDate);
			}
			if (refinedValues.arrangedTeethTestDate) {
				refinedValues.arrangedTeethTestDate = this.refineDate(
					refinedValues.arrangedTeethTestDate
				);
			}

			refinedValues.teeth = this.state.teeth;
			if (this.isNewProduct()) {
				if (this.state.teeth.length === 0) {
					this.setState({ isTeethError: true });
					return;
				}

				this.props.createItem(
					this.props.currentOrder.id,
					refinedValues,
					this.setTeethErrorMessage,
					this.closeModal
				);
			} else {
				if (isLabAdmin(this.props.account)) {
					let errors = formUtils.getErrorsOnOrderSave();
					if (!isEmptyObject(errors)) {
						this.closeModal();
						Object.keys(errors).forEach((field) => {
							this.props.dispatch(touch('orderDetailsForm', [field]));
						});
						this.props.dispatch(stopSubmit('orderDetailsForm', errors));
						this.props.showMessageModal(
							i18n.t('translation:common.errors.error'),
							i18n.t('translation:common.errors.fillAllFields')
						);
						return;
					}
				}
				if (this.state.teeth.length === 0) {
					this.setState({ isTeethError: true });
					return;
				}
				if (!this.state.productChanged) {
					delete refinedValues.productId;
				}

				this.props.updateItem(
					this.props.currentOrder.id,
					this.props.initialValues.id,
					refinedValues,
					this.setTeethErrorMessage,
					this.closeModal
				);
			}
		})();
	};

	refineDate = (date) => {
		return moment(date).format(DEFAULT_BACKEND_DATE_FORMAT);
	};

	closeModal = () => {
		this.props.destroy();
		this.props.closeModal();
		this.setState({ teeth: [], isTeethError: false, teethErrorMessage: '' });
	};
}

const addProductForm = reduxForm({ form: 'addProductForm' })(OrderItemModal);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		colors: state.orders.colors,
		currentOrder: state.orders.currentOrder,
		orderItems: state.orders.currentOrder.orderItemFieldStates,
		inventory: state.inventory.labInventory,
		colorLoader: state.loader[LOADER_TYPES.COLOR_LOADER],
		productLoader: state.loader[LOADER_TYPES.PRODUCT_LOADER],
		formValues: getFormValues('addProductForm')(state) || {}
	};
};

export default connect(mapStateToProps, {
	getLabInventoryOnly,
	createItem,
	updateItem,
	getColors,
	getOrderDetails,
	getColorCodeEquivalent,
	showMessageModal
})(addProductForm);
