import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import {
	LANGUAGE_CODES,
	LANGUAGE_DISPLAY_NAMES,
	LANGUAGE_TABLE_FIELD_PREFIXES,
	REDUX_FORM_NAMES,
	SINGLE_SPACE
} from '../../../utils/constants';
import Table from '../../common/Table';
import { prepareSingleGlobalProductForLanguageTable } from '../../../utils/tableUtils';
import { clearSubmitErrors, getFormValues, SubmissionError } from 'redux-form';
import store from '../../../store';
import { formUtils } from '../../../utils/formUtils';
import { isEmptyObject } from '../../../utils/appUtils';
import { validateGlobalProductDetails } from './GlobalProductDetails';
import { addGlobalProduct, showMessageModal, updateGlobalProduct } from '../../../actions';

class GlobalProductLanguagesTable extends React.Component {
	state = {
		errorExists: false
	};

	render() {
		const rows = prepareSingleGlobalProductForLanguageTable();

		const columns = [
			i18n.t('translation:common.language'),
			i18n.t('translation:inventory.table.type'),
			i18n.t('translation:inventory.table.material')
		];

		const initialValues = {};

		const { product } = this.props;
		if (product) {
			for (const row of rows) {
				const languageName = row[0];

				switch (languageName) {
					case LANGUAGE_DISPLAY_NAMES.BG:
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.BG}`
						] = product.typeInBulgarian;
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.BG}`
						] = product.materialInBulgarian;
						break;

					case LANGUAGE_DISPLAY_NAMES.DE:
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.DE}`
						] = product.typeInGerman;
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.DE}`
						] = product.materialInGerman;
						break;

					case LANGUAGE_DISPLAY_NAMES.EN:
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.EN}`
						] = product.typeInEnglish;
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.EN}`
						] = product.materialInEnglish;
						break;

					case LANGUAGE_DISPLAY_NAMES.TR:
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.TR}`
						] = product.typeInTurkish;
						initialValues[
							`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.TR}`
						] = product.materialInTurkish;
						break;

					default:
					// Do nothing
				}
			}
		}

		return (
			<Fragment>
				<Table
					className="table-languages editable-inventory"
					columns={columns}
					data={rows}
					onSubmit={this.onSubmit}
					initialValues={{ ...initialValues }}
					leftAlignLastColumn={true}
				/>
				{this.renderIfErrorExists()}
			</Fragment>
		);
	}

	renderIfErrorExists = () => {
		if (this.state.errorExists) {
			return (
				<span className="align-items-start text-danger">
					{i18n.t('translation:common.errors.enterValidDataInAllFields')}
				</span>
			);
		}
	};

	onSubmit = (formValues) => {
		var productValues = getFormValues(REDUX_FORM_NAMES.GLOBAL_PRODUCT_DETAILS)(
			store.getState()
		);

		this.validate(formValues, productValues);

		this.submitData(formValues, productValues);
	};

	validate = (formValues, productValues) => {
		let errors = validateGlobalProductDetails(productValues);
		const errorExistsElsewhere = !isEmptyObject(errors);

		errors = formUtils.validateGlobalProductLanguagesTable(formValues);

		for (const valueName in formValues) {
			const value = formValues[valueName];
			if (isEmptyObject(value) || isEmptyObject(value.trim())) {
				errors[valueName] = SINGLE_SPACE;
			}
		}

		// If the data for new product is added here, there won't be any formValues entries for the fields
		// that are empty. That is why we inspect and change the errors themselves below
		for (const valueName in errors) {
			errors[valueName] = SINGLE_SPACE;
		}

		const errorsExistsHere = !isEmptyObject(errors);
		const errorExists = errorsExistsHere || errorExistsElsewhere;

		this.setState({ ...this.state, errorExists: errorExists });

		if (errorExists) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}
		this.props.clearSubmitErrors('globalInventoryPhasesForm');
	};

	submitData = (formValues, productValues) => {
		const payload = {
			category: productValues.category,
			typeInBulgarian:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.BG}`],
			typeInEnglish:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.EN}`],
			typeInGerman:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.DE}`],
			typeInTurkish:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.TR}`],
			materialInBulgarian:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.BG}`],
			materialInEnglish:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.EN}`],
			materialInGerman:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.DE}`],
			materialInTurkish:
				formValues[`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.TR}`],
			hasColor: !productValues.hasColor
		};

		const { product, currentPage, closeModal } = this.props;
		if (product) {
			this.props.updateGlobalProduct(payload, product.id, currentPage, closeModal);
		} else {
			this.props.addGlobalProduct(payload, currentPage, closeModal);
		}
	};
}

export default connect(null, {
	addGlobalProduct,
	updateGlobalProduct,
	showMessageModal,
	clearSubmitErrors
})(GlobalProductLanguagesTable);
