import React, { Fragment } from 'react';
import {
	getSelectedTaxType,
	isClinicAdmin,
	isLabAdmin,
	parseBillingDocumentFilterType
} from '../../../../utils/appUtils';
import {
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_TYPES,
	PAGES_PATHS,
	PAGINATION_TYPES
} from '../../../../utils/constants';
import { getListWithTooltip, parseInvoicingCurrency } from '../../../../utils/tableUtils';
import i18n from '../../../../i18n';
import Table from '../../../common/Table';
import Pagination from '../../../common/Pagination';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from '../../../../utils/moment';
import BillingDocumentModal, {
	BILLING_DOCUMENT_MODE
} from '../invoicing/billingDocumentModal/BillingDocumentModal';
import { setPagination } from '../../../../actions/index';
import { subtract } from '../invoicing/billingDocumentModal/utils/documentUtils';
import { getLocalizedConnectedTo } from '../utils';

class DebtorDaysModalTable extends React.Component {
	state = {
		isModalOpen: false,
		documentId: null,
		documentType: null,
		clinicId: null,
		mode: null,
		isExportPdfModalOpen: false
	};

	getLocalizedTypeColumn(type) {
		let filterType;

		if (
			type === BILLING_DOCUMENT_TYPES.FREE_INVOICE ||
			type === BILLING_DOCUMENT_TYPES.ORDER_INVOICE
		) {
			filterType = BILLING_DOCUMENT_FILTER_TYPES.INVOICE;
		}

		if (type === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			filterType = BILLING_DOCUMENT_FILTER_TYPES.CREDIT_NOTE;
		}

		if (type === BILLING_DOCUMENT_TYPES.DEBIT_NOTE) {
			filterType = BILLING_DOCUMENT_FILTER_TYPES.DEBIT_NOTE;
		}

		if (!filterType) {
			return 'UNKNOWN';
		}

		return parseBillingDocumentFilterType(filterType);
	}

	parseDocuments = (documents) => {
		if (!documents) {
			return [];
		}
		let documentRows = [];

		for (let index in documents) {
			const document = documents[index];

			const number = this.getDocumentNumberColumn(document);
			const issueDate = this.getIssueDateColumn(document);
			const type = this.getLocalizedTypeColumn(document.type);
			const connectedToNumbers = getListWithTooltip(document.connectedItemsIds, '-', 3, true);
			const connectedTo = getLocalizedConnectedTo(document.connectedItemsTypes);
			const amount = this.getAmountColumn(document);

			documentRows.push([
				number,
				issueDate,
				type,
				this.getClinicLegalNameColumn(document),
				connectedTo,
				connectedToNumbers,
				amount,
				this.getPaidColumn(document),
				this.getNotPaidColumn(document)
			]);
		}

		return documentRows;
	};

	getPaidColumn(document) {
		const { currencySymbol, localizedCurrencyAbbreviation } = document;
		const remainingAmount = document.remainingAmount ?? 0;
		const total = document.total ?? 0;
		let paidAmount = subtract(total, remainingAmount);

		if (document.type === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			paidAmount = Number(paidAmount) * -1;
		}

		let valueToDisplay = parseInvoicingCurrency(
			paidAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);

		return valueToDisplay;
	}

	getNotPaidColumn(document) {
		const { currencySymbol, localizedCurrencyAbbreviation } = document;
		let remainingAmount = document.remainingAmount ?? 0;

		if (document.type === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			remainingAmount = remainingAmount * -1;
		}

		const valueToDisplay = parseInvoicingCurrency(
			remainingAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);

		return valueToDisplay;
	}

	getAmountColumn(document) {
		let total = document.total;

		if (document.type === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			total = document.total * -1;
		}

		let valueToDisplay = parseInvoicingCurrency(
			total,
			document.localizedCurrencyAbbreviation,
			document.currencySymbol
		);

		return valueToDisplay;
	}

	getClinicLegalNameColumn(document) {
		return document.clinicLegalName;
	}

	getLabLegalNameColumn(document) {
		return (
			<Link to={PAGES_PATHS.SUPPLIERS + '/' + document.labId}>{document.labLegalName}</Link>
		);
	}

	getOrdersIdsColumn(document) {
		let list = [];

		if (document.type === BILLING_DOCUMENT_TYPES.ORDER_INVOICE) {
			list = document?.connectedItemsIds ?? [];
		}

		return getListWithTooltip(list, '-');
	}

	getIssueDateColumn(document) {
		return moment(document.issueDate).format('DD.MM.YYYY');
	}

	getDocumentNumberColumn(document) {
		return document.number;
	}

	onModalClose() {
		this.setState({ isModalOpen: false, documentId: null });
	}

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.DEBTOR_DAYS_DOCUMENTS, page);
	};

	renderBillingDocumentModal = () => {
		if (this.state.isModalOpen) {
			return (
				<BillingDocumentModal
					mode={this.state.mode}
					selectedDocumentId={this.state.documentId}
					clinicId={this.state.clinicId}
					documentType={this.state.documentType}
					isModalOpen={true}
					onModalClose={() => this.onModalClose()}
				/>
			);
		}

		return null;
	};

	renderDocuments = () => {
		const taxType = getSelectedTaxType(this.props.billingSettings.taxType);
		const columnsNames = [
			i18n.t('translation:billingModule.common.table.number'),
			i18n.t('translation:billingModule.common.table.issueDate'),
			i18n.t('translation:billingModule.common.table.type'),
			i18n.t('translation:billingModule.common.table.client'),
			i18n.t('translation:billingModule.balance.table.connectedWith'),
			i18n.t('translation:billingModule.balance.table.numConnectedWith'),
			i18n.t('translation:billingModule.common.table.amountWithTax', { taxType: taxType }),
			i18n.t('translation:billingModule.common.table.paid'),
			i18n.t('translation:billingModule.common.table.notPaid')
		];

		const documents = this.parseDocuments(this.props.debtorDaysDocuments?.content);

		return (
			<Fragment>
				<div className="col-12 mt-s p-0 documents-table">
					<Table columns={columnsNames} data={documents} />
				</div>
			</Fragment>
		);
	};

	renderPagination = () => {
		const documents = this.props.debtorDaysDocuments?.content;
		if (documents) {
			return (
				<Pagination
					currentPage={this.props.debtorDaysDocuments?.number + 1}
					total={this.props.debtorDaysDocuments?.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	render() {
		return (
			<div style={{ paddingBottom: '30px' }}>
				{this.renderDocuments()}
				{this.renderPagination()}
				{this.renderBillingDocumentModal()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		billingSettings: state.billingSettings,
		debtorDaysDocuments: state.billingDebtorDays.debtorDaysDocuments
	};
};

export default connect(mapStateToProps, {
	getSelectedTaxType,
	setPagination
})(DebtorDaysModalTable);
