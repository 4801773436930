import { GET_GLOBAL_PRODUCT_PHASE_TEMPLATES } from '../types';
import { getAxiosWithToken } from '../../utils/webApi';
import { showSuccessPopup } from '../../utils/appUtils';
import { getGlobalProducts } from '../inventoryActions';
import i18n from '../../i18n';

export const getGlobalProductPhasesTemplates = (id, showModal) => async (dispatch) => {
	const response = await getAxiosWithToken(true, true, showModal).get(
		`/product/global-product-template-phases/${id}`
	);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_GLOBAL_PRODUCT_PHASE_TEMPLATES,
			payload: returnedData.data
		});
	}
};

export const updateGlobalProductPhasesTemplates =
	(id, phases, closeModal, setErrorMessage, page) => async (dispatch) => {
		const response = await getAxiosWithToken(false, true).put(
			`/product/global-product-template-phases/${id}`,
			{ phases }
		);
		let returnedData = response.data;
		if (returnedData.success) {
			showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedTemplate'));
			dispatch(getGlobalProducts(page));
			closeModal();
		} else {
			setErrorMessage(returnedData.userMessage);
		}
	};
