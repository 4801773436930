import React, { Fragment } from 'react';
import {
	PAGES_PATHS,
	TABLE_ACTIONS,
	TRANSACTION_STATUS,
	TRANSACTION_TYPE
} from '../../../../utils/constants';
import {
	getListWithTooltip,
	parseInvoicingCurrency,
	renderActionButton,
	renderSimpleTable
} from '../../../../utils/tableUtils';
import i18n from '../../../../i18n';
import Pagination from '../../../common/Pagination';

import { Link } from 'react-router-dom';
import moment from '../../../../utils/moment';
import { subtract } from '../invoicing/billingDocumentModal/utils/documentUtils';
import { IncomeOutcomeModalContentType } from './IncomeOutcomeModal';
import { renderTransactionStatus } from '../utils';

class IncomesOutcomesTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			localizedDocumentType: {
				INVOICE: i18n.t('translation:billingModule.incomesOutcomes.invoice'),
				CREDIT_NOTE: i18n.t('translation:billingModule.incomesOutcomes.creditNote'),
				TRANSACTION: i18n.t('translation:billingModule.incomesOutcomes.income'),
				DEBIT_NOTE: i18n.t('translation:billingModule.incomesOutcomes.debitNote')
			}
		};
	}

	getHeader() {
		const header = [
			{
				style: { width: '7%' },
				content: i18n.t('translation:billingModule.common.table.number')
			},
			{
				content: i18n.t('translation:billingModule.incomesOutcomes.date')
			},
			{
				content: i18n.t('translation:billingModule.common.table.type')
			},
			{
				content: i18n.t('translation:billingModule.incomesOutcomes.documentType')
			},
			{
				content: i18n.t('translation:billingModule.incomesOutcomes.table.documents')
			},
			{
				content: i18n.t('translation:billingModule.common.table.client')
			},
			{
				content: i18n.t('translation:billingModule.common.table.amount')
			},
			{
				content: `${i18n.t('translation:billingModule.incomesOutcomes.table.remainder')}*`,
				props: {
					datatoggle: 'tooltip',
					dataplacement: 'right',
					title: i18n.t('translation:billingModule.incomesOutcomes.table.reminderTooltip')
				}
			},
			{ content: i18n.t('translation:billingModule.common.table.status') },
			{
				style: { width: '10%' },
				content: i18n.t('translation:billingModule.common.table.action')
			}
		];

		return header;
	}

	parseTransactions() {
		const transactions = this.props.transactions ?? [];

		let transactionsRows = [];

		for (let transaction of transactions) {
			transactionsRows.push([
				this.getNumberColumn(transaction),
				this.getTransactionDateColumn(transaction.transactionDate),
				this.getTransactionType(transaction.type),
				this.getDocumentType(transaction.documentTypes),
				this.getLinkedDocumentsNumbers(transaction),
				this.getClinicLegalNameColumn(transaction),
				this.getAmountColumn(
					transaction.amount,
					transaction.localizedCurrencyAbbreviation,
					transaction.currencySymbol,
					transaction.type
				),
				this.getRemainderColumn(
					transaction.type,
					transaction.amountRemaining,
					transaction.creditedAmount,
					transaction.localizedCurrencyAbbreviation,
					transaction.currencySymbol
				),
				renderTransactionStatus(transaction.status),
				this.getActionButtonsColumn(transaction)
			]);
		}

		return transactionsRows;
	}

	getAmountColumn(amount, localizedCurrencyAbbreviation, currencySymbol, transactionType) {
		if (transactionType === TRANSACTION_TYPE.CREDIT) {
			amount = amount * -1;
		}

		return parseInvoicingCurrency(amount, localizedCurrencyAbbreviation, currencySymbol);
	}

	getRemainderColumn(
		transactionType,
		remainder,
		creditedAmount,
		localizedCurrencyAbbreviation,
		currencySymbol
	) {
		if (transactionType === TRANSACTION_TYPE.CREDIT) {
			return parseInvoicingCurrency(0, localizedCurrencyAbbreviation, currencySymbol);
		}
		return parseInvoicingCurrency(
			subtract(remainder, creditedAmount),
			localizedCurrencyAbbreviation,
			currencySymbol
		);
	}

	getClinicLegalNameColumn(transaction) {
		return (
			<Link to={PAGES_PATHS.CLIENTS + '/' + transaction.clinicId}>
				{transaction.clinicLegalName}
			</Link>
		);
	}

	getTransactionDateColumn(date) {
		return moment(date).format('DD.MM.YYYY');
	}

	getNumberColumn(transaction) {
		const action = this.getEditPreviewDocumentAction(transaction);
		return (
			<span className={'link'} onClick={action}>
				{transaction.labTransactionId}
			</span>
		);
	}

	getTransactionType(transactionType) {
		if (transactionType === TRANSACTION_TYPE.CREDIT) {
			return i18n.t('translation:billingModule.incomesOutcomes.outcome');
		}
		return i18n.t('translation:billingModule.incomesOutcomes.income');
	}

	getDocumentType(documentTypes) {
		const localizedTypes = documentTypes.map((type) => this.state.localizedDocumentType[type]);
		if (!localizedTypes.length) {
			return i18n.t('translation:billingModule.incomesOutcomes.table.noDocuments');
		}
		if (localizedTypes.length === 1) {
			return localizedTypes[0];
		}
		return localizedTypes.join(', ');
	}

	getLinkedDocumentsNumbers(transaction) {
		let documentsNumbers = [];

		if (transaction.type === TRANSACTION_TYPE.DEBIT) {
			documentsNumbers = transaction.billingDocumentsNumbers;
		} else {
			if (transaction.creditNotesNumbers && transaction.creditNotesNumbers.length) {
				documentsNumbers = transaction.creditNotesNumbers;
			} else if (
				transaction.debitLabTransactionsIds &&
				transaction.debitLabTransactionsIds.length
			) {
				documentsNumbers = transaction.debitLabTransactionsIds;
			}
		}

		return getListWithTooltip(
			documentsNumbers,
			i18n.t('translation:billingModule.incomesOutcomes.table.noDocuments'),
			3,
			true
		);
	}

	getEditPreviewDocumentAction(transaction) {
		const transactionType = transaction.type;

		const modalContentType =
			transactionType === TRANSACTION_TYPE.DEBIT
				? IncomeOutcomeModalContentType.INCOME
				: IncomeOutcomeModalContentType.OUTCOME;

		let modalIsCreation = false;
		const modalContentNumber = transaction.labTransactionId;
		const modalContentId = transaction.id;

		return this.props.onActionButtonClick({
			modalIsCreation,
			modalContentType,
			modalContentId,
			modalContentNumber
		});
	}

	getActionButtonsColumn(transaction) {
		const action = this.getEditPreviewDocumentAction(transaction);

		if (
			transaction.status === TRANSACTION_STATUS.COMPLETED ||
			transaction.status === TRANSACTION_STATUS.CANCELED
		) {
			return renderActionButton([
				{
					type: TABLE_ACTIONS.VIEW,
					action
				}
			]);
		}
		return renderActionButton([
			{
				type: TABLE_ACTIONS.EDIT,
				action
			}
		]);
	}

	renderContent = () => {
		const transactions = this.parseTransactions();
		return (
			<Fragment>
				<div className="col-12 mt-s p-0 transaction-table">
					{renderSimpleTable(
						transactions,
						'',
						this.getHeader(),
						i18n.t('translation:common.table.noItems')
					)}
				</div>
			</Fragment>
		);
	};

	renderPagination = () => {
		if (!this.props.paginated) {
			return null;
		}
		const { totalElements } = this.props;
		if (totalElements) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={totalElements}
					setCurrentPage={this.props.setCurrentPage}
				/>
			);
		}
	};

	render() {
		return (
			<Fragment>
				{this.renderContent()}
				{this.renderPagination()}
			</Fragment>
		);
	}
}

export default IncomesOutcomesTable;
