import { CLEAR_PAGINATION, SET_PAGINATION } from '../actions/types';

const initState = {};

const paginationReducer = (state = { ...initState }, action) => {
	if (action.type === SET_PAGINATION) {
		state[action.payload.type] = action.payload.page;
		return { ...state };
	}

	if (action.type === CLEAR_PAGINATION) {
		const type = action.payload.type;

		if (!type) {
			state = {};
			return { ...state };
		} else {
			state[action.payload.type] = 0;
			return { ...state };
		}
	}

	return state;
};

export default paginationReducer;
