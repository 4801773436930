import { getAxiosWithToken } from '../utils/webApi';
import { GET_MODULE } from './types';
import { showSuccessPopup } from '../utils/appUtils';
import i18n from '../i18n';

export const getLabModules = (id) => async (dispatch) => {
	if (id === null || id === undefined) {
		return;
	}
	const response = await getAxiosWithToken().get(`/lab/${id}/module`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_MODULE,
			payload: returnedData.data
		});
	}
};

export const activateDeactivateLabModule = (labId, updatedModulesData) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/lab/${labId}/module`, updatedModulesData);

	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedSettings'));
		dispatch({
			type: GET_MODULE,
			payload: returnedData.data
		});
	}
};
