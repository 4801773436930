import React from 'react';
import store from '../store';
import i18n from '../i18n';
import DefaultButton from '../components/common/DefaultButton';
import { hideMessageModal, showMessageModal } from '../actions';
import {
	ORDER_FILE_SUB_TYPE,
	SPECIAL_ORDER_FILE_TYPES,
	TEACS_FILES_LOCATION_BASE_URL
} from './constants';

export const IMAGE_TYPE = 'image/jpeg';
export const IMAGE_FILE_EXTENSION = 'jpg';

export function getFileLocation(fileName) {
	return TEACS_FILES_LOCATION_BASE_URL + fileName;
}

export async function getBase64EncodedCroppedImage(imageSrc, pixelCrop, fileType, rotation = 0) {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const maxSize = Math.max(image.width, image.height);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2);
	ctx.rotate(getRadianAngle(rotation));
	ctx.translate(-safeArea / 2, -safeArea / 2);

	// draw rotated image and store data.
	ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
	const data = ctx.getImageData(0, 0, safeArea, safeArea);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
		0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
	);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return canvas.toDataURL(fileType);
}

const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}

export const createFileFromBase64ImageData = (imageData) => {
	var blobBin = atob(imageData.split(',')[1]);
	var array = [];
	for (var i = 0; i < blobBin.length; i++) {
		array.push(blobBin.charCodeAt(i));
	}
	return new Blob([new Uint8Array(array)], { type: IMAGE_TYPE });
};

export const avatarValidation = (file, maxAvatarSizeInMb, showError = true) => {
	return fileNotTooLarge(file, maxAvatarSizeInMb, showError);
};

export const fileNotTooLarge = (file, maxSizeInMB, showError = true) => {
	const closeButton = (
		<DefaultButton
			title={i18n.t('translation:common.buttons.close')}
			onClick={() => {
				store.dispatch(hideMessageModal());
			}}
		/>
	);

	const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
	if (sizeInMB > maxSizeInMB) {
		if (showError) {
			store.dispatch(
				showMessageModal(
					i18n.t('translation:common.errors.error'),
					i18n.t('translation:common.errors.fileSizeError', { word1: maxSizeInMB }),
					[closeButton]
				)
			);
		}
		return false;
	}

	return true;
};

export const isPredefinedPhotoFile = (file) => {
	return isPhotoFile(file) && file.fileSubType !== ORDER_FILE_SUB_TYPE.ADDITIONAL;
};

export const isPredefinedVideoFile = (file) => {
	return isVideoFile(file) && file.fileSubType !== ORDER_FILE_SUB_TYPE.ADDITIONAL;
};

export const isPhotoFile = (file) => {
	return file.fileType === SPECIAL_ORDER_FILE_TYPES.PHOTO;
};

export const isVideoFile = (file) => {
	return file.fileType === SPECIAL_ORDER_FILE_TYPES.VIDEO;
};

export const getFileExtension = (fileName) => {
	return fileName.substring(fileName.lastIndexOf('.') + 1);
};

export const isValidJson = (json) => {
	try {
		JSON.parse(json);
		return true;
	} catch (e) {
		return false;
	}
};
