import React, { Fragment } from 'react';
import {
	BALANCE_FORM_FIELD,
	DEBTOR_DAYS_FIELD,
	PAGES_PATHS,
	PAGINATION_TYPES
} from '../../../../utils/constants';
import { connect } from 'react-redux';
import {
	getBillingSettings,
	getDebtorDays,
	searchStatement,
	setPagination
} from '../../../../actions';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import Pagination from '../../../common/Pagination';
import { parseInvoicingCurrency, renderSimpleTable } from '../../../../utils/tableUtils';
import { Link } from 'react-router-dom';
import DebtorDaysModal from './DebtorDaysModal';
import { getFormValues, reduxForm } from 'redux-form';
import DebtorDaysFilters from './DebtorDaysFilters';

class DebtorDays extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			overdueIntervalId: null,
			clinicId: null,
			title: null
		};
	}

	componentDidMount() {
		this.props.getBillingSettings(this.props.account.labId);
		this.setCurrentPage(1);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.getDebtorDays(
				this.props.account.labId,
				this.props.page,
				this.props.formValues[DEBTOR_DAYS_FIELD.TO_DATE]
			);
		}
	}

	renderPagination = () => {
		const debtorDays = this.props.debtorDays?.content;
		if (debtorDays) {
			return (
				<Pagination
					currentPage={this.props.debtorDays?.number + 1}
					total={this.props.debtorDays?.totalElements}
					setCurrentPage={(page) => this.setCurrentPage(page)}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.DEBTOR_DAYS, page);
	};

	getHeaders() {
		const headers = [
			{
				content: i18n.t('translation:billingModule.takings.table.client')
			},
			{
				content: i18n.t('translation:billingModule.takings.table.unexpired')
			}
		];

		const overdueIntervalHeaders = this.getOverdueIntervalsHeaders();

		if (overdueIntervalHeaders) {
			headers.push(...overdueIntervalHeaders);
		}

		headers.push({
			content: i18n.t('translation:billingModule.takings.table.expiredAll')
		});

		return headers;
	}

	getOverdueIntervalsHeaders() {
		const overdueIntervals = this.props.billingSettings?.overdueIntervals;

		if (!overdueIntervals) {
			return null;
		}

		const headers = [];

		for (let interval of overdueIntervals) {
			headers.push({
				content: i18n.t('translation:billingModule.takings.table.expiredSinceTill', {
					since: interval.fromDay,
					till: interval.toDay
				})
			});
		}

		const lastInterval = overdueIntervals[overdueIntervals.length - 1];

		if (overdueIntervals.length) {
			headers.push({
				content: i18n.t('translation:billingModule.takings.table.expiredAbove', {
					days: lastInterval.toDay
				})
			});
		} else {
			headers.push({
				content: i18n.t('translation:billingModule.takings.table.expired')
			});
		}

		return headers;
	}

	openModal(clinicId, type, overdueIntervalId, title) {
		this.setState({
			isModalOpen: true,
			clinicId,
			type,
			overdueIntervalId,
			title
		});
	}

	closeModal() {
		this.setState({
			isModalOpen: false,
			clinicId: null,
			type: null,
			overdueIntervalId: null,
			title: null
		});
	}

	getLastOverdueInterval() {
		const overdueIntervals = this.props.billingSettings?.overdueIntervals;
		if (overdueIntervals.length) {
			return overdueIntervals[overdueIntervals.length - 1];
		}

		return null;
	}

	getIntervalFromToDates(id) {
		const overdueIntervals = this.props.billingSettings?.overdueIntervals;

		const interval = overdueIntervals.find((int) => Number(int.id) === id);

		if (interval) {
			return {
				fromDay: interval.fromDay,
				toDay: interval.toDay
			};
		}
		return {
			fromDay: '',
			toDay: ''
		};
	}

	renderClientCell(clinicId, clinicLegalName) {
		return <Link to={PAGES_PATHS.CLIENTS + '/' + clinicId}>{clinicLegalName}</Link>;
	}

	renderIntervalCell(interval, clinicId) {
		const { type, overdueIntervalId, localizedCurrencyAbbreviation, currencySymbol, amount } =
			interval;

		let title = '';

		if (type === 'NOT_OVERDUE') {
			title = i18n.t('translation:billingModule.takings.table.unexpired');
		}

		if (type === 'OVERDUE') {
			if (overdueIntervalId) {
				const { fromDay, toDay } = this.getIntervalFromToDates(overdueIntervalId);
				title = i18n.t('translation:billingModule.takings.table.expiredSinceTill', {
					since: fromDay,
					till: toDay
				});
			} else {
				const lastOverdueInterval = this.getLastOverdueInterval();

				if (lastOverdueInterval) {
					const days = lastOverdueInterval.toDay;

					title = i18n.t('translation:billingModule.takings.table.expiredAbove', {
						days: days
					});
				} else {
					title = i18n.t('translation:billingModule.takings.table.expired');
				}
			}
		}

		if (type === 'TOTAL_OVERDUE') {
			title = i18n.t('translation:billingModule.takings.table.expiredAll');
		}

		return [
			<div
				className={'link'}
				key={`${type}_${overdueIntervalId ? overdueIntervalId : 'NO_ID'}`}
				onClick={() => this.openModal(clinicId, type, overdueIntervalId, title)}>
				{parseInvoicingCurrency(amount, localizedCurrencyAbbreviation, currencySymbol)}
			</div>
		];
	}

	parseData(data) {
		if (!data) {
			return [];
		}

		const parsedData = [];

		for (let item of data) {
			const { clinicLegalName, clinicId, intervals } = item;
			const intervalsCells = [];

			intervals.forEach((interval) =>
				intervalsCells.push(this.renderIntervalCell(interval, clinicId))
			);

			parsedData.push([this.renderClientCell(clinicId, clinicLegalName), ...intervalsCells]);
		}
		return parsedData;
	}

	renderModal() {
		if (!this.state.isModalOpen) {
			return null;
		}
		return (
			<DebtorDaysModal
				isOpen={this.state.isModalOpen}
				clinicId={this.state.clinicId}
				type={this.state.type}
				overdueIntervalId={this.state.overdueIntervalId}
				closeModal={() => this.closeModal()}
				title={this.state.title}
			/>
		);
	}

	render() {
		return (
			<Fragment>
				<Header title={i18n.t('translation:billingModule.takings.header')} />
				<DebtorDaysFilters setCurrentPage={(page) => this.setCurrentPage(page)} />
				{renderSimpleTable(
					this.parseData(this.props.debtorDays?.content),
					'debtor-days-table mt-s',
					this.getHeaders(),
					i18n.t('translation:common.table.noItems')
				)}
				{this.renderPagination()}
				{this.renderModal()}
			</Fragment>
		);
	}
}

const getInitialValues = () => {
	const now = new Date();
	return {
		[DEBTOR_DAYS_FIELD.TO_DATE]: now
	};
};

const debtorDaysForm = reduxForm({
	form: 'debtorDaysForm',
	initialValues: getInitialValues()
})(DebtorDays);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		page: state.pagination[PAGINATION_TYPES.DEBTOR_DAYS],
		debtorDays: state.billingDebtorDays.debtorDays,
		billingSettings: state.billingSettings,
		formValues: getFormValues('debtorDaysForm')(state)
	};
};

export default connect(mapStateToProps, { getDebtorDays, setPagination, getBillingSettings })(
	debtorDaysForm
);
