import React, { Fragment } from 'react';
import { Fields } from '../../common/Fields';
import { connect } from 'react-redux';
import { clearSubmitErrors, reduxForm, SubmissionError } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import { isEmptyObject, parseAccountType } from '../../../utils/appUtils';
import { getFileLocation } from '../../../utils/fileUtils';
import { MAX_TEXT_FIELD_LENGTH, NO_AVATAR } from '../../../utils/constants';
import { commonValidation } from '../../../utils/formUtils';
import { createComment } from '../../../actions';
import moment from '../../../utils/moment';

class Comments extends React.Component {
	render() {
		return (
			<div className="comment-section">
				{Fields.orderFields.comment({ className: 'first' })}
				<div className="mt-s d-flex justify-content-end">
					<DefaultButton
						title={i18n.t('translation:orders.buttons.addComment')}
						onClick={this.onAddCommentButtonClick}
					/>
				</div>
				{this.renderComments()}
			</div>
		);
	}

	onAddCommentButtonClick = () => {
		this.props.handleSubmit((formValues) => {
			let errors = {};
			commonValidation(formValues, errors, 'comment')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
				.validate();
			if (!isEmptyObject(errors)) {
				throw new SubmissionError({
					...errors,
					_error: 'error'
				});
			}
			this.props.clearSubmitErrors('orderDetailsForm');
			this.props.createComment(this.props.currentOrder.id, formValues.comment);
		})();
	};

	renderComments = () => {
		const { comments } = this.props;
		const { account } = this.props;
		if (!comments) {
			return <Fragment />;
		}
		const commentsView = comments.map((comment, index) => {
			const commentView =
				comment.authorId === account.id
					? this.renderMyComment(comment)
					: this.renderCorrespondentComment(comment);

			return <Fragment key={'m' + index}>{commentView}</Fragment>;
		});
		return <div className="comments-container">{commentsView}</div>;
	};

	renderCorrespondentComment = (comment) => {
		const createDate = moment(comment.createDate).format('L, LT');

		let containerCssClass = 'mr-auto ';
		containerCssClass += comment.isAuthorActivated
			? 'active-user-comment'
			: 'deactivated-user-comment';

		return (
			<div className={containerCssClass}>
				<div className="row m-0 align-items-center">
					<img
						className="avatar-comment-preview"
						src={
							comment.authorAvatar ? getFileLocation(comment.authorAvatar) : NO_AVATAR
						}
						alt=""
					/>
					<div className="comments-user-info-left mr-auto">
						<span>
							{comment.authorName}
							{!comment.isAuthorActivated ? (
								<FontAwesomeIcon
									className="deactivated-icon"
									title={i18n.t('translation:common.statuses.user.deactivated')}
									icon={faBan}
								/>
							) : null}
						</span>
						<span>{parseAccountType(comment.authorRole)}</span>
					</div>
					<span className="comment-date ml-s align-self-start">{createDate}</span>
				</div>
				<div className="row m-0 comment-text">
					<span>{comment.content}</span>
				</div>
			</div>
		);
	};

	renderMyComment = (comment) => {
		const createDate = moment(comment.createDate).format('L, LT');

		return (
			<div className="ml-auto my-comment">
				<div className="row m-0 align-items-center">
					<span className="comment-date mr-s align-self-start">{createDate}</span>
					<div className="comments-user-info-right ml-auto">
						<span>{comment.authorName}</span>
						<span>{parseAccountType(comment.authorRole)}</span>
					</div>
					<img
						className="avatar-comment-preview"
						src={
							comment.authorAvatar ? getFileLocation(comment.authorAvatar) : NO_AVATAR
						}
						alt=""
					/>
				</div>
				<div className="row m-0 d-flex comment-text">
					<span className="ml-auto">{comment.content}</span>
				</div>
			</div>
		);
	};
}

const commentsForm = reduxForm({
	form: 'commentsForm'
})(Comments);

const mapStateToProps = (state) => {
	return {
		currentOrder: state.orders.currentOrder,
		comments: state.orders.currentOrder.comments,
		account: state.account
	};
};

export default connect(mapStateToProps, { createComment, clearSubmitErrors })(commentsForm);
