import React from 'react';
import { getAxiosWithToken } from '../utils/webApi';
import i18n from '../i18n';
import { reset } from 'redux-form';
import DefaultButton from '../components/common/DefaultButton';
import { GET_STATISTICS, GET_USER_DETAILS, GET_USERS, GET_USERS_WITH_PAGINATION } from './types';
import { hideMessageModal, showMessageModal } from './portalActions';
import { loadAccount, setCurrentUser } from './loginActions';
import { createFileFromBase64ImageData } from '../utils/fileUtils';
import { showSuccessPopup } from '../utils/appUtils';
import { USER_STATUS } from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';

export const getAllNonAdminUsers = (page) => async (dispatch) => {
	page = page - 1;
	const response = await getAxiosWithToken().get('/user/get-all-non-admins', {
		params: { page }
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USERS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const getAllWaitingForApproval = (page) => async (dispatch) => {
	page = page - 1;
	const response = await getAxiosWithToken().get('/user/get-all-waiting-for-approval', {
		params: { page }
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USERS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const getStatistics = () => async (dispatch, getState) => {
	const response = await getAxiosWithToken().get('/user/dashboard-statistics');
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_STATISTICS,
			payload: returnedData.data
		});
	}
};

export const getUserDetails = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/user/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USER_DETAILS,
			payload: returnedData.data
		});
	}
};

export const getGlobalAdminUsers = () => async (dispatch) => {
	const response = await getAxiosWithToken().get('/user/get-all-admins');
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USERS,
			payload: returnedData.data
		});
	}
};

export const activateUserAndAssignRole = (id, role) => async (dispatch) => {
	const response = await getAxiosWithToken().put(
		`/user/activate/${id}`,
		{},
		{
			params: {
				role: role
			}
		}
	);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:users.successfulApprovedAccount'));
		dispatch({
			type: GET_USER_DETAILS,
			payload: returnedData.data
		});
	} else {
		dispatch(getUserDetails(id));
	}
};

export const activateUser = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/user/activate/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:users.successfullyActivatedAccount'));
		dispatch({
			type: GET_USER_DETAILS,
			payload: returnedData.data
		});
	}
};

export const denyUser = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/user/${id}/refuse-activation`);
	let returnedData = response.data;
	if (returnedData.success) {
		navigationMethods.goBack();
		showSuccessPopup(i18n.t('translation:users.successfullyDeniedAccount'));
	}
};

export const changeUserStatus = (id, newStatus) => async (dispatch) => {
	const response = await getAxiosWithToken().put(
		`/user/${id}/change-status?new-status=${newStatus}`
	);
	let returnedData = response.data;
	if (returnedData.success) {
		if (newStatus === USER_STATUS.DEACTIVATED) {
			showSuccessPopup(i18n.t('translation:users.successfullyDeactivatedAccount'));
		} else if (newStatus === USER_STATUS.ACTIVE) {
			showSuccessPopup(i18n.t('translation:users.successfullyActivatedAccount'));
		} else if (newStatus === USER_STATUS.INACTIVE) {
			showSuccessPopup(i18n.t('translation:users.successfullyInactivatedAccount'));
		}
		dispatch({
			type: GET_USER_DETAILS,
			payload: returnedData.data
		});
	}
};

export const updateUser =
	(
		updatedUserId,
		user,
		originalAccount,
		avatarImageData,
		fileType,
		deleteAvatar,
		loggedInUserId,
		displayErrorMessage,
		closeModal
	) =>
	async (dispatch) => {
		let formData = new FormData();
		formData.append('user', JSON.stringify(user));
		if (avatarImageData) {
			const fileExtension = fileType.split('/')[1];
			formData.append('avatar-file', createFileFromBase64ImageData(avatarImageData));
			formData.append('avatar-data', JSON.stringify({ fileExtension }));
		}

		const response = await getAxiosWithToken(false, false).post(
			`/user/update/${updatedUserId}?delete-avatar=${deleteAvatar}`,
			formData,
			{ 'Content-Type': 'multipart/form-data' }
		);

		const returnedData = response.data;
		if (returnedData.success) {
			if (updatedUserId === loggedInUserId) {
				const updatedUserData = returnedData.data;
				setCurrentUser(updatedUserData);
				dispatch(loadAccount());
			}

			dispatch(getUserDetails(updatedUserId));

			closeModal();

			const successButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.ok')}
					onClick={() => {
						dispatch(hideMessageModal());
					}}
				/>
			);
			dispatch(
				showMessageModal(
					i18n.t('translation:common.successfulUpdate'),
					i18n.t('translation:users.successfullyUpdatedAccount'),
					[successButton]
				)
			);
		} else {
			displayErrorMessage(returnedData.userMessage);
		}
	};

export const savePreferences = (userId, data) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/user/update/${userId}/preferences`, data);
	if (response.data.success) {
		dispatch(reset('userDetailsForm'));
		showSuccessPopup(i18n.t('translation:users.successfullyUpdatedPreferences'));
	}
};

export const sendFeedback = (formValues, closeModalCallback) => async (dispatch) => {
	const { topic, description } = formValues;
	const response = await getAxiosWithToken(false, true).post('/system/feedback', {
		topic,
		description
	});

	closeModalCallback();

	if (response.data.success) {
		showSuccessMessageModal(
			i18n.t('translation:common.thankYou'),
			i18n.t('translation:users.feedbackSent'),
			dispatch
		);
	}
};

const showSuccessMessageModal = (messageHeader, message, dispatch) => {
	const successButton = (
		<DefaultButton
			title={i18n.t('translation:common.buttons.ok')}
			onClick={() => {
				dispatch(hideMessageModal());
			}}
		/>
	);

	dispatch(showMessageModal(messageHeader, message, [successButton]));
};
