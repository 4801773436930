import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import { hideMessageModal } from '../../actions';
import { connect } from 'react-redux';
import { isEmptyObject } from '../../utils/appUtils';
import DefaultButton from './DefaultButton';
import i18n from '../../i18n';

class MessageModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	render() {
		const contentData = this.props.messageModal.modalDescription;

		const modalContent = this.props.messageModal.renderContentAsHtml ? (
			parse(contentData)
		) : (
			<p className="modal-description" style={{ whiteSpace: 'pre-line' }}>
				{contentData}
			</p>
		);

		const overlayClassName = this.props.messageModal.isOnFront
			? 'custom-overlay-front'
			: 'custom-overlay';

		return (
			<Modal
				isOpen={this.props.messageModal.isModalOpen}
				className="custom-modal"
				overlayClassName={overlayClassName}>
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.props.hideMessageModal();
					}}
				/>
				<h2>{this.props.messageModal.modalTitle}</h2>
				{modalContent}
				<div className="buttons">{this.renderButtons()}</div>
			</Modal>
		);
	}

	renderButtons = () => {
		if (isEmptyObject(this.props.messageModal.buttons)) {
			return (
				<DefaultButton
					title={i18n.t('translation:common.buttons.ok')}
					onClick={this.props.hideMessageModal}
				/>
			);
		}
		return this.props.messageModal.buttons.map((button, index) => (
			<Fragment key={index + 'messageModalButton'}>{button}</Fragment>
		));
	};
}

const mapStateToProps = (state) => {
	return {
		messageModal: state.portal.messageModal
	};
};

export default connect(mapStateToProps, { hideMessageModal })(MessageModal);
