import React, { Fragment } from 'react';
import Header from '../../common/Header';
import {
	getClinicFromOrder,
	getDentistFromOrder,
	getFirstOrderItemWithColor,
	getLabFromOrder,
	getName,
	getOrderInitialValues,
	getRefinedOrderFields,
	getSelectedTaxType,
	isBillingModuleEnabled,
	isClinicAdmin,
	isCompanyDeactivated,
	isEmptyObject,
	isGlobalAdmin,
	isLabAdmin,
	isLabTechnician,
	isOrderDraft,
	isPassiveClinic,
	isTaskManagerEnabled,
	parseStatus,
	registeredInClinic
} from '../../../utils/appUtils';
import i18n from '../../../i18n';
import DropdownButton from '../../common/DropdownButton';
import { connect } from 'react-redux';
import {
	clearCurrentOrder,
	deleteOrder,
	getAllCalendarOrderItems,
	getBillingSettings,
	getCalendarOrderItems,
	getGeneralWorkingHours,
	getLabStaff,
	getOrderDetails,
	getSettingFromServer,
	hideMessageModal,
	showMessageModal,
	updateOrder,
	updatePaidAmount
} from '../../../actions';
import DefaultButton from '../../common/DefaultButton';
import _ from 'lodash';
import {
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	CLIENT_PAYMENT_STATUS,
	FILTER_FIELDS,
	ORDER_FIELD_STATUSES,
	ORDER_FIELDS,
	ORDER_ITEM_FIELDS,
	ORDER_STATUS,
	USER_STATUS
} from '../../../utils/constants';
import DataRow from '../../common/DataRow';
import Status from '../../common/Status';
import { Fields } from '../../common/Fields';
import { clearSubmitErrors, getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { formUtils } from '../../../utils/formUtils';
import SubHeader from '../../common/SubHeader';
import {
	getDropdownData,
	parseOrderDetailsCalendarOrdersChart,
	parseOrderDetailsCalendarOrdersNestedTable,
	renderSimpleTable
} from '../../../utils/tableUtils';
import OrderItems from './OrderItems';
import Tab from '../../common/Tab';
import {
	faBan,
	faComments,
	faDollarSign,
	faHistory,
	faLaptop
} from '@fortawesome/free-solid-svg-icons';
import Comments from './Comments';
import PredefinedFiles from './PredefinedFiles';
import AdditionalFiles from './AdditionalFiles';
import TeethMap from './TeethMap';
import ColorSwitch from './ColorSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import History from './History';
import Ganttchart from '../taskManager/calendar/orders/Ganttchart';
import navigationUtils from '../../../utils/navigationUtils';
import IssueDocumentButton from './IssueDocumentButton';
import DocumentsTable from '../billingModule/documents/DocumentsTable';
import IncomesOutcomesTable from '../billingModule/incomesOutcomes/IncomesOutcomesTable';
import { subtract } from '../billingModule/invoicing/billingDocumentModal/utils/documentUtils';
import IncomeOutcomeModal from '../billingModule/incomesOutcomes/IncomeOutcomeModal';

class OrderDetails extends React.Component {
	state = {
		checkForDeactivatedDentistNotDoneYet: true,
		dropdown: {},
		filters: {},
		isQuickViewModalOpen: false,
		isEditModalOpen: false,
		order: {},
		currentOrderItem: {},
		currentTask: {},
		ganttchartHeigh: 0,
		isInvoiceSectionOpen: false,
		isTransactionSectionOpen: false,
		transactionModal: {
			isOpen: false,
			contentType: null,
			contentNumber: null,
			contentId: null
		}
	};

	UNSAFE_componentWillMount() {
		if (
			!registeredInClinic(this.props.account) &&
			(isLabAdmin(this.props.account) || isLabTechnician(this.props.account))
		) {
			this.props.getLabStaff(this.props.account.labId);
		}
	}

	componentDidMount() {
		const orderId = this.props.match.params.id;

		this.props.getOrderDetails(orderId).then((resp) => {
			if (this.allowGanttchart()) {
				this.props.getAllCalendarOrderItems(
					this.props.account.labId,
					{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: [resp.labOrderId] },
					true
				);
			}
		});

		if (isTaskManagerEnabled(this.props.modules)) {
			this.props.getGeneralWorkingHours(this.props.account.labId);
		}

		if (navigationUtils.getRoute().split('/')[1] !== 'orders') {
			window.addEventListener('beforeunload', function (e) {
				formUtils.updateDraft();
			});
		}

		const isLabLocalAdmin = isLabAdmin(this.props.account);
		if (isLabLocalAdmin) {
			this.props.getBillingSettings(this.props.account.labId);
		}
	}

	componentWillUnmount() {
		this.props.clearCurrentOrder();
	}

	render() {
		if (isEmptyObject(this.props.currentOrder)) {
			return <Fragment />;
		}

		this.warnForDeactivatedDentistIfNeeded();

		return (
			<Fragment>
				<Header
					title={this.getHeaderTitle()}
					backButton
					buttons={[
						this.renderStatusButton(),
						this.renderNegativeButton(),
						this.renderSaveButton()
					]}
				/>
				{this.renderMainSection()}
				<OrderItems allowGanttchart={this.allowGanttchart()} />
				{this.renderItemsSummarySection()}
				<div className="position-relative mt-s">
					{this.renderGanttchartSubHeader()}
					<div className="position-absolute gantchart">
						{this.renderGanttchart()}
						<PredefinedFiles />
						<AdditionalFiles />
						{this.renderFinancialData()}
						{this.renderInvoiceSection()}
						{this.renderTransactionSection()}
						{this.renderTabs()}
						<div className="mb-m" />
					</div>
				</div>
			</Fragment>
		);
	}

	allowGanttchart = () => {
		if (
			(isLabTechnician(this.props.account) || isLabAdmin(this.props.account)) &&
			isTaskManagerEnabled(this.props.modules)
		) {
			return true;
		}
		return false;
	};

	renderGanttchartSubHeader = () => {
		const { currentOrder } = this.props;

		if (!isOrderDraft(currentOrder) && this.allowGanttchart()) {
			return <SubHeader title={i18n.t('translation:taskManager.orders.workingTasks')} />;
		}
	};

	renderGanttchart = () => {
		const { currentOrder } = this.props;

		const orders = { ...this.props.orders };

		const clonedOrders = _.cloneDeep(orders);

		if (
			!isOrderDraft(currentOrder) &&
			!isEmptyObject(this.props.orders.orders) &&
			this.allowGanttchart()
		) {
			const chartData = parseOrderDetailsCalendarOrdersChart(orders.orders);
			const nestedTableData = parseOrderDetailsCalendarOrdersNestedTable(
				clonedOrders.orders,
				this.openQuickViewModal,
				this.openEditModal,
				this.props.account
			);
			const columns = [
				i18n.t('translation:taskManager.calendar.orders.table.itemTask'),
				i18n.t('translation:taskManager.common.table.start'),
				i18n.t('translation:taskManager.common.table.end'),
				i18n.t('translation:common.table.status'),
				i18n.t('translation:taskManager.common.table.assignee'),
				''
			];

			return (
				<div className="d-flex mt-s mb-fix-s-m">
					<Ganttchart
						workingHours={this.props.workingHours}
						isEditModalOpen={this.state.isEditModalOpen}
						closeEditModal={this.closeEditModal}
						currentTask={this.state.currentTask}
						currentOrderItem={this.state.currentOrderItem}
						order={this.state.order}
						orders={this.props.orders}
						filters={this.state.filters}
						isQuickViewModalOpen={this.state.isQuickViewModalOpen}
						closeQuickViewModal={this.closeQuickViewModal}
						dropdown={this.state.dropdown}
						chartData={chartData}
						nestedTableData={[...nestedTableData]}
						setDropdown={this.setDropdown}
						account={this.props.account}
						getCalendarOrders={this.props.getCalendarOrders}
						columns={columns}
						orderDetails={true}
					/>
				</div>
			);
		}
	};

	warnForDeactivatedDentistIfNeeded() {
		const order = this.props.currentOrder;
		const { account } = this.props;
		if (this.state.checkForDeactivatedDentistNotDoneYet) {
			// If the currently logged in user is from lab - showing warning if the dentist
			// in the order has disabled account. That is - if the clinic is not passive
			if (
				(isLabAdmin(account) || isLabTechnician(account)) &&
				!isPassiveClinic(getClinicFromOrder(order)) &&
				order.status !== ORDER_STATUS.COMPLETED &&
				order.status !== ORDER_STATUS.REJECTED &&
				getDentistFromOrder(order) &&
				getDentistFromOrder(order).status !== USER_STATUS.ACTIVE
			) {
				this.props.showMessageModal(
					i18n.t('translation:common.warning'),
					i18n.t('translation:orders.deactivatedDentistWarning')
				);
			}
			this.setState({ checkForDeactivatedDentistNotDoneYet: false });
		}
	}

	renderMainSection = () => {
		const { currentOrder } = this.props;

		const dentistFirstRow = [
			{
				title: i18n.t('translation:common.status'),
				value: (
					<div className="mt-xxs">
						<Status status={currentOrder.status} />
					</div>
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.LAB),
					{ icon: this.renderLabIcons() }
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.DENTIST)
				)
			},
			{
				value: (
					<div>
						{formUtils.renderOrderField(
							formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.PATIENT_NAME)
						)}
						{formUtils.renderOrderField(
							formUtils.getFieldByType(
								currentOrder.fields,
								ORDER_FIELDS.PATIENT_NAME_VISIBLE_TO_LAB
							),
							{ blueLabel: false }
						)}
					</div>
				)
			}
		];

		const confirmationButtons =
			this.props.currentOrder.status === ORDER_STATUS.OPENED
				? {
						title: i18n.t('translation:orders.fields.approvals'),
						value: (
							<div className="position-absolute">
								{Fields.orderFields.availableDentalTechnician()}
								{Fields.orderFields.availableMaterials()}
								{Fields.orderFields.clientPaysOnTime()}
							</div>
						)
				  }
				: {};
		const patientName = {
			value: formUtils.renderOrderField(
				formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.PATIENT_NAME)
			)
		};
		const dentalTechnicianLocalAdminFirstRow = [
			{
				title: i18n.t('translation:common.status'),
				value: (
					<div className="mt-xxs">
						<Status status={currentOrder.status} />
					</div>
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.CLINIC),
					{ icon: this.renderClinicIcons() }
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.LAB_TECHNICIAN),
					{
						options: this.getActiveLabUsers()
					}
				)
			},
			isOrderDraft(currentOrder) ? patientName : confirmationButtons
		];

		const globalAdminFirstRow = [
			{
				title: i18n.t('translation:common.status'),
				value: (
					<div className="mt-xxs">
						<Status status={currentOrder.status} />
					</div>
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.CLINIC),
					{
						label: i18n.t('translation:orders.fields.practice'),
						icon: this.renderClinicIcons()
					}
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.DENTIST)
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.LAB),
					{
						label: i18n.t('translation:orders.fields.lab'),
						icon: this.renderLabIcons()
					}
				)
			}
		];

		const dentalTechnicianUserFirstRow = [
			{
				title: i18n.t('translation:common.status'),
				value: (
					<div className="mt-xxs">
						<Status status={currentOrder.status} />
					</div>
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.CLINIC),
					{ icon: this.renderClinicIcons() }
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.LAB_TECHNICIAN),
					{
						options: this.getActiveLabUsers()
					}
				)
			},
			{}
		];

		const secondRow = [
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.CREATE_DATE)
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.DUE_DATE),
					{ className: 'd-block' }
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.DELIVERY_DATE)
				)
			},
			{}
		];

		const thirdRow = [
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.DELIVERY_LOCATION),
					{
						options: (function () {
							let locations = formUtils.getFieldByType(
								currentOrder.fields,
								ORDER_FIELDS.CLINIC
							).value.locations;
							locations = locations.map((location) => {
								return {
									name: `${location.name}: ${location.address}`,
									value: location.id
								};
							});
							locations.unshift({ name: '', value: '' });
							return locations;
						})()
					}
				)
			},
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.RETURN_LOCATION),
					{
						options: (function () {
							let locations = formUtils.getFieldByType(
								currentOrder.fields,
								ORDER_FIELDS.LAB
							).value.locations;
							locations = locations.map((location) => {
								return {
									name: `${location.name}: ${location.address}`,
									value: location.id
								};
							});
							locations.unshift({ name: '', value: '' });
							return locations;
						})()
					}
				)
			}
		];

		const lastRow = [
			{
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.NOTES)
				)
			}
		];

		if (
			(isLabTechnician(this.props.account) || isLabAdmin(this.props.account)) &&
			!isOrderDraft(this.props.currentOrder) &&
			formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.PATIENT_NAME_VISIBLE_TO_LAB)
				.value
		) {
			lastRow.push({
				value: formUtils.renderOrderField(
					formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.PATIENT_NAME)
				)
			});
		}

		const firstRow = registeredInClinic(this.props.account)
			? dentistFirstRow
			: isLabAdmin(this.props.account)
			? dentalTechnicianLocalAdminFirstRow
			: isLabTechnician(this.props.account)
			? dentalTechnicianUserFirstRow
			: globalAdminFirstRow;

		return (
			<div className="col-12 p-0 data-section">
				<div className="content">
					<DataRow data={firstRow} first blueLabel />
					<DataRow data={secondRow} blueLabel />
					<DataRow data={thirdRow} blueLabel />
					<DataRow data={lastRow} blueLabel />
				</div>
			</div>
		);
	};

	getActiveLabUsers = () => {
		let allUsers = this.props.staff.content;
		if (!isEmptyObject(allUsers)) {
			let activeUsers = allUsers.flatMap((user) =>
				user.status === USER_STATUS.ACTIVE ? { name: getName(user), value: user.id } : []
			);
			activeUsers.unshift({ name: '', value: '' });

			return activeUsers;
		}
	};

	renderFinancialData = () => {
		if (this.props.currentOrder.canAccessPaymentData) {
			return (
				<div className="row">
					<div className="col-9">{this.renderPaymentTerms()}</div>
					<div className="col-3">{this.renderPayment()}</div>
				</div>
			);
		}
	};

	renderInvoiceSection = () => {
		if (!isLabAdmin(this.props.account) && !isClinicAdmin(this.props.account)) {
			return null;
		}
		if (isOrderDraft(this.props.currentOrder)) {
			return null;
		}
		if (!isBillingModuleEnabled(this.props.modules)) {
			return null;
		}
		return (
			<Fragment>
				<SubHeader
					title={i18n.t('translation:orders.documents')}
					onTrigger={() =>
						this.setState((prevState) => {
							return {
								...prevState,
								isInvoiceSectionOpen: !this.state.isInvoiceSectionOpen
							};
						})
					}
					isOpen={this.state.isInvoiceSectionOpen}
					buttons={[this.renderIssueDocumentButton()]}
				/>
				{this.renderInvoiceDocuments()}
			</Fragment>
		);
	};

	renderTransactionSection = () => {
		if (!isLabAdmin(this.props.account)) {
			return null;
		}
		if (isOrderDraft(this.props.currentOrder)) {
			return null;
		}
		return (
			<Fragment>
				<IncomeOutcomeModal
					isOpen={this.state.transactionModal.isOpen}
					contentType={this.state.transactionModal.contentType}
					isCreation={false}
					number={this.state.transactionModal.contentNumber}
					onClose={this.onTransactionModalCloseHandler}
					contentId={this.state.transactionModal.contentId}
				/>

				<SubHeader
					title={i18n.t('translation:orders.incomesOutcomes')}
					onTrigger={() =>
						this.setState((prevState) => {
							return {
								...prevState,
								isTransactionSectionOpen: !this.state.isTransactionSectionOpen
							};
						})
					}
					isOpen={this.state.isTransactionSectionOpen}
				/>
				{this.renderTransactionDocuments()}
			</Fragment>
		);
	};

	renderTransactionDocuments = () => {
		if (this.state.isTransactionSectionOpen) {
			return (
				<IncomesOutcomesTable
					transactions={this.props.currentOrder.transactions}
					paginated={false}
					onActionButtonClick={this.onTransactionClickHandler}
				/>
			);
		}
		return null;
	};

	onTransactionClickHandler =
		({ modalIsCreation, modalContentType, modalContentId, modalContentNumber }) =>
		() => {
			this.setState((prevState) => {
				return {
					...prevState,
					transactionModal: {
						isOpen: true,
						contentType: modalContentType,
						contentNumber: modalContentNumber,
						contentId: modalContentId
					}
				};
			});
		};

	onTransactionModalCloseHandler = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				transactionModal: {
					isOpen: false,
					contentType: null,
					contentNumber: null,
					contentId: null
				}
			};
		});
	};

	renderInvoiceDocuments = () => {
		if (this.state.isInvoiceSectionOpen) {
			return (
				<DocumentsTable
					onConfirmDocumentSuccess={this.onBillingDocumentModalClose}
					onModalClose={this.onBillingDocumentModalClose}
					documents={this.props.currentOrder.documents}
				/>
			);
		}
		return null;
	};

	onBillingDocumentModalClose = () => {
		this.props.getOrderDetails(this.props.currentOrder.id);
	};

	getHeaderTitle = () => {
		const title =
			this.props.currentOrder.status === ORDER_STATUS.DRAFT
				? i18n.t('translation:orders.draftOrderNumber', {
						word1: this.props.currentOrder.labOrderId
				  })
				: i18n.t('translation:orders.orderNumber', {
						word1: this.props.currentOrder.labOrderId
				  });
		return title;
	};

	renderStatusButton = () => {
		let buttons = _.filter(this.props.currentOrder.possibleNextStatuses, function (button) {
			return button !== ORDER_STATUS.REJECTED && button !== ORDER_STATUS.CHANGES_NOT_ACCEPTED;
		});

		const labTechnician = formUtils.getFieldByType(
			this.props.currentOrder.fields,
			ORDER_FIELDS.LAB_TECHNICIAN
		);
		if (
			isLabTechnician(this.props.account) &&
			(!labTechnician.value || labTechnician.value.id !== this.props.account.id)
		) {
			buttons = [];
		}

		const title = parseStatus(this.props.currentOrder.status);
		return (
			<DropdownButton
				title={title}
				buttons={buttons}
				statusButton={true}
				tooltip={i18n.t('translation:orders.nextStatus')}
				onButtonClick={this.onStatusButtonClick}
			/>
		);
	};

	onStatusButtonClick = (status) => {
		if (this.props.invalid) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:common.errors.fillAllFields')
			);
		}

		if (isEmptyObject(this.props.currentOrder.orderItems)) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:orders.errors.noProducts')
			);
			return;
		}

		this.update(status);
	};

	renderNegativeButton = () => {
		let buttons = _.filter(this.props.currentOrder.possibleNextStatuses, function (button) {
			return button === ORDER_STATUS.REJECTED || button === ORDER_STATUS.CHANGES_NOT_ACCEPTED;
		});

		const onDeleteButtonPress = () => {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						this.props.deleteOrder(this.props.currentOrder.id);
						this.props.hideMessageModal();
					}}
					danger
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						this.props.hideMessageModal();
					}}
					secondary
				/>
			);
			this.props.showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:orders.deleteOrderWarning'),
				[yesButton, noButton]
			);
		};

		if (buttons.length) {
			return (
				<DefaultButton
					title={parseStatus(buttons[0])}
					tooltip={i18n.t('translation:orders.nextStatus')}
					onClick={() => {
						this.update(buttons[0]);
					}}
					danger
				/>
			);
		}

		if (this.props.currentOrder.canDeleteOrder) {
			return (
				<DefaultButton
					tooltip={i18n.t('translation:orders.nextStatus')}
					title={i18n.t('translation:common.buttons.delete')}
					onClick={onDeleteButtonPress}
					danger
				/>
			);
		}
	};

	renderPaymentTerms = () => {
		const { currentOrder, formValues } = this.props;
		const lab = getLabFromOrder(currentOrder);
		const customPropsForCurrency = lab
			? {
					currencyPrefix: lab.currencySymbol,
					currencySuffix: lab.localizedCurrencyAbbreviation,
					invoicingCurrency: true
			  }
			: {};

		if (formValues) {
			const priceIncrease = this.props.initialValues[_.camelCase(ORDER_FIELDS.PRICE_INCREASE)]
				? null
				: formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.PRICE_INCREASE).value;
			const taxType = isLabAdmin(this.props.account)
				? getSelectedTaxType(this.props.billingSettings.taxType)
				: i18n.t('translation:billingModule.common.tax').toLowerCase();
			return (
				<Fragment>
					<SubHeader title={i18n.t('translation:orders.paymentTerms')} />
					<div className="mt-s">
						{renderSimpleTable(
							[
								[
									<label
										key="translation:billingModule.common.taxBase"
										className="input-label">
										{i18n.t('translation:billingModule.common.taxBase')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.INITIAL_PRICE
										),
										customPropsForCurrency
									)
								],
								[
									<label
										key="translation:orders.fields.discount"
										className="input-label">
										{i18n.t('translation:orders.fields.discount')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.DISCOUNT
										),
										customPropsForCurrency
									)
								],
								[
									<label
										key="translation:orders.fields.priorityOrder"
										className="input-label">
										{i18n.t('translation:orders.fields.priorityOrder')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.IS_PRIORITY_ORDER
										),
										{
											className: '',
											disabled:
												formUtils.getFieldByType(
													currentOrder.fields,
													ORDER_FIELDS.PRICE_INCREASE
												).state === ORDER_FIELD_STATUSES.READ_ONLY
										}
									)
								],
								[
									<label
										key="translation:orders.fields.increase"
										className="input-label">
										{i18n.t('translation:orders.fields.increase')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.PRICE_INCREASE
										),
										{
											...customPropsForCurrency,
											value:
												!isEmptyObject(formValues) &&
												!formValues.isPriorityOrder
													? '0'
													: priceIncrease,
											disabled: !formValues.isPriorityOrder
										}
									)
								],
								[
									<label
										key="translation:orders.fields.calculatedTax"
										className="input-label">
										{i18n.t('translation:orders.fields.calculatedTax', {
											taxType
										})}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.TAX
										),
										customPropsForCurrency
									)
								],
								[
									<label
										key="translation:orders.fields.price"
										className="input-label">
										{i18n.t('translation:orders.fields.price')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.PRICE_WITH_TAX
										),
										customPropsForCurrency
									)
								],
								[
									<label
										key="translation:orders.fields.paymentDueDate"
										className="input-label">
										{i18n.t('translation:orders.fields.paymentDueDate')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.PAYMENT_DUE_DATE
										),
										{ className: '' }
									)
								],
								[
									<label
										key="translation:orders.fields.paymentTerms"
										className="input-label">
										{i18n.t('translation:orders.fields.paymentTerms')}
									</label>,
									formUtils.renderOrderField(
										formUtils.getFieldByType(
											currentOrder.fields,
											ORDER_FIELDS.PAYMENT_TERMS
										),
										{ className: '' }
									)
								]
							],
							'payment-terms'
						)}
					</div>
				</Fragment>
			);
		}
	};

	renderItemsSummarySection = () => {
		const teeth = [];
		this.props.currentOrder.orderItems.forEach((item) => {
			item.teeth.forEach((tooth) => teeth.push(tooth));
		});
		let firstOrderItemWithColor = getFirstOrderItemWithColor(
			this.props.currentOrder.orderItems
		);

		return (
			<div className="summary-section mt-s">
				<div className="align-items-center">
					<ColorSwitch
						initialValues={{
							colorSwitch: firstOrderItemWithColor
								? firstOrderItemWithColor[
										_.camelCase(ORDER_ITEM_FIELDS.COLOR_SCHEME)
								  ]
								: null
						}}
					/>
				</div>
				<div>
					<TeethMap readOnly={true} teeth={teeth} />
				</div>
				<div />
			</div>
		);
	};

	renderPayment = () => {
		const { currentOrder } = this.props;
		const lab = getLabFromOrder(currentOrder);
		const customPropsForCurrency = lab
			? {
					currencyPrefix: lab.currencySymbol,
					currencySuffix: lab.localizedCurrencyAbbreviation,
					invoicingCurrency: true
			  }
			: {};

		const markPaymentButton = currentOrder.canUpdatePaidAmount ? (
			<DefaultButton
				fullWidth
				title={i18n.t('translation:orders.buttons.markPayment')}
				onClick={() => {
					const price = +formUtils.getFieldByType(
						currentOrder.fields,
						ORDER_FIELDS.PRICE_WITH_TAX
					).value;

					const amountDue = +this.props.formValues.amountDue;

					if (+this.props.formValues.paidAmount === price) {
						const yesButton = (
							<DefaultButton
								tooltip={i18n.t('translation:orders.nextStatus')}
								title={i18n.t('translation:common.buttons.yes')}
								onClick={() => {
									this.props.updatePaidAmount(
										currentOrder.id,
										this.props.formValues.paidAmount || 0,
										true,
										price,
										amountDue
									);
									this.props.hideMessageModal();
								}}
							/>
						);
						const noButton = (
							<DefaultButton
								title={i18n.t('translation:common.buttons.no')}
								onClick={() => {
									this.props.hideMessageModal();
								}}
								secondary
							/>
						);
						this.props.showMessageModal(
							i18n.t('translation:common.confirmation'),
							i18n.t('translation:orders.paidAmountConfirmationWarning', {
								word1: parseStatus(ORDER_STATUS.COMPLETED)
							}),
							[yesButton, noButton]
						);
					} else {
						this.props.updatePaidAmount(
							currentOrder.id,
							this.props.formValues.paidAmount || 0,
							false,
							price,
							amountDue
						);
					}
				}}
			/>
		) : null;
		return (
			<Fragment>
				<SubHeader title={i18n.t('translation:orders.payment')} />
				<div className="mt-s">
					{renderSimpleTable(
						[
							[
								<label
									key="translation:orders.fields.total"
									className="input-label">
									{i18n.t('translation:orders.fields.total')}
								</label>,
								formUtils.renderOrderField(
									formUtils.getFieldByType(
										currentOrder.fields,
										ORDER_FIELDS.PRICE_WITH_TAX
									),
									customPropsForCurrency
								)
							],
							[
								<label
									key="translation:orders.fields.paidAmount"
									className="input-label">
									{i18n.t('translation:orders.fields.paidAmount')}
								</label>,
								formUtils.renderOrderField(
									formUtils.getFieldByType(
										currentOrder.fields,
										ORDER_FIELDS.PAID_AMOUNT
									),
									customPropsForCurrency
								)
							],
							[
								<label
									key="translation:orders.fields.amountDue"
									className="input-label">
									{i18n.t('translation:orders.fields.amountDue')}
								</label>,
								formUtils.renderOrderField(
									formUtils.getFieldByType(
										currentOrder.fields,
										ORDER_FIELDS.AMOUNT_DUE
									),
									customPropsForCurrency
								)
							],
							[
								<label
									key="translation:orders.fields.lastPaymentDate"
									className="input-label">
									{i18n.t('translation:orders.fields.lastPaymentDate')}
								</label>,
								formUtils.renderOrderField(
									formUtils.getFieldByType(
										currentOrder.fields,
										ORDER_FIELDS.LAST_PAYMENT_DATE
									),
									{ className: '' }
								)
							]
						],
						'paid-amount'
					)}
				</div>
				<div className="mt-s full-width">{markPaymentButton}</div>
			</Fragment>
		);
	};

	renderSaveButton = () => {
		const labTechnician = formUtils.getFieldByType(
			this.props.currentOrder.fields,
			ORDER_FIELDS.LAB_TECHNICIAN
		);

		if (
			!this.props.currentOrder.possibleNextStatuses.length ||
			(isLabTechnician(this.props.account) &&
				(!labTechnician.value || labTechnician.value.id !== this.props.account.id))
		) {
			return (
				<DefaultButton
					secondary
					title={i18n.t('translation:orders.buttons.save')}
					secondaryDisabled
				/>
			);
		}

		return (
			<DefaultButton
				secondary
				title={i18n.t('translation:orders.buttons.save')}
				onClick={this.onSaveButtonClick}
			/>
		);
	};

	renderIssueDocumentButton() {
		if (!this.props.currentOrder.canCreateBillingDocuments) {
			return null;
		}
		const { labOrderId, fields, id, status, documents } = this.props.currentOrder;

		const clinicField = fields.find((field) => field.type === ORDER_FIELDS.CLINIC);
		const orderPrice = fields.find((field) => field.type === ORDER_FIELDS.PRICE)?.value;
		const invoicedAmount = this.props.currentOrder.invoicedAmount ?? 0;
		const orderHasLeftForInvoice = subtract(orderPrice, invoicedAmount) > 0;

		const hasIssuedInvoice =
			documents.findIndex(
				(document) =>
					(document.type === BILLING_DOCUMENT_TYPES.ORDER_INVOICE &&
						document.status === BILLING_DOCUMENT_STATUS.OPENED) ||
					document.status === BILLING_DOCUMENT_STATUS.PAID ||
					document.status === BILLING_DOCUMENT_STATUS.PAID_PARTIALLY
			) !== -1;

		return (
			<div className={'d-flex justify-content-end'}>
				<div style={{ position: 'relative' }} className={'button-ml-xs'}>
					<IssueDocumentButton
						orderId={id}
						labOrderId={labOrderId}
						clinicId={clinicField?.value?.id}
						clinicLegalName={clinicField?.value?.legalName}
						hasIssuedInvoice={hasIssuedInvoice}
						orderHasLeftForInvoice={orderHasLeftForInvoice}
					/>
				</div>
			</div>
		);
	}

	onSaveButtonClick = () => {
		if (this.props.invalid && !isOrderDraft(this.props.currentOrder)) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:common.errors.fillAllFields')
			);
		}
		this.update(this.props.currentOrder.status);
	};

	isSaveButtonPress = (status) => {
		return status === this.props.currentOrder.status;
	};

	update = (status) => {
		this.props.handleSubmit((formValues) => {
			const fields = this.props.currentOrder.fields;
			const editableFields = formUtils.getEditableFields(fields);
			if (status !== ORDER_STATUS.REJECTED) {
				let errors = {};
				if (this.isSaveButtonPress(status)) {
					errors = formUtils.getErrorsOnOrderSave();
				} else {
					errors = formUtils.getErrorsOnOrderChangeStatus(status);
				}
				if (!isEmptyObject(errors)) {
					this.props.showMessageModal(
						i18n.t('translation:common.errors.error'),
						i18n.t('translation:common.errors.fillAllFields')
					);
					throw new SubmissionError({
						...errors,
						_error: 'error'
					});
				}
				this.props.clearSubmitErrors('orderDetailsForm');
			}

			const originalOrder = this.props.currentOrder;
			this.props.updateOrder(originalOrder.id, originalOrder.status, {
				status: status,
				fields: getRefinedOrderFields(formValues, editableFields)
			});
		})();
	};

	renderTabs = () => {
		if (!isOrderDraft(this.props.currentOrder)) {
			const tabs = this.props.currentOrder.canAccessComments
				? [
						{
							title: i18n.t('translation:orders.comments'),
							icon: faComments,
							content: <Comments />
						},
						{
							title: i18n.t('translation:orders.history'),
							icon: faHistory,
							content: <History />
						}
				  ]
				: [
						{
							title: i18n.t('translation:orders.history'),
							icon: faHistory,
							content: <History />
						}
				  ];
			return (
				<div className="mt-m">
					<Tab tabs={tabs} />
				</div>
			);
		}
	};

	renderClinicIcons = () => {
		const { currentOrder, account } = this.props;
		const clinic = getClinicFromOrder(currentOrder);
		const passiveClinicIndicator = isPassiveClinic(clinic) ? (
			<FontAwesomeIcon
				className="passive-clinic-icon"
				title={i18n.t('translation:clinics.passiveClinic')}
				icon={faLaptop}
			/>
		) : null;
		const deactivatedClinicIndicator = isCompanyDeactivated(clinic) ? (
			<FontAwesomeIcon
				className="deactivated-icon"
				title={i18n.t('translation:clinics.deactivatedClinic')}
				icon={faBan}
			/>
		) : null;

		function getPaymentStatusIcon() {
			if (isLabAdmin(account) || isGlobalAdmin(account)) {
				const clientPaymentStatus = currentOrder.clinicPaymentStatus;
				if (clientPaymentStatus) {
					const iconColor =
						clientPaymentStatus === CLIENT_PAYMENT_STATUS.HAVE_DEBTS
							? 'red'
							: clientPaymentStatus === CLIENT_PAYMENT_STATUS.NO_DEBTS
							? 'green'
							: 'gray';
					const title =
						clientPaymentStatus === CLIENT_PAYMENT_STATUS.HAVE_DEBTS
							? i18n.t('translation:orders.haveOrdersWaitingPayment')
							: clientPaymentStatus === CLIENT_PAYMENT_STATUS.NO_DEBTS
							? i18n.t('translation:orders.noDebts')
							: i18n.t('translation:orders.noOrdersWaitingPayment');
					return (
						<div title={title} className={'payment-status-icon mr-xxs ' + iconColor}>
							<FontAwesomeIcon icon={faDollarSign} />
						</div>
					);
				}
			}
		}

		return (
			<div className="d-flex align-items-center ml-xxs">
				{getPaymentStatusIcon()}
				{passiveClinicIndicator}
				{deactivatedClinicIndicator}
			</div>
		);
	};

	renderLabIcons = () => {
		const lab = getLabFromOrder(this.props.currentOrder);
		const deactivatedLabIndicator = isCompanyDeactivated(lab) ? (
			<FontAwesomeIcon
				className="deactivated-icon"
				title={i18n.t('translation:labs.deactivatedLab')}
				icon={faBan}
			/>
		) : null;

		return <div className="d-flex align-items-center ml-xxs">{deactivatedLabIndicator}</div>;
	};

	onGlobalDropdownClick = (onDropdownClick) => {
		onDropdownClick();
	};

	setDropdown = (elementIndex, isExpanded, data) => {
		const ganttchart = document.getElementsByClassName('gantchart')[0];
		const ganttchartHeigh = ganttchart ? ganttchart.clientHeight : 0;
		this.setState({
			dropdown: getDropdownData(
				elementIndex,
				this.state.dropdown,
				isExpanded,
				ganttchartHeigh
			)
		});
	};

	closeQuickViewModal = () => {
		this.setState({ isQuickViewModalOpen: false });
	};

	closeEditModal = () => {
		this.setState({ isEditModalOpen: false });
	};

	openQuickViewModal = (order) => {
		this.setState({ isQuickViewModalOpen: true, order });
	};

	openEditModal = (currentTask, currentOrderItem, order) => {
		this.setState({ isEditModalOpen: true, currentTask, order, currentOrderItem });
	};
}

const orderDetailsForm = reduxForm({
	form: 'orderDetailsForm',
	validate: formUtils.validateOrderDetails,
	enableReinitialize: true,
	destroyOnUnmount: true,
	onSubmit: formUtils.remoteSubmits.onDraftOrderSubmit
})(OrderDetails);

const mapStateToProps = (state) => {
	return {
		currentOrder: state.orders.currentOrder,
		account: state.account,
		initialValues: getInitialValues(state),
		settings: state.settings,
		staff: state.users.users,
		fields: state.orders.currentOrder.fields,
		formValues: getFormValues('orderDetailsForm')(state),
		workingHours: state.workingHours,
		orders: state.calendar.orders,
		modules: state.modules,
		filters: getFormValues('calendarOrdersFiltersForm')(state) || {},
		billingSettings: state.billingSettings
	};
};

const getInitialValues = (state) => {
	let formValues = getFormValues('orderDetailsForm')(state);
	const order = Object.assign(state.orders.currentOrder);

	// NOTE 1: We are removing the "amount due" field only for 'Draft' and 'Information Required' statuses -
	// the only cases when we can freely add/remove/update order items. For the other statuses it is
	// important that we have the value in the "form values" collection in order to update it correctly
	// when any amount is being paid
	// NOTE 2: Excluding the "amount due" value in status "Completed" as well because of bug related
	// to showing the old amount due immediately after paying in full the order in status "Seated"
	const includeAmountDue =
		order.status === ORDER_STATUS.DRAFT ||
		order.status === ORDER_STATUS.INFORMATION_REQUIRED ||
		order.status === ORDER_STATUS.COMPLETED;

	let nonReadOnlyUiValuesWithoutPaidAmount = _.omit(
		{ ...formValues },
		formUtils.getReadOnlyFields(order.fields, includeAmountDue)
	);

	// The following code is commented because of incorrect initialization of the checkbox "isPriorityOrder" in case we
	// have price increase. It fix the bug from "[P2-I6] Issues to fix during the iteration".

	// The logic below fixes bug with incorrect 'isPriorityOrder' checkbox behavior
	// let isPriorityOrder = (!isEmptyObject(formValues)) ? formValues.isPriorityOrder : null;
	// if (isPriorityOrder == null) {
	//     const field = formUtils.getFieldByType(state.orders.currentOrder.fields, ORDER_FIELDS.IS_PRIORITY_ORDER);
	//     console.log("isPriorityOrder", field, state.orders)
	//     if (field) {
	//         isPriorityOrder = field.value;
	//     }
	//     else {
	//         isPriorityOrder = false;
	//     }
	// }

	// if (nonReadOnlyUiValuesWithoutPaidAmount.hasOwnProperty(_.camelCase(ORDER_FIELDS.PRICE_INCREASE))) {
	//     return { ...getOrderInitialValues(order), ...nonReadOnlyUiValuesWithoutPaidAmount, isPriorityOrder };
	// } else {
	//     return { isPriorityOrder, ...getOrderInitialValues(order), ...nonReadOnlyUiValuesWithoutPaidAmount };
	// }

	return { ...getOrderInitialValues(order), ...nonReadOnlyUiValuesWithoutPaidAmount };
};

export default connect(mapStateToProps, {
	getOrderDetails,
	clearCurrentOrder,
	updateOrder,
	showMessageModal,
	getLabStaff,
	deleteOrder,
	updatePaidAmount,
	hideMessageModal,
	clearSubmitErrors,
	getSettingFromServer,
	getGeneralWorkingHours,
	getCalendarOrderItems,
	getAllCalendarOrderItems,
	getBillingSettings
})(orderDetailsForm);
