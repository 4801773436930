import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { formUtils } from '../../../../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../../../../common/Fields';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import DataRow from '../../../../common/DataRow';
import { getLabId, getTasksStatuses } from '../../../../../utils/appUtils';
import { getLabApprovedStaff, setPagination } from '../../../../../actions';

class Filters extends React.Component {
	state = { isFilterCollapsed: true };

	componentDidMount() {
		const labId = getLabId(this.props.location, this.props.account);
		this.props.getLabApprovedStaff(labId);
	}

	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters pt-0">
					<form onSubmit={this.onFilterButtonClick}>
						{this.renderFirstFilterRow()}
						{this.renderSecondFilterRow()}
					</form>
				</div>
			</div>
		);
	}

	renderFirstFilterRow = () => {
		const performers = [];
		const users = this.props.users || [];
		for (let performer of users) {
			performers.push({
				name: performer.firstName + ' ' + performer.lastName,
				id: performer.id
			});
		}

		return (
			<div className="row m-0">
				<div className="mt-s pl-s col-xl-3 col-lg-4 col-4">
					{Fields.filterFields.labTechnicianList({
						className: 'mr-m',
						options: performers
					})}
				</div>

				<div className="mt-s pl-s col-xl-3 col-lg-4 col-4">
					{Fields.filterFields.taskStatusStaff({
						className: 'mr-m',
						options: getTasksStatuses()
					})}
				</div>

				<div className="col-xl-3 col-lg-7 col-7 mt-xl-0  mt-lg-3 mt-3">
					<DataRow
						data={[
							{
								title: i18n.t(
									'translation:taskManager.common.fields.filters.taskDate'
								),
								value: this.renderIntervalFields(
									Fields.filterFields.dateFrom({ className: 'mr-s d-block' }),
									Fields.filterFields.dateTo({ className: 'mr-s d-block' })
								)
							}
						]}
						noColumns
					/>
				</div>

				<div className="filter-button mr-s">
					<DefaultButton
						title={i18n.t('translation:orders.buttons.filter')}
						onClick={this.onFilterButtonClick}
					/>
					{this.renderTrigger()}
				</div>
			</div>
		);
	};

	renderSecondFilterRow = () => {
		if (!this.state.isFilterCollapsed) {
			return (
				<div className="row m-0">
					<div className="mt-s pl-s col-xl-3 col-lg-4 col-4">
						{Fields.filterFields.taskName({ className: 'mr-m' })}
					</div>
				</div>
			);
		}
	};

	collapseExpandFilter = () => {
		this.setState({ isFilterCollapsed: !this.state.isFilterCollapsed });
	};

	renderTrigger = () => {
		return !this.state.isFilterCollapsed ? (
			<span
				className="arrow-up arrow-up-sub-header ml-xs"
				onClick={this.collapseExpandFilter}
			/>
		) : (
			<span
				className="arrow-down arrow-down-sub-header ml-xs"
				onClick={this.collapseExpandFilter}
			/>
		);
	};

	onFilterButtonClick = (e) => {
		e.preventDefault();
		this.props.handleSubmit((formValues) => {
			this.props.setFilters(formValues);
		})();
	};

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};
}

const filtersForm = reduxForm({
	form: 'calendarStaffFiltersForm',
	validate: formUtils.validateStaffFilters,
	destroyOnUnmount: true
})(Filters);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		staff: state.staff,
		users: state.users.users.content,
		formValues: getFormValues('calendarStaffFiltersForm')(state)
	};
};

export default connect(mapStateToProps, { setPagination, getLabApprovedStaff })(filtersForm);
