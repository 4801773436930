import React, { Component, Fragment } from 'react';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import DocumentsFilters from './DocumentsFilters';
import DocumentsTable from './DocumentsTable';
import IssueDocumentButton from '../common/issueDocumentButton/IssueDocumentButton';
import ExportDocumentButton from './ExportDocumentButton';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import {
	clearCurrentDocument,
	getBillingDocuments,
	getBillingSettings,
	setPagination
} from '../../../../actions';
import { isBillingModuleEnabled, isEmptyObject, isLabAdmin } from '../../../../utils/appUtils';
import { SEARCH_BILLING_DOCUMENTS } from '../../../../actions/types';
import { BILLING_DOCUMENT_STATUS, PAGINATION_TYPES } from '../../../../utils/constants';

class Documents extends Component {
	componentDidMount() {
		this.props.getBillingDocuments({
			page: this.props.page || 1,
			filters: { ...this.props.formValues },
			user: this.props.account,
			actionType: SEARCH_BILLING_DOCUMENTS
		});

		const isLabLocalAdmin = isLabAdmin(this.props.account);
		if (isLabLocalAdmin) {
			this.props.getBillingSettings(this.props.account.labId);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.page &&
			this.props.page !== 1 &&
			isEmptyObject(this.props.documents.content)
		) {
			this.props.setPagination(PAGINATION_TYPES.BILLING_DOCUMENTS, this.props.page - 1);
			return;
		}

		if (prevProps.page !== this.props.page) {
			this.props.getBillingDocuments({
				page: this.props.page || 1,
				filters: { ...this.props.formValues },
				user: this.props.account,
				actionType: SEARCH_BILLING_DOCUMENTS
			});
		}
	}

	setFilters = (filters) => {
		this.setState({ filters });
	};

	onConfirmDocumentSuccess = () => {
		this.props.getBillingDocuments({
			page: this.props.page || 1,
			filters: { ...this.props.formValues },
			user: this.props.account,
			actionType: SEARCH_BILLING_DOCUMENTS
		});
	};

	onFiltersSubmit = () => {
		this.props.getBillingDocuments({
			page: this.props.page || 1,
			filters: { ...this.props.formValues },
			user: this.props.account,
			actionType: SEARCH_BILLING_DOCUMENTS
		});
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.BILLING_DOCUMENTS, page);
	};

	onModalClose = () => {
		this.props.clearCurrentDocument();
	};

	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:billingModule.documents.header')}
					buttons={[this.renderIssueDocumentButton(), this.renderExportDocumentButton()]}
				/>
				<DocumentsFilters onSubmit={this.onFiltersSubmit} />
				<DocumentsTable
					onConfirmDocumentSuccess={this.onConfirmDocumentSuccess}
					page={this.props.page}
					setCurrentPage={(page) => this.setCurrentPage(page)}
					onModalClose={this.onModalClose}
					paginated={true}
					documents={this.props.documents.content}
					totalElements={this.props.documents.totalElements}
					onDocumentChange={this.onDocumentChange}
				/>
			</Fragment>
		);
	}

	onDocumentChange = () => {
		this.props.getBillingDocuments({
			page: this.props.page ?? 1,
			filters: this.props.formValues,
			user: this.props.account,
			actionType: SEARCH_BILLING_DOCUMENTS
		});
	};

	renderIssueDocumentButton = () => {
		if (!isBillingModuleEnabled(this.props.modules)) {
			return null;
		}
		return (
			<IssueDocumentButton
				previousScreenFilters={this.props.formValues}
				onDocumentChange={this.onDocumentChange}
			/>
		);
	};

	renderExportDocumentButton = () => {
		return <ExportDocumentButton />;
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		page: state.pagination[PAGINATION_TYPES.BILLING_DOCUMENTS],
		documents: state.billingDocument.documents,
		formValues: getFormValues('billingDocumentFiltersForm')(state),
		modules: state.modules
	};
};

export default connect(mapStateToProps, {
	getBillingDocuments,
	setPagination,
	clearCurrentDocument,
	getBillingSettings
})(Documents);
