import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import { parseSuppliers } from '../../../utils/tableUtils';
import SendUserInvitationModal from '../links/SendUserInvitationModal';
import SendLinkInvitationModal from '../links/SendLinkInvitationModal';
import Table from '../../common/Table';
import DefaultButton from '../../common/DefaultButton';
import { LANGUAGE_CODES, PAGINATION_TYPES } from '../../../utils/constants';
import Pagination from '../../common/Pagination';
import {
	getLanguageCode,
	isClinicAdmin,
	isDentistUser,
	isEmptyObject
} from '../../../utils/appUtils';
import { clearReducers, getLinkedLabs, setPagination } from '../../../actions';

class Suppliers extends React.Component {
	state = {
		isUserInvitationModalOpen: false,
		isLinkInvitationModalOpen: false
	};

	componentDidMount() {
		const id = this.props.account.clinicId;

		this.props.getLinkedLabs(id, this.props.page || 1);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			const id = this.props.account.clinicId;
			this.props.getLinkedLabs(id, this.props.page);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.labs.content)
			) {
				this.props.setPagination(PAGINATION_TYPES.LABS, this.props.page - 1);
			}
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<SendUserInvitationModal
					initialEmail={this.props.signUpUserEmail}
					isInvitationToDentist={false}
					isModalOpen={this.state.isUserInvitationModalOpen}
					closeModal={this.toggleUserInvitationModalVisibility}
				/>

				<SendLinkInvitationModal
					organizationId={this.props.account.clinicId}
					isInvitationToClinic={false}
					isModalOpen={this.state.isLinkInvitationModalOpen}
					closeModal={this.toggleLinkInvitationModalVisibility}
					showUserInvitationModal={this.toggleUserInvitationModalVisibility}
				/>

				<Header
					title={i18n.t('translation:labs.suppliers')}
					buttons={[
						this.renderInviteLabTechnicianButton(),
						this.renderInviteClientButton()
					]}
				/>
				<div className="col-12 p-0">
					<hr />
					{this.renderSuppliers()}
					{this.renderPagination()}
				</div>
			</Fragment>
		);
	}

	renderInviteLabTechnicianButton = () => {
		if (isClinicAdmin(this.props.account)) {
			const title = i18n.t('translation:labs.buttons.inviteLabTechnician');
			const onClickCallback = () => {
				this.setState({ isUserInvitationModalOpen: true });
			};

			return getLanguageCode() === LANGUAGE_CODES.DE ||
				getLanguageCode() === LANGUAGE_CODES.TR ? (
				<DefaultButton title={title} onClick={onClickCallback} extraWide />
			) : (
				<DefaultButton title={title} onClick={onClickCallback} />
			);
		} else {
			return null;
		}
	};

	renderInviteClientButton = () => {
		if (isClinicAdmin(this.props.account)) {
			const title = i18n.t('translation:labs.buttons.inviteSupplier');
			const onClickCallback = () => {
				this.setState({ isLinkInvitationModalOpen: true });
			};

			return getLanguageCode() === LANGUAGE_CODES.DE ||
				getLanguageCode() === LANGUAGE_CODES.TR ? (
				<DefaultButton title={title} onClick={onClickCallback} extraWide />
			) : (
				<DefaultButton title={title} onClick={onClickCallback} />
			);
		} else {
			return null;
		}
	};

	toggleUserInvitationModalVisibility = () => {
		this.setState({
			isUserInvitationModalOpen: !this.state.isUserInvitationModalOpen
		});
	};

	toggleLinkInvitationModalVisibility = () => {
		this.setState({
			isLinkInvitationModalOpen: !this.state.isLinkInvitationModalOpen
		});
	};

	renderSuppliers = () => {
		const suppliers =
			!isEmptyObject(this.props.labs.content) && this.props.labs.content[0].id
				? parseSuppliers(this.props.labs.content)
				: [];
		const columns = [
			i18n.t('translation:common.table.name'),
			i18n.t('translation:common.table.phone'),
			i18n.t('translation:common.table.email'),
			i18n.t('translation:common.table.address')
		];
		if (!isDentistUser(this.props.account)) {
			columns.push(i18n.t('translation:common.table.discount'));
		}

		columns.push(i18n.t('translation:common.table.action'));
		return <Table columns={columns} data={suppliers} />;
	};

	renderPagination = () => {
		const { labs } = this.props;
		if (labs) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={labs.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.LABS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		labs: state.labs.labs,
		page: state.pagination[PAGINATION_TYPES.LABS],
		account: state.account,
		signUpUserEmail: state.invitations.signUpUserEmail
	};
};

export default connect(mapStateToProps, {
	getLinkedLabs,
	setPagination,
	clearReducers
})(Suppliers);
