import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { LOADER_TYPES } from '../../utils/constants';

class SpinnerModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	render() {
		return (
			<Modal
				isOpen={this.props.loader || this.props.ganttchartLoader}
				className="custom-modal spinner-modal"
				overlayClassName="custom-overlay"
				portalClassName={'loader'}>
				<Loader type="BallTriangle" color="white" height={100} width={100} />
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loader: state.loader[LOADER_TYPES.GLOBAL_LOADER],
		ganttchartLoader: state.loader[LOADER_TYPES.GANTTCHART_LOADER]
	};
};

export default connect(mapStateToProps)(SpinnerModal);
