import React from 'react';
import { connect } from 'react-redux';
import {
	clearReducers,
	getLabDetails,
	getLabInventoryOnly,
	getLabInventoryWithAllProducts,
	getTaxGroups
} from '../../../actions';
import { isLabAdmin, isLabTechnician } from '../../../utils/appUtils';
import UserInventory from './UserInventory';

class LabInventory extends React.Component {
	constructor(props) {
		super(props);

		const { account } = this.props;
		const { labId } = account;

		if (isLabAdmin(account)) {
			this.props.getLabInventoryWithAllProducts(labId);
			this.props.getLabDetails(labId);
			this.props.getTaxGroups(labId);
		} else if (isLabTechnician(account)) {
			this.props.getLabInventoryOnly(labId);
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return <UserInventory inventory={this.props.inventory} taxGroups={this.props.taxGroups} />;
	}
}

const mapStateToProps = (state) => {
	return {
		inventory: state.inventory.labInventory,
		account: state.account,
		modules: state.modules,
		taxGroups: state.billingSettings.taxGroups
	};
};

export default connect(mapStateToProps, {
	getLabInventoryWithAllProducts,
	getLabInventoryOnly,
	getLabDetails,
	getTaxGroups,
	clearReducers
})(LabInventory);
