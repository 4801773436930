import { getAxiosWithToken } from '../utils/webApi';
import { getLinkedClinics } from './labsActions';
import { getLinkedLabs } from './clinicsActions';
import navigationUtils from '../utils/navigationUtils';
import { isPracticeRoute, showSuccessPopup } from '../utils/appUtils';
import { PAGINATION_TYPES } from '../utils/constants';
import i18n from '../i18n';
import { CLEAR_CURRENT_ORDER, CLEAR_REDUCER, SET_COMMON_REDUCER } from './types';

export const addLink = (clinicId, labId) => async (dispatch, getState) => {
	const response = await getAxiosWithToken().post(
		'/link',
		{},
		{
			params: {
				'clinic-id': clinicId,
				'lab-id': labId
			}
		}
	);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulAddedLink'));
		if (isPracticeRoute(navigationUtils.getRoute())) {
			dispatch(
				getLinkedLabs(clinicId, getState().pagination[PAGINATION_TYPES.LINKED_LABS] || 1)
			);
		} else {
			dispatch(
				getLinkedClinics(labId, getState().pagination[PAGINATION_TYPES.LINKED_CLINICS] || 1)
			);
		}
	}
};

export const deleteLink = (id) => async (dispatch, getState) => {
	const response = await getAxiosWithToken().delete(`/link/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulDeletedLink'));
		const companyId = navigationUtils.getRoute().split('/').pop();
		if (isPracticeRoute(navigationUtils.getRoute())) {
			dispatch(
				getLinkedLabs(companyId, getState().pagination[PAGINATION_TYPES.LINKED_LABS] || 1)
			);
		} else {
			dispatch(
				getLinkedClinics(
					companyId,
					getState().pagination[PAGINATION_TYPES.LINKED_CLINICS] || 1
				)
			);
		}
	}
};

export const clearCurrentOrder = () => {
	return { type: CLEAR_CURRENT_ORDER };
};

export const clearReducers = () => {
	return { type: CLEAR_REDUCER };
};

export const setCommonReducer = (data) => {
	return { type: SET_COMMON_REDUCER, payload: data };
};

export const createExcelFileUrl = (data) => {
	return window.URL.createObjectURL(new Blob([data]), {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	});
};

export const createCsvFileUrl = (data) => {
	return window.URL.createObjectURL(new Blob([data]), {
		type: 'text/csv;charset=utf-8;'
	});
};

export const createPdfFileUrl = (data) => {
	return window.URL.createObjectURL(new Blob([data]), {
		type: 'application/pdf'
	});
};

export const getFileName = (account, fileName, extension) => {
	const companyName = account.clinicName || account.labName;
	const uniqueFileName = `${fileName}_${Date.now()}`;

	if (companyName) {
		return `${uniqueFileName}_${companyName}.${extension}`;
	}

	return `${uniqueFileName}.${extension}`;
};
