import React from 'react';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import IncomesDetailsFilters from './IncomesDetailsFilters';
import IncomesDetailsTable from './IncomesDetailsTable';
import { getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
	cancelTransaction,
	createTransaction,
	getBillingSettings,
	getLabDetails,
	getTransaction,
	hideMessageModal,
	setCurrentDebitDocuments,
	showMessageModal,
	updateTransaction
} from '../../../../../actions';
import {
	TRANSACTION_FORM_FIELD,
	TRANSACTION_STATUS,
	TRANSACTION_TYPE
} from '../../../../../utils/constants';
import { isEmptyObject, showSuccessPopup } from '../../../../../utils/appUtils';
import moment from '../../../../../utils/moment';
import { add, subtract } from '../../invoicing/billingDocumentModal/utils/documentUtils';
import { renderTransactionStatus } from '../../utils';

class IncomeDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
		this.props.getLabDetails(this.props.account.labId);
		this.props.getBillingSettings(this.props.account.labId);
		if (!this.isCreation()) {
			const incomeId = this.props.incomeId;
			const transaction = await getTransaction(incomeId);

			if (transaction.status === TRANSACTION_STATUS.COMPLETED) {
				this.setState({ ...transaction, reminder: 0 });
				return;
			}

			if (
				transaction.status === TRANSACTION_STATUS.PARTIALLY ||
				transaction.status === TRANSACTION_STATUS.CANCELED
			) {
				let documentsAmount = 0;
				for (let document of transaction.billingDocuments) {
					documentsAmount = add(documentsAmount, document.amount);
				}
				const reminder = subtract(transaction.amount, documentsAmount);
				this.setState({ ...transaction, reminder });
				return;
			}
			if (transaction.status === TRANSACTION_STATUS.PENDING) {
				this.setState({ ...transaction, reminder: transaction.amount });
				return;
			}
		}
	}

	componentWillUnmount() {
		this.setState({});
		this.props.setCurrentDebitDocuments([]);
	}

	isCreation() {
		return this.props.isCreation;
	}

	renderSaveButton() {
		if (
			!this.isCreation() &&
			(!this.state.status ||
				this.state.status === TRANSACTION_STATUS.COMPLETED ||
				this.state.status === TRANSACTION_STATUS.CANCELED)
		) {
			return null;
		}
		return (
			<div className={'ml-3'}>
				<DefaultButton
					title={i18n.t('translation:orders.buttons.save')}
					onClick={(e) => this.onSaveButtonClick(e)}
				/>
			</div>
		);
	}

	renderCancelButton() {
		if (
			this.isCreation() ||
			!this.state.status ||
			this.state.status === TRANSACTION_STATUS.CANCELED
		) {
			return null;
		}
		return (
			<DefaultButton
				title={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.incomeDetails.buttons.cancelIncome'
				)}
				danger
				onClick={(e) => this.onCancelButtonClick(e)}
			/>
		);
	}

	onCancelButtonClick(e) {
		e.preventDefault();
		const incomeId = this.state.id;
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					cancelTransaction(incomeId)
						.then(() => {
							this.createUpdateDebitTransactionCallback(
								i18n.t(
									'translation:billingModule.incomeOutcomeDetails.successMessages.cancelIncome'
								)
							);
							this.props.hideMessageModal();
						})
						.catch((e) => this.props.hideMessageModal());
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);
		this.props.showMessageModal(
			i18n.t('translation:common.warning'),
			i18n.t('translation:billingModule.incomeOutcomeDetails.incomeDetails.cancelIncome'),
			[yesButton, noButton],
			false,
			true
		);
	}

	onSaveButtonClick(e) {
		const {
			TRANSACTION_REFERENCES_NOTES,
			TRANSACTION_AMOUNT,
			TRANSACTION_DATE,
			TRANSACTION_METHOD,
			TRANSACTION_CLINIC_ID,
			DOCUMENTS_IDS,
			DOCUMENT_AMOUNT_,
			TRANSACTION_COMMON_NOTES
		} = TRANSACTION_FORM_FIELD;

		const isCreation = this.isCreation();

		if (this.props.invalid) {
			const errors = this.props.errors;

			if (errors[DOCUMENTS_IDS]) {
				this.props.showMessageModal(
					i18n.t('translation:common.errors.error'),
					i18n.t(
						'translation:billingModule.incomeOutcomeDetails.errors.documentAmountError'
					),
					[],
					false,
					true
				);
			}

			return;
		}

		//it is important this to stay here!
		//otherwise will submit the form twice if valid
		e.preventDefault();

		const formValues = this.props.formValues;
		const transactionMethod = formValues[TRANSACTION_METHOD];
		const transactionDate = moment(formValues[TRANSACTION_DATE]).format('YYYY-MM-DD');
		const amount = formValues[TRANSACTION_AMOUNT];
		const referenceNotes = formValues[TRANSACTION_REFERENCES_NOTES];
		const commonNotes = formValues[TRANSACTION_COMMON_NOTES];

		const clinicId = formValues[TRANSACTION_CLINIC_ID];
		const labId = this.props.account.labId;

		const billingDocuments = [];
		const documentsIds = formValues[DOCUMENTS_IDS];

		for (let id of documentsIds) {
			const amount = formValues[`${DOCUMENT_AMOUNT_}${id}`];
			if (amount) {
				billingDocuments.push({
					amount: formValues[`${DOCUMENT_AMOUNT_}${id}`],
					billingDocumentId: id
				});
			}
		}

		if (!this.isCreation()) {
			for (let document of this.state.billingDocuments) {
				const amount = document.amount;
				const id = document.id;
				const billingDocumentId = document.billingDocument.id;
				billingDocuments.push({
					id,
					amount,
					billingDocumentId
				});
			}
		}

		if (isCreation) {
			createTransaction({
				transactionDate,
				transactionMethod,
				billingDocuments,
				amount,
				clinicId,
				referenceNotes,
				labId,
				commonNotes,
				type: TRANSACTION_TYPE.DEBIT
			}).then(() => {
				this.props.onDocumentChange && this.props.onDocumentChange();
				this.createUpdateDebitTransactionCallback(
					i18n.t(
						'translation:billingModule.incomeOutcomeDetails.successMessages.createIncome'
					)
				);
			});
		} else {
			updateTransaction({
				id: this.state.id,
				billingDocuments,
				transactionDate,
				transactionMethod,
				amount,
				clinicId,
				referenceNotes,
				labId,
				commonNotes,
				type: TRANSACTION_TYPE.DEBIT
			}).then(() => {
				this.props.onDocumentChange && this.props.onDocumentChange();
				this.createUpdateDebitTransactionCallback(
					i18n.t(
						'translation:billingModule.incomeOutcomeDetails.successMessages.updateIncome'
					)
				);
			});
		}
	}

	createUpdateDebitTransactionCallback(message) {
		showSuccessPopup(message);

		this.props.onClose();
	}

	render() {
		return (
			<form
				onSubmit={this.props.handleSubmit(() => this.onSaveButtonClick())}
				className={'pr-4'}>
				<div className={'d-flex flex-row'}>
					<div className={'d-flex flex-row flex-fill justify-content-start'}>
						{this.state.status && renderTransactionStatus(this.state.status)}
					</div>
					<div className={'d-flex flex-row flex-fill justify-content-end'}>
						{this.renderCancelButton()}
						{this.renderSaveButton()}
					</div>
				</div>

				<IncomesDetailsFilters
					isCreation={this.isCreation()}
					clinicId={this.state.clinicId}
					referencesNotes={this.state.referenceNotes}
					transactionDate={this.state.transactionDate}
					transactionMethod={this.state.transactionMethod}
					amount={this.state.amount}
					reminder={this.state.reminder}
					billingDocuments={this.state.billingDocuments}
					status={this.state.status}
					creditedAmount={this.state.creditedAmount}
					commonNotes={this.state.commonNotes}
					innerRef={this.reminderRef}
				/>
				<IncomesDetailsTable
					isCreation={this.isCreation()}
					billingDocuments={this.state.billingDocuments}
					status={this.state.status}
				/>
			</form>
		);
	}
}

const validateIncomeDetails = (formValues) => {
	const {
		TRANSACTION_AMOUNT,
		TRANSACTION_METHOD,
		TRANSACTION_DATE,
		TRANSACTION_REMINDER,
		TRANSACTION_CLINIC_ID
	} = TRANSACTION_FORM_FIELD;

	const amount = formValues[TRANSACTION_AMOUNT];
	const reminder = formValues[TRANSACTION_REMINDER];

	const formFields = [
		TRANSACTION_AMOUNT,
		TRANSACTION_METHOD,
		TRANSACTION_DATE,
		TRANSACTION_CLINIC_ID
	];

	let errors = {};

	for (let key of formFields) {
		if (!formValues[key]) {
			errors[key] = i18n.t('translation:common.errors.requiredField');
		}
	}
	if (amount && Number(reminder) < 0) {
		errors[TRANSACTION_REMINDER] = i18n.t(
			'translation:billingModule.incomeOutcomeDetails.errors.amountDistribute'
		);
	}

	if (Number(amount) === 0) {
		errors[TRANSACTION_AMOUNT] = i18n.t(
			'translation:billingModule.incomeOutcomeDetails.errors.amountGreaterThanZero'
		);
	}

	errors = validateIncomeDocumentAmount(errors, formValues);

	return errors;
};

export const validateIncomeDocumentAmount = (errors, formValues) => {
	const { DOCUMENTS_IDS, DOCUMENT_REMAINING_AMOUNT_, DOCUMENT_AMOUNT_, DOCUMENT_PAYED_AMOUNT_ } =
		TRANSACTION_FORM_FIELD;
	const documentsIds = formValues[DOCUMENTS_IDS];

	const valid = isEmptyObject(errors);

	if (documentsIds && documentsIds.length) {
		for (let i = 0; i < documentsIds.length; i++) {
			const id = documentsIds[i];
			const documentAmount = formValues[`${DOCUMENT_AMOUNT_}${id}`] ?? 0;
			if (!documentAmount) {
				continue;
			}
			const documentRemainingAmount = formValues[`${DOCUMENT_REMAINING_AMOUNT_}${id}`] ?? 0;
			if (Number(documentRemainingAmount) < 0) {
				//empty to not show error inside a table
				errors[`${DOCUMENT_AMOUNT_}${id}`] = ' ';
				//to show messageModal on submit and only if other field are valid
				if (valid) {
					errors[DOCUMENTS_IDS] = 'error';
				}
			}
		}
	}

	return errors;
};

const incomesDetailsForm = reduxForm({
	form: 'incomesDetailsForm',
	initialValues: {},
	validate: validateIncomeDetails
})(IncomeDetails);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('incomesDetailsForm')(state),
		currentDocuments: state.billingTransactions.currentDebitDocuments,
		errors: getFormSyncErrors('incomesDetailsForm')(state)
	};
};

export default connect(mapStateToProps, {
	createTransaction,
	getLabDetails,
	showMessageModal,
	hideMessageModal,
	setCurrentDebitDocuments,
	getBillingSettings
})(incomesDetailsForm);
