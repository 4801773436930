import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import SubHeader from '../../common/SubHeader';
import Table from '../../common/Table';
import LinkedLabs from './LinkedLabs';
import { parseClinicStaff } from '../../../utils/tableUtils';
import DefaultButton from '../../common/DefaultButton';
import { submit } from 'redux-form';
import {
	getCompanyActivateButtonTitle,
	getCompanyStatusText,
	isCompanyActive,
	isEmptyObject,
	isGlobalAdmin,
	isLabAdmin,
	isMyCompany,
	isNew,
	isPassiveClinic,
	isWaitingForApproval
} from '../../../utils/appUtils';
import navigationUtils from '../../../utils/navigationUtils';
import {
	activateClinic,
	areClinicAndLabLinked,
	clearReducers,
	createOrder,
	deactivateClinic,
	denyClinic,
	getClinicDetails,
	getClinicStaff,
	hideMessageModal,
	markClinicAsNotPassive,
	markClinicAsPassive,
	showMessageModal
} from '../../../actions';
import { COMPANY_STATUS, PAGES_PATHS } from '../../../utils/constants';
import Header from '../../common/Header';
import CompanyDetails from '../common/CompanyDetails';
import CompanyLocations from '../common/CompanyLocations';
import ActiveUsersSection from '../common/ActiveUsersSection';

class ClinicDetails extends React.Component {
	componentDidMount() {
		const route = navigationUtils.getRoute();
		if (isNew(route)) {
			return;
		}

		const clinicId =
			route === PAGES_PATHS.MY_PRACTICE_DETAILS
				? this.props.account.clinicId
				: this.props.match.params.id;
		this.props.getClinicDetails(clinicId);

		const { account } = this.props;
		if (isGlobalAdmin(account)) {
			this.props.getClinicStaff(navigationUtils.getRoute().split('/').pop());
		}

		if (isLabAdmin(account)) {
			this.props.areClinicAndLabLinked(this.props.match.params.id, account.labId);
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<Header
					title={this.getHeaderTitle()}
					backButton
					buttons={[
						this.renderMarkPassiveClinicButton(),
						this.renderActivateClinicButton(),
						this.renderDenyClinicButton(),
						this.renderSaveClinicButton(),
						this.renderOrderButton()
					]}
				/>
				<div className="col-12 p-0">
					<hr />
					<div className="row">
						<CompanyDetails isLabAdminAndCanEdit={this.isLabAdminAndCanEdit()} />
					</div>
					<div className="row">
						<ActiveUsersSection
							company={this.props.currentClinic}
							initialValues={{ maxNumber: this.props.currentClinic.maxActiveUsers }}
						/>
						<div className="col-6">{this.renderGeneralInformation()}</div>
					</div>
					<div className="row">
						<div className="col-12">
							<CompanyLocations
								isClinic={true}
								locations={
									isNew(navigationUtils.getRoute())
										? []
										: this.props.currentClinic.locations
								}
								canEditLocations={this.props.currentClinic.canEditLocations}
							/>
						</div>
					</div>
					{this.renderLinkedLabs()}
					<div className="row">
						<div className="col-12">{this.renderUsers()}</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getHeaderTitle = () => {
		if (isNew(navigationUtils.getRoute())) {
			return i18n.t('translation:clinics.createNewClinic');
		}

		const clinic = this.props.currentClinic;

		if (isEmptyObject(clinic)) {
			return '';
		}

		const isPassive = isPassiveClinic(clinic);
		const passiveText = isPassive ? i18n.t('translation:clinics.passive') : '';
		const statusText = getCompanyStatusText(clinic);

		let additionalText = '';
		if (isPassive || !isCompanyActive(clinic)) {
			additionalText += ' (';
			if (isPassive) {
				additionalText += passiveText;
			}
			if (!isCompanyActive(clinic)) {
				if (isPassive) {
					additionalText += ', ';
				}
				additionalText += statusText;
			}
			additionalText += ')';
		}

		return this.props.currentClinic.name + additionalText;
	};

	renderLinkedLabs = () => {
		if (isGlobalAdmin(this.props.account)) {
			return (
				<div className="row">
					<div className="col-12">
						<LinkedLabs />
					</div>
				</div>
			);
		}
	};

	renderOrderButton = () => {
		const { currentClinic } = this.props;
		if (isLabAdmin(this.props.account) && isPassiveClinic(currentClinic)) {
			return (
				<DefaultButton
					title={i18n.t('translation:labs.buttons.createOrder')}
					onClick={() => {
						this.props.createOrder(
							this.props.currentClinic.id,
							this.props.account.labId
						);
					}}
					disabled={!isCompanyActive(currentClinic)}
				/>
			);
		}
	};

	renderSaveClinicButton = () => {
		if (!isMyCompany() && !isGlobalAdmin(this.props.account) && !this.isLabAdminAndCanEdit()) {
			return <Fragment />;
		}

		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.submit('companyDetailsForm');
				}}
			/>
		);
	};

	isLabAdminAndCanEdit = () => {
		const { account, clinicAndLabLinked } = this.props;

		return this.props.currentClinic.isPassive && isLabAdmin(account) && clinicAndLabLinked;
	};

	renderMarkPassiveClinicButton = () => {
		if (!isGlobalAdmin(this.props.account) || isNew(navigationUtils.getRoute())) {
			return <Fragment />;
		}

		const title = isPassiveClinic(this.props.currentClinic)
			? i18n.t('translation:clinics.buttons.nonPassive')
			: i18n.t('translation:clinics.buttons.passive');

		return (
			<DefaultButton
				title={title}
				onClick={() => {
					if (isPassiveClinic(this.props.currentClinic)) {
						this.props.markClinicAsNotPassive(this.props.currentClinic.id);
					} else {
						this.props.markClinicAsPassive(this.props.currentClinic.id);
					}
				}}
			/>
		);
	};

	renderGeneralInformation = () => {
		if (
			(!isMyCompany() && !isGlobalAdmin(this.props.account)) ||
			isNew(navigationUtils.getRoute())
		) {
			return <Fragment />;
		}

		return (
			<Fragment>
				<SubHeader title={i18n.t('translation:common.generalInformation')} />
				<div className="col-12 data-section mt-s p-0">
					<div className="content h-s">
						<div className="data-row">
							<label className="input-label">
								{i18n.t('translation:common.registrationCode')}
							</label>
							<span>{this.props.currentClinic.registrationCode}</span>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	renderActivateClinicButton = () => {
		if (!isGlobalAdmin(this.props.account) || isNew(navigationUtils.getRoute())) {
			return <Fragment />;
		}

		const clinic = this.props.currentClinic;
		const buttonTitle = getCompanyActivateButtonTitle(clinic);

		return <DefaultButton title={buttonTitle} onClick={this.onActivateButtonPress} />;
	};

	renderDenyClinicButton = () => {
		if (
			!isGlobalAdmin(this.props.account) ||
			isNew(navigationUtils.getRoute()) ||
			!isWaitingForApproval(this.props.currentClinic)
		) {
			return <Fragment />;
		}

		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.deny')}
				danger
				onClick={this.onDenyButtonPress}
			/>
		);
	};

	onDenyButtonPress = () => {
		const clinic = this.props.currentClinic;

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.denyClinic(clinic.id);
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			i18n.t('translation:clinics.denyClinicWarning'),
			[yesButton, noButton]
		);
	};

	onActivateButtonPress = () => {
		const clinic = this.props.currentClinic;
		const waitingForApproval = clinic.status === COMPANY_STATUS.WAITING_FOR_APPROVAL;
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					if (isCompanyActive(clinic)) {
						this.props.deactivateClinic(clinic.id);
					} else {
						this.props.activateClinic(clinic.id, waitingForApproval);
					}
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		const confirmationMessage = isCompanyActive(clinic)
			? i18n.t('translation:clinics.deactivateClinicWarning')
			: i18n.t('translation:clinics.activateClinicWarning');
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			confirmationMessage,
			[yesButton, noButton]
		);
	};

	renderUsers = () => {
		if (isNew(navigationUtils.getRoute()) || !isGlobalAdmin(this.props.account)) {
			return;
		}

		const users = parseClinicStaff(this.props.users);

		return (
			<Fragment>
				<SubHeader title={i18n.t('translation:common.users')} />
				<div className="col-12 mt-s p-0">
					<Table
						columns={[
							i18n.t('translation:common.table.name'),
							i18n.t('translation:common.table.phone'),
							i18n.t('translation:common.table.email'),
							i18n.t('translation:common.table.status')
						]}
						data={users}
					/>
				</div>
			</Fragment>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		currentClinic: state.clinics.currentClinic,
		clinicAndLabLinked: state.invitations.clinicAndLabLinked,
		settings: state.settings,
		initialValues: state.clinics.currentClinic,
		users: state.users.users.content
	};
};

export default connect(mapStateToProps, {
	getClinicDetails,
	showMessageModal,
	hideMessageModal,
	areClinicAndLabLinked,
	getClinicStaff,
	clearReducers,
	markClinicAsNotPassive,
	markClinicAsPassive,
	activateClinic,
	deactivateClinic,
	createOrder,
	denyClinic,
	submit
})(ClinicDetails);
