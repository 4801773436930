import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common/Header';
import i18n from '../../../../../i18n';
import SubHeader from '../../../../common/SubHeader';
import DefaultButton from '../../../../common/DefaultButton';
import { parseWorkingHours } from '../../../../../utils/tableUtils';
import Table from '../../../../common/Table';
import { addZeroIfOneDigit, isEmptyObject, isGlobalAdmin } from '../../../../../utils/appUtils';
import { getFormValues, SubmissionError, submit } from 'redux-form';
import { getGeneralWorkingHours, updateGeneralWorkingHours } from '../../../../../actions';

class WeeklyWorkingHours extends React.Component {
	componentDidMount() {
		const labId = this.props.account.labId;
		this.props.getGeneralWorkingHours(labId);
	}

	renderWorkingHours = () => {
		const { workingHours, tableFormValues } = this.props;

		const columns = [
			i18n.t('translation:taskManager.common.table.day'),
			i18n.t('translation:taskManager.common.table.start'),
			i18n.t('translation:taskManager.common.table.end'),
			i18n.t('translation:taskManager.common.table.time'),
			i18n.t('translation:common.table.action')
		];

		const data = parseWorkingHours(workingHours, tableFormValues);
		let initialValues = {};

		for (let value of workingHours) {
			const startTime = value.startTime ? value.startTime.split(':') : [];
			const endTime = value.endTime ? value.endTime.split(':') : [];

			initialValues['dayOfTheWeek_' + value.id + 'd'] = value.dayOfTheWeek;
			initialValues['duration_' + value.id + 't'] = value.duration;

			initialValues['hours_' + value.id + 's'] = startTime[0];
			initialValues['minutes_' + value.id + 's'] = startTime[1];

			initialValues['hours_' + value.id + 'e'] = endTime[0];
			initialValues['minutes_' + value.id + 'e'] = endTime[1];
		}

		return (
			<Table
				columns={columns}
				data={data}
				onSubmit={this.onTableFormSubmit}
				initialValues={initialValues}
			/>
		);
	};

	onTableFormSubmit = (formValues) => {
		let errors = {};

		for (let value in formValues) {
			const index = value.split('_')[1];

			if (index.endsWith('s') && formValues[value]) {
				const startHours = formValues['hours_' + index];
				const startMinutes = formValues['minutes_' + index];
				const endHours = formValues['hours_' + index.replace('s', 'e')];
				const endMinutes = formValues['minutes_' + index.replace('s', 'e')];

				errors['hours_' + index] = startHours ? undefined : ' ';
				errors['minutes_' + index] = startMinutes ? undefined : ' ';
				errors['hours_' + index.replace('s', 'e')] = endHours ? undefined : ' ';
				errors['minutes_' + index.replace('s', 'e')] = endMinutes ? undefined : ' ';
			}

			if (index.endsWith('e') && formValues[value]) {
				const startHours = formValues['hours_' + index];
				const startMinutes = formValues['minutes_' + index];
				const endHours = formValues['hours_' + index.replace('e', 's')];
				const endMinutes = formValues['minutes_' + index.replace('e', 's')];

				errors['hours_' + index] = startHours ? undefined : ' ';
				errors['minutes_' + index] = startMinutes ? undefined : ' ';
				errors['hours_' + index.replace('e', 's')] = endHours ? undefined : ' ';
				errors['minutes_' + index.replace('e', 's')] = endMinutes ? undefined : ' ';
			}
		}

		for (let index in errors) {
			if (errors[index] == undefined) {
				delete errors[index];
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		let workingHours = [];

		for (let value in formValues) {
			let currentValues = {};
			const index = value.split('_')[1];
			const id = value.split('_')[1].slice(0, -1);

			currentValues['id'] = Number(id);
			workingHours.push(currentValues);

			for (let i = 0; i < workingHours.length; i++) {
				if (workingHours[i].id == id) {
					if (index.endsWith('s') && formValues[value]) {
						workingHours[i]['startTime'] = addZeroIfOneDigit(
							formValues['hours_' + index]
						)
							.concat(':')
							.concat(formValues['minutes_' + index]);
					}
					if (index.endsWith('e') && formValues[value]) {
						workingHours[i]['endTime'] = addZeroIfOneDigit(formValues['hours_' + index])
							.concat(':')
							.concat(formValues['minutes_' + index]);
					}
					if (index.endsWith('d') && formValues[value]) {
						workingHours[i]['dayOfTheWeek'] = formValues['dayOfTheWeek_' + id + 'd'];
					}
					if (index.endsWith('t') && formValues[value]) {
						workingHours[i]['duration'] = formValues['duration_' + id + 't'];
					}
				}
			}
		}

		let updatedWorkingHours = workingHours.reduce(function (acc, curr) {
			let findIndex = acc.findIndex(function (item) {
				return item.id === curr.id;
			});

			if (findIndex === -1) {
				acc.push(curr);
			} else {
				acc[findIndex] = Object.assign({}, acc[findIndex], curr);
			}
			return acc;
		}, []);

		const labId = this.props.account.labId;
		this.props.updateGeneralWorkingHours(updatedWorkingHours, labId);
	};

	render() {
		const saveWorkingHoursButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				key={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.submit('tableForm');
				}}
			/>
		);

		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = isReadOnly ? ' read-only-field' : '';
		const simpleTitle = i18n.t('translation:taskManager.planner.workingHours');
		const headerTitle = isGlobalAdmin(this.props.account)
			? this.props.currentLab.name + ' - ' + simpleTitle
			: simpleTitle;
		const backButtonShown = isGlobalAdmin(this.props.account) ? true : false;

		return (
			<Fragment>
				<Header title={headerTitle} backButton={backButtonShown} />
				<SubHeader
					first
					readOnly={readOnlyClass}
					title={i18n.t('translation:taskManager.planner.weeklyWorkingHours')}
					buttons={[saveWorkingHoursButton]}
				/>
				<br />
				<div className="col-12 p-0">
					<hr />
					<div className={'col-12 p-0' + readOnlyClass}>{this.renderWorkingHours()}</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		workingHours: state.workingHours,
		tableFormValues: getFormValues('tableForm')(state) || {}
	};
};

export default connect(mapStateToProps, {
	getGeneralWorkingHours,
	updateGeneralWorkingHours,
	submit
})(WeeklyWorkingHours);
