import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import './i18n';
import App from './components/main/App';
import 'sanitize.css/sanitize.css';
import './resources/styles/customStyles.scss';
import './resources/styles/bootstrap.css';
import store from './store';
import 'react-notifications/lib/notifications.css';

export const history = createBrowserHistory();

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.querySelector('#root')
);
