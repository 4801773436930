import { START_LOADER, STOP_LOADER } from '../actions/types';

const initState = {};

const loaderReducer = (state = { ...initState }, action) => {
	if (action.type === START_LOADER) {
		state[action.payload] = true;
		return { ...state };
	}

	if (action.type === STOP_LOADER) {
		state[action.payload] = false;
		return { ...state };
	}

	return state;
};

export default loaderReducer;
