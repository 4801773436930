import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../i18n';
import { isEmptyObject } from '../../utils/appUtils';
import { renderActionButton } from '../../utils/tableUtils';

class NestedTable extends React.Component {
	state = {};

	componentDidMount() {
		if (this.props.expand) {
			this.onDropdownClick(null, true, []);
		}
	}

	render() {
		const tableClassName = this.props.className ? this.props.className : '';

		return (
			<div className={tableClassName}>
				<table className="nested-table table">
					<thead>
						<tr>{this.renderHeader()}</tr>
					</thead>
					<tbody>
						{this.renderData()}
						{this.renderNoItemsMessage()}
					</tbody>
				</table>
			</div>
		);
	}

	renderDropdown(elementIndex, primaryData) {
		if (elementIndex) {
			if (this.props.dropdown && !this.props.dropdown[elementIndex]) {
				return (
					<td
						onClick={() => {
							this.onDropdownClick(elementIndex, true, primaryData);
						}}
						className="text-right pointer">
						<FontAwesomeIcon icon={faSortDown} className="ml-xs" />
					</td>
				);
			} else {
				return (
					<td
						onClick={() => {
							this.onDropdownClick(elementIndex, false, primaryData);
						}}
						className="text-right pointer">
						<FontAwesomeIcon icon={faSortUp} className="ml-xs" />
					</td>
				);
			}
		} else {
			const indexes = this.props.data.map((data, index) => {
				return index + '-0';
			});

			if (
				!isEmptyObject(this.props.dropdown) &&
				indexes.some((index) => this.props.dropdown[index] === true)
			) {
				return (
					<th
						onClick={() => {
							this.onDropdownClick(elementIndex, false, primaryData);
						}}
						className="text-right pointer">
						<FontAwesomeIcon icon={faSortUp} className="ml-xs" />
					</th>
				);
			} else {
				if (this.props.setDropdown) {
					return (
						<th
							onClick={() => {
								this.onDropdownClick(elementIndex, true, primaryData);
								if (typeof this.props.onGlobalDropdownClick === 'function') {
									this.props.onGlobalDropdownClick(this.onDropdownClick);
								}
							}}
							className="text-right pointer">
							<FontAwesomeIcon icon={faSortDown} className="ml-xs" />
						</th>
					);
				}
			}
		}
	}

	onDropdownClick = (elementIndex, isExpanded, data) => {
		if (elementIndex) {
			this.props.setDropdown(elementIndex, isExpanded, data);
		} else {
			const indexes = this.props.data.map((data, index) => {
				return index + '-0';
			});

			this.props.setDropdown(indexes, isExpanded, data);
		}
	};

	renderHeader = () => {
		const { columns } = this.props;

		const header = columns.map((column, index) => {
			const tableHeaderStyle =
				index === 0 ? 'pl-xl-3' : index === columns.length - 1 ? 'pr-xl-3' : null;
			const tableColumn = 'd-inline';

			return (
				<Fragment key={index + 'tableColumn'}>
					<th className={tableHeaderStyle} key={index + 'h'} scope="col">
						<div className={tableColumn}>{column}</div>
					</th>
				</Fragment>
			);
		});

		header.push(<Fragment key={'dropdown'}>{this.renderDropdown()}</Fragment>);

		return header;
	};

	renderData = () => {
		const { data } = this.props;
		return data.map((dataRow, index) => {
			return this.renderGroup(dataRow, index, 0);
		});
	};

	renderContent = (content, index, level, uniqueIndex) => {
		const className =
			index === 0
				? `pl-xl-${3 + level}`
				: index === this.props.columns.length
				? 'pr-xl-3'
				: null;

		if (Array.isArray(content)) {
			return <td key={uniqueIndex + index + 'd'}>{renderActionButton(content)}</td>;
		}

		return (
			<td key={uniqueIndex + index + 'd'} className={className}>
				{content}
			</td>
		);
	};

	renderGroup = (primaryData, parentIndex, levelIndex, index = 0) => {
		let primaryRow;

		parentIndex = parentIndex.toString();

		const elementIndex = parentIndex + '-' + index;

		const uniqueIndex = levelIndex.toString() + '-' + index.toString() + '-' + parentIndex;
		if (Array.isArray(primaryData[0])) {
			if (
				this.props.dropdown &&
				!this.props.dropdown[parentIndex] &&
				parentIndex.split('-').length > 1
			) {
				return;
			}

			let className =
				this.props.dropdown && !this.props.dropdown[elementIndex]
					? 'collapsed level-' + levelIndex
					: !this.props.dropdown
					? 'collapsed level-' + levelIndex
					: 'level-' + levelIndex;

			if (this.props.calendarTable && levelIndex === 1) {
				className += ' background-dark-gray';
			}

			const colSpan = this.props.columns.length + 1;
			const noItemsMessage = this.props.noItemsMessage
				? this.props.noItemsMessage
				: i18n.t('translation:common.table.noItems');
			const noItems = [
				<tr className={className} key={uniqueIndex}>
					<td className={'text-center'} colSpan={colSpan}>
						{noItemsMessage}
					</td>
				</tr>
			];

			const secondaryRows = primaryData[0].length
				? primaryData[0].map((currentData, index) => {
						return this.renderGroup(currentData, elementIndex, levelIndex + 1, index);
				  })
				: this.props.dropdown && !this.props.dropdown[elementIndex]
				? []
				: [];

			primaryData.shift();

			primaryData.push('');

			primaryRow = (
				<tr className={className} key={uniqueIndex}>
					{primaryData.map((content, index) => {
						if (index === primaryData.length - 1) {
							return (
								<Fragment key={elementIndex}>
									{this.renderDropdown(elementIndex, primaryData)}
								</Fragment>
							);
						}
						return this.renderContent(content, index, levelIndex, uniqueIndex);
					})}
				</tr>
			);

			return [primaryRow, ...secondaryRows];
		} else {
			if (this.props.dropdown && !this.props.dropdown[parentIndex]) {
				return;
			}

			if (this.props.setDropdown) {
				primaryData.push('');
			}

			const content = primaryData.map((content, index) => {
				return this.renderContent(content, index, levelIndex, uniqueIndex);
			});

			let className = !this.props.dropdown
				? 'collapsed level-' + levelIndex
				: 'level-' + levelIndex;

			return (
				<tr key={uniqueIndex} className={className}>
					{content}
				</tr>
			);
		}
	};

	renderNoItemsMessage = () => {
		const noItemsMessage = this.props.noItemsMessage
			? this.props.noItemsMessage
			: i18n.t('translation:common.table.noItems');
		const colSpan = this.props.columns.length + 1;

		if (this.props.data.length === 0) {
			return (
				<tr className="d-table-row">
					<td colSpan={colSpan} className="text-center">
						{noItemsMessage}
					</td>
				</tr>
			);
		}
	};
}

export default NestedTable;
