import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import i18n from '../../../i18n';
import { formUtils } from '../../../utils/formUtils';
import { createLinkedPassiveClinic } from '../../../actions';

class AddPassiveClinicModal extends React.Component {
	constructor() {
		super();
		this.state = this.getInitialState();
	}

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	getInitialState = () => {
		return {
			errorExists: false,
			errorMessage: ''
		};
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll send-invitation-modal"
				overlayClassName="custom-overlay">
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={() => {
							this.closeModalResettingState();
						}}
					/>

					{this.renderTitle()}

					{Fields.commonFields.name({ className: 'input-container full-width' })}
					{Fields.commonFields.legalName({ className: 'input-container full-width' })}
					{Fields.commonFields.registeredOffice({
						className: 'input-container full-width'
					})}
					{Fields.commonFields.phone({ className: 'input-container full-width' })}
					{Fields.commonFields.email({ className: 'input-container full-width' })}

					{this.renderError()}

					<div className="buttons modal-dialog-buttons-below-error-message">
						{this.renderSaveButton()}
						{this.renderCancelButton()}
					</div>
				</form>
			</Modal>
		);
	}

	renderTitle = () => {
		return <h2>{i18n.t('translation:clinics.buttons.addPassiveClient')}</h2>;
	};

	renderError = () => {
		if (this.state.errorExists) {
			return (
				<div className="align-items-start text-danger position-absolute modal-dialog-error-message">
					{this.state.errorMessage}
				</div>
			);
		}
	};

	renderSaveButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.props.handleSubmit(this.onFormSubmit)}
			/>
		);
	};

	renderCancelButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModalResettingState();
				}}
				secondary
			/>
		);
	};

	closeModalResettingState = () => {
		this.resetState();

		this.props.closeModal();
		this.props.destroy();
	};

	resetState = () => {
		this.setState(this.getInitialState());

		this.clearReduxForm();
	};

	clearReduxForm = () => {
		this.props.reset();
	};

	onFormSubmit = (formValues) => {
		const clinicData = this.getClinicData(formValues);
		this.props.createLinkedPassiveClinic(
			this.props.account.labId,
			clinicData,
			this.displayErrorMessage,
			this.closeModalResettingState
		);
	};

	getClinicData = ({ name, legalName, registeredOffice, phone, email }) => {
		return { name, legalName, registeredOffice, phone, email };
	};

	displayErrorMessage = (errorMessage) => {
		this.setState({ errorExists: true, errorMessage });
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};

const addPassiveClinicForm = reduxForm({
	form: 'addPassiveClinicForm',
	validate: formUtils.validateCompanyDetails
})(AddPassiveClinicModal);

export default connect(mapStateToProps, { createLinkedPassiveClinic })(addPassiveClinicForm);
