import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
	deleteFile,
	hideMessageModal,
	showMessageModal,
	updateFile,
	uploadFile
} from '../../../actions';
import i18n from '../../../i18n';
import { faPlayCircle, faPlus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubHeader from '../../common/SubHeader';
import DefaultButton from '../../common/DefaultButton';
import AddPhotoModal from './UploadFileModal';
import EditFileDataModal from './EditFileDataModal';
import FileDetailsModal from './FileDetailsModal';
import {
	ORDER_ADDITIONAL_FILES_PER_ROW,
	ORDER_FILE_SUB_TYPE,
	SPECIAL_ORDER_FILE_TYPES
} from '../../../utils/constants';
import {
	getFileExtension,
	getFileLocation,
	isPhotoFile,
	isVideoFile
} from '../../../utils/fileUtils';
import { canEditOrDeleteFiles, localizeFileDeleteWarning } from '../../../utils/appUtils';
import fileBackgroundImage from '../../../resources/images/file-background-image.png';
import videoBackgroundImage from '../../../resources/icons/video.svg';

class AdditionalFiles extends React.Component {
	state = {
		isSectionOpen: true,
		isAddPhotoModalOpen: false,
		currentFile: null,
		isViewPhotoModalOpen: false,
		isEditPhotoModalOpen: false
	};

	render() {
		return (
			<Fragment>
				<SubHeader
					title={i18n.t('translation:orders.additionalFiles')}
					onTrigger={this.triggerSection}
					isOpen={this.state.isSectionOpen}
				/>
				{this.renderFilesGrid()}
			</Fragment>
		);
	}

	renderFilesGrid() {
		if (!this.state.isSectionOpen) {
			return <Fragment></Fragment>;
		}

		const filesGrid = [];
		const additionalFiles = this.getAdditionalFiles();

		const numberOfRows =
			Math.floor(additionalFiles.length / ORDER_ADDITIONAL_FILES_PER_ROW) + 1;
		const additionalFilesIndexMaxValue = numberOfRows * ORDER_ADDITIONAL_FILES_PER_ROW;

		let i = 0;
		while (i < additionalFilesIndexMaxValue) {
			// cover all cells in all rows
			filesGrid.push(this.renderRowOfFiles(additionalFiles, i));
			i += ORDER_ADDITIONAL_FILES_PER_ROW;
		}

		const canEditOrDeleteFilesValue = canEditOrDeleteFiles(
			this.props.account,
			this.props.currentOrder
		);

		return (
			<Fragment>
				<div className="mt-s">{filesGrid}</div>
				<AddPhotoModal
					isOpen={this.state.isAddPhotoModalOpen}
					onClose={this.triggerUploadFileModal}
					onUpload={this.uploadFile.bind(this)}
				/>
				<FileDetailsModal
					isOpen={this.state.isViewPhotoModalOpen}
					onClose={this.triggerViewPhotoModal}
					onDelete={this.deleteFile.bind(this)}
					onEdit={this.triggerEditPhotoModal}
					file={this.state.currentFile}
					canEditFileData={canEditOrDeleteFilesValue}
					canDeleteFiles={canEditOrDeleteFilesValue}
				/>
				<EditFileDataModal
					isOpen={this.state.isEditPhotoModalOpen}
					onClose={this.triggerEditPhotoModal}
					onUpdate={this.updateFile}
					file={this.state.currentFile}
				/>
			</Fragment>
		);
	}

	renderRowOfFiles(files, startFileIndex) {
		const { canUploadAdditionalFiles, maxNumberOfAdditionalFilesReached } =
			this.props.currentOrder;
		const rowOfFiles = [];

		let addButtonAlreadyAdded = false;
		for (let j = 0; j < ORDER_ADDITIONAL_FILES_PER_ROW; j++) {
			const currentIndex = startFileIndex + j;
			const file = files[currentIndex];

			if (file !== undefined) {
				rowOfFiles.push(this.renderFileCell(file, currentIndex));
			} else if (
				canUploadAdditionalFiles &&
				!maxNumberOfAdditionalFilesReached &&
				!addButtonAlreadyAdded
			) {
				rowOfFiles.push(this.renderAddFileButton(currentIndex));
				addButtonAlreadyAdded = true;
			} else {
				rowOfFiles.push(this.renderEmptyFileCell(currentIndex));
			}
		}

		return (
			<div className="row py-3" key={startFileIndex + 1}>
				{rowOfFiles}
			</div>
		);
	}

	renderAddFileButton(cellIndex) {
		return (
			<div className="col-sm" key={cellIndex}>
				<div
					className="photo photo-add-button"
					onClick={() => this.triggerUploadFileModal()}>
					<div className="photo-icon-container">
						<FontAwesomeIcon icon={faPlus} size="3x" />
						{i18n.t('translation:orders.clickToUploadFile')}
					</div>
				</div>
			</div>
		);
	}

	renderEmptyFileCell(cellIndex) {
		return <div className="col-sm" key={cellIndex}></div>;
	}

	renderFileCell(file, cellIndex) {
		return (
			<div className="col-sm" key={cellIndex}>
				<div className="photo photo-no-border">
					<div
						style={{
							backgroundImage: getBackgroundImage(file),
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat'
						}}>
						<div
							className="photo-label photo-label-with-background"
							onClick={() => this.triggerViewPhotoModal(file)}>
							<div className="photo-icon-container">
								<FontAwesomeIcon
									icon={isVideoFile(file) ? faPlayCircle : faSearchPlus}
									size="3x"
									className="photo-icon"
								/>
								{getOpenFileHeader(file)}
							</div>
						</div>
					</div>
				</div>
				{this.renderPhotoDescription(file.description)}
			</div>
		);
	}

	renderPhotoDescription(description) {
		return (
			<div className="row">
				<div className="col-sm additional-photo-description">{description}</div>
			</div>
		);
	}

	triggerSection = () => {
		this.setState({ isSectionOpen: !this.state.isSectionOpen });
	};

	triggerUploadFileModal = () => {
		this.setState({ isAddPhotoModalOpen: !this.state.isAddPhotoModalOpen });
	};

	triggerViewPhotoModal = (photo) => {
		this.setState({
			isViewPhotoModalOpen: !this.state.isViewPhotoModalOpen,
			currentFile: photo
		});
	};

	triggerEditPhotoModal = (photo) => {
		this.setState({
			isEditPhotoModalOpen: !this.state.isEditPhotoModalOpen,
			currentFile: photo
		});
	};

	uploadFile(file, description) {
		this.triggerUploadFileModal();
		if (file !== undefined) {
			// file === undefined when you click cancel

			// NOTE: deliberately not sending the file type. The back-end will
			// figure out what the file is while uploading it on the server.
			// And then return the correct file type as part of the response.
			const noFileType = null;

			this.props.uploadFile(
				this.props.currentOrder.id,
				noFileType,
				ORDER_FILE_SUB_TYPE.ADDITIONAL,
				description,
				file,
				getFileExtension(file.name)
			);
		}
	}

	updateFile = (description) => {
		this.props.updateFile(this.props.currentOrder.id, this.state.currentFile.id, description);
	};

	deleteFile = (file) => {
		this.triggerViewPhotoModal(null);

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.deleteFile(this.props.currentOrder.id, file.id);
					this.props.hideMessageModal();
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			localizeFileDeleteWarning(file.fileType),
			[yesButton, noButton]
		);
	};

	getAdditionalFiles() {
		const additionalFiles = [];
		for (const file of this.props.currentOrder.files) {
			if (file.fileSubType === ORDER_FILE_SUB_TYPE.ADDITIONAL) {
				additionalFiles.push(file);
			}
		}

		return additionalFiles;
	}
}

const getBackgroundImage = (file) => {
	if (isPhotoFile(file)) {
		return `url(${getThumbnailImage(file)})`;
	} else if (isVideoFile(file)) {
		return `url(${videoBackgroundImage})`;
	} else {
		return `url(${fileBackgroundImage})`;
	}
};

const getThumbnailImage = (file) => {
	switch (file.fileType) {
		case SPECIAL_ORDER_FILE_TYPES.PHOTO:
			return getFileLocation(file.thumbnailFileName);
		default:
			return null;
	}
};

const getOpenFileHeader = (file) => {
	if (isPhotoFile(file)) {
		return i18n.t('translation:orders.enlargePhoto');
	} else if (isVideoFile(file)) {
		return i18n.t('translation:orders.showVideo');
	} else {
		return i18n.t('translation:orders.showFileDetails');
	}
};

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		account: state.account,
		currentOrder: state.orders.currentOrder
	};
};

export default connect(mapStateToProps, {
	uploadFile,
	updateFile,
	deleteFile,
	showMessageModal,
	hideMessageModal
})(AdditionalFiles);
