import {
	SEARCH_TRANSACTIONS,
	GET_CURRENT_CREDIT_DOCUMENTS,
	GET_CURRENT_DEBIT_DOCUMENTS
} from '../../actions/types';

const initState = {
	transactions: {},
	currentDebitDocuments: [],
	currentCreditDocuments: []
};

const billingTransactionReducer = (state = initState, action) => {
	if (action.type === SEARCH_TRANSACTIONS) {
		state.transactions = action.payload;
		return { ...state };
	}

	if (action.type === GET_CURRENT_DEBIT_DOCUMENTS) {
		state.currentDebitDocuments = action.payload;
		return { ...state };
	}

	if (action.type === GET_CURRENT_CREDIT_DOCUMENTS) {
		state.currentCreditDocuments = action.payload;
		return { ...state };
	}

	return initState;
};

export default billingTransactionReducer;
