import {
	ACCESSING_SITE_FOR_FIRST_TIME,
	ATTEMPT_LOGIN,
	GET_CURRENT_USER,
	LOGOUT,
	TOGGLE_LOGIN
} from '../actions/types';

const initialState = {
	showLogin: true
};

const loginReducer = (state = initialState, action) => {
	state = {
		...state,
		loginAttempt: false,
		sendEmailAttempt: false,
		newPasswordAttempt: false,
		personalInfoUpdateAttempt: false,
		changePasswordAttempt: false
	};

	switch (action.type) {
		case ATTEMPT_LOGIN:
			return {
				...state,
				loginAttempt: true,
				successfulLogin: action.payload.success
			};

		case LOGOUT:
			return {
				...state,
				currentUser: null
			};

		case TOGGLE_LOGIN:
			return {
				...state,
				showLogin: !state.showLogin
			};

		case GET_CURRENT_USER:
			return {
				...state,
				currentUser: action.payload
			};

		case ACCESSING_SITE_FOR_FIRST_TIME:
			return {
				...state,
				accessingSiteForFirstTime: action.payload
			};

		default:
			return state;
	}
};

export default loginReducer;
