import { getAxiosWithToken } from '../../utils/webApi';
import {
	CLEAR_CALENDAR_STAFF_TASKS_DATES,
	GET_AVAILABLE_STAFF,
	GET_CALENDAR_ORDER_ITEMS,
	GET_CALENDAR_ORDERS,
	GET_CALENDAR_STAFF,
	GET_CALENDAR_STAFF_ORDERS,
	GET_CALENDAR_STAFF_TASKS,
	GET_CALENDAR_STAFF_TASKS_LAB_TECHNICIAN
} from '../types';
import DefaultButton from '../../components/common/DefaultButton';
import i18n from '../../i18n';
import store from '../../store';
import { hideMessageModal, showMessageModal } from '../portalActions';
import React from 'react';
import { showSuccessPopup } from '../../utils/appUtils';
import { ERROR_CODES, LOADER_TYPES } from '../../utils/constants';
import { startLoader, stopLoader } from '../loaderActions';

export const getCalendarOrders =
	(labId, data, setLimitedOrders, returnOrdersIfAboveLimit = false) =>
	async (dispatch) => {
		dispatch({ type: GET_CALENDAR_ORDERS, payload: { orders: [] } });
		dispatch(startLoader(LOADER_TYPES.GANTTCHART_LOADER));

		const response = await getAxiosWithToken().post(
			'task/order/search',
			{ ...data, returnOrdersIfAboveLimit },
			{
				params: {
					'lab-id': labId
				}
			}
		);
		const returnedData = response.data;
		if (returnedData.success) {
			if (returnedData.code === 4064 && !returnOrdersIfAboveLimit) {
				dispatch({ type: GET_CALENDAR_ORDERS, payload: returnedData.data });
				const yesButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.yes')}
						onClick={() => {
							setLimitedOrders(false);
							dispatch(getCalendarOrders(labId, data, getCalendarOrders, true));
							dispatch(hideMessageModal());
						}}
					/>
				);
				const noButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.no')}
						onClick={() => {
							dispatch(hideMessageModal());
							setLimitedOrders(true);
						}}
						secondary
					/>
				);
				dispatch(
					showMessageModal(
						i18n.t('translation:common.warning'),
						returnedData.userMessage,
						[yesButton, noButton]
					)
				);
				dispatch(stopLoader(LOADER_TYPES.GANTTCHART_LOADER));
				return;
			}
			setLimitedOrders(false);
			if (returnedData.data.orders.length > 100) {
				const timeout = setTimeout(() => {
					dispatch(stopLoader(LOADER_TYPES.GANTTCHART_LOADER));
					clearTimeout(timeout);
				}, 1200);
			} else {
				dispatch(stopLoader(LOADER_TYPES.GANTTCHART_LOADER));
			}
			dispatch({ type: GET_CALENDAR_ORDERS, payload: returnedData.data });
		}
	};

export const getCalendarOrderItems =
	(orderId, startDate, endDate, taskStatuses, taskAssigneeIdList) => async (dispatch) => {
		const response = await getAxiosWithToken().post('task/order/items', {
			orderId,
			startDate,
			endDate,
			taskStatuses,
			taskAssigneeIdList
		});
		const returnedData = response.data;

		if (returnedData.success) {
			dispatch({
				type: GET_CALENDAR_ORDER_ITEMS,
				payload: { data: returnedData.data, orderId }
			});
		}
	};

export const getAllCalendarOrderItems =
	(
		labId,
		data,
		returnOrdersIfAboveLimit,
		onDropdownClick = () => {},
		returnOrdersOnlyUpToSoftLimit
	) =>
	async (dispatch, getState) => {
		returnOrdersIfAboveLimit = returnOrdersOnlyUpToSoftLimit
			? returnOrdersOnlyUpToSoftLimit
			: returnOrdersIfAboveLimit;
		const response = await getAxiosWithToken().post(
			'task/order/search-and-return-all-data',
			{ ...data, returnOrdersIfAboveLimit, returnOrdersOnlyUpToSoftLimit },
			{
				params: {
					'lab-id': labId
				}
			}
		);
		const returnedData = response.data;

		if (returnedData.success) {
			const indexes = returnedData.data.orders.map((order, parentIndex) => {
				const subIndexes = order.orderItems.map(
					(orderItem, index) => parentIndex + '-0' + '-' + index
				);
				return [parentIndex + '-0', ...subIndexes];
			});
			const refinedIndexes = [];
			indexes.forEach((index) => {
				index.forEach((index) => refinedIndexes.push(index));
			});
			onDropdownClick(refinedIndexes, true);
			dispatch({ type: GET_CALENDAR_ORDERS, payload: returnedData.data });
		} else {
			if (returnedData.code !== 406) {
				const yesButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.yes')}
						onClick={() => {
							store.dispatch(hideMessageModal());
							store.dispatch(
								getAllCalendarOrderItems(
									labId,
									data,
									true,
									onDropdownClick,
									returnOrdersOnlyUpToSoftLimit
								)
							);
						}}
					/>
				);
				const noButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.no')}
						onClick={() => {
							const indexes = getState().calendar.orders.orders.map(
								(order, parentIndex) => parentIndex + '-0'
							);
							onDropdownClick(indexes, false);
							store.dispatch(hideMessageModal());
						}}
						secondary
					/>
				);
				dispatch(
					showMessageModal(
						i18n.t('translation:common.warning'),
						returnedData.userMessage,
						[yesButton, noButton]
					)
				);
			} else {
				const indexes = getState().calendar.orders.orders.map(
					(order, parentIndex) => parentIndex + '-0'
				);
				onDropdownClick(indexes, false);
			}
		}
	};

export const getTaskData = (taskId, callBack) => async (dispatch) => {
	const response = await getAxiosWithToken(false).get(`/task/${taskId}`);
	let returnedData = response.data;

	if (callBack) {
		callBack(returnedData.data);
	}

	return returnedData;
};

export const updateTask =
	(taskId, data, setErrorMessage, callback, closeModal) => async (dispatch) => {
		const response = await getAxiosWithToken(false).put(`/task/${taskId}`, data);

		let returnedData = response.data;
		if (returnedData.success) {
			if (callback) {
				callback();
			}
			showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedSettings'));

			//work around to refresh task updates in ganttchart table
			setTimeout(closeModal, 500);
		} else {
			if (returnedData.code == ERROR_CODES.TASK_CONFLICTS) {
				const userMessage = returnedData.userMessage.split(';').slice(0, -1);
				setErrorMessage(userMessage, true);
			} else {
				setErrorMessage(returnedData.userMessage);
			}
		}
	};

export const listPossibleAssignees =
	(data, setErrorMessage, employeesOldData) => async (dispatch) => {
		//work around to hide the available staff till the data is loaded
		// dispatch({ type: GET_AVAILABLE_STAFF, payload: [] });

		const response = await getAxiosWithToken(false).post(
			`/task/order/list-possible-assignees`,
			data
		);

		let returnedData = response.data;
		if (returnedData.success) {
			const employees = returnedData.data.sortedEmployees;
			dispatch({
				type: GET_AVAILABLE_STAFF,
				payload: employees
			});

			return employees;
		} else {
			setErrorMessage(returnedData.userMessage);

			if (employeesOldData) {
				dispatch({
					type: GET_AVAILABLE_STAFF,
					payload: employeesOldData
				});
			}
		}

		// return returnedData;
	};

export const loadSelectedAssignee = (assignee) => async (dispatch) => {
	dispatch({ type: GET_AVAILABLE_STAFF, payload: assignee });
};

export const getCalendarStaffWithTasks = (labId, data, showByHour) => async (dispatch) => {
	const response = await getAxiosWithToken().post('task/search-and-return-all-data', data, {
		params: {
			'lab-id': labId,
			'show-by-hour': showByHour
		}
	});

	const returnedData = response.data;
	if (returnedData.success) {
		dispatch({ type: GET_CALENDAR_STAFF, payload: returnedData.data });
	}
};

export const getCalendarStaff = (labId, data, showByHour) => async (dispatch) => {
	dispatch({ type: GET_CALENDAR_STAFF, payload: [] });
	const response = await getAxiosWithToken().post('task/search', data, {
		params: {
			'lab-id': labId,
			'show-by-hour': showByHour
		}
	});

	const returnedData = response.data;

	const refinedCalendarStaff = returnedData.data.map((staff) => {
		const id = staff.id;
		const refinedStaff = staff;
		refinedStaff.timeSlotWorkDurations.map((item) => {
			item['assigneeId'] = id;
			if (showByHour) {
				item.timeSlotWorkDurations.map((durations) => {
					durations['assigneeId'] = id;
				});
			}
		});

		return refinedStaff;
	});

	if (returnedData.success) {
		dispatch({ type: GET_CALENDAR_STAFF, payload: refinedCalendarStaff });
	}
};

export const getCalendarStaffTasks =
	(labId, data, userId, showByHour, isLabTechnician) => async (dispatch) => {
		const response = await getAxiosWithToken().post('task/search', data, {
			params: {
				'lab-id': labId,
				'show-by-hour': showByHour,
				'user-id': userId
			}
		});

		const returnedData = response.data;
		if (returnedData.success) {
			if (isLabTechnician) {
				dispatch({
					type: GET_CALENDAR_STAFF_TASKS_LAB_TECHNICIAN,
					payload: returnedData.data.tasks || []
				});
			} else {
				dispatch({
					type: GET_CALENDAR_STAFF_TASKS,
					payload: { tasks: returnedData.data.tasks, userId }
				});
			}
		}
	};

export const clearCalendarStaffTasksDates = () => async (dispatch) => {
	dispatch({ type: CLEAR_CALENDAR_STAFF_TASKS_DATES });
};

export const getOrdersByOrderType = (orderTypeValue) => async (dispatch) => {
	dispatch({ type: GET_CALENDAR_STAFF_ORDERS, payload: [] });

	const response = await getAxiosWithToken().get('task/order/for-task-assignment', {
		params: {
			'order-type': orderTypeValue
		}
	});

	const returnedData = response.data;
	if (returnedData.success) {
		dispatch({ type: GET_CALENDAR_STAFF_ORDERS, payload: returnedData.data });
	}
};
