import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
	getCalendarStaff,
	getCalendarStaffTasks,
	getCalendarStaffWithTasks,
	getGeneralWorkingHours,
	getLabApprovedStaff,
	getLabDetails,
	showMessageModal
} from '../../../../../actions';
import moment from '../../../../../utils/moment';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	FILTER_FIELDS,
	STAFFCHART_MODES
} from '../../../../../utils/constants';
import i18n from '../../../../../i18n';
import {
	getDropdownData,
	parseStaffChartDays,
	parseStaffChartHours,
	parseStaffNestedTable
} from '../../../../../utils/tableUtils';
import {
	getLabId,
	isAllNull,
	isEmptyObject,
	isGlobalAdmin,
	parseFlagToLanguage
} from '../../../../../utils/appUtils';
import Header from '../../../../common/Header';
import StaffChart from './StaffChart';
import Filters from './Filters';
import { getFormValues } from 'redux-form';

class CalendarStaff extends React.Component {
	state = {
		dropdown: {},
		date: moment(),
		mode: STAFFCHART_MODES.WEEKS,
		currentTask: {},
		isEditTaskModalOpen: false,
		filters: {}
	};

	componentDidMount() {
		const startDate = moment(this.state.date)
			.startOf('week')
			.format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getCalendarStaff(labId, { startDate, endDate }, false);
		this.props.getGeneralWorkingHours(labId);
		this.props.getLabApprovedStaff(labId);

		if (isGlobalAdmin(this.props.account)) {
			this.props.getLabDetails(labId);
		}
	}

	render() {
		moment.locale(parseFlagToLanguage(this.props.language), { week: { dow: 1, doy: 1 } });

		const columns = [
			i18n.t('translation:taskManager.common.table.dentalTechnicianTask'),
			i18n.t('translation:taskManager.common.table.work'),
			''
		];

		const nestedTableData = [
			...parseStaffNestedTable(this.props.staff, this.openEditTaskModal)
		];
		const weekends = this.props.workingHours
			.filter((day) => day.startTime === null)
			.map((day) => day.dayOfTheWeek);
		const chartData =
			this.state.mode === STAFFCHART_MODES.WEEKS
				? parseStaffChartDays(this.props.staff)
				: parseStaffChartHours(this.props.staff);

		const simpleTitle = i18n.t('translation:taskManager.calendar.staff.title');
		const headerTitle = isGlobalAdmin(this.props.account)
			? this.props.currentLab.name + ' - ' + simpleTitle
			: simpleTitle;
		const backButtonShown = isGlobalAdmin(this.props.account) ? true : false;

		return (
			<Fragment>
				<Header title={headerTitle} backButton={backButtonShown} />
				<Filters setFilters={this.setFilters} location={this.props.location} />
				<div className="position-relative mt-s mb-m">
					<div className="position-absolute staffchart fixed-header-chart staffchart-height d-flex flex-wrap">
						<StaffChart
							nestedTableData={nestedTableData}
							setDropdown={this.setDropdown}
							columns={columns}
							dropdown={this.state.dropdown}
							mode={this.state.mode}
							triggerMode={this.triggerMode}
							onAddWeek={this.onAddWeek}
							onSubtractWeek={this.onSubtractWeek}
							onAddDay={this.onAddDay}
							onSubtractDay={this.onSubtractDay}
							chartData={chartData}
							weekends={weekends}
							onGlobalDropdownClick={this.onGlobalDropdownClick}
							filters={this.state.filters}
							staff={this.props.staff}
							account={this.props.account}
							users={this.props.users}
							getCalendarStaff={this.getCalendarStaffData}
							currentTask={this.state.currentTask}
							isEditTaskModalOpen={this.state.isEditTaskModalOpen}
							closeEditTaskModal={this.closeEditTaskModal}
						/>
						{this.renderBadges()}
					</div>
				</div>
			</Fragment>
		);
	}

	openEditTaskModal = (currentTask) => {
		this.setState({ isEditTaskModalOpen: true, currentTask: currentTask });
	};

	closeEditTaskModal = (currentTask) => {
		this.setState({ isEditTaskModalOpen: false, currentTask: {} });
	};

	getCalendarStaffData = (showByHour) => {
		const labId = getLabId(this.props.location, this.props.account);

		if (this.state.mode == 'HOURS') {
			const startDate = moment(this.state.date).format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate = moment(this.state.date).format(DEFAULT_BACKEND_DATE_FORMAT);

			this.props.getCalendarStaff(labId, { ...this.state.filters, startDate, endDate }, true);
		} else {
			const startDate = moment(this.state.date)
				.startOf('week')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate = moment(this.state.date)
				.endOf('week')
				.format(DEFAULT_BACKEND_DATE_FORMAT);

			this.props.getCalendarStaff(
				labId,
				{ ...this.state.filters, startDate, endDate },
				false
			);
		}

		this.props.getGeneralWorkingHours(labId);
		this.props.getLabApprovedStaff(labId);
	};

	setFilters = (filters) => {
		const refinedFilters = { ...filters };
		refinedFilters[FILTER_FIELDS.LAB_ORDER_ID_LIST] = refinedFilters[
			FILTER_FIELDS.LAB_ORDER_ID_LIST
		]
			? refinedFilters[FILTER_FIELDS.LAB_ORDER_ID_LIST].split(',')
			: null;
		const labId = getLabId(this.props.location, this.props.account);

		if (this.state.mode === STAFFCHART_MODES.WEEKS) {
			let startDate = moment(this.state.date)
				.startOf('isoWeek')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			let endDate = moment(this.state.date)
				.endOf('isoWeek')
				.format(DEFAULT_BACKEND_DATE_FORMAT);

			if (!isEmptyObject(refinedFilters) && !isAllNull(refinedFilters)) {
				startDate = refinedFilters.startDate
					? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date)
							.startOf('isoWeek')
							.format(DEFAULT_BACKEND_DATE_FORMAT);
				endDate = refinedFilters.endDate
					? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).endOf('isoWeek').format(DEFAULT_BACKEND_DATE_FORMAT);
			}

			this.props.getCalendarStaff(
				labId,
				{ ...refinedFilters, startDate: startDate, endDate: endDate },
				false
			);
			this.setState({ filters: refinedFilters, dropdown: [] });
		} else {
			if (this.isFilterRangeIsDay(refinedFilters)) {
				let startDate = moment(this.state.date)
					.startOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);
				let endDate = moment(this.state.date)
					.endOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);

				if (!isEmptyObject(refinedFilters) && !isAllNull(refinedFilters)) {
					startDate = refinedFilters.startDate
						? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date)
								.startOf('day')
								.format(DEFAULT_BACKEND_DATE_FORMAT);
					endDate = refinedFilters.endDate
						? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date).endOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
				}

				this.props.getCalendarStaff(
					labId,
					{ ...refinedFilters, startDate: startDate, endDate: endDate },
					true
				);
				this.setState({ filters: refinedFilters, dropdown: [] });
			} else {
				this.props.showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:taskManager.calendar.staff.rangeOverOneDayWarning')
				);
			}
		}
	};

	onGlobalDropdownClick = () => {
		const refinedFilters = this.state.filters;
		const labId = getLabId(this.props.location, this.props.account);

		if (this.state.mode === STAFFCHART_MODES.WEEKS) {
			const startDate =
				refinedFilters && refinedFilters.startDate
					? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).startOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate =
				refinedFilters && refinedFilters.endDate
					? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
			this.props.getCalendarStaffWithTasks(
				labId,
				{
					...refinedFilters,
					startDate,
					endDate
				},
				false
			);
		} else {
			const startDate =
				refinedFilters && refinedFilters.startDate
					? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).startOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate =
				refinedFilters && refinedFilters.endDate
					? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).endOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
			this.props.getCalendarStaffWithTasks(
				labId,
				{
					...refinedFilters,
					startDate,
					endDate
				},
				true
			);
		}
	};

	renderBadges = () => {
		const badges = [
			{
				title: i18n.t('translation:taskManager.calendar.staff.undertime'),
				className: 'blue'
			},
			{ title: i18n.t('translation:taskManager.calendar.staff.overtime'), className: 'red' },
			{
				title: i18n.t('translation:taskManager.calendar.staff.outsideAssigned'),
				className: 'yellow'
			},
			{
				title: i18n.t('translation:taskManager.calendar.staff.noSchedule'),
				className: 'white'
			}
		];

		const components = badges.map((badge, index) => {
			return (
				<div key={badge.title} className="badge">
					<div className={badge.className} />
					<span>{badge.title}</span>
				</div>
			);
		});

		return <div className="staff-badges">{components}</div>;
	};

	onAddWeek = (startDate, endDate) => {
		const date = this.state.date.add(1, 'W');
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getCalendarStaff(labId, { ...this.state.filters, startDate, endDate }, false);
		this.setState({ dropdown: {}, date });
	};

	onSubtractWeek = (startDate, endDate) => {
		const date = this.state.date.subtract(1, 'W');
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getCalendarStaff(labId, { ...this.state.filters, startDate, endDate }, false);
		this.setState({ dropdown: {}, date });
	};

	onAddDay = (startDate, endDate) => {
		const date = this.state.date.add(1, 'days');
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getCalendarStaff(labId, { ...this.state.filters, startDate, endDate }, true);
		this.setState({ dropdown: {}, date });
	};

	onSubtractDay = (startDate, endDate) => {
		const date = this.state.date.subtract(1, 'days');
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getCalendarStaff(labId, { ...this.state.filters, startDate, endDate }, true);
		this.setState({ dropdown: {}, date });
	};

	isFilterRangeIsDay = (filters) => {
		const startDate = filters.startDate;
		const endDate = filters.endDate;
		if (startDate && endDate) {
			const diff = moment(endDate).diff(moment(startDate), 'days');
			return diff === 0;
		}
		return true;
	};

	triggerMode = (mode) => {
		const labId = getLabId(this.props.location, this.props.account);

		if (mode === STAFFCHART_MODES.WEEKS) {
			const startDate =
				this.state.filters && this.state.filters.startDate
					? moment(this.state.filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).startOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate =
				this.state.filters && this.state.filters.endDate
					? moment(this.state.filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
			this.setState({ mode, dropdown: {} });
			this.props.getCalendarStaff(
				labId,
				{ ...this.state.filters, startDate, endDate },
				false
			);
		} else {
			if (this.isFilterRangeIsDay(this.state.filters)) {
				const startDate =
					this.state.filters && this.state.filters.startDate
						? moment(this.state.filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date)
								.startOf('day')
								.format(DEFAULT_BACKEND_DATE_FORMAT);
				const endDate =
					this.state.filters && this.state.filters.endDate
						? moment(this.state.filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date).endOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
				this.setState({ mode, dropdown: {} });
				this.props.getCalendarStaff(
					labId,
					{ ...this.state.filters, startDate, endDate },
					true
				);
			} else {
				this.props.showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:taskManager.calendar.staff.rangeOverOneDayWarning')
				);
			}
		}
	};

	setDropdown = (elementIndex, isExpanded, data) => {
		const labId = getLabId(this.props.location, this.props.account);

		if (isExpanded && data) {
			const filters = this.state.filters;
			if (this.state.mode === STAFFCHART_MODES.WEEKS) {
				const startDate =
					filters && filters.startDate
						? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date)
								.startOf('week')
								.format(DEFAULT_BACKEND_DATE_FORMAT);
				const endDate =
					filters && filters.endDate
						? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
				this.props.getCalendarStaffTasks(
					labId,
					{ ...filters, startDate, endDate },
					data[2],
					false
				);
			} else {
				const startDate =
					filters && filters.startDate
						? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date)
								.startOf('day')
								.format(DEFAULT_BACKEND_DATE_FORMAT);
				const endDate =
					filters && filters.endDate
						? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: moment(this.state.date).endOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
				this.props.getCalendarStaffTasks(
					labId,
					{ ...filters, startDate, endDate },
					data[2],
					true
				);
			}
		}
		this.setState({ dropdown: getDropdownData(elementIndex, this.state.dropdown, isExpanded) });
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		staff: state.calendar.staff,
		workingHours: state.workingHours,
		users: state.users.users.content,
		filters: getFormValues('calendarStaffFiltersForm')(state) || {},
		currentLab: state.labs.currentLab,
		language: state.settings.language
	};
};

export default connect(mapStateToProps, {
	getCalendarStaff,
	getGeneralWorkingHours,
	getCalendarStaffTasks,
	getCalendarStaffWithTasks,
	getLabApprovedStaff,
	showMessageModal,
	getLabDetails
})(CalendarStaff);
