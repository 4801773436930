import React, { Fragment } from 'react';

class SubHeader extends React.Component {
	render() {
		const className = this.props.className ?? '';

		return (
			<Fragment>
				<div className={`sub-header ${className}`}>
					<div className="d-flex flex-row align-items-end">
						{this.props.leftIcon && this.renderIcon()}
						{this.renderTitle()}
						{!this.props.leftIcon && this.renderIcon()}
						{this.renderTrigger()}
						<div className={'buttons ml-auto ' + this.props.readOnly}>
							{this.renderButtons()}
						</div>
					</div>
				</div>
				<hr />
			</Fragment>
		);
	}

	renderButtons = () => {
		return this.props.buttons ? (
			this.props.buttons.map((button, index) => (
				<Fragment key={index + 'subHeaderButton'}>{button}</Fragment>
			))
		) : (
			<Fragment />
		);
	};

	renderIcon = () => {
		return this.props.icon ? <div>{this.props.icon}</div> : null;
	};

	renderTitle = () => {
		if (this.props.title) {
			if (typeof this.props.title === 'string') {
				const headerClass = this.props.first ? 'mt-s' : 'mt-m';
				return <h2 className={headerClass}>{this.props.title}</h2>;
			} else {
				return this.props.title;
			}
		}
	};

	renderTrigger = () => {
		if (this.props.onTrigger) {
			return this.props.isOpen ? (
				<span
					className="arrow-up arrow-up-sub-header ml-xs"
					onClick={this.props.onTrigger}
				/>
			) : (
				<span
					className="arrow-down arrow-down-sub-header ml-xs"
					onClick={this.props.onTrigger}
				/>
			);
		}
	};
}

export default SubHeader;
