import {
	ADD_CLINIC_LOCATION,
	CLEAR_CURRENT_CLINIC,
	CLEAR_REDUCER,
	DELETE_CLINIC_LOCATION,
	GET_CLINIC_DETAILS,
	GET_CLINICS,
	GET_CLINICS_WITH_PAGINATION,
	UPDATE_CLINIC,
	UPDATE_CLINIC_LOCATION
} from '../actions/types';
import _ from 'lodash';

const initState = {
	currentClinic: {},
	clinics: []
};

const clinicsReducer = (state = { ...initState }, action) => {
	if (action.type === GET_CLINICS_WITH_PAGINATION) {
		state.clinics = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_CLINIC_DETAILS) {
		state.currentClinic = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_CLINICS) {
		state.clinics = action.payload;
		return { ...state };
	}

	if (action.type === UPDATE_CLINIC) {
		state.currentClinic = { ...action.payload };
		return { ...state };
	}

	if (action.type === ADD_CLINIC_LOCATION) {
		state.currentClinic.locations.push(action.payload);
		state.currentClinic.locations = [...state.currentClinic.locations];
		state.currentClinic = { ...state.currentClinic };
		return { ...state };
	}

	if (action.type === UPDATE_CLINIC_LOCATION) {
		let index = _.findIndex(state.currentClinic.locations, { id: action.payload.id });
		state.currentClinic.locations.splice(index, 1, action.payload);
		state.currentClinic.locations = [...state.currentClinic.locations];
		state.currentClinic = { ...state.currentClinic };
		return { ...state };
	}

	if (action.type === DELETE_CLINIC_LOCATION) {
		_.remove(state.currentClinic.locations, function (location) {
			return +location.id === +action.payload;
		});
		state.currentClinic.locations = [...state.currentClinic.locations];
		state.currentClinic = { ...state.currentClinic };
		return { ...state };
	}

	if (action.type === CLEAR_CURRENT_CLINIC) {
		state.currentClinic = {};
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default clinicsReducer;
