import React from 'react';
import ClientsDropdownField from '../../common/UI/ClientsDropdownField';
import { change, Field, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import {
	getLinkedClinicsWithoutPagination,
	searchAllBillingDocuments,
	searchAllTransactions,
	setCurrentCreditDocuments
} from '../../../../../actions';
import {
	BILLING_DOCUMENT_STATUS,
	OUTCOME_DOCUMENT_TYPE,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_METHOD,
	TRANSACTION_STATUS,
	TRANSACTION_TYPE
} from '../../../../../utils/constants';
import i18n from '../../../../../i18n';
import {
	customDateInput,
	invoicingTableInput,
	selectInput,
	text,
	textArea,
	textInput
} from '../../../../common/Fields';
import { formUtils } from '../../../../../utils/formUtils';
import { GET_CURRENT_CREDIT_DOCUMENTS } from '../../../../../actions/types';
import { subtract } from '../../invoicing/billingDocumentModal/utils/documentUtils';
import { parseInvoicingCurrency } from '../../../../../utils/tableUtils';
import { getSelectedTaxType } from '../../../../../utils/appUtils';

class OutcomeDetailsFilters extends React.Component {
	constructor(props) {
		super(props);
		this.reminderRef = React.createRef();
		this.state = {
			intersecting: null
		};
	}

	componentDidMount() {
		this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);

		this.observer = new IntersectionObserver(([entry]) => {
			const updatedIntersecting = entry.isIntersecting;
			if (updatedIntersecting !== this.state.intersecting) {
				this.setState({ intersecting: updatedIntersecting });
			}
		});
		this.observer.observe(this.reminderRef?.current);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//on preview
		if (prevProps.clinicId !== this.props.clinicId) {
			this.fillInitialData();
		}
	}

	componentWillUnmount() {
		this.observer.unobserve(this.reminderRef?.current);
	}

	fillInitialData() {
		const {
			TRANSACTION_CLINIC_ID,
			TRANSACTION_METHOD,
			TRANSACTION_AMOUNT,
			TRANSACTION_DATE,
			TRANSACTION_REFERENCES_NOTES,
			TRANSACTION_DOCUMENT_TYPE,
			TRANSACTION_COMMON_NOTES
		} = TRANSACTION_FORM_FIELD;
		const {
			clinicId,
			referencesNotes,
			transactionDate,
			transactionMethod,
			amount,
			billingDocuments,
			commonNotes
		} = this.props;

		const transactionDocumentType = billingDocuments.length
			? OUTCOME_DOCUMENT_TYPE.CREDIT_NOTE
			: OUTCOME_DOCUMENT_TYPE.DEBIT_NOTE;

		this.props.change('outcomesDetailsForm', TRANSACTION_CLINIC_ID, clinicId);
		this.props.change('outcomesDetailsForm', TRANSACTION_REFERENCES_NOTES, referencesNotes);
		this.props.change('outcomesDetailsForm', TRANSACTION_METHOD, transactionMethod);
		this.props.change('outcomesDetailsForm', TRANSACTION_DATE, new Date(transactionDate));
		this.props.change('outcomesDetailsForm', TRANSACTION_AMOUNT, amount);
		this.props.change('outcomesDetailsForm', TRANSACTION_COMMON_NOTES, commonNotes);
		this.props.change(
			'outcomesDetailsForm',
			TRANSACTION_DOCUMENT_TYPE,
			transactionDocumentType
		);
	}

	isCreation() {
		return this.props.isCreation;
	}

	onDocumentsTypeChange(value) {
		const {
			TRANSACTION_DOCUMENT_TYPE,
			TRANSACTION_CLINIC_ID,
			TRANSACTION_REMINDER,
			TRANSACTION_AMOUNT
		} = TRANSACTION_FORM_FIELD;
		const clinicId = this.props.formValues[TRANSACTION_CLINIC_ID];
		const transactionAmount = this.props.formValues[TRANSACTION_AMOUNT] ?? 0;

		this.props.change('outcomesDetailsForm', TRANSACTION_DOCUMENT_TYPE, value);
		this.props.change('outcomesDetailsForm', TRANSACTION_REMINDER, transactionAmount);

		//if empty option chosen
		if (!value.toString().trim().length) {
			this.props.setCurrentCreditDocuments([]);
			this.resetInputFields();
			return;
		}

		if (clinicId === undefined) {
			return;
		}
		const clinicLegalName = this.props.clinics.find(
			(clinic) => clinic.id.toString() === clinicId.toString()
		).legalName;

		this.getDocuments(value, clinicLegalName);
	}

	onClientChange = async (target) => {
		const {
			TRANSACTION_DOCUMENT_TYPE,
			TRANSACTION_CLINIC_ID,
			TRANSACTION_REMINDER,
			TRANSACTION_AMOUNT
		} = TRANSACTION_FORM_FIELD;
		const documentsType = this.props.formValues[TRANSACTION_DOCUMENT_TYPE];

		const { options } = target;
		const clinicLegalName = options[options.selectedIndex].text;
		const clinicId = options[options.selectedIndex].value;
		const transactionAmount = this.props.formValues[TRANSACTION_AMOUNT] ?? 0;

		this.props.change('outcomesDetailsForm', TRANSACTION_CLINIC_ID, clinicId);
		this.props.change('outcomesDetailsForm', TRANSACTION_REMINDER, transactionAmount);

		//if empty option chosen
		if (!clinicId.toString().trim().length) {
			this.props.setCurrentCreditDocuments([]);
			this.resetInputFields();
			return;
		}

		if (!documentsType) {
			return;
		}

		this.getDocuments(documentsType, clinicLegalName);
	};

	resetInputFields() {
		const {
			TRANSACTION_REMINDER,
			TRANSACTION_AMOUNT,
			TRANSACTION_METHOD,
			TRANSACTION_DATE,
			TOTAL_PAYED
		} = TRANSACTION_FORM_FIELD;
		this.props.change('outcomesDetailsForm', TRANSACTION_REMINDER, null);
		this.props.change('outcomesDetailsForm', TRANSACTION_AMOUNT, null);
		this.props.change('outcomesDetailsForm', TRANSACTION_METHOD, null);
		this.props.change('outcomesDetailsForm', TRANSACTION_DATE, null);
		this.props.change('outcomesDetailsForm', TOTAL_PAYED, null);
	}

	getDocuments(outcomeDocumentType, clinicLegalName) {
		if (outcomeDocumentType === OUTCOME_DOCUMENT_TYPE.CREDIT_NOTE) {
			this.props.searchAllBillingDocuments({
				filters: {
					labId: this.props.account.labId,
					clinicLegalName,
					types: [outcomeDocumentType],
					statuses: [
						BILLING_DOCUMENT_STATUS.OPENED,
						BILLING_DOCUMENT_STATUS.PAID_PARTIALLY
					]
				},
				actionType: GET_CURRENT_CREDIT_DOCUMENTS,
				documentsToExclude: []
			});
		} else {
			searchAllTransactions({
				type: TRANSACTION_TYPE.DEBIT,
				labId: this.props.account.labId,
				statuses: [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.PARTIALLY],
				clinicLegalName,
				fullyPaid: false
			}).then((data) => {
				this.props.setCurrentCreditDocuments(data);
			});
		}
	}

	getFieldsAreDisabled() {
		if (this.isCreation()) {
			const { TRANSACTION_CLINIC_ID, TRANSACTION_DOCUMENT_TYPE } = TRANSACTION_FORM_FIELD;
			const formValues = this.props.formValues;
			const clinicId = formValues[TRANSACTION_CLINIC_ID];
			const documentsType = formValues[TRANSACTION_DOCUMENT_TYPE];
			return (
				clinicId === undefined ||
				!clinicId.trim().length ||
				documentsType === undefined ||
				documentsType === null
			);
		}

		return true;
	}

	renderOutcomeMethod() {
		const disabled = this.getFieldsAreDisabled();

		const options = [
			{ name: '', value: null },
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.bankTransfer'),
				value: TRANSACTION_METHOD.BANK
			},
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.posTerminal'),
				value: TRANSACTION_METHOD.POS
			},
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.cash'),
				value: TRANSACTION_METHOD.CASH
			}
		];

		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_METHOD}
				label={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.common.outcomeMethod'
				)}
				customProps={{
					className: 'mr-0 col-12 pl-0 pr-0',
					options: options,
					blueLabel: true,
					disabled
				}}
				component={selectInput}
			/>
		);
	}

	renderDocumentType() {
		const disabled = !this.isCreation();

		const options = [
			{ name: '', value: null },
			{
				name: i18n.t(
					'translation:billingModule.incomeOutcomeDetails.outcomeDetails.incomes'
				),
				value: OUTCOME_DOCUMENT_TYPE.DEBIT_NOTE
			},
			{
				name: i18n.t(
					'translation:billingModule.incomeOutcomeDetails.outcomeDetails.creditNotes'
				),
				value: OUTCOME_DOCUMENT_TYPE.CREDIT_NOTE
			}
		];

		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_DOCUMENT_TYPE}
				label={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.outcomeDetails.documentType'
				)}
				customProps={{
					className: 'mr-0 col-12 pl-0 pr-0',
					options: options,
					blueLabel: true,
					disabled
				}}
				component={selectInput}
				onChange={(event) => this.onDocumentsTypeChange(event.target.value)}
			/>
		);
	}

	renderOutcomeDate() {
		const disabled = this.getFieldsAreDisabled();

		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_DATE}
				customProps={{
					label: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.common.outcomeDate'
					),
					className: 'd-block pr-0',
					blueLabel: true,
					disabled,
					maxDate: new Date()
				}}
				component={customDateInput}
			/>
		);
	}

	renderClientsDropdown(clinics) {
		return (
			<ClientsDropdownField
				clients={clinics}
				name={TRANSACTION_FORM_FIELD.TRANSACTION_CLINIC_ID}
				onChange={(event) => this.onClientChange(event.target)}
				blueLabel={true}
				disabled={!this.isCreation()}
			/>
		);
	}

	renderAmountInput() {
		const disabled = this.getFieldsAreDisabled();
		const taxType = getSelectedTaxType(this.props.billingSettings.taxType);

		return (
			<div className={'col-4'}>
				<label className={'blue-label'}>
					{i18n.t('translation:billingModule.incomeOutcomeDetails.common.amountWithTax', {
						taxType: taxType
					})}
				</label>
				<Field
					name={TRANSACTION_FORM_FIELD.TRANSACTION_AMOUNT}
					customProps={{
						className: 'col-12 p-0 w-100 amount',
						disabled
					}}
					normalize={formUtils.normalize.price}
					component={invoicingTableInput}
					onChange={(e) => this.onAmountChange(e.target.value)}
					currency={true}
					localizedCurrencyAbbreviation={
						this.props.currentLab.localizedCurrencyAbbreviation
					}
					currencySymbol={this.props.currentLab.currencySymbol}
				/>
			</div>
		);
	}

	renderReminderBar() {
		if (
			this.state.intersecting ||
			this.props.status === TRANSACTION_STATUS.COMPLETED ||
			this.props.status === TRANSACTION_STATUS.CANCELED
		) {
			return null;
		}
		const title = i18n.t('translation:billingModule.incomeOutcomeDetails.common.remainder');

		const reminder = Number(
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER] ?? 0
		);

		const isReminderErrored = Number(reminder) < 0;

		return (
			<div
				className={
					'w-100 incomes-details-reminder-fixed-top font-weight-bold text-right pr-5 shadow-sm '
				}
				style={{ zIndex: 2000, paddingLeft: '30px', paddingRight: '30px' }}>
				<div>
					{`${title}: `}
					{parseInvoicingCurrency(
						reminder,
						this.props.currentLab.localizedCurrencyAbbreviation,
						this.props.currentLab.currencySymbol
					)}
				</div>
				{isReminderErrored && (
					<div className={'p-1'} style={{ color: 'red' }}>
						{i18n.t(
							'translation:billingModule.incomeOutcomeDetails.errors.amountDistribute'
						)}
					</div>
				)}
			</div>
		);
	}

	renderRemind() {
		const reminder = Number(
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER] ?? 0
		);

		const labelClass = reminder === 0 ? 'blue-label' : 'red-label';

		return (
			<div className="col-4">
				<label
					className={labelClass}
					title={i18n.t(
						'translation:billingModule.incomesOutcomes.table.outcomeReminderTooltip'
					)}>
					{i18n.t('translation:billingModule.incomeOutcomeDetails.common.remainder')}*
				</label>
				<Field
					name={TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER}
					component={text}
					value={this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER]}
					defaultValue={parseInvoicingCurrency(
						0,
						this.props.currentLab.localizedCurrencyAbbreviation,
						this.props.currentLab.currencySymbol
					)}
					currency={true}
					localizedCurrencyAbbreviation={
						this.props.currentLab.localizedCurrencyAbbreviation
					}
					currencySymbol={this.props.currentLab.currencySymbol}
				/>
			</div>
		);
	}

	renderReferenceNotes() {
		if (
			!this.isCreation() &&
			!this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REFERENCES_NOTES]
		) {
			return null;
		}
		const disabled = this.getFieldsAreDisabled();
		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_REFERENCES_NOTES}
				label={i18n.t('translation:billingModule.incomeOutcomeDetails.common.reference')}
				customProps={{ className: 'col-12', blueLabel: true, disabled: disabled }}
				component={textInput}
				maxLength={250}
			/>
		);
	}

	renderCommonNotes() {
		if (
			!this.isCreation() &&
			!this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_COMMON_NOTES]
		) {
			return null;
		}
		const disabled = this.getFieldsAreDisabled();
		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_COMMON_NOTES}
				label={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.incomeDetails.incomeNotes'
				)}
				customProps={{ className: 'col-12', blueLabel: true, disabled: disabled }}
				component={textArea}
				maxLength={250}
			/>
		);
	}

	onAmountChange(value) {
		const normalizeValue = formUtils.normalize.price(value);

		if (value !== normalizeValue) {
			return;
		}

		const totalPayed = this.props.formValues[TRANSACTION_FORM_FIELD.TOTAL_PAYED] ?? 0;
		const reminder = subtract(value, totalPayed);
		this.props.change('outcomesDetailsForm', TRANSACTION_FORM_FIELD.TRANSACTION_AMOUNT, value);
		this.props.change(
			'outcomesDetailsForm',
			TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER,
			reminder
		);
	}

	render() {
		return (
			<div className="col-12 p-0 data-section" ref={this.reminderRef}>
				<div className="content filters outcomes-details-filters">
					<div className="row pt-4">
						<div className="col-4">
							{this.renderClientsDropdown(this.props.clinics)}
						</div>
						<div className="col-4">{this.renderDocumentType()}</div>
						<div className="col-4">{this.renderOutcomeDate()}</div>
					</div>
					<div className="row pt-4">
						<div className="col-4">{this.renderOutcomeMethod()}</div>
						{this.renderAmountInput()}
						{this.renderRemind()}
					</div>
					<div className="row pt-4">{this.renderReferenceNotes()}</div>
					<div className="row pt-4">{this.renderCommonNotes()}</div>
					{this.renderReminderBar()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		clinics: state.clinics.clinics,
		account: state.account,
		formValues: getFormValues('outcomesDetailsForm')(state),
		currentLab: state.labs.currentLab,
		billingSettings: state.billingSettings
	};
};

export default connect(mapStateToProps, {
	getLinkedClinicsWithoutPagination,
	searchAllBillingDocuments,
	setCurrentCreditDocuments,
	change,
	reset
})(OutcomeDetailsFilters);
