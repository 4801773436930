import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import i18n from '../../../i18n';
import { formUtils } from '../../../utils/formUtils';
import { clearSignUpUserEmail, sendInvitationToUser } from '../../../actions';
import { EMPTY_STRING } from '../../../utils/constants';

class SendUserInvitationModal extends React.Component {
	constructor() {
		super();
		this.state = this.getInitialState();
	}

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const email = nextProps.initialEmail ? nextProps.initialEmail : EMPTY_STRING;
			this.props.initialize({ email });
		}
	}

	render() {
		const sendButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.send')}
				type="submit"
				key={i18n.t('translation:common.buttons.send')}
				onClick={this.props.handleSubmit(this.onFormSubmit)}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModalResettingState();
				}}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll send-invitation-modal"
				overlayClassName="custom-overlay">
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={() => {
							this.closeModalResettingState();
						}}
					/>
					{this.renderTitle()}

					{Fields.commonFields.email({
						label: this.getEmailLabel(),
						className: 'input-container full-width mb-s'
					})}

					{Fields.commonFields.sendInAnotherLanguage({
						onChange: this.onSendInAnotherLanguageChanged
					})}

					{this.state.sendInAnotherLanguage
						? Fields.commonFields.languageCode({
								options: formUtils.options.getLanguages(),
								className: 'input-container full-width'
						  })
						: null}

					{this.renderError()}
					<div className="buttons modal-dialog-buttons-below-error-message">
						{sendButton}
						{cancelButton}
					</div>
				</form>
			</Modal>
		);
	}

	onSendInAnotherLanguageChanged = (e) => {
		this.setState({
			sendInAnotherLanguage: e.target.checked
		});
	};

	getEmailLabel = () =>
		this.props.isInvitationToDentist
			? i18n.t('translation:userInvitationModal.emailLabelWhenInvitingDentist')
			: i18n.t('translation:userInvitationModal.emailLabelWhenInvitingLabTechnician');

	renderTitle = () => (
		<h2>
			{this.props.isInvitationToDentist
				? i18n.t('translation:userInvitationModal.dentistInvitationTitle')
				: i18n.t('translation:userInvitationModal.labTechnicianInvitationTitle')}
		</h2>
	);

	renderError = () => {
		if (this.state.errorExists) {
			return (
				<div className="align-items-start text-danger position-absolute modal-dialog-error-message">
					{this.state.errorMessage}
				</div>
			);
		}
	};

	onFormSubmit = (formValues) => {
		// In case no language code is provided we send empty string, not null. Otherwise there will
		// be 'Bad Request' (status code 400) from the backend
		const languageCode = formValues.sendInAnotherLanguage
			? formValues.languageCode
			: EMPTY_STRING;

		var reSendingInvitation = false;
		this.props.sendInvitationToUser(
			formValues.email,
			languageCode,
			reSendingInvitation,
			this.displayErrorMessage,
			this.closeModalResettingState
		);
	};

	closeModalResettingState = () => {
		this.resetState();

		this.props.closeModal();
		this.props.destroy();
	};

	resetState = () => {
		this.setState(this.getInitialState());

		this.clearReduxForm();

		this.props.clearSignUpUserEmail();
	};

	getInitialState = () => {
		return {
			errorExists: false,
			errorMessage: EMPTY_STRING,
			email: EMPTY_STRING,
			sendInAnotherLanguage: false
		};
	};

	clearReduxForm = () => {
		this.props.reset();
	};

	displayErrorMessage = (errorMessage) => {
		this.setState({ errorExists: true, errorMessage });
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};

const sendUserInvitationForm = reduxForm({
	form: 'sendUserInvitationForm',
	validate: formUtils.validateSendUserInvitationModal
})(SendUserInvitationModal);

export default connect(mapStateToProps, {
	sendInvitationToUser,
	clearSignUpUserEmail
})(sendUserInvitationForm);
