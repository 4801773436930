import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from '../../../../../utils/moment';
import {
	getAbsenceByType,
	isEmptyObject,
	parseColorCodeToBackgroundColor
} from '../../../../../utils/appUtils';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarkDaysModal from './MarkDaysModal';
import { reset } from 'redux-form';
import { setCommonReducer, showMessageModal } from '../../../../../actions';
import { COMMON_REDUCER_TYPES, WORKDAY_TYPES } from '../../../../../utils/constants';
import i18n from '../../../../../i18n';
import { ScrollSyncPane } from 'react-scroll-sync';

class ShiftsTable extends React.Component {
	state = { isMarkDaysModalOpen: false, initialValues: {} };

	componentDidMount() {
		this.props.setCommonReducer({
			name: COMMON_REDUCER_TYPES.OPEN_MODAL,
			value: this.openModal
		});
	}

	render() {
		return (
			<Fragment>
				<MarkDaysModal
					isModalOpen={this.state.isMarkDaysModalOpen}
					closeModal={this.closeModal}
					shiftsAndAbsences={this.props.shiftsAndAbsences}
					initialValues={this.state.initialValues}
					isMultiMarkDaysModalOpen={this.props.isMultiMarkDaysModalOpen}
					staff={[...this.props.staff]}
					getStaff={this.props.getStaff}
				/>
				<div className="shifts-table">
					{this.renderDate()}
					<ScrollSyncPane group="vertical">
						<div className="calendar-table">
							<div>
								<div className="days chart-header">{this.renderDays()}</div>
								{this.renderData()}
							</div>
						</div>
					</ScrollSyncPane>
				</div>
			</Fragment>
		);
	}

	closeModal = () => {
		this.setState({ isMarkDaysModalOpen: false, initialValues: { fields: '' } });
		this.props.reset('markDaysForm');
	};

	openModal = (staff, day) => {
		if (!day) {
			this.setState({
				isMarkDaysModalOpen: true
			});
		} else {
			const startDate = new Date(day.date);
			const endDate = new Date(day.date);

			this.setState({
				isMarkDaysModalOpen: true,
				initialValues: {
					startDate,
					endDate,
					performer: staff.userId,
					labTechnicianOptions: { name: staff.userName, value: staff.userId },
					notes: day.notes,
					shiftAndAbsence: day.shiftId || day.type
				}
			});
		}
	};

	renderDays = () => {
		const staff = this.props.staff;

		const borderRight = (isLast) => (isLast ? {} : { borderRight: 'none' });
		const borderLeft = (isFirst) => (isFirst ? { borderLeft: 'none' } : {});

		if (staff[0]) {
			return staff[0].calendar.map((day, index) => (
				<div
					className="day"
					style={{
						...borderLeft(index === 0),
						...borderRight(index === staff[0].calendar.length - 1)
					}}
					key={'day' + index}>
					{moment(day.date).format('DD.MM')}
				</div>
			));
		}
	};

	renderDate = () => {
		const filters = this.props.filters;
		const arrowLeft = !filters.startDate ? (
			<FontAwesomeIcon
				onClick={this.props.subtractMonth}
				icon={faSortDown}
				className="fa-rotate-90 pointer"
			/>
		) : null;
		const arrowRight = !filters.startDate ? (
			<FontAwesomeIcon
				onClick={this.props.addMonth}
				icon={faSortDown}
				className="fa-rotate-270 pointer"
			/>
		) : null;

		const startDate = filters.startDate
			? moment(filters.startDate).format('DD.MM.YYYY')
			: this.props.date.startOf('month').format('DD.MM.YYYY');
		const endDate = filters.endDate
			? moment(filters.endDate).format('DD.MM.YYYY')
			: this.props.date.endOf('month').format('DD.MM.YYYY');

		return (
			<div className="range">
				{arrowLeft}
				<p className="m-0">
					{startDate} - {endDate}
				</p>
				{arrowRight}
			</div>
		);
	};

	renderData = () => {
		const staff = this.props.staff;

		if (!isEmptyObject(staff)) {
			return staff.map((currentStaff, index) => this.renderDataRow(currentStaff, index));
		}
	};

	renderDataRow = (staff, index) => {
		const data = staff.calendar.map((day, index) =>
			this.renderDataElement(
				day,
				index === staff.calendar.length - 1,
				index === 0,
				staff,
				index
			)
		);

		return (
			<div key={'shift_data_row' + index} className="data-row">
				{data}
			</div>
		);
	};

	renderDataElement = (day, isLast, isFirst, staff, index) => {
		const borderLeft = isFirst ? { borderLeft: 'none' } : {};
		const borderRight = isLast ? {} : { borderRight: 'none' };

		const absence = getAbsenceByType(day.type, this.props.absences);
		const style =
			day.isWeekend && day.type !== WORKDAY_TYPES.PUBLIC_HOLIDAY
				? day.type == 'SICK_LEAVE'
					? {
							...borderRight,
							...borderLeft,
							...parseColorCodeToBackgroundColor(absence['backgroundColor'])
					  }
					: { ...borderRight, ...borderLeft }
				: {
						...borderLeft,
						...borderRight,
						...parseColorCodeToBackgroundColor(absence.backgroundColor)
				  };

		const className = day.isWeekend
			? 'data-element light-blue pointer'
			: 'data-element pointer';
		return (
			<div
				key={'data' + index}
				onClick={() => {
					if (!staff.isActive) {
						this.props.showMessageModal(
							i18n.t('translation:common.warning'),
							i18n.t('translation:taskManager.common.disabledEmployee')
						);
					} else {
						this.openModal(staff, day);
					}
				}}
				className={className}
				style={style}>
				{day.shiftCode}
			</div>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		absences: state.absences
	};
};

export default connect(mapStateToProps, { reset, setCommonReducer, showMessageModal })(ShiftsTable);
