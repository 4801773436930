import { history } from '../index';
import { isEmptyObject } from './appUtils';

const getRoute = () => history.location.pathname;

const navigate = (path, data, pathParams, query = null) => {
	if (!isEmptyObject(pathParams)) {
		for (let param in pathParams) {
			path = path.replace(':' + param, pathParams[param]);
		}
	}
	if (query) {
		history.push(
			{
				pathname: path,
				search:
					'?' +
					JSON.stringify(query)
						.replaceAll('"', '')
						.replaceAll('{', '')
						.replaceAll('}', '')
						.replaceAll(':', '=')
						.replaceAll(',', '&')
			},
			data
		);
	} else {
		history.push(path, data);
	}
};

const goBack = () => history.goBack();

const isMenuItemActive = (currentPage) => {
	let styleClasses = '';

	const pathComponents = currentPage.split('/');
	pathComponents.pop();
	const subPath = pathComponents.join('/');

	if (
		currentPage.split('/')[currentPage.split('/').length - 1] ===
			getRoute().split('/')[currentPage.split('/').length - 1] &&
		getRoute().startsWith(subPath)
	) {
		styleClasses += 'active ';
	}
	if (getRoute() !== currentPage) {
		styleClasses += 'pointer ';
	}

	return styleClasses;
};

const navigationMethods = {
	getRoute,
	navigate,
	goBack,
	isMenuItemActive
};

export default navigationMethods;
