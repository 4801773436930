import { GET_HOLIDAYS } from '../../actions/types';

const initState = [];

const holidayReducer = (state = [...initState], action) => {
	if (action.type === GET_HOLIDAYS) {
		state = action.payload;
		return [...state];
	}

	return state;
};

export default holidayReducer;
