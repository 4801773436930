import { getAxiosWithToken } from '../../utils/webApi';
import { CLEAR_DEBTOR_DAYS_DOCUMENTS, GET_DEBTOR_DAYS, GET_DEBTOR_DAYS_DOCUMENTS } from '../types';
import { BALANCE_FORM_FIELD } from '../../utils/constants';
import moment from '../../utils/moment';

export const getDebtorDays =
	(labId, page = 0, date) =>
	async (dispatch) => {
		if (page) {
			page = page - 1;
		}

		let toDate = date;

		if (toDate) {
			toDate = moment(toDate).format('YYYY-MM-DD');
		} else {
			toDate = undefined;
		}

		const response = await getAxiosWithToken().post(
			`billing/debtor-days`,
			{ labId, toDate },
			{
				params: { page }
			}
		);

		let returnedData = response.data;

		if (returnedData.success) {
			dispatch({
				type: GET_DEBTOR_DAYS,
				payload: returnedData.data
			});
		}
	};

export const getDebtorDaysDocuments =
	({ labId, clinicId, type, overdueIntervalId, page = 0, date }) =>
	async (dispatch) => {
		if (page) {
			page = page - 1;
		}

		let toDate = date;

		if (toDate) {
			toDate = moment(toDate).format('YYYY-MM-DD');
		} else {
			toDate = undefined;
		}

		const response = await getAxiosWithToken().post(
			`/billing/debtor-days/documents`,
			{
				labId,
				clinicId,
				type,
				toDate,
				overdueIntervalId
			},
			{ params: { page, size: 8 } }
		);
		let returnedData = response.data;

		if (returnedData.success) {
			dispatch({
				type: GET_DEBTOR_DAYS_DOCUMENTS,
				payload: returnedData.data
			});
		}
	};

export const clearDebtorDaysDocuments = () => async (dispatch) => {
	dispatch({
		type: CLEAR_DEBTOR_DAYS_DOCUMENTS
	});
};
