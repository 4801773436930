import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import SubHeader from '../../common/SubHeader';
import { reduxForm, reset } from 'redux-form';
import { Fields } from '../../common/Fields';
import { formUtils } from '../../../utils/formUtils';
import { PAGES_PATHS } from '../../../utils/constants';
import DefaultButton from '../../common/DefaultButton';
import { changePassword, clearReducers } from '../../../actions';
import navigationUtils from '../../../utils/navigationUtils';

class ChangePassword extends React.Component {
	componentWillUnmount = () => {
		this.props.clearReducers();
	};

	render = () => {
		if (navigationUtils.getRoute() !== PAGES_PATHS.MY_ACCOUNT) {
			return null;
		}

		return (
			<div className="data-section">
				<SubHeader title={i18n.t('translation:users.password')} />
				<div className="content mt-s">
					<form onSubmit={this.props.handleSubmit(this.onChangePassword)}>
						<div className="row p-0">
							<div className="col-12 align-self-center">
								{Fields.commonFields.oldPassword({ className: 'first' })}
								{Fields.commonFields.password({
									label: i18n.t('translation:users.fields.newPassword')
								})}
								{Fields.commonFields.repeatPassword()}
							</div>
						</div>
						<div className="mt-s">{this.getSaveButton()}</div>
					</form>
				</div>
			</div>
		);
	};

	onChangePassword = (formValues) => {
		const { password, oldPassword } = formValues;
		this.props.changePassword(oldPassword, password);
	};

	getSaveButton = () => {
		return <DefaultButton title={i18n.t('translation:common.buttons.save')} />;
	};
}

const afterSubmit = (result, dispatch) => dispatch(reset('changePasswordForm'));

const changePasswordForm = reduxForm({
	form: 'changePasswordForm',
	enableReinitialize: true,
	validate: formUtils.validatePasswordChange,
	onSubmitSuccess: afterSubmit
})(ChangePassword);

export default connect(null, {
	changePassword,
	clearReducers
})(changePasswordForm);
