import React, { Fragment } from 'react';
import i18n from '../../../i18n';
import SubHeader from '../../common/SubHeader';
import Table from '../../common/Table';
import DefaultButton from '../../common/DefaultButton';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ORDER_FIELDS } from '../../../utils/constants';
import OrderItemModal from './OrderItemModal';
import {
	getOrderItemsAverageTax,
	getOrderItemsTotalPriceWithoutTax,
	getOrderItemsTotalPriceWithTax,
	parseInvoicingCurrency,
	parseOrderItems
} from '../../../utils/tableUtils';
import { formUtils } from '../../../utils/formUtils';
import { getLabInventoryOnly, switchColorScheme } from '../../../actions';
import {
	getFirstOrderItemWithColor,
	getLabFromOrder,
	getSelectedTaxType,
	isEmptyObject,
	isLabAdmin,
	parseColorSchemeConstantToValue
} from '../../../utils/appUtils';
import { getFormValues } from 'redux-form';
import AddTasksModal from '../taskManager/orders/AddTasksModal';

class OrderItems extends React.Component {
	state = { isModalOpen: false, isAddTasksModalOpen: false, currentProduct: null, colors: [] };

	componentDidMount() {
		this.props.getMyLabCurrentInventory(
			formUtils.getFieldByType(this.props.currentOrder.fields, ORDER_FIELDS.LAB).value.id,
			false,
			formUtils.getFieldByType(this.props.currentOrder.fields, ORDER_FIELDS.CLINIC).value.id
		);
		if (!isEmptyObject(this.props.currentOrder.orderItems)) {
			const firstOrderItemWithColor = getFirstOrderItemWithColor(
				this.props.currentOrder.orderItems
			);
			if (firstOrderItemWithColor) {
				this.props
					.switchColorScheme(
						this.props.currentOrder.id,
						firstOrderItemWithColor.colorScheme
					)
					.then((colors) => {
						this.setState({ colors: colors.data.data });
					});
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.formValues &&
			prevProps.formValues &&
			(prevProps.formValues.colorSwitch !== this.props.formValues.colorSwitch ||
				prevProps.currentOrder.orderItems.length !==
					this.props.currentOrder.orderItems.length ||
				this.orderItemColorChanged(prevProps.currentOrder))
		) {
			this.props
				.switchColorScheme(this.props.currentOrder.id, this.props.formValues.colorSwitch)
				.then((colors) => this.setState({ colors: colors.data.data }));
		}
	}

	orderItemColorChanged(prevOrder) {
		const order = this.props.currentOrder;
		for (const item of order.orderItems) {
			const prevItem = _.find(prevOrder.orderItems, (prevItem) => prevItem.id === item.id);
			if (prevItem && item.colorCode !== prevItem.colorCode) {
				return true;
			}
		}

		return false;
	}

	render() {
		const { currentProduct } = this.state;

		const initialValues = isEmptyObject(this.state.currentProduct)
			? {}
			: {
					...currentProduct,
					product: this.state.currentProduct.productId,
					isGroup: this.state.currentProduct.teeth.length > 1,
					productName: `${
						this.state.currentProduct.productType
					} (${this.state.currentProduct.productMaterial.toLowerCase()})`,
					colorScheme: parseColorSchemeConstantToValue(
						this.state.currentProduct.colorScheme
					)
			  };

		return (
			<Fragment>
				<OrderItemModal
					isModalOpen={this.state.isModalOpen}
					inventory={this.props.inventory}
					closeModal={this.triggerModal}
					initialValues={initialValues}
					lab={getLabFromOrder(this.props.currentOrder)}
				/>
				<AddTasksModal
					isModalOpen={this.state.isAddTasksModalOpen}
					inventory={this.props.inventory}
					closeModal={this.triggerItemTasksModal}
					initialValues={initialValues}
					currentOrder={this.props.currentOrder}
					lab={getLabFromOrder(this.props.currentOrder)}
					allowGanttchart={this.props.allowGanttchart}
				/>
				{this.renderSubHeader()}
				<div className="mt-s">{this.renderProducts()}</div>
			</Fragment>
		);
	}

	renderProducts = () => {
		const { currentOrder, formValues } = this.props;
		const colorScheme = formValues ? formValues.colorSwitch : null;
		const { taxType } = this.props.billingSettings;

		const items = parseOrderItems(
			currentOrder.orderItems,
			currentOrder,
			this.showModalWithInitialization,
			this.state.colors,
			colorScheme,
			this.showModalTasksWithInitialization,
			this.props.modules
		);
		const tooltips = [
			null,
			null,
			null,
			null,
			null,
			null,
			i18n.t('translation:orders.table.tooltips.warranty')
		];
		const columns = [
			i18n.t('translation:orders.table.type'),
			i18n.t('translation:orders.table.color'),
			i18n.t('translation:orders.table.teeth'),
			i18n.t('translation:orders.table.quantity'),
			i18n.t('translation:orders.table.notes'),
			i18n.t('translation:orders.table.test'),
			i18n.t('translation:orders.table.warranty')
		];

		if (currentOrder.canAccessPaymentData) {
			columns.push(i18n.t('translation:orders.table.priceWithoutDiscount'));
			tooltips.push(i18n.t('translation:orders.table.tooltips.priceWithoutDiscount'));

			columns.push(i18n.t('translation:orders.table.tax'));
			tooltips.push(null);

			columns.push(
				i18n.t('translation:orders.table.priceWithTax', {
					taxType: isLabAdmin(this.props.account)
						? getSelectedTaxType(taxType)
						: i18n.t('translation:billingModule.common.tax').toLowerCase()
				})
			);
			tooltips.push(null);

			columns.push(i18n.t('translation:orders.table.totalPrice'));
			tooltips.push(null);
		}
		columns.push(i18n.t('translation:common.table.action'));
		tooltips.push(null);

		let initialPriceField = formUtils.getFieldByType(
			this.props.currentOrder.fields,
			ORDER_FIELDS.INITIAL_PRICE
		);

		const lab = formUtils.getFieldByType(currentOrder.fields, ORDER_FIELDS.LAB).value;

		const totalPriceWithoutTax = parseInvoicingCurrency(
			getOrderItemsTotalPriceWithoutTax(currentOrder.orderItems),
			lab.localizedCurrencyAbbreviation,
			lab.currencySymbol
		);

		const averageTax = getOrderItemsAverageTax(currentOrder.orderItems);

		const totalPriceWithTax = parseInvoicingCurrency(
			getOrderItemsTotalPriceWithTax(currentOrder.orderItems),
			lab.localizedCurrencyAbbreviation,
			lab.currencySymbol
		);

		return (
			<Table
				columns={columns}
				data={items}
				lab={getLabFromOrder(currentOrder)}
				tooltips={tooltips}
				totalPriceWithoutTax={totalPriceWithoutTax}
				averageTax={averageTax}
				totalPriceWithTax={totalPriceWithTax}
				orderItemCategories={getOrderItemCategories(currentOrder.orderItems)}
				canAccessPaymentData={currentOrder.canAccessPaymentData}
			/>
		);
	};

	showModalWithInitialization = (currentProduct) => {
		this.setState({ isModalOpen: true, currentProduct });
	};

	showModalTasksWithInitialization = (currentProduct) => {
		this.setState({ isAddTasksModalOpen: true, currentProduct });
	};

	renderSubHeader = () => {
		const addProductButton = this.props.currentOrder.canAddAndRemoveOrderItems ? (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				key={i18n.t('translation:common.buttons.add')}
				onClick={() => {
					this.triggerModal();
				}}
			/>
		) : (
			<Fragment />
		);

		return (
			<SubHeader title={i18n.t('translation:orders.products')} buttons={[addProductButton]} />
		);
	};

	triggerModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen, currentProduct: null });
	};

	triggerItemTasksModal = () => {
		this.setState({
			isAddTasksModalOpen: !this.state.isAddTasksModalOpen,
			currentProduct: null
		});
	};
}

const getOrderItemCategories = (orderItems) => {
	return orderItems.map((item) => item.productCategory);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
		currentOrder: state.orders.currentOrder,
		modules: state.modules,
		settings: state.settings,
		inventory: state.inventory.labInventory,
		billingSettings: state.billingSettings,
		formValues: getFormValues('colorSwitchForm')(state)
	};
};

export default connect(mapStateToProps, {
	getMyLabCurrentInventory: getLabInventoryOnly,
	switchColorScheme
})(OrderItems);
