import React from 'react';
import { change, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../../../i18n';
import {
	getListWithTooltip,
	parseInvoicingCurrency,
	renderSimpleTable
} from '../../../../../utils/tableUtils';
import SubHeader from '../../../../common/SubHeader';
import DefaultButton from '../../../../common/DefaultButton';
import {
	BILLING_DOCUMENT_TYPES,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_STATUS
} from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import { invoicingTableInput, text } from '../../../../common/Fields';
import { formUtils } from '../../../../../utils/formUtils';
import { add, subtract } from '../../invoicing/billingDocumentModal/utils/documentUtils';
import { getSelectedTaxType } from '../../../../../utils/appUtils';

class IncomesDetailsTable extends React.Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentDocuments !== this.props.currentDocuments) {
			const documentsIds = [];
			this.props.currentDocuments.forEach(({ id, paidAmount, remainingAmount }) => {
				documentsIds.push(id);
				this.props.change(
					'incomesDetailsForm',
					`${TRANSACTION_FORM_FIELD.DOCUMENT_PAYED_AMOUNT_}${id}`,
					paidAmount
				);
				this.props.change(
					'incomesDetailsForm',
					`${TRANSACTION_FORM_FIELD.DOCUMENT_REMAINING_AMOUNT_}${id}`,
					remainingAmount
				);
				this.props.change(
					'incomesDetailsForm',
					`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`,
					null
				);
			});
			this.props.change(
				'incomesDetailsForm',
				TRANSACTION_FORM_FIELD.DOCUMENTS_IDS,
				documentsIds
			);
		}
	}

	getHeader() {
		const taxType = getSelectedTaxType(this.props.billingSettings.taxType);
		return [
			{
				content: i18n.t('translation:billingModule.common.table.number')
			},
			{
				content: i18n.t('translation:billingModule.incomeOutcomeDetails.table.dateOfIssue')
			},
			{
				content: i18n.t('translation:billingModule.incomeOutcomeDetails.table.typeDocument')
			},
			{
				content: i18n.t('translation:billingModule.incomeOutcomeDetails.table.orders')
			},
			{
				content: i18n.t('translation:billingModule.common.table.amountWithTax', {
					taxType: taxType
				})
			},
			{ content: i18n.t('translation:billingModule.incomeOutcomeDetails.table.payed') },
			{
				content: i18n.t('translation:billingModule.incomeOutcomeDetails.table.unPaid')
			},
			{
				content: i18n.t(
					'translation:billingModule.incomeOutcomeDetails.table.payedInThisIncome'
				)
			}
		];
	}

	distributeReminder() {
		const {
			TRANSACTION_REMINDER,
			DOCUMENTS_IDS,
			DOCUMENT_REMAINING_AMOUNT_,
			DOCUMENT_AMOUNT_,
			DOCUMENT_PAYED_AMOUNT_,
			TRANSACTION_AMOUNT,
			TOTAL_PAYED
		} = TRANSACTION_FORM_FIELD;

		const formValues = this.props.formValues;
		let reminder = this.props.formValues[TRANSACTION_REMINDER] ?? 0;
		const documentsIds = this.props.formValues[DOCUMENTS_IDS];
		let index = 0;

		while (Number(reminder) > 0 && index < documentsIds.length) {
			let toAdd = 0;
			let updatedDocumentPayedAmount = 0;
			let updatedDocumentAmount = 0;
			let updatedDocumentRemainingAmount = 0;

			const id = documentsIds[index];
			const currentDocumentRemainingAmount =
				formValues[`${DOCUMENT_REMAINING_AMOUNT_}${id}`] ?? 0;
			const currentDocumentAmount = formValues[`${DOCUMENT_AMOUNT_}${id}`] ?? 0;
			const currentDocumentPayedAmount = formValues[`${DOCUMENT_PAYED_AMOUNT_}${id}`] ?? 0;

			if (Number(currentDocumentRemainingAmount) <= 0) {
				index++;
				continue;
			}

			if (Number(currentDocumentRemainingAmount) <= reminder) {
				toAdd = currentDocumentRemainingAmount;
			} else {
				toAdd = reminder;
				index = documentsIds.length;
			}

			updatedDocumentPayedAmount = add(currentDocumentPayedAmount, toAdd);
			updatedDocumentAmount = Number(add(currentDocumentAmount, toAdd).toFixed(2));
			updatedDocumentRemainingAmount = subtract(currentDocumentRemainingAmount, toAdd);

			reminder = subtract(reminder, toAdd);

			this.props.change(
				'incomesDetailsForm',
				`${DOCUMENT_AMOUNT_}${id}`,
				updatedDocumentAmount
			);
			this.props.change(
				'incomesDetailsForm',
				`${DOCUMENT_PAYED_AMOUNT_}${id}`,
				updatedDocumentPayedAmount
			);
			this.props.change(
				'incomesDetailsForm',
				`${DOCUMENT_REMAINING_AMOUNT_}${id}`,
				updatedDocumentRemainingAmount
			);

			toAdd = 0;
			index++;
		}

		const transactionAmount = this.props.formValues[TRANSACTION_AMOUNT] ?? 0;
		const totalPayed = subtract(transactionAmount, reminder);

		this.props.change('incomesDetailsForm', TRANSACTION_REMINDER, reminder);
		this.props.change('incomesDetailsForm', TOTAL_PAYED, totalPayed);
	}

	renderDistributeAmountButton() {
		if (
			this.props.status === TRANSACTION_STATUS.COMPLETED ||
			this.props.status === TRANSACTION_STATUS.CANCELED
		) {
			return null;
		}
		const formValues = this.props.formValues;
		const { TRANSACTION_CLINIC_ID, TRANSACTION_AMOUNT } = TRANSACTION_FORM_FIELD;
		const clinicId = formValues[TRANSACTION_CLINIC_ID];

		const disabled =
			(this.props.isCreation && !Number(formValues[TRANSACTION_AMOUNT] ?? 0)) ||
			clinicId === undefined ||
			Number(this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER]) <= 0;

		return (
			<DefaultButton
				type={'button'}
				title={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.incomeDetails.buttons.distributeRemainder'
				)}
				onClick={() => this.distributeReminder()}
				disabled={disabled}
			/>
		);
	}

	getCurrentDocuments() {
		const currentDocuments = [];
		this.props.currentDocuments.forEach(
			({
				id,
				type,
				number,
				issueDate,
				paidAmount,
				remainingAmount,
				labOrderIds,
				localizedCurrencyAbbreviation,
				currencySymbol
			}) => {
				currentDocuments.push(
					this.getRow(
						id,
						number,
						issueDate,
						type,
						labOrderIds,
						paidAmount,
						remainingAmount,
						localizedCurrencyAbbreviation,
						currencySymbol,
						false,
						null,
						null
					)
				);
			}
		);
		return currentDocuments;
	}

	getTransactionDocuments() {
		const transactionDocuments = [];

		this.props.billingDocuments?.forEach(({ amount, billingDocument }) => {
			const {
				id,
				type,
				number,
				issueDate,
				paidAmount,
				remainingAmount,
				labOrderIds,
				localizedCurrencyAbbreviation,
				currencySymbol,
				totalRounded
			} = billingDocument;

			transactionDocuments.push(
				this.getRow(
					id,
					number,
					issueDate,
					type,
					labOrderIds,
					paidAmount,
					remainingAmount,
					localizedCurrencyAbbreviation,
					currencySymbol,
					true,
					totalRounded,
					amount
				)
			);
		});

		return transactionDocuments;
	}

	getTableData() {
		const data = [];

		if (this.props.isCreation) {
			data.push(...this.getCurrentDocuments());
		} else {
			data.push(...this.getTransactionDocuments());
			data.push(...this.getCurrentDocuments());
		}

		return data;
	}

	getRow(
		id,
		number,
		issueDate,
		type,
		labOrderIds,
		paidAmount,
		remainingAmount = 0,
		localizedCurrencyAbbreviation,
		currencySymbol,
		documentPartOfTransaction,
		totalRounded,
		amount
	) {
		const transactionAmount = documentPartOfTransaction
			? totalRounded
			: add(paidAmount, remainingAmount);

		const unpaidPart = documentPartOfTransaction
			? subtract(totalRounded, paidAmount)
			: remainingAmount;

		return [
			number,
			this.getIssueDate(issueDate),
			this.getType(type),
			this.getOrdersIds(labOrderIds),
			parseInvoicingCurrency(
				transactionAmount,
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			this.getPayedAmountField(paidAmount, localizedCurrencyAbbreviation, currencySymbol, id),
			this.getRemainingAmountField(
				unpaidPart,
				localizedCurrencyAbbreviation,
				currencySymbol,
				id
			),
			this.getAmountField(
				id,
				localizedCurrencyAbbreviation,
				currencySymbol,
				remainingAmount,
				documentPartOfTransaction,
				amount,
				paidAmount
			)
		];
	}

	getType(type) {
		const { DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (type === DEBIT_NOTE) {
			return i18n.t('translation:billingModule.documentType.debitNote');
		}

		return i18n.t('translation:billingModule.incomesOutcomes.invoice');
	}

	getOrdersIds(labOrderIds) {
		return getListWithTooltip(
			labOrderIds,
			i18n.t('translation:billingModule.incomeOutcomeDetails.common.noDocuments')
		);
	}

	getIssueDate(issueDate) {
		return moment(issueDate).format('DD.MM.YYYY');
	}

	getPayedAmountField(payedAmount, localizedCurrencyAbbreviation, currencySymbol, id) {
		return (
			<Field
				name={`${TRANSACTION_FORM_FIELD.DOCUMENT_PAYED_AMOUNT_}${id}`}
				component={text}
				currency={true}
				localizedCurrencyAbbreviation={localizedCurrencyAbbreviation}
				currencySymbol={currencySymbol}
				defaultValue={parseInvoicingCurrency(
					payedAmount,
					localizedCurrencyAbbreviation,
					currencySymbol
				)}
			/>
		);
	}

	getRemainingAmountField(remainingAmount, localizedCurrencyAbbreviation, currencySymbol, id) {
		return (
			<Field
				name={`${TRANSACTION_FORM_FIELD.DOCUMENT_REMAINING_AMOUNT_}${id}`}
				component={text}
				currency={true}
				localizedCurrencyAbbreviation={localizedCurrencyAbbreviation}
				currencySymbol={currencySymbol}
				defaultValue={parseInvoicingCurrency(
					remainingAmount,
					localizedCurrencyAbbreviation,
					currencySymbol
				)}
			/>
		);
	}

	getAmountField(
		id,
		localizedCurrencyAbbreviation,
		currencySymbol,
		remainingAmount = 0,
		documentPartOfTransaction,
		amount,
		paidAmount
	) {
		//edit/preview
		if (documentPartOfTransaction) {
			return parseInvoicingCurrency(amount, localizedCurrencyAbbreviation, currencySymbol);
		}

		const disabled = !(
			this.props.formValues.amount !== undefined && this.props.formValues.amount != 0
		);

		return (
			<Field
				name={`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`}
				customProps={{
					disabled: disabled
				}}
				component={invoicingTableInput}
				currency={true}
				localizedCurrencyAbbreviation={localizedCurrencyAbbreviation}
				currencySymbol={currencySymbol}
				normalize={formUtils.normalize.price}
				onChange={(e) =>
					this.onDocumentAmountChange(e.target.value, id, remainingAmount, paidAmount)
				}
			/>
		);
	}

	onDocumentAmountChange(value, id, remainingAmount, paidAmount) {
		const normalizeValue = formUtils.normalize.price(value);

		if (value !== normalizeValue) {
			return;
		}

		const previousValue =
			this.props.formValues[`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`] ?? 0;
		const transactionAmount =
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_AMOUNT] ?? 0;

		let totalPayed = subtract(
			add(this.props.formValues[TRANSACTION_FORM_FIELD.TOTAL_PAYED], value),
			previousValue
		);

		const reminder = subtract(transactionAmount, totalPayed);
		const updatedRemainingAmount = subtract(remainingAmount, value);

		this.props.change(
			'incomesDetailsForm',
			TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER,
			reminder
		);
		this.props.change(
			'incomesDetailsForm',
			`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`,
			value
		);
		this.props.change(
			'incomesDetailsForm',
			`${TRANSACTION_FORM_FIELD.DOCUMENT_REMAINING_AMOUNT_}${id}`,
			updatedRemainingAmount
		);
		this.props.change(
			'incomesDetailsForm',
			`${TRANSACTION_FORM_FIELD.DOCUMENT_PAYED_AMOUNT_}${id}`,
			add(paidAmount, value)
		);
		this.props.change('incomesDetailsForm', TRANSACTION_FORM_FIELD.TOTAL_PAYED, totalPayed);
	}

	getHeaderEmptyDataMessage() {
		const clinicId = this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_CLINIC_ID];

		if (clinicId === undefined) {
			return i18n.t(
				'translation:billingModule.incomeOutcomeDetails.incomeDetails.selectClientFirst'
			);
		}

		return i18n.t('translation:common.table.noItems');
	}

	render() {
		return (
			<div>
				<SubHeader
					title={
						<h2 className={'mt-m'} style={{ fontWeight: 'bold' }}>
							{i18n
								.t(
									'translation:billingModule.incomeOutcomeDetails.incomeDetails.tableHeader'
								)
								.toUpperCase()}
						</h2>
					}
					className="pb-2"
					buttons={[this.renderDistributeAmountButton()]}
				/>
				<div className={'mt-4 incomes-table pb-5'}>
					{renderSimpleTable(
						this.getTableData(),
						'',
						this.getHeader(),
						this.getHeaderEmptyDataMessage()
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentDocuments: state.billingTransactions.currentDebitDocuments,
		billingSettings: state.billingSettings,
		formValues: getFormValues('incomesDetailsForm')(state)
	};
};

export default connect(mapStateToProps, { change })(IncomesDetailsTable);
