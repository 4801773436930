import React from 'react';
import i18n from '../../../../i18n';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from '../../../common/DefaultButton';
import { DEBTOR_DAYS_FIELD, PAGINATION_TYPES } from '../../../../utils/constants';
import { connect } from 'react-redux';
import {
	getDebtorDaysDocuments,
	clearDebtorDaysDocuments,
	setPagination
} from '../../../../actions';
import Pagination from '../../../common/Pagination';
import DebtorDaysTable from './DebtorDaysModalTable';
import { getFormValues } from 'redux-form';

class DebtorDaysModal extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.setCurrentPage(1);
	}

	componentWillUnmount() {
		this.resetState();
		this.props.clearDebtorDaysDocuments();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page && this.props.page) {
			this.props.getDebtorDaysDocuments({
				labId: this.props.account.labId,
				type: this.props.type,
				overdueIntervalId: this.props.overdueIntervalId,
				clinicId: this.props.clinicId,
				page: this.props.page,
				date: this.props.formValues[DEBTOR_DAYS_FIELD.TO_DATE]
			});
		}
	}

	resetState() {
		this.setCurrentPage(0);
	}

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.DEBTOR_DAYS_DOCUMENTS, page);
	};

	renderCloseButton() {
		return (
			<DefaultButton
				type="button"
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => this.props.closeModal()}
				secondary
			/>
		);
	}

	renderPagination = () => {
		const items = this.props.debtorDaysDocuments?.content;
		if (items && items.length) {
			return (
				<Pagination
					currentPage={this.props.debtorDaysDocuments?.page}
					total={this.props.debtorDaysDocuments?.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	renderTable = () => {
		if (!this.props.debtorDaysDocuments) {
			return null;
		}

		return (
			<>
				<div>{this.props.clinicId}</div>
				<div>{this.props.overdueIntervalId}</div>
				{this.renderPagination()}
				<div className="buttons task-buttons">{this.renderCloseButton()}</div>
			</>
		);
	};

	render() {
		if (!this.props.isOpen) {
			return null;
		}

		return (
			<Modal
				className="custom-modal full-page-modal"
				overlayClassName="custom-overlay"
				isOpen={true}>
				<div className={'fixed-top p-3'}>
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={() => {
							this.props.closeModal();
						}}
					/>
					<h2>{this.props.title}</h2>
				</div>
				<div className="modal-content">
					<DebtorDaysTable {...this.props} />
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		debtorDaysDocuments: state.billingDebtorDays.debtorDaysDocuments,
		page: state.pagination[PAGINATION_TYPES.DEBTOR_DAYS_DOCUMENTS],
		formValues: getFormValues('debtorDaysForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	getDebtorDaysDocuments,
	clearDebtorDaysDocuments
})(DebtorDaysModal);
