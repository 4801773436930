import React, { Fragment } from 'react';
import teethMap from '../../../resources/images/teethMap.png';
import TeethMask from './TeethMask';
import i18n from '../../../i18n';
import { TOOTH_TYPES } from '../../../utils/constants';
import _ from 'lodash';

class TeethMap extends React.Component {
	state = { activeIndex: null, isMilkTeeth: null, toothNumber: null, otherToothInMask: null };

	render() {
		const teethMapStyle = this.props.readOnly
			? 'teeth-map-section read-only'
			: 'teeth-map-section';

		return (
			<Fragment>
				<div className={teethMapStyle} onClick={this.onBackgroundClick}>
					<img src={teethMap} alt="" className="teeth-map"></img>
					<div className="d-flex position-absolute teeth-mask-section">
						<div className="teeth-mask-first-row">{this.renderFirstTeethRow()}</div>
						<div className="teeth-mask-second-row">{this.renderSecondTeethRow()}</div>
						{this.renderDropdownButton()}
					</div>
				</div>
				{this.renderIsTeethError()}
			</Fragment>
		);
	}

	renderIsTeethError = () => {
		if (this.props.isError) {
			return (
				<span className="align-items-start text-danger">
					{this.props.teethErrorMessage || i18n.t('translation:orders.errors.noTeeth')}
				</span>
			);
		}
	};

	renderFirstTeethRow = () => {
		const teeth = [
			{
				permanentTeeth: 18
			},
			{
				permanentTeeth: 17
			},
			{
				permanentTeeth: 16
			},
			{
				permanentTeeth: 15,
				milkTeeth: 55
			},
			{
				permanentTeeth: 14,
				milkTeeth: 54
			},
			{
				permanentTeeth: 13,
				milkTeeth: 53
			},
			{
				permanentTeeth: 12,
				milkTeeth: 52
			},
			{
				permanentTeeth: 11,
				milkTeeth: 51
			},
			{
				permanentTeeth: 21,
				milkTeeth: 61
			},
			{
				permanentTeeth: 22,
				milkTeeth: 62
			},
			{
				permanentTeeth: 23,
				milkTeeth: 63
			},
			{
				permanentTeeth: 24,
				milkTeeth: 64
			},
			{
				permanentTeeth: 25,
				milkTeeth: 65
			},
			{
				permanentTeeth: 26
			},
			{
				permanentTeeth: 27
			},
			{
				permanentTeeth: 28
			}
		];
		let implants = _.filter(this.props.teeth, function (tooth) {
			return tooth.type === TOOTH_TYPES.IMPLANT;
		});
		implants = implants.map((tooth) => +tooth.fdiPosition);
		let teethForProcess = _.filter(this.props.teeth, function (tooth) {
			return tooth.type === TOOTH_TYPES.TOOTH;
		});
		teethForProcess = teethForProcess.map((tooth) => +tooth.fdiPosition);
		let noTeeth = _.filter(this.props.teeth, function (tooth) {
			return tooth.type === TOOTH_TYPES.MISSING_TOOTH;
		});
		noTeeth = noTeeth.map((tooth) => +tooth.fdiPosition);

		return teeth.map((tooth, index) => {
			const toothData = this.getToothData(noTeeth, implants, teethForProcess, tooth);
			return (
				<TeethMask
					key={index + 'teeth'}
					isTop={this.state.activeIndex && this.state.activeIndex.split('_')[1] === 't'}
					firstRow={true}
					isActive={this.state.activeIndex === `${index}_t`}
					onClick={(e, isMilkTeeth, toothNumber, otherToothInMask) => {
						this.onTeethButtonClick(
							`${index}_t`,
							isMilkTeeth,
							toothNumber,
							otherToothInMask,
							e
						);
					}}
					toothData={toothData}
					permanentTeeth={tooth.permanentTeeth}
					milkTeeth={tooth.milkTeeth}
				/>
			);
		});
	};

	renderSecondTeethRow = () => {
		const teeth = [
			{
				permanentTeeth: 48
			},
			{
				permanentTeeth: 47
			},
			{
				permanentTeeth: 46
			},
			{
				permanentTeeth: 45,
				milkTeeth: 85
			},
			{
				permanentTeeth: 44,
				milkTeeth: 84
			},
			{
				permanentTeeth: 43,
				milkTeeth: 83
			},
			{
				permanentTeeth: 42,
				milkTeeth: 82
			},
			{
				permanentTeeth: 41,
				milkTeeth: 81
			},
			{
				permanentTeeth: 31,
				milkTeeth: 71
			},
			{
				permanentTeeth: 32,
				milkTeeth: 72
			},
			{
				permanentTeeth: 33,
				milkTeeth: 73
			},
			{
				permanentTeeth: 34,
				milkTeeth: 74
			},
			{
				permanentTeeth: 35,
				milkTeeth: 75
			},
			{
				permanentTeeth: 36
			},
			{
				permanentTeeth: 37
			},
			{
				permanentTeeth: 38
			}
		];
		let implants = _.filter(this.props.teeth, function (tooth) {
			return tooth.type === TOOTH_TYPES.IMPLANT;
		});
		implants = implants.map((tooth) => +tooth.fdiPosition);
		let teethForProcess = _.filter(this.props.teeth, function (tooth) {
			return tooth.type === TOOTH_TYPES.TOOTH;
		});
		teethForProcess = teethForProcess.map((tooth) => +tooth.fdiPosition);
		let noTeeth = _.filter(this.props.teeth, function (tooth) {
			return tooth.type === TOOTH_TYPES.MISSING_TOOTH;
		});
		noTeeth = noTeeth.map((tooth) => +tooth.fdiPosition);
		return teeth.map((tooth, index) => {
			const toothData = this.getToothData(noTeeth, implants, teethForProcess, tooth);

			return (
				<TeethMask
					key={index + 'teethSecond'}
					isTop={this.state.activeIndex && this.state.activeIndex.split('_')[1] === 't'}
					isActive={this.state.activeIndex === `${index}_b`}
					onClick={(e, isMilkTeeth, toothNumber, otherToothInMask) => {
						this.onTeethButtonClick(
							`${index}_b`,
							isMilkTeeth,
							toothNumber,
							otherToothInMask,
							e
						);
					}}
					toothData={toothData}
					firstRow={false}
					permanentTeeth={tooth.permanentTeeth}
					milkTeeth={tooth.milkTeeth}
				/>
			);
		});
	};

	getToothData = (noTeeth, implants, teethForProcess, tooth) => {
		let data = null;
		if (noTeeth.includes(tooth.permanentTeeth)) {
			data = { type: TOOTH_TYPES.MISSING_TOOTH, isMilkTooth: false };
		} else if (noTeeth.includes(tooth.milkTeeth)) {
			data = { type: TOOTH_TYPES.MISSING_TOOTH, isMilkTooth: true };
		} else if (implants.includes(tooth.permanentTeeth)) {
			data = { type: TOOTH_TYPES.IMPLANT, isMilkTooth: false };
		} else if (implants.includes(tooth.milkTeeth)) {
			data = { type: TOOTH_TYPES.IMPLANT, isMilkTooth: true };
		} else if (teethForProcess.includes(tooth.permanentTeeth)) {
			data = { type: TOOTH_TYPES.TOOTH, isMilkTooth: false };
		} else if (teethForProcess.includes(tooth.milkTeeth)) {
			data = { type: TOOTH_TYPES.TOOTH, isMilkTooth: true };
		}
		return data;
	};

	onTeethButtonClick = (index, isMilkTeeth, toothNumber, otherToothInMask, e) => {
		e.stopPropagation();
		if (!this.props.readOnly) {
			this.setState({ activeIndex: index, isMilkTeeth, toothNumber, otherToothInMask });
		}
	};

	onBackgroundClick = () => {
		this.setState({ activeIndex: null });
	};

	renderDropdownButton = () => {
		if (this.state.activeIndex) {
			const isMilkTeeth = this.state.isMilkTeeth;
			const isFirst = this.state.activeIndex.split('_')[0] === '0';
			const isLast = this.state.activeIndex.split('_')[0] === '15';
			const isFirstRow = this.state.activeIndex.split('_')[1] !== 'b';
			const positionY =
				isMilkTeeth && isFirstRow
					? 174 - 32
					: !isMilkTeeth && isFirstRow
					? 174
					: isMilkTeeth && !isFirstRow
					? 359 - (160 + 4 * 42 - 32)
					: 359 - (160 + 4 * 42);
			const positionX =
				this.state.activeIndex.split('_')[0] * 57 -
				((isFirst ? 0 : isLast ? 116 * 2 : 115) - (isFirst ? 0 : isLast ? 28.5 * 2 : 28.5));
			const toothNumber = this.state.toothNumber;
			const otherToothInMask = this.state.otherToothInMask;
			return (
				<div
					style={{
						left: `${positionX}px`,
						top: `${positionY}px`
					}}
					className="teeth-dropdown-buttons">
					{this.renderTeethMapMenuButton(i18n.t('translation:orders.tooth'), () => {
						this.props.setTooth(toothNumber, TOOTH_TYPES.TOOTH, otherToothInMask);
					})}
					{this.renderTeethMapMenuButton(i18n.t('translation:orders.noTooth'), () => {
						this.props.setTooth(
							toothNumber,
							TOOTH_TYPES.MISSING_TOOTH,
							otherToothInMask
						);
					})}
					{this.renderTeethMapMenuButton(i18n.t('translation:orders.implant'), () => {
						this.props.setTooth(toothNumber, TOOTH_TYPES.IMPLANT, otherToothInMask);
					})}
					{this.renderTeethMapMenuButton(
						i18n.t('translation:orders.clearSelection'),
						() => {
							this.props.setTooth(toothNumber, '');
						}
					)}
				</div>
			);
		}
	};

	renderTeethMapMenuButton = (title, onClick) => {
		return (
			<div onClick={onClick}>
				<span>{title}</span>
			</div>
		);
	};
}

export default TeethMap;
