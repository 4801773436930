import {
	BILLING_DOCUMENT_TYPES,
	COUNTRY_CODES_BILLING_MODULE,
	INVOICE_FIELD_NAME
} from '../../../../../../../../utils/constants';
import { getLocalizedItemName } from '../../../../../../../../utils/formUtils';

export const getSecondThirdLangAbbreviation = (documentLanguage) => {
	const { BG, DE, EN, TR } = COUNTRY_CODES_BILLING_MODULE;

	const abbreviations = {
		[BG]: [EN, DE, TR],
		[DE]: [BG, EN, TR],
		[TR]: [BG, EN, DE],
		default: [BG, DE, TR]
	};

	return abbreviations[documentLanguage] || abbreviations.default;
};

export const isLocalizationMissing = (formValues, documentType, langAbbreviations) => {
	const { DOCUMENT_ITEMS_IDS } = INVOICE_FIELD_NAME;
	const {
		BANK_NAME,
		COMPOSED_BY,
		SUPPLIER_RESPONSIBLE_PERSON,
		SUPPLIER_NAME,
		SUPPLIER_ADDRESS,
		RECEIVER_RESPONSIBLE_PERSON,
		RECEIVER_ADDRESS,
		RECEIVER_NAME,
		VERBALLY,
		NOTES,
		TAX_NOT_INCLUDED_REASON,
		DOCUMENT_REASON,
		DISCOUNT_DESCRIPTION
	} = INVOICE_FIELD_NAME.LOCALIZATION;
	const itemsIds = formValues[DOCUMENT_ITEMS_IDS];

	let localizationMissing = true;

	let localizationFieldsNames = [];

	for (let abbreviation of langAbbreviations) {
		localizationFieldsNames.push(
			BANK_NAME[abbreviation],
			COMPOSED_BY[abbreviation],
			SUPPLIER_ADDRESS[abbreviation],
			SUPPLIER_NAME[abbreviation],
			SUPPLIER_RESPONSIBLE_PERSON[abbreviation],
			VERBALLY[abbreviation],
			NOTES[abbreviation],
			DISCOUNT_DESCRIPTION[abbreviation],
			TAX_NOT_INCLUDED_REASON[abbreviation],
			DOCUMENT_REASON[abbreviation],
			RECEIVER_ADDRESS[abbreviation],
			RECEIVER_RESPONSIBLE_PERSON[abbreviation],
			RECEIVER_NAME[abbreviation]
		);
	}

	for (let i = 0; i < localizationFieldsNames.length; i++) {
		if (formValues[localizationFieldsNames[i]]) {
			localizationMissing = false;
			break;
		}
	}

	if (!localizationMissing) {
		return false;
	}

	if (documentType === BILLING_DOCUMENT_TYPES.FREE_INVOICE) {
		for (let i = 0; i < itemsIds.length; i++) {
			for (let abbreviation of langAbbreviations) {
				if (getLocalizedItemName(formValues, abbreviation, itemsIds[i])) {
					localizationMissing = false;
					break;
				}
			}
		}
	}

	return localizationMissing;
};
