import { GET_GENERAL_WORKING_HOURS, UPDATE_GENERAL_WORKING_HOURS } from '../../actions/types';

const initState = [];

const generalWorkingHoursReducer = (state = [...initState], action) => {
	if (action.type === GET_GENERAL_WORKING_HOURS) {
		state = action.payload;
		return [...state];
	}

	if (action.type === UPDATE_GENERAL_WORKING_HOURS) {
		state = action.payload;
		return [...state];
	}

	return state;
};

export default generalWorkingHoursReducer;
