import React from 'react';
import {
	BILLING_DOCUMENT_STATUS,
	ORDER_INVOICING_STATUS
} from '../../../../../../../utils/constants';
import {
	faBan,
	faChartPie,
	faCheck,
	faClock,
	faEdit,
	faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseBillingStatuses } from '../../../../../../../utils/appUtils';

class Status extends React.Component {
	renderTitle(status) {
		return <span>{parseBillingStatuses(status)}</span>;
	}

	getClassName(status) {
		let className = 'status';
		if (status === BILLING_DOCUMENT_STATUS.OPENED) {
			className += ' pending';
		} else if (status === BILLING_DOCUMENT_STATUS.DRAFT) {
			className += ' pending';
		} else if (status === BILLING_DOCUMENT_STATUS.SENT_FOR_APPROVAL) {
			className += ' awaiting';
		} else if (status === BILLING_DOCUMENT_STATUS.REJECTED) {
			className += ' negative';
		} else if (status === BILLING_DOCUMENT_STATUS.PAID_PARTIALLY) {
			className += ' neutral';
		} else if (status === BILLING_DOCUMENT_STATUS.PAID) {
			className += ' completed';
		} else if (status === ORDER_INVOICING_STATUS.NOT_INVOICED) {
			className += ' awaiting';
		} else if (status === ORDER_INVOICING_STATUS.PARTIALLY_INVOICED) {
			className += ' neutral';
		} else if (status === ORDER_INVOICING_STATUS.FULLY_INVOICED) {
			className += ' completed';
		}

		return className;
	}

	getIcon = (status) => {
		let icon;

		if (status === BILLING_DOCUMENT_STATUS.SENT_FOR_APPROVAL) {
			icon = faSpinner;
		} else if (status === BILLING_DOCUMENT_STATUS.OPENED) {
			icon = faCheck;
		} else if (status === BILLING_DOCUMENT_STATUS.PAID_PARTIALLY) {
			icon = faChartPie;
		} else if (status === BILLING_DOCUMENT_STATUS.REJECTED) {
			icon = faBan;
		} else if (status === BILLING_DOCUMENT_STATUS.DRAFT) {
			icon = faClock;
		} else if (status === BILLING_DOCUMENT_STATUS.PAID) {
			icon = faCheck;
		} else if (status === ORDER_INVOICING_STATUS.NOT_INVOICED) {
			icon = faClock;
		} else if (status === ORDER_INVOICING_STATUS.PARTIALLY_INVOICED) {
			icon = faChartPie;
		} else if (status === ORDER_INVOICING_STATUS.FULLY_INVOICED) {
			icon = faCheck;
		}

		return icon;
	};

	render() {
		let { status } = this.props;
		if (!status) {
			status = BILLING_DOCUMENT_STATUS.DRAFT;
		}
		return (
			<div className={this.getClassName(status)}>
				<FontAwesomeIcon icon={this.getIcon(status)} className="status-icon" />
				{this.renderTitle(status)}
			</div>
		);
	}
}

export default Status;
