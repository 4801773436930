import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common/Header';
import i18n from '../../../../../i18n';
import { isEmptyObject, isTaskManagerEnabled } from '../../../../../utils/appUtils';
import NotFound from '../../../../errors/NotFound';
import Calendar from '../../../../common/Calendar';
import { getAbsenceTypes, getHolidays, getStaff } from '../../../../../actions';
import moment from '../../../../../utils/moment';
import { DEFAULT_BACKEND_DATE_FORMAT, WORKDAY_TYPES } from '../../../../../utils/constants';
import SubHeader from '../../../../common/SubHeader';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AbsenceBadges from '../../../../common/AbsenceBadges';

class WorkSchedule extends React.Component {
	state = { date: moment().startOf('year') };

	componentDidMount() {
		this.props.getHolidays(this.props.account.labId, this.state.date.format('YYYY'));
		this.props.getAbsenceTypes();
		this.getStaff(this.state.date);
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <NotFound />;
		}

		return (
			<Fragment>
				<Header
					title={i18n.t('translation:taskManager.planner.workSchedule')}
					buttons={[]}
				/>
				{this.renderSubHeader()}
				<div className="row">{this.renderCalendars()}</div>
				<div className="d-flex">
					<div className="absence-badges mb-m">
						<AbsenceBadges absences={this.props.absences} workedDaysAbsence={false} />
					</div>
				</div>
			</Fragment>
		);
	}

	renderSubHeader = () => {
		const nextButton = (
			<button onClick={this.addYear} className="icon-button w-auto">
				{<FontAwesomeIcon className="arrow-button" icon={faCaretRight} />}
			</button>
		);
		const backButton = (
			<button onClick={this.subtractYear} className="icon-button w-auto">
				{<FontAwesomeIcon className="arrow-button" icon={faCaretLeft} />}
			</button>
		);

		const title = (
			<div className="d-flex align-items-center">
				{backButton}
				<h2 className="mr-xs ml-xs">{this.state.date.format('YYYY')}</h2>
				{nextButton}
			</div>
		);

		return <SubHeader title={title} buttons={[]} />;
	};

	renderCalendars = () => {
		let absenceEvents = [];
		if (!isEmptyObject(this.props.staff)) {
			this.props.staff[0].calendar.map((item, index) => {
				if (item.absence == true) {
					absenceEvents.push({
						start: item.date,
						end: item.date,
						type: item.type,
						isWeekend: item.isWeekend
					});
				} else if (item.type == WORKDAY_TYPES.WORK_SHIFT) {
					absenceEvents.push({
						start: item.date,
						end: item.date,
						type: item.type,
						shiftCode: item.shiftCode,
						isWeekend: item.isWeekend
					});
				} else if (item.isWeekend == true) {
					absenceEvents.push({
						start: item.date,
						end: item.date,
						type: 'NO_WORKING_DAY',
						isWeekend: item.isWeekend
					});
				}
			});
		}

		return Array.from(Array(12).keys()).map((el, index) => {
			return (
				<div
					key={`shed-${index}`}
					className="col-md-12 col-lg-6 col-xl-4 mt-s holiday-calendar work-schedule-calendar">
					<Calendar
						holidays
						absenceEvents
						events={absenceEvents}
						date={moment(this.state.date).add(index, 'M')}
						onSelectDay={() => console.log('onSelectDay')}
					/>
				</div>
			);
		});
	};

	addYear = () => {
		const date = this.state.date.add(1, 'Y');
		this.props.getHolidays(this.props.account.labId, date.format('YYYY'));
		this.props.getAbsenceTypes();
		this.getStaff(date);
		this.setState({ date });
	};

	subtractYear = () => {
		const date = this.state.date.subtract(1, 'Y');
		this.props.getHolidays(this.props.account.labId, date.format('YYYY'));
		this.props.getAbsenceTypes();
		this.getStaff(date);
		this.setState({ date });
	};

	getStaff(date) {
		const labId = this.props.account.labId;
		const accountId = this.props.account.id;

		let data = {};
		data.userIdList = [accountId];
		data.shiftId = null;
		data.absenceType = null;
		data.startDate = moment(date).startOf('year').format(DEFAULT_BACKEND_DATE_FORMAT);
		data.endDate = moment(date).endOf('year').format(DEFAULT_BACKEND_DATE_FORMAT);

		this.props.getStaff({ ...data }, labId);
	}
}

const mapStateToProps = (state) => {
	return {
		modules: state.modules,
		account: state.account,
		holidays: state.holidays,
		absences: state.absences,
		staff: state.staff
	};
};

export default connect(mapStateToProps, { getHolidays, getAbsenceTypes, getStaff })(WorkSchedule);
