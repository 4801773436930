import { GET_ALL_CURRENCY_CODES, GET_SETTING_FROM_SERVER } from './types';
import { getAxiosWithToken } from '../utils/webApi';

export const getSettingFromServer = (name) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/system/setting?name=${name}`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_SETTING_FROM_SERVER,
			payload: {
				name,
				value: returnedData.data
			}
		});
	}
};

export const getAllCurrencyCodes = () => async (dispatch) => {
	const response = await getAxiosWithToken().get('/system/all-currency-codes');
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_ALL_CURRENCY_CODES,
			payload: returnedData.data
		});
	}
};
