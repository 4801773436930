import { LOAD_SETTINGS, SAVE_SETTINGS } from './types';
import { SETTINGS_IN_LOCAL_STORAGE } from '../utils/constants';
import { isEmptyObject, isOrderDraft } from '../utils/appUtils';
import { getOrderDetails } from './orderActions';

export const loadSettings = () => async (dispatch) => {
	let settings = {};
	try {
		const obj = await localStorage.getItem(SETTINGS_IN_LOCAL_STORAGE);
		if (obj !== null) {
			settings = JSON.parse(obj);
		}
	} catch (e) {
		settings = {};
	}
	dispatch({ type: LOAD_SETTINGS, payload: settings });
	return settings;
};

export const saveSettings = (settings) => async (dispatch) => {
	try {
		const obj = JSON.stringify(settings);
		await localStorage.setItem(SETTINGS_IN_LOCAL_STORAGE, obj);
		dispatch({ type: SAVE_SETTINGS, payload: settings });
	} catch (e) {
		// not empty block
	}
};

export const loadLanguage = () => {
	let settingsInJson = localStorage.getItem(SETTINGS_IN_LOCAL_STORAGE);
	return settingsInJson != null ? JSON.parse(settingsInJson).language : null;
};

export const saveLanguage = (language) => async (dispatch, getState) => {
	try {
		const state = getState();
		let settings = { ...state.settings };
		settings.language = language;
		const obj = JSON.stringify(settings);
		await localStorage.setItem(SETTINGS_IN_LOCAL_STORAGE, obj);
		dispatch({ type: SAVE_SETTINGS, payload: settings });
		const currentOrder = getState().orders.currentOrder;
		if (!isEmptyObject(currentOrder) && !isOrderDraft(currentOrder)) {
			dispatch(getOrderDetails(currentOrder.id));
		}
	} catch (e) {
		// not empty block
	}
};
