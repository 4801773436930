import React from 'react';

class LinkButton extends React.Component {
	render() {
		let attachedClassNames = 'btn btn-link';
		if (this.props.className) {
			attachedClassNames += ` ${this.props.className}`;
		}
		return (
			<button
				className={attachedClassNames}
				onClick={this.props.onClick}
				title={this.props.tooltip}>
				<span>{this.props.title}</span>
			</button>
		);
	}
}

export default LinkButton;
