import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from './constants';
import { BILLING_DOCUMENT_MODE } from '../components/pages/billingModule/invoicing/billingDocumentModal/BillingDocumentModal';

export const getCanEditRecipientSupplierName = (documentType, mode) => {
	if (
		documentType === BILLING_DOCUMENT_TYPES.FREE_INVOICE &&
		mode === BILLING_DOCUMENT_MODE.CREATE
	) {
		return true;
	}
	return false;
};

export const isReadableInput = (mode) => {
	if (mode === BILLING_DOCUMENT_MODE.PREVIEW) {
		return true;
	}
	return false;
};

export const changeDiscountOnTaxExclude = (props) => {
	const discountWithoutTax =
		props.formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND] ?? 0;

	if (props.formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND]) {
		props.change(INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND, discountWithoutTax);

		props.change(INVOICE_FIELD_NAME.SHOW_IN_INVOICE, false);
		props.change(INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX, discountWithoutTax);
		props.change(INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NOTE, '');
	}

	props.change(INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_ROUNDED, 0);
	props.change(INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED, 0);
};
