import { GET_GLOBAL_PRODUCT_PHASE_TEMPLATES } from '../../actions/types';

const initState = { phaseTemplates: {} };

const phasesReducer = (state = { ...initState }, action) => {
	if (action.type === GET_GLOBAL_PRODUCT_PHASE_TEMPLATES) {
		state.globalPhasesTemplates = { ...action.payload };
		return { ...state };
	}

	return state;
};

export default phasesReducer;
