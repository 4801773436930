import {
	CLEAR_GLOBAL_PRODUCT,
	CLEAR_REDUCER,
	GET_GLOBAL_INVENTORY,
	GET_GLOBAL_PRODUCT,
	GET_INDIVIDUAL_INVENTORY,
	GET_LAB_INVENTORY,
	GET_LAB_TAX_GROUPS
} from '../actions/types';

const initState = {
	currentGlobalProduct: null,
	globalInventory: {},
	labInventory: [],
	individualInventory: []
};

const inventoryReducer = (state = { ...initState }, action) => {
	if (action.type === GET_GLOBAL_PRODUCT) {
		state.currentGlobalProduct = { ...action.payload };
		return { ...state };
	}

	if (action.type === CLEAR_GLOBAL_PRODUCT) {
		state.currentGlobalProduct = null;
		return { ...state };
	}

	if (action.type === GET_GLOBAL_INVENTORY) {
		state.globalInventory = action.payload;
		return { ...state };
	}

	if (action.type === GET_LAB_INVENTORY) {
		state.labInventory = action.payload;
		return { ...state };
	}

	if (action.type === GET_INDIVIDUAL_INVENTORY) {
		state.individualInventory = action.payload;
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default inventoryReducer;
