import React, { Fragment } from 'react';
import Header from '../../../common/Header';
import navigationUtils from '../../../../utils/navigationUtils';
import { PAGES_PATHS, PAGINATION_TYPES, STATEMENT_BALANCE_TYPE } from '../../../../utils/constants';
import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import {
	exportStatementsEndBalanceToExcelOrCsv,
	exportStatementsStartBalanceToExcelOrCsv,
	getStatementEndBalance,
	getStatementStartBalance,
	setPagination
} from '../../../../actions';
import { getFormValues } from 'redux-form';
import DropdownButton from '../../../common/DropdownButton';
import StatementTable from './StatementTable';
import Pagination from '../../../common/Pagination';

class StatementOpenClose extends React.Component {
	constructor(props) {
		super(props);
	}

	getPageDataType() {
		return this.props.location.state.dataType;
	}

	componentDidMount() {
		this.setCurrentPage(1);
	}

	componentWillUnmount() {
		this.resetState();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page && this.props.page) {
			if (this.getPageDataType() === STATEMENT_BALANCE_TYPE.OPENING) {
				this.props.getStatementStartBalance({
					page: this.props.page,
					filters: { ...this.props.formValues, labId: this.props.account.labId }
				});
			} else {
				this.props.getStatementEndBalance({
					page: this.props.page,
					filters: { ...this.props.formValues, labId: this.props.account.labId }
				});
			}
		}
	}

	getTitle() {
		if (navigationUtils.getRoute() === PAGES_PATHS.BILLING_STATEMENT_OPENING) {
			return i18n.t('translation:billingModule.balance.viewOpenBalance');
		}

		return i18n.t('translation:billingModule.balance.viewCloseBalance');
	}

	renderExportDocumentButton = () => {
		const buttons =
			this.props.balance && this.props.balance.length
				? [
						i18n.t('translation:billingModule.common.buttons.exportToExcel'),
						i18n.t('translation:billingModule.common.buttons.exportToCsv')
				  ]
				: [];

		const title = i18n.t('translation:billingModule.common.buttons.export');

		return (
			<div className={'position-relative align-self-end'}>
				<DropdownButton
					title={title}
					buttons={buttons}
					short={true}
					statusButton={false}
					isSecondButton={true}
					onButtonClick={this.getButtonHandler(
						{ ...this.props.formValues },
						this.props.account.labId
					)}
				/>
			</div>
		);
	};

	resetState() {
		this.setCurrentPage(0);
	}

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.STATEMENT_BALANCE, page);
	};

	getButtonHandler(dates, labId) {
		if (this.getPageDataType() === STATEMENT_BALANCE_TYPE.OPENING) {
			return (type) => {
				this.props.exportStatementsStartBalanceToExcelOrCsv(type, dates, labId);
			};
		}

		return (type) => {
			this.props.exportStatementsEndBalanceToExcelOrCsv(type, dates, labId);
		};
	}

	renderPagination = () => {
		const items = this.props.balance;
		if (items && items.length) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={this.props.totalElements}
					setCurrentPage={(page) => this.setCurrentPage(page)}
				/>
			);
		}
	};

	renderTable = () => {
		if (!this.props.balance) {
			return null;
		}

		return (
			<>
				<StatementTable items={this.props.balance} isCurrentBalance={true} />
				{this.renderPagination()}
			</>
		);
	};

	render() {
		return (
			<div className={'col-12 p-0 statement-open-close'}>
				<Header
					title={this.getTitle()}
					backButton
					buttons={[this.renderExportDocumentButton()]}
				/>
				{this.renderTable()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		balance: state.billingStatement.balance.content,
		page: state.pagination[PAGINATION_TYPES.STATEMENT_BALANCE],
		totalElements: state.billingStatement.balance.totalElements,
		formValues: getFormValues('statementForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	getStatementStartBalance,
	getStatementEndBalance,
	exportStatementsEndBalanceToExcelOrCsv,
	exportStatementsStartBalanceToExcelOrCsv
})(StatementOpenClose);
