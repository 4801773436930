import React from 'react';
import { parseInvoicingCurrency } from '../../../../../../../utils/tableUtils';

class TableCell extends React.Component {
	render() {
		return (
			<div className={'data-row'}>
				<span className={'d-inline'}>
					{this.props.currency
						? parseInvoicingCurrency(
								this.props.value,
								this.props.localizedCurrencyAbbreviation,
								this.props.currencySymbol
						  )
						: this.props.value}
				</span>
			</div>
		);
	}
}

export default TableCell;
