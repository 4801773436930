import React, { Fragment } from 'react';
import SubHeader from '../../../common/SubHeader';
import i18n from '../../../../i18n';
import { Field, reduxForm } from 'redux-form';
import { CustomCheckbox } from '../../../common/Fields';
import { connect } from 'react-redux';
import { isGlobalAdmin, registeredInClinic } from '../../../../utils/appUtils';
import { activateDeactivateLabModule, getLabModules } from '../../../../actions';
import { LOADER_TYPES } from '../../../../utils/constants';

function generateFieldName(module) {
	return `${module.code}-${module.id}`;
}

function extractModuleDataFromFieldName(fieldName) {
	const nameArray = fieldName.split('-');
	return {
		id: nameArray[1],
		code: nameArray[0]
	};
}

class ActivateModuleSection extends React.Component {
	render() {
		if (this.props.loader || registeredInClinic(this.props.account)) {
			return <Fragment />;
		}

		const isReadOnlyField = !isGlobalAdmin(this.props.account);
		const readOnlyFieldClass = isReadOnlyField ? 'read-only-field' : 'activate-module-checkbox';
		const options = { readOnlyFieldClass, isReadOnlyField };

		const heightClass = isReadOnlyField ? ' h-s' : 'h-m';

		return (
			<div className="col-6">
				<SubHeader title={i18n.t('translation:taskManager.labs.modules')} />
				<div className="col-12 data-section mt-s p-0">
					<div className={`col-12 ${heightClass}`}>
						<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
							<div className="content row">
								<div className="col-12 pl-0 pb-3">
									{this.renderModules(options)}
									{this.renderCreateLabMessage()}
								</div>
								{this.renderSaveButton(isReadOnlyField)}
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}

	renderCreateLabMessage() {
		const { isLabCreation } = this.props;

		if (!isLabCreation) {
			return null;
		}
		return (
			<div className={'text-center pt-3'}>
				{i18n.t('translation:labs.createLabToActivateModule')}
			</div>
		);
	}

	renderSaveButton(isReadOnlyField) {
		const { isLabCreation } = this.props;

		if (isLabCreation) {
			return null;
		}

		return (
			<div className="col-12 pl-0 pt-1" hidden={isReadOnlyField}>
				<button className="default-button">
					{i18n.t('translation:common.buttons.save').toUpperCase()}
				</button>
			</div>
		);
	}

	renderModules(options) {
		if (!this.props.modules) {
			return;
		}

		return this.props.modules.map((module, index) => this.renderModuleField(module, options));
	}

	renderModuleField = (module, options) => {
		return (
			<Field
				key={module.code}
				name={generateFieldName(module)}
				customProps={{
					className: options.readOnlyFieldClass,
					disabled: options.isReadOnlyField,
					activateModuleSection: true,
					label: module.name
				}}
				component={CustomCheckbox}
			/>
		);
	};

	onFormSubmit = (formValues) => {
		const updatedModulesData = this.prepareUpdateModulesData(formValues);

		if (!updatedModulesData || updatedModulesData.length === 0) {
			return;
		}

		this.props.activateDeactivateLabModule(this.props.company.id, updatedModulesData);
	};

	prepareUpdateModulesData = (formValues) => {
		if (!formValues) {
			return null;
		}

		const modulesData = [];

		for (let key in formValues) {
			const module = extractModuleDataFromFieldName(key);
			modulesData.push({ id: module.id, enabled: formValues[key] });
		}

		return modulesData;
	};
}

const ActiveModuleForm = reduxForm({
	form: 'activeModuleForm',
	enableReinitialize: true
})(ActivateModuleSection);

const mapStateToProps = (state, props) => {
	const getInitValues = (modules) => {
		const initialValues = {};

		if (!modules) {
			return initialValues;
		}

		modules.map((module, index) => {
			initialValues[generateFieldName(module)] = module.enabled;
		});

		return initialValues;
	};

	return {
		modules: state.modules,
		account: state.account,
		initialValues: getInitValues(state.modules),
		loader: state.loader[LOADER_TYPES.GLOBAL_LOADER]
	};
};

export default connect(mapStateToProps, {
	activateDeactivateLabModule,
	getLabModules
})(ActiveModuleForm);
