import { ATTEMPT_LOGIN, LOAD_TOKEN, LOGOUT } from '../actions/types';

const initState = '';

const tokenReducer = (state = initState, action) => {
	if (action.type === LOAD_TOKEN) {
		state = action.payload;
		return state;
	}

	if (action.type === ATTEMPT_LOGIN) {
		state = action.payload.token.value;
		return state;
	}

	if (action.type === LOGOUT) {
		state = '';
		return state;
	}

	return state;
};

export default tokenReducer;
