import {
	HIDE_AVATAR_MODAL,
	HIDE_MESSAGE_MODAL,
	SHOW_AVATAR_MODAL,
	SHOW_MESSAGE_MODAL
} from './types';

export const showMessageModal = (
	modalTitle,
	modalDescription,
	buttons,
	renderContentAsHtml = false,
	isOnFront = false
) => {
	return {
		type: SHOW_MESSAGE_MODAL,
		payload: { modalTitle, modalDescription, buttons, renderContentAsHtml, isOnFront }
	};
};

export const hideMessageModal = () => {
	return { type: HIDE_MESSAGE_MODAL, payload: null };
};

export const showAvatarModal = (image) => {
	return { type: SHOW_AVATAR_MODAL, payload: { image } };
};

export const hideAvatarModal = () => {
	return { type: HIDE_AVATAR_MODAL, payload: null };
};
