import React from 'react';
import i18n from '../../i18n';

import ReturnToHomePage from './ReturnToHomePage';

const UnhandledError = () => (
	<div className="error-page">
		<h2>{i18n.t('translation:errors.unhandled.title')}</h2>
		<hr />
		<p>&nbsp;</p>
		<p>{i18n.t('translation:errors.unhandled.errorText')}</p>
		<ReturnToHomePage />
	</div>
);

export default UnhandledError;
