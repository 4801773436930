import React, { Fragment } from 'react';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import { parseClinicStaff } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import SubHeader from '../../common/SubHeader';
import { connect } from 'react-redux';
import { clearReducers, getClinicDetails, getClinicStaff } from '../../../actions';
import DefaultButton from '../../common/DefaultButton';
import navigationUtils from '../../../utils/navigationUtils';
import { PAGES_PATHS } from '../../../utils/constants';
import CompanyDataSection from './../common/CompanyDataSection';
import {
	faBookOpen,
	faCreditCard,
	faDollarSign,
	faEnvelope,
	faKey,
	faMapMarkedAlt,
	faPhone,
	faTooth
} from '@fortawesome/free-solid-svg-icons';
import Orders from './../common/Orders';
import { isPassiveClinic } from '../../../utils/appUtils';
import StaffWaitingApproval from '../users/StaffWaitingApproval';

class DashboardDentist extends React.Component {
	state = { isMainSectionOpen: true };

	UNSAFE_componentWillMount() {
		this.props.getClinicStaff(this.props.account.clinicId);
		this.props.getClinicDetails(this.props.account.clinicId);
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<Header
					title={this.getHeaderTitle()}
					onTrigger={this.triggerMainSection}
					isOpen={this.state.isMainSectionOpen}
				/>
				<div className="col-12 p-0">
					{this.renderMainSection()}
					<StaffWaitingApproval users={this.props.users} account={this.props.account} />
					<SubHeader title={i18n.t('translation:dashboard.openOrders')} />
					<Orders />
					<SubHeader title={i18n.t('translation:dashboard.users')} />
					<div className="col-12 mt-s p-0">{this.renderUsers()}</div>
				</div>
			</Fragment>
		);
	}

	getHeaderTitle = () => {
		const passive = isPassiveClinic(this.props.currentClinic)
			? ` (${i18n.t('translation:clinics.passive')})`
			: '';
		return i18n.t('translation:dashboard.myClinic') + passive;
	};

	triggerMainSection = () => {
		this.setState({ isMainSectionOpen: !this.state.isMainSectionOpen });
	};

	renderEditClinicButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.edit')}
				onClick={() => {
					navigationUtils.navigate(PAGES_PATHS.MY_PRACTICE_DETAILS);
				}}
			/>
		);
	};

	renderMainSection = () => {
		const { currentClinic } = this.props;
		const firstColumn = [
			{
				title: i18n.t('translation:common.name'),
				value: currentClinic.name,
				icon: faTooth
			},
			{
				title: i18n.t('translation:common.email'),
				value: currentClinic.email,
				icon: faEnvelope
			},
			{
				title: i18n.t('translation:common.registrationCode'),
				value: currentClinic.registrationCode,
				icon: faKey
			}
		];

		const secondColumn = [
			{
				title: i18n.t('translation:common.legalName'),
				value: currentClinic.legalName,
				icon: faBookOpen
			},
			{
				title: i18n.t('translation:common.registeredOffice'),
				value: currentClinic.registeredOffice,
				icon: faMapMarkedAlt
			},
			{
				title: i18n.t('translation:common.phone'),
				value: currentClinic.phone,
				icon: faPhone
			}
		];

		const thirdColumn = [
			{
				title: i18n.t('translation:common.bankInformation'),
				value: [
					`${i18n.t('translation:common.IBAN')}: ${currentClinic.iban || ''}`,
					`${i18n.t('translation:common.BIC')}: ${currentClinic.bic || ''}`,
					`${i18n.t('translation:common.bankName')}: ${currentClinic.bankName || ''}`,
					`${i18n.t('translation:common.bankAddress')}: ${
						currentClinic.bankAddress || ''
					}`
				],
				icon: faCreditCard
			},
			{
				title: i18n.t('translation:common.invoicingInformation'),
				value: [
					`${i18n.t('translation:common.fields.companyOwner')}: ${
						currentClinic.owner || ''
					}`,
					`${i18n.t('translation:common.fields.uic')}: ${currentClinic.uic || ''}`,
					`${i18n.t('translation:common.fields.vatNumber')}: ${
						currentClinic.vatNumber || ''
					}`
				],
				icon: faDollarSign
			}
		];

		const columns = [firstColumn, secondColumn, thirdColumn];

		return this.state.isMainSectionOpen ? (
			<CompanyDataSection columns={columns} locations={currentClinic.locations} />
		) : (
			<Fragment />
		);
	};

	renderUsers = () => {
		const users = parseClinicStaff(this.props.users);

		return (
			<Table
				columns={[
					i18n.t('translation:common.table.name'),
					i18n.t('translation:common.table.phone'),
					i18n.t('translation:common.table.email'),
					i18n.t('translation:common.table.status')
				]}
				data={users}
			/>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		users: state.users.users.content,
		account: state.account,
		currentClinic: state.clinics.currentClinic,
		statistics: state.statistics
	};
};

export default connect(mapStateToProps, {
	getClinicStaff,
	getClinicDetails,
	clearReducers
})(DashboardDentist);
