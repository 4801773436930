import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../../i18n';
import Header from '../../../../common/Header';
import SubHeader from '../../../../common/SubHeader';
import Badge from '../../../../common/Badge';
import Calendar from '../../../../common/Calendar';
import StatisticsItem from '../../../dashboards/StatisticsItem';
import NotFound from '../../../../errors/NotFound';
import { isTaskManagerEnabled } from '../../../../../utils/appUtils';
import moment from '../../../../../utils/moment';
import {
	DEFAULT_USER_DATE_FORMAT,
	FILTER_FIELDS,
	PAGES_PATHS,
	STATISTIC_TYPES
} from '../../../../../utils/constants';
import {
	getDashboardMonthlyOrdersItems,
	getDashboardOrdersItems,
	getHolidays
} from '../../../../../actions';
import {
	faBan,
	faHourglassEnd,
	faHourglassHalf,
	faHourglassStart
} from '@fortawesome/free-solid-svg-icons';
import navigationMethods from '../../../../../utils/navigationUtils';

class CalendarDashboard extends React.Component {
	state = {
		date: moment(),
		startOfYear: moment().startOf('year')
	};

	componentDidMount() {
		const labId = this.props.account.labId;
		this.props.getDashboardOrdersItems(labId);
		this.props.getHolidays(labId, this.state.date.format('YYYY'));
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <NotFound />;
		}

		const subTitleForCurrentDay = i18n.t(
			'translation:taskManager.calendar.dashboard.subTitleForCurrentDay'
		);
		const subTitleForCurrentMonth = i18n.t(
			'translation:taskManager.calendar.dashboard.subTitleForCurrentMonth'
		);

		return (
			<Fragment>
				<Header
					title={i18n.t('translation:taskManager.calendar.dashboard.title')}
					buttons={[]}
				/>
				{this.renderSubHeader(subTitleForCurrentDay, true)}
				<div className="statistics dashboard-statistics mb-m p-0">
					{this.renderStatisticBadges()}
				</div>
				{this.renderSubHeader(subTitleForCurrentMonth, false)}
				{this.renderCalendar()}
				{this.renderCalendarLegend()}
			</Fragment>
		);
	}

	renderSubHeader = (subTitle, isBadgeShown) => {
		const badge = isBadgeShown ? (
			<Badge
				backgroundColor={'eaf1f9'}
				className="dark-text-span"
				name={this.state.date.format(DEFAULT_USER_DATE_FORMAT)}
			/>
		) : null;

		const title = (
			<div className="d-flex align-items-center">
				<h2 className="mr-xs ml-xs">{subTitle}</h2>
				{badge}
			</div>
		);

		return <SubHeader title={title} buttons={[]} />;
	};

	renderStatisticBadges() {
		return Object.keys(this.props.statisticOrders).map((item, index) => {
			const className = this.props.statisticOrders[item].length ? 'pointer' : '';
			return (
				<StatisticsItem
					description={this.getDescription(item)}
					key={index}
					value={this.props.statisticOrders[item].length}
					icon={this.getIcon(item)}
					className={className}
					onClick={() =>
						this.props.statisticOrders[item].length
							? navigationMethods.navigate(
									PAGES_PATHS.CALENDAR_ORDERS,
									{},
									{},
									{
										[FILTER_FIELDS.LAB_ORDER_ID_LIST]:
											this.props.statisticOrders[item].join(';')
									}
							  )
							: null
					}
					// onClick={
					//     ()=> this.props.statisticOrders[item].length ? navigationMethods.navigate(PAGES_PATHS.CALENDAR_ORDERS,
					//         {},
					//         {},
					//         {[FILTER_FIELDS.LAB_ORDER_ID_LIST]: this.props.statisticOrders[item].join(';'), [FILTER_FIELDS.START_DATE]: this.state.date.format(DEFAULT_BACKEND_DATE_FORMAT), [FILTER_FIELDS.END_DATE]: this.state.date.format(DEFAULT_BACKEND_DATE_FORMAT)}) : null}
				/>
			);
		});
	}

	getIcon = (type) => {
		const icons = {
			[STATISTIC_TYPES.OVERDUE_ORDERS]: faHourglassEnd,
			[STATISTIC_TYPES.DUE_TODAY_ORDERS]: faHourglassHalf,
			[STATISTIC_TYPES.DUE_TOMORROW_ORDERS]: faHourglassStart,
			[STATISTIC_TYPES.BLOCKED_ORDERS]: faBan
		};
		return icons[type];
	};

	getDescription = (type) => {
		const description = {
			overdueOrders: i18n.t('translation:taskManager.calendar.dashboard.overdueCount'),
			dueTodayOrders: i18n.t('translation:taskManager.calendar.dashboard.dueTodayCount'),
			dueTomorrowOrders: i18n.t(
				'translation:taskManager.calendar.dashboard.dueTomorrowCount'
			),
			blockedOrders: i18n.t('translation:taskManager.calendar.dashboard.blockedCount')
		};
		return description[type];
	};

	renderCalendar = () => {
		const events = this.props.dashboardOrders.monthly.map((day, index) => {
			return {
				start: day.date,
				end: day.date,
				completedOrders: day.completedOrders !== null ? day.completedOrders.length : null,
				inProgressOrders:
					day.inProgressOrders !== null ? day.inProgressOrders.length : null,
				notPlannedOrders:
					day.notPlannedOrders !== null ? day.notPlannedOrders.length : null,
				overdueOrders: day.overdueOrders !== null ? day.overdueOrders.length : null,
				toBeSentOrders: day.toBeSentOrders !== null ? day.toBeSentOrders.length : null,
				onClickInProgress: () =>
					navigationMethods.navigate(
						PAGES_PATHS.CALENDAR_ORDERS,
						{},
						{},
						{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: day.inProgressOrders.join(';') }
					),
				onClickCompleted: () =>
					navigationMethods.navigate(
						PAGES_PATHS.CALENDAR_ORDERS,
						{},
						{},
						{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: day.completedOrders.join(';') }
					),
				onClickNotPlanned: () =>
					navigationMethods.navigate(
						PAGES_PATHS.CALENDAR_ORDERS,
						{},
						{},
						{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: day.notPlannedOrders.join(';') }
					),
				onClickТoBeSent: () =>
					navigationMethods.navigate(
						PAGES_PATHS.CALENDAR_ORDERS,
						{},
						{},
						{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: day.toBeSentOrders.join(';') }
					)
			};
		});

		return Array.from(Array(1).keys()).map((el, index) => {
			return (
				<div key={index} className="mt-s dashboard-calendar">
					<Calendar
						dashboard
						events={events}
						date={moment(this.state.date).add(index, 'M')}
						addMonth={this.addMonth}
						subtractMonth={this.subtractMonth}
					/>
				</div>
			);
		});
	};

	addMonth = () => {
		const labId = this.props.account.labId;
		const date = moment(this.state.date).add(1, 'M');
		const year = date.format('YYYY');
		const month = date.format('M');

		this.props.getDashboardMonthlyOrdersItems(labId, year, month);
		this.setState({ date });
	};

	subtractMonth = () => {
		const labId = this.props.account.labId;
		const date = moment(this.state.date).subtract(1, 'M');
		const year = date.format('YYYY');
		const month = date.format('M');

		this.props.getDashboardMonthlyOrdersItems(labId, year, month);
		this.setState({ date });
	};

	renderCalendarLegend = () => {
		const legendArray = [
			{
				name: i18n.t('translation:taskManager.calendar.dashboard.completedCount'),
				className: 'completed-count'
			},
			{
				name: i18n.t('translation:taskManager.calendar.dashboard.inProgressCount'),
				className: 'in-progress-count'
			},
			{
				name: i18n.t('translation:taskManager.calendar.dashboard.notPlannedCount'),
				className: 'not-planned-count'
			},
			{
				name: i18n.t('translation:taskManager.calendar.dashboard.toBeSentOrders'),
				className: 'to-be-sent-count'
			}
		];

		return (
			<div className="col-md-12 col-lg-12 col-xl-12 p-0 mt-s dashboard-calendar-legend">
				{legendArray.map((item, index) => {
					return (
						<Badge key={'badge' + index} className={item.className} name={item.name} />
					);
				})}
			</div>
		);
	};
}
const mapStateToProps = (state) => {
	return {
		account: state.account,
		modules: state.modules,
		holidays: state.holidays,
		statisticOrders: state.dashboard.daily,
		dashboardOrders: state.dashboard
	};
};

export default connect(mapStateToProps, {
	getDashboardOrdersItems,
	getDashboardMonthlyOrdersItems,
	getHolidays
})(CalendarDashboard);
