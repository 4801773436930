import noAvatar from '../resources/images/noAvatar.png';

// eslint-disable-next-line no-undef
export const TEACS_BACK_END_BASE_URL = process.env.REACT_APP_BACK_END_BASE_URL;
// eslint-disable-next-line no-undef
export const TEACS_FILES_LOCATION_BASE_URL = process.env.REACT_APP_IMAGES_LOCATION_BASE_URL;

export const TOKEN_ITEM_IN_LOCAL_STORAGE = 'TEACS_ERP_TOKEN';
export const CURRENT_USER_IN_LOCAL_STORAGE = 'TEACS_ERP_CURRENT_USER';
export const SETTINGS_IN_LOCAL_STORAGE = 'TEACS_ERP_SETTINGS';
export const ACCESSING_SITE_FOR_FIRST_TIME_IN_LOCAL_STORAGE = 'ACCESSING_SITE_FOR_FIRST_TIME';

export const SUCCESS_NOTIFICATION_POPUP_TIMEOUT_IN_MILLISECONDS = 7000;

export const EMPTY_STRING = '';
export const SINGLE_SPACE = ' ';

export const NO_OPTION_SELECTED_VALUE = 'NONE';

export const FEEDBACK_SIDE_MENU_ITEM = 'FEEDBACK_SIDE_MENU_ITEM';

export const MIN_TEXT_FIELD_LENGTH = {
	PASSWORD: 8,
	USERNAME: 6
};

export const MAX_TEXT_FIELD_LENGTH = {
	ULTRA_SHORT: 3,
	SHORT: 20,
	NORMAL: 100,
	LONG: 250,
	VERY_LONG: 1000
};

export const USER_TYPE = {
	DENTIST: 'DENTIST',
	DENTAL_TECHNICIAN: 'DENTAL_TECHNICIAN'
};

export const ORGANIZATION_TYPE = {
	NEW_CLINIC: 'NEW_CLINIC',
	EXISTING_CLINIC: 'EXISTING_CLINIC',
	NEW_LAB: 'NEW_LAB',
	EXISTING_LAB: 'EXISTING_LAB'
};

export const SIGN_UP_ORGANIZATION_TYPE = {
	CLINIC: 'Clinic',
	LAB: 'Lab'
};

export const USER_ROLE = {
	GLOBAL_ADMIN: 'GLOBAL_ADMIN',
	LOCAL_ADMIN: 'LOCAL_ADMIN',
	USER: 'USER'
};

export const ACCOUNT_TYPE = {
	GLOBAL_ADMIN: 'GlobalAdmin',
	DENTIST: 'Dentist',
	LAB_ADMIN: 'LabAdmin',
	LAB_TECHNICIAN: 'LabTechnician'
};

export const GLOBAL_PRODUCT_CATEGORY = {
	CONSECUTIVE_TEETH: 'CONSECUTIVE_TEETH',
	CONSTANT_PRICE: 'CONSTANT_PRICE',
	CONSTANT_PRICE_CONSECUTIVE_TEETH: 'CONSTANT_PRICE_CONSECUTIVE_TEETH'
};

export const COUNTRY_CODES = ['BG', 'DE', 'GB', 'TR'];

export const COUNTRY_CODES_BILLING_MODULE = {
	BG: 'BG',
	DE: 'DE',
	EN: 'EN',
	TR: 'TR',
	OTHER: 'OTHER'
};

export const LANGUAGE_FLAG_NAMES = {
	BG: 'Български',
	DE: 'Deutsch',
	GB: 'English',
	TR: 'Türkçe'
};

export const LANGUAGE_CODES = {
	BG: 'bg',
	DE: 'de',
	EN: 'en',
	TR: 'tr'
};

export const LANGUAGE_DISPLAY_NAMES = {
	BG: 'Български',
	DE: 'Deutsch',
	EN: 'English',
	TR: 'Türkçe'
};

export const COUNTRY_DISPLAY_NAMES = {
	BG: 'България',
	DE: 'Deutschland',
	UK: 'United Kingdom',
	TR: 'Türkiye'
};

const prefix = '';

export const PAGES_PATHS = {
	SIGN_UP: prefix + '/sign-up',
	HOME: prefix + '/',
	PRACTICES: prefix + '/clinics',
	PRACTICE_DETAILS: prefix + '/clinics/:id',
	PRACTICE_CREATE: prefix + '/clinics/create',
	LABORATORIES: prefix + '/labs',
	LABORATORY_DETAILS: prefix + '/labs/:id',
	LABORATORY_CREATE: prefix + '/labs/create',
	USERS: prefix + '/users',
	USER_DETAILS: prefix + '/users/:id',
	MY_ACCOUNT: prefix + '/my-account',
	ORDERS: prefix + '/orders',
	ORDERS_DETAILS: prefix + '/orders/:id',
	ORDERS_CREATE: prefix + '/orders/create',
	INVENTORY: prefix + '/inventory',
	LOCAL_TEMPLATES: prefix + '/inventory/:id/templates',
	ADMINISTRATORS: prefix + '/administrators',
	ADMINISTRATOR_DETAILS: prefix + '/administrators/:id',
	FORGOTTEN_PASSWORD: prefix + '/forgotten-password',
	NEW_PASSWORD: prefix + '/reset-password',
	CLIENTS: prefix + '/clients',
	CLIENT_DETAILS: prefix + '/clients/:id',
	CLIENT_PRICE_LIST: prefix + '/clients/:id/price-list',
	SUPPLIERS: prefix + '/suppliers',
	SUPPLIER_DETAILS: prefix + '/suppliers/:id',
	MY_LABORATORY: prefix + '/my-lab',
	MY_LABORATORY_DETAILS: prefix + '/my-lab-details',
	MY_PRACTICE_DETAILS: prefix + '/my-clinic-details',
	PLANNER: prefix + '/planner',
	PLANNER_WORK_SCHEDULE: prefix + '/planner/work-schedule',
	PLANNER_WORKING_HOURS: prefix + '/planner/working-hours',
	PLANNER_HOLIDAYS: prefix + '/planner/holidays',
	PLANNER_STAFF: prefix + '/planner/staff',
	CALENDAR_STAFF: prefix + '/calendar/staff',
	CALENDAR_ORDERS: prefix + '/calendar/orders',
	CALENDAR_DASHBOARD: prefix + '/calendar/dashboard',
	CALENDAR: prefix + '/calendar',
	BILLING_FINANCE: prefix + '/finance',
	BILLING_INVOICING: prefix + '/finance/invoicing',
	BILLING_STATEMENT: prefix + '/finance/statement',
	BILLING_STATEMENT_OPENING: prefix + '/finance/statement/opening',
	BILLING_STATEMENT_CLOSING: prefix + '/finance/statement/closing',
	BILLING_DOCUMENTS: prefix + '/finance/documents',
	BILLING_INCOMES_OUTCOMES: prefix + '/finance/transactions',
	BILLING_INCOMES: prefix + '/finance/incomes',
	BILLING_OUTCOMES: prefix + '/finance/outcomes',
	BILLING_INCOMES_DETAILS: prefix + '/finance/incomes/:id',
	BILLING_OUTCOMES_DETAILS: prefix + '/finance/outcomes/:id',
	BILLING_SETTINGS: prefix + '/finance/settings',
	BILLING_DEBTOR_DAYS: prefix + '/finance/debtor-days'
};

export const TABLE_ACTIONS = {
	VIEW: 'view',
	DELETE: 'delete',
	ORDER: 'order',
	EDIT: 'edit',
	LINK: 'link',
	UNLINK: 'unlink',
	SEND_AGAIN: 'sendAgain',
	CANCEL: 'cancel',
	TEMPLATES_GLOBAL: 'templatesGlobal',
	TEMPLATES_LOCAL: 'templatesLocal',
	CLEAR: 'clear',
	EXPAND_COLLAPSE: 'expand_collapse',
	EXPAND: 'expand',
	COLLAPSE: 'collapse',
	INFO: 'info',
	WARNING: 'warning',
	DOWNLOAD: 'download',
	CHECK: 'check'
};

export const STAFFCHART_MODES = { HOURS: 'HOURS', WEEKS: 'WEEKS' };

export const PLANNING_STATUS = {
	NOT_PLANNED: 'NotPlanned',
	OVERDUE: 'Overdue',
	PLANNED: 'Planned',
	DUE_TODAY: 'DueToday'
};

export const WORK_ALLOCATIONS = {
	OUTSIDE_ASSIGNED: 'OutsideAssignedShifts',
	UNDERUSED: 'Underused',
	FULLY_UTILIZED: 'FullyUtilized'
};

export const USER_STATUS = {
	WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	DEACTIVATED: 'DEACTIVATED'
};

export const COMPANY_STATUS = {
	WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
	ACTIVE: 'ACTIVE',
	DEACTIVATED: 'DEACTIVATED'
};

export const ORDER_STATUS = {
	DRAFT: 'DRAFT',
	OPENED: 'OPENED',
	INFORMATION_REQUIRED: 'INFORMATION_REQUIRED',
	CHANGES_NOT_ACCEPTED: 'CHANGES_NOT_ACCEPTED',
	ACCEPTED: 'ACCEPTED',
	IN_PROGRESS: 'IN_PROGRESS',
	ON_HOLD: 'ON_HOLD',
	IN_TESTING: 'IN_TESTING',
	PRODUCED: 'PRODUCED',
	SENT: 'SENT',
	RECEIVED: 'RECEIVED',
	WORK_REQUIRED: 'WORK_REQUIRED',
	MOUNTED_TEMPORARILY: 'MOUNTED_TEMPORARILY',
	MOUNTED: 'MOUNTED',
	REJECTED: 'REJECTED',
	COMPLETED: 'COMPLETED'
};

export const TASK_STATUS = {
	OPENED: 'OPENED',
	IN_PROGRESS: 'IN_PROGRESS',
	ON_HOLD: 'ON_HOLD',
	CANCELED: 'CANCELED',
	COMPLETED: 'COMPLETED'
};

export const TRANSACTION_STATUS = {
	PENDING: 'PENDING',
	PARTIALLY: 'PARTIALLY',
	COMPLETED: 'COMPLETED',
	CANCELED: 'CANCELED'
};

export const TRANSACTION_TYPE = {
	CREDIT: 'CREDIT',
	DEBIT: 'DEBIT'
};

export const TRANSACTION_METHOD = {
	BANK: 'BANK',
	CASH: 'CASH',
	POS: 'POS'
};

//TODO
export const OUTCOME_DOCUMENT_TYPE = {
	CREDIT_NOTE: 'CREDIT_NOTE',
	DEBIT_NOTE: 'DEBIT_NOTE'
};

export const ORDER_FIELDS = {
	LAB: 'Lab',
	CLINIC: 'Clinic',
	LAB_TECHNICIAN: 'LabTechnician',
	CREATE_DATE: 'CreateDate',
	DUE_DATE: 'DueDate',
	DELIVERY_DATE: 'DeliveryDate',
	PATIENT_NAME: 'PatientName',
	PATIENT_NAME_VISIBLE_TO_LAB: 'PatientNameVisibleToLab',
	DENTIST: 'Dentist',
	NOTES: 'Notes',
	INITIAL_PRICE: 'InitialPrice',
	DISCOUNT: 'Discount',
	IS_PRIORITY_ORDER: 'IsPriorityOrder',
	PRICE_INCREASE: 'PriceIncrease',
	PRICE: 'Price',
	PAYMENT_DUE_DATE: 'PaymentDueDate',
	PAYMENT_TERMS: 'PaymentTerms',
	PAID_AMOUNT: 'PaidAmount',
	AMOUNT_DUE: 'AmountDue',
	LAST_PAYMENT_DATE: 'LastPaymentDate',
	DELIVERY_LOCATION: 'DeliveryLocation',
	RETURN_LOCATION: 'ReturnLocation',
	TAX: 'Tax',
	PRICE_WITH_TAX: 'PriceWithTax'
};

export const FILTER_FIELDS = {
	LAB_ORDER_ID: 'labOrderId',
	CLINIC_NAME: 'clinicName',
	LAB_NAME: 'labName',
	CLINIC_LEGAL_NAME: 'clinicLegalName',
	LAB_LEGAL_NAME: 'labLegalName',
	LAB_TECHNICIAN_NAME: 'labTechnicianName',
	STATUSES: 'statuses',
	DENTIST_NAME: 'dentistName',
	CREATE_DATE_FROM: 'createDateFrom',
	CREATE_DATE_TO: 'createDateTo',
	DELIVERY_DATE_FROM: 'deliveryDateFrom',
	DELIVERY_DATE_TO: 'deliveryDateTo',
	PAYMENT_DUE_DATE_FROM: 'paymentDueDateFrom',
	PAYMENT_DUE_DATE_TO: 'paymentDueDateTo',
	DUE_DATE_FROM: 'dueDateFrom',
	DUE_DATE_TO: 'dueDateTo',
	PRICE_FROM: 'priceFrom',
	PRICE_TO: 'priceTo',
	PERFORMERS: 'userIdList',
	LAB_TECHNICIAN_ID_LIST: 'labTechnicianIdList',
	TASK_ASSIGNEE_ID_LIST: 'taskAssigneeIdList',
	CLINIC_LIST: 'clinicIdList',
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	SHIFTS_AND_ABSENCES: 'shiftAndAbsence',
	PATIENT_NAME: 'patientName',
	LAB_ORDER_ID_LIST: 'labOrderIdList',
	TASK_ID_LIST: 'taskIdList',
	ORDER_STATUSES: 'orderStatuses',
	TASK_STATUSES: 'taskStatuses',
	TASK_STATUSES_STAFF: 'statuses',
	TITLE: 'title',
	TYPES: 'types',
	NUMBER: 'number',
	TRANSACTION_NUMBER_LAB: 'labTransactionId',
	BILLING_DOCUMENT_NUMBER: 'billingDocumentNumber',
	ISSUE_DATE_FROM: 'issueDateFrom',
	ISSUE_DATE_TO: 'issueDateTo',
	TRANSACTION_DATE_FROM: 'transactionDateFrom',
	TYPE: 'type',
	TRANSACTION_DATE_TO: 'transactionDateTo',
	TRANSACTION_CONNECTED_TO_INVOICE_OF_DEBIT: 'connectedToInvoicesOrDebitNotes'
};

export const ORDER_ITEM_FIELDS = {
	PRODUCT: 'ProductId',
	COLOR_SCHEME: 'ColorScheme',
	COLOR_CODE: 'ColorCode',
	PRICE: 'Price',
	WARRANTY_IN_MONTHS: 'WarrantyInMonths',
	METAL_TEST_DATE: 'MetalTestDate',
	CERAMIC_TEST_DATE: 'CeramicTestDate',
	INDIVIDUAL_SPOON_TEST_DATE: 'IndividualSpoonTestDate',
	BITE_TEST_DATE: 'BiteTestDate',
	ARRANGED_TEETH_TEST_DATE: 'ArrangedTeethTestDate',
	TEETH: 'Teeth',
	NOTES: 'Notes',
	NO_COLOR_SPECIFIED: 'NoColorSpecified'
};

export const TOOTH_TYPES = {
	TOOTH: 'TOOTH',
	MISSING_TOOTH: 'MISSING_TOOTH',
	IMPLANT: 'IMPLANT'
};

export const LOADER_TYPES = {
	COLOR_LOADER: 'colorLoader',
	GLOBAL_LOADER: 'globalLoader',
	PRODUCT_LOADER: 'productLoader',
	GANTTCHART_LOADER: 'ganttchartLoader'
};

export const ORDER_SORT_KEYS = {
	LAB_ORDER_ID: 'labOrderId',
	CLINIC_NAME: 'clinicName',
	CLINIC_LEGAL_NAME: 'clinicLegalName',
	DENTIST_NAME: 'dentistName',
	LAB_NAME: 'labName',
	LAB_LEGAL_NAME: 'labLegalName',
	LAB_TECHNICIAN_NAME: 'labTechnicianName',
	CREATE_DATE: 'createDate',
	PAYMENT_DUE_DATE: 'paymentDueDate',
	DELIVERY_DATE: 'deliveryDate',
	STATUS: 'status',
	PRICE: 'price'
};

export const COMMON_REDUCER_TYPES = {
	OPEN_MODAL: 'OPEN_MODAL'
};

export const ORDER_SORT_DIRECTORY = {
	ASC: 'ASC',
	DESC: 'DESC'
};

export const MULTI_MARK_DAYS_SORT_DIRECTORY = {
	ASC: 'ASC',
	DESC: 'DESC'
};

export const MULTI_MARK_DAYS_SORT_KEYS = {
	USER_NAME: 'userName',
	START_DATE: 'startDateTime',
	END_DATE: 'endDateTime'
};

export const SPECIAL_ORDER_FILE_TYPES = {
	PHOTO: 'PHOTO',
	VIDEO: 'VIDEO'
};

export const ORDER_FILE_SUB_TYPE = {
	ADDITIONAL: 'ADDITIONAL',
	LEFT_OCCLUSION: 'LEFT_OCCLUSION',
	RIGHT_OCCLUSION: 'RIGHT_OCCLUSION',
	FRONT_CENTRAL: 'FRONT_CENTRAL',
	FRONT_CENTRAL_WITH_SLIGHT_SMILE: 'FRONT_CENTRAL_WITH_SLIGHT_SMILE',
	UPPER_WITH_SMILE: 'UPPER_WITH_SMILE',
	ARTICULATION_VIDEO: 'ARTICULATION_VIDEO'
};

export const VISIBLE_AVATAR_DIMENSIONS = {
	WIDTH: 200,
	HEIGHT: 200
};

export const SERVER_SETTING_NAMES = {
	USER_AVATAR_MAX_SIZE_IN_MB: 'user.avatar.maxSizeInMb',
	ORDER_PHOTOS_MAX_SIZE_IN_MB: 'order.photos.maxSizeInMb',
	UI_DEFAULT_TABLE_PAGE_SIZE: 'ui.defaultTablePageSize',
	ALL_CURRENCY_CODES: 'ALL_CURRENCY_CODES'
};

export const NO_AVATAR = noAvatar;

export const PAGINATION_TYPES = {
	WAITING_FOR_APPROVAL_USERS: 'WAITING_FOR_APPROVAL_USERS',
	NON_ADMIN_USERS: 'NON_ADMIN_USERS',
	CLINICS: 'CLINICS',
	LINKED_LABS: 'LINKED_LABS',
	LINKED_CLINICS: 'LINKED_CLINICS',
	LABS: 'LABS',
	ORDERS: 'ORDERS',
	GLOBAL_PRODUCTS: 'GLOBAL_PRODUCTS',
	BILLING_DOCUMENTS: 'BILLING_DOCUMENTS',
	BILLING_ORDERS: 'BILLING_ORDERS',
	PRE_CREATE_MODAL_BILLING_DOCUMENTS: 'PRE_CREATE_MODAL_BILLING_DOCUMENTS',
	PRE_CREATE_MODAL_BILLING_ORDERS: 'PRE_CREATE_MODAL_BILLING_ORDERS',
	TRANSACTIONS: 'TRANSACTIONS',
	DEBTOR_DAYS: 'DEBTOR_DAYS',
	DEBTOR_DAYS_DOCUMENTS: 'DEBTOR_DAYS_DOCUMENTS',
	STATEMENT: 'STATEMENT',
	STATEMENT_BALANCE: 'STATEMENT_BALANCE'
};

export const SORT_TYPES = {
	ORDER_SORT: 'ORDER_SORT'
};

export const SLIDER_DEFAULT_VALUES = {
	MIN: 1,
	MAX: 10,
	STEP: 1,
	START_VALUE: 5
};

export const ORDER_ADDITIONAL_FILES_PER_ROW = 6;

export const STATISTIC_TYPES = {
	ORDERS: 'orderCount',
	LINKED_COMPANIES: 'linkedCompanyCount',
	PAID_AMOUNT: 'totalPaidAmount',
	USERS: 'userCount',
	LABS: 'labCount',
	CLINICS: 'clinicCount',
	OVERDUE_ORDERS: 'overdueOrders',
	DUE_TODAY_ORDERS: 'dueTodayOrders',
	DUE_TOMORROW_ORDERS: 'dueTomorrowOrders',
	BLOCKED_ORDERS: 'blockedOrders',
	TO_BE_SENT_ORDERS: 'toBeSentOrders',
	OVERDUE_TASKS: 'overdueTasks',
	DUE_TODAY_TASKS: 'dueTodayTasks',
	DUE_TOMORROW_TASKS: 'dueTomorrowTasks',
	BLOCKED_TASKS: 'blockedTasks'
};

export const CLIENT_PAYMENT_STATUS = {
	NO_ORDERS: 'NoOrdersRequiringPayment',
	NO_DEBTS: 'PaysOnTime',
	HAVE_DEBTS: 'DelayedPaymentInThePast'
};

export const LINK_INVITATION_ACTION_RESULT = {
	INVITATION_SENT: 'InvitationSent',
	LINK_CREATED: 'LinkCreated',
	ORGANIZATION_NOT_EXIST: 'OrganizationNotExist'
};

export const DISPLAY_TYPES = {
	TEXT: 'TEXT',
	INTEGER: 'INTEGER',
	FLOAT: 'FLOAT',
	PRICE: 'PRICE'
};

export const ERROR_CODES = {
	WORK_DAYS_OVERWRITE: 4061,
	TASK_CONFLICTS: 4062
};

export const ERROR_MESSAGES = {
	NUMBER_AND_ISSUE_DATE_NOT_UNIQUE: 'NUMBER_AND_ISSUE_DATE_NOT_UNIQUE'
};

export const SUCCESS_CODES = {
	CLOSE_TASKS: 2002
};

export const ORDER_FIELD_STATUSES = {
	READ_ONLY: 'ReadOnly'
};

export const DEFAULT_BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_USER_DATE_FORMAT = 'DD-MM-YYYY';
export const DEFAULT_TIME_FORMAT = 'HH:mm';

export const LANGUAGE_TABLE_FIELD_PREFIXES = {
	PRODUCT_TYPE: 'productType_',
	PRODUCT_MATERIAL: 'productMaterial_'
};

export const REDUX_FORM_NAMES = {
	TABLE: 'tableForm',
	GLOBAL_PRODUCT_DETAILS: 'globalProductDetailsForm'
};

export const MODULE_CODES = {
	TASK_MANAGER: 'TM',
	BILLING_MODULE: 'BI'
};

export const MODULE_IDS = {
	TASK_MANAGER: 1
};

export const WORKDAY_TYPES = {
	PUBLIC_HOLIDAY: 'PUBLIC_HOLIDAY',
	WORK_SHIFT: 'WORK_SHIFT',
	NO_WORKING_DAY: 'NO_WORKING_DAY'
};

export const BILLING_DOCUMENT_TYPES = {
	ORDER_INVOICE: 'ORDER_INVOICE',
	FREE_INVOICE: 'FREE_INVOICE',
	CREDIT_NOTE: 'CREDIT_NOTE',
	DEBIT_NOTE: 'DEBIT_NOTE'
};

export const BILLING_DOCUMENT_FILTER_TYPES = {
	INVOICE: 'INVOICE',
	CREDIT_NOTE: 'CREDIT_NOTE',
	DEBIT_NOTE: 'DEBIT_NOTE'
};

export const BILLING_DOCUMENT_STATUS = {
	OPENED: 'OPENED',
	SENT_FOR_APPROVAL: 'SENT_FOR_APPROVAL',
	DRAFT: 'DRAFT',
	REJECTED: 'REJECTED',
	PAID_PARTIALLY: 'PAID_PARTIALLY',
	PAID: 'PAID'
};

export const BILLING_PRODUCT_UNIT_TYPE = {
	HOURS: 'HOURS',
	NUMBER: 'NUMBER'
};

export const INVOICE_FIELD_NAME = {
	//items
	ITEM_UNIT_PRICE_NO_TAX_: 'itemUnitPriceNoTax_',
	ITEM_QUANTITY_: 'itemQuantity_',
	ITEM_TAX_PERCENT_TAX_NAME_: 'itemTaxPercentTaxName_',
	ITEM_INITIAL_TAX_PERCENT_TAX_NAME_: 'itemInitialTaxPercentTaxName_',
	ITEM_TOTAL_PRICE_WITH_TAX_: 'itemTotalPriceWithTax_',
	ITEM_TOTAL_TAX_: 'itemTotalTax',
	ITEM_NAME_: 'itemName_',
	ITEM_UNIT_: 'itemUnit_',
	ITEM_AMOUNT_NO_TAX_: 'itemAmountNoTax_',
	ITEM_ADVANCE_NO_TAX_: 'itemAdvanceNoTax_',
	ITEM_CREDIT_DEBIT_ROUND_: 'itemCreditDebitROUND_',
	ITEM_CREDIT_DEBIT_NO_ROUND_: 'itemCreditDebitNoRound_',
	ITEM_INVOICING_DISCOUNT_: 'itemCreditDiscount_',
	ITEM_REMAINING_CREDIT_AMOUNT_: 'itemCreditDiscountInvolved',
	ITEM_CATALOG_NUMBER_: 'itemCatalogNumber_',
	ITEM_ID_: 'itemId',

	//document
	DOCUMENT_TOTAL_WITH_TAX: 'documentTotalWithTax',
	DOCUMENT_TOTAL_TAX: 'documentTotalTax',
	DOCUMENT_TOTAL_NO_TAX: 'documentTotalNoTax',
	DOCUMENT_ORDERS_IDS: 'documentOrdersIds',
	MAP_ORDER_ID_ID: 'mapOrderIdId',
	DOCUMENT_INVOICES_IDS: 'invoiceInvoicesIDS',
	DOCUMENT_DISCOUNT_NO_TAX_ROUND: 'documentDiscountRounded',
	DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND: 'documentDiscountNoROund',
	DOCUMENT_DISCOUNT_TAX_ROUNDED: 'invoiceDiscountTax',
	DOCUMENT_DISCOUNT_WITH_TAX: 'discountWithTax',
	DOCUMENT_DISCOUNT_NOTE: 'discountNote',
	DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED: 'invoiceDiscountTaxNotFormatted',
	//free invoice: ['index', 'index']
	//order invoice: [[orderId]: ['id', 'id', 'id']]
	//credit/debit note: [[invoiceID]: ['id', 'id', 'id']]
	DOCUMENT_ITEMS_IDS: 'documentItemsIds',
	//[item.id] = item.creditedItemId;
	MAP_ITEM_ID_BILLING_ITEM_ID: 'mapItemIdBillingItemId',
	//freeInvoice: []
	//orderInvoice: ['id', 'id']
	//creditNote: [[invoiceID]: ['id', 'id', 'id']]
	DOCUMENT_REASON: 'documentReason',
	TRANSACTION_METHOD: 'transactionMethod',

	//invoice
	INVOICE_TOTAL_WITH_TAX_: 'invoiceTotalWithTax',
	INVOICE_TOTAL_TAX_: 'totalTax',

	//order
	ORDER_UN_INVOICED_AMOUNT_IN_PERCENTAGE_: 'orderUNInvoicedPart_',
	ORDER_TOTAL_NO_TAX_: 'orderTotalNoTax_',
	ORDER_TOTAL_ADVANCE_WITH_TAX_: 'orderTotalAdvanceWithTax_',
	ORDER_TOTAL_TAX_: 'orderTotalTax',
	ORDER_INVOICING_METHOD_: 'orderInvoicingMethod_',
	ORDER_PERCENT_FOR_INVOICING_ROUND_: 'orderPercentForInvoicingRound_',
	ORDER_PERCENT_FOR_INVOICING_: 'orderPercentForInvoicing_',
	ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_: 'orderFixedAmountForInvoicingRound_',
	ORDER_FIXED_AMOUNT_FOR_INVOICING_: 'orderFixedAmountForInvoicing_',

	//info
	SHOW_IN_INVOICE: 'showInInvoice',
	INVOICE_NUMBER: 'invoiceNumber',
	DATE_OF_ISSUE: 'dateOfIssue',
	DATE_OF_TAX_EVENT: 'dateOfTaxEvent',
	COMPOSED_BY: 'composedBy',
	SUPPLIER_ID: 'supplierID',
	SUPPLIER_COMPANY_NAME: 'supplierCompanyName',
	SUPPLIER_UIC: 'supplierUic',
	SUPPLIER_VAT_NUMBER: 'supplierVatNumber',
	SUPPLIER_REGISTRATION_ADDRESS: 'supplierRegistrationAddress',
	SUPPLIER_RESPONSIBLE_PERSON: 'supplierResponsiblePerson',
	SUPPLIER_IBAN: 'supplierIban',
	SUPPLIER_BIC: 'supplierBic',
	SUPPLIER_BANK_NAME: 'supplierBankName',
	IBAN: 'iban',
	BIC: 'bic',
	BANK_NAME: 'bankName',
	DUE_DATE: 'dueDate',
	RECIPIENT_ID: 'recipient_id',
	RECIPIENT_COMPANY_NAME: 'recipientCompanyName',
	RECIPIENT_UIC: 'recipientUic',
	RECIPIENT_VAT_NUMBER: 'recipientVatNumber',
	RECIPIENT_RESPONSIBLE_PERSON: 'recipientResponsiblePerson',
	RECIPIENT_REGISTRATION_ADDRESS: 'recipientRegistrationAddress',
	VERBALLY: 'verbally',
	NOTES: 'notes',
	EXCLUDE_TAX: 'excludeTax',
	REASON_FOR_TAX_EXCLUDE: 'reasonForTaxExclude',
	DOCUMENT_TYPE: 'documentType',
	CREDIT_REASON: 'creditReason',

	//localisation
	LOCALIZATION: {
		COMPOSED_BY: {
			BG: 'locComposedByBG',
			DE: 'locComposedByDE',
			EN: 'locComposedByGB',
			TR: 'locComposedByTR'
		},
		SUPPLIER_NAME: {
			BG: 'locSupplierNameBG',
			DE: 'locSupplierNameDE',
			EN: 'locSupplierNameGB',
			TR: 'locSupplierNameTR'
		},
		SUPPLIER_ADDRESS: {
			BG: 'locSupplierAddressBG',
			DE: 'locSupplierAddressDE',
			EN: 'locSupplierAddressGB',
			TR: 'locSupplierAddressTR'
		},
		SUPPLIER_RESPONSIBLE_PERSON: {
			BG: 'locSupplierRespPersonBG',
			DE: 'locSupplierRespPersonDE',
			EN: 'locSupplierRespPersonGB',
			TR: 'locSupplierRespPersonTR'
		},
		RECEIVER_NAME: {
			BG: 'locReceiverNameBG',
			DE: 'locReceiverNameDE',
			EN: 'locReceiverNameGB',
			TR: 'locReceiverNameTR'
		},
		RECEIVER_ADDRESS: {
			BG: 'locReceiverAddressBG',
			DE: 'locReceiverAddressDE',
			EN: 'locReceiverAddressGB',
			TR: 'locReceiverAddressTR'
		},
		RECEIVER_RESPONSIBLE_PERSON: {
			BG: 'locReceiverRespPersonBG',
			DE: 'locReceiverRespPersonDE',
			EN: 'locReceiverRespPersonGB',
			TR: 'locReceiverRespPersonTR'
		},
		ITEM_NAME: {
			BG: 'locItemNameBG',
			DE: 'locItemNameDE',
			EN: 'locItemNameGB',
			TR: 'locItemNameTR'
		},
		VERBALLY: {
			BG: 'locVerballyBG',
			DE: 'locVerballyDE',
			EN: 'locVerballyGB',
			TR: 'locVerballyTR'
		},
		BANK_NAME: {
			BG: 'locBankNameBG',
			DE: 'locBankNameDE',
			EN: 'locBankNameGB',
			TR: 'locBankNameTR'
		},
		DISCOUNT_DESCRIPTION: {
			BG: 'locDiscountDescriptionBG',
			DE: 'locDiscountDescriptionDE',
			EN: 'locDiscountDescriptionEN',
			TR: 'locDiscountDescriptionTR'
		},
		DOCUMENT_REASON: {
			BG: 'locDocumentReasonBG',
			DE: 'locDocumentReasonDE',
			EN: 'locDocumentReasonEN',
			TR: 'locDocumentReasonTR'
		},
		TAX_NOT_INCLUDED_REASON: {
			BG: 'locTaxNotIncludedBG',
			DE: 'locTaxNotIncludedDE',
			EN: 'locTaxNotIncludedEN',
			TR: 'locTaxNotIncludedTR'
		},
		NOTES: {
			BG: 'locNotesBG',
			DE: 'locNotesDE',
			EN: 'locNotesEN',
			TR: 'locNotesTR'
		}
	}
};

export const INVOICING_METHODS = {
	PERCENTAGE: 'PERCENTAGE',
	VALUE: 'VALUE'
};

export const INVOICING_FILTER_INPUT_NAME = {
	LAB_ORDER_ID: 'labOrderId',
	CLIENT_ID: 'clientId',
	CLIENT_NAME: 'clientName',
	STATUSES: 'statuses',
	PATIENT_NAME: 'patientName',
	PAYMENT_DUE_DATE_FROM: 'paymentDueDateFrom',
	PAYMENT_DUE_DATE_TO: 'paymentDueDateTo',
	CREATE_DATE_FROM: 'createDateFrom',
	CREATE_DATE_TO: 'createDateTo',
	DELIVERY_DATE_FROM: 'deliveryDateFrom',
	DELIVERY_DATE_TO: 'deliveryDateTo',
	INVOICE_TYPE: 'invoiceType'
};

export const ORDER_INVOICING_STATUS = {
	FULLY_INVOICED: 'FULLY_INVOICED',
	NOT_INVOICED: 'NOT_INVOICED',
	PARTIALLY_INVOICED: 'PARTIALLY_INVOICED'
};

export const TAX_TYPE = {
	VAT: 'VAT',
	SALES_TAX: 'SALES_TAX'
};

export const INVOICE_TYPES = {
	FREE: 'FREE',
	LINKED_TO_ORDERS: 'LINKED_TO_ORDERS'
};

export const ORGANIZATION_ADMIN_TYPE = {
	CLINIC_ADMIN: 'Clinic admin',
	LAB_ADMIN: 'Lab admin'
};

export const DOCUMENT_EXPORT_TYPE = {
	EXCEL: 'EXCEL',
	CSV: 'CSV',
	PDF: 'PDF'
};

export const DOCUMENT_EXPORT_FILE_EXTENSION = {
	EXCEL: 'xlsx',
	CSV: 'csv',
	PDF: 'pdf'
};

export const TRANSACTION_FORM_FIELD = {
	TRANSACTION_DATE: 'transactionDate',
	TRANSACTION_METHOD: 'transactionMethod',
	TRANSACTION_REFERENCES_NOTES: 'referenceNotes',
	TRANSACTION_AMOUNT: 'amount',
	TRANSACTION_DOCUMENT_TYPE: 'transactionDocumentType',
	TRANSACTION_COMMON_NOTES: 'commonNotes',
	DOCUMENT_AMOUNT_: 'documentAmount_',
	TRANSACTION_CLINIC_ID: 'clinicId',
	DOCUMENTS_IDS: 'documentsIds',
	TRANSACTION_REMINDER: 'reminder',
	TOTAL_PAYED: 'totalPayed',
	DOCUMENT_REMAINING_AMOUNT_: 'remainingAmount_',
	DOCUMENT_PAYED_AMOUNT_: 'documentPayedAmount_'
};

export const CREATE_ROUTE_NAME = 'create';

export const EXPORT_PDF_FIELDS = {
	TYPE: 'type',
	LANGUAGE: 'language'
};

export const BALANCE_FORM_FIELD = {
	PERIOD_DATE_FROM: 'periodDateFrom',
	PERIOD_DATE_TO: 'periodDateTo'
};

export const DEBTOR_DAYS_FIELD = {
	TO_DATE: 'toDate'
};

export const STATEMENTS_CONNECTED_ITEM_TYPES = {
	INVOICE: 'INVOICE',
	FREE_INVOICE: 'FREE_INVOICE',
	ORDER_INVOICE: 'ORDER_INVOICE',
	ORDER: 'ORDER',
	DEBIT_TRANSACTION: 'DEBIT_TRANSACTION',
	CREDIT_NOTE: 'CREDIT_NOTE',
	DEBIT_NOTE: 'DEBIT_NOTE'
};

export const STATEMENT_BALANCE_TYPE = {
	OPENING: 'OPENING',
	CLOSING: 'CLOSING'
};
