import { applyMiddleware, compose, createStore } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';

const store =
	typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function'
		? createStore(
				reducers,
				compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())
		  )
		: createStore(reducers, applyMiddleware(thunk));
//const store = createStore(reducers, applyMiddleware(thunk));

export default store;
