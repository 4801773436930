import React from 'react';
import i18n from '../../../../../../../i18n';

class OrderItemsHeader extends React.Component {
	render() {
		return (
			<>
				<td style={{ fontWeight: 'bold' }} colSpan={1}>
					{i18n.t('translation:inventory.table.catalogNumber')}
				</td>
				<td style={{ fontWeight: 'bold' }} colSpan={3}>
					{i18n.t('translation:billingModule.invoicing.table.common.product')}
				</td>
				<td style={{ ...noBreakWordStyle, fontWeight: 'bold' }} colSpan={1}>
					{i18n.t('translation:billingModule.invoicing.table.common.unit')}
				</td>
				<td style={{ ...noBreakWordStyle, fontWeight: 'bold' }} colSpan={1}>
					{i18n.t('translation:billingModule.invoicing.table.common.quantity')}
				</td>
				<td style={{ fontWeight: 'bold' }} colSpan={2}>
					{i18n.t('translation:billingModule.invoicing.table.common.unitPrice')}
				</td>
				<td style={{ fontWeight: 'bold' }} colSpan={2}>
					{i18n.t('translation:billingModule.invoicing.table.common.amount')}
				</td>
				<td style={{ fontWeight: 'bold' }} colSpan={2}>
					{i18n.t('translation:billingModule.invoicing.table.orderInvoice.advance')}
				</td>
				<td style={{ fontWeight: 'bold' }} colSpan={1}>
					{i18n.t('translation:billingModule.invoicing.table.common.tax')}
				</td>
				<td style={{ fontWeight: 'bold' }} colSpan={2}>
					{i18n.t('translation:billingModule.invoicing.table.common.totalPrice')}
				</td>
				<td colSpan={1}></td>
			</>
		);
	}
}

export default OrderItemsHeader;

const noBreakWordStyle = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
};
