import _ from 'lodash';
import {
	ACCOUNT_TYPE,
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	COMPANY_STATUS,
	GLOBAL_PRODUCT_CATEGORY,
	INVOICE_TYPES,
	MODULE_CODES,
	ORDER_FIELDS,
	ORDER_FILE_SUB_TYPE,
	ORDER_INVOICING_STATUS,
	ORDER_STATUS,
	ORGANIZATION_TYPE,
	PAGES_PATHS,
	SPECIAL_ORDER_FILE_TYPES,
	SUCCESS_NOTIFICATION_POPUP_TIMEOUT_IN_MILLISECONDS,
	TASK_STATUS,
	USER_ROLE,
	USER_STATUS,
	USER_TYPE
} from './constants';
import { NotificationManager } from 'react-notifications';
import i18n from '../i18n';
import navigationUtils from './navigationUtils';
import moment from './moment';
import { formUtils } from './formUtils';
import { loadLanguage } from '../actions';

const languageMap = { bg: 'BG', en: 'GB', de: 'DE', tr: 'TR' };

export const showErrorPopup = (message) =>
	NotificationManager.error(message, null, SUCCESS_NOTIFICATION_POPUP_TIMEOUT_IN_MILLISECONDS);

export const showSuccessPopup = (message) =>
	NotificationManager.success(message, null, SUCCESS_NOTIFICATION_POPUP_TIMEOUT_IN_MILLISECONDS);

export const refreshPage = () => window.location.reload();

export const isEmptyObject = (object) => {
	if (!object) {
		return true;
	}

	return Object.keys(object).length === 0;
};

export const getName = (user) => {
	if (!isEmptyObject(user)) {
		return user.firstName + ' ' + user.lastName;
	}
};

export const getOrdersStatuses = () => {
	const statuses = [];
	for (let status in ORDER_STATUS) {
		statuses.push({ name: parseStatus(status), id: status });
	}
	return statuses;
};

export const getTasksStatuses = () => {
	const statuses = [];
	for (let status in TASK_STATUS) {
		statuses.push({ name: parseTaskStatus(status), id: status });
	}
	return statuses;
};

export const getProfession = (user) => {
	return registeredInClinic(user)
		? i18n.t('translation:users.dentist')
		: i18n.t('translation:users.dentalTechnician');
};

export const registeredInClinic = (user) => {
	return user.clinicName ? true : false;
};

export const isDentistUser = (user) => {
	return registeredInClinic(user) && !isLocalAdmin(user);
};

export const isMyCompany = () =>
	navigationUtils.getRoute() === PAGES_PATHS.MY_PRACTICE_DETAILS ||
	navigationUtils.getRoute() === PAGES_PATHS.MY_LABORATORY_DETAILS;

export const isPassiveClinic = (clinic) => {
	return clinic.isPassive;
};

export const isAdmin = (user) => {
	return user.role === USER_ROLE.GLOBAL_ADMIN || user.role === USER_ROLE.LOCAL_ADMIN;
};

export const isLocalAdmin = (user) => {
	return user.role === USER_ROLE.LOCAL_ADMIN;
};

export const isGlobalAdmin = (user) => {
	return user.role === USER_ROLE.GLOBAL_ADMIN;
};

export const isLabTechnician = (user) => {
	return user.role === USER_ROLE.USER && user.labName !== null;
};

export const isLabAdmin = (user) => {
	return user.role === USER_ROLE.LOCAL_ADMIN && user.labName;
};

export const isClinicAdmin = (user) => {
	return user.role === USER_ROLE.LOCAL_ADMIN && user.clinicName;
};

export const isLocalInventory = (route) => route === PAGES_PATHS.INVENTORY;

export const areInSameCompany = (user1, user2) => {
	return (
		(user1.clinicId && user2.clinicId && user1.clinicId === user2.clinicId) ||
		(user1.labId && user2.labId && user1.labId === user2.labId)
	);
};

export const isOrderDraft = (order) => {
	return order && order.status === ORDER_STATUS.DRAFT;
};

export const isOrderOpened = (order) => {
	return order && order.status === ORDER_STATUS.OPENED;
};

export const isOrderCompleted = (order) => {
	return order && order.status === ORDER_STATUS.COMPLETED;
};

export const parseLanguageToFlag = (language) => {
	const languageCode = language.split('-')[0];
	return languageMap[languageCode] || languageMap[Object.keys(languageMap)[0]];
};

export const isInformationRequired = (order) => {
	return order && order.status === ORDER_STATUS.INFORMATION_REQUIRED;
};

export const getRefinedOrderFields = (formValues, editableFields) => {
	const refinedFields = [];
	for (let field in formValues) {
		if (editableFields.includes(field)) {
			const type = _.upperFirst(field);
			let value = formValues[field];
			if (Object.values(ORDER_FIELDS).includes(type)) {
				if (
					value &&
					(type === ORDER_FIELDS.DUE_DATE ||
						type === ORDER_FIELDS.CREATE_DATE ||
						type === ORDER_FIELDS.DELIVERY_DATE ||
						type === ORDER_FIELDS.PAYMENT_DUE_DATE ||
						type === ORDER_FIELDS.LAST_PAYMENT_DATE)
				) {
					value = moment(value).format('YYYY-MM-DD');
				}
				if (!value && value !== false) {
					value = null;
				}
				if (type === ORDER_FIELDS.PRICE_INCREASE && !formValues.isPriorityOrder) {
					value = 0;
				}
				refinedFields.push({ type, value });
			}
		}
	}
	return refinedFields;
};

export const truncate = (str, length = 20, ending = '...') => {
	if (str && str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	} else {
		return str;
	}
};

export const getOrderInitialValues = (currentOrder) => {
	const fields = currentOrder.fields;
	let initialValues = {};

	function getValue(field, value) {
		if (field === ORDER_FIELDS.CLINIC) {
			return value.name;
		} else if (field === ORDER_FIELDS.DENTIST) {
			return getName(value);
		} else if (field === ORDER_FIELDS.LAB_TECHNICIAN) {
			return value ? value.id : null;
		} else if (field === ORDER_FIELDS.DELIVERY_LOCATION) {
			return value ? value.id : null;
		} else if (field === ORDER_FIELDS.RETURN_LOCATION) {
			return value ? value.id : null;
		} else if (
			field === ORDER_FIELDS.DUE_DATE ||
			field === ORDER_FIELDS.CREATE_DATE ||
			field === ORDER_FIELDS.DELIVERY_DATE ||
			field === ORDER_FIELDS.PAYMENT_DUE_DATE ||
			field === ORDER_FIELDS.LAST_PAYMENT_DATE
		) {
			return value ? moment(value, 'YYYY-MM-DD').toDate() : null;
		} else if (field === ORDER_FIELDS.LAB) {
			return value.name;
		} else if (field === ORDER_FIELDS.PATIENT_NAME_VISIBLE) {
			return value ? value : false;
		} else if (
			field === ORDER_FIELDS.PRICE_INCREASE ||
			field === ORDER_FIELDS.PAID_AMOUNT ||
			field === ORDER_FIELDS.AMOUNT_DUE ||
			field === ORDER_FIELDS.DISCOUNT ||
			(field === ORDER_FIELDS.INITIAL_PRICE) | (field === ORDER_FIELDS.PRICE)
		) {
			return parsePrice(value);
		}
		return value;
	}

	if (!isEmptyObject(fields)) {
		for (let field in ORDER_FIELDS) {
			const currentField = formUtils.getFieldByType(fields, ORDER_FIELDS[field]);
			if (currentField) {
				initialValues[_.camelCase(field)] = getValue(
					ORDER_FIELDS[field],
					currentField.value
				);
			}
		}
	}
	return initialValues;
};

export const isAllNull = (object) => {
	return Object.values(object).every((x) => x === null || x === '' || (x && x.length == 0));
};

export const isEmptyFilters = (filters) =>
	Object.values(filters).every((x) => x === null || x === '' || !x.length);

export const parseFlagToLanguage = (flag) => {
	return _.invert(languageMap)[flag];
};

export const getLanguageCode = () => {
	return parseFlagToLanguage(loadLanguage());
};

export const parseStatus = (status) => {
	const statusMap = {
		[USER_STATUS.ACTIVE]: i18n.t('translation:common.statuses.user.active'),
		[USER_STATUS.DEACTIVATED]: i18n.t('translation:common.statuses.user.deactivated'),
		[USER_STATUS.INACTIVE]: i18n.t('translation:common.statuses.user.inactivated'),
		[USER_STATUS.WAITING_FOR_APPROVAL]: i18n.t('translation:common.statuses.user.awaiting'),
		[ORDER_STATUS.ACCEPTED]: i18n.t('translation:common.statuses.order.accepted'),
		[ORDER_STATUS.CANCELED]: i18n.t('translation:common.statuses.order.canceled'),
		[ORDER_STATUS.CHANGES_NOT_ACCEPTED]: i18n.t(
			'translation:common.statuses.order.changesNotAccepted'
		),
		[ORDER_STATUS.COMPLETED]: i18n.t('translation:common.statuses.order.completed'),
		[ORDER_STATUS.DRAFT]: i18n.t('translation:common.statuses.order.draft'),
		[ORDER_STATUS.IN_PROGRESS]: i18n.t('translation:common.statuses.order.inProgress'),
		[ORDER_STATUS.IN_TESTING]: i18n.t('translation:common.statuses.order.inTesting'),
		[ORDER_STATUS.INFORMATION_REQUIRED]: i18n.t(
			'translation:common.statuses.order.informationRequired'
		),
		[ORDER_STATUS.MOUNTED]: i18n.t('translation:common.statuses.order.mounted'),
		[ORDER_STATUS.MOUNTED_TEMPORARILY]: i18n.t(
			'translation:common.statuses.order.mountedTemporary'
		),
		[ORDER_STATUS.ON_HOLD]: i18n.t('translation:common.statuses.order.onHold'),
		[ORDER_STATUS.OPENED]: i18n.t('translation:common.statuses.order.opened'),
		[ORDER_STATUS.PRODUCED]: i18n.t('translation:common.statuses.order.produced'),
		[ORDER_STATUS.RECEIVED]: i18n.t('translation:common.statuses.order.received'),
		[ORDER_STATUS.REJECTED]: i18n.t('translation:common.statuses.order.rejected'),
		[ORDER_STATUS.SENT]: i18n.t('translation:common.statuses.order.sent'),
		[ORDER_STATUS.WORK_REQUIRED]: i18n.t('translation:common.statuses.order.workRequired'),
		[TASK_STATUS.CANCELED]: i18n.t('translation:common.statuses.order.canceled'),
		[ORDER_INVOICING_STATUS.FULLY_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.fullyInvoiced'
		),
		[ORDER_INVOICING_STATUS.PARTIALLY_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.partiallyInvoiced'
		),
		[ORDER_INVOICING_STATUS.NOT_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.notInvoiced'
		)
	};
	return statusMap[status];
};

export const parseTaskStatus = (status) => {
	const statusMap = {
		[TASK_STATUS.CANCELED]: i18n.t('translation:common.statuses.order.canceled'),
		[TASK_STATUS.COMPLETED]: i18n.t('translation:common.statuses.order.completed'),
		[TASK_STATUS.IN_PROGRESS]: i18n.t('translation:common.statuses.order.inProgress'),
		[TASK_STATUS.ON_HOLD]: i18n.t('translation:common.statuses.order.onHold'),
		[TASK_STATUS.OPENED]: i18n.t('translation:common.statuses.order.opened')
	};
	return statusMap[status];
};

export const getTaskStatusId = (status) => {
	const statusMap = {
		[i18n.t('translation:common.statuses.order.canceled')]: TASK_STATUS.CANCELED,
		[i18n.t('translation:common.statuses.order.completed')]: TASK_STATUS.COMPLETED,
		[i18n.t('translation:common.statuses.order.inProgress')]: TASK_STATUS.IN_PROGRESS,
		[i18n.t('translation:common.statuses.order.onHold')]: TASK_STATUS.ON_HOLD,
		[i18n.t('translation:common.statuses.order.opened')]: TASK_STATUS.OPENED
	};
	return statusMap[status];
};

export const parseAccountType = (type) => {
	const accountTypeMap = {
		[ACCOUNT_TYPE.DENTIST]: i18n.t('translation:common.accountTypes.dentist'),
		[ACCOUNT_TYPE.GLOBAL_ADMIN]: i18n.t('translation:common.accountTypes.globalAdmin'),
		[ACCOUNT_TYPE.LAB_ADMIN]: i18n.t('translation:common.accountTypes.labAdmin'),
		[ACCOUNT_TYPE.LAB_TECHNICIAN]: i18n.t('translation:common.accountTypes.labTechnician')
	};
	return accountTypeMap[type];
};

export const parseColorScheme = (color) => {
	const colorSchemeMap = {
		ivoclarChromascop: 'Ivoclar Chromascop',
		vita3dMaster: 'Vita 3d Master',
		vitaClassic: 'Vita Classic'
	};
	return colorSchemeMap[color];
};

export const parseColorSchemeToConstant = (color) => {
	const colorSchemeMap = {
		ivoclarChromascop: 'IVOCLAR_CHROMASCOP',
		vita3dMaster: 'VITA_3D_MASTER',
		vitaClassic: 'VITA_CLASSIC'
	};
	return colorSchemeMap[color];
};

export const getFirstOrderItemWithColor = (orderItems) => {
	let firstOrderItemWithColor = null;

	for (let item of orderItems) {
		if (item.productHasColor) {
			firstOrderItemWithColor = item;
			break;
		}
	}
	return firstOrderItemWithColor;
};

export const parsePrice = (price) => {
	return parseFloat(price.toFixed(2));
};

export const parseColorSchemeConstantToValue = (color) => {
	const colorSchemeMap = {
		IVOCLAR_CHROMASCOP: 'ivoclarChromascop',
		VITA_3D_MASTER: 'vita3dMaster',
		VITA_CLASSIC: 'vitaClassic'
	};
	return colorSchemeMap[color];
};

export const parseUserType = (type) => {
	const userTypeMap = {
		[USER_TYPE.DENTIST]: i18n.t('translation:users.dentist'),
		[USER_TYPE.DENTAL_TECHNICIAN]: i18n.t('translation:users.dentalTechnician')
	};
	return userTypeMap[type];
};

export const parseOrganizationType = (type) => {
	const companyTypeMap = {
		[ORGANIZATION_TYPE.NEW_CLINIC]: i18n.t('translation:auth.organizationTypes.newClinic'),
		[ORGANIZATION_TYPE.EXISTING_CLINIC]: i18n.t(
			'translation:auth.organizationTypes.existingClinic'
		),
		[ORGANIZATION_TYPE.NEW_LAB]: i18n.t('translation:auth.organizationTypes.newLab'),
		[ORGANIZATION_TYPE.EXISTING_LAB]: i18n.t('translation:auth.organizationTypes.existingLab')
	};
	return companyTypeMap[type];
};

export const parseRole = (role) => {
	const roleMap = {
		[USER_ROLE.GLOBAL_ADMIN]: i18n.t('translation:common.roles.globalAdmin'),
		[USER_ROLE.LOCAL_ADMIN]: i18n.t('translation:common.roles.localAdmin'),
		[USER_ROLE.USER]: i18n.t('translation:common.roles.user')
	};
	return roleMap[role];
};

export const parseGlobalProductCategory = (category) => {
	const roleMap = {
		[GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH]: i18n.t(
			'translation:common.globalProductCategories.consecutiveTeeth'
		),
		[GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE]: i18n.t(
			'translation:common.globalProductCategories.constantPrice'
		),
		[GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE_CONSECUTIVE_TEETH]: i18n.t(
			'translation:common.globalProductCategories.constantPriceConsecutiveTeeth'
		)
	};
	return roleMap[category];
};

export const isPracticeRoute = (path) =>
	path === PAGES_PATHS.MY_PRACTICE_DETAILS ||
	path.includes(PAGES_PATHS.PRACTICES) ||
	path.includes(PAGES_PATHS.CLIENTS);

export const isNew = (path) =>
	path === PAGES_PATHS.LABORATORY_CREATE ||
	path === PAGES_PATHS.PRACTICE_CREATE ||
	path === PAGES_PATHS.ORDERS_CREATE;

export const isWaitingForApproval = (company) => {
	return company.status === COMPANY_STATUS.WAITING_FOR_APPROVAL;
};

export const canEditOrDeleteFiles = (user, order) => {
	let labTechnician = formUtils.getFieldByType(order.fields, ORDER_FIELDS.LAB_TECHNICIAN);
	if (labTechnician != null) {
		labTechnician = labTechnician.value;
	}

	const clinic = getClinicFromOrder(order);
	if (
		(registeredInClinic(user) && user.clinicId === clinic.id) ||
		(labTechnician != null && isLabTechnician(user) && user.id === labTechnician.id)
	) {
		// For some users we are allowing deleting of all photos, if they can add photos in
		// the current order status. The business validation in the back-end is going to
		// return error (shown to the user) if a photo can't be deleted. This arrangement
		// will make possible of removing/changing photos by the dentist that he added
		// himself/herself in the current order status.
		return order.canUploadPredefinedPhotos || order.canUploadAdditionalFiles;
	} else {
		return order.canDeleteFiles;
	}
};

export const localizeFileTypeName = (photoType) => {
	switch (photoType) {
		case ORDER_FILE_SUB_TYPE.LEFT_OCCLUSION:
			return i18n.t('translation:orders.photoDescriptionLeftProfileInOcclusion');
		case ORDER_FILE_SUB_TYPE.RIGHT_OCCLUSION:
			return i18n.t('translation:orders.photoDescriptionRightProfileInOcclusion');
		case ORDER_FILE_SUB_TYPE.FRONT_CENTRAL:
			return i18n.t('translation:orders.photoDescriptionCentralFront');
		case ORDER_FILE_SUB_TYPE.FRONT_CENTRAL_WITH_SLIGHT_SMILE:
			return i18n.t('translation:orders.photoDescriptionCentralFrontWithLightSmile');
		case ORDER_FILE_SUB_TYPE.UPPER_WITH_SMILE:
			return i18n.t('translation:orders.photoDescriptionTopWithSmile');
		case ORDER_FILE_SUB_TYPE.ARTICULATION_VIDEO:
			return i18n.t('translation:orders.articulationVideo');
		default:
			return null;
	}
};

export const localizeFileDeleteWarning = (fileType) => {
	switch (fileType) {
		case SPECIAL_ORDER_FILE_TYPES.PHOTO:
			return i18n.t('translation:orders.deletePhotoWarning');
		case SPECIAL_ORDER_FILE_TYPES.VIDEO:
			return i18n.t('translation:orders.deleteVideoWarning');
		default:
			return i18n.t('translation:orders.deleteFileWarning');
	}
};

export const getArrayOfPropertyValues = (obj) => {
	return Object.keys(obj).map((propertyName) => obj[propertyName]);
};

export const isCompanyActive = (company) => {
	return company.status === COMPANY_STATUS.ACTIVE;
};

export const isCompanyDeactivated = (company) => {
	return company.status === COMPANY_STATUS.DEACTIVATED;
};

export const getLabFromOrder = (order) => {
	return formUtils.getFieldByType(order.fields, ORDER_FIELDS.LAB).value;
};

export const getClinicFromOrder = (order) => {
	return formUtils.getFieldByType(order.fields, ORDER_FIELDS.CLINIC).value;
};

export const getDentistFromOrder = (order) => {
	return formUtils.getFieldByType(order.fields, ORDER_FIELDS.DENTIST).value;
};

export const isFirefoxBrowser = () => {
	return typeof InstallTrigger !== 'undefined';
};

export const getCompanyStatusText = (company) => {
	if (company.status === COMPANY_STATUS.WAITING_FOR_APPROVAL) {
		return i18n.t('translation:common.statuses.user.awaiting');
	} else if (company.status === COMPANY_STATUS.DEACTIVATED) {
		return i18n.t('translation:clinics.deactivated');
	} else {
		return ''; // no need of status explanation in case of active
	}
};

export const getCompanyActivateButtonTitle = (company) => {
	if (company.status === COMPANY_STATUS.WAITING_FOR_APPROVAL) {
		return i18n.t('translation:users.buttons.approve');
	} else if (company.status === COMPANY_STATUS.DEACTIVATED) {
		return i18n.t('translation:common.buttons.activate');
	} else if (company.status === COMPANY_STATUS.ACTIVE) {
		return i18n.t('translation:common.buttons.deactivate');
	} else {
		return 'Unknown Action'; // this should not happen
	}
};

export const addZeroIfOneDigit = (digit) => {
	return ('0' + digit).slice(-2);
};

export const isRegisteredFieldValue = (phases, lastIndex) => {
	return (
		_.findIndex(phases, (phase) => {
			return phase.id == lastIndex;
		}) >= 0
	);
};

export const calculatePhasesTotalTime = (formValues, phasesFromState) => {
	let minutes = 0;
	let hours = 0;
	for (let key in formValues) {
		const lastIndex = key.split('_')[key.split('_').length - 1];
		if (isRegisteredFieldValue(phasesFromState, lastIndex)) {
			if (key.startsWith('minutes')) {
				minutes += +formValues[key];
			}
			if (key.startsWith('hours')) {
				hours += +formValues[key];
			}
		}
	}
	hours += Math.floor(minutes / 60);
	minutes = minutes % 60;
	return `${hours}:${addZeroIfOneDigit(minutes)}`;
};

export const isTaskManagerEnabled = (modules) => {
	const moduleCode = MODULE_CODES.TASK_MANAGER;
	const tm = modules.find((module) => {
		return module.code === moduleCode;
	});
	if (tm) {
		return tm.enabled;
	}
	return false;
};

export const isBillingModuleEnabled = (modules) => {
	const moduleCode = MODULE_CODES.BILLING_MODULE;
	const billingModule = modules.find((module) => {
		return module.code === moduleCode;
	});
	if (billingModule) {
		return billingModule.enabled;
	}
	return false;
};

export const sortDataById = (data) => {
	data.sort(function (a, b) {
		return a.id - b.id;
	});
};

export const getAbsenceByType = (type, absences) => {
	const absence = absences.find((absence) => absence.value === type);
	return absence || [];
};

export const getAbsenceAbbreviation = (type, absences) => {
	const workedDaysAbsence = {
		name: i18n.t('translation:taskManager.planner.daysWorked'),
		abbreviation: i18n.t('translation:taskManager.planner.daysWorkedAbbreviation'),
		backgroundColor: '13253b'
	};
	const absence = absences.find((absence) => absence.value === type);

	return absence.abbreviation !== '' ? absence.abbreviation : absence.name;
};

export const parseColorCodeToBackgroundColor = (colorCode, isTransparent) => {
	if (colorCode) {
		return { backgroundColor: '#' + colorCode };
	} else if (isTransparent) {
		return { backgroundColor: '#ffffff00' };
	}
	return { backgroundColor: '#fff' };
};

export const isNumeric = (value) => {
	return /^-?\d+$/.test(value);
};

export const getAssigneeTaskData = (staff, day) => {
	let assignee;
	staff.map((item) => {
		if (item.timeSlotWorkDurations) {
			item.timeSlotWorkDurations.map((timeSlot) => {
				if (
					timeSlot.date == day.date &&
					timeSlot.duration == day.duration &&
					timeSlot.assigneeId == day.assigneeId
				) {
					assignee = item;
					assignee['timeSlotWorkDurations'] = day;
					return assignee;
				}
			});
		}
	});

	return assignee;
};

export const getLabId = (location, account) => {
	const query = new URLSearchParams(location.search);
	const labId = account.labId ? account.labId : query.get('id');

	return labId;
};

export const parseBillingDocumentFilterType = (type) => {
	const typeMap = {
		[BILLING_DOCUMENT_FILTER_TYPES.INVOICE]: i18n.t(
			'translation:billingModule.documentType.invoice'
		),
		[BILLING_DOCUMENT_FILTER_TYPES.CREDIT_NOTE]: i18n.t(
			'translation:billingModule.documentType.creditNote'
		),
		[BILLING_DOCUMENT_FILTER_TYPES.DEBIT_NOTE]: i18n.t(
			'translation:billingModule.documentType.debitNote'
		)
	};
	return typeMap[type];
};

export const parseOrderInvoicingStatusFilterType = (type) => {
	const typeMap = {
		[ORDER_INVOICING_STATUS.FULLY_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.fullyInvoiced'
		),
		[ORDER_INVOICING_STATUS.PARTIALLY_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.partiallyInvoiced'
		),
		[ORDER_INVOICING_STATUS.NOT_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.notInvoiced'
		)
	};
	return typeMap[type];
};

export const getSelectedTaxType = (taxType) => {
	let selectedTaxType;

	if (taxType?.length > 0) {
		if (taxType === 'VAT') {
			selectedTaxType = i18n.t('translation:billingModule.common.vat');
		} else {
			selectedTaxType = i18n.t('translation:billingModule.common.salesTax');
		}
	}

	return selectedTaxType;
};

export const parseInvoiceTypeFilterType = (type) => {
	const typeMap = {
		[INVOICE_TYPES.FREE]: i18n.t('translation:billingModule.invoiceType.free'),
		[INVOICE_TYPES.LINKED_TO_ORDERS]: i18n.t(
			'translation:billingModule.invoiceType.linkedToOrders'
		)
	};
	return typeMap[type];
};

export const getClinicIdByName = (clinics, name) => {
	const clinic = clinics.filter((clinic) => clinic.legalName === name)[0];
	if (clinic) {
		return clinic.id;
	}
};

export const parseDocumentsStatusFilterType = (type) => {
	const typeMap = {
		OPENED: i18n.t('translation:billingModule.invoicing.statuses.opened'),
		SENT_FOR_APPROVAL: i18n.t('translation:billingModule.invoicing.statuses.sentForApproval'),
		DRAFT: i18n.t('translation:billingModule.invoicing.statuses.draft'),
		REJECTED: i18n.t('translation:billingModule.invoicing.statuses.rejected'),
		PAID_PARTIALLY: i18n.t('translation:billingModule.invoicing.statuses.paidPartially'),
		PAID: i18n.t('translation:billingModule.invoicing.statuses.paid')
	};
	return typeMap[type];
};

export const parseBillingStatuses = (type) => {
	const statusMap = {
		[BILLING_DOCUMENT_STATUS.DRAFT]: i18n.t(
			'translation:billingModule.invoicing.statuses.draft'
		),
		[BILLING_DOCUMENT_STATUS.SENT_FOR_APPROVAL]: i18n.t(
			'translation:billingModule.invoicing.statuses.sentForApproval'
		),
		[BILLING_DOCUMENT_STATUS.OPENED]: i18n.t(
			'translation:billingModule.invoicing.statuses.opened'
		),
		[BILLING_DOCUMENT_STATUS.REJECTED]: i18n.t(
			'translation:billingModule.invoicing.statuses.rejected'
		),
		[BILLING_DOCUMENT_STATUS.PAID_PARTIALLY]: i18n.t(
			'translation:billingModule.invoicing.statuses.paidPartially'
		),
		[BILLING_DOCUMENT_STATUS.PAID]: i18n.t('translation:billingModule.invoicing.statuses.paid'),
		[ORDER_INVOICING_STATUS.FULLY_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.fullyInvoiced'
		),
		[ORDER_INVOICING_STATUS.PARTIALLY_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.partiallyInvoiced'
		),
		[ORDER_INVOICING_STATUS.NOT_INVOICED]: i18n.t(
			'translation:billingModule.orderInvoicingStatus.notInvoiced'
		)
	};
	return statusMap[type];
};

export const parseBillingDocumentType = (type) => {
	const typeMap = {
		[BILLING_DOCUMENT_TYPES.ORDER_INVOICE]: i18n.t(
			'translation:billingModule.documentType.invoice'
		),
		[BILLING_DOCUMENT_TYPES.FREE_INVOICE]: i18n.t(
			'translation:billingModule.documentType.invoice'
		),
		[BILLING_DOCUMENT_TYPES.CREDIT_NOTE]: i18n.t(
			'translation:billingModule.documentType.creditNote'
		),
		[BILLING_DOCUMENT_TYPES.DEBIT_NOTE]: i18n.t(
			'translation:billingModule.documentType.debitNote'
		)
	};
	return typeMap[type];
};
