import React, { Fragment } from 'react';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import {
	getBillingSettings,
	getLabDetails,
	getLinkedClinicsWithoutPagination,
	hideMessageModal,
	showMessageModal,
	getBillingOrders
} from '../../../../actions';
import { BILLING_DOCUMENT_TYPES, PAGINATION_TYPES } from '../../../../utils/constants';
import InvoicingOrdersTable from './InvoicingOrdersTable';
import InvoicingOrdersFilters from './InvoicingOrdersFilters';
import { reset } from 'redux-form';
import IssueDocumentButton from '../common/issueDocumentButton/IssueDocumentButton';
import { isEmptyObject } from '../../../../utils/appUtils';
import store from '../../../../store';
import { SEARCH_BILLING_ORDERS } from '../../../../actions/types';

class Invoicing extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isCreateInvoiceModalOpened: false,
			isCreateCreditOrDebitNoteModalOpened: false,
			isDocumentModalOpened: false,
			invoiceType: BILLING_DOCUMENT_TYPES.FREE_INVOICE,
			clinicId: null,
			filters: {},
			sort: {}
		};
	}

	componentDidMount() {
		if (!this.props.clinics || !this.props.clinics.length) {
			this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);
		}
		//todo for now we need this only for currency localization
		if (!this.props.currentLab || isEmptyObject(this.props.currentLab)) {
			this.props.getLabDetails(this.props.account.labId);
		}

		this.props.getBillingSettings(this.props.account.labId);
	}

	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:billingModule.invoicing.header')}
					buttons={[this.renderIssueDocumentButton()]}
				/>
				<InvoicingOrdersFilters filters={this.state.filters} setFilters={this.setFilters} />
				<InvoicingOrdersTable
					onSelectOrder={this.onSelectOrder}
					filters={this.state.filters}
				/>
			</Fragment>
		);
	}

	onDocumentChange = () => {
		this.props.getBillingOrders(
			this.props.page ?? 1,
			this.state.filters,
			this.props.account.labId,
			false,
			(responseData) => {
				store.dispatch({
					type: SEARCH_BILLING_ORDERS,
					payload: responseData
				});
			}
		);
	};

	renderIssueDocumentButton = () => {
		return (
			<IssueDocumentButton
				previousScreenFilters={this.state.filters}
				clearPreviousScreenFilterValues={this.clearPreviousScreenFilterValues}
				onDocumentChange={this.onDocumentChange}
			/>
		);
	};

	setFilters = (filters) => {
		this.setState({ filters });
	};

	clearPreviousScreenFilterValues = () => {
		this.setState({ filters: {} });
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		billingSettings: state.billingSettings,
		orders: state.orders.orders,
		clinics: state.clinics.clinics,
		page: state.pagination[PAGINATION_TYPES.BILLING_ORDERS]
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	getBillingSettings,
	getLinkedClinicsWithoutPagination,
	showMessageModal,
	hideMessageModal,
	getBillingOrders,
	reset
})(Invoicing);
