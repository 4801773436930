import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { parseSentInvitationsToUsers } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import SubHeader from '../../common/SubHeader';
import { acceptLinkInvitation, clearReducers, getAllSentUserInvitations } from '../../../actions';

class SentInvitationsToUsers extends React.Component {
	componentDidMount() {
		this.props.getAllSentUserInvitations();
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		if (this.props.invitationsSentToUser.length < 1) {
			return null;
		}

		return (
			<Fragment>
				<SubHeader
					title={
						this.props.isClinic
							? i18n.t('translation:dashboard.invitedLabUsers')
							: i18n.t('translation:dashboard.invitedClinicUsers')
					}
				/>
				<div className="col-12 mt-s p-0">{this.renderInvitationsTable()}</div>
			</Fragment>
		);
	}

	renderInvitationsTable = () => {
		const companies = parseSentInvitationsToUsers(this.props.invitationsSentToUser);

		let columns = [
			i18n.t('translation:common.table.email'),
			i18n.t('translation:common.table.firstSentOn'),
			i18n.t('translation:common.table.action')
		];

		return <Table columns={columns} data={companies} />;
	};
}

const mapStateToProps = (state) => {
	return { invitationsSentToUser: state.invitations.sentToUser };
};

export default connect(mapStateToProps, {
	getAllSentUserInvitations,
	acceptLinkInvitation,
	clearReducers
})(SentInvitationsToUsers);
