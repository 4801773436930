import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import { parseClinics } from '../../utils/tableUtils';
import Table from './Table';
import { COMPANY_STATUS, PAGINATION_TYPES } from '../../utils/constants';
import Pagination from './Pagination';
import { isEmptyObject } from '../../utils/appUtils';
import { clearReducers, getClinics, setPagination } from '../../actions';

class ClinicsTable extends React.Component {
	componentDidMount() {
		this.props.getClinics(this.props.page || 1, this.props.status);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.getClinics(this.props.page, this.props.status);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.clinics.content)
			) {
				this.props.setPagination(PAGINATION_TYPES.CLINICS, this.props.page - 1);
			}
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				{this.renderClinics()}
				{this.renderPagination()}
			</Fragment>
		);
	}

	renderClinics = () => {
		const { status } = this.props;

		const clinics = parseClinics(this.props.clinics.content, status);

		const columns = [
			i18n.t('translation:common.table.legalName'),
			i18n.t('translation:common.table.phone'),
			i18n.t('translation:common.table.email'),
			i18n.t('translation:common.table.address')
		];

		if (status === COMPANY_STATUS.WAITING_FOR_APPROVAL) {
			columns.push(i18n.t('translation:common.table.status'));
		}

		return <Table columns={columns} data={clinics} />;
	};

	renderPagination = () => {
		const { clinics } = this.props;
		if (clinics) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={clinics.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.CLINICS, page);
	};
}

const mapStateToProps = (state) => {
	return { clinics: state.clinics.clinics, page: state.pagination[PAGINATION_TYPES.CLINICS] };
};

export default connect(mapStateToProps, { getClinics, setPagination, clearReducers })(ClinicsTable);
