import { getAxiosWithToken } from '../../utils/webApi';
import { GET_GENERAL_WORKING_HOURS, UPDATE_GENERAL_WORKING_HOURS } from '../types';
import { showSuccessPopup, sortDataById } from '../../utils/appUtils';
import i18n from '../../i18n';

export const getGeneralWorkingHours = (id) => async (dispatch) => {
	if (id === null || id === undefined) {
		return;
	}

	const response = await getAxiosWithToken().get(`/lab/${id}/general-work-time`);
	let returnedData = response.data;
	if (returnedData.success) {
		let sortedData = sortDataById(returnedData.data);
		dispatch({
			type: GET_GENERAL_WORKING_HOURS,
			payload: sortedData ? sortedData : returnedData.data
		});
	}
};

export const updateGeneralWorkingHours = (data, id) => async (dispatch) => {
	if (id === null || id === undefined) {
		return;
	}

	const response = await getAxiosWithToken().put(`/lab/${id}/general-work-time`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedSettings'));
		dispatch(getGeneralWorkingHours(id));
	}
};

export const updateWorkingHours = (data) => {
	if (data) {
		return { type: UPDATE_GENERAL_WORKING_HOURS, payload: data };
	}
};
