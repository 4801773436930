import React, { Component } from 'react';
import i18n from '../../../../i18n';
import DropdownButton from '../../../common/DropdownButton';
import { connect } from 'react-redux';
import { exportDocumentToExcelOrCsv } from '../../../../actions';
import {
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	DOCUMENT_EXPORT_TYPE,
	FILTER_FIELDS,
	ORGANIZATION_ADMIN_TYPE
} from '../../../../utils/constants';
import { getFormValues } from 'redux-form';
import moment from '../../../../utils/moment';
import { isEmptyObject } from '../../../../utils/appUtils';

class ExportDocumentButton extends Component {
	onExportButtonClick = (buttonTitle) => {
		let exportDocumentsDto = {
			type: undefined,
			filter: {}
		};

		const { labId, clinicId } = this.props.account;

		let exportDocumentsDtoFilter = exportDocumentsDto.filter;

		if (this.getUserType() === ORGANIZATION_ADMIN_TYPE.LAB_ADMIN) {
			exportDocumentsDtoFilter.labId = Number(labId);
		} else {
			exportDocumentsDtoFilter.clinicId = Number(clinicId);
			exportDocumentsDtoFilter.statuses = [
				BILLING_DOCUMENT_STATUS.OPENED,
				BILLING_DOCUMENT_STATUS.PAID_PARTIALLY,
				BILLING_DOCUMENT_STATUS.PAID
			];
		}

		const formValues = { ...this.props.formValues };

		if (!isEmptyObject(formValues)) {
			for (const formValuesKey in formValues) {
				if (formValuesKey === 'clinicSelect') {
					continue;
				}

				if (formValuesKey === FILTER_FIELDS.TYPES) {
					let types = [];

					if (formValues[formValuesKey].includes(BILLING_DOCUMENT_FILTER_TYPES.INVOICE)) {
						types.push(BILLING_DOCUMENT_TYPES.FREE_INVOICE);
						types.push(BILLING_DOCUMENT_TYPES.ORDER_INVOICE);
					}

					if (
						formValues[formValuesKey].includes(
							BILLING_DOCUMENT_FILTER_TYPES.CREDIT_NOTE
						)
					) {
						types.push(BILLING_DOCUMENT_FILTER_TYPES.CREDIT_NOTE);
					}

					if (
						formValues[formValuesKey].includes(BILLING_DOCUMENT_FILTER_TYPES.DEBIT_NOTE)
					) {
						types.push(BILLING_DOCUMENT_FILTER_TYPES.DEBIT_NOTE);
					}

					// when previous filter has types than all types removed returns empty types array
					// to prevent filtering issue check types arrays length
					if (types.length > 0) {
						exportDocumentsDtoFilter[formValuesKey] = types;
					}
				}

				if (formValuesKey === FILTER_FIELDS.LAB_ORDER_ID) {
					exportDocumentsDtoFilter[formValuesKey] = Number(formValues[formValuesKey]);
				}

				if (formValues[formValuesKey] instanceof Date) {
					formValues[formValuesKey] = moment(formValues[formValuesKey]).format(
						'YYYY-MM-DD'
					);
					exportDocumentsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === FILTER_FIELDS.NUMBER) {
					exportDocumentsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === FILTER_FIELDS.LAB_LEGAL_NAME) {
					exportDocumentsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === FILTER_FIELDS.CLINIC_LEGAL_NAME) {
					exportDocumentsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}
			}
		}

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.exportToExcel')) {
			exportDocumentsDto.type = DOCUMENT_EXPORT_TYPE.EXCEL;
		} else {
			exportDocumentsDto.type = DOCUMENT_EXPORT_TYPE.CSV;
		}

		return this.props.exportDocumentToExcelOrCsv(exportDocumentsDto);
	};

	getUserType = () => {
		const { labId } = this.props.account;

		if (labId) {
			return ORGANIZATION_ADMIN_TYPE.LAB_ADMIN;
		} else {
			return ORGANIZATION_ADMIN_TYPE.CLINIC_ADMIN;
		}
	};

	render() {
		const buttons = [
			i18n.t('translation:billingModule.common.buttons.exportToExcel'),
			i18n.t('translation:billingModule.common.buttons.exportToCsv')
		];

		const title = i18n.t('translation:billingModule.common.buttons.export');

		return (
			<DropdownButton
				title={title}
				buttons={buttons}
				short={true}
				statusButton={false}
				isSecondButton={true}
				onButtonClick={this.onExportButtonClick}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('billingDocumentFiltersForm')(state)
	};
};

export default connect(mapStateToProps, { exportDocumentToExcelOrCsv })(ExportDocumentButton);
