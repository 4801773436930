import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { PAGINATION_TYPES } from '../../../utils/constants';
import { parseLinkedCompanies } from '../../../utils/tableUtils';
import DefaultButton from '../../common/DefaultButton';
import i18n from '../../../i18n';
import AddRelationsModal from './../../common/AddRelationsModal';
import SubHeader from '../../common/SubHeader';
import Table from '../../common/Table';
import Pagination from '../../common/Pagination';
import { isEmptyObject, isGlobalAdmin, isNew } from '../../../utils/appUtils';
import navigationUtils from '../../../utils/navigationUtils';
import { getLinkedLabs, setPagination } from '../../../actions';

class LinkedLabs extends React.Component {
	state = { isModalOpen: false };

	componentDidMount() {
		if (!isNew(navigationUtils.getRoute())) {
			const clinicId = navigationUtils.getRoute().split('/').pop();
			this.props.getLinkedLabs(clinicId, this.props.page || 1);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			const clinicId = navigationUtils.getRoute().split('/').pop();
			this.props.getLinkedLabs(clinicId, this.props.page);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.labs.content)
			) {
				this.props.setPagination(PAGINATION_TYPES.LINKED_LABS, this.props.page - 1);
			}
		}
	}

	render() {
		return (
			<Fragment>
				{this.renderLabs()}
				{this.renderPagination()}
			</Fragment>
		);
	}

	renderLabs = () => {
		const labs =
			!isEmptyObject(this.props.labs.content) && this.props.labs.content[0].id
				? parseLinkedCompanies(this.props.labs.content)
				: [];
		const addLabButton = isNew(navigationUtils.getRoute()) ? (
			<Fragment />
		) : (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				key={i18n.t('translation:common.buttons.add')}
				onClick={() => {
					this.triggerModal();
				}}
			/>
		);

		return (
			<Fragment>
				<AddRelationsModal
					isModalOpen={this.state.isModalOpen}
					closeModal={this.triggerModal}
				/>
				<SubHeader
					title={i18n.t('translation:common.laboratories')}
					buttons={[addLabButton]}
				/>
				<div className="col-12 mt-s p-0">
					<Table
						columns={[
							i18n.t('translation:common.table.legalName'),
							i18n.t('translation:common.table.address'),
							i18n.t('translation:common.table.phone'),
							i18n.t('translation:common.table.email'),
							i18n.t('translation:common.table.action')
						]}
						data={labs}
						noItemsMessage={this.getNoItemsMessage()}
					/>
				</div>
			</Fragment>
		);
	};

	getNoItemsMessage = () => {
		if (isGlobalAdmin(this.props.account)) {
			return i18n.t('translation:clinics.canBeAddedAfterClinicIsCreated');
		}
	};

	triggerModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen });
	};

	renderPagination = () => {
		const { labs } = this.props;
		if (labs) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={labs.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.LINKED_LABS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		labs: isNew(navigationUtils.getRoute()) ? [] : state.labs.labs,
		settings: state.settings,
		page: state.pagination[PAGINATION_TYPES.LINKED_LABS]
	};
};

export default connect(mapStateToProps, { getLinkedLabs, setPagination })(LinkedLabs);
