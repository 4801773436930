import React, { Fragment } from 'react';
import Chart from '../../../../common/Chart';
import i18n from '../../../../../i18n';
import NestedTable from '../../../../common/NestedTable';
import moment from '../../../../../utils/moment';
import { DEFAULT_BACKEND_DATE_FORMAT } from '../../../../../utils/constants';
import { connect } from 'react-redux';
import EditModal from './EditModal';
import { stopLoader } from '../../../../../actions';
import QuickViewModal from './QuickViewModal';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

class Ganttchart extends React.Component {
	state = { date: moment() };

	render() {
		return <Fragment>{this.renderGanttChart()}</Fragment>;
	}

	renderGanttChart = () => {
		const data = this.props.chartData;
		const weekends = this.props.workingHours
			.filter((day) => day.startTime === null)
			.map((day) => day.dayOfTheWeek);
		const quickViewModalTitle = i18n.t('translation:orders.orderNumber', {
			word1: this.props.order.labOrderId
		});
		return (
			<Fragment>
				<EditModal
					isModalOpen={this.props.isEditModalOpen}
					closeModal={this.props.closeEditModal}
					initialValues={this.props.currentTask}
					currentOrderItem={this.props.currentOrderItem}
					order={this.props.order}
					date={this.state.date}
					filters={this.props.filters}
					orderDetails={this.props.orderDetails}
				/>
				<QuickViewModal
					isModalOpen={this.props.isQuickViewModalOpen}
					closeModal={this.props.closeQuickViewModal}
					title={quickViewModalTitle}
					initialValues={this.props.order}
				/>

				<ScrollSync horizontal={false}>
					<Fragment>
						<div className="gantchart-nested-table col-10 col-md-10 col-lg-9 col-xl-7 pr-0 pl-0 mb-m">
							{this.renderTable()}
						</div>
						<div className="col-2 col-md-2 col-lg-3 col-xl-5 pl-0 pr-0">
							<Chart
								date={this.state.date}
								filters={this.props.filters}
								addMonth={this.addMonth}
								subtractMonth={this.subtractMonth}
								data={data}
								dropdown={this.props.dropdown}
								orders={this.props.orders}
								weekends={weekends}
								orderDetails={this.props.orderDetails}
								language={this.props.language}
							/>
						</div>
					</Fragment>
				</ScrollSync>
			</Fragment>
		);
	};

	renderTable = () => {
		const data = this.props.nestedTableData;

		return (
			<ScrollSyncPane group="vertical">
				<div className="table-fix-header">
					<NestedTable
						columns={this.props.columns}
						data={data}
						setDropdown={this.props.setDropdown}
						dropdown={this.props.dropdown}
						calendarTable
						className={this.props.orderDetails ? 'table-responsive' : ''}
						onGlobalDropdownClick={this.props.onGlobalDropdownClick}
					/>
				</div>
			</ScrollSyncPane>
		);
	};

	addMonth = () => {
		const date = this.state.date.add(1, 'M');
		const startDate = moment(date).startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		this.props.getCalendarOrders(this.props.account.labId, { startDate, endDate });
		if (typeof this.props.onAddMonth === 'function') {
			this.props.onAddMonth();
		}
		this.setState({ date });
	};

	subtractMonth = () => {
		const date = this.state.date.subtract(1, 'M');
		const startDate = moment(date).startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		this.props.getCalendarOrders(this.props.account.labId, { startDate, endDate });
		if (typeof this.props.onSubtractMonth === 'function') {
			this.props.onSubtractMonth();
		}
		this.setState({ date });
	};
}

export default connect(null, { stopLoader })(Ganttchart);
