import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { sendForgottenPasswordEmail, toggleLogin } from '../../../actions/loginActions';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../utils/formUtils';
import { PAGES_PATHS } from '../../../utils/constants';

class ForgottenPassword extends Component {
	render() {
		return (
			<Fragment>
				<form
					className="authentication-form"
					onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<h2>{i18n.t('translation:auth.forgotPassword')}</h2>
					<hr className="full-width-element" />
					{Fields.commonFields.email()}
					<DefaultButton
						onPress={this.onFormSubmit}
						title={i18n.t('translation:common.buttons.send')}
					/>
					<Link to={PAGES_PATHS.HOME} className="text-button mt-xs">
						{i18n.t('translation:auth.backToLogin')}
					</Link>
				</form>
			</Fragment>
		);
	}

	onFormSubmit = (formValues) => {
		this.props.sendForgottenPasswordEmail(formValues.email);
	};
}

const mapStateToProps = (state) => {
	return {
		sendEmailAttempt: state.login.sendEmailAttempt,
		emailSentSuccessfully: state.login.emailSentSuccessfully,
		settings: state.settings
	};
};

const forgotPasswordForm = reduxForm({
	form: 'forgotPasswordForm',
	validate: formUtils.validateForgottenPassword
})(ForgottenPassword);

export default connect(mapStateToProps, { sendForgottenPasswordEmail, toggleLogin })(
	forgotPasswordForm
);
