import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

class SideMenuDropdownButton extends Component {
	state = {
		open: false
	};

	render() {
		return (
			<button
				type="button"
				className="d-flex justify-content-center align-items-center w-100 text-left"
				onClick={() => {
					this.onClick();
					this.props.onClick();
				}}>
				<FontAwesomeIcon
					className="side-bar-icon"
					icon={this.props.icon}
					style={{ marginRight: '5px' }}
				/>
				{this.props.name}
				<FontAwesomeIcon
					className="side-menu-dropdown-icon"
					icon={this.getIcon(this.state.open)}
				/>
			</button>
		);
	}

	onClick = () => {
		this.setState({ open: !this.state.open });
	};

	getIcon = (isOpen) => {
		return isOpen ? faCaretUp : faCaretDown;
	};
}

export default SideMenuDropdownButton;
