import React, { Fragment } from 'react';
import i18n from '../../../i18n';
import { connect } from 'react-redux';
import navigationUtils from '../../../utils/navigationUtils';
import {
	isGlobalAdmin,
	isLabAdmin,
	isLocalAdmin,
	isMyCompany,
	isPracticeRoute
} from '../../../utils/appUtils';
import { parseLocations } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import AddLocationModal from '../../common/AddLocationModal';
import SubHeader from '../../common/SubHeader';
import DefaultButton from '../../common/DefaultButton';
import { PAGES_PATHS } from '../../../utils/constants';

class CompanyLocations extends React.Component {
	state = { isModalOpen: false, currentLocation: null };

	render() {
		const canEditLocations =
			this.props.canEditLocations || (isMyCompany() && isLocalAdmin(this.props.account));
		const isPassiveClinic =
			isGlobalAdmin(this.props.account) ||
			(isLabAdmin(this.props.account) && this.props.isClinic);

		const locations = parseLocations(
			this.props.locations,
			canEditLocations,
			isPassiveClinic,
			this.showModalWithInitialization
		);

		const tableClassName = this.props.dataSection ? 'col-12 mt-xxs p-0' : 'col-12 mt-s p-0';
		const columns = [
			i18n.t('translation:common.table.name'),
			i18n.t('translation:common.table.address'),
			i18n.t('translation:common.table.phone'),
			i18n.t('translation:common.table.email')
		];

		if (canEditLocations) {
			columns.push(i18n.t('translation:common.table.action'));
		}

		return (
			<Fragment>
				<AddLocationModal
					isModalOpen={this.state.isModalOpen}
					closeModal={this.triggerModal}
					initialValues={this.state.currentLocation}
					isClinic={this.props.isClinic}
				/>

				{this.renderSubHeader(canEditLocations)}

				<div className={tableClassName}>
					<Table
						columns={columns}
						data={locations}
						noItemsMessage={this.getNoItemsMessage()}
					/>
				</div>
			</Fragment>
		);
	}

	showModalWithInitialization = (currentLocation) => {
		this.setState({ isModalOpen: true, currentLocation });
	};

	renderSubHeader = (canEditLocations) => {
		let addLocationButton = canEditLocations ? (
			<DefaultButton
				title={i18n.t('translation:common.buttons.addLocation')}
				key={i18n.t('translation:common.buttons.addLocation')}
				onClick={() => this.triggerModal()}
			/>
		) : (
			<DefaultButton
				title={i18n.t('translation:common.buttons.addLocation')}
				key={i18n.t('translation:common.buttons.addLocation')}
				disabled
			/>
		);

		addLocationButton =
			navigationUtils.getRoute() === PAGES_PATHS.HOME ||
			navigationUtils.getRoute() === PAGES_PATHS.MY_LABORATORY ? (
				<Fragment />
			) : (
				addLocationButton
			);

		return (
			<SubHeader
				title={i18n.t('translation:common.locations')}
				buttons={[addLocationButton]}
			/>
		);
	};

	triggerModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen, currentLocation: null });
	};

	getNoItemsMessage = () => {
		if (isGlobalAdmin(this.props.account)) {
			if (isPracticeRoute(navigationUtils.getRoute())) {
				return i18n.t('translation:clinics.willBeAddedFromClinic');
			} else {
				return i18n.t('translation:labs.willBeAddedFromLab');
			}
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		settings: state.settings
	};
};

export default connect(mapStateToProps)(CompanyLocations);
