import React from 'react';
import { Fields } from '../../../common/Fields';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../../utils/formUtils';
import { connect } from 'react-redux';
import DefaultButton from '../../../common/DefaultButton';
import i18n from '../../../../i18n';

class SelectTemplateForm extends React.Component {
	render() {
		const { initialValues, templates, isExcludedFromPlanning } = this.props;

		const readOnlyClass = this.props.readOnly ? ' read-only-field' : '';
		const buttonTitle = isExcludedFromPlanning
			? i18n.t('translation:taskManager.common.buttons.includeInPlanning')
			: i18n.t('translation:taskManager.common.buttons.excludeFromPlanning');

		const excludeFromPlanning = (
			<DefaultButton
				title={buttonTitle}
				key={i18n.t('translation:taskManager.common.buttons.excludeFromPlanning')}
				onClick={this.props.excludeFromPlanning}
			/>
		);

		return (
			<div className="task-container">
				<div className="task-field">
					{Fields.templateFields.productName({
						id: initialValues.id,
						value: initialValues.productName,
						readOnly: true,
						className: 'read-only-item'
					})}
				</div>
				<div className="task-field">
					{Fields.commonFields.tasksTemplates({
						options: formUtils.options.getTasksTemplates(templates),
						disabled: isExcludedFromPlanning,
						className: readOnlyClass,
						onChange: (props, value) => {
							this.props.onTemplateChange(value);
						}
					})}
				</div>
				<div className={'task-field button-container task-buttons ' + readOnlyClass}>
					{excludeFromPlanning}
				</div>
			</div>
		);
	}
}

const selectTemplateForm = reduxForm({
	form: 'selectTemplateForm',
	validate: formUtils.validateLocalInventoryAddPhase
})(SelectTemplateForm);

const mapStateToProps = (state) => {
	return {
		templates: state.tasks.templates
	};
};

export default connect(mapStateToProps, {})(selectTemplateForm);
