import { getAxiosWithToken } from '../utils/webApi';
import {
	CLEAR_GLOBAL_PRODUCT,
	GET_GLOBAL_INVENTORY,
	GET_GLOBAL_PRODUCT,
	GET_INDIVIDUAL_INVENTORY,
	GET_LAB_INVENTORY,
	GET_LAB_TAX_GROUPS
} from './types';
import i18n from '../i18n';
import { showSuccessPopup } from '../utils/appUtils';

export const getGlobalProductById = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/product/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_GLOBAL_PRODUCT,
			payload: returnedData.data
		});
	}
};

export const clearCurrentGlobalProduct = () => {
	return { type: CLEAR_GLOBAL_PRODUCT };
};

export const getGlobalProducts = (page) => async (dispatch) => {
	page = page - 1;
	const response = await getAxiosWithToken().get(`/product/all?page=${page}`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_GLOBAL_INVENTORY,
			payload: returnedData.data
		});
	}
};

export const addGlobalProduct = (data, currentPage, closeModal) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().post('/product', data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfullyAddedProduct'));
		dispatch(getGlobalProducts(currentPage));
	}
};

export const updateGlobalProduct = (data, id, currentPage, closeModal) => async (dispatch) => {
	closeModal();

	const response = await getAxiosWithToken().put(`/product/${id}`, data);

	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfullyUpdatedProduct'));
		dispatch(getGlobalProducts(currentPage));
	}
};

export const deleteGlobalProduct = (id, currentPage) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/product/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfullyDeletedProduct'));
		dispatch(getGlobalProducts(currentPage));
	}
};

export const getLabInventoryWithAllProducts = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/product/all-with-lab-products/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_LAB_INVENTORY,
			payload: returnedData.data
		});
	}
};

export const getLabInventoryOnly =
	(id, includeDiscount = false, clinicId) =>
	async (dispatch) => {
		const response = await getAxiosWithToken().get(`/product/lab-products-only/${id}`, {
			params: {
				'include-discount': includeDiscount,
				'clinic-id': clinicId
			}
		});
		let returnedData = response.data;
		if (returnedData.success) {
			dispatch({
				type: GET_LAB_INVENTORY,
				payload: returnedData.data
			});
		}
	};

export const updateMyLabInventory = (id, data) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/product/update-lab-products/${id}`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch(getLabInventoryWithAllProducts(id));
		showSuccessPopup(i18n.t('translation:inventory.successfulUpdatedInventory'));
	}
};

export const getIndividualInventory = (labId, clientId) => async (dispatch) => {
	const response = await getAxiosWithToken().get(
		`/product/clinic-lab-products/${labId}/${clientId}`
	);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_INDIVIDUAL_INVENTORY,
			payload: returnedData.data
		});
	}
	return returnedData.data;
};

export const createIndividualInventory = (labId, clinicId) => async (dispatch) => {
	const response = await getAxiosWithToken().post(
		`/product/clinic-lab-products/${labId}/${clinicId}`
	);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_INDIVIDUAL_INVENTORY,
			payload: returnedData.data
		});
	}
	return response.data;
};

export const updateIndividualInventory = (labId, clinicId, data) => async (dispatch) => {
	const response = await getAxiosWithToken().put(
		`/product/clinic-lab-products/${labId}/${clinicId}`,
		data
	);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:inventory.successfulUpdatedInventory'));
	}
};
