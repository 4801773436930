import React, { Fragment } from 'react';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
	customDateInput,
	dateInput,
	leftLabelledSelectInput,
	textInput
} from '../../../../common/Fields';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import { isEmptyObject } from '../../../../../utils/appUtils';
import {
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_TYPES,
	FILTER_FIELDS,
	PAGINATION_TYPES
} from '../../../../../utils/constants';
import { clearPagination, setPagination } from '../../../../../actions';
import SubHeader from '../../../../common/SubHeader';
import { formUtils } from '../../../../../utils/formUtils';

class CreateCreditOrDebitNoteModalOrdersFilters extends React.Component {
	componentDidMount() {
		this.setInitialValues();
	}

	render() {
		return (
			<div className="col-12 p-0 data-section">
				{this.renderClientNameInputAndInvoiceType()}
				{this.renderFilters()}
			</div>
		);
	}

	renderClientNameInputAndInvoiceType = () => {
		return (
			<div className="d-flex section">
				<div className="d-flex col-4">{this.renderClientNameInput()}</div>
				<div className="d-flex align-items-center">{this.renderDocumentType()}</div>
			</div>
		);
	};

	renderFilters = () => {
		if (this.props.formValues?.[FILTER_FIELDS.CLINIC_LEGAL_NAME]) {
			return (
				<Fragment>
					<SubHeader title={i18n.t('translation:billingModule.common.addInvoices')} />
					{this.renderFilterInputs()}
				</Fragment>
			);
		}

		return (
			<p className="text-center mt-4">
				{i18n.t('translation:billingModule.common.selectClient')}
			</p>
		);
	};

	renderFilterInputs = () => {
		return (
			<div className="content section filters pt-0">
				<form onSubmit={this.onFilterButtonClick}>
					<div className="d-flex flex-row pt-4">
						<div className="d-flex flex-row col-9 p-0">
							<div className="col-3">{this.renderDocumentNumberInput()}</div>
							<div className="d-flex justify-content-center align-items-center col pr-0">
								{this.renderCreateDateRangeInputs()}
							</div>
							<div className="col-3 pl-0">{this.renderOrderNumberInput()}</div>
						</div>
						<div className="d-flex flex-row justify-content-center align-items-end col offset-xl-1 p-0">
							{this.renderFilterButton()}
						</div>
					</div>
				</form>
			</div>
		);
	};

	renderClientNameInput() {
		let options = [{ name: '', value: '' }];

		this.props.clinics?.forEach((clinic) =>
			options.push({ name: clinic.legalName, value: clinic.id })
		);

		return (
			<Field
				name={'clientSelect'}
				label={i18n.t('translation:orders.fields.filters.client')}
				customProps={{
					className: 'd-flex align-items-center w-100 mb-0',
					options: options,
					readableInput: this.props.isMainFiltersReadOnly
				}}
				onChange={(event) => this.onClientChange(event.target)}
				component={leftLabelledSelectInput}
			/>
		);
	}

	renderDocumentType() {
		const documentType =
			this.props.invoiceType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE
				? i18n.t('translation:billingModule.documentType.creditNote')
				: i18n.t('translation:billingModule.documentType.debitNote');

		return (
			<Fragment>
				<label className="left-label">
					{i18n.t('translation:billingModule.common.documentType')}
				</label>
				<span>{documentType}</span>
			</Fragment>
		);
	}

	renderDocumentNumberInput() {
		return (
			<Field
				name={FILTER_FIELDS.NUMBER}
				label={i18n.t('translation:billingModule.common.number')}
				customProps={{ className: 'mr-0' }}
				normalize={formUtils.normalize.number}
				component={textInput}
			/>
		);
	}

	renderCreateDateRangeInputs() {
		return (
			<div className="d-flex flex-column">
				<label>{i18n.t('translation:billingModule.common.createToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={FILTER_FIELDS.ISSUE_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={FILTER_FIELDS.ISSUE_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						allowOldDate={true}
						component={dateInput}
					/>
				)}
			</div>
		);
	}

	renderOrderNumberInput() {
		return (
			<Field
				name={FILTER_FIELDS.LAB_ORDER_ID}
				label={i18n.t('translation:billingModule.common.orderNumber')}
				customProps={{ className: 'mr-0', readableInput: this.props.isMainFiltersReadOnly }}
				component={textInput}
				normalize={formUtils.normalize.number}
			/>
		);
	}

	renderFilterButton = () => {
		return (
			<div className="filter-button">
				<DefaultButton title={i18n.t('translation:billingModule.common.filter')} />
			</div>
		);
	};

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};

	onClientChange = (target) => {
		const { options, value } = target;
		const clientNameLegalName = options[options.selectedIndex].text;

		const filters = { ...this.props.filters };
		filters[FILTER_FIELDS.CLINIC_LEGAL_NAME] = clientNameLegalName;
		filters[FILTER_FIELDS.TYPES] = [BILLING_DOCUMENT_FILTER_TYPES.INVOICE];

		this.props.setFilters(filters);
		this.props.onSelectClient(value);
		this.props.change(FILTER_FIELDS.CLINIC_LEGAL_NAME, clientNameLegalName);
	};

	onFilterButtonClick = (e) => {
		e.preventDefault();
		this.props.clearPagination(PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_DOCUMENTS);

		this.props.handleSubmit((formValues) => {
			const filters = { ...formValues };
			filters.types = [BILLING_DOCUMENT_FILTER_TYPES.INVOICE];

			this.props.setFilters(filters);
			this.props.setPagination(PAGINATION_TYPES.BILLING_DOCUMENTS, 1);
		})();
	};

	setInitialValues = () => {
		const filters = this.props.filters;

		if (!isEmptyObject(filters)) {
			let updatedFilters = {};

			for (const filterName in filters) {
				if (filterName === FILTER_FIELDS.CLINIC_LEGAL_NAME) {
					this.props.change(filterName, filters[filterName]);
					updatedFilters[filterName] = filters[filterName];

					const clinic = this.props.clinics.find(
						(clinic) => clinic.legalName === filters[filterName]
					);

					if (clinic) {
						this.props.change('clientSelect', clinic.id);
						this.props.onSelectClient(clinic.id);
					}
				}

				if (
					filterName === FILTER_FIELDS.NUMBER ||
					filterName === FILTER_FIELDS.ISSUE_DATE_FROM ||
					filterName === FILTER_FIELDS.ISSUE_DATE_TO ||
					filterName === FILTER_FIELDS.LAB_ORDER_ID
				) {
					this.props.change(filterName, filters[filterName]);
					updatedFilters[filterName] = filters[filterName];
				}
			}

			updatedFilters.types = [BILLING_DOCUMENT_FILTER_TYPES.INVOICE];

			this.props.setFilters(updatedFilters);
		}
	};
}

const createCreditOrDebitNoteSetDetailsFiltersForm = reduxForm({
	form: 'createCreditOrDebitNoteSetDetailsFiltersForm',
	forceUnregisterOnUnmount: true,
	destroyOnUnmount: true,
	validate: validateFilters
})(CreateCreditOrDebitNoteModalOrdersFilters);

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		account: state.account,
		currentLab: state.labs.currentLab,
		clinics: state.clinics.clinics,
		formValues: getFormValues('createCreditOrDebitNoteSetDetailsFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	clearPagination,
	change
})(createCreditOrDebitNoteSetDetailsFiltersForm);

function validateFilters(formValues) {
	let errors = {};

	if (
		formValues[FILTER_FIELDS.ISSUE_DATE_FROM] &&
		formValues[FILTER_FIELDS.ISSUE_DATE_TO] &&
		formValues[FILTER_FIELDS.ISSUE_DATE_FROM].getTime() >
			formValues[FILTER_FIELDS.ISSUE_DATE_TO].getTime()
	) {
		errors[FILTER_FIELDS.ISSUE_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	return errors;
}
