import { getAxiosWithToken } from '../utils/webApi';
import { COMPANY_STATUS, LOADER_TYPES, PAGES_PATHS } from '../utils/constants';
import {
	ADD_LAB_LOCATION,
	DELETE_LAB_LOCATION,
	GET_CLINICS,
	GET_CLINICS_WITH_PAGINATION,
	GET_LAB_DETAILS,
	GET_LABS_WITH_PAGINATION,
	GET_USERS,
	UPDATE_LAB,
	UPDATE_LAB_LOCATION
} from './types';
import navigationUtils from '../utils/navigationUtils';
import navigationMethods from '../utils/navigationUtils';
import i18n from '../i18n';
import { isGlobalAdmin, showSuccessPopup } from '../utils/appUtils';
import { getFormValues } from 'redux-form';
import { startLoader } from './loaderActions';

export const getLabs = (page, status) => async (dispatch) => {
	page = page - 1;
	const url =
		status === COMPANY_STATUS.WAITING_FOR_APPROVAL
			? '/lab/get-all-waiting-for-approval'
			: '/lab/get-all';
	const response = await getAxiosWithToken().get(url, {
		params: { page }
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_LABS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const getLabDetails = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/lab/${id}`);
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_LAB_DETAILS,
			payload: returnedData.data
		});
	}
};

export const createLab = (data) => async (dispatch, getState) => {
	getAxiosWithToken()
		.post('/lab', data)
		.then((response) => {
			let returnedData = response.data;
			if (returnedData.success) {
				getAxiosWithToken()
					.put(
						`/lab/${returnedData.data.id}/change-max-active-users`,
						{},
						{
							params: {
								'max-number': getFormValues('activeUsersForm')(getState())[
									'maxNumber'
								]
							}
						}
					)
					.then((response) => {
						showSuccessPopup(i18n.t('translation:labs.successfulCreatedLab'));
						navigationUtils.navigate(PAGES_PATHS.LABORATORIES);
					});
			}
		});
};

export const updateLab = (data, id) => async (dispatch, getState) => {
	let message = i18n.t('translation:labs.successfulUpdatedLab');
	if (isGlobalAdmin(getState().account)) {
		getAxiosWithToken()
			.put(`/lab/${id}`, data)
			.then((resp) => {
				let returnedData = resp.data;
				if (returnedData.success) {
					dispatch({
						type: UPDATE_LAB,
						payload: returnedData.data
					});
				}
				getAxiosWithToken()
					.put(
						`/lab/${id}/change-max-active-users`,
						{},
						{
							params: {
								'max-number': getFormValues('activeUsersForm')(getState())[
									'maxNumber'
								]
							}
						}
					)
					.then((resp) => {
						const userMessage = resp.data.userMessage;
						if (userMessage) {
							dispatch(getLabDetails(id));
							dispatch(getLabStaff(id));
							message += '. ' + userMessage;
						}
						showSuccessPopup(message);
					});
			});
	} else {
		getAxiosWithToken()
			.put(`/lab/${id}`, data)
			.then((resp) => {
				let returnedData = resp.data;
				if (returnedData.success) {
					dispatch({
						type: UPDATE_LAB,
						payload: returnedData.data
					});
					showSuccessPopup(message);
				}
			});
	}
};

export const addLabLocation = (data, id, closeModal) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().post(`/lab/${id}/location`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulAddedLocation'));
		dispatch({
			type: ADD_LAB_LOCATION,
			payload: returnedData.data
		});
	}
};

export const updateLabLocation = (data, labId, locationId, closeModal) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().put(`/lab/${labId}/location/${locationId}`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulUpdatedLocation'));
		dispatch({
			type: UPDATE_LAB_LOCATION,
			payload: returnedData.data
		});
	}
};

export const deleteLabLocation = (labId, locationId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/lab/${labId}/location/${locationId}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulDeletedLocation'));
		dispatch({
			type: DELETE_LAB_LOCATION,
			payload: locationId
		});
	}
};

export const getLabsLegalNames = async () => {
	const response = await getAxiosWithToken().get('/lab/get-all-legal-names');
	let returnedData = response.data;
	if (returnedData.success) {
		return returnedData.data;
	}
	return [];
};

export const getLinkedClinics = (labId, page) => async (dispatch) => {
	page = page - 1;
	const response = await getAxiosWithToken().get(`/link/get-linked-clinics/${labId}`, {
		params: { page: page }
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CLINICS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const updateLinkedClients = async (labId, data, callback) => {
	const response = await getAxiosWithToken().put('/link/update-links', data, {
		params: {
			'lab-id': labId
		}
	});
	let returnedData = response.data;
	if (returnedData.success) {
		callback();
		showSuccessPopup(i18n.t('translation:labs.successfulUpdatedDiscount'));
	}
};

export const denyLab = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/lab/${id}/refuse-activation`);
	let returnedData = response.data;
	if (returnedData.success) {
		navigationMethods.goBack();
		showSuccessPopup(i18n.t('translation:labs.successfullyDeniedLab'));
	}
};

export const activateLab = (id, waitingForApproval) => async (dispatch, getState) => {
	const maxNumber = getFormValues('activeUsersForm')(getState())['maxNumber'] || 0;
	if (waitingForApproval && maxNumber) {
		dispatch(startLoader(LOADER_TYPES.GLOBAL_LOADER));
		getAxiosWithToken(true, false)
			.put(
				`/lab/${id}/change-max-active-users`,
				{},
				{
					params: {
						'max-number': maxNumber
					}
				}
			)
			.then((resp) => {
				activate();
			});
	} else {
		activate();
	}
	async function activate() {
		const users = getState().users.users;
		const maxActiveUsers = getState().labs.currentLab.maxActiveUsers;
		const response = await getAxiosWithToken().put(`/lab/${id}/activate`);
		let returnedData = response.data;
		if (returnedData.success) {
			if (users && users.content && users.content.length > maxActiveUsers) {
				showSuccessPopup(i18n.t('translation:labs.successfullyActivatedLabWithoutStaff'));
			} else {
				showSuccessPopup(i18n.t('translation:labs.successfullyActivatedLab'));
			}
			dispatch({
				type: GET_LAB_DETAILS,
				payload: returnedData.data
			});
			dispatch(getLabStaff(id));
		}
	}
};

export const deactivateLab = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/lab/${id}/deactivate`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:labs.successfullyDeactivatedLab'));
		dispatch({
			type: GET_LAB_DETAILS,
			payload: returnedData.data
		});
		dispatch(getLabStaff(id));
	}
};

export const getLabStaff = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/lab/${id}/get-staff`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USERS,
			payload: returnedData.data
		});
	}
};

export const getLabApprovedStaff = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/lab/${id}/get-already-approved-staff`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USERS,
			payload: returnedData.data
		});
	}
};

export const getLinkedClinicsWithoutPagination = (labId) => async (dispatch) => {
	const response = await getAxiosWithToken().get(
		`/link/get-linked-clinics-minimal-data/${labId}`
	);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CLINICS,
			payload: returnedData.data
		});
	}
};

export const createLinkedPassiveClinic =
	(labId, clinicData, displayErrorMessage, closeModal) => async (dispatch) => {
		const response = await getAxiosWithToken().post(
			'/clinic/create-linked-passive-clinic',
			clinicData
		);
		let returnedData = response.data;
		if (returnedData.success) {
			showSuccessPopup(i18n.t('translation:clinics.successfulCreatedClinic'));

			if (closeModal) {
				closeModal();
			}

			// Reload the linked clinics from the backend to get the correct sorting and pagination.
			// Position on the first page since we cannot know on which page will be the newely created clinic.
			dispatch(getLinkedClinics(labId, 1));
		} else if (displayErrorMessage) {
			displayErrorMessage(returnedData.userMessage);
		}
	};
