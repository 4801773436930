import React, { Fragment } from 'react';
import SubHeader from '../../../common/SubHeader';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import { Fields } from '../../../common/Fields';
import { getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { formUtils } from '../../../../utils/formUtils';
import { parseOverdueIntervals, parseTaxGroups } from '../../../../utils/tableUtils';
import { isBillingModuleEnabled, isEmptyObject } from '../../../../utils/appUtils';
import DefaultButton from '../../../common/DefaultButton';
import Modal from 'react-modal';
import TaxGroupModal from './TaxGroupModal';
import OverdueIntervalModal from './OverdueIntervalModal';
import Table from '../../../common/Table';
import {
	getBillingSettings,
	updateBillingDocumentNumber,
	updateTaxType
} from '../../../../actions';

class Settings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isCountrySet: false,
			isTaxGroupModalOpen: false,
			selectedTaxGroup: {},
			isOverdueIntervalModalOpen: false,
			selectedOverdueInterval: {},
			errorExists: false,
			errorMessage: {}
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');
		this.props.getBillingSettings(this.props.account.labId);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.formValues !== this.props.formValues) {
			const isCountrySet =
				this.props.formValues?.country?.length > 0 &&
				this.props.formValues?.country.trim().length;

			if (isCountrySet) {
				this.setState({ isCountrySet: false });
			} else {
				this.setState({ isCountrySet: true });
			}
		}
	}

	render() {
		if (!this.props.modules.length) {
			return (
				<Fragment>
					<Header title={i18n.t('translation:billingModule.settings.header')} />
				</Fragment>
			);
		}

		return (
			<Fragment>
				<Header title={i18n.t('translation:billingModule.settings.header')} />
				<hr />
				{this.renderContent()}
				{this.state.isTaxGroupModalOpen && this.renderTaxGroupModal()}
				{this.state.isOverdueIntervalModalOpen && this.renderOverdueIntervalModal()}
			</Fragment>
		);
	}

	renderContent = () => {
		if (!isBillingModuleEnabled(this.props.modules)) {
			return (
				<div className="d-flex mt-5">
					<div className="col-6 pl-0">{this.renderTaxTypeSection()}</div>
					<div className="col-6 pr-0">{this.renderTaxGroupsSection()}</div>
				</div>
			);
		}

		return (
			<Fragment>
				<div className="d-flex mt-5">
					<div className="col-6 pl-0">{this.renderInitialDocumentSection()}</div>
					<div className="col-6 pr-0">{this.renderTaxTypeSection()}</div>
				</div>
				<div className="d-flex mt-5">
					<div className="col-6 pl-0">{this.renderTaxGroupsSection()}</div>
					<div className="col-6 pr-0">{this.renderOverdueIntervalsSection()}</div>
				</div>
			</Fragment>
		);
	};

	renderInitialDocumentSection = () => {
		return (
			<Fragment>
				<SubHeader
					title={
						<h2 className="mr-xs">
							{i18n.t('translation:billingModule.settings.initialDocumentNumber')}
						</h2>
					}
					buttons={[this.renderSubmitButton(this.onInitialDocumentSubmit)]}
				/>
				<form
					onSubmit={(e) => {
						if (!this.isBillingSettingsEditable()) {
							e.preventDefault();
						}
					}}>
					<div className="p-b-30 data-section mt-4">
						<div className="content w-100 p-0">
							{this.renderInitDocCountryDropdown()}
							{this.renderInitDocNumberInput()}
						</div>
					</div>
				</form>
			</Fragment>
		);
	};

	renderTaxTypeSection = () => {
		return (
			<Fragment>
				<SubHeader
					title={
						<h2 className="mr-xs">
							{i18n.t('translation:billingModule.common.taxType')}
						</h2>
					}
					buttons={[this.renderSubmitButton(this.onTaxTypeSubmit)]}
				/>
				<form>
					<div className="p-b-30 data-section mt-4">
						<div className="content w-100 p-0">{this.renderTaxTypeDropdown()}</div>
					</div>
				</form>
			</Fragment>
		);
	};

	renderOverdueIntervalsSection = () => {
		const columns = [
			i18n.t('translation:billingModule.settings.common.intervalDays'),
			i18n.t('translation:billingModule.settings.common.action')
		];

		const data = parseOverdueIntervals(
			this.props.overdueIntervals,
			this.openOverdueIntervalModal,
			this.props.billingSettings.id
		);

		return (
			<Fragment>
				<SubHeader
					title={
						<h2 className="mr-xs">
							{i18n.t('translation:billingModule.settings.overdueIntervals')}
						</h2>
					}
					buttons={[this.renderAddButton('overdueInterval')]}
				/>
				<Table
					className="p-0 p-b-30 data-section mt-4 w-100"
					columns={columns}
					data={data}
				/>
			</Fragment>
		);
	};

	renderTaxGroupsSection = () => {
		const columns = [
			i18n.t('translation:billingModule.settings.common.name'),
			i18n.t('translation:billingModule.settings.common.rate'),
			i18n.t('translation:billingModule.settings.common.action')
		];

		const data = parseTaxGroups(
			this.props.taxGroups,
			this.openTaxGroupModal,
			this.props.billingSettings.id
		);

		return (
			<Fragment>
				<SubHeader
					title={
						<h2 className="mr-xs">
							{i18n.t('translation:billingModule.settings.taxGroups')}
						</h2>
					}
					buttons={[this.renderAddButton('taxGroup')]}
				/>
				<Table
					className="p-0 p-b-30 data-section mt-4 w-100"
					columns={columns}
					data={data}
				/>
			</Fragment>
		);
	};

	renderSubmitButton = (onSubmitAction) => (
		<DefaultButton
			title={i18n.t('translation:common.buttons.save')}
			short={true}
			onClick={onSubmitAction}
			//disabled={!(this.isBillingSettingsEditable() && !this.state.isCountrySet)}
		/>
	);

	renderInitDocCountryDropdown = () => (
		<div className="d-flex justify-content-start align-items-center pt-3">
			<label className="col-2 p-0 ml-3 mr-1">
				{i18n.t('translation:billingModule.settings.initDocCountry')}
			</label>
			{Fields.billingModule.settings.initialDocumentNumberCountry({
				options: formUtils.options.getDocInitNumCountriesItems(
					this.props.formValues?.country
				),
				onChange: (event) => {
					this.setState({ errorMessage: {}, errorExists: false });
				},
				className: 'col-7 pl-0',
				noLabel: true,
				validate: formUtils.validateRequiredField
			})}
			{this.renderError('country')}
		</div>
	);

	renderInitDocNumberInput = () => (
		<Fragment>
			<div className="d-flex align-items-center py-3">
				<label className="col-2 p-0 ml-3 mr-1">
					{i18n.t('translation:billingModule.settings.initDocNumberNum')}
				</label>
				{Fields.billingModule.settings.initialDocumentNumber({
					className: 'col-7 pl-0',
					noLabel: true,
					disabled: this.state.isCountrySet
				})}
			</div>
			{this.renderError(
				'initialDocumentNumber',
				i18n.t('translation:billingModule.settings.initDocNumberNum')
			)}
		</Fragment>
	);

	renderTaxTypeDropdown = () => (
		<div className="d-flex justify-content-start align-items-center py-3">
			<label className="col-2 p-0 ml-3 mr-1">
				{i18n.t('translation:billingModule.common.taxType')}
			</label>
			{Fields.billingModule.settings.taxType({
				options: formUtils.options.getTaxTypeOptions(),
				className: 'col-7 pl-0',
				noLabel: true,
				validate: formUtils.validateRequiredField
			})}
			{this.renderError('taxType')}
		</div>
	);

	renderFormIsDisabledMessage = () => {
		if (!this.isBillingSettingsEditable()) {
			return (
				<div className="col-10 offset-2 w-100 pl-3 pb-3 font-italic">
					{i18n.t('translation:billingModule.settings.initialDocNumberDisabled')}
				</div>
			);
		}

		return null;
	};

	isBillingSettingsEditable = () => {
		if (isEmptyObject(this.props.billingSettings)) {
			return true;
		}

		return this.props.billingSettings.editable;
	};

	renderError = (inputName) => {
		if (this.state.errorExists && this.state.error[inputName]) {
			return (
				<div className="text-danger fixed-bottom pl-3 pb-0">
					{this.state.error[inputName]}
				</div>
			);
		}

		return null;
	};

	onInitialDocumentSubmit = (e) => {
		e.preventDefault();

		this.props.handleSubmit((formValues) => {
			const errors = formUtils.validateBillingBGInitialDocNum(formValues, this.props.touch);

			if (!isEmptyObject(errors)) {
				throw new SubmissionError({
					...errors,
					_error: 'error'
				});
			} else {
				const settingsId = this.props.billingSettings.id;
				const { country, initialDocumentNumber } = formValues;

				const billingSettingsRequestDto = {
					country: country,
					initialDocumentNumber: initialDocumentNumber
				};

				this.props.updateBillingDocumentNumber(settingsId, billingSettingsRequestDto);
			}
		})();

		return;
	};

	onTaxTypeSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit((formValues) => {
			const errors = formUtils.validateTaxType(this.props.formValues);

			if (!isEmptyObject(errors)) {
				throw new SubmissionError({
					...errors,
					_error: 'error'
				});
			} else {
				const settingsId = this.props.billingSettings.id;
				const updateTaxTypeDto = {
					taxType: formValues.taxType
				};

				this.props.updateTaxType(settingsId, updateTaxTypeDto);
			}
		})();

		return;
	};

	renderAddButton = (category) => {
		let onClick;

		if (category === 'taxGroup') {
			onClick = this.openTaxGroupModal;
		}
		if (category === 'overdueInterval') {
			onClick = this.openOverdueIntervalModal;
		}

		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				key={i18n.t('translation:common.buttons.add')}
				short={true}
				onClick={onClick}
			/>
		);
	};

	openTaxGroupModal = (taxGroup) => {
		this.setState({
			isTaxGroupModalOpen: true,
			selectedTaxGroup: {
				taxGroupName: taxGroup.name,
				taxGroupRate: taxGroup.percentage,
				taxGroupId: taxGroup.id ? taxGroup.id : null
			}
		});
	};

	closeTaxGroupModal = () => {
		this.setState({
			isTaxGroupModalOpen: false
		});
	};

	renderTaxGroupModal = () => {
		return (
			<TaxGroupModal
				isModalOpen={this.state.isTaxGroupModalOpen}
				closeModal={this.closeTaxGroupModal}
				initialValues={this.state.selectedTaxGroup}
			/>
		);
	};

	openOverdueIntervalModal = (overdueInterval) => {
		this.setState({
			isOverdueIntervalModalOpen: true,
			selectedOverdueInterval: {
				overdueIntervalFromDay: overdueInterval.fromDay,
				overdueIntervalToDay: overdueInterval.toDay,
				overdueIntervalId: overdueInterval.id ? overdueInterval.id : null
			}
		});
	};

	closeOverdueIntervalModal = () => {
		this.setState({
			isOverdueIntervalModalOpen: false
		});
	};

	renderOverdueIntervalModal = () => {
		return (
			<OverdueIntervalModal
				isModalOpen={this.state.isOverdueIntervalModalOpen}
				closeModal={this.closeOverdueIntervalModal}
				initialValues={this.state.selectedOverdueInterval}
			/>
		);
	};
}

const getInitialValues = (state) => {
	if (isEmptyObject(state.billingSettings)) {
		return {};
	}

	const { country, initialDocumentNumber, taxType } = state.billingSettings;
	return { country, initialDocumentNumber, taxType };
};

const billingSettingsForm = reduxForm({
	form: 'billingSettingsForm',
	enableReinitialize: true,
	destroyOnUnmount: true
})(Settings);

const mapStateToProps = (state) => {
	return {
		modules: state.modules,
		account: state.account,
		billingSettings: state.billingSettings,
		taxGroups: state.billingSettings.taxGroups,
		overdueIntervals: state.billingSettings.overdueIntervals,
		formValues: getFormValues('billingSettingsForm')(state),
		initialValues: getInitialValues(state)
	};
};

export default connect(mapStateToProps, {
	getBillingSettings,
	updateBillingDocumentNumber,
	updateTaxType
})(billingSettingsForm);
