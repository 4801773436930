import React, { Fragment } from 'react';
import i18n from '../../../../../i18n';
import { connect } from 'react-redux';
import BillingDocumentModal, {
	BILLING_DOCUMENT_MODE
} from '../../invoicing/billingDocumentModal/BillingDocumentModal';
import {
	getLabDetails,
	getBillingSettings,
	clearPreCreateModalDocumentReducer,
	showMessageModal,
	hideMessageModal,
	clearOrderInvoiceData
} from '../../../../../actions';
import { BILLING_DOCUMENT_TYPES, INVOICE_TYPES } from '../../../../../utils/constants';
import CreateCreditOrDebitNoteModalOrdersFilters from './CreateCreditOrDebitNoteModalOrdersFilters';
import CreateCreditOrDebitNoteModalOrdersTable from './CreateCreditOrDebitNoteModalOrdersTable';
import DefaultButton from '../../../../common/DefaultButton';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEmptyObject } from '../../../../../utils/appUtils';
import { reset } from 'redux-form';

class CreateCreditOrDebitNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDocumentModalOpened: false,
			invoiceType: undefined,
			selectedDocuments: [],
			selectedDocumentsIds: [],
			clinicId: undefined,
			filters: {},
			sort: {}
		};

		this.props.getLabDetails(this.props.account.labId);
		this.props.getBillingSettings(this.props.account.labId);
	}

	componentDidMount() {
		if (!isEmptyObject(this.props.previousScreenFilters)) {
			this.setState({ filters: this.props.previousScreenFilters });
		}
	}

	componentWillUnmount() {
		this.props.clearPreCreateModalDocumentReducer();
		this.props.clearOrderInvoiceData();
		// TODO comment this.props.clearPreviousScreenFilterValues && this.props.clearPreviousScreenFilterValues();
		// TODO comment this.setState({ filters: {} });
	}

	render() {
		const modalTitle = i18n.t('translation:billingModule.common.setDetailsModalTitle');

		return (
			<Fragment>
				<Modal
					className="custom-modal full-page-modal pre-create-modal"
					overlayClassName="custom-overlay"
					isOpen={this.props.isModalOpen}>
					<div className={'fixed-top p-3'}>
						<FontAwesomeIcon
							className="close-icon"
							icon={faTimes}
							onClick={() => {
								this.closeModal();
							}}
						/>
						<h2>{modalTitle}</h2>
					</div>
					<div className="modal-content">
						<CreateCreditOrDebitNoteModalOrdersFilters
							invoiceType={this.props.invoiceType}
							setFilters={this.setFilters}
							filters={this.props.previousScreenFilters}
							onSelectClient={this.onSelectClient}
							onSelectInvoiceType={this.onSelectInvoiceType}
							isMainFiltersReadOnly={this.props.isMainFiltersReadOnly}
						/>
						{this.renderOrdersTable()}
						<div className="buttons task-buttons">
							{this.renderContinueButton()}
							{this.renderCloseButton()}
						</div>
					</div>
					<div className="modal-error-message w-100 p-3 text-center">
						{this.state.serverErrorMessage}
					</div>
				</Modal>
				{this.renderBillingDocumentModal()}
			</Fragment>
		);
	}

	renderOrdersTable = () => {
		if (this.state.clinicId) {
			return (
				<CreateCreditOrDebitNoteModalOrdersTable
					filters={this.state.filters}
					onSort={this.onSort}
					onSelectDocument={this.onSelectDocument}
				/>
			);
		}

		return null;
	};

	renderBillingDocumentModal = () => {
		if (this.state.isDocumentModalOpened) {
			return (
				<BillingDocumentModal
					mode={BILLING_DOCUMENT_MODE.CREATE}
					isModalOpen={this.state.isDocumentModalOpened}
					onModalClose={this.closeModal}
					documentType={this.state.invoiceType}
					selectedDocumentsIds={this.state.selectedDocumentsIds}
					clinicId={this.state.clinicId}
					previousScreenFilters={this.props.previousScreenFilters}
					onDocumentChange={this.props.onDocumentChange}
				/>
			);
		}
		return null;
	};

	renderContinueButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:billingModule.common.buttons.continue')}
				key={i18n.t('translation:billingModule.common.buttons.continue')}
				type="submit"
				onClick={() => this.onContinueButtonClick()}
			/>
		);
	};

	renderCloseButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				type="button"
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);
	};

	setFilters = (filters) => {
		this.setState({ filters });
	};

	onSort = (sort) => {
		this.setState({ sort });
	};

	onSelectDocument = (document) => {
		const selectedDocuments = this.state.selectedDocuments;

		const isAlreadySelected = selectedDocuments.some(
			(selectedDocument) => selectedDocument.id === document.id
		);

		if (isAlreadySelected) {
			const updatedDocuments = selectedDocuments.filter(
				(selectedDocument) => selectedDocument.id !== document.id
			);
			this.setState({ selectedDocuments: updatedDocuments });

			let selectedDocumentsIds = [];
			updatedDocuments.forEach((document) => selectedDocumentsIds.push(document.id));
			this.setState({ selectedDocumentsIds });
		} else {
			selectedDocuments.push(document);
			this.setState({ selectedDocuments });

			let selectedDocumentsIds = [];
			selectedDocuments.forEach((order) => selectedDocumentsIds.push(order.id));
			this.setState({ selectedDocumentsIds });
		}
	};

	onSelectClient = (clinicId) => {
		this.resetSelectedOrders();
		this.setState({ clinicId });
		this.props.reset('tableForm');
	};

	onSelectInvoiceType = (selectedInvoiceType) => {
		const invoiceType =
			selectedInvoiceType === INVOICE_TYPES.FREE
				? BILLING_DOCUMENT_TYPES.FREE_INVOICE
				: BILLING_DOCUMENT_TYPES.ORDER_INVOICE;

		this.setState({ invoiceType });
	};

	onContinueButtonClick = () => {
		const selectedDocuments = this.state.selectedDocuments;

		if (!this.state.clinicId) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:billingModule.invoicing.errors.noSelectedClient'),
				[],
				false,
				true
			);
			return;
		}

		if (selectedDocuments.length === 0) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:billingModule.invoicing.errors.noSelectedInvoice'),
				[],
				false,
				true
			);
			return;
		}

		if (this.isMixedInvoicesWithExcludedTaxStatusesSelected(this.state.selectedDocuments)) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:billingModule.errors.selectedMixedInvoices'),
				[],
				false,
				true
			);
			return;
		}

		if (this.props.invoiceType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			this.setState((prevState) => {
				return {
					...prevState,
					isDocumentModalOpened: true,
					invoiceType: BILLING_DOCUMENT_TYPES.CREDIT_NOTE,
					clinicId: this.state.clinicId || selectedDocuments[0].clinicId
				};
			});
			this.setState({ filters: {} });
		}

		if (this.props.invoiceType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE) {
			this.setState((prevState) => {
				return {
					...prevState,
					isDocumentModalOpened: true,
					invoiceType: BILLING_DOCUMENT_TYPES.DEBIT_NOTE,
					clinicId: this.state.clinicId || selectedDocuments[0].clinicId
				};
			});
			this.setState({ filters: {} });
		}
	};

	closeModal = () => {
		this.props.onModalClose();
		this.props.clearOrderInvoiceData();
		// TODO commentthis.props.clearPreviousScreenFilterValues && this.props.clearPreviousScreenFilterValues();
		this.setState({ filters: {} });
	};

	resetSelectedOrders = () => {
		this.setState({ selectedDocuments: [], selectedDocumentsIds: [] });
	};

	isMixedInvoicesWithExcludedTaxStatusesSelected = (selectedInvoices) => {
		let invoicesWithIncludedTax = 0;
		let invoicesWithExcludedTax = 0;

		selectedInvoices.forEach((invoice) => {
			if (invoice.taxIncluded) {
				invoicesWithIncludedTax++;
			} else {
				invoicesWithExcludedTax++;
			}
		});

		if (invoicesWithIncludedTax > 0 && invoicesWithExcludedTax > 0) {
			return true;
		} else {
			return false;
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		billingSettings: state.billingSettings
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	getBillingSettings,
	clearPreCreateModalDocumentReducer,
	showMessageModal,
	hideMessageModal,
	clearOrderInvoiceData,
	reset
})(CreateCreditOrDebitNoteModal);
