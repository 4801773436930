import { LOAD_SETTINGS, SAVE_SETTINGS } from '../actions/types';

const settingReducer = (state = {}, action) => {
	if (action.type === LOAD_SETTINGS) {
		state = action.payload;
		return { ...state };
	}
	if (action.type === SAVE_SETTINGS) {
		state = action.payload;
		return { ...state };
	}

	return state;
};

export default settingReducer;
