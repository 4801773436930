import {
	changeItemAmountNoTax,
	changeItemTotalPriceWithTax,
	formUtils,
	getItemQuantity,
	getItemTax,
	getItemUnitPriceNoTax
} from '../../../../../../utils/formUtils';
import { INVOICE_FIELD_NAME } from '../../../../../../utils/constants';
import Decimal from 'decimal.js-light';

export const add = (a, b) => {
	const addendOne = parseNumberToDecimal(a);
	const addendTwo = parseNumberToDecimal(b);

	const approximatelyResult = addendOne.toNumber() + addendTwo.toNumber();
	const resultPrecision = getPrecision(approximatelyResult);
	setDecimalResultPrecision(resultPrecision);
	return addendOne.add(addendTwo).toNumber();
};

export const subtract = (a, b) => {
	const minuend = parseNumberToDecimal(a);
	const subtrahend = parseNumberToDecimal(b);

	const approximatelyResult = minuend.toNumber() - subtrahend.toNumber();
	const resultPrecision = getPrecision(approximatelyResult);
	setDecimalResultPrecision(resultPrecision);
	return minuend.sub(subtrahend).toNumber();
};

export const multiply = (a, b) => {
	const multiplierOne = parseNumberToDecimal(a);
	const multiplierTwo = parseNumberToDecimal(b);

	const approximatelyResult = multiplierOne.toNumber() * multiplierTwo.toNumber();
	const resultPrecision = getPrecision(approximatelyResult);
	setDecimalResultPrecision(resultPrecision);
	return multiplierOne.mul(multiplierTwo).toNumber();
};

export const divide = (a, b) => {
	if (!b || isNaN(b)) {
		return parseNumberToDecimal(a);
	}
	const dividend = parseNumberToDecimal(a);
	const divisor = parseNumberToDecimal(b);

	const approximatelyResult = dividend.toNumber() / divisor.toNumber();
	const resultPrecision = getPrecision(approximatelyResult);
	setDecimalResultPrecision(resultPrecision);

	return dividend.dividedBy(divisor).toNumber();
};

export const setDecimalResultPrecision = (precision) => {
	Decimal.set({ precision: precision, rounding: 8 });
};

export const parseNumberToDecimal = (num) => {
	if (
		num === undefined ||
		num === null ||
		num.toString().trim().length === 0 ||
		num.toString().trim() === '_' //some empty tax options values are equal to " _ "
	) {
		num = 0;
	} else {
		num = Number(num);
	}

	const numPrecision = getPrecision(num);
	Decimal.set({ precision: numPrecision, rounding: 6 });
	return new Decimal(Number(num));
};

//returns a precision with 6 digits float part
const getPrecision = (a) => {
	const intPartLength = Math.trunc(a).toString().length;
	return Math.min(6 + intPartLength, 14);
};

export const freeInvoiceUtils = {
	onItemQuantityChange: ({ newQuantity, itemId, formValues, formName, formChange }) => {
		const itemUnitPriceNoTax = getItemUnitPriceNoTax(formValues, itemId);
		const previousValue = formValues[`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${itemId}`];
		const normalizedQuantity = formUtils.normalize.productQuantity(newQuantity, previousValue);

		if (normalizedQuantity !== newQuantity) {
			return;
		}

		this.onItemAmountChange({
			unitPrice: itemUnitPriceNoTax,
			quantity: Number(newQuantity),
			itemId,
			formValues,
			formName,
			formChange
		});
	},

	onItemAmountChange: ({ unitPrice, quantity, itemId, formValues, formName, formChange }) => {
		const currentTax = getItemTax(formValues, itemId);

		if (quantity && unitPrice) {
			const updatedAmount = Number(quantity) * Number(unitPrice);

			changeItemAmountNoTax(formChange, itemId, updatedAmount, formName);

			if (currentTax) {
				this.updateItemTotalPriceWithTax({
					unitPrice,
					quantity,
					taxPercentName: currentTax,
					itemId,
					formName,
					formChange
				});
			}
		} else {
			changeItemAmountNoTax(formChange, itemId, 0, formName);
			changeItemTotalPriceWithTax(formChange, itemId, 0, formName);
		}
	},

	onItemTaxChange: ({ itemTax, itemId, formValues, formName, formChange }) => {
		const unitPriceNoTax = getItemUnitPriceNoTax(formValues, itemId);
		const quantity = getItemQuantity(formValues, itemId);

		this.updateItemTotalPriceWithTax({
			unitPrice: unitPriceNoTax,
			quantity,
			taxPercentName: itemTax,
			formName,
			formChange
		});
	},

	onItemUnitPriceChange: ({ unitPrice, itemId, formName, formValues, formChange }) => {
		const quantity = getItemQuantity(formValues, itemId);

		const previousValue = formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${itemId}`];

		const normalizedPrice = formUtils.normalize.price(unitPrice, previousValue);

		if (normalizedPrice !== unitPrice) {
			return;
		}

		this.onItemAmountChange({
			unitPrice: Number(unitPrice),
			quantity,
			itemId,
			formValues,
			formName,
			formChange
		});
	},

	updateItemTotalPriceWithTax: ({
		unitPrice,
		quantity,
		taxPercentName,
		itemId,
		formName,
		formChange
	}) => {
		const tax = taxPercentName ? taxPercentName.split('_')[0] : null;

		let updatedTotalPrice = 0;

		if (unitPrice && quantity && tax !== null && tax.trim().length) {
			const updatedAmount = Number(unitPrice) * Number(quantity);
			updatedTotalPrice = updatedAmount + updatedAmount * Number(tax) * 0.01;
		}

		changeItemTotalPriceWithTax(formChange, itemId, updatedTotalPrice, formName);
	}
};
