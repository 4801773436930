import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getFormValues, initialize, reduxForm } from 'redux-form';
import i18n from '../../../../../i18n';
import DefaultButton from '../../../../common/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
	clearCurrentClinic,
	clearPreCreateModalDocumentReducer,
	createInvoice,
	getBillingDocumentDetails,
	getBillingDocuments,
	getBillingOrders,
	getCreditOrderInvoiceData,
	getLabDetails,
	getNextDocumentNumber,
	getOrderInvoiceData,
	hideMessageModal,
	loadLanguage,
	rejectBillingDocument,
	showMessageModal,
	updateInvoice,
	updateInvoiceLocales
} from '../../../../../actions';
import GeneralInformationSection from './sections/common/GeneralInformationSection';
import RecipientSection from './sections/common/RecipientSection';
import SupplierSection from './sections/common/SupplierSection';
import InvoiceSummarySection from './sections/common/InvoiceSummarySection';
import BankDetailsSection from './sections/common/BankDetailsSection';
import DiscountSection from './sections/common/DiscountSection';
import {
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	ERROR_MESSAGES,
	INVOICE_FIELD_NAME,
	INVOICING_METHODS,
	PAGINATION_TYPES,
	TRANSACTION_FORM_FIELD
} from '../../../../../utils/constants';
import { isEmptyObject, showErrorPopup, showSuccessPopup } from '../../../../../utils/appUtils';
import FreeDocumentProductSection from './sections/common/FreeDocumentProductSection';
import OrderInvoiceTable from './tables/orderInvoiceTable/OrderInvoiceTable';
import CreditDebitInvoiceTable from './tables/creditDebitInvoiceTable/CreditDebitInvoiceTable';
import {
	formUtils,
	getDocumentItemsIDS,
	getItemCreditDebitNoRound,
	getLocalizedItemName,
	getMapOrderIdToId
} from '../../../../../utils/formUtils';
import LocalizationTable from './sections/common/LocalizationTable';
import {
	getSecondThirdLangAbbreviation,
	isLocalizationMissing
} from './sections/common/localization/localisationUtils';
import { multiply, subtract } from './utils/documentUtils';
import ExportPdfModal from '../../documents/ExportPdfModal';

const SUBMIT_TYPE = {
	CREATE: 'CREATE',
	CREATE_AND_SEND: 'CREATE_AND_SEND',
	SAVE_LOCALES: 'SAVE_LOCALES'
};

export const BILLING_DOCUMENT_MODE = {
	CREATE: 'CREATE',
	PREVIEW: 'PREVIEW',
	EDIT: 'EDIT'
};

class BillingDocumentModal extends React.Component {
	state = {
		clickedButtonSubmitType: undefined,
		isExportPdfModalOpen: false,
		dueDateInitial: null,
		mode: null,
		isInitialized: false,
		selectedDocumentId: null
	};

	static getDerivedStateFromProps(props, state) {
		if (!state.isInitialized) {
			return {
				clickedButtonSubmitType: state.clickedButtonSubmitType,
				isExportPdfModalOpen: state.isExportPdfModalOpen,
				dueDateInitial: state.dueDateInitial,
				mode: props.mode,
				selectedDocumentId: props.selectedDocumentId,
				isInitialized: state.isInitialized
			};
		}
		return {
			clickedButtonSubmitType: state.clickedButtonSubmitType,
			isExportPdfModalOpen: state.isExportPdfModalOpen,
			dueDateInitial: state.dueDateInitial,
			mode: state.mode,
			selectedDocumentId: state.selectedDocumentId,
			isInitialized: state.isInitialized
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');

		const { currentLab, account, documentType } = this.props;
		const { mode } = this.state;
		const { CREATE, PREVIEW, EDIT } = BILLING_DOCUMENT_MODE;

		if (!currentLab || isEmptyObject(currentLab)) {
			this.props.getLabDetails(account.labId);
		}

		if (mode === CREATE) {
			this.componentDidMountOnCreateMode();
		}

		if (mode === PREVIEW || mode === EDIT) {
			this.componentDidMountOnPreviewEditMode(this.props.selectedDocumentId);
		}

		this.props.change(INVOICE_FIELD_NAME.DOCUMENT_TYPE, documentType);
	}

	componentWillUnmount() {
		this.clearReduxForm();
		this.props.clearPreCreateModalDocumentReducer();
	}

	componentDidMountOnPreviewEditMode(selectedDocumentId) {
		if (!selectedDocumentId) {
			throw new Error(
				`'selectedDocumentId' prop is required when mode is ${BILLING_DOCUMENT_MODE.PREVIEW} `
			);
		}

		this.props.getBillingDocumentDetails(selectedDocumentId);

		this.setState((prevState) => {
			return {
				...prevState,
				isInitialized: true
			};
		});
	}

	componentDidMountOnCreateMode() {
		// TODO Medzhit - check if this can be called from the table for Order Invoice
		const { documentType, selectedOrdersIds, selectedDocumentsIds } = this.props;
		const { ORDER_INVOICE, CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (selectedOrdersIds && documentType === ORDER_INVOICE) {
			this.props.getOrderInvoiceData(selectedOrdersIds);
		}

		// TODO Medzhit -> this is needed when creating Credit/Debit Note
		// TODO Medzhit -> check if this can be moved to corresponding component
		if (documentType === CREDIT_NOTE || documentType === DEBIT_NOTE) {
			this.props.getCreditOrderInvoiceData(selectedDocumentsIds);
		}

		this.setState((prevState) => {
			return {
				...prevState,
				isInitialized: true
			};
		});
	}

	getCurrencyProps() {
		if (this.state.mode === BILLING_DOCUMENT_MODE.CREATE) {
			return {
				localizedCurrencyAbbreviation: this.props.currentLab.localizedCurrencyAbbreviation,
				currencySymbol: this.props.currentLab.currencySymbol
			};
		}
		return {
			localizedCurrencyAbbreviation:
				this.props.currentDocument?.localizedCurrencyAbbreviation,
			currencySymbol: this.props.currentDocument?.currencySymbol
		};
	}

	getModalTitle(documentType) {
		const { FREE_INVOICE, ORDER_INVOICE, CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (documentType === FREE_INVOICE) {
			return i18n.t('translation:billingModule.invoicing.freeInvoiceModalTitle');
		}
		if (documentType === ORDER_INVOICE) {
			return i18n.t('translation:billingModule.invoicing.orderInvoiceModalTitle');
		}
		if (documentType === CREDIT_NOTE) {
			return i18n.t('translation:billingModule.invoicing.creditNoteModalTitle');
		}
		if (documentType === DEBIT_NOTE) {
			return i18n.t('translation:billingModule.invoicing.debitNoteModalTitle');
		}
	}

	render() {
		const { documentType, isModalOpen, clinicId, touch, formValues } = this.props;
		const { mode } = this.state;

		return (
			<Modal
				className="custom-modal full-page-modal billing-modal"
				overlayClassName="custom-overlay"
				isOpen={isModalOpen}>
				{this.renderModalHeader(documentType)}
				<div className="modal-content" style={{ borderRadius: '0px' }}>
					<form
						className="mb-4"
						onSubmit={this.props.handleSubmit((formValues) =>
							this.onFormSubmit(formValues)
						)}>
						{this.renderGeneralInformation(documentType, mode)}
						{this.renderRecipientSupplierSection(documentType, clinicId, mode)}
						{this.renderFreeDocumentProductsSection(documentType, mode, touch)}
						{this.renderOrderDocumentProductsSection(documentType, mode)}
						{this.renderCreditDebitNoteDocumentProductsSection(documentType, mode)}
						{this.renderDiscountSection(documentType, mode)}
						{this.renderSummaryBankSections(documentType, mode)}
						{this.renderLocalizationTable(documentType, mode)}
						<div className="buttons task-buttons">
							{this.renderSaveUpdateButton(documentType, mode, formValues)}
							{this.renderSaveUpdateAndSendButton(documentType, mode, formValues)}
							{this.renderCloseButton()}
							{this.renderExportDocumentButton(this.props.currentDocument)}
							{this.renderRejectDocumentButton(
								this.props.currentDocument,
								documentType
							)}
						</div>
					</form>
					{this.renderExportPdfModal(this.props.currentDocument)}
				</div>
			</Modal>
		);
	}

	closeExportPdfModal = () => {
		this.setState((prevState) => {
			return { ...prevState, isExportPdfModalOpen: false };
		});
	};

	renderExportPdfModal = (document) => {
		if (this.state.isExportPdfModalOpen) {
			return (
				<ExportPdfModal
					isModalOpen={true}
					closeModal={() => this.closeExportPdfModal()}
					document={document}
				/>
			);
		}
		return null;
	};

	renderSummaryBankSections(documentType, mode) {
		return (
			<div className="row mt-4">
				<InvoiceSummarySection
					mode={mode}
					documentType={documentType}
					{...this.getCurrencyProps()}
				/>
				<BankDetailsSection
					mode={mode}
					documentType={documentType}
					clinicId={this.props.clinicId}
				/>
			</div>
		);
	}

	renderRecipientSupplierSection(documentType, clinicId, mode) {
		return (
			<div className="row mt-4">
				<RecipientSection documentType={documentType} clinicId={clinicId} mode={mode} />
				<SupplierSection documentType={documentType} mode={mode} />
			</div>
		);
	}

	renderGeneralInformation(documentType, mode) {
		return (
			<GeneralInformationSection
				documentType={documentType}
				mode={mode}
				touch={this.props.touch}
				onInitialDueDateChange={(date) =>
					this.setState((prevState) => {
						return {
							...prevState,
							dueDateInitial: date
						};
					})
				}
			/>
		);
	}

	renderModalHeader(documentType) {
		const modalTitle = this.getModalTitle(documentType);

		return (
			<div className={'fixed-top'}>
				<FontAwesomeIcon className="close-icon" icon={faTimes} onClick={this.closeModal} />
				<h2>{modalTitle}</h2>
			</div>
		);
	}

	renderLocalizationTable(documentType, mode) {
		if (this.props.currentDocument?.status === BILLING_DOCUMENT_STATUS.REJECTED) {
			return null;
		}

		return <LocalizationTable documentType={documentType} mode={mode} />;
	}

	renderDiscountSection(documentType, mode) {
		const { CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (documentType === CREDIT_NOTE || documentType === DEBIT_NOTE) {
			return null;
		}

		return (
			<DiscountSection documentType={documentType} mode={mode} {...this.getCurrencyProps()} />
		);
	}

	renderCreditDebitNoteDocumentProductsSection = (documentType, mode) => {
		const { CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (documentType === CREDIT_NOTE || documentType === DEBIT_NOTE) {
			return (
				<CreditDebitInvoiceTable
					documentType={documentType}
					mode={mode}
					{...this.getCurrencyProps()}
				/>
			);
		}

		return null;
	};

	renderOrderDocumentProductsSection = (documentType, mode) => {
		if (documentType !== BILLING_DOCUMENT_TYPES.ORDER_INVOICE) {
			return null;
		}

		return <OrderInvoiceTable mode={mode} {...this.getCurrencyProps()} />;
	};

	renderFreeDocumentProductsSection = (documentType, mode, touch) => {
		if (documentType !== BILLING_DOCUMENT_TYPES.FREE_INVOICE) {
			return null;
		}

		return (
			<FreeDocumentProductSection
				touch={touch}
				documentType={documentType}
				mode={mode}
				{...this.getCurrencyProps()}
			/>
		);
	};

	renderSaveUpdateButton = () => {
		if (this.state.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return null;
		}

		const title = i18n.t('translation:common.buttons.save');

		return (
			<DefaultButton
				title={title}
				key={title}
				onClick={() => this.setState({ clickedButtonSubmitType: SUBMIT_TYPE.CREATE })}
			/>
		);
	};

	renderSaveUpdateAndSendButton = () => {
		if (this.props.currentDocument?.status === BILLING_DOCUMENT_STATUS.REJECTED) {
			return null;
		}
		const { PREVIEW } = BILLING_DOCUMENT_MODE;

		let title = i18n.t('translation:billingModule.invoicing.buttons.saveAndSend');
		let submitType = SUBMIT_TYPE.CREATE_AND_SEND;

		if (this.state.mode === PREVIEW) {
			title = i18n.t('translation:billingModule.invoicing.buttons.saveTranslations');
			submitType = SUBMIT_TYPE.SAVE_LOCALES;
		}

		return (
			<DefaultButton
				title={title}
				key={title}
				onClick={() => {
					this.setState({ clickedButtonSubmitType: submitType });
				}}
			/>
		);
	};

	renderRejectDocumentButton = (currentDocument, documentType) => {
		if (!currentDocument) {
			return;
		}
		const { status, id } = currentDocument;

		if (status !== BILLING_DOCUMENT_STATUS.DRAFT) {
			return null;
		}

		const rejectButton = (
			<DefaultButton
				title={i18n.t('translation:billingModule.documents.buttons.reject')}
				onClick={() => {
					this.props.rejectBillingDocument({
						documentId: currentDocument.id,
						onSuccess: () => {
							this.props.hideMessageModal();
							this.setState(
								{
									clickedButtonSubmitType: undefined,
									isExportPdfModalOpen: false,
									dueDateInitial: null,
									isInitialized: true,
									selectedDocumentId: id,
									mode: BILLING_DOCUMENT_MODE.PREVIEW
								},
								() => {
									this.componentDidMountOnPreviewEditMode(id);
									this.props.onDocumentChange && this.props.onDocumentChange();
								}
							);
						}
					});
				}}
				class="btn base-button btn-outline-danger"
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		return (
			<DefaultButton
				type="button"
				title={i18n.t('translation:billingModule.documents.buttons.reject')}
				onClick={() =>
					this.props.showMessageModal(
						i18n.t('translation:common.warning'),
						i18n.t('translation:billingModule.invoicing.common.rejectDocumentModal'),
						[rejectButton, cancelButton],
						false,
						true
					)
				}
				class="btn base-button btn-outline-danger"
			/>
		);
	};

	renderExportDocumentButton = (currentDocument) => {
		if (!currentDocument) {
			return null;
		}
		if (this.props.currentDocument.status === BILLING_DOCUMENT_STATUS.REJECTED) {
			return null;
		}
		const delivered = currentDocument.status !== BILLING_DOCUMENT_STATUS.DRAFT;

		const title = delivered
			? i18n.t('translation:billingModule.common.buttons.downloadOriginal')
			: i18n.t('translation:billingModule.common.buttons.downloadDraft');

		return (
			<DefaultButton
				type="button"
				title={title}
				key={title}
				onClick={() =>
					this.setState((prevState) => {
						return { ...prevState, isExportPdfModalOpen: true };
					})
				}
				secondary
			/>
		);
	};

	renderCloseButton = () => {
		const { documentType } = this.props;
		const { mode } = this.state;
		const { FREE_INVOICE, ORDER_INVOICE } = BILLING_DOCUMENT_TYPES;
		let onClickAction;

		if (mode == BILLING_DOCUMENT_MODE.CREATE) {
			onClickAction = this.onCloseButtonClick;
		} else {
			onClickAction = this.closeModal;
		}

		return (
			<DefaultButton
				type="button"
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={onClickAction}
				secondary
			/>
		);
	};

	getFreeInvoiceDTO = (formValues, actionType, itemsIDS) => {
		const commonDto = this.getCreateUpdateBillingDocumentDto(formValues, actionType);

		const items = this.getFreeOrderInvoiceDtoItems(
			itemsIDS,
			formValues,
			BILLING_DOCUMENT_TYPES.FREE_INVOICE
		);

		commonDto.items = [...items];

		return commonDto;
	};

	getLocalizedItems(formValues, itemsIDS, langAbbreviations, localizationData) {
		let newItemsIds = {};
		let updatedItems = {};

		for (let abbreviation in langAbbreviations) {
			newItemsIds[abbreviation] = itemsIDS;
			updatedItems[abbreviation] = [];
		}

		if (localizationData && localizationData.length) {
			for (let localizationObj of localizationData) {
				const { items, language } = localizationObj;

				if (!items) {
					return;
				}
				const currentItems = [];

				for (let item of items) {
					const itemNotDeleted = itemsIDS.findIndex((id) => id == item.billingItemId);
					if (itemNotDeleted !== -1) {
						const updatedItem = { ...item };
						let itemName =
							getLocalizedItemName(formValues, language, item.billingItemId) ?? '';

						updatedItem.name = itemName;
						newItemsIds[language] = newItemsIds[language].filter(
							(id) => id != item.billingItemId
						);
						currentItems.push(updatedItem);
					}
				}

				updatedItems[language] = currentItems;
			}
		}

		for (let newItemId in newItemsIds) {
			for (let billingItemId of newItemsIds[newItemId]) {
				let secondLangItemName =
					getLocalizedItemName(formValues, newItemId, billingItemId) ?? '';

				const itemObj = { billingItemId };

				itemObj.name = secondLangItemName;
				updatedItems[newItemId].push({ ...itemObj });
			}
		}

		return updatedItems;
	}

	getLocalisationDto = ({
		formValues,
		documentType,
		documentLanguage,
		localizationData,
		itemsIDS
	}) => {
		const langAbbreviations = getSecondThirdLangAbbreviation(documentLanguage);

		const { LOCALIZATION } = INVOICE_FIELD_NAME;
		const { FREE_INVOICE } = BILLING_DOCUMENT_TYPES;

		const {
			COMPOSED_BY,
			VERBALLY,
			SUPPLIER_RESPONSIBLE_PERSON,
			SUPPLIER_NAME,
			SUPPLIER_ADDRESS,
			BANK_NAME,
			RECEIVER_ADDRESS,
			RECEIVER_NAME,
			RECEIVER_RESPONSIBLE_PERSON,
			DOCUMENT_REASON,
			DISCOUNT_DESCRIPTION,
			TAX_NOT_INCLUDED_REASON,
			NOTES
		} = LOCALIZATION;

		let localizations = [];

		for (let abbreviation of langAbbreviations) {
			localizations.push({
				language: abbreviation,
				creator: formValues[COMPOSED_BY[abbreviation]],
				issuerName: formValues[SUPPLIER_NAME[abbreviation]],
				issuerAddress: formValues[SUPPLIER_ADDRESS[abbreviation]],
				issuerOwner: formValues[SUPPLIER_RESPONSIBLE_PERSON[abbreviation]],
				bankName: formValues[BANK_NAME[abbreviation]],
				totalInText: formValues[VERBALLY[abbreviation]],
				receiverName: formValues[RECEIVER_NAME[abbreviation]],
				receiverOwner: formValues[RECEIVER_RESPONSIBLE_PERSON[abbreviation]],
				receiverAddress: formValues[RECEIVER_ADDRESS[abbreviation]],
				documentReason: formValues[DOCUMENT_REASON[abbreviation]],
				discountDescription: formValues[DISCOUNT_DESCRIPTION[abbreviation]],
				taxNotIncludedReason: formValues[TAX_NOT_INCLUDED_REASON[abbreviation]],
				notes: formValues[NOTES[abbreviation]]
			});
		}

		const localizationAlreadySet = localizationData && localizationData.length;

		if (localizationAlreadySet) {
			for (const [index, abbreviation] of langAbbreviations.entries()) {
				const languageData = localizationData.find((loc) => loc.language === abbreviation);

				if (languageData) {
					localizations[index].id = languageData.id;
				}
			}
		}

		if (documentType === FREE_INVOICE) {
			if (itemsIDS === undefined) {
				throw new Error(
					'Localization and itemsIDS are mandatory props of getLocalisationDto if Free invoice'
				);
			}
			const updatedItems = this.getLocalizedItems(
				formValues,
				itemsIDS,
				langAbbreviations,
				localizationData
			);

			for (let key in updatedItems) {
				const localization = localizations.find((loc) => loc.language === key);
				if (localization) {
					localization.items = updatedItems[key];
				}
			}
		}

		return localizations;
	};

	getFreeOrderInvoiceDtoItems = (itemsIDS, formValues, documentType) => {
		const { ORDER_INVOICE, FREE_INVOICE } = BILLING_DOCUMENT_TYPES;
		const { ITEM_TAX_PERCENT_TAX_NAME_, MAP_ITEM_ID_BILLING_ITEM_ID } = INVOICE_FIELD_NAME;
		const { CREATE } = BILLING_DOCUMENT_MODE;

		const items = [];

		for (let i = 0; i < itemsIDS.length; i++) {
			//orderItemId - order invoice;
			const currentID = itemsIDS[i];
			const splitTaxGroup =
				formValues[`${ITEM_TAX_PERCENT_TAX_NAME_}${currentID}`].split('_');
			const taxName = splitTaxGroup[1];
			const taxPercentage = splitTaxGroup[0];

			items.push({
				// id,
				name: formValues[`${INVOICE_FIELD_NAME.ITEM_NAME_}${currentID}`],
				unit: formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_}${currentID}`],
				quantity: formValues[`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${currentID}`],
				unitPrice: formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${currentID}`],
				taxName,
				taxPercentage,
				totalPrice:
					formValues[`${INVOICE_FIELD_NAME.ITEM_TOTAL_PRICE_WITH_TAX_}${currentID}`]
			});

			if (documentType === ORDER_INVOICE) {
				items[items.length - 1].advance =
					formValues[`${INVOICE_FIELD_NAME.ITEM_ADVANCE_NO_TAX_}${currentID}`];
				items[items.length - 1].orderItemId = currentID;

				if (this.state.mode !== CREATE) {
					const id = formValues[MAP_ITEM_ID_BILLING_ITEM_ID][currentID];
					items[items.length - 1].id = id;
				}
			}

			if (documentType === FREE_INVOICE) {
				items[items.length - 1].advance = multiply(
					formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${currentID}`],
					formValues[`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${currentID}`]
				);

				const id = itemsIDS[i];
				items[items.length - 1].id = id;
			}
		}

		return items;
	};

	getOrdersDTO = (formValues, mode) => {
		const {
			DOCUMENT_ORDERS_IDS,
			DOCUMENT_ITEMS_IDS,
			ORDER_INVOICING_METHOD_,
			ORDER_PERCENT_FOR_INVOICING_,
			ORDER_FIXED_AMOUNT_FOR_INVOICING_
		} = INVOICE_FIELD_NAME;
		const { CREATE } = BILLING_DOCUMENT_MODE;
		const { ORDER_INVOICE } = BILLING_DOCUMENT_TYPES;

		const ordersIDS = formValues[DOCUMENT_ORDERS_IDS];

		let orders = [];

		const mappedOrdersIdsToIds = getMapOrderIdToId(formValues);

		for (let orderID of ordersIDS) {
			const orderItemsIDS = formValues[DOCUMENT_ITEMS_IDS][orderID];

			const items = this.getFreeOrderInvoiceDtoItems(
				orderItemsIDS,
				formValues,
				ORDER_INVOICE
			);

			const orderInvoiceMethod = formValues[`${ORDER_INVOICING_METHOD_}${orderID}`];
			let orderInvoiceValue;

			if (orderInvoiceMethod === INVOICING_METHODS.PERCENTAGE) {
				orderInvoiceValue = formValues[`${ORDER_PERCENT_FOR_INVOICING_}${orderID}`];
			} else {
				orderInvoiceValue = formValues[`${ORDER_FIXED_AMOUNT_FOR_INVOICING_}${orderID}`];
			}

			orders.push({
				orderInvoiceMethod,
				orderInvoiceValue: orderInvoiceValue,
				orderId: orderID,
				items: [...items]
			});

			if (mode !== CREATE) {
				const id = mappedOrdersIdsToIds[orderID];
				orders[orders.length - 1].id = id;
			}
		}

		return orders;
	};

	getCreateUpdateBillingDocumentDto = (formValues, actionType) => {
		const total = subtract(
			formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX],
			formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX]
		);

		const subTotal = subtract(
			formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX],
			formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_TAX]
		);

		let discountTax = formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED];

		if (discountTax) {
			discountTax = multiply(discountTax, 100);
		}

		const dto = {
			type: this.props.documentType,
			labId: formValues[INVOICE_FIELD_NAME.SUPPLIER_ID],
			clinicId: formValues[INVOICE_FIELD_NAME.RECIPIENT_ID],
			issueDate: formValues[INVOICE_FIELD_NAME.DATE_OF_ISSUE],
			dueDate: formValues[INVOICE_FIELD_NAME.DUE_DATE],
			taxEventDate: formValues[INVOICE_FIELD_NAME.DATE_OF_TAX_EVENT],
			creator: formValues[INVOICE_FIELD_NAME.COMPOSED_BY],
			receiver: {
				name: formValues[INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME].split('_%_')[1],
				uic: formValues[INVOICE_FIELD_NAME.RECIPIENT_UIC],
				vatNumber: formValues[INVOICE_FIELD_NAME.RECIPIENT_VAT_NUMBER],
				owner: formValues[INVOICE_FIELD_NAME.RECIPIENT_RESPONSIBLE_PERSON],
				address: formValues[INVOICE_FIELD_NAME.RECIPIENT_REGISTRATION_ADDRESS]
			},
			issuer: {
				name: formValues[INVOICE_FIELD_NAME.SUPPLIER_COMPANY_NAME],
				uic: formValues[INVOICE_FIELD_NAME.SUPPLIER_UIC],
				vatNumber: formValues[INVOICE_FIELD_NAME.SUPPLIER_VAT_NUMBER],
				owner: formValues[INVOICE_FIELD_NAME.SUPPLIER_RESPONSIBLE_PERSON],
				address: formValues[INVOICE_FIELD_NAME.SUPPLIER_REGISTRATION_ADDRESS]
			},
			discountAmount: formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND],
			discountVisibleByReceiver: formValues[INVOICE_FIELD_NAME.SHOW_IN_INVOICE],
			discountTax: discountTax,
			discountDescription: formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NOTE],
			subtotal: subTotal,
			totalTax: formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_TAX],
			total: total,
			totalInText: formValues[INVOICE_FIELD_NAME.VERBALLY],
			bankName: formValues[INVOICE_FIELD_NAME.BANK_NAME],
			bic: formValues[INVOICE_FIELD_NAME.BIC],
			iban: formValues[INVOICE_FIELD_NAME.IBAN],
			taxIncluded: !formValues[INVOICE_FIELD_NAME.EXCLUDE_TAX],
			transactionMethod: formValues[INVOICE_FIELD_NAME.TRANSACTION_METHOD],
			notes: formValues[INVOICE_FIELD_NAME.NOTES]
		};

		if (formValues[INVOICE_FIELD_NAME.EXCLUDE_TAX]) {
			dto.taxNotIncludedReason = formValues[INVOICE_FIELD_NAME.REASON_FOR_TAX_EXCLUDE];
		}
		dto.saveAction = actionType;

		return dto;
	};

	getOrderInvoiceDto = (formValues, actionType, mode) => {
		const commonDTO = this.getCreateUpdateBillingDocumentDto(formValues, actionType);

		const orders = this.getOrdersDTO(formValues, mode);

		commonDTO.orders = [...orders];

		return commonDTO;
	};

	getCreditDebitInvoiceDto = (formValues, actionType, documentType, mode) => {
		const {
			DOCUMENT_ITEMS_IDS,
			DOCUMENT_INVOICES_IDS,
			MAP_ITEM_ID_BILLING_ITEM_ID,
			DOCUMENT_REASON
		} = INVOICE_FIELD_NAME;
		const { CREATE } = BILLING_DOCUMENT_MODE;
		const { CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		const commonDTO = this.getCreateUpdateBillingDocumentDto(formValues, actionType);

		const invoicesIDS = formValues[DOCUMENT_INVOICES_IDS];

		const localisations = this.getLocalisationDto({
			formValues,
			documentType,
			documentLanguage: this.props.documentLanguage,
			localizationData: this.props.currentDocument?.localizations,
			itemsIDS: undefined
		});

		const creditedDebitItemsDTO = [];

		for (let invoiceID of invoicesIDS) {
			const invoiceItemsIDS = formValues[DOCUMENT_ITEMS_IDS][invoiceID];

			const itemFieldsIDS = invoiceItemsIDS.map((billingItemId) => {
				const itemFieldID = `${invoiceID}_${billingItemId}`;
				let amount = getItemCreditDebitNoRound(formValues, itemFieldID);

				if (mode !== CREATE) {
					const id = formValues[MAP_ITEM_ID_BILLING_ITEM_ID][billingItemId];
					creditedDebitItemsDTO.push({ amount, billingItemId, id });
				} else {
					creditedDebitItemsDTO.push({ amount, billingItemId });
				}
			});
		}

		if (documentType === CREDIT_NOTE) {
			commonDTO.creditedItems = [...creditedDebitItemsDTO];
			commonDTO.creditReason = formValues[INVOICE_FIELD_NAME.CREDIT_REASON];
		}

		if (documentType === DEBIT_NOTE) {
			commonDTO.debitedItems = [...creditedDebitItemsDTO];
		}

		commonDTO.documentReason = formValues[DOCUMENT_REASON];

		commonDTO.localizations = localisations;

		return commonDTO;
	};

	getInvoiceNumber(dto, documentType, actionType, formValues) {
		if (
			documentType === BILLING_DOCUMENT_TYPES.FREE_INVOICE ||
			documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			return dto;
		}
		const number = formValues[INVOICE_FIELD_NAME.INVOICE_NUMBER];
		if (actionType === SUBMIT_TYPE.CREATE) {
			dto.numberDraft = number;
		} else {
			dto.number = number;
		}

		return dto;
	}

	onFormSubmit = (formValues) => {
		const actionType = this.state.clickedButtonSubmitType;

		const { FREE_INVOICE, ORDER_INVOICE, CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;
		const { documentType } = this.props;
		const { mode } = this.state;

		if (actionType === SUBMIT_TYPE.SAVE_LOCALES) {
			const { documentLanguage } = this.props;
			const localizationData = this.props.currentDocument?.localizations;
			let itemsIDS = undefined;

			if (documentType === FREE_INVOICE) {
				itemsIDS = formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS];
			}

			const locales = this.getLocalisationDto({
				formValues,
				documentType,
				documentLanguage,
				localizationData,
				itemsIDS
			});

			this.props.updateInvoiceLocales(this.props.currentDocument.id, locales);
		} else {
			if (documentType === FREE_INVOICE) {
				const productsIndexes = formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS];

				if (!productsIndexes.length) {
					showErrorPopup(
						i18n.t('translation:billingModule.invoicing.errors.productsNotAdded')
					);
				} else {
					this.submitFreeInvoice(actionType, documentType, mode, formValues);
				}
				return;
			}

			if (documentType === ORDER_INVOICE) {
				this.submitOrderInvoice(actionType, documentType, mode, formValues);
				return;
			}

			if (documentType === CREDIT_NOTE || documentType === DEBIT_NOTE) {
				this.submitCreditDebitInvoice(actionType, documentType, mode, formValues);
			}
		}
	};

	submitCreditDebitInvoice(actionType, documentType, mode, formValues) {
		let dto = this.getCreditDebitInvoiceDto(formValues, actionType, documentType, mode);

		dto = this.getInvoiceNumber(dto, documentType, actionType, formValues);

		if (mode === BILLING_DOCUMENT_MODE.CREATE) {
			const invoicesIDS = formValues[INVOICE_FIELD_NAME.DOCUMENT_INVOICES_IDS];
			let creditDebitNoteHasOneCreditedItem = false;

			for (let invoiceID of invoicesIDS) {
				const itemFieldsIDS = formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS][invoiceID];

				for (let i = 0; i < itemFieldsIDS.length; i++) {
					const itemID = itemFieldsIDS[i];
					const itemFieldID = `${invoiceID}_${itemID}`;
					const itemCreditDebitNoRound =
						formValues[
							`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_NO_ROUND_}${itemFieldID}`
						];

					if (itemCreditDebitNoRound) {
						creditDebitNoteHasOneCreditedItem = true;
						i = itemFieldID.length;
					}
				}
			}

			if (!creditDebitNoteHasOneCreditedItem) {
				const errorMessage =
					documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE
						? i18n.t(
								'translation:billingModule.invoicing.errors.creditItemCreditNotAdded'
						  )
						: i18n.t(
								'translation:billingModule.invoicing.errors.debitItemDebitNotAdded'
						  );
				showErrorPopup(errorMessage);
				return;
			} else {
				this.props.createInvoice(dto, (response) =>
					this.postInvoiceCallback(response, actionType)
				);
			}
		} else {
			this.props.updateInvoice(this.props.currentDocument.id, dto, (response) =>
				this.postInvoiceCallback(response, actionType)
			);
		}
	}

	submitOrderInvoice = (actionType, documentType, mode, formValues) => {
		const documentLanguage = this.props.documentLanguage;
		const { CREATE } = BILLING_DOCUMENT_MODE;

		let dto = this.getOrderInvoiceDto(formValues, actionType, mode, documentType);
		dto = this.getInvoiceNumber(dto, documentType, actionType, formValues);

		const langAbbreviations = getSecondThirdLangAbbreviation(documentLanguage);

		const localizationMissing = isLocalizationMissing(
			formValues,
			documentType,
			langAbbreviations
		);

		if (localizationMissing && mode === CREATE) {
			this.renderLocalizationMissingMessage(dto, actionType);
		} else {
			if (!localizationMissing) {
				dto.localizations = this.getLocalisationDto({
					formValues,
					documentType,
					documentLanguage,
					localizationData: this.props.currentDocument?.localizations,
					itemsIDS: undefined
				});
			}
			if (mode === CREATE) {
				this.props.createInvoice(dto, (response) =>
					this.postInvoiceCallback(response, actionType)
				);
			} else {
				this.props.updateInvoice(this.props.currentDocument.id, dto, (response) =>
					this.postInvoiceCallback(response, actionType)
				);
			}
		}
	};

	renderLocalizationMissingMessage(dto, actionType) {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.hideMessageModal();
					this.props.createInvoice(dto, (response) =>
						this.postInvoiceCallback(response, actionType)
					);
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => this.props.hideMessageModal()}
				secondary
			/>
		);

		this.props.showMessageModal(
			i18n.t('translation:common.warning'),
			i18n.t('translation:billingModule.invoicing.table.localization.localizationModal'),
			[yesButton, noButton],
			false,
			true
		);
	}

	submitFreeInvoice = (actionType, documentType, mode, formValues) => {
		const { CREATE } = BILLING_DOCUMENT_MODE;
		const itemsIDS = formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS];
		const { documentLanguage } = this.props;

		let dto = this.getFreeInvoiceDTO(formValues, actionType, itemsIDS);

		const langAbbreviations = getSecondThirdLangAbbreviation(documentLanguage);

		const localizationMissing = isLocalizationMissing(
			formValues,
			documentType,
			langAbbreviations
		);

		if (localizationMissing && mode === CREATE) {
			this.renderLocalizationMissingMessage(dto, actionType);
		} else {
			if (!localizationMissing) {
				dto.localizations = this.getLocalisationDto({
					formValues,
					documentType,
					documentLanguage,
					localizationData: this.props.currentDocument?.localizations,
					itemsIDS
				});
			}

			if (mode === CREATE) {
				this.props.createInvoice(dto, (response) =>
					this.postInvoiceCallback(response, actionType)
				);
			} else {
				this.props.updateInvoice(this.props.currentDocument.id, dto, (response) =>
					this.postInvoiceCallback(response, actionType)
				);
			}
		}
	};

	setInvoiceNumber() {
		this.props.getNextDocumentNumber(this.props.account.labId, (number) => {
			this.props.change(INVOICE_FIELD_NAME.INVOICE_NUMBER, number);
			this.props.hideMessageModal();
			showSuccessPopup(
				i18n.t(
					'translation:billingModule.invoicing.successMessages.documentNumberSetToNext'
				)
			);
		});
	}

	showInvoiceNumberAlreadyTakenMessage() {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => this.setInvoiceNumber()}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => this.props.hideMessageModal()}
				secondary
			/>
		);
		this.props.showMessageModal(
			i18n.t('translation:common.errors.error'),
			i18n.t('translation:billingModule.invoicing.errors.invoiceNumberAlreadyTaken'),
			[yesButton, noButton],
			false,
			true
		);
	}

	postInvoiceCallback = ({ error, data }, actionType) => {
		if (error) {
			if (error.message === ERROR_MESSAGES.NUMBER_AND_ISSUE_DATE_NOT_UNIQUE) {
				this.showInvoiceNumberAlreadyTakenMessage();
				return;
			}
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				error.userMessage,
				[],
				false,
				true
			);
			return;
		}

		const mode =
			this.state.clickedButtonSubmitType === SUBMIT_TYPE.CREATE
				? BILLING_DOCUMENT_MODE.EDIT
				: BILLING_DOCUMENT_MODE.PREVIEW;

		this.showCreateEditSuccessMessage(data.data, actionType);
		this.props.onDocumentChange && this.props.onDocumentChange();

		this.setState(
			{
				clickedButtonSubmitType: undefined,
				isExportPdfModalOpen: false,
				dueDateInitial: null,
				isInitialized: true,
				selectedDocumentId: data.data,
				mode: mode
			},
			() => this.componentDidMountOnPreviewEditMode(data.data)
		);
	};

	getCreateSuccessMessage(id, actionType) {
		const { FREE_INVOICE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;
		const { documentType } = this.props;

		let documentNumber = ' ';

		if (
			documentType === FREE_INVOICE ||
			documentType === DEBIT_NOTE ||
			actionType === SUBMIT_TYPE.CREATE
		) {
			documentNumber = `${id} (${i18n.t(
				'translation:billingModule.invoicing.common.draft'
			)})`;
		} else {
			documentNumber = this.props.formValues.invoiceNumber;
		}
		return i18n.t('translation:billingModule.invoicing.successMessages.createInvoice', {
			documentNumber: documentNumber
		});
	}

	getEditSuccessMessage() {
		const { FREE_INVOICE, ORDER_INVOICE, DEBIT_NOTE, CREDIT_NOTE } = BILLING_DOCUMENT_TYPES;
		const { documentType } = this.props;
		if (documentType === FREE_INVOICE) {
			return i18n.t('translation:billingModule.invoicing.successMessages.updateFreeInvoice');
		}

		if (documentType === DEBIT_NOTE) {
			return i18n.t('translation:billingModule.invoicing.successMessages.updateDebitInvoice');
		}

		if (documentType === ORDER_INVOICE) {
			return i18n.t('translation:billingModule.invoicing.successMessages.updateOrderInvoice');
		}
		if (documentType === CREDIT_NOTE) {
			return i18n.t(
				'translation:billingModule.invoicing.successMessages.updateCreditInvoice'
			);
		}
	}

	showCreateEditSuccessMessage(id, actionType) {
		const mode = this.state.mode;
		const { CREATE } = BILLING_DOCUMENT_MODE;

		const message =
			mode === CREATE
				? this.getCreateSuccessMessage(id, actionType)
				: this.getEditSuccessMessage();

		showSuccessPopup(message);
	}

	closeModal = () => {
		this.props.onModalClose();
	};

	onCloseButtonClick = () => {
		const yesButton = (
			<DefaultButton
				type="button"
				title={i18n.t('translation:common.buttons.yes')}
				onClick={this.onYesButtonClick}
			/>
		);

		const noButton = (
			<DefaultButton
				type="button"
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => this.props.hideMessageModal()}
				secondary
			/>
		);

		this.props.showMessageModal(
			i18n.t('translation:common.warning'),
			i18n.t('translation:billingModule.invoicing.warnings.cancelInvoice'),
			[yesButton, noButton],
			false,
			true
		);
	};

	onYesButtonClick = () => {
		//this.clearForm();
		this.props.hideMessageModal();
		this.closeModal();
	};

	// This is not needed, consider to delete
	clearForm = () => {
		const { change, untouch, documentType, invoiceData } = this.props;
		const { FREE_INVOICE, ORDER_INVOICE } = BILLING_DOCUMENT_TYPES;
		const nowDate = new Date();

		const {
			RECIPIENT_COMPANY_NAME,
			RECIPIENT_UIC,
			RECIPIENT_VAT_NUMBER,
			RECIPIENT_REGISTRATION_ADDRESS,
			RECIPIENT_RESPONSIBLE_PERSON,
			DOCUMENT_ITEMS_IDS,
			DOCUMENT_DISCOUNT_WITH_TAX,
			DOCUMENT_DISCOUNT_TAX_ROUNDED,
			DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND,
			DOCUMENT_DISCOUNT_NO_TAX_ROUND,
			DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED,
			DOCUMENT_TOTAL_WITH_TAX,
			DOCUMENT_TOTAL_TAX,
			DOCUMENT_TOTAL_NO_TAX,
			DOCUMENT_DISCOUNT_NOTE,
			SHOW_IN_INVOICE,
			VERBALLY,
			NOTES,
			DUE_DATE,
			DATE_OF_ISSUE,
			DATE_OF_TAX_EVENT,
			EXCLUDE_TAX,
			REASON_FOR_TAX_EXCLUDE,
			ITEM_NAME_,
			ITEM_UNIT_,
			ITEM_QUANTITY_,
			ITEM_UNIT_PRICE_NO_TAX_,
			ITEM_TAX_PERCENT_TAX_NAME_,
			ITEM_TOTAL_PRICE_WITH_TAX_,
			ITEM_ADVANCE_NO_TAX_,
			ORDER_INVOICING_METHOD_,
			ORDER_PERCENT_FOR_INVOICING_ROUND_,
			ORDER_PERCENT_FOR_INVOICING_,
			ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_,
			ORDER_FIXED_AMOUNT_FOR_INVOICING_,
			ORDER_TOTAL_ADVANCE_WITH_TAX_,
			ORDER_UN_INVOICED_AMOUNT_IN_PERCENTAGE_,
			ORDER_TOTAL_TAX_
		} = INVOICE_FIELD_NAME;

		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);

		const {
			COMPOSED_BY,
			RECEIVER_NAME,
			RECEIVER_ADDRESS,
			RECEIVER_RESPONSIBLE_PERSON,
			SUPPLIER_NAME,
			SUPPLIER_ADDRESS,
			SUPPLIER_RESPONSIBLE_PERSON,
			ITEM_NAME,
			VERBALLY: LOC_VERBALLY,
			NOTES: LOC_NOTES,
			DISCOUNT_DESCRIPTION,
			TAX_NOT_INCLUDED_REASON,
			BANK_NAME
		} = INVOICE_FIELD_NAME.LOCALIZATION;

		let fieldNames = [];

		for (let abbreviation of langAbbreviations) {
			fieldNames.push(COMPOSED_BY[abbreviation]);
			fieldNames.push(RECEIVER_NAME[abbreviation]);
			fieldNames.push(RECEIVER_ADDRESS[abbreviation]);
			fieldNames.push(RECEIVER_RESPONSIBLE_PERSON[abbreviation]);
			fieldNames.push(SUPPLIER_NAME[abbreviation]);
			fieldNames.push(SUPPLIER_ADDRESS[abbreviation]);
			fieldNames.push(SUPPLIER_RESPONSIBLE_PERSON[abbreviation]);
			fieldNames.push(LOC_VERBALLY[abbreviation]);
			fieldNames.push(LOC_NOTES[abbreviation]);
			fieldNames.push(DISCOUNT_DESCRIPTION[abbreviation]);
			fieldNames.push(TAX_NOT_INCLUDED_REASON[abbreviation]);
			fieldNames.push(BANK_NAME[abbreviation]);
		}

		fieldNames.push(TRANSACTION_FORM_FIELD.TRANSACTION_METHOD);
		fieldNames.push(DOCUMENT_DISCOUNT_WITH_TAX);
		fieldNames.push(DOCUMENT_DISCOUNT_TAX_ROUNDED);
		fieldNames.push(DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND);
		fieldNames.push(DOCUMENT_DISCOUNT_NO_TAX_ROUND);
		fieldNames.push(DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED);
		// fieldNames.push(DOCUMENT_TOTAL_TAX);
		fieldNames.push(DOCUMENT_DISCOUNT_NOTE);
		fieldNames.push(VERBALLY);
		fieldNames.push(NOTES);
		fieldNames.push(EXCLUDE_TAX);
		fieldNames.push(REASON_FOR_TAX_EXCLUDE);

		if (documentType === FREE_INVOICE) {
			fieldNames.push(RECIPIENT_COMPANY_NAME);
			fieldNames.push(RECIPIENT_UIC);
			fieldNames.push(RECIPIENT_VAT_NUMBER);
			fieldNames.push(RECIPIENT_REGISTRATION_ADDRESS);
			fieldNames.push(RECIPIENT_RESPONSIBLE_PERSON);

			const itemsIDS = [].concat(getDocumentItemsIDS(this.props.formValues));

			itemsIDS.forEach((itemId) => {
				fieldNames.push(`${ITEM_NAME_}${itemId}`);
				fieldNames.push(`${ITEM_UNIT_}${itemId}`);
				fieldNames.push(`${ITEM_QUANTITY_}${itemId}`);
				fieldNames.push(`${ITEM_UNIT_PRICE_NO_TAX_}${itemId}`);
				fieldNames.push(`${ITEM_TOTAL_PRICE_WITH_TAX_}${itemId}`);
				fieldNames.push(`${ITEM_TAX_PERCENT_TAX_NAME_}${itemId}`);

				for (let abbreviation of langAbbreviations) {
					fieldNames.push(`${ITEM_NAME[abbreviation]}${itemId}`);
				}
			});
		}

		if (documentType === ORDER_INVOICE) {
			invoiceData.forEach((order) => {
				fieldNames.push(`${ORDER_INVOICING_METHOD_}${order.orderId}`);
				fieldNames.push(`${ORDER_PERCENT_FOR_INVOICING_ROUND_}${order.orderId}`);
				fieldNames.push(`${ORDER_PERCENT_FOR_INVOICING_}${order.orderId}`);
				fieldNames.push(`${ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_}${order.orderId}`);
				fieldNames.push(`${ORDER_FIXED_AMOUNT_FOR_INVOICING_}${order.orderId}`);

				order.items.forEach((item) => {
					change(`${ITEM_TOTAL_PRICE_WITH_TAX_}${item.orderItemId}`);
					change(`${ITEM_ADVANCE_NO_TAX_}${item.orderItemId}`);
				});

				change(`${ORDER_TOTAL_ADVANCE_WITH_TAX_}${order.orderId}`, 0);
				change(`${ORDER_TOTAL_TAX_}${order.orderId}`, 0);
				change(`${DOCUMENT_TOTAL_TAX}`, 0);
				change(`${DOCUMENT_TOTAL_NO_TAX}`, 0);
				change(`${DOCUMENT_TOTAL_WITH_TAX}`, 0);
				change(`${DOCUMENT_DISCOUNT_WITH_TAX}`, 0);
			});
		}

		change(DATE_OF_TAX_EVENT, nowDate);
		change(DATE_OF_ISSUE, nowDate);
		change(SHOW_IN_INVOICE, true);
		fieldNames.forEach((fieldName) => {
			change(fieldName, '');
		});

		if (documentType === FREE_INVOICE) {
			change(DOCUMENT_ITEMS_IDS, []);
			change(DUE_DATE, '');
		}

		if (documentType === ORDER_INVOICE) {
			change(DUE_DATE, this.state.dueDateInitial);
		}
		untouch(...fieldNames);
	};

	clearReduxForm = () => {
		this.props.reset();
	};
}

const validate = (state, props) => {
	const taxType = props.taxType;
	const values = state;
	const documentType = values.documentType;
	const { FREE_INVOICE, ORDER_INVOICE, CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

	if (documentType === FREE_INVOICE) {
		return formUtils.validateFreeInvoice(values, taxType, props.touch, props.country);
	}

	if (documentType === ORDER_INVOICE) {
		return formUtils.validateOrderInvoice(values, taxType, props.touch, props.country);
	}

	if (documentType === CREDIT_NOTE || documentType === DEBIT_NOTE) {
		return formUtils.validateCreditDebitInvoice(
			values,
			taxType,
			props.touch,
			props.country,
			documentType
		);
	}

	return {};
};

const freeInvoiceForm = reduxForm({
	form: 'freeInvoiceForm',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validate
})(BillingDocumentModal);

const mapStateToProps = (state, props) => {
	return {
		nextDocumentNumber: state.billingDocument.nextDocumentNumber,
		account: state.account,
		ordersCurrentPage: state.pagination[PAGINATION_TYPES.BILLING_ORDERS],
		documentsCurrentPage: state.pagination[PAGINATION_TYPES.BILLING_DOCUMENTS],
		country: state.settings.language,
		currentLab: state.labs.currentLab,
		taxGroups: state.billingSettings.taxGroups,
		documentLanguage: state.billingDocument.currentDocument?.language || loadLanguage(),
		taxType: state.billingSettings.taxType,
		invoiceData: state.billingDocument.invoiceData,
		formValues: getFormValues('freeInvoiceForm')(state),
		createInvoiceFormValues: getFormValues('createInvoiceSetDetailsFiltersForm')(state),
		initialValues: getInitialValues(state, props),
		currentDocument: state.billingDocument.currentDocument
	};
};

export default connect(mapStateToProps, {
	initialize,
	createInvoice,
	updateInvoice,
	updateInvoiceLocales,
	clearCurrentClinic,
	getNextDocumentNumber,
	getOrderInvoiceData,
	getCreditOrderInvoiceData,
	getBillingOrders,
	getBillingDocuments,
	clearPreCreateModalDocumentReducer,
	getBillingDocumentDetails,
	getLabDetails,
	showMessageModal,
	hideMessageModal,
	rejectBillingDocument
})(freeInvoiceForm);

const nowDate = new Date();

const getExcludeTaxAndReason = (state, props) => {
	const mode = props.mode;

	let excludedTax = false;
	let excludeTaxReason = '';

	if (mode !== BILLING_DOCUMENT_MODE.CREATE) {
		excludedTax = state.billingDocument.currentDocument
			? !state.billingDocument.currentDocument?.taxIncluded
			: false;
		excludeTaxReason = state.billingDocument.currentDocument?.taxNotIncludedReason;

		return {
			excludedTax,
			excludeTaxReason
		};
	}

	return {
		excludedTax,
		excludeTaxReason
	};
};

const getInitialValues = (state, props) => {
	const account = state.account;
	const currentLab = state.labs.currentLab;
	const nextDocumentNumber = state.billingDocument.nextDocumentNumber;

	const { firstName, lastName } = account;
	const { legalName, registeredOffice, uic, vatNumber, owner, iban, bic, bankName } = currentLab;

	const initialValues = {};

	const { excludedTax, excludeTaxReason } = getExcludeTaxAndReason(state, props);

	if (nextDocumentNumber) {
		initialValues[INVOICE_FIELD_NAME.INVOICE_NUMBER] = nextDocumentNumber;
	}

	initialValues[INVOICE_FIELD_NAME.DATE_OF_ISSUE] = nowDate;
	initialValues[INVOICE_FIELD_NAME.DATE_OF_TAX_EVENT] = nowDate;
	initialValues[INVOICE_FIELD_NAME.COMPOSED_BY] = `${firstName} ${lastName}`;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_ID] = state.account.labId;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_COMPANY_NAME] = legalName;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_UIC] = uic;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_VAT_NUMBER] = vatNumber;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_REGISTRATION_ADDRESS] = registeredOffice;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_RESPONSIBLE_PERSON] = owner;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_IBAN] = iban;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_BIC] = bic;
	initialValues[INVOICE_FIELD_NAME.SUPPLIER_BANK_NAME] = bankName;
	initialValues[INVOICE_FIELD_NAME.IBAN] = iban;
	initialValues[INVOICE_FIELD_NAME.BIC] = bic;
	initialValues[INVOICE_FIELD_NAME.BANK_NAME] = bankName;
	initialValues[`${INVOICE_FIELD_NAME.ITEM_AMOUNT_NO_TAX_}-1`] = 0;
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX] = 0;
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_ROUNDED] = 0;
	initialValues[INVOICE_FIELD_NAME.SHOW_IN_INVOICE] = true;
	initialValues[`${INVOICE_FIELD_NAME.ITEM_NAME_}-1`] = '';
	initialValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_}-1`] = '';
	initialValues[`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}-1`] = '';
	initialValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}-1`] = '';
	initialValues[`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}-1`] = ' _ ';
	initialValues[`${INVOICE_FIELD_NAME.ITEM_TOTAL_PRICE_WITH_TAX_}-1`] = 0;
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS] = [];
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS] = [];
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_INVOICES_IDS] = [];
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX] = 0;
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_TAX] = 0;
	initialValues[INVOICE_FIELD_NAME.EXCLUDE_TAX] = excludedTax;
	initialValues[INVOICE_FIELD_NAME.REASON_FOR_TAX_EXCLUDE] = excludeTaxReason;
	initialValues[INVOICE_FIELD_NAME.DOCUMENT_TYPE] = '';
	initialValues[INVOICE_FIELD_NAME.CREDIT_REASON] = 'CORRECT_ORDER';

	return initialValues;
};
