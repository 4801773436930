import React, { Fragment, useEffect, useState } from 'react';
import Table from '../../../../common/Table';
import i18n from '../../../../../i18n';
import SubHeader from '../../../../common/SubHeader';
import ShiftsTypesModal from './ShiftsTypesModal';
import DefaultButton from '../../../../common/DefaultButton';
import { getShifts } from '../../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { parseShifts } from '../../../../../utils/tableUtils';
import { isEmptyObject, isGlobalAdmin, isLabTechnician } from '../../../../../utils/appUtils';
import { reset } from 'redux-form';

const ShiftTypes = (props) => {
	const account = useSelector((state) => state.account);
	const shifts = useSelector((state) => state.shifts);
	const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState();
	const [shift, setShift] = useState();

	const openModalWithInitialization = (shift) => {
		setShift(shift);
		setIsModalOpen(true);
	};

	useEffect(() => {
		const labId = account.labId ? account.labId : props.labId;

		if (!isLabTechnician(account)) {
			dispatch(getShifts(labId));
		}
	}, []);

	const renderTable = () => {
		const labId = account.labId ? account.labId : props.labId;

		const data = parseShifts(shifts, labId, openModalWithInitialization);

		const columns = [
			i18n.t('translation:taskManager.planner.table.name'),
			i18n.t('translation:taskManager.common.table.code'),
			i18n.t('translation:taskManager.common.table.start'),
			i18n.t('translation:taskManager.common.table.end'),
			i18n.t('translation:taskManager.common.table.time'),
			i18n.t('translation:taskManager.common.table.breakDuration'),
			i18n.t('translation:common.table.action')
		];

		const isReadOnly = isGlobalAdmin(account);
		const readOnlyClass = isReadOnly ? ' read-only-field' : '';

		return <Table className={'mt-s' + readOnlyClass} columns={columns} data={data} />;
	};

	const renderSubHeader = () => {
		const title = i18n.t('translation:taskManager.planner.shiftTypes');

		const addShiftTypeButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				key={i18n.t('translation:common.buttons.add')}
				onClick={() => {
					setIsModalOpen(true);
				}}
			/>
		);

		const isReadOnly = isGlobalAdmin(account);
		const readOnlyClass = isReadOnly ? ' read-only-field' : '';

		return <SubHeader readOnly={readOnlyClass} title={title} buttons={[addShiftTypeButton]} />;
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setShift({});
		dispatch(reset('shiftsTypesForm'));
	};

	const renderShiftsTypesModal = () => {
		const initialValues = { ...shift };
		if (!isEmptyObject(initialValues)) {
			const startTime = initialValues.startTime.split(':');
			const endTime = initialValues.endTime.split(':');
			initialValues.startTime = new Date().setHours(startTime[0], startTime[1]);
			initialValues.endTime = new Date().setHours(endTime[0], endTime[1]);
			initialValues.hours = initialValues.breakDuration.split(':')[0];
			initialValues.minutes_0 = initialValues.breakDuration.split(':')[1];
		}

		return (
			<ShiftsTypesModal
				isModalOpen={isModalOpen}
				closeModal={closeModal}
				initialValues={initialValues}
			/>
		);
	};

	if (isLabTechnician(account)) {
		return <Fragment />;
	}

	return (
		<Fragment>
			{renderShiftsTypesModal()}
			{renderSubHeader()}
			{renderTable()}
		</Fragment>
	);
};

export default ShiftTypes;
