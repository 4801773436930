import React from 'react';
import { TOOTH_TYPES } from '../../../utils/constants';
import noTooth from '../../../resources/icons/no-tooth.svg';
import implant from '../../../resources/icons/implant.svg';

class TeethMask extends React.Component {
	render() {
		return (
			<div className="teeth-mask">
				{this.renderImplant()}
				{this.renderMissingTooth()}
				{this.renderMark()}
				{this.renderMilkTeethButton()}
				{this.renderPermanentTeethButton()}
			</div>
		);
	}

	renderMissingTooth = () => {
		const data = this.props.toothData;
		if (data && data.type === TOOTH_TYPES.MISSING_TOOTH) {
			const maskStyle = this.props.firstRow ? 'no-tooth top-mask' : 'no-tooth bottom-mask';
			return (
				<div className={maskStyle}>
					<img src={noTooth} alt={'nothing'} />
				</div>
			);
		}
	};

	renderImplant = () => {
		const data = this.props.toothData;
		if (data && data.type === TOOTH_TYPES.IMPLANT) {
			const maskStyle = this.props.firstRow ? 'implant top-mask' : 'implant bottom-mask';
			return (
				<div className={maskStyle}>
					<img src={implant} alt={'implant'} />
				</div>
			);
		}
	};

	renderMark = () => {
		if (this.props.isActive) {
			const maskStyle = this.props.isTop ? 'active-mask top-mask' : 'active-mask bottom-mask';
			return <div className={maskStyle} />;
		}
	};

	renderPermanentTeethButton = () => {
		if (this.props.permanentTeeth) {
			const data = this.props.toothData;
			const buttonStyle =
				data && !data.isMilkTooth ? 'teeth-button active-teeth-button' : 'teeth-button';
			return (
				<div
					className={buttonStyle}
					onClick={(e) => {
						this.props.onClick(
							e,
							false,
							this.props.permanentTeeth,
							this.props.milkTeeth
						);
					}}>
					<span>{this.props.permanentTeeth}</span>
				</div>
			);
		}
	};

	renderMilkTeethButton = () => {
		if (this.props.milkTeeth) {
			const data = this.props.toothData;
			const buttonStyle =
				data && data.isMilkTooth
					? 'teeth-button m-xxs active-teeth-button'
					: 'teeth-button m-xxs';
			return (
				<div
					className={buttonStyle}
					onClick={(e) => {
						this.props.onClick(
							e,
							true,
							this.props.milkTeeth,
							this.props.permanentTeeth
						);
					}}>
					<span>{this.props.milkTeeth}</span>
				</div>
			);
		}
	};
}

export default TeethMask;
