import React, { Fragment } from 'react';
import Header from '../../../../common/Header';
import i18n from '../../../../../i18n';
import SubHeader from '../../../../common/SubHeader';
import Filters from './Filters';
import StaffChart from '../staff/StaffChart';
import {
	parseStaffTasksChartDays,
	parseStaffTasksChartHours,
	parseStaffTasksNestedTable
} from '../../../../../utils/tableUtils';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	DEFAULT_USER_DATE_FORMAT,
	FILTER_FIELDS,
	STAFFCHART_MODES,
	STATISTIC_TYPES
} from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import { getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import {
	getCalendarStaff,
	getCalendarStaffTasks,
	getDashboardEmployeeTasks,
	getGeneralWorkingHours,
	showMessageModal
} from '../../../../../actions';
import {
	faBan,
	faHourglassEnd,
	faHourglassHalf,
	faHourglassStart,
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAllNull, isEmptyObject, parseFlagToLanguage } from '../../../../../utils/appUtils';
import StatisticsItem from '../../../dashboards/StatisticsItem';
import Badge from '../../../../common/Badge';

class DentalTechnicianCalendarDashboard extends React.Component {
	componentDidMount() {
		const startDate = moment(this.state.date)
			.startOf('week')
			.format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
		this.props.getCalendarStaffTasks(
			this.props.account.labId,
			{
				startDate,
				endDate
			},
			this.props.account.id,
			false,
			true
		);
		this.props.getGeneralWorkingHours(this.props.account.labId);
		this.props.getDashboardEmployeeTasks();
	}

	state = {
		dropdown: {},
		date: moment(),
		mode: STAFFCHART_MODES.WEEKS,
		filters: {},
		currentTask: {},
		isEditTaskModalOpen: false
	};

	render() {
		const columns = [
			i18n.t('translation:taskManager.common.table.task'),
			i18n.t('translation:taskManager.common.table.start'),
			i18n.t('translation:taskManager.common.table.end'),
			i18n.t('translation:common.table.status'),
			''
		];

		moment.locale(parseFlagToLanguage(this.props.language), { week: { dow: 1, doy: 1 } });

		const nestedTableData = parseStaffTasksNestedTable(
			this.props.staff,
			this.openEditTaskModal
		);
		const weekends = this.props.workingHours
			.filter((day) => day.startTime === null)
			.map((day) => day.dayOfTheWeek);
		const chartData =
			this.state.mode === STAFFCHART_MODES.WEEKS
				? parseStaffTasksChartDays(this.props.staff)
				: parseStaffTasksChartHours(this.props.staff);
		const subTitleForCurrentDay = i18n.t('translation:taskManager.calendar.dashboard.dayTasks');
		const subTitleForCurrentMonth = i18n.t(
			'translation:taskManager.calendar.dashboard.monthTasks'
		);

		return (
			<Fragment>
				<Header title={i18n.t('translation:taskManager.calendar.dashboard.myCalendar')} />
				{this.renderSubHeader(subTitleForCurrentDay, true)}
				<div className="statistics dashboard-statistics mb-m p-0">
					{this.renderStatisticBadges()}
				</div>
				{this.renderSubHeader(subTitleForCurrentMonth, false)}
				<div className="mt-s">
					<Filters setFilters={this.setFilters} />
				</div>

				<div className="position-relative mt-s mb-m">
					<div className="position-absolute staffchart d-flex flex-wrap lab-technician-chart">
						<StaffChart
							nestedTableData={nestedTableData}
							mode={this.state.mode}
							columns={columns}
							triggerMode={this.triggerMode}
							onAddWeek={this.onAddWeek}
							onSubtractWeek={this.onSubtractWeek}
							onAddDay={this.onAddDay}
							onSubtractDay={this.onSubtractDay}
							chartData={chartData}
							weekends={weekends}
							labTechnician={true}
							filters={this.state.filters}
							currentTask={this.state.currentTask}
							isEditTaskModalOpen={this.state.isEditTaskModalOpen}
							closeEditTaskModal={this.closeEditTaskModal}
							singleEmployee
						/>
						{this.renderBadges()}
					</div>
				</div>
			</Fragment>
		);
	}

	renderSubHeader = (subTitle, isBadgeShown) => {
		const badge = isBadgeShown ? (
			<Badge
				backgroundColor={'eaf1f9'}
				className="dark-text-span"
				name={this.state.date.format(DEFAULT_USER_DATE_FORMAT)}
			/>
		) : null;

		const title = (
			<div className="d-flex align-items-center">
				<h2 className="mr-xs ml-xs">{subTitle}</h2>
				{badge}
			</div>
		);

		return <SubHeader title={title} buttons={[]} />;
	};

	renderStatisticBadges() {
		if (this.props.statisticTasks) {
			return Object.keys(this.props.statisticTasks).map((item, index) => {
				return (
					<StatisticsItem
						description={this.getDescription(item)}
						key={index}
						value={this.props.statisticTasks[item].length}
						icon={this.getIcon(item)}
						onClick={() => this.filterByTaskIds(this.props.statisticTasks[item])}
					/>
				);
			});
		}
	}

	filterByTaskIds = (statisticTasks) => {
		if (statisticTasks.length > 0) {
			this.props.reset('calendarStaffFiltersForm');

			this.setFilters({
				[FILTER_FIELDS.TASK_ID_LIST]: statisticTasks
			});
		}
	};

	getIcon = (type) => {
		const icons = {
			[STATISTIC_TYPES.OVERDUE_TASKS]: faHourglassEnd,
			[STATISTIC_TYPES.DUE_TODAY_TASKS]: faHourglassHalf,
			[STATISTIC_TYPES.DUE_TOMORROW_TASKS]: faHourglassStart,
			[STATISTIC_TYPES.BLOCKED_TASKS]: faBan
		};
		return icons[type];
	};

	getDescription = (type) => {
		const description = {
			overdueTasks: i18n.t('translation:taskManager.calendar.dashboard.overdueCount'),
			dueTodayTasks: i18n.t('translation:taskManager.calendar.dashboard.dueTodayCount'),
			dueTomorrowTasks: i18n.t('translation:taskManager.calendar.dashboard.dueTomorrowCount'),
			blockedTasks: i18n.t('translation:taskManager.calendar.dashboard.blockedCount')
		};
		return description[type];
	};

	openEditTaskModal = (currentTask) => {
		this.setState({ isEditTaskModalOpen: true, currentTask: currentTask });
	};

	closeEditTaskModal = () => {
		this.setState({ isEditTaskModalOpen: false, currentTask: {} });
	};

	setFilters = (filters) => {
		const refinedFilters = { ...filters };
		refinedFilters[FILTER_FIELDS.LAB_ORDER_ID_LIST] = refinedFilters[
			FILTER_FIELDS.LAB_ORDER_ID_LIST
		]
			? refinedFilters[FILTER_FIELDS.LAB_ORDER_ID_LIST].split(',')
			: null;
		if (this.state.mode === STAFFCHART_MODES.WEEKS) {
			let startDate = moment(this.state.date)
				.startOf('week')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			let endDate = moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);

			if (!isEmptyObject(refinedFilters) && !isAllNull(refinedFilters)) {
				startDate = refinedFilters.startDate
					? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: null;
				endDate = refinedFilters.endDate
					? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: null;
			}

			this.props.getCalendarStaffTasks(
				this.props.account.labId,
				{
					...refinedFilters,
					startDate: startDate,
					endDate: endDate
				},
				this.props.account.id,
				false,
				true
			);
			this.setState({ filters: refinedFilters, dropdown: [] });
		} else {
			if (this.isFilterRangeIsDay(refinedFilters)) {
				let startDate = moment(this.state.date)
					.startOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);
				let endDate = moment(this.state.date)
					.endOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);

				if (!isEmptyObject(refinedFilters) && !isAllNull(refinedFilters)) {
					startDate = refinedFilters.startDate
						? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
					endDate = refinedFilters.endDate
						? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
				}

				this.props.getCalendarStaffTasks(
					this.props.account.labId,
					{
						...refinedFilters,
						startDate: startDate,
						endDate: endDate
					},
					this.props.account.id,
					true,
					true
				);
				this.setState({ filters: refinedFilters, dropdown: [] });
			} else {
				this.props.showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:taskManager.calendar.staff.rangeOverOneDayWarning')
				);
			}
		}
	};

	onAddWeek = (startDate, endDate) => {
		const date = this.state.date.add(1, 'W');
		this.props.getCalendarStaffTasks(
			this.props.account.labId,
			{
				...this.state.filters,
				startDate,
				endDate
			},
			this.props.account.id,
			false,
			true
		);
		this.setState({ dropdown: {}, date });
	};

	onSubtractWeek = (startDate, endDate) => {
		const date = this.state.date.subtract(1, 'W');
		this.props.getCalendarStaffTasks(
			this.props.account.labId,
			{
				...this.state.filters,
				startDate,
				endDate
			},
			this.props.account.id,
			false,
			true
		);
		this.setState({ dropdown: {}, date });
	};

	onAddDay = (startDate, endDate) => {
		const date = this.state.date.add(1, 'days');
		this.props.getCalendarStaffTasks(
			this.props.account.labId,
			{
				...this.state.filters,
				startDate,
				endDate
			},
			this.props.account.id,
			true,
			true
		);
		this.setState({ dropdown: {}, date });
	};

	onSubtractDay = (startDate, endDate) => {
		const date = this.state.date.subtract(1, 'days');
		this.props.getCalendarStaffTasks(
			this.props.account.labId,
			{
				...this.state.filters,
				startDate,
				endDate
			},
			this.props.account.id,
			true,
			true
		);
		this.setState({ dropdown: {}, date });
	};

	renderBadges = () => {
		const badges = [
			{ title: i18n.t('translation:taskManager.calendar.dashboard.itemWithNote') }
		];

		const components = badges.map((badge, index) => {
			return (
				<div key={badge.title} className="badge">
					<FontAwesomeIcon icon={faInfoCircle} className={'note-icon'} />
					&nbsp;&nbsp;&nbsp;
					<span>{badge.title}</span>
				</div>
			);
		});

		return <div className="staff-badges">{components}</div>;
	};

	isFilterRangeIsDay = (filters) => {
		const startDate = filters.startDate;
		const endDate = filters.endDate;
		if (startDate && endDate) {
			const diff = moment(endDate).diff(moment(startDate), 'days');
			return diff === 0;
		}
		return true;
	};

	triggerMode = (mode) => {
		const { filters } = this.state;
		if (mode === STAFFCHART_MODES.WEEKS) {
			let startDate = moment(this.state.date)
				.startOf('week')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			let endDate = moment(this.state.date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);

			if (!isEmptyObject(filters) && !isAllNull(filters)) {
				startDate = filters.startDate
					? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: null;
				endDate = filters.endDate
					? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: null;
			}

			this.setState({ mode, dropdown: {} });
			this.props.getCalendarStaffTasks(
				this.props.account.labId,
				{
					...this.state.filters,
					startDate,
					endDate
				},
				this.props.account.id,
				false,
				true
			);
		} else {
			if (this.isFilterRangeIsDay(this.state.filters)) {
				let startDate = moment(this.state.date)
					.startOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);
				let endDate = moment(this.state.date)
					.endOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);

				if (!isEmptyObject(filters) && !isAllNull(filters)) {
					startDate = filters.startDate
						? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
					endDate = filters.endDate
						? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
				}

				this.setState({ mode, dropdown: {} });
				this.props.getCalendarStaffTasks(
					this.props.account.labId,
					{
						...this.state.filters,
						startDate,
						endDate
					},
					this.props.account.id,
					true,
					true
				);
			} else {
				this.props.showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:taskManager.calendar.staff.rangeOverOneDayWarning')
				);
			}
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		staff: state.calendar.staff,
		workingHours: state.workingHours,
		statisticTasks: state.dashboard.daily,
		filters: getFormValues('calendarStaffFiltersForm')(state) || {},
		language: state.settings.language
	};
};

export default connect(mapStateToProps, {
	getCalendarStaff,
	getGeneralWorkingHours,
	getCalendarStaffTasks,
	showMessageModal,
	getDashboardEmployeeTasks,
	reset
})(DentalTechnicianCalendarDashboard);
