import { parseFlagToLanguage } from './appUtils';
import moment from 'moment/min/moment-with-locales';
import store from '../store';

moment.locale(parseFlagToLanguage(store.getState().settings.language), {
	week: {
		dow: 1,
		doy: 1
	}
});

export default moment;
