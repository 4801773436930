import React, { Fragment } from 'react';
import AddProduct from '../freeInvoice/AddProduct';
import ProductsTable from '../../tables/ProductsTable';
import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import {
	changeDocumentDiscountTaxFormat,
	changeDocumentDiscountTaxNotFormat,
	changeDocumentItemsIDS,
	changeDocumentTotalNoTax,
	changeDocumentTotalTax,
	changeDocumentTotalWithTax,
	changeItemAdvanceNoTax,
	changeItemAmountNoTax,
	changeItemInitialTaxPercentTaxName,
	changeItemName,
	changeItemQuantity,
	changeItemTaxPercentTaxName,
	changeItemTotalPriceWithTax,
	changeItemUnit,
	changeItemUnitPriceNoTax,
	formUtils,
	getDocumentItemsIDS,
	getDocumentTaxExclude,
	getDocumentTotalTax,
	getDocumentTotalWithTax,
	getItemAmountNoTax,
	getItemQuantity,
	getItemTax,
	getItemTotalPriceWithTax,
	getItemUnitPriceNoTax
} from '../../../../../../../utils/formUtils';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import { add, divide, multiply, subtract } from '../../utils/documentUtils';

class FreeDocumentProductSection extends React.Component {
	state = {
		currentItemID: -1,
		showServerErrorMessage: false,
		serverErrorMessage: ''
	};

	updateDocumentTotalOnComponentDidUpdate(prevProps) {
		const prevDocumentIds = getDocumentItemsIDS(prevProps.formValues);
		const documentIds = getDocumentItemsIDS(this.props.formValues);

		// If Add or Remove Item to Product/Services table
		if (prevDocumentIds !== documentIds) {
			this.onDocumentTotalChange(documentIds);
			// we stop here because no need to check for update as it was add or remove
			return;
		}

		// Check if it was an update of a field in already added to Products/Service table item
		// If yes, initiate update of document total
		this.updateDocumentTotalOnAddedItemFieldChange(documentIds, prevProps);
	}

	updateDocumentTotalOnAddedItemFieldChange(documentIds, prevProps) {
		if (!documentIds || documentIds.length === 0) {
			return;
		}

		let newTemp;
		let oldTemp;
		let shouldUpdateDocumentTotal;

		for (let i = 0; i < documentIds.length; i++) {
			newTemp = getItemTotalPriceWithTax(this.props.formValues, documentIds[i]);
			oldTemp = getItemTotalPriceWithTax(prevProps.formValues, documentIds[i]);

			if (newTemp && oldTemp && newTemp !== oldTemp) {
				shouldUpdateDocumentTotal = true;
				break;
			}
		}

		if (shouldUpdateDocumentTotal) {
			this.onDocumentTotalChange(getDocumentItemsIDS(this.props.formValues));
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.updateDocumentTotalOnComponentDidUpdate(prevProps);

		if (
			prevProps.currentDocument !== this.props.currentDocument &&
			this.props.currentDocument
		) {
			this.fillInitialData();
		}

		if (
			prevProps.formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS].length > 0 &&
			this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS].length === 0
		) {
			this.setState({ currentItemID: -1 });
		}

		const taxExcluded = getDocumentTaxExclude(this.props.formValues);

		if (prevProps.formValues && getDocumentTaxExclude(prevProps.formValues) !== taxExcluded) {
			const { formValues } = this.props;
			this.onTaxExclude(taxExcluded, formValues, this.props.mode);
		}
	}

	onTaxExclude(taxExcluded, formValues, mode) {
		if (mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return;
		}

		if (taxExcluded) {
			this.changeFreeInvoiceItemsTax(formValues, true);
		} else {
			this.changeFreeInvoiceItemsTax(formValues, false);
		}
	}

	changeFreeInvoiceItemsTax(formValues, setToZeroTax) {
		const { ITEM_TOTAL_PRICE_WITH_TAX_, ITEM_TAX_PERCENT_TAX_NAME_ } = INVOICE_FIELD_NAME;

		const zeroTaxOption =
			setToZeroTax && formUtils.options.getZeroTaxOption(this.props.taxGroups);

		const itemIds = getDocumentItemsIDS(formValues);

		let itemTotalPriceNoTax = 0;
		let itemId;

		for (let i = 0; i < itemIds.length; i++) {
			itemId = itemIds[i];
			itemTotalPriceNoTax = getItemAmountNoTax(formValues, itemIds[i]);

			let updatedItemTax;

			if (setToZeroTax) {
				updatedItemTax = zeroTaxOption.value;
			} else {
				updatedItemTax = ' _ ';
			}

			this.props.change(
				'freeInvoiceForm',
				[`${ITEM_TOTAL_PRICE_WITH_TAX_}${itemId}`],
				itemTotalPriceNoTax
			);
			this.props.change(
				'freeInvoiceForm',
				[`${ITEM_TAX_PERCENT_TAX_NAME_}${itemId}`],
				updatedItemTax
			);

			this.props.touch([`${ITEM_TAX_PERCENT_TAX_NAME_}${itemId}`]);
		}
	}

	fillInitialData() {
		const documentsItems = this.props.currentDocument.items;

		const documentItemsIDS = [];

		let documentTotalNoTax = 0.0;
		let documentTotalWIthTax = 0.0;
		let documentTotalTax = 0.0;

		for (let i = 0; i < documentsItems.length; i++) {
			const item = documentsItems[i];
			documentItemsIDS.push(item.id);

			const {
				id,
				advance,
				name,
				quantity,
				taxName,
				taxPercentage,
				totalPrice,
				unit,
				unitPrice
			} = item;

			changeItemName(this.props.change, id, name);
			changeItemAdvanceNoTax(this.props.change, id, advance);
			changeItemQuantity(this.props.change, id, quantity);
			changeItemTaxPercentTaxName(this.props.change, id, `${taxPercentage}_${taxName}`);
			//this field is in case the invoice is created with certain tax group, and the tax is deleted after that
			changeItemInitialTaxPercentTaxName(
				this.props.change,
				id,
				`${taxPercentage}_${taxName}`
			);
			changeItemTotalPriceWithTax(this.props.change, id, totalPrice);

			changeItemUnit(this.props.change, id, unit);
			changeItemUnitPriceNoTax(this.props.change, id, unitPrice);
			changeItemAmountNoTax(this.props.change, id, advance);

			documentTotalNoTax = add(documentTotalNoTax, advance);

			documentTotalWIthTax = add(documentTotalWIthTax, totalPrice);

			documentTotalTax = add(documentTotalTax, subtract(totalPrice, advance));
		}

		documentTotalNoTax = subtract(documentTotalWIthTax, documentTotalNoTax);

		changeDocumentTotalWithTax(this.props.change, documentTotalWIthTax);

		changeDocumentTotalTax(this.props.change, documentTotalTax);

		changeDocumentTotalNoTax(this.props.change, documentTotalNoTax);

		changeDocumentItemsIDS(this.props.change, documentItemsIDS);

		if (!this.props.currentDocument.taxIncluded) {
			const zeroTaxOption = formUtils.options.getZeroTaxOption(this.props.taxGroups);
			changeItemTaxPercentTaxName(
				this.props.change,
				this.state.currentItemID,
				zeroTaxOption.value
			);
		}
	}

	decreaseCurrentItemIDByOne = () => {
		this.setState({ currentItemID: this.state.currentItemID - 1 });
	};

	onAddProductFormSubmit = () => {
		this.updatedItemsIDS();
		this.decreaseCurrentItemIDByOne();
	};

	updatedItemsIDS = () => {
		const updatedItemIDS = [].concat(getDocumentItemsIDS(this.props.formValues));
		updatedItemIDS.push(this.state.currentItemID);

		changeDocumentItemsIDS(this.props.change, updatedItemIDS);
	};

	onItemRemove = (id) => {
		changeItemName(this.props.change, id, null);
		changeItemUnit(this.props.change, id, null);
		changeItemUnitPriceNoTax(this.props.change, id, null);
		changeItemAmountNoTax(this.props.change, id, null);
		changeItemTaxPercentTaxName(this.props.change, id, null);
		changeItemTotalPriceWithTax(this.props.change, id, null);
		changeItemQuantity(this.props.change, id, null);

		const updatedItemsIDS = getDocumentItemsIDS(this.props.formValues).filter(
			(itemID) => itemID.toString() !== id.toString()
		);

		changeDocumentItemsIDS(this.props.change, updatedItemsIDS);
	};

	onDocumentTotalChange = (documentItemsIDS) => {
		let documentTotalWithTax = 0.0;
		let documentTotalTax = 0.0;

		for (let i = 0; i < documentItemsIDS.length; i++) {
			const itemID = documentItemsIDS[i];

			const itemAmount = getItemAmountNoTax(this.props.formValues, itemID);

			const itemTotalWithTax = getItemTotalPriceWithTax(this.props.formValues, itemID);

			documentTotalWithTax = add(documentTotalWithTax, itemTotalWithTax);

			documentTotalTax = add(documentTotalTax, subtract(itemTotalWithTax, itemAmount));
		}

		const documentTotalNoTax = subtract(documentTotalWithTax, documentTotalTax);

		changeDocumentTotalWithTax(this.props.change, documentTotalWithTax);

		changeDocumentTotalTax(this.props.change, documentTotalTax);

		changeDocumentTotalNoTax(this.props.change, documentTotalNoTax);

		this.setDocumentDiscountTax(
			documentTotalNoTax,
			documentItemsIDS,
			documentTotalWithTax,
			documentTotalTax
		);
	};

	setDocumentDiscountTax(
		documentTotalNoTax,
		documentItemsIDS,
		documentTotalWithTax,
		documentTotalTax
	) {
		if (!documentTotalNoTax) {
			changeDocumentDiscountTaxNotFormat(this.props.change, 0);
			changeDocumentDiscountTaxFormat(this.props.change, 0);
			return;
		}

		const documentDiscountTax = divide(multiply(documentTotalTax, 100), documentTotalNoTax);

		if (documentDiscountTax === 0) {
			changeDocumentDiscountTaxNotFormat(this.props.change, 0);
			changeDocumentDiscountTaxFormat(this.props.change, 0);
		} else {
			changeDocumentDiscountTaxFormat(this.props.change, documentDiscountTax);
			changeDocumentDiscountTaxNotFormat(
				this.props.change,
				multiply(documentDiscountTax, 0.01)
			);
		}
	}

	updateItemTotalPriceWithTax = (
		currentItemUnitPrice,
		currentItemQuantity,
		currentItemTaxPercentName,
		itemID
	) => {
		const tax = currentItemTaxPercentName ? currentItemTaxPercentName.split('_')[0] : null;

		if (currentItemUnitPrice && currentItemQuantity && tax !== null && tax.trim().length) {
			const updatedAmount = multiply(currentItemUnitPrice, currentItemQuantity);
			const updatedTotalPrice = add(
				updatedAmount,
				multiply(multiply(updatedAmount, tax), 0.01)
			);

			changeItemTotalPriceWithTax(this.props.change, itemID, updatedTotalPrice);
		} else {
			changeItemTotalPriceWithTax(this.props.change, itemID, 0);
		}
	};

	onItemAmountChange = (unitPrice, quantity, itemID) => {
		const currentTax = getItemTax(this.props.formValues, itemID);

		if (quantity && unitPrice) {
			const updatedAmount = multiply(quantity, unitPrice);

			changeItemAmountNoTax(this.props.change, itemID, updatedAmount);

			if (currentTax) {
				this.updateItemTotalPriceWithTax(unitPrice, quantity, currentTax, itemID);
			}
		} else {
			changeItemAmountNoTax(this.props.change, itemID, 0);
			changeItemTotalPriceWithTax(this.props.change, itemID, 0);
		}
	};

	onItemTaxChange = (itemTax, itemID) => {
		if (!itemTax || !itemTax.toString().trim().length) {
			itemTax = '0_0';
		}

		const itemUnitPriceNoTax = getItemUnitPriceNoTax(this.props.formValues, itemID);
		const itemQuantity = getItemQuantity(this.props.formValues, itemID);

		this.updateItemTotalPriceWithTax(itemUnitPriceNoTax, itemQuantity, itemTax, itemID);
	};

	onItemQuantityChange = (newQuantity, itemID) => {
		const itemUnitPriceNoTax = getItemUnitPriceNoTax(this.props.formValues, itemID);

		const previousValue =
			this.props.formValues[`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${itemID}`];

		const normalizedQuantity = formUtils.normalize.productQuantity(newQuantity, previousValue);

		if (normalizedQuantity !== newQuantity) {
			return;
		}

		this.onItemAmountChange(itemUnitPriceNoTax, newQuantity, itemID);
	};

	onItemUnitPriceChange = (unitPrice, itemID) => {
		const quantity = getItemQuantity(this.props.formValues, itemID);

		const previousValue =
			this.props.formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${itemID}`];

		const normalizedPrice = formUtils.normalize.price(unitPrice, previousValue);

		if (normalizedPrice !== unitPrice) {
			return;
		}

		this.onItemAmountChange(unitPrice, quantity, itemID);
	};

	renderAddItem() {
		const canAddItem = !isReadableInput(this.props.mode);

		if (canAddItem) {
			return (
				<AddProduct
					currentItemID={this.state.currentItemID}
					onItemQuantityChange={this.onItemQuantityChange}
					onItemTaxChange={this.onItemTaxChange}
					onItemUnitPriceChange={this.onItemUnitPriceChange}
					onSubmit={this.onAddProductFormSubmit}
					touch={this.props.touch}
					localizedCurrencyAbbreviation={this.props.localizedCurrencyAbbreviation}
					currencySymbol={this.props.currencySymbol}
				/>
			);
		}

		return null;
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				{this.renderAddItem()}
				<ProductsTable
					onItemQuantityChange={this.onItemQuantityChange}
					onItemUnitPriceChange={this.onItemUnitPriceChange}
					onItemTaxChange={this.onItemTaxChange}
					onItemRemove={this.onItemRemove}
					documentType={this.props.documentType}
					mode={this.props.mode}
					localizedCurrencyAbbreviation={this.props.localizedCurrencyAbbreviation}
					currencySymbol={this.props.currencySymbol}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		currentDocument: state.billingDocument.currentDocument,
		taxGroups: state.billingSettings.taxGroups
	};
};

export default connect(mapStateToProps, { change })(FreeDocumentProductSection);
