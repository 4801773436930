import React, { Fragment } from 'react';
import moment from '../../utils/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ORDER_STATUS } from '../../utils/constants';
import { isAllNull, isEmptyObject, parseFlagToLanguage } from '../../utils/appUtils';
import { ScrollSyncPane } from 'react-scroll-sync';

class Chart extends React.Component {
	state = { rows: 0 };

	render() {
		return (
			<Fragment>
				<ScrollSyncPane group="vertical">
					<div className="calendar-table">
						<div>
							<div className="days chart-header">{this.renderDays()}</div>

							{this.renderData()}
						</div>
					</div>
				</ScrollSyncPane>
				{this.renderDate()}
			</Fragment>
		);
	}

	renderDate = () => {
		moment.locale(parseFlagToLanguage(this.props.language), {
			week: {
				dow: 1 // Monday is the first day of the week.
			}
		});

		const filters = this.props.filters;
		const arrowLeft =
			isAllNull(filters) && !this.props.orderDetails ? (
				<FontAwesomeIcon
					onClick={this.props.subtractMonth}
					icon={faSortDown}
					className="fa-rotate-90 pointer"
				/>
			) : null;
		const arrowRight =
			isAllNull(filters) && !this.props.orderDetails ? (
				<FontAwesomeIcon
					onClick={this.props.addMonth}
					icon={faSortDown}
					className="fa-rotate-270 pointer"
				/>
			) : null;

		let startDate =
			filters.startDate && !filters.dueDateFrom && !filters.dueDateTo
				? moment(filters.startDate).format('DD.MM.YYYY')
				: !isAllNull(filters) && this.props.orders.earliestStartDate
				? moment(this.props.orders.earliestStartDate).format('DD.MM.YYYY')
				: !isAllNull(filters) && !this.props.orders.earliestStartDate
				? ''
				: this.props.date.startOf('month').format('DD.MM.YYYY');
		let endDate =
			filters.endDate && !filters.dueDateFrom && !filters.dueDateTo
				? moment(filters.endDate).format('DD.MM.YYYY')
				: !isAllNull(filters) && this.props.orders.latestEndDate
				? moment(this.props.orders.latestEndDate).format('DD.MM.YYYY')
				: !isAllNull(filters) && !this.props.orders.latestEndDate
				? ''
				: this.props.date.endOf('month').format('DD.MM.YYYY');

		if (this.props.orderDetails) {
			startDate = moment(this.props.orders.earliestStartDate).format('DD.MM.YYYY');
			endDate = moment(this.props.orders.latestEndDate).format('DD.MM.YYYY');
		}

		const date = startDate && endDate ? startDate + ' - ' + endDate : '';
		return (
			<div className="range">
				<div className="date">
					{arrowLeft}
					<p className="m-0">{date}</p>
					{arrowRight}
				</div>
			</div>
		);
	};

	renderData = () => {
		const data = this.props.data;
		return data.map((dataRow, index) => {
			return this.renderGroup(dataRow, index, 0);
		});
	};

	renderContent = (data) => {
		const date = this.props.date.clone();
		const filters = this.props.filters;
		let startDate =
			filters.startDate && !filters.dueDateFrom && !filters.dueDateTo
				? moment(filters.startDate)
				: !isAllNull(filters)
				? moment(this.props.orders.earliestStartDate)
				: date.clone().startOf('month');
		let endDate =
			filters.endDate && !filters.dueDateFrom && !filters.dueDateTo
				? moment(filters.endDate)
				: !isAllNull(filters)
				? moment(this.props.orders.latestEndDate)
				: date.clone().endOf('month');
		if (this.props.orderDetails) {
			startDate = moment(this.props.orders.earliestStartDate);
			endDate = moment(this.props.orders.latestEndDate);
		}
		const weekends = this.props.weekends;
		const days = [];
		let day = startDate;

		while (day <= endDate) {
			days.push(day.toDate());
			day = day.clone().add(1, 'd');
		}

		const getSideMargins = (index) =>
			index == 0 ? ' ml-xxs' : index == days.length - 1 ? ' mr-xxs' : '';

		let badgeClassName = (day, index) =>
			moment(day).isSame(data[1].split(' ')[0], 'day') &&
			moment(day).isSame(data[2].split(' ')[0], 'day')
				? 'chart-badge start end' + getSideMargins(index)
				: moment(day).isSame(data[1].split(' ')[0], 'day')
				? 'chart-badge start' + getSideMargins(index)
				: moment(day).isSame(data[2].split(' ')[0], 'day')
				? 'chart-badge end' + getSideMargins(index)
				: moment(day).isBetween(data[1], data[2], undefined, '()')
				? 'chart-badge' + getSideMargins(index)
				: 'chart-badge d-none' + getSideMargins(index);

		const opacity = data[5] ? 1 : 0.3;
		const check = (day) =>
			moment(day).isSame(data[1].split(' ')[0], 'day') &&
			data[4].props &&
			data[4].props.status === ORDER_STATUS.COMPLETED ? (
				<div className="check">
					<FontAwesomeIcon icon={faCheck} />
				</div>
			) : null;
		const badge = (day, index) => (
			<div className={badgeClassName(day, index)} style={{ opacity }}>
				{check(day)}
			</div>
		);

		if (days) {
			return days.map((day, index) => {
				const dayOfWeek = moment(day).day() ? moment(day).day() : 7;
				const className = weekends.includes(dayOfWeek)
					? 'chart-data light-blue'
					: 'chart-data';
				return (
					<div className={className} key={'day' + index}>
						{badge(day, index)}
					</div>
				);
			});
		}
	};

	renderGroup = (primaryData, parentIndex, levelIndex, index = 0) => {
		let primaryRow;

		parentIndex = parentIndex.toString();

		const elementIndex = parentIndex + '-' + index;

		const uniqueIndex = levelIndex.toString() + '-' + index.toString() + '-' + parentIndex;
		if (Array.isArray(primaryData[0])) {
			if (
				this.props.dropdown &&
				!this.props.dropdown[parentIndex] &&
				parentIndex.split('-').length > 1
			) {
				return;
			}

			let className =
				this.props.dropdown && !this.props.dropdown[elementIndex]
					? 'collapsed chart-row level-' + levelIndex
					: 'chart-row level-' + levelIndex;

			// const colSpan = this.props.columns.length + 1;
			// const noItemsMessage = this.props.noItemsMessage ? this.props.noItemsMessage :
			// i18n.t('translation:common.table.noItems'); const noItems = [<tr className={className}
			// key={uniqueIndex}><td className={'text-center'} colSpan={colSpan}>{noItemsMessage}</td></tr>]

			const secondaryRows = primaryData[0].length
				? primaryData[0].map((currentData, index) => {
						return this.renderGroup(currentData, elementIndex, levelIndex + 1, index);
				  })
				: this.props.dropdown && !this.props.dropdown[elementIndex]
				? []
				: [];

			primaryData.shift();

			primaryData.push('');

			primaryRow = (
				<div className={className} key={uniqueIndex}>
					{this.renderContent(primaryData)}
				</div>
			);

			return [primaryRow, ...secondaryRows];
		} else {
			if (this.props.dropdown && !this.props.dropdown[parentIndex]) {
				return;
			}

			primaryData.push('');

			const content = this.renderContent(primaryData);

			return (
				<div key={uniqueIndex} className={'chart-row level-' + levelIndex}>
					{content}
				</div>
			);
		}
	};

	renderDays = () => {
		const date = this.props.date.clone();
		const filters = this.props.filters;
		let startDate =
			filters.startDate && !filters.dueDateFrom && !filters.dueDateTo
				? moment(filters.startDate)
				: !isAllNull(filters)
				? moment(this.props.orders.earliestStartDate)
				: date.clone().startOf('month');
		let endDate =
			filters.endDate && !filters.dueDateFrom && !filters.dueDateTo
				? moment(filters.endDate)
				: !isAllNull(filters)
				? moment(this.props.orders.latestEndDate)
				: date.clone().endOf('month');

		if (this.props.orderDetails) {
			startDate = moment(this.props.orders.earliestStartDate);
			endDate = moment(this.props.orders.latestEndDate);
		}

		const days = [];
		let day = startDate;

		while (day <= endDate) {
			days.push(day.toDate());
			day = day.clone().add(1, 'd');
		}

		const borderRight = (isLast) => (isLast ? {} : { borderRight: 'none' });
		const borderLeft = (isFirst) => (isFirst ? { borderLeft: 'none' } : {});

		if (!isEmptyObject(days)) {
			return days.map((day, index) => (
				<div
					className="day"
					style={{
						...borderLeft(index === 0),
						...borderRight(index === days.length - 1)
					}}
					key={'day' + index}>
					{moment(day).format('DD.MM')}
				</div>
			));
		} else {
			return <div className="day w-100" />;
		}
	};
}

export default Chart;
