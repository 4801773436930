import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Fields } from '../../../common/Fields';
import i18n from '../../../../i18n';
import DefaultButton from '../../../common/DefaultButton';
import { formUtils } from '../../../../utils/formUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { createTaxGroup, updateTaxGroup } from '../../../../actions';

class TaxGroupModal extends React.Component {
	state = {
		showServerErrorMessage: false,
		serverErrorMessage: ''
	};

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
			let refinedInitialValues = {};

			refinedInitialValues['taxGroupName'] = this.props.initialValues.taxGroupName;
			refinedInitialValues['taxGroupRate'] = this.props.initialValues.taxGroupRate;
			refinedInitialValues['taxGroupId'] = this.props.initialValues.taxGroupId;

			this.props.initialize(refinedInitialValues);

			this.clearErrorMessage();
		}
	}

	render() {
		const saveButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.onFormSubmit}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const modalTitle = i18n.t('translation:billingModule.settings.taxGroupModalTitle');

		return (
			<Modal
				className="custom-modal"
				overlayClassName="custom-overlay"
				isOpen={this.props.isModalOpen}>
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				<form className="mb-4" onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{Fields.billingModule.settings.taxGroupName({
						onChange: () => this.clearErrorMessage()
					})}
					{Fields.billingModule.settings.taxGroupRate({
						onChange: () => this.clearErrorMessage()
					})}
					{this.state.showServerErrorMessage && (
						<div className="modal-error-message">{this.state.serverErrorMessage}</div>
					)}
				</form>
				<div className="buttons">
					{saveButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	onFormSubmit = () => {
		this.props.handleSubmit((formValues) => {
			const taxGroup = {
				name: formValues.taxGroupName,
				percentage: Number(formValues.taxGroupRate)
			};

			if (this.props.initialValues.taxGroupId) {
				this.props.updateTaxGroup(
					this.props.billingSettings.id,
					this.props.initialValues.taxGroupId,
					taxGroup,
					this.closeModal,
					this.setServerErrorMessage
				);

				return;
			}

			this.props.createTaxGroup(
				this.props.billingSettings.id,
				taxGroup,
				this.closeModal,
				this.setServerErrorMessage
			);
		})();

		return;
	};

	closeModal = () => {
		this.setState({ selectedTaxGroup: {} });
		this.props.destroy();
		this.props.closeModal();
	};

	setServerErrorMessage = (serverErrorMessage) => {
		this.setState({
			showServerErrorMessage: true,
			serverErrorMessage: serverErrorMessage
		});
	};

	clearErrorMessage = () => {
		this.setState({
			showServerErrorMessage: false,
			serverErrorMessage: ''
		});
	};
}

const taxGroupForm = reduxForm({
	form: 'taxGroupForm',
	validate: formUtils.validateTaxGroup,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true
})(TaxGroupModal);

const mapStateToProps = (state, props) => {
	return {
		billingSettings: state.billingSettings
	};
};

export default connect(mapStateToProps, { createTaxGroup, updateTaxGroup })(taxGroupForm);
