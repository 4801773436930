import {
	CLEAR_REDUCER,
	GET_USER_DETAILS,
	GET_USERS,
	GET_USERS_WITH_PAGINATION
} from '../actions/types';

const initState = { currentUser: {}, users: {} };

const usersReducer = (state = { ...initState }, action) => {
	if (action.type === GET_USERS) {
		state.users.content = [...action.payload];
		state.users = { ...state.users };
		return { ...state };
	}

	if (action.type === GET_USERS_WITH_PAGINATION) {
		state.users = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_USER_DETAILS) {
		state.currentUser = { ...action.payload };
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default usersReducer;
