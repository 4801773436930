import { START_LOADER, STOP_LOADER } from './types';

export const startLoader = (type) => {
	return {
		type: START_LOADER,
		payload: type
	};
};

export const stopLoader = (type, onStopLoading) => async (dispatch) => {
	const timer = await setTimeout(() => {
		clearTimeout(timer);
		dispatch({
			type: STOP_LOADER,
			payload: type
		});
		if (typeof onStopLoading === 'function') {
			onStopLoading();
		}
	}, 300);
};

export const stopLoaderImmediately = (type) => {
	return {
		type: STOP_LOADER,
		payload: type
	};
};
