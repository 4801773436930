import React, { Fragment } from 'react';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import OrderTable from '../common/Orders';
import Filters from './Filters';
import { exportOrders } from '../../../actions';
import { connect } from 'react-redux';
import { isDentistUser, isEmptyObject, isLabTechnician } from '../../../utils/appUtils';

class Orders extends React.Component {
	state = { filters: {}, sort: {} };

	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:orders.orders')}
					buttons={[this.renderExportCsvButton()]}
				/>
				<Filters setFilters={this.setFilters} />
				<OrderTable filters={this.state.filters} onSort={this.onSort} />
			</Fragment>
		);
	}

	renderExportCsvButton = () => {
		if (!isLabTechnician(this.props.account) && !isDentistUser(this.props.account)) {
			return (
				<DefaultButton
					title={i18n.t('translation:orders.buttons.exportToExcelFile')}
					onClick={() => this.props.exportOrders(this.state.sort, this.state.filters)}
					disabled={
						!isEmptyObject(this.props.orders.orders) &&
						this.props.orders.orders.content.length === 0
					}
				/>
			);
		}
	};

	setFilters = (filters) => {
		this.setState({ filters });
	};

	onSort = (sort) => {
		this.setState({ sort });
	};
}

const mapStateToProps = (state) => {
	return { account: state.account, orders: state.orders };
};

export default connect(mapStateToProps, { exportOrders })(Orders);
