import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import loginReducer from './loginReducer';
import accountReducer from './accountReducer';
import settingReducer from './settingReducer';
import usersReducer from './usersReducer';
import inventoryReducer from './inventoryReducer';
import tokenReducer from './tokenReducer';
import clinicsReducer from './clinicsReducer';
import portalReducer from './portalReducer';
import paginationReducer from './paginationReducer';
import ordersReducer from './ordersReducer';
import loaderReducer from './loaderReducer';
import labsReducer from './labsReducer';
import statisticsReducer from './statisticReducer';
import sortReducer from './sortReducer';
import serverSettingReducer from './serverSettingReducer';
import invitationReducer from './invitationReducer';
import phasesReducer from './taskManager/phasesReducer';
import moduleReducer from './moduleReducer';
import templateReducer from './taskManager/templateReducer';
import generalWorkingHoursReducer from './taskManager/generalWorkingHoursReducer';
import shiftReducer from './taskManager/shiftReducer';
import addTasksReducer from './taskManager/addTasksReducer';
import staffReducer from './taskManager/staffReducer';
import absenceReducer from './taskManager/absenceReducer';
import commonReducer from './commonReducer';
import holidayReducer from './taskManager/holidayReducer';
import calendarReducer from './taskManager/calendarReducer';
import calendarDashboardReducer from './taskManager/calendarDashboardReducer';
import billingSettingsReducer from './billing/billingSettingsReducer';
import billingDocumentReducer from './billing/billingDocumentReducer';
import billingTransactionReducer from './billing/billingTransactionsReducer';
import billingDebtorDaysReducer from './billing/billingDebtorDaysReducer';
import billingStatementReducer from './billing/billingStatementReducer';

export default combineReducers({
	login: loginReducer,
	account: accountReducer,
	settings: settingReducer,
	serverSettings: serverSettingReducer,
	users: usersReducer,
	inventory: inventoryReducer,
	clinics: clinicsReducer,
	token: tokenReducer,
	pagination: paginationReducer,
	portal: portalReducer,
	orders: ordersReducer,
	loader: loaderReducer,
	statistics: statisticsReducer,
	labs: labsReducer,
	invitations: invitationReducer,
	sort: sortReducer,
	phases: phasesReducer,
	modules: moduleReducer,
	templates: templateReducer,
	workingHours: generalWorkingHoursReducer,
	shifts: shiftReducer,
	tasks: addTasksReducer,
	staff: staffReducer,
	absences: absenceReducer,
	common: commonReducer,
	holidays: holidayReducer,
	calendar: calendarReducer,
	dashboard: calendarDashboardReducer,
	billingSettings: billingSettingsReducer,
	billingDocument: billingDocumentReducer,
	billingTransactions: billingTransactionReducer,
	billingDebtorDays: billingDebtorDaysReducer,
	billingStatement: billingStatementReducer,
	form: formReducer
});
