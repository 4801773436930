import React, { Fragment } from 'react';
import DefaultButton from './DefaultButton';
import { isFirefoxBrowser, parseStatus } from '../../utils/appUtils';

class DropdownButton extends React.Component {
	state = { isDropdownOpen: false };

	render() {
		let buttonClass = 'default-button';
		buttonClass += this.props.short ? ' short-dropdown-button' : ' dropdown-button';
		buttonClass += this.props.danger ? ' danger-button' : '';
		buttonClass += this.props.secondary ? ' secondary-button' : '';

		if (!this.props.buttons.length) {
			buttonClass += ' disabled-button';
			return (
				<Fragment>
					<button className={buttonClass}>{this.renderTitleSpan()}</button>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<button
					className={buttonClass}
					onClick={this.onButtonClick}
					title={this.props.tooltip}>
					{this.renderTitleSpan()}
					{this.renderTrigger()}
				</button>
				{this.renderDropdown()}
			</Fragment>
		);
	}

	renderTitleSpan = () => {
		return (
			<span
				style={{
					overflow: isFirefoxBrowser() ? 'clip' : 'hidden'
				}}>
				{this.props.title}
			</span>
		);
	};

	onButtonClick = () => {
		if (this.props.buttons) {
			this.onTrigger();
		}
	};

	renderTrigger = () => {
		if (this.props.buttons) {
			return this.state.isDropdownOpen ? (
				<span className="arrow-up" />
			) : (
				<span className="arrow-down" />
			);
		}
	};

	onTrigger = () => {
		this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
	};

	renderDropdown = () => {
		if (this.state.isDropdownOpen && this.props.buttons) {
			const className = this.props.short ? 'short-dropdown-buttons' : 'dropdown-buttons';

			let { buttons, statusButton, onButtonClick } = this.props;

			buttons = buttons.map((button, index) => {
				const title = statusButton ? parseStatus(button) : button;
				return (
					<DefaultButton
						key={index + 'dropdownButton'}
						title={title}
						onClick={() => {
							onButtonClick(button);
							this.onTrigger();
						}}
					/>
				);
			});

			return <div className={className}>{buttons}</div>;
		}
	};
}

export default DropdownButton;
