import React, { Fragment } from 'react';
import { change, getFormValues } from 'redux-form';
import { INVOICE_FIELD_NAME, INVOICING_METHODS } from '../../../../../../../utils/constants';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import { connect } from 'react-redux';
import {
	changeDocumentDiscountTaxFormat,
	changeDocumentDiscountTaxNotFormat,
	changeDocumentDiscountWithTax,
	changeDocumentItemsIDS,
	changeDocumentOrdersIDS,
	changeDocumentTotalNoTax,
	changeDocumentTotalTax,
	changeDocumentTotalWithTax,
	changeItemAdvanceNoTax,
	changeItemAmountNoTax,
	changeItemCatalogNumber,
	changeItemName,
	changeItemQuantity,
	changeItemTaxPercentTaxName,
	changeItemTotalPriceWithTax,
	changeItemUnit,
	changeItemUnitPriceNoTax,
	changeMapItemIdBillingItemId,
	changeMapOrderIdToId,
	changeOrderFixedAmountForInvoicing,
	changeOrderFixedAmountForInvoicingRound,
	changeOrderInvoicingMethod,
	changeOrderPercentForInvoicing,
	changeOrderPercentForInvoicingRound,
	changeOrderTotalAdvanceWithTax,
	changeOrderTotalNoTax,
	changeOrderTotalTax,
	changeOrderUnInvoicedAmountInPercentage,
	formUtils,
	getDocumentItemsIDS,
	getDocumentOrdersIDS,
	getDocumentTaxExclude,
	getItemAdvance,
	getItemAmountNoTax,
	getItemTax,
	getOrderFixedAmountForInvoicing,
	getOrderInvoicingMethod,
	getOrderPercentForInvoicing,
	getOrderTotal,
	getOrderTotalAdvanceWithTax,
	getOrderTotalTax,
	getOrderUnInvoicedAmountInPercentage
} from '../../../../../../../utils/formUtils';
import Header from './Header';
import { getSelectedTaxType } from '../../../../../../../utils/appUtils';
import {
	parseInvoicingCurrency,
	preparePercentForDisplay
} from '../../../../../../../utils/tableUtils';
import OrderItemRow from './OrderItemRow';
import OrderValueModified from '../../sections/common/OrderValueModified';
import OrderItemsHeader from './OrderItemsHeader';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { add, divide, multiply, subtract } from '../../utils/documentUtils';

//does the order have discount/increase
export const ORDER_PRICE_MODIFIED = {
	INCREASE: 'increase',
	DECREASE: 'decrease',
	INCREASE_AND_DECREASE: 'increase_and_decrease'
};

class OrderInvoiceTable extends React.Component {
	state = {
		//[orderId]: ['prodId', 'prodId']
		orderProductsIds: {}, //todo -> to remove this!!!
		//	orderTriggers[order.orderId] = { isDropdownOpen: false };
		ordersTriggers: {},
		//{[orderId]: {type: 'INCREASE', amount: 4.78}, [orderID]: {type: 'DECREASE', amount: 78}}
		//ordersPriceModified[456] -> will be undefined if order with id 456 is not modified /shows does the order
		// has a discount/price increase/
		ordersPriceModified: {},
		ordersUnInvoicedAmountAbsoluteValues: {},
		invoiceItemsTotalAmountNoTax: 0.0,
		ordersAverageTax: {},
		isInitialized: false
	};

	isCreation() {
		return this.props.mode === BILLING_DOCUMENT_MODE.CREATE;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.invoiceData !== this.props.invoiceData) {
			this.initialize({ isInitializingAfterTaxExclude: false, isCreation: true });
			return;
		}

		if (prevProps.currentDocument !== this.props.currentDocument) {
			this.initialize({ isInitializingAfterTaxExclude: false, isCreation: false });
			return;
		}

		const taxExcluded = getDocumentTaxExclude(this.props.formValues);

		if (prevProps.formValues && getDocumentTaxExclude(prevProps.formValues) !== taxExcluded) {
			this.onTaxExclude(taxExcluded, this.props.formValues, this.props.mode);
		}

		if (
			prevProps &&
			getDocumentTaxExclude(prevProps.formValues) === true &&
			getDocumentTaxExclude(this.props.formValues) === false
		) {
			this.initialize({
				isInitializingAfterTaxExclude: true,
				isCreation: this.isCreation()
			});
		}

		if (
			prevProps.formValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS] !==
			this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS]
		) {
			this.setOrdersAverageTaxesInvoiceDiscountTax();
		}

		this.onInvoiceTotalChange(prevProps);

		if (
			prevState.isInitialized !== this.state.isInitialized &&
			this.state.isInitialized &&
			!this.isCreation()
		) {
			this.fillInitialData();
		}
	}

	onTaxExclude(taxExcluded, formValues, mode) {
		if (mode === BILLING_DOCUMENT_MODE.PREVIEW || !taxExcluded) {
			return;
		}
		this.changeOrdersTaxToZero(formValues);
		this.changeOrderItemsTaxToZero(formValues);
	}

	changeOrderItemsTaxToZero(formValues) {
		const zeroTaxOption = formUtils.options.getZeroTaxOption(this.props.taxGroups);

		const itemIds = this.getOrderInvoiceItemsIds(formValues);

		let itemTotalPriceNoTax = 0;
		let itemId;

		for (let i = 0; i < itemIds.length; i++) {
			itemId = itemIds[i];
			itemTotalPriceNoTax = getItemAdvance(formValues, itemIds[i]);
			changeItemTotalPriceWithTax(this.props.change, itemId, itemTotalPriceNoTax);
			changeItemTaxPercentTaxName(this.props.change, itemId, zeroTaxOption.value);
		}
	}

	getOrderInvoiceItemsIds(formValues) {
		const ordersIds = getDocumentOrdersIDS(formValues);
		const orderItemsIds = getDocumentItemsIDS(formValues);
		const itemsIds = [];

		for (let orderId of ordersIds) {
			if (orderItemsIds[orderId].length) {
				itemsIds.push(...orderItemsIds[orderId]);
			}
		}

		return itemsIds;
	}

	changeOrdersTaxToZero(formValues) {
		const ordersIds = getDocumentOrdersIDS(formValues);
		let orderTotalAdvanceNoTax = 0;
		let orderTotalAdvanceWithTax = 0;
		let orderTotalTax = 0;

		ordersIds.forEach((orderId) => {
			orderTotalTax = getOrderTotalTax(formValues, orderId);
			orderTotalAdvanceWithTax = getOrderTotalAdvanceWithTax(formValues, orderId);
			orderTotalAdvanceNoTax = subtract(orderTotalAdvanceWithTax, orderTotalTax);
			this.props.change(
				'freeInvoiceForm',
				`${INVOICE_FIELD_NAME.ORDER_TOTAL_ADVANCE_WITH_TAX_}${orderId}`,
				orderTotalAdvanceNoTax
			);

			this.props.change(
				'freeInvoiceForm',
				`${INVOICE_FIELD_NAME.ORDER_TOTAL_TAX_}${orderId}`,
				0
			);
		});
	}

	onInvoiceTotalChange(prevProps) {
		if (prevProps.formValues !== this.props.formValues) {
			const ordersIDS = this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS];
			let documentTotalWithTax = 0;
			let documentTotalTax = 0;

			let invoiceTotalISChanged = false;

			for (let orderID of ordersIDS) {
				documentTotalWithTax = add(
					documentTotalWithTax,
					getOrderTotalAdvanceWithTax(this.props.formValues, orderID)
				);

				documentTotalTax = add(
					documentTotalTax,
					getOrderTotalTax(this.props.formValues, orderID)
				);

				if (
					prevProps.formValues &&
					prevProps.formValues[
						`${INVOICE_FIELD_NAME.ORDER_TOTAL_ADVANCE_WITH_TAX_}${orderID}`
					] !==
						this.props.formValues[
							`${INVOICE_FIELD_NAME.ORDER_TOTAL_ADVANCE_WITH_TAX_}${orderID}`
						]
				) {
					invoiceTotalISChanged = true;
				}
			}

			if (invoiceTotalISChanged) {
				this.calculateDiscountTax(
					documentTotalWithTax,
					documentTotalTax,
					getDocumentTaxExclude(this.props.formValues)
				);

				changeDocumentTotalWithTax(this.props.change, documentTotalWithTax);

				changeDocumentTotalTax(this.props.change, documentTotalTax);

				changeDocumentTotalNoTax(
					this.props.change,
					subtract(documentTotalWithTax, documentTotalTax)
				);
			}
		}
	}

	initialize = ({ isInitializingAfterTaxExclude, isCreation }) => {
		const updatedOrderProductsIds = {};
		const initialOrdersTriggers = {};

		const documentOrdersIds = [];

		const updatedDocumentItemsIDS = [];

		let updatedOrdersPriceModified = {};

		const ordersUnInvoicedAmountAbsoluteValues = {};

		let invoiceItemsTotalAmountNoTax = 0.0;

		let allOrdersHasItemsLengthLessThanFour = true;

		const documentData = isCreation
			? this.props.invoiceData
			: this.props.currentDocument.orders;

		const ordersItemsTotalAdvance = {};

		const mapOrderIdToId = {};
		const mapOrderItemIdToId = {};

		documentData.map((order, index) => {
			const orderDiscount = order.discount ?? 0;
			const orderIncrease = order.priceIncrease ?? 0;
			const orderTotalNoTax = order.price;
			const orderID = order.orderId;

			let orderTotalAdvanceWithTax = 0;
			let orderTotalTax = 0;

			let orderHasInvoiceValue = false;
			if (
				isInitializingAfterTaxExclude &&
				(getOrderPercentForInvoicing(this.props.formValues, orderID) ||
					getOrderFixedAmountForInvoicing(this.props.formValues, orderID))
			) {
				orderHasInvoiceValue = true;
			}

			if (!isCreation) {
				mapOrderIdToId[orderID] = order.id;
			}

			let orderItemsTotalAmount = 0.0;
			let currentOrderItemsTotalAdvance = 0.0;

			invoiceItemsTotalAmountNoTax = add(invoiceItemsTotalAmountNoTax, orderTotalNoTax);

			documentOrdersIds.push(orderID);

			updatedOrderProductsIds[orderID] = [];
			initialOrdersTriggers[orderID] = { isDropdownOpen: true };
			updatedDocumentItemsIDS[orderID] = [];

			updatedOrdersPriceModified = this.updateOrderPriceModified(
				updatedOrdersPriceModified,
				orderDiscount,
				orderIncrease,
				order.orderId
			);

			const orderPrice = order.price;

			changeOrderTotalNoTax(this.props.change, orderID, orderPrice);

			const itemPercentOfInitialPrice = isCreation
				? divide(
						divide(
							multiply(orderPrice, 100),
							subtract(add(orderPrice, orderDiscount), orderIncrease)
						),
						100
				  )
				: 1;

			if (order.items.length >= 4) {
				allOrdersHasItemsLengthLessThanFour = false;
			}

			order.items.map((orderItem) => {
				const orderItemID = orderItem.orderItemId;

				const itemId = orderItem.id;

				updatedOrderProductsIds[order.orderId].push(orderItemID);
				updatedDocumentItemsIDS[order.orderId].push(orderItemID);

				const name = orderItem.name;
				const unit = orderItem.unit;
				const quantity = orderItem.quantity;
				const unitPrice = multiply(orderItem.unitPrice, itemPercentOfInitialPrice);

				let itemTaxName = orderItem.taxName;
				let itemTax = orderItem.taxPercentage;
				const catalogNumber = orderItem.catalogNumber;
				let advance = orderItem.advance;

				if (isInitializingAfterTaxExclude) {
					if (orderHasInvoiceValue) {
						advance = getItemAdvance(this.props.formValues, orderItemID);
					} else {
						advance = 0;
					}
				}

				if (isInitializingAfterTaxExclude) {
					const { taxName, taxPercentage } = orderItem.orderItem;
					itemTaxName = taxName;
					itemTax = taxPercentage;
				}

				let itemTotalPriceWithTax = 0;

				if (isInitializingAfterTaxExclude) {
					itemTotalPriceWithTax = add(advance, divide(multiply(advance, itemTax), 100));
				} else {
					itemTotalPriceWithTax = add(advance, divide(multiply(advance, itemTax), 100));
				}

				const amount = multiply(unitPrice, quantity);

				orderItemsTotalAmount = add(orderItemsTotalAmount, amount);
				currentOrderItemsTotalAdvance = add(
					currentOrderItemsTotalAdvance,
					orderItem.advance
				);

				ordersItemsTotalAdvance[orderID] = currentOrderItemsTotalAdvance;

				changeItemName(this.props.change, orderItemID, name);
				changeItemUnit(this.props.change, orderItemID, unit);
				changeItemQuantity(this.props.change, orderItemID, quantity);
				changeItemUnitPriceNoTax(this.props.change, orderItemID, unitPrice);
				changeItemTaxPercentTaxName(
					this.props.change,
					orderItemID,
					`${itemTax}_${itemTaxName}`
				);
				changeItemCatalogNumber(this.props.change, orderItemID, catalogNumber);
				changeItemTotalPriceWithTax(this.props.change, orderItemID, itemTotalPriceWithTax);
				changeItemAmountNoTax(this.props.change, orderItemID, amount);
				changeItemAdvanceNoTax(this.props.change, orderItemID, advance);

				mapOrderItemIdToId[orderItemID] = itemId;

				if (isInitializingAfterTaxExclude) {
					orderTotalAdvanceWithTax = add(orderTotalAdvanceWithTax, itemTotalPriceWithTax);
					orderTotalTax = add(
						orderTotalTax,
						this.getPercentValueAsAbsolute(itemTax, advance)
					);
				}
			});

			this.fixOrderDiscountDiff(
				orderItemsTotalAmount,
				orderTotalNoTax,
				order.items,
				itemPercentOfInitialPrice
			);

			const orderInvoicedAmountAbsoluteValue = this.calculateOrderInvoicedAmountAbsoluteValue(
				isCreation,
				order.orderInvoicedAmount,
				order.orderInvoiceMethod,
				order.orderInvoiceValue,
				orderPrice
			);

			const orderUNInvoicedAmountInPercentage = orderInvoicedAmountAbsoluteValue
				? subtract(100, divide(multiply(orderInvoicedAmountAbsoluteValue, 100), orderPrice))
				: 100;

			ordersUnInvoicedAmountAbsoluteValues[orderID] = subtract(
				orderPrice,
				orderInvoicedAmountAbsoluteValue
			);

			changeOrderUnInvoicedAmountInPercentage(
				this.props.change,
				order.orderId,
				orderUNInvoicedAmountInPercentage
			);

			changeOrderTotalAdvanceWithTax(this.props.change, orderID, orderTotalAdvanceWithTax);
			changeOrderTotalTax(this.props.change, orderID, orderTotalTax);
		});

		changeDocumentItemsIDS(this.props.change, updatedDocumentItemsIDS);
		changeDocumentOrdersIDS(this.props.change, documentOrdersIds);

		if (!isCreation) {
			changeMapOrderIdToId(this.props.change, mapOrderIdToId);
		}

		changeMapItemIdBillingItemId(this.props.change, mapOrderItemIdToId);

		const { updatedOrdersTriggers } = this.closeAllTriggersIfNeeded(
			allOrdersHasItemsLengthLessThanFour,
			initialOrdersTriggers
		);

		const updatedIsInitialized = isInitializingAfterTaxExclude
			? this.state.isInitialized
			: isCreation;

		this.setState((prevState) => {
			return {
				orderProductsIds: updatedOrderProductsIds,
				ordersTriggers: updatedOrdersTriggers,
				ordersPriceModified: updatedOrdersPriceModified,
				ordersUnInvoicedAmountAbsoluteValues,
				invoiceItemsTotalAmountNoTax,
				isInitialized: updatedIsInitialized
			};
		});
	};

	calculateOrderInvoicedAmountAbsoluteValue(
		isCreation,
		orderInvoicedAmount,
		orderInvoiceMethod,
		orderInvoiceValue,
		orderPrice
	) {
		if (isCreation) {
			return orderInvoicedAmount;
		}

		let currentDocumentOrderInvoicedAmount;

		if (orderInvoiceMethod === INVOICING_METHODS.VALUE) {
			currentDocumentOrderInvoicedAmount = orderInvoiceValue;
		} else {
			currentDocumentOrderInvoicedAmount = divide(
				multiply(orderPrice, orderInvoiceValue),
				100
			);
		}

		return subtract(orderInvoicedAmount, currentDocumentOrderInvoicedAmount);
	}

	fillInitialData() {
		let orderItemsTotalAdvance = 0.0;

		const isTaxExcluded = !this.props.currentDocument.taxIncluded;

		this.props.currentDocument.orders.map((order) => {
			const orderID = order.orderId;

			orderItemsTotalAdvance = 0.0;

			for (let i = 0; i < order.items.length; i++) {
				orderItemsTotalAdvance = add(orderItemsTotalAdvance, order.items[i].advance);
			}

			if (order.orderInvoiceMethod === INVOICING_METHODS.VALUE) {
				changeOrderInvoicingMethod(this.props.change, orderID, INVOICING_METHODS.VALUE);
				changeOrderFixedAmountForInvoicing(
					this.props.change,
					orderID,
					order.orderInvoiceValue
				);

				changeOrderFixedAmountForInvoicingRound(
					this.props.change,
					orderID,
					order.orderInvoiceValue
				);
			} else {
				changeOrderInvoicingMethod(
					this.props.change,
					orderID,
					INVOICING_METHODS.PERCENTAGE
				);
				changeOrderPercentForInvoicing(this.props.change, orderID, order.orderInvoiceValue);

				changeOrderPercentForInvoicingRound(
					this.props.change,
					orderID,
					preparePercentForDisplay(order.orderInvoiceValue)
				);
			}

			const orderAverageTax = isTaxExcluded
				? 0
				: this.state.ordersAverageTax[orderID].averageTax;
			const orderTotalTax = divide(multiply(orderItemsTotalAdvance, orderAverageTax), 100);

			const orderTotalAdvanceWithTax = add(
				orderItemsTotalAdvance,
				divide(multiply(orderItemsTotalAdvance, orderAverageTax), 100)
			);

			changeOrderTotalAdvanceWithTax(this.props.change, orderID, orderTotalAdvanceWithTax);
			changeOrderTotalTax(this.props.change, orderID, orderTotalTax);
		});
	}

	closeAllTriggersIfNeeded = (allOrdersHasItemsLengthLessThanFour, ordersTriggers) => {
		let updatedOrdersTriggers = {};
		if (!allOrdersHasItemsLengthLessThanFour) {
			for (let key in ordersTriggers) {
				updatedOrdersTriggers[key] = { isDropdownOpen: false };
			}

			return {
				updatedOrdersTriggers
			};
		}

		return {
			updatedOrdersTriggers: ordersTriggers
		};
	};

	calculateDiscountTax(documentTotalWithTax, documentTotalTax, isTaxExclude) {
		if (isTaxExclude) {
			changeDocumentDiscountTaxNotFormat(this.props.change, 0);
			changeDocumentDiscountTaxFormat(this.props.change, 0);
			return;
		}

		const documentTotalNoTax = subtract(documentTotalWithTax, documentTotalTax);

		const documentDiscountTax = divide(multiply(documentTotalTax, 100), documentTotalNoTax);

		changeDocumentDiscountTaxNotFormat(this.props.change, divide(documentDiscountTax, 100));

		changeDocumentDiscountTaxFormat(this.props.change, documentDiscountTax);
	}

	fixOrderDiscountDiff(orderItemsTotal, orderTotalNoTax, orderItems, itemPercentOfInitialPrice) {
		if (orderItemsTotal !== orderTotalNoTax) {
			const diff = subtract(orderItemsTotal, orderTotalNoTax);

			const firstItem = orderItems[0];
			const firstItemID = firstItem.orderItemId;

			const firstItemQuantity = firstItem.quantity;
			const firstItemUnitPrice = add(
				multiply(firstItem.unitPrice, itemPercentOfInitialPrice),
				divide(diff, firstItemQuantity)
			);

			const firstItemAmount = multiply(firstItemUnitPrice, firstItemQuantity);

			changeItemUnitPriceNoTax(this.props.change, firstItemID, firstItemUnitPrice);

			changeItemAmountNoTax(this.props.change, firstItemID, firstItemAmount);
		}
	}

	onFixedAmountInvoicingChange = (newFixedAmount, orderId) => {
		const oldFixedAmountValue =
			this.props.formValues[
				`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_}${orderId}`
			] ?? 0;

		const fixedAmount = formUtils.normalize.price(newFixedAmount, oldFixedAmountValue);

		const orderLeftForInvoice = Number(
			this.state.ordersUnInvoicedAmountAbsoluteValues[orderId]
		).toFixed(2);

		const orderUnInvoicedAmountInPercentage = getOrderUnInvoicedAmountInPercentage(
			this.props.formValues,
			orderId
		);

		let fixedAmountInPercentage = 0;

		if (Number(fixedAmount) === Number(orderLeftForInvoice)) {
			fixedAmountInPercentage = orderUnInvoicedAmountInPercentage;
			changeOrderFixedAmountForInvoicing(this.props.change, orderId, orderLeftForInvoice);
		} else {
			fixedAmountInPercentage = this.transformFixedAmountInPercentage(fixedAmount, orderId);
			changeOrderFixedAmountForInvoicing(this.props.change, orderId, fixedAmount);
		}

		this.updateOrderItemData(orderId, fixedAmountInPercentage);
	};

	onPercentForInvoicingChange = (newPercentForInvoicing, orderId) => {
		const oldPercentForInvoicingValue =
			this.props.formValues[
				`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_ROUND_}${orderId}`
			] ?? 0;

		let percentForInvoicing = formUtils.normalize.percent(
			newPercentForInvoicing,
			oldPercentForInvoicingValue
		);

		const orderUnInvoicedAmountInPercentage = getOrderUnInvoicedAmountInPercentage(
			this.props.formValues,
			orderId
		);

		const unInvoicedPartRounded = preparePercentForDisplay(orderUnInvoicedAmountInPercentage);

		if (Number(percentForInvoicing) === Number(unInvoicedPartRounded)) {
			percentForInvoicing = orderUnInvoicedAmountInPercentage;
			changeOrderPercentForInvoicing(
				this.props.change,
				orderId,
				orderUnInvoicedAmountInPercentage
			);
		} else {
			changeOrderPercentForInvoicing(this.props.change, orderId, percentForInvoicing);
		}

		this.updateOrderItemData(orderId, percentForInvoicing);
	};

	updateOrderItemData = (orderID, orderPercentForInvoicing) => {
		const orderItemsIDS = this.state.orderProductsIds[orderID];

		const orderPrice = getOrderTotal(this.props.formValues, orderID);

		let orderTotalAdvance = 0.0;
		let orderTotalAdvanceTax = 0.0;

		for (let i = 0; i < orderItemsIDS.length; i++) {
			const currentItemID = orderItemsIDS[i];

			const { itemAdvance, itemTotalAdvanceWithTax, itemTaxAbs } =
				this.getProductAdvanceTotalAdvance(
					currentItemID,
					orderPercentForInvoicing,
					orderPrice
				);

			orderTotalAdvance = add(orderTotalAdvance, itemTotalAdvanceWithTax);
			orderTotalAdvanceTax = add(orderTotalAdvanceTax, itemTaxAbs);

			changeItemAdvanceNoTax(this.props.change, currentItemID, itemAdvance);

			changeItemTotalPriceWithTax(this.props.change, currentItemID, itemTotalAdvanceWithTax);
		}

		changeOrderTotalAdvanceWithTax(this.props.change, orderID, orderTotalAdvance);

		changeOrderTotalTax(this.props.change, orderID, orderTotalAdvanceTax);

		// this.fixOrderRoundingDiff(
		// 	orderAdvanceFormatted,
		// 	orderTotalAdvanceFormatted,
		// 	orderTotalAdvanceTaxFormatted,
		// 	lastItemID,
		// 	orderTotalAdvanceInitial,
		// 	orderTotalAdvanceTaxInitial,
		// 	lastItemInitialAdvance,
		// 	lastItemInitialTotalAdvanceWithTax
		// );
	};

	getProductAdvanceTotalAdvance = (itemID, percentForInvoicing) => {
		const formValues = this.props.formValues;
		const itemAmount = getItemAmountNoTax(formValues, itemID);
		const itemTaxPercent = getItemTax(formValues, itemID);
		const itemAdvance = this.getPercentValueAsAbsolute(percentForInvoicing, itemAmount);
		const itemTaxAbs = this.getPercentValueAsAbsolute(itemTaxPercent, itemAdvance);
		const itemTotalAdvanceWithTax = add(itemAdvance, itemTaxAbs);

		return {
			itemAdvance,
			itemTotalAdvanceWithTax,
			itemTaxAbs
		};
	};

	onInvoicingMethodChange = (val, orderId) => {
		if (!val || !val.trim().length) {
			changeOrderFixedAmountForInvoicingRound(this.props.change, orderId, '');
			changeOrderFixedAmountForInvoicing(this.props.change, orderId, '');
			changeOrderPercentForInvoicing(this.props.change, orderId, '');
			changeOrderPercentForInvoicingRound(this.props.change, orderId, '');
			changeOrderTotalTax(this.props.change, orderId, 0);
			changeOrderTotalAdvanceWithTax(this.props.change, orderId, 0);
			return;
		}
		if (val !== INVOICING_METHODS.VALUE) {
			changeOrderFixedAmountForInvoicingRound(this.props.change, orderId, '');
			changeOrderFixedAmountForInvoicing(this.props.change, orderId, '');
		} else {
			changeOrderPercentForInvoicing(this.props.change, orderId, '');
			changeOrderPercentForInvoicingRound(this.props.change, orderId, '');
		}
		changeOrderTotalTax(this.props.change, orderId, 0);
		changeOrderTotalAdvanceWithTax(this.props.change, orderId, 0);
	};

	onTriggerClick = (orderId) => {
		const updatedOrderTriggers = {
			...this.state.ordersTriggers,
			[orderId]: {
				isDropdownOpen: !this.state.ordersTriggers[orderId].isDropdownOpen
			}
		};

		this.setState((prevState) => {
			return { ...prevState, ordersTriggers: updatedOrderTriggers };
		});
	};

	setOrdersAverageTaxesInvoiceDiscountTax() {
		const invoiceItemsTotalAmountNoTax = this.state.invoiceItemsTotalAmountNoTax;
		const formValues = this.props.formValues;

		let ordersAverageTax = {};
		let documentDiscountTax = 0.0;

		const documentData =
			this.props.mode === BILLING_DOCUMENT_MODE.CREATE
				? this.props.invoiceData
				: this.props.currentDocument.orders;

		documentData.map((order) => {
			let orderAverageTax = 0.0;
			const orderTotalNoTax = order.price;

			order.items.map((orderItem) => {
				const itemID = orderItem.orderItemId;
				const itemAmount = getItemAmountNoTax(formValues, itemID);
				// const itemTax = getItemTax(formValues, itemID);

				const { taxPercentage } = orderItem.orderItem;

				const itemPercentOfOrderTotal = divide(multiply(itemAmount, 100), orderTotalNoTax);

				orderAverageTax = add(
					orderAverageTax,
					divide(multiply(itemPercentOfOrderTotal, taxPercentage), 100)
				);
			});

			ordersAverageTax[order.orderId] = {
				averageTax: orderAverageTax,
				orderItemsTotal: orderTotalNoTax
			};

			const orderTotalPercentOfInvoiceTotal = divide(
				multiply(orderTotalNoTax, 100),
				invoiceItemsTotalAmountNoTax
			);

			documentDiscountTax = add(
				documentDiscountTax,
				divide(multiply(orderTotalPercentOfInvoiceTotal, orderAverageTax), 100)
			);
		});

		const documentDiscount =
			this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND];

		//in case taxExclude
		if (documentDiscount && documentDiscount.length) {
			const updatedDiscount = add(
				documentDiscount,
				divide(multiply(documentDiscount, documentDiscountTax), 100)
			);

			changeDocumentDiscountWithTax(this.props.change, updatedDiscount);
		}

		this.setState((prevState) => {
			return {
				...prevState,
				ordersAverageTax,
				isInitialized: true
			};
		});
	}

	resetOrderProductsAdvance = (orderID) => {
		const orderProductsIDS = this.state.orderProductsIds[orderID];

		for (let productID of orderProductsIDS) {
			changeItemAdvanceNoTax(this.props.change, productID, 0);

			changeItemTotalPriceWithTax(this.props.change, productID, 0);
		}
	};

	updateOrderPriceModified(orderPriceModified, orderDiscount, orderIncrease, orderID) {
		if (orderDiscount > 0 && orderIncrease > 0) {
			return {
				...orderPriceModified,
				[orderID]: {
					type: ORDER_PRICE_MODIFIED.INCREASE_AND_DECREASE,
					amount: orderIncrease
				}
			};
		} else if (orderDiscount > 0) {
			return {
				...orderPriceModified,
				[orderID]: { type: ORDER_PRICE_MODIFIED.DECREASE, amount: orderDiscount }
			};
		} else if (orderIncrease > 0) {
			return {
				...orderPriceModified,
				[orderID]: { type: ORDER_PRICE_MODIFIED.INCREASE, amount: orderIncrease }
			};
		}

		return orderPriceModified;
	}

	transformFixedAmountInPercentage = (fixedAmount, orderID) => {
		if (!fixedAmount || !fixedAmount.toString().length) {
			return 0;
		}

		const orderPrice = getOrderTotal(this.props.formValues, orderID);

		const fixedAmountInPercentage = this.getAbsoluteValueAsPercent(fixedAmount, orderPrice);

		return fixedAmountInPercentage;
	};

	transformPercentageInFixedAmount = (percentage, orderID) => {
		if (!percentage || !percentage.length) {
			return 0;
		}
		const orderPrice = Number(
			this.props.formValues[`${INVOICE_FIELD_NAME.ORDER_TOTAL_NO_TAX_}${orderID}`] ?? 0
		);

		const fixedAmount = multiply(divide(multiply(orderPrice, percentage), 100), 1.0);

		return fixedAmount;
	};

	getAbsoluteValueAsPercent(absoluteValue, all) {
		return divide(multiply(100, absoluteValue), all);
	}

	getPercentValueAsAbsolute(percent, allAbsoluteValue) {
		return divide(multiply(percent, allAbsoluteValue), 100);
	}

	renderTotal = (orderId, orderNumber) => {
		const visible =
			this.state.ordersTriggers[orderId] && this.state.ordersTriggers[orderId].isDropdownOpen;

		if (!visible) {
			return null;
		}

		const orderTotal = parseInvoicingCurrency(
			getOrderTotalAdvanceWithTax(this.props.formValues, orderId),
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);

		const totalText = getDocumentTaxExclude(this.props.formValues)
			? i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.orderTotalExcludeTaxFooter',
					{ orderId: orderNumber }
			  )
			: i18n.t('translation:billingModule.invoicing.table.orderInvoice.orderTotalFooter', {
					taxType: getSelectedTaxType(this.props.billingSettings.taxType),
					orderId: orderNumber
			  });

		return (
			<tr className="order-invoice-total">
				<td className={'pl-xl-4 text-right'} style={{ border: 'none' }} colSpan={13}>
					{totalText}
				</td>
				<td
					className={'pr-xl-4 text-left d-flex align-items-center'}
					style={{ border: 'none' }}
					colSpan={3}>
					{orderTotal}
					<FontAwesomeIcon
						icon={faInfoCircle}
						className={'note-icon mb-xs ml-xxs'}
						title={i18n.t(
							'translation:billingModule.common.orderInvoiceTotalPriceTooltip'
						)}
					/>
				</td>
				<td className={'order-info'} style={{ border: 'none' }} colSpan={2}></td>
			</tr>
		);
	};

	renderData = () => {
		let data = [];
		if (this.props.mode === BILLING_DOCUMENT_MODE.CREATE) {
			data = this.props.invoiceData;
		} else {
			data = this.props.currentDocument?.orders ?? [];
		}
		return data.map((order, index) => {
			return this.renderOrder(order, index);
		});
	};

	renderOrder = (order, index) => {
		return (
			<Fragment key={`${index}=order`}>
				{this.renderOrderData(order)}
				{this.renderOrderItems(order)}
				{this.renderTotal(order.orderId, order.labOrderId)}
			</Fragment>
		);
	};

	renderOrderPrice = (order) => {
		const orderPriceModified = this.state.ordersPriceModified[order.orderId];

		return (
			<OrderValueModified
				modificationType={orderPriceModified}
				value={parseInvoicingCurrency(
					getOrderTotal(this.props.formValues, order.orderId),
					this.props.localizedCurrencyAbbreviation,
					this.props.currencySymbol
				)}
				colSpan={3}
			/>
		);
	};

	renderOrderData = (order) => {
		const readableInput = isReadableInput(this.props.mode);

		return (
			<tr className={'order-info'}>
				<td className={'order-info'} colSpan={1}>
					{order.labOrderId}
				</td>
				{this.renderOrderPrice(order)}
				<td className={'order-info'} colSpan={2}>
					{this.renderInvoicedPart(
						order.orderId,
						this.props.localizedCurrencyAbbreviation
					)}
				</td>
				<td className={'order-info'} colSpan={2}>
					{this.renderUnInvoicedPart(
						order.orderId,
						this.props.localizedCurrencyAbbreviation
					)}
				</td>
				<td className={'order-info'} colSpan={2}>
					{this.renderInvoiceMethodField(order.orderId, readableInput)}
				</td>
				<td className={'order-info'} colSpan={2}>
					{this.renderPercentInvoicingField(order.orderId, readableInput)}
				</td>
				<td className={'order-info'} colSpan={3}>
					{this.renderFixedAmountInvoicing(
						order.orderId,
						this.props.localizedCurrencyAbbreviation,
						readableInput
					)}
				</td>
				<td className={'order-info'} colSpan={1}>
					{this.renderTrigger(order.orderId)}
				</td>
			</tr>
		);
	};

	renderOrderItems = (order) => {
		const visible =
			this.state.ordersTriggers[order.orderId] &&
			this.state.ordersTriggers[order.orderId].isDropdownOpen;

		if (!visible) {
			return null;
		}

		return (
			<Fragment>
				<tr className={'prod-info'}>
					<OrderItemsHeader />
				</tr>
				{order.items.map((orderItem) => (
					<OrderItemRow
						key={'keyOrIt' + orderItem.orderItemId}
						orderPriceModified={this.state.ordersPriceModified[order.orderId]}
						orderItemId={orderItem.orderItemId}
						localizedCurrencyAbbreviation={this.props.localizedCurrencyAbbreviation}
						currencySymbol={this.props.currencySymbol}
					/>
				))}
			</Fragment>
		);
	};

	renderUnInvoicedPart(orderID, currencySuffix) {
		const orderInvoicingMethod = getOrderInvoicingMethod(this.props.formValues, orderID);

		let unInvoicedPart = getOrderUnInvoicedAmountInPercentage(this.props.formValues, orderID);

		if (orderInvoicingMethod === INVOICING_METHODS.PERCENTAGE) {
			const unInvoicedPartForDisplay = preparePercentForDisplay(unInvoicedPart);
			return `${unInvoicedPartForDisplay}%`;
		}

		unInvoicedPart = this.state.ordersUnInvoicedAmountAbsoluteValues[orderID];

		return parseInvoicingCurrency(
			unInvoicedPart,
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);
	}

	renderInvoicedPart(orderID, currencySuffix) {
		const orderInvoicingMethod = getOrderInvoicingMethod(this.props.formValues, orderID);
		const orderUnInvoicedAmountInPercentage = getOrderUnInvoicedAmountInPercentage(
			this.props.formValues,
			orderID
		);
		const orderTotal = getOrderTotal(this.props.formValues, orderID);

		let invoicedPart;

		if (orderInvoicingMethod === INVOICING_METHODS.PERCENTAGE) {
			invoicedPart = subtract(100, orderUnInvoicedAmountInPercentage);

			return `${preparePercentForDisplay(invoicedPart)}%`;
		}

		invoicedPart = subtract(
			orderTotal,
			this.state.ordersUnInvoicedAmountAbsoluteValues[orderID]
		);

		return parseInvoicingCurrency(
			invoicedPart,
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);
	}

	renderInvoiceMethodField = (orderId, readableInput) =>
		Fields.billingModule.invoicing.invoicingMethod(orderId, {
			options: formUtils.options.getInvoicingMethodOptions(),
			noLabel: true,
			onChange: (e) => this.onInvoicingMethodChange(e.target.value, orderId),
			validate: formUtils.validateRequiredTableField,
			readableInput
		});

	renderPercentInvoicingField = (orderId, readableInput) => {
		const orderInvoicingMethod = getOrderInvoicingMethod(this.props.formValues, orderId);
		const disabled = orderInvoicingMethod !== INVOICING_METHODS.PERCENTAGE;

		if (disabled && this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return null;
		}

		return Fields.billingModule.invoicing.percentInvoicing(orderId, {
			noLabel: true,
			disabled,
			onChange: (e) => this.onPercentForInvoicingChange(e.target.value, orderId),
			validate: (val) => this.validatePercentInvoicingField(val, orderId),
			readableInput
		});
	};

	renderFixedAmountInvoicing = (orderId, currencySuffix, readableInput) => {
		const orderInvoicingMethod = getOrderInvoicingMethod(this.props.formValues, orderId);
		const disabled = orderInvoicingMethod !== INVOICING_METHODS.VALUE;

		if (disabled && this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return null;
		}

		return Fields.billingModule.invoicing.fixedAmountInvoicing(orderId, {
			onChange: (e) => this.onFixedAmountInvoicingChange(e.target.value, orderId),
			noLabel: true,
			disabled,
			validate: (val) => this.validateFixedAmountInvoicingField(val, orderId),
			readableInput,
			localizedCurrencyAbbreviation: this.props.localizedCurrencyAbbreviation,
			currencySymbol: this.props.currencySymbol
		});
	};

	renderTrigger = (orderId) => {
		if (this.state.ordersTriggers[orderId]) {
			return this.state.ordersTriggers[orderId].isDropdownOpen ? (
				<span
					className="d-flex arrow-up arrow-light align-items-center"
					onClick={() => this.onTriggerClick(orderId)}
				/>
			) : (
				<span
					className="d-flex arrow-down arrow-light align-items-center"
					onClick={() => this.onTriggerClick(orderId)}
				/>
			);
		}

		return null;
	};

	validatePercentInvoicingField = (value, orderId) => {
		const orderUnInvoicedPart = Number(
			this.props.formValues[
				`${INVOICE_FIELD_NAME.ORDER_UN_INVOICED_AMOUNT_IN_PERCENTAGE_}${orderId}`
			] ?? 0
		).toFixed(2);
		const orderInvoiceMethod =
			this.props.formValues[`${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderId}`];

		if (orderInvoiceMethod === INVOICING_METHODS.VALUE) {
			return undefined;
		}

		if (
			value &&
			value.toString().trim().length &&
			value !== 0 &&
			Number(value) <= Number(orderUnInvoicedPart)
		) {
			return undefined;
		}

		return ' ';
	};

	validateFixedAmountInvoicingField = (value, orderId) => {
		const orderInvoiceMethod =
			this.props.formValues[`${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderId}`];

		if (orderInvoiceMethod === INVOICING_METHODS.PERCENTAGE) {
			return undefined;
		}

		const unInvoicedPartAbs = this.state.ordersUnInvoicedAmountAbsoluteValues[orderId];

		if (value && value.toString().trim().length && value !== 0) {
			if (Number(value) <= Number(unInvoicedPartAbs)) {
				return undefined;
			}
		}

		return ' ';
	};

	renderInvoiceTotal() {
		// const total = preparePriceForDisplay(
		// 	this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX],
		// 	this.props.currentLab,
		// 	true
		// );

		const invoiceNumber = this.props.formValues[INVOICE_FIELD_NAME.INVOICE_NUMBER];

		const totalText = getDocumentTaxExclude(this.props.formValues)
			? i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.invoiceTotalExcludeTaxFooter',
					invoiceNumber
			  )
			: i18n.t('translation:billingModule.invoicing.table.orderInvoice.invoiceTotalFooter', {
					taxType: getSelectedTaxType(this.props.billingSettings.taxType),
					invoiceNumber
			  });

		return (
			<tr className="order-invoice-total">
				<td className={'pl-xl-4 text-right'} colSpan={7}>
					{totalText}
				</td>
				<td className={'pr-xl-4 text-left'} colSpan={7}>
					{/*{total}*/}
				</td>
			</tr>
		);
	}

	render() {
		return (
			<table className="table col-12 mt-m order-invoice-table position-relative">
				<Header />
				<tbody>
					{this.renderData()}
					{/*{this.renderInvoiceTotal()}*/}
				</tbody>
			</table>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		taxGroups: state.billingSettings.taxGroups,
		invoiceData: state.billingDocument.invoiceData,
		billingSettings: state.billingSettings,
		currentDocument: state.billingDocument.currentDocument
	};
};

export default connect(mapStateToProps, { change })(OrderInvoiceTable);
