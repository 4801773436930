import React from 'react';
import i18n from '../../../../../../../i18n';
import { renderSimpleTable } from '../../../../../../../utils/tableUtils';
import {
	BILLING_DOCUMENT_TYPES,
	COUNTRY_CODES_BILLING_MODULE,
	INVOICE_FIELD_NAME
} from '../../../../../../../utils/constants';
import { Fields } from '../../../../../../common/Fields';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import SubHeader from '../../../../../../common/SubHeader';
import { getSecondThirdLangAbbreviation } from './localization/localisationUtils';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	changeLocaliseBankName,
	changeLocaliseComposerBy,
	changeLocaliseDiscountDescription,
	changeLocaliseDocumentReason,
	changeLocaliseItemName,
	changeLocaliseNotes,
	changeLocaliseReceiverAddress,
	changeLocaliseReceiverName,
	changeLocaliseReceiverResponsiblePerson,
	changeLocaliseSupplierAddress,
	changeLocaliseSupplierName,
	changeLocaliseSupplierResponsiblePerson,
	changeLocaliseTaxNotIncludedReason,
	changeLocaliseVerbally,
	getBankName,
	getComposedBy,
	getDocumentDiscountNote,
	getDocumentItemsIDS,
	getDocumentReason,
	getDocumentTaxExclude,
	getItemName,
	getReceiverAddress,
	getReceiverName,
	getReceiverResponsiblePerson,
	getSupplierAddress,
	getSupplierName,
	getSupplierResponsiblePerson,
	getTaxExcludeReason
} from '../../../../../../../utils/formUtils';
import { loadLanguage } from '../../../../../../../actions';

class LocalizationTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			headerColumns: {
				nameOfLocalizedField: {
					content: i18n.t(
						'translation:billingModule.invoicing.table.localization.nameOfLocalizedField'
					)
				},
				bgLanguage: {
					content: i18n.t(
						'translation:billingModule.invoicing.table.localization.bgLanguage'
					)
				},
				enLanguage: {
					content: i18n.t(
						'translation:billingModule.invoicing.table.localization.enLanguage'
					)
				},
				deLanguage: {
					content: i18n.t(
						'translation:billingModule.invoicing.table.localization.deLanguage'
					)
				},
				trLanguage: {
					content: i18n.t(
						'translation:billingModule.invoicing.table.localization.trLanguage'
					)
				}
			},
			firstColumnRows: [
				i18n.t('translation:billingModule.invoicing.composedBy'),
				`${i18n.t('translation:billingModule.invoicing.supplier')}/${i18n.t(
					'translation:billingModule.invoicing.common.companyName'
				)}`,
				`${i18n.t('translation:billingModule.invoicing.supplier')}/${i18n.t(
					'translation:billingModule.invoicing.common.registrationAddress'
				)}`,
				`${i18n.t('translation:billingModule.invoicing.supplier')}/${i18n.t(
					'translation:billingModule.invoicing.common.responsiblePerson'
				)}`,

				i18n.t('translation:billingModule.invoicing.verbally'),
				i18n.t('translation:billingModule.invoicing.bankName'),
				i18n.t('translation:billingModule.invoicing.table.localization.item'),

				`${i18n.t('translation:billingModule.invoicing.recipient')}/${i18n.t(
					'translation:billingModule.invoicing.common.companyName'
				)}`,
				`${i18n.t('translation:billingModule.invoicing.recipient')}/${i18n.t(
					'translation:billingModule.invoicing.common.registrationAddress'
				)}`,
				`${i18n.t('translation:billingModule.invoicing.recipient')}/${i18n.t(
					'translation:billingModule.invoicing.common.responsiblePerson'
				)}`,

				i18n.t('translation:billingModule.common.noteDiscount'),
				i18n.t('translation:billingModule.invoicing.table.creditNote.invoiceReason'),
				i18n.t('translation:billingModule.invoicing.reasonForTaxExclude'),

				i18n.t('translation:billingModule.invoicing.notes')
			],
			isLocalisationSectionOpen: false
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentDocument !== this.props.currentDocument) {
			this.fillInitialData(this.props.currentDocument.localizations);
		}
	}

	fillInitialData(localizations) {
		localizations.forEach(
			({
				language,
				bankName,
				creator,
				documentReason,
				discountDescription,
				issuerAddress,
				issuerName,
				issuerOwner,
				taxNotIncludedReason,
				totalInText,
				items,
				receiverName,
				receiverOwner,
				receiverAddress,
				notes
			}) => {
				changeLocaliseBankName(this.props.change, language, bankName);
				changeLocaliseComposerBy(this.props.change, language, creator);
				changeLocaliseDocumentReason(this.props.change, language, documentReason);
				changeLocaliseDiscountDescription(this.props.change, language, discountDescription);
				changeLocaliseSupplierAddress(this.props.change, language, issuerAddress);
				changeLocaliseSupplierName(this.props.change, language, issuerName);
				changeLocaliseSupplierResponsiblePerson(this.props.change, language, issuerOwner);
				changeLocaliseReceiverAddress(this.props.change, language, receiverAddress);
				changeLocaliseReceiverName(this.props.change, language, receiverName);
				changeLocaliseReceiverResponsiblePerson(this.props.change, language, receiverOwner);
				changeLocaliseTaxNotIncludedReason(
					this.props.change,
					language,
					taxNotIncludedReason
				);
				changeLocaliseVerbally(this.props.change, language, totalInText);
				changeLocaliseNotes(this.props.change, language, notes);

				items.forEach(({ billingItemId, id, name }) => {
					changeLocaliseItemName(this.props.change, language, billingItemId, name);
				});
			}
		);
	}

	getHeader() {
		const countryHeaderColumns = {
			[COUNTRY_CODES_BILLING_MODULE.BG]: [
				this.state.headerColumns.nameOfLocalizedField,
				this.state.headerColumns.bgLanguage,
				this.state.headerColumns.enLanguage,
				this.state.headerColumns.deLanguage,
				this.state.headerColumns.trLanguage
			],
			[COUNTRY_CODES_BILLING_MODULE.DE]: [
				this.state.headerColumns.nameOfLocalizedField,
				this.state.headerColumns.deLanguage,
				this.state.headerColumns.bgLanguage,
				this.state.headerColumns.enLanguage,
				this.state.headerColumns.trLanguage
			],
			[COUNTRY_CODES_BILLING_MODULE.TR]: [
				this.state.headerColumns.nameOfLocalizedField,
				this.state.headerColumns.trLanguage,
				this.state.headerColumns.bgLanguage,
				this.state.headerColumns.enLanguage,
				this.state.headerColumns.deLanguage
			],
			default: [
				this.state.headerColumns.nameOfLocalizedField,
				this.state.headerColumns.enLanguage,
				this.state.headerColumns.bgLanguage,
				this.state.headerColumns.deLanguage,
				this.state.headerColumns.trLanguage
			]
		};

		return countryHeaderColumns[this.props.documentLanguage] || countryHeaderColumns.default;
	}

	getKey(index, suffix) {
		return `${index}${suffix}`;
	}

	getComposedBy() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { COMPOSED_BY } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [[this.state.firstColumnRows[0]], [getComposedBy(this.props.formValues)]];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(COMPOSED_BY[abbreviation])]);
		}

		return result;
	}

	getDocumentReason() {
		const { FREE_INVOICE, ORDER_INVOICE } = BILLING_DOCUMENT_TYPES;

		if (this.props.documentType === FREE_INVOICE || this.props.documentType === ORDER_INVOICE) {
			return [];
		}

		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { DOCUMENT_REASON } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[11]],
			[getDocumentReason(this.props.formValues)]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(DOCUMENT_REASON[abbreviation])]);
		}

		return result;
	}

	getTaxExcludeReason() {
		const taxExclude = getDocumentTaxExclude(this.props.formValues);

		if (!taxExclude) {
			return [];
		}

		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { TAX_NOT_INCLUDED_REASON } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[12]],
			[getTaxExcludeReason(this.props.formValues)]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(TAX_NOT_INCLUDED_REASON[abbreviation])]);
		}

		return result;
	}

	getDiscountDescription() {
		const discountDescription = getDocumentDiscountNote(this.props.formValues);

		if (!discountDescription || !discountDescription.trim().length) {
			return [];
		}

		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { DISCOUNT_DESCRIPTION } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [[this.state.firstColumnRows[10]], [discountDescription]];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(DISCOUNT_DESCRIPTION[abbreviation])]);
		}

		return result;
	}

	getSupplierName() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { SUPPLIER_NAME } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [[this.state.firstColumnRows[1]], [getSupplierName(this.props.formValues)]];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(SUPPLIER_NAME[abbreviation])]);
		}

		return result;
	}

	getReceiverName() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { RECEIVER_NAME } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [[this.state.firstColumnRows[7]], [getReceiverName(this.props.formValues)]];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(RECEIVER_NAME[abbreviation])]);
		}

		return result;
	}

	getSupplierAddress() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { SUPPLIER_ADDRESS } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[2]],
			[getSupplierAddress(this.props.formValues)]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(SUPPLIER_ADDRESS[abbreviation])]);
		}

		return result;
	}

	getReceiverAddress() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { RECEIVER_ADDRESS } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[8]],
			[getReceiverAddress(this.props.formValues)]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(RECEIVER_ADDRESS[abbreviation])]);
		}

		return result;
	}

	getSupplierResponsiblePerson() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { SUPPLIER_RESPONSIBLE_PERSON } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[3]],
			[getSupplierResponsiblePerson(this.props.formValues)]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([
				this.renderLocalizationFormField(SUPPLIER_RESPONSIBLE_PERSON[abbreviation])
			]);
		}

		return result;
	}

	getReceiverResponsiblePerson() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { RECEIVER_RESPONSIBLE_PERSON } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[9]],
			[getReceiverResponsiblePerson(this.props.formValues)]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([
				this.renderLocalizationFormField(RECEIVER_RESPONSIBLE_PERSON[abbreviation])
			]);
		}

		return result;
	}

	getVerballyRow() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { VERBALLY } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[4]],
			[this.props.formValues[INVOICE_FIELD_NAME.VERBALLY]]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(VERBALLY[abbreviation])]);
		}

		return result;
	}

	getNotesRow() {
		const { CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (this.props.documentType === CREDIT_NOTE || this.props.documentType === DEBIT_NOTE) {
			return [];
		}

		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { NOTES } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [
			[this.state.firstColumnRows[13]],
			[this.props.formValues[INVOICE_FIELD_NAME.NOTES]]
		];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(NOTES[abbreviation])]);
		}

		return result;
	}

	getBankName() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);
		const { BANK_NAME } = INVOICE_FIELD_NAME.LOCALIZATION;

		const result = [[this.state.firstColumnRows[5]], [getBankName(this.props.formValues)]];

		for (let abbreviation of langAbbreviations) {
			result.push([this.renderLocalizationFormField(BANK_NAME[abbreviation])]);
		}

		return result;
	}

	getItemsRows() {
		const langAbbreviations = getSecondThirdLangAbbreviation(this.props.documentLanguage);

		const itemsIDS = [].concat(getDocumentItemsIDS(this.props.formValues));
		const result = [];

		for (let itemId of itemsIDS) {
			result.push(this.getItemsRowResultItem(langAbbreviations, itemId));
		}

		return result;
	}

	getItemsRowResultItem(langAbbreviations, itemId) {
		const result = [this.state.firstColumnRows[6], getItemName(this.props.formValues, itemId)];
		const { ITEM_NAME } = INVOICE_FIELD_NAME.LOCALIZATION;

		for (let abbreviation of langAbbreviations) {
			result.push(this.renderLocalizationFormField(`${ITEM_NAME[abbreviation]}${itemId}`));
		}

		return result;
	}

	triggerLocalisations() {
		this.setState((prevState) => {
			return {
				...prevState,
				isLocalisationSectionOpen: !prevState.isLocalisationSectionOpen
			};
		});
	}

	renderLocalizationFormField(formFieldName) {
		return Fields.billingModule.invoicing.invoiceLocalizationField({
			name: formFieldName
		});
	}

	isFreeInvoice() {
		return this.props.documentType === BILLING_DOCUMENT_TYPES.FREE_INVOICE;
	}

	renderContent() {
		if (this.state.isLocalisationSectionOpen) {
			if (this.isFreeInvoice()) {
				return (
					<div className={'mt-4'}>
						{renderSimpleTable(
							[
								this.getComposedBy(),
								this.getReceiverName(),
								this.getReceiverAddress(),
								this.getReceiverResponsiblePerson(),
								this.getSupplierName(),
								this.getSupplierAddress(),
								this.getSupplierResponsiblePerson(),
								...this.getItemsRows(),
								this.getDiscountDescription(),
								this.getVerballyRow(),
								this.getNotesRow(),
								this.getTaxExcludeReason(),
								this.getBankName()
							],
							'localisation-table',
							this.getHeader()
						)}
					</div>
				);
			}

			return (
				<div className={'mt-4'}>
					{renderSimpleTable(
						[
							this.getDocumentReason(),
							this.getComposedBy(),
							this.getReceiverName(),
							this.getReceiverAddress(),
							this.getReceiverResponsiblePerson(),
							this.getSupplierName(),
							this.getSupplierAddress(),
							this.getSupplierResponsiblePerson(),
							this.getDiscountDescription(),
							this.getVerballyRow(),
							this.getNotesRow(),
							this.getTaxExcludeReason(),
							this.getBankName()
						],
						'localisation-table',
						this.getHeader()
					)}
				</div>
			);
		}

		return null;
	}

	render() {
		return (
			<div>
				{this.renderSubHeader()}
				{this.renderContent()}
			</div>
		);
	}

	renderSubHeader() {
		return (
			<div className={'d-flex'}>
				<SubHeader
					title={i18n.t(
						'translation:billingModule.invoicing.table.localization.subHeader'
					)}
					onTrigger={() => this.triggerLocalisations()}
					isOpen={this.state.isLocalisationSectionOpen}
					buttons={[]}
					className={'blue left-icon cursor-pointer'}
					icon={
						<FontAwesomeIcon
							datatoggle="tooltip"
							dataplacement="right"
							title={i18n.t(
								'translation:billingModule.invoicing.table.localization.localizationSubHeaderTooltip'
							)}
							icon={faGlobe}
							color={'#6598be'}
							style={{ marginRight: '1rem', cursor: 'pointer' }}
						/>
					}
					leftIcon={true}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		documentLanguage: state.billingDocument.currentDocument?.language || loadLanguage(),
		invoiceData: state.billingDocument.invoiceData,
		currentDocument: state.billingDocument.currentDocument
	};
};

export default connect(mapStateToProps, { change })(LocalizationTable);
