import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import { getName, isEmptyObject, isGlobalAdmin, registeredInClinic } from '../../utils/appUtils';
import { clearReducers, getUserDetails, logout } from '../../actions';
import FlagDropdown from './LanguageSelector';
import navigationUtils from '../../utils/navigationUtils';
import { getFileLocation } from '../../utils/fileUtils';
import { PAGES_PATHS } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../../resources/images/logo.png';

class HeaderBar extends React.Component {
	state = { avatarRenderError: false };

	render = () => (
		<div className="fixed-top header-bar-container d-flex pt-xxs pb-xxs align-items-center">
			<img
				src={logo}
				alt=""
				className="logo"
				onClick={() => {
					if (navigationUtils.getRoute() !== PAGES_PATHS.HOME) {
						navigationUtils.navigate(PAGES_PATHS.HOME);
					}
				}}
			/>
			{this.renderCompanyName()}
			<div className="ml-auto header-bar-text d-flex align-items-center mr-xxs">
				{this.renderAvatar()}
				{this.renderWelcomeMessage()}
			</div>
			<div className="d-flex align-items-center">
				<FlagDropdown />
				<div className="separator" />
				<div onClick={this.onLogoutClick} className="sign-out-button">
					{i18n.t('translation:common.buttons.signOut')}
				</div>
			</div>
		</div>
	);

	renderAvatar = () => {
		if (isEmptyObject(this.props.account)) {
			return null;
		}
		return (
			<div
				className="header-avatar mr-s"
				onClick={() => {
					this.navigateToMyAccount();
				}}>
				{this.props.account.avatarFileName && !this.state.avatarRenderError
					? this.renderAvatarImage()
					: this.renderNameInitials()}
			</div>
		);
	};

	renderAvatarImage = () => (
		<img
			src={getFileLocation(this.props.account.avatarFileName)}
			onError={this.onAvatarRenderError}
			alt=""
			className="header-avatar"
		/>
	);

	onAvatarRenderError = () => this.setState({ avatarRenderError: true });

	renderNameInitials = () => (
		<span>
			<span>{this.props.account.firstName[0]}</span>
			<span>{this.props.account.lastName[0]}</span>
		</span>
	);

	renderWelcomeMessage = () => {
		return (
			<div
				className="welcome-message"
				onClick={() => {
					this.navigateToMyAccount();
				}}>
				<span>{i18n.t('translation:common.hi')}&nbsp;</span>
				<span className="font-italic">{getName(this.props.account)}</span>
			</div>
		);
	};

	navigateToMyAccount() {
		if (navigationUtils.getRoute() !== PAGES_PATHS.MY_ACCOUNT) {
			this.props.getUserDetails(this.props.account.id);
			this.props.clearReducers();
			navigationUtils.navigate(PAGES_PATHS.MY_ACCOUNT);
		}
	}

	renderCompanyName = () => {
		const icon = registeredInClinic(this.props.account) ? (
			<FontAwesomeIcon icon={faHospitalAlt} className="header-bar-icon" />
		) : (
			<FontAwesomeIcon icon={faFlask} className="header-bar-icon" />
		);

		function getCompanyName(account) {
			return registeredInClinic(account) ? account.clinicName : account.labName;
		}

		return isGlobalAdmin(this.props.account) ? (
			<Fragment />
		) : (
			<Fragment>
				<div className="separator" />
				<div className="company-name">
					{icon}
					<span className="organization-name">{getCompanyName(this.props.account)}</span>
				</div>
			</Fragment>
		);
	};

	onLogoutClick = () => {
		this.props.logout();
	};
}

const mapStateToProps = (state) => {
	return { account: state.account, settings: state.settings };
};

export default connect(mapStateToProps, { logout, clearReducers, getUserDetails })(HeaderBar);
