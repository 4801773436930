import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
	deleteFile,
	getSupportedPhotoExtensions,
	getSupportedVideoExtensions,
	hideMessageModal,
	showMessageModal,
	uploadFile
} from '../../../actions';
import i18n from '../../../i18n';
import {
	faCamera,
	faInfoCircle,
	faPlayCircle,
	faSearchPlus,
	faVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import face from '../../../resources/icons/face.svg';
import faceProfile from '../../../resources/icons/face-profile.svg';
import SubHeader from '../../common/SubHeader';
import DefaultButton from '../../common/DefaultButton';
import FileDetailsModal from './FileDetailsModal';
import { ORDER_FILE_SUB_TYPE, SPECIAL_ORDER_FILE_TYPES } from '../../../utils/constants';
import { getFileExtension, getFileLocation } from '../../../utils/fileUtils';
import {
	canEditOrDeleteFiles,
	localizeFileDeleteWarning,
	localizeFileTypeName
} from '../../../utils/appUtils';
import videoBackgroundImage from '../../../resources/icons/video.svg';

class PredefinedFiles extends React.Component {
	state = {
		isModalOpen: false,
		currentPhoto: null
	};

	componentDidMount() {
		this.props.getSupportedPhotoExtensions();
		this.props.getSupportedVideoExtensions();
	}

	render() {
		// This is always false for predefined files - they have description that can't be changed
		const canChangePredefinedPhotoDescription = false;
		const canEditOrDeletePhotosValue = canEditOrDeleteFiles(
			this.props.account,
			this.props.currentOrder
		);
		const icon = (
			<FontAwesomeIcon
				title={i18n.t('translation:orders.predefinedFilesTooltip')}
				icon={faInfoCircle}
			/>
		);

		return (
			<Fragment>
				<SubHeader title={i18n.t('translation:orders.photosAndVideo')} icon={icon} />
				<div className="mt-s">
					<div className="row">
						{this.renderFileCell(
							ORDER_FILE_SUB_TYPE.LEFT_OCCLUSION,
							localizeFileTypeName(ORDER_FILE_SUB_TYPE.LEFT_OCCLUSION),
							faceProfile,
							'face-icon face-left-profile-icon'
						)}
						{this.renderFileCell(
							ORDER_FILE_SUB_TYPE.RIGHT_OCCLUSION,
							localizeFileTypeName(ORDER_FILE_SUB_TYPE.RIGHT_OCCLUSION),
							faceProfile,
							'face-icon'
						)}
						{this.renderFileCell(
							ORDER_FILE_SUB_TYPE.FRONT_CENTRAL,
							localizeFileTypeName(ORDER_FILE_SUB_TYPE.FRONT_CENTRAL),
							face,
							'face-icon'
						)}
						{this.renderFileCell(
							ORDER_FILE_SUB_TYPE.FRONT_CENTRAL_WITH_SLIGHT_SMILE,
							localizeFileTypeName(
								ORDER_FILE_SUB_TYPE.FRONT_CENTRAL_WITH_SLIGHT_SMILE
							),
							face,
							'face-icon'
						)}
						{this.renderFileCell(
							ORDER_FILE_SUB_TYPE.UPPER_WITH_SMILE,
							localizeFileTypeName(ORDER_FILE_SUB_TYPE.UPPER_WITH_SMILE),
							face,
							'face-icon'
						)}
						{this.renderFileCell(
							ORDER_FILE_SUB_TYPE.ARTICULATION_VIDEO,
							localizeFileTypeName(ORDER_FILE_SUB_TYPE.ARTICULATION_VIDEO),
							face,
							'face-icon'
						)}
					</div>
				</div>
				<FileDetailsModal
					isOpen={this.state.isModalOpen}
					onClose={this.triggerModal}
					onDelete={this.deletePhoto.bind(this)}
					file={this.state.currentPhoto}
					canEditFileData={canChangePredefinedPhotoDescription}
					canDeleteFiles={canEditOrDeletePhotosValue}
				/>
			</Fragment>
		);
	}

	renderFileCell(type, description, faceIconSrc, faceIconClassName) {
		const file = this.getFileByType(type);

		if (file === null) {
			return this.renderFilePlaceholder(type, description, faceIconSrc, faceIconClassName);
		} else {
			return this.renderFile(file, description, faceIconSrc, faceIconClassName);
		}
	}

	renderFilePlaceholder(type, description, faceIconSrc, faceIconClassName) {
		const { canUploadPredefinedPhotos } = this.props.currentOrder;
		const isVideoFile = this.isVideoFileSubType(type);

		// If the user is not allowed to add new predefined files then hide the action explanation label
		// and disable the click on the input field
		const labelStyle = canUploadPredefinedPhotos ? {} : { cursor: 'default' };
		let labelText = '';
		if (canUploadPredefinedPhotos) {
			labelText = isVideoFile
				? i18n.t('translation:orders.clickToUploadVideo')
				: i18n.t('translation:orders.clickToUploadPhoto');
		}
		const disabled = canUploadPredefinedPhotos ? false : true;

		return (
			<div className="col-sm">
				<div className="photo">
					<div>
						<input
							id={'photo_' + type}
							className="photo-file-input"
							type="file"
							name={'photo_' + type}
							disabled={disabled}
							onChange={(event) => {
								this.uploadFile(type, event);
							}}
						/>
						<label htmlFor={'photo_' + type} className="photo-label" style={labelStyle}>
							<div className="photo-icon-container">
								<FontAwesomeIcon
									icon={isVideoFile ? faVideo : faCamera}
									size="3x"
									className="photo-icon"
								/>
								{labelText}
							</div>
						</label>
					</div>
				</div>
				{this.renderFileDescription(description, faceIconSrc, faceIconClassName)}
			</div>
		);
	}

	renderFile(file, description, faceIconSrc, faceIconClassName) {
		const isVideoFile = this.isVideoFileSubType(file.fileSubType);
		const backgroundImage = isVideoFile
			? videoBackgroundImage
			: getFileLocation(file.thumbnailFileName);
		file.name = description;
		return (
			<div className="col-sm">
				<div className="photo photo-no-border">
					<div
						style={{
							backgroundImage: `url(${backgroundImage})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat'
						}}>
						<div
							className="photo-label photo-label-with-background"
							onClick={() => this.triggerModal(file)}>
							<div className="photo-icon-container">
								<FontAwesomeIcon
									icon={isVideoFile ? faPlayCircle : faSearchPlus}
									size="3x"
									className="photo-icon"
								/>
								{isVideoFile
									? i18n.t('translation:orders.showVideo')
									: i18n.t('translation:orders.enlargePhoto')}
							</div>
						</div>
					</div>
				</div>
				{this.renderFileDescription(description, faceIconSrc, faceIconClassName)}
			</div>
		);
	}

	renderFileDescription(description, faceIconSrc, faceIconClassName) {
		return (
			<div className="row">
				<div className="col-sm-9">{description}</div>
				<div className="col-sm-3">
					<img src={faceIconSrc} className={faceIconClassName} alt="face-icon" />
				</div>
			</div>
		);
	}

	uploadFile(fileSubType, event) {
		event.preventDefault();
		const file = event.target.files[0];
		if (file !== undefined) {
			// file === undefined when you click cancel
			const fileExtension = getFileExtension(file.name);

			if (fileSubType === ORDER_FILE_SUB_TYPE.ARTICULATION_VIDEO) {
				if (this.isSupportedVideoFile(fileExtension)) {
					this.props.uploadFile(
						this.props.currentOrder.id,
						SPECIAL_ORDER_FILE_TYPES.VIDEO,
						fileSubType,
						null,
						file,
						fileExtension
					);
				} else {
					const fileListText = this.props.videoFileExtensions.join(', ');
					const errorMessage =
						i18n.t('translation:orders.errors.notSupportedVideoFile') +
						fileListText +
						'.';
					this.props.showMessageModal(
						i18n.t('translation:common.errors.error'),
						errorMessage
					);
				}

				//reset field after CANCEL in ERROR dialog pressed
				let uploadFileField = document.getElementById('photo_' + fileSubType);
				if (uploadFileField) {
					uploadFileField.value = '';
				}
			} else if (fileSubType !== ORDER_FILE_SUB_TYPE.ADDITIONAL) {
				if (this.isSupportedImageFile(fileExtension)) {
					this.props.uploadFile(
						this.props.currentOrder.id,
						SPECIAL_ORDER_FILE_TYPES.PHOTO,
						fileSubType,
						null,
						file,
						fileExtension
					);
				} else {
					const fileListText = this.props.photoFileExtensions.join(', ');
					const errorMessage =
						i18n.t('translation:orders.errors.notSupportedPhotoFile') +
						fileListText +
						'.';
					this.props.showMessageModal(
						i18n.t('translation:common.errors.error'),
						errorMessage
					);
				}

				//reset field after CANCEL in ERROR dialog pressed
				let uploadFileField = document.getElementById('photo_' + fileSubType);
				if (uploadFileField) {
					uploadFileField.value = '';
				}
			} else {
				// Do nothing
			}
		}
	}

	isSupportedImageFile(fileExtension) {
		return this.props.photoFileExtensions.includes(fileExtension.toLowerCase());
	}

	isSupportedVideoFile(fileExtension) {
		return this.props.videoFileExtensions.includes(fileExtension.toLowerCase());
	}

	deletePhoto = (file) => {
		this.triggerModal(null);

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.deleteFile(this.props.currentOrder.id, file.id);
					this.props.hideMessageModal();
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			localizeFileDeleteWarning(file.fileType),
			[yesButton, noButton]
		);
	};

	triggerModal = (photo) => {
		this.setState({ isModalOpen: !this.state.isModalOpen, currentPhoto: photo });
	};

	getFileByType(fileType) {
		for (const file of this.props.currentOrder.files) {
			if (file.fileSubType === fileType) {
				return file;
			}
		}

		return null;
	}

	isVideoFileSubType = (fileType) => {
		return fileType === ORDER_FILE_SUB_TYPE.ARTICULATION_VIDEO;
	};
}

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		account: state.account,
		currentOrder: state.orders.currentOrder,
		photoFileExtensions: state.orders.photoFileExtensions,
		videoFileExtensions: state.orders.videoFileExtensions
	};
};

export default connect(mapStateToProps, {
	uploadFile,
	deleteFile,
	getSupportedPhotoExtensions,
	getSupportedVideoExtensions,
	showMessageModal,
	hideMessageModal
})(PredefinedFiles);
