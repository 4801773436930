import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { formUtils } from '../../../../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../../../../common/Fields';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import DataRow from '../../../../common/DataRow';
import { isEmptyObject } from '../../../../../utils/appUtils';
import { getLabApprovedStaff, setPagination } from '../../../../../actions';

class Filters extends React.Component {
	componentDidMount() {
		const id = this.props.account.labId ? this.props.account.labId : this.props.labId;
		this.props.getLabApprovedStaff(id);
	}

	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters pt-0">
					<form onSubmit={this.onFilterButtonClick}>{this.renderFirstFilterRow()}</form>
				</div>
			</div>
		);
	}

	getShiftsAndAbsencesOptions = () => {
		const shiftsAndAbsences = this.props.shiftsAndAbsences;
		if (!isEmptyObject(shiftsAndAbsences)) {
			const options = shiftsAndAbsences
				.filter((shiftAndAbsence) => shiftAndAbsence)
				.map((shiftAndAbsence) => {
					return {
						name: shiftAndAbsence.name,
						value: shiftAndAbsence.shiftId || shiftAndAbsence.absenceType
					};
				});
			options.unshift({ name: '', value: '' });
			return options;
		}
	};

	renderFirstFilterRow = () => {
		const performers = [];
		const users = this.props.users || [];
		for (let performer of users) {
			performers.push({
				name: performer.firstName + ' ' + performer.lastName,
				id: performer.id
			});
		}

		return (
			<div className="row m-0">
				<div className="mt-s pl-s col-xl-3 col-lg-4 col-4">
					{Fields.filterFields.performer({ className: 'mr-m', options: performers })}
				</div>
				<div className="mt-s pl-s col-xl-3 col-lg-4 col-4">
					{Fields.filterFields.shiftTypeAndAbsence({
						className: 'mr-m',
						options: this.getShiftsAndAbsencesOptions()
					})}
				</div>

				<div className="col-xl-3 col-lg-7 col-7">
					<DataRow
						data={[
							{
								title: i18n.t('translation:taskManager.common.fields.range'),
								value: this.renderIntervalFields(
									Fields.filterFields.dateFrom({ className: 'mr-s d-block' }),
									Fields.filterFields.dateTo({ className: 'mr-s d-block' })
								)
							}
						]}
						noColumns
					/>
				</div>

				<div className="filter-button">
					<DefaultButton
						title={i18n.t('translation:orders.buttons.filter')}
						onClick={this.onFilterButtonClick}
					/>
				</div>
			</div>
		);
	};

	onFilterButtonClick = (e) => {
		e.preventDefault();
		this.props.handleSubmit((formValues) => {
			this.props.setFilters(formValues);
		})();
	};

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};
}

const filtersForm = reduxForm({
	form: 'staffFiltersForm',
	validate: formUtils.validateStaffFilters,
	destroyOnUnmount: true
})(Filters);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		staff: state.staff,
		users: state.users.users.content,
		formValues: getFormValues('staffFiltersForm')(state)
	};
};

export default connect(mapStateToProps, { setPagination, getLabApprovedStaff })(filtersForm);
