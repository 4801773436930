import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import i18n from '../../../i18n';
import {
	areInSameCompany,
	getName,
	getProfession,
	isAdmin,
	isGlobalAdmin,
	isLocalAdmin,
	registeredInClinic
} from '../../../utils/appUtils';
import { getFileLocation } from '../../../utils/fileUtils';
import Header from '../../common/Header';
import Status from '../../common/Status';
import DataRow from '../../common/DataRow';
import SubHeader from '../../common/SubHeader';
import { formUtils } from '../../../utils/formUtils';
import { Fields } from '../../common/Fields';
import { NO_AVATAR, PAGES_PATHS, USER_ROLE, USER_STATUS } from '../../../utils/constants';
import UserModal from './UserModal';
import DefaultButton from '../../common/DefaultButton';
import ChangePassword from './ChangePassword';
import UserPreferences from './UserPreferences';
import {
	activateUser,
	activateUserAndAssignRole,
	changePassword,
	changeUserStatus,
	clearReducers,
	denyUser,
	getUserDetails,
	hideMessageModal,
	showMessageModal
} from '../../../actions';
import moment from '../../../utils/moment';
import navigationUtils from '../../../utils/navigationUtils';

class UserDetails extends React.Component {
	state = { isModalOpen: false };

	componentDidMount = () => {
		if (navigationUtils.getRoute() === PAGES_PATHS.MY_ACCOUNT) {
			this.props.getUserDetails(this.props.account.id);
		} else {
			this.props.getUserDetails(this.props.match.params.id);
		}
	};

	componentWillUnmount = () => {
		this.props.clearReducers();
	};

	render = () => {
		if (!this.props.currentUser) {
			return null;
		}

		return (
			<Fragment>
				<UserModal
					isModalOpen={this.state.isModalOpen}
					closeModal={this.triggerModal}
					initialValues={this.props.currentUser}
					userId={this.props.currentUser.id}
				/>
				<Header
					title={getName(this.props.currentUser)}
					backButton
					buttons={[
						this.getEditButton(),
						this.getApproveButton(),
						this.getDenyButton(),
						this.renderInactivateButton(),
						this.renderActivateButton(),
						this.renderDeactivateButton()
					]}
				/>

				{this.renderMainSection()}

				{this.renderProfessionalInfo()}

				{this.renderReceiveMarketingInformation()}

				<div className="col-12 p-0 data-section">
					<div className="row p-0">
						<div className="col-6">{this.renderChangePassword()}</div>
						<div className="col-6">{this.renderPreferences()}</div>
					</div>
				</div>
			</Fragment>
		);
	};

	renderReceiveMarketingInformation = () => {
		if (
			isGlobalAdmin(this.props.account) &&
			this.props.initialValues.receiveMarketingInformation !== null &&
			navigationUtils.getRoute() !== PAGES_PATHS.MY_ACCOUNT
		) {
			return (
				<div className="col-12 p-0 data-section">
					<div className="content mt-s">
						<div className="row p-0">
							<div className="col-12 align-self-center">
								{Fields.commonFields.receiveMarketingInformation({
									className: 'mt-0',
									readableInput: true
								})}
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	triggerModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen });
	};

	getSaveButton = () => {
		return <DefaultButton title={i18n.t('translation:common.buttons.save')} />;
	};

	getEditButton = () => {
		const { currentUser, account } = this.props;
		if (
			currentUser.id === account.id ||
			this.isAdminInShownUserOrganization(currentUser, account) ||
			(isGlobalAdmin(account) && !isGlobalAdmin(currentUser))
		) {
			return (
				<DefaultButton
					title={i18n.t('translation:common.buttons.edit')}
					onClick={() => {
						this.setState({ isModalOpen: true });
					}}
				/>
			);
		}
	};

	isAdminInShownUserOrganization = (shownUser, loggedInUser) => {
		return (
			(shownUser.clinicId &&
				loggedInUser.clinicId &&
				shownUser.clinicId === loggedInUser.clinicId &&
				isLocalAdmin(loggedInUser)) ||
			(shownUser.labId &&
				loggedInUser.labId &&
				shownUser.labId === loggedInUser.labId &&
				isLocalAdmin(loggedInUser))
		);
	};

	getApproveButton = () => {
		const { currentUser, account } = this.props;
		if (currentUser.status === USER_STATUS.WAITING_FOR_APPROVAL && isAdmin(account)) {
			return (
				<DefaultButton
					title={i18n.t('translation:users.buttons.approve')}
					onClick={this.props.handleSubmit(this.onFormSubmit)}
				/>
			);
		}
	};

	getDenyButton = () => {
		const { currentUser, account } = this.props;
		if (currentUser.status === USER_STATUS.WAITING_FOR_APPROVAL && isAdmin(account)) {
			return (
				<DefaultButton
					title={i18n.t('translation:common.buttons.deny')}
					danger
					onClick={this.onDenyButtonPress}
				/>
			);
		}
	};

	onDenyButtonPress = () => {
		const user = this.props.currentUser;
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.denyUser(user.id);
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		const confirmationMessage = i18n.t('translation:users.denyAccountWarning');
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			confirmationMessage,
			[yesButton, noButton]
		);
	};

	renderActivateButton = () => {
		if (!this.showActivateButton()) {
			return <Fragment />;
		}

		const buttonTitle = i18n.t('translation:common.buttons.activate');

		return <DefaultButton title={buttonTitle} onClick={this.onActivateButtonPress} />;
	};

	renderDeactivateButton = () => {
		if (!this.showDeactivateButton()) {
			return <Fragment />;
		}

		const buttonTitle = i18n.t('translation:common.buttons.deactivate');

		return (
			<DefaultButton
				title={buttonTitle}
				danger={true}
				onClick={this.onDeactivateButtonPress}
			/>
		);
	};

	renderInactivateButton = () => {
		if (!this.showMakeInactivateButton()) {
			return <Fragment />;
		}

		const buttonTitle = i18n.t('translation:common.buttons.makeInactive');

		return (
			<DefaultButton extraWide title={buttonTitle} onClick={this.onInactivateButtonPress} />
		);
	};

	onInactivateButtonPress = () => {
		const user = this.props.currentUser;
		const newUserStatus = USER_STATUS.INACTIVE;

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.changeUserStatus(user.id, newUserStatus);
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		const confirmationMessage = i18n.t('translation:users.inactivateAccountWarning');
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			confirmationMessage,
			[yesButton, noButton]
		);
	};

	showMakeInactivateButton = () => {
		const { account } = this.props;
		const user = this.props.currentUser;

		// Can't change status of own account. Nobody can change the status of a global admin account as well
		if (user.status === USER_STATUS.INACTIVE) {
			return false;
		}
		if (
			account.id === user.id ||
			user.status === USER_STATUS.WAITING_FOR_APPROVAL ||
			isGlobalAdmin(user)
		) {
			return false;
		} else if (isGlobalAdmin(account)) {
			return true;
		} else if (isLocalAdmin(account) && areInSameCompany(account, user)) {
			return true;
		} else {
			return false;
		}
	};

	showDeactivateButton = () => {
		const { account } = this.props;
		const user = this.props.currentUser;

		if (user.status === USER_STATUS.DEACTIVATED) {
			return false;
		}

		// Can't change status of own account. Nobody can change the status of a global admin account as well
		if (
			account.id === user.id ||
			user.status === USER_STATUS.WAITING_FOR_APPROVAL ||
			isGlobalAdmin(user)
		) {
			return false;
		}

		if (isGlobalAdmin(account)) {
			return true;
		} else if (isLocalAdmin(account) && areInSameCompany(account, user)) {
			return true;
		} else {
			return false;
		}
	};

	showActivateButton = () => {
		const { account } = this.props;
		const user = this.props.currentUser;

		if (user.status === USER_STATUS.ACTIVE) {
			return false;
		}

		// Can't change status of own account. Nobody can change the status of a global admin account as well
		if (
			account.id === user.id ||
			user.status === USER_STATUS.WAITING_FOR_APPROVAL ||
			isGlobalAdmin(user)
		) {
			return false;
		}

		if (isGlobalAdmin(account)) {
			return true;
		} else if (isLocalAdmin(account) && areInSameCompany(account, user)) {
			return true;
		} else {
			return false;
		}
	};

	canChangeStatusOfActivatedUser = () => {
		const { account } = this.props;
		const user = this.props.currentUser;

		// Can't change status of own account. Nobody can change the status of a global admin account as well
		if (
			account.id === user.id ||
			user.status === USER_STATUS.WAITING_FOR_APPROVAL ||
			isGlobalAdmin(user)
		) {
			return false;
		}

		if (isGlobalAdmin(account)) {
			return true;
		} else if (isLocalAdmin(account) && areInSameCompany(account, user)) {
			return true;
		} else {
			return false;
		}
	};

	onDeactivateButtonPress = () => {
		const user = this.props.currentUser;
		const newUserStatus = USER_STATUS.DEACTIVATED;

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.changeUserStatus(user.id, newUserStatus);
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		const confirmationMessage = i18n.t('translation:users.deactivateAccountWarning');
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			confirmationMessage,
			[yesButton, noButton]
		);
	};

	onActivateButtonPress = () => {
		const user = this.props.currentUser;
		const newUserStatus = USER_STATUS.ACTIVE;

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.changeUserStatus(user.id, newUserStatus);
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		const confirmationMessage = i18n.t('translation:users.activateAccountWarning');
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			confirmationMessage,
			[yesButton, noButton]
		);
	};

	renderMainSection = () => {
		const { currentUser } = this.props;
		const firstRow = [
			{
				title: i18n.t('translation:common.name'),
				value: getName(currentUser)
			},
			{
				title: i18n.t('translation:common.userName'),
				value: currentUser.username
			},
			{
				title: i18n.t('translation:common.status'),
				value: (
					<div className="mt-xxs">
						<Status status={currentUser.status} />
					</div>
				)
			}
		];

		const secondRow = [
			{
				title: i18n.t('translation:common.phone'),
				value: currentUser.phone
			},
			{
				title: i18n.t('translation:common.email'),
				value: currentUser.email
			},
			{
				title: i18n.t('translation:common.registrationDate'),
				value: moment(currentUser.createDate).format('L')
			}
		];

		return (
			<div className="col-12 p-0 data-section">
				<div className="content">
					<div className="row p-0">
						<div className="col-3">
							<img
								className="avatar"
								src={
									this.props.currentUser.avatarFileName
										? getFileLocation(this.props.currentUser.avatarFileName)
										: NO_AVATAR
								}
								alt=""
							/>
						</div>
						<div className="col-9 align-self-center">
							<DataRow data={firstRow} first blueLabel />
							<DataRow data={secondRow} blueLabel />
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderProfessionalInfo = () => {
		const { currentUser } = this.props;

		if (isGlobalAdmin(currentUser) || navigationUtils.getRoute() === PAGES_PATHS.MY_ACCOUNT) {
			return;
		}

		const dropdownOptions = formUtils.options.getUserRole();
		const readableInput =
			currentUser.status !== USER_STATUS.WAITING_FOR_APPROVAL || !isAdmin(this.props.account);

		const firstRow = [
			{
				title: registeredInClinic(currentUser)
					? i18n.t('translation:users.clinic')
					: i18n.t('translation:users.lab'),
				value: registeredInClinic(currentUser)
					? currentUser.clinicName
					: currentUser.labName
			},
			{
				title: i18n.t('translation:users.profession'),
				value: getProfession(currentUser)
			},
			{
				title: null,
				value: Fields.commonFields.role({
					className: 'first',
					options: dropdownOptions,
					readableInput
				})
			},
			{}
		];

		return (
			<div className="col-12 p-0 data-section">
				<SubHeader title={i18n.t('translation:users.professionalInfo')} />
				<div className="content mt-s">
					<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
						<div className="row p-0">
							<div className="col-12 align-self-center">
								<DataRow data={firstRow} first />
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	};

	onFormSubmit = (formValues) => {
		if (navigationUtils.getRoute() !== PAGES_PATHS.MY_ACCOUNT) {
			let role = formValues.role;
			if (role === null || role === '') {
				role = USER_ROLE.USER; // By default the user role should be USER
			}
			this.props.activateUserAndAssignRole(this.props.match.params.id, role);
		}
	};

	renderChangePassword = () => {
		if (navigationUtils.getRoute() !== PAGES_PATHS.MY_ACCOUNT) {
			return null;
		}

		return <ChangePassword />;
	};

	renderPreferences = () => {
		// NOTE: It is important to render the preferences component ONLY AFTER
		// the preferences aren't null/undefined. Otherwise, the lifecycle
		// of Redux Forms inside UserPreferences controller doesn't seem
		// to work properly and doesn't load the preference values in the
		// controls from the "initialValues" property
		return this.props.currentUser.preferences ? (
			<UserPreferences
				accountId={this.props.currentUser.id}
				initialValues={this.props.currentUser.preferences}
			/>
		) : null;
	};
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.users.currentUser,
		account: state.account,
		settings: state.settings,
		initialValues: {
			role: getUserRole(state),
			receiveMarketingInformation: state.users.currentUser.preferences
				? state.users.currentUser.preferences.receiveMarketingInformation
				: null
		},
		isModalOpen: state.portal.messageModal.isModalOpen
	};
};

const getUserRole = (state) => {
	return state.users.currentUser.status === USER_STATUS.ACTIVE ||
		state.users.currentUser.status === USER_STATUS.INACTIVE ||
		state.users.currentUser.status === USER_STATUS.DEACTIVATED
		? state.users.currentUser.role
		: '';
};

const userDetailsForm = reduxForm({
	form: 'userDetailsForm',
	enableReinitialize: true,
	validate: formUtils.validateUserDetails
})(UserDetails);

export default connect(mapStateToProps, {
	getUserDetails,
	activateUserAndAssignRole,
	activateUser,
	changeUserStatus,
	changePassword,
	showMessageModal,
	hideMessageModal,
	denyUser,
	clearReducers
})(userDetailsForm);
