import React from 'react';

class DownloadButton extends React.Component {
	render() {
		return (
			<a
				href={this.props.href}
				target="_blank"
				rel="noopener noreferrer"
				className="download-button"
				download>
				{this.props.title}
			</a>
		);
	}
}

export default DownloadButton;
