import React from 'react';
import ClientsDropdownField from '../../common/UI/ClientsDropdownField';
import { change, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import {
	getLinkedClinicsWithoutPagination,
	searchAllBillingDocuments,
	setCurrentDebitDocuments
} from '../../../../../actions';
import {
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_METHOD,
	TRANSACTION_STATUS
} from '../../../../../utils/constants';
import i18n from '../../../../../i18n';
import {
	customDateInput,
	invoicingTableInput,
	selectInput,
	text,
	textArea,
	textInput
} from '../../../../common/Fields';
import { formUtils } from '../../../../../utils/formUtils';
import { GET_CURRENT_DEBIT_DOCUMENTS } from '../../../../../actions/types';
import { parseInvoicingCurrency } from '../../../../../utils/tableUtils';
import { add, subtract } from '../../invoicing/billingDocumentModal/utils/documentUtils';
import { getSelectedTaxType } from '../../../../../utils/appUtils';

class IncomesDetailsFilters extends React.Component {
	constructor(props) {
		super(props);
		this.reminderRef = React.createRef();
		this.state = {
			intersecting: null
		};
	}

	componentDidMount() {
		this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);

		this.observer = new IntersectionObserver(([entry]) => {
			const updatedIntersecting = entry.isIntersecting;
			if (updatedIntersecting !== this.state.intersecting) {
				this.setState({ intersecting: updatedIntersecting });
			}
		});
		this.observer.observe(this.reminderRef?.current);
	}

	componentWillUnmount() {
		this.observer.unobserve(this.reminderRef?.current);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//on edit/preview
		if (prevProps.clinicId !== this.props.clinicId) {
			this.fillInitialData();
		}
	}

	fillInitialData() {
		const {
			TRANSACTION_CLINIC_ID,
			TRANSACTION_METHOD,
			TRANSACTION_AMOUNT,
			TRANSACTION_DATE,
			TRANSACTION_REFERENCES_NOTES,
			TRANSACTION_REMINDER,
			TOTAL_PAYED,
			TRANSACTION_COMMON_NOTES
		} = TRANSACTION_FORM_FIELD;
		const {
			clinicId,
			referencesNotes,
			transactionDate,
			transactionMethod,
			amount,
			reminder,
			billingDocuments,
			status,
			creditedAmount,
			commonNotes
		} = this.props;

		const clinic = this.props.clinics.find(
			(clinic) => clinic.id.toString() === clinicId.toString()
		);
		const clinicLegalName = clinic?.legalName;

		this.props.change('incomesDetailsForm', TRANSACTION_CLINIC_ID, clinicId);
		this.props.change('incomesDetailsForm', TRANSACTION_REFERENCES_NOTES, referencesNotes);
		this.props.change('incomesDetailsForm', TRANSACTION_METHOD, transactionMethod);
		this.props.change('incomesDetailsForm', TRANSACTION_DATE, new Date(transactionDate));
		this.props.change('incomesDetailsForm', TRANSACTION_AMOUNT, amount);
		this.props.change('incomesDetailsForm', TRANSACTION_COMMON_NOTES, commonNotes);
		this.props.change(
			'incomesDetailsForm',
			TRANSACTION_REMINDER,
			subtract(reminder, creditedAmount)
		);

		if (status === TRANSACTION_STATUS.COMPLETED || status === TRANSACTION_STATUS.CANCELED) {
			return;
		}

		let totalPayed = 0;
		for (let document of billingDocuments) {
			totalPayed = add(totalPayed, document.amount);
		}

		this.props.change('incomesDetailsForm', TOTAL_PAYED, totalPayed);

		const documentsAlreadyProceedIds = [];

		for (let document of billingDocuments) {
			documentsAlreadyProceedIds.push(document.billingDocument.id);
		}

		this.props.searchAllBillingDocuments({
			filters: {
				labId: this.props.account.labId,
				clinicLegalName,
				types: [
					BILLING_DOCUMENT_TYPES.ORDER_INVOICE,
					BILLING_DOCUMENT_TYPES.FREE_INVOICE,
					BILLING_DOCUMENT_TYPES.DEBIT_NOTE
				],
				statuses: [BILLING_DOCUMENT_STATUS.PAID_PARTIALLY, BILLING_DOCUMENT_STATUS.OPENED]
			},
			actionType: GET_CURRENT_DEBIT_DOCUMENTS,
			documentsToExclude: documentsAlreadyProceedIds
		});
	}

	isCreation() {
		return this.props.isCreation;
	}

	onClientChange = async (target) => {
		const { TRANSACTION_REMINDER, TRANSACTION_AMOUNT, TOTAL_PAYED, TRANSACTION_CLINIC_ID } =
			TRANSACTION_FORM_FIELD;
		const { options } = target;
		const clinicLegalName = options[options.selectedIndex].text;
		const clinicId = options[options.selectedIndex].value;

		const transactionAmount = this.props.formValues[TRANSACTION_AMOUNT] ?? 0;
		this.props.change('incomesDetailsForm', TRANSACTION_REMINDER, transactionAmount);
		this.props.change('incomesDetailsForm', TOTAL_PAYED, 0);
		this.props.change('incomesDetailsForm', TRANSACTION_CLINIC_ID, clinicId);

		//if empty option chosen
		if (!clinicId.toString().trim().length) {
			this.props.setCurrentDebitDocuments([]);
			return;
		}

		this.props.searchAllBillingDocuments({
			filters: {
				labId: this.props.account.labId,
				clinicLegalName,
				types: [
					BILLING_DOCUMENT_TYPES.ORDER_INVOICE,
					BILLING_DOCUMENT_TYPES.FREE_INVOICE,
					BILLING_DOCUMENT_TYPES.DEBIT_NOTE
				],
				statuses: [BILLING_DOCUMENT_STATUS.OPENED, BILLING_DOCUMENT_STATUS.PAID_PARTIALLY]
			},
			actionType: GET_CURRENT_DEBIT_DOCUMENTS,
			documentsToExclude: []
		});
	};

	getFieldsAreDisabled() {
		if (this.isCreation()) {
			return (
				this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_CLINIC_ID] === undefined
			);
		}

		return true;
	}

	renderIncomeMethod() {
		const disabled = this.getFieldsAreDisabled();

		const options = [
			{ name: '', value: null },
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.bankTransfer'),
				value: TRANSACTION_METHOD.BANK
			},
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.posTerminal'),
				value: TRANSACTION_METHOD.POS
			},
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.cash'),
				value: TRANSACTION_METHOD.CASH
			}
		];

		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_METHOD}
				label={i18n.t('translation:billingModule.incomeOutcomeDetails.common.incomeMethod')}
				customProps={{
					className: 'mr-0 col-12',
					options: options,
					blueLabel: true,
					disabled
				}}
				component={selectInput}
			/>
		);
	}

	renderIncomeDate() {
		const disabled = this.getFieldsAreDisabled();

		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_DATE}
				customProps={{
					label: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.common.incomeDate'
					),
					className: 'd-block',
					blueLabel: true,
					disabled,
					maxDate: new Date()
				}}
				component={customDateInput}
			/>
		);
	}

	renderClientsDropdown(clinics) {
		return (
			<ClientsDropdownField
				clients={clinics}
				name={TRANSACTION_FORM_FIELD.TRANSACTION_CLINIC_ID}
				onChange={(event) => this.onClientChange(event.target)}
				blueLabel={true}
				disabled={!this.isCreation()}
			/>
		);
	}

	renderAmountInput() {
		const disabled = this.getFieldsAreDisabled();
		const taxType = getSelectedTaxType(this.props.billingSettings.taxType);

		return (
			<div className={'col-4 position-relative'}>
				<label className={'blue-label'}>
					{i18n.t('translation:billingModule.incomeOutcomeDetails.common.amountWithTax', {
						taxType: taxType
					})}
				</label>
				<Field
					name={TRANSACTION_FORM_FIELD.TRANSACTION_AMOUNT}
					customProps={{ className: 'col-12 p-0 amount', blueLabel: true, disabled }}
					normalize={formUtils.normalize.price}
					component={invoicingTableInput}
					onChange={(e) => this.onAmountChange(e.target.value)}
					localizedCurrencyAbbreviation={
						this.props.currentLab.localizedCurrencyAbbreviation
					}
					currencySymbol={this.props.currentLab.currencySymbol}
				/>
			</div>
		);
	}

	renderRemind() {
		return (
			<div className="col-4" ref={this.reminderRef}>
				<label
					className="blue-label"
					title={i18n.t(
						'translation:billingModule.incomesOutcomes.table.incomeReminderTooltip'
					)}>
					{i18n.t('translation:billingModule.incomeOutcomeDetails.common.remainder')}*
				</label>
				<Field
					name={TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER}
					component={text}
					value={this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER]}
					defaultValue={i18n.t(
						'translation:billingModule.incomeOutcomeDetails.incomeDetails.pleaseAddAmount'
					)}
					currency={true}
					localizedCurrencyAbbreviation={
						this.props.currentLab.localizedCurrencyAbbreviation
					}
					currencySymbol={this.props.currentLab.currencySymbol}
				/>
			</div>
		);
	}

	renderReferenceNotes() {
		if (
			!this.isCreation() &&
			!this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REFERENCES_NOTES]
		) {
			return null;
		}
		const disabled = this.getFieldsAreDisabled();
		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_REFERENCES_NOTES}
				label={i18n.t('translation:billingModule.incomeOutcomeDetails.common.reference')}
				customProps={{ className: 'col-12', blueLabel: true, disabled: disabled }}
				component={textInput}
				maxLength={250}
			/>
		);
	}

	renderCommonNotes() {
		if (
			!this.isCreation() &&
			!this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_COMMON_NOTES]
		) {
			return null;
		}
		const disabled = this.getFieldsAreDisabled();
		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_COMMON_NOTES}
				label={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.incomeDetails.incomeNotes'
				)}
				customProps={{ className: 'col-12', blueLabel: true, disabled: disabled }}
				component={textArea}
				maxLength={250}
			/>
		);
	}

	renderPayedInOutcomes() {
		if (this.isCreation()) {
			return null;
		}

		return (
			<div className={'col-4'}>
				<div className={'data-row'}>
					<label className={'blue-label'}>
						{i18n.t(
							'translation:billingModule.incomeOutcomeDetails.table.digestedInOutcomes'
						)}
					</label>
				</div>
				<div className={'pt-2'}>
					{parseInvoicingCurrency(
						this.props.creditedAmount ?? 0,
						this.props.currentLab.localizedCurrencyAbbreviation,
						this.props.currentLab.currencySymbol
					)}
				</div>
			</div>
		);
	}

	onAmountChange(value) {
		const normalizeValue = formUtils.normalize.price(value);

		if (value !== normalizeValue) {
			return;
		}

		const totalPayed = this.props.formValues[TRANSACTION_FORM_FIELD.TOTAL_PAYED] ?? 0;
		const reminder = subtract(value, totalPayed);
		this.props.change('incomesDetailsForm', TRANSACTION_FORM_FIELD.TRANSACTION_AMOUNT, value);
		this.props.change(
			'incomesDetailsForm',
			TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER,
			reminder
		);
	}

	renderReminderBar() {
		if (
			this.state.intersecting ||
			this.props.status === TRANSACTION_STATUS.COMPLETED ||
			this.props.status === TRANSACTION_STATUS.CANCELED
		) {
			return null;
		}
		const title = i18n.t('translation:billingModule.incomeOutcomeDetails.common.remainder');

		const reminder = this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER] ?? 0;

		const isReminderErrored = Number(reminder) < 0;

		return (
			<div
				className={
					'w-100 incomes-details-reminder-fixed-top font-weight-bold text-right pr-5 shadow-sm '
				}
				style={{ zIndex: 2000, paddingLeft: '30px', paddingRight: '30px' }}>
				<div>
					{`${title}: `}
					{parseInvoicingCurrency(
						reminder,
						this.props.currentLab.localizedCurrencyAbbreviation,
						this.props.currentLab.currencySymbol
					)}
				</div>
				{isReminderErrored && (
					<div className={'p-1'} style={{ color: 'red' }}>
						{i18n.t(
							'translation:billingModule.incomeOutcomeDetails.errors.amountDistribute'
						)}
					</div>
				)}
			</div>
		);
	}

	render() {
		return (
			<div className="col-12 p-0 data-section position-relative">
				<div className="content filters incomes-details-filters">
					<div className="row pt-4">
						<div className="col-4">
							{this.renderClientsDropdown(this.props.clinics)}
						</div>
						<div className="col-4">{this.renderIncomeDate()}</div>
						<div className="col-4 p-0">{this.renderIncomeMethod()}</div>
					</div>
					<div className="row pt-4">
						{this.renderAmountInput()}
						{this.renderRemind()}
						{this.renderPayedInOutcomes()}
					</div>
					<div className="row pt-4">{this.renderReferenceNotes()}</div>
					<div className="row pt-4">{this.renderCommonNotes()}</div>
					{this.renderReminderBar()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		clinics: state.clinics.clinics,
		account: state.account,
		formValues: getFormValues('incomesDetailsForm')(state),
		currentLab: state.labs.currentLab,
		billingSettings: state.billingSettings
	};
};

export default connect(mapStateToProps, {
	getLinkedClinicsWithoutPagination,
	searchAllBillingDocuments,
	setCurrentDebitDocuments,
	change
})(IncomesDetailsFilters);
