import React, { Component } from 'react';
import i18n from '../../../../i18n';
import DropdownButton from '../../../common/DropdownButton';
import { connect } from 'react-redux';
import { exportTransactionsToExcelOrCsv } from '../../../../actions';
import {
	DOCUMENT_EXPORT_TYPE,
	FILTER_FIELDS,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_TYPE
} from '../../../../utils/constants';
import { getFormValues } from 'redux-form';
import moment from '../../../../utils/moment';
import { isEmptyObject } from '../../../../utils/appUtils';

class ExportTransactionsButton extends Component {
	onExportButtonClick = (buttonTitle) => {
		let exportTransactionsDto = {
			type: undefined,
			filter: {}
		};

		let exportTransactionsDtoFilter = exportTransactionsDto.filter;

		exportTransactionsDtoFilter.labId = Number(this.props.account.labId);

		const formValues = { ...this.props.formValues };

		if (!isEmptyObject(formValues)) {
			for (const formValuesKey in formValues) {
				const {
					TRANSACTION_NUMBER_LAB,
					BILLING_DOCUMENT_NUMBER,
					STATUSES,
					TYPE,
					CLINIC_LEGAL_NAME
				} = FILTER_FIELDS;

				if (formValuesKey === TRANSACTION_NUMBER_LAB) {
					exportTransactionsDtoFilter[formValuesKey] = Number(formValues[formValuesKey]);
				}

				if (formValuesKey === BILLING_DOCUMENT_NUMBER) {
					exportTransactionsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === STATUSES) {
					exportTransactionsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === TYPE) {
					const { CREDIT, DEBIT } = TRANSACTION_TYPE;
					let type;

					if (formValues[formValuesKey] === CREDIT) {
						type = CREDIT;
					}
					if (formValues[formValuesKey] === DEBIT) {
						type = DEBIT;
					}

					exportTransactionsDtoFilter[formValuesKey] = type;
				}

				if (formValuesKey === TRANSACTION_FORM_FIELD.TRANSACTION_REFERENCES_NOTES) {
					exportTransactionsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === BILLING_DOCUMENT_NUMBER) {
					exportTransactionsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValues[formValuesKey] instanceof Date) {
					formValues[formValuesKey] = moment(formValues[formValuesKey]).format(
						'YYYY-MM-DD'
					);
					exportTransactionsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}

				if (formValuesKey === CLINIC_LEGAL_NAME) {
					exportTransactionsDtoFilter[formValuesKey] = formValues[formValuesKey];
				}
			}
		}

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.exportToExcel')) {
			exportTransactionsDto.type = DOCUMENT_EXPORT_TYPE.EXCEL;
		} else {
			exportTransactionsDto.type = DOCUMENT_EXPORT_TYPE.CSV;
		}

		return this.props.exportTransactionsToExcelOrCsv(exportTransactionsDto);
	};

	render() {
		const buttons = [
			i18n.t('translation:billingModule.common.buttons.exportToExcel'),
			i18n.t('translation:billingModule.common.buttons.exportToCsv')
		];

		const title = i18n.t('translation:billingModule.common.buttons.export');

		return (
			<DropdownButton
				title={title}
				buttons={buttons}
				short={true}
				statusButton={false}
				isSecondButton={true}
				onButtonClick={this.onExportButtonClick}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('incomesOutcomesFiltersForm')(state)
	};
};

export default connect(mapStateToProps, { exportTransactionsToExcelOrCsv })(
	ExportTransactionsButton
);
