import React from 'react';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import { connect } from 'react-redux';
import { getSelectedTaxType } from '../../../../../../../utils/appUtils';
import {
	getCanEditRecipientSupplierName,
	isReadableInput
} from '../../../../../../../utils/billingUtils';
import { change } from 'redux-form';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';

class SupplierSection extends React.Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.currentDocument !== this.props.currentDocument &&
			this.props.currentDocument
		) {
			this.fillInitialData();
			return;
		}

		const documentMode = this.props.mode;
		const documentType = this.props.documentType;

		const isCreateModeAndCreditOrDebitNote =
			documentMode === BILLING_DOCUMENT_MODE.CREATE &&
			(documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
				documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE);

		if (prevProps.invoiceData !== this.props.invoiceData) {
			if (isCreateModeAndCreditOrDebitNote) {
				this.setClinicDetailsForCreditOrDebitNote();
			}
		}
	}

	fillInitialData() {
		const address = this.props.currentDocument.issuer.address;
		const name = this.props.currentDocument.issuer.name;
		const owner = this.props.currentDocument.issuer.owner;
		const uic = this.props.currentDocument.issuer.uic;
		const vatNumber = this.props.currentDocument.issuer.vatNumber;

		this.changeDetails({ address, name, owner, uic, vatNumber });
	}

	setClinicDetailsForCreditOrDebitNote() {
		const issuer = this.props.invoiceData[0].issuer;
		const { uic, vatNumber, address, owner, name } = issuer;

		this.changeDetails({ uic, vatNumber, address, owner, name });
	}

	changeDetails({ name, uic, vatNumber, address, owner }) {
		const labId = this.props.currentLab.labId;

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.SUPPLIER_ID, labId);
		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.SUPPLIER_COMPANY_NAME, name);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.SUPPLIER_UIC, uic);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.SUPPLIER_VAT_NUMBER, vatNumber);

		this.props.change(
			'freeInvoiceForm',
			INVOICE_FIELD_NAME.SUPPLIER_REGISTRATION_ADDRESS,
			address
		);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.SUPPLIER_RESPONSIBLE_PERSON, owner);
	}

	renderSupplierCompanyName() {
		if (this.props.mode === BILLING_DOCUMENT_MODE.CREATE) {
			const canEditSupplierName = getCanEditRecipientSupplierName(
				this.props.documentType,
				this.props.mode
			);

			return Fields.billingModule.invoicing.supplierCompanyName({
				additionalLabelClass: 'col-4 pl-0',
				readableInput: !canEditSupplierName
			});
		}

		return (
			<div className="d-flex align-items-center pt-1 pb-3 data-row">
				<label className={'left-label col-4 pl-0'}>
					{i18n.t('translation:billingModule.invoicing.common.companyName')}
				</label>
				<div>{this.props.currentDocument?.issuer?.name}</div>
			</div>
		);
	}

	render() {
		return (
			<div className="col-6 i-section">
				<div className="d-flex align-items-center w-100 custom-table-header">
					{i18n.t('translation:billingModule.invoicing.supplier')}
				</div>
				<div className="section mt-0 pt-3 pb-0">
					{this.renderSupplierCompanyName()}
					{Fields.billingModule.invoicing.uic(INVOICE_FIELD_NAME.SUPPLIER_UIC, {
						additionalLabelClass: 'col-4 pl-0',
						readableInput: isReadableInput(this.props.mode)
					})}
					{Fields.billingModule.invoicing.vatNumber(
						INVOICE_FIELD_NAME.SUPPLIER_VAT_NUMBER,
						{
							label: i18n.t('translation:billingModule.invoicing.common.vatNumber', {
								taxType: getSelectedTaxType(this.props.billingSettings.taxType)
							}),
							additionalLabelClass: 'col-4 pl-0',
							readableInput: isReadableInput(this.props.mode)
						}
					)}
					{Fields.billingModule.invoicing.registrationAddress(
						INVOICE_FIELD_NAME.SUPPLIER_REGISTRATION_ADDRESS,
						{
							additionalLabelClass: 'col-4 pl-0',
							readableInput: isReadableInput(this.props.mode)
						}
					)}
					{Fields.billingModule.invoicing.responsiblePerson(
						INVOICE_FIELD_NAME.SUPPLIER_RESPONSIBLE_PERSON,
						{
							additionalLabelClass: 'col-4 pl-0',
							readableInput: isReadableInput(this.props.mode)
						}
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		billingSettings: state.billingSettings,
		currentDocument: state.billingDocument.currentDocument,
		invoiceData: state.billingDocument.invoiceData,
		currentLab: state.labs.currentLab
	};
};

export default connect(mapStateToProps, { change })(SupplierSection);
