import { getAxiosWithToken } from '../../utils/webApi';
import {
	CLEAR_CURRENT_DOCUMENT,
	CLEAR_ORDER_INVOICE_DATA,
	CLEAR_PRE_CREATE_MODAL,
	GET_BILLING_DOCUMENT_DETAILS,
	GET_NEXT_BILLING_DOCUMENT_NUMBER,
	GET_ORDER_INVOICE_DATA
} from '../types';
import {
	isClinicAdmin,
	isEmptyObject,
	isLabAdmin,
	parseBillingDocumentType,
	showSuccessPopup
} from '../../utils/appUtils';
import moment from '../../utils/moment';
import {
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_TYPES,
	DOCUMENT_EXPORT_FILE_EXTENSION,
	DOCUMENT_EXPORT_TYPE,
	ORDER_INVOICING_STATUS
} from '../../utils/constants';
import { createCsvFileUrl, createExcelFileUrl, createPdfFileUrl, getFileName } from '../common';
import i18n from '../../i18n';
import { isValidJson } from '../../utils/fileUtils';
import {
	formatDateToYYYYMMDD,
	getDocumentDisplayNumber
} from '../../components/pages/billingModule/utils';

export const getBillingDocuments =
	({ page, filters, user, actionType }) =>
	async (dispatch) => {
		page = page - 1;
		let filterData;

		const isLabLocalAdmin = isLabAdmin(user);
		const isClinicLocalAdmin = isClinicAdmin(user);

		if (isLabLocalAdmin) {
			filterData = { ...filters, labId: user.labId };
		} else if (isClinicLocalAdmin) {
			filterData = {
				...filters,
				clinicId: user.clinicId
			};
		} else {
			throw new Error(
				'Cannot make this request with User different from Lab Local Admin or Clinic Local Admin!'
			);
		}

		if (!isEmptyObject(filterData)) {
			for (let filter in filterData) {
				if (filterData[filter] instanceof Date) {
					filterData[filter] = moment(filterData[filter]).format('YYYY-MM-DD');
				}
			}

			if (filterData.types && filterData.types.length !== 0) {
				const types = [];

				if (filterData.types.includes(BILLING_DOCUMENT_FILTER_TYPES.INVOICE)) {
					types.push(BILLING_DOCUMENT_TYPES.FREE_INVOICE);
					types.push(BILLING_DOCUMENT_TYPES.ORDER_INVOICE);
				}

				if (filterData.types.includes(BILLING_DOCUMENT_FILTER_TYPES.CREDIT_NOTE)) {
					types.push(BILLING_DOCUMENT_TYPES.CREDIT_NOTE);
				}

				if (filterData.types.includes(BILLING_DOCUMENT_FILTER_TYPES.DEBIT_NOTE)) {
					types.push(BILLING_DOCUMENT_TYPES.DEBIT_NOTE);
				}

				filterData.types = types;
			} else {
				// we need to explicitly remove types as formData
				// return it as empty array and this bugs the backend
				filterData.types = undefined;
			}
		}

		const response = await getAxiosWithToken().post('/billing/document/search', filterData, {
			params: {
				page: page
			}
		});

		if (response.data.success && actionType) {
			dispatch({
				type: actionType,
				payload: response.data.data
			});
		}
	};

export const searchAllBillingDocuments =
	({ filters, actionType, documentsToExclude }) =>
	async (dispatch) => {
		const response = await getAxiosWithToken().post('/billing/document/search-all', filters);

		let returnedData = response.data;

		let filteredData = [];
		if (!documentsToExclude.length) {
			filteredData = returnedData.data;
		} else {
			filteredData = returnedData.data.filter(({ id }) => {
				const index = documentsToExclude.findIndex((el) => el === id);
				if (index !== -1) {
					documentsToExclude.splice(index, 1);
					return false;
				}
				return true;
			});
		}

		dispatch({
			type: actionType,
			payload: filteredData
		});
	};

export const getNextDocumentNumber = (labId, callback) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/billing/document/next-number?lab-id=${labId}`);
	let returnedData = response.data;

	dispatch({
		type: GET_NEXT_BILLING_DOCUMENT_NUMBER,
		payload: returnedData.data
	});

	callback && callback(returnedData.data);
};

export const getInvoiceDueDays = (clinicId, labId, callback) => async (dispatch) => {
	const response = await getAxiosWithToken().get(
		`/billing/document/invoice-due-days?clinic-id=${clinicId}&lab-id=${labId}`
	);
	let returnedData = response.data;

	if (returnedData.success) {
		callback(returnedData.data);
	}
};

export const createInvoice = (createInvoiceRequestDto, callback) => async (dispatch) => {
	getAxiosWithToken(false)
		.post('/billing/document', createInvoiceRequestDto)
		.catch((e) => console.log(e))
		.then((res) => {
			let returnedData = res.data;

			if (returnedData.success) {
				callback({ error: null, data: res.data });
			} else {
				callback({ error: returnedData, data: null });
			}
		});
};

export const updateInvoice = (id, updateInvoiceRequestDto, callback) => async (dispatch) => {
	getAxiosWithToken(true)
		.put(`/billing/document/${id}`, updateInvoiceRequestDto)
		.catch((e) => {
			console.log(e);
		})
		.then((res) => {
			let returnedData = res.data;

			if (returnedData.success) {
				callback({ error: null, data: res.data });
			} else {
				callback({ error: returnedData, data: null });
			}
		});
};

export const updateInvoiceLocales = (invoiceId, locales, callback) => async (dispatch) => {
	getAxiosWithToken(true)
		.put(`/billing/document/${invoiceId}/locales`, locales)
		.catch((e) => {
			console.log(e);
		})
		.then((res) => {
			let returnedData = res.data;

			if (callback && returnedData.success) {
				callback({ error: null, data: res.data });
			} else {
				callback({ error: returnedData, data: null });
			}
		});
};

export const getOrderInvoiceData = (orderIds) => async (dispatch) => {
	const response = await getAxiosWithToken(false).post(
		'/billing/document/get-order-invoice-data',
		{ orderIds }
	);

	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_ORDER_INVOICE_DATA,
			payload: returnedData.data.orders
		});
	}
};

export const clearOrderInvoiceData = () => async (dispatch) => {
	dispatch({
		type: CLEAR_ORDER_INVOICE_DATA
	});
};

export const getCreditOrderInvoiceData = (invoiceIds) => async (dispatch) => {
	const response = await getAxiosWithToken(false).post(
		'/billing/document/get-invoice-credit-or-debit-note-data',
		{ invoiceIds }
	);

	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_ORDER_INVOICE_DATA,
			payload: returnedData.data.invoices
		});
	}
};

export const getBillingOrders =
	(page, filters, labId, exceptFullyInvoiced, onSuccess) => async (dispatch) => {
		page = page - 1;
		const filterData = { ...filters, labId: labId };

		if (!isEmptyObject(filters)) {
			for (let filter in filterData) {
				if (filterData[filter] instanceof Date) {
					filterData[filter] = moment(filterData[filter]).format('YYYY-MM-DD');
				}
			}

			if (
				(!filterData.statuses || filterData.statuses.length === 0) &&
				!exceptFullyInvoiced
			) {
				filterData.statuses = undefined;
			}

			if ((!filterData.statuses || filterData.statuses.length === 0) && exceptFullyInvoiced) {
				const updatedStatuses = [];
				updatedStatuses.push(ORDER_INVOICING_STATUS.PARTIALLY_INVOICED);
				updatedStatuses.push(ORDER_INVOICING_STATUS.NOT_INVOICED);
				filterData.statuses = updatedStatuses;
			} else if (
				filterData.statuses &&
				filterData.statuses.includes(ORDER_INVOICING_STATUS.FULLY_INVOICED) &&
				exceptFullyInvoiced
			) {
				const updatedStatuses = filterData.statuses.filter(
					(status) => status !== ORDER_INVOICING_STATUS.FULLY_INVOICED
				);

				if (updatedStatuses.length === 0) {
					updatedStatuses.push(ORDER_INVOICING_STATUS.PARTIALLY_INVOICED);
					updatedStatuses.push(ORDER_INVOICING_STATUS.NOT_INVOICED);
				}
				filterData.statuses = updatedStatuses;
			}
		}

		if (isEmptyObject(filters) && exceptFullyInvoiced) {
			const statuses = [];
			statuses.push(ORDER_INVOICING_STATUS.PARTIALLY_INVOICED);
			statuses.push(ORDER_INVOICING_STATUS.NOT_INVOICED);
			filterData.statuses = statuses;
		}

		const response = await getAxiosWithToken().post(
			'/billing/document/search/orders',
			filterData,
			{
				params: {
					page: page
				}
			}
		);

		let returnedData = response.data;

		if (returnedData.success && onSuccess) {
			onSuccess(returnedData.data);
		}
	};

export const clearPreCreateModalDocumentReducer = () => async (dispatch) => {
	dispatch({
		type: CLEAR_PRE_CREATE_MODAL
	});
};

export const getBillingDocumentDetails = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/billing/document/${id}`);
	let returnedData = response.data;

	dispatch({
		type: GET_BILLING_DOCUMENT_DETAILS,
		payload: returnedData.data
	});
};

export const clearCurrentDocument = () => async (dispatch) => {
	dispatch({
		type: CLEAR_CURRENT_DOCUMENT
	});
};

export const confirmBillingDocument =
	({ documentId, onSuccess }) =>
	async (dispatch) => {
		const response = await getAxiosWithToken().post(`/billing/document/${documentId}/confirm`);

		if (response.data.success && onSuccess) {
			onSuccess();
		}
	};

export const rejectBillingDocument =
	({ documentId, onSuccess }) =>
	async (dispatch) => {
		const response = await getAxiosWithToken().post(`/billing/document/${documentId}/reject`);

		if (response.data.success && onSuccess) {
			onSuccess();
		}
	};

export const exportDocumentToExcelOrCsv = (exportDocumentsDto) => async (dispatch, getState) => {
	let exportFileType;
	let isExcelFormat;

	if (exportDocumentsDto.type === DOCUMENT_EXPORT_TYPE.EXCEL) {
		exportFileType = DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL;
		isExcelFormat = true;
	} else {
		exportFileType = DOCUMENT_EXPORT_FILE_EXTENSION.CSV;
		isExcelFormat = false;
	}

	const response = await getAxiosWithToken(false).post(
		'/billing/document/export',
		exportDocumentsDto,
		{ responseType: 'arraybuffer' }
	);

	const link = document.createElement('a');

	if (isExcelFormat) {
		link.href = createExcelFileUrl(response.data);
	} else {
		link.href = createCsvFileUrl(response.data);
	}

	link.setAttribute('download', getFileName(getState().account, 'documents', exportFileType));

	document.body.appendChild(link);
	link.click();

	if (isExcelFormat) {
		showSuccessPopup(
			i18n.t('translation:billingModule.documents.successMessages.generatedExcel')
		);
	} else {
		showSuccessPopup(
			i18n.t('translation:billingModule.documents.successMessages.generatedCsv')
		);
	}
};

export const exportSingleDocumentToPdf =
	(exportPdfDocumentDto, closeModal, setServerErrorMessage, billingDocument) =>
	async (dispatch, getState) => {
		const response = await getAxiosWithToken(false).post(
			`/billing/document/${billingDocument.id}/export-pdf`,
			exportPdfDocumentDto,
			{ responseType: 'arraybuffer' }
		);

		const decodedString = String.fromCharCode.apply(null, new Uint8Array(response.data));

		if (!isValidJson(decodedString)) {
			const link = document.createElement('a');
			link.href = createPdfFileUrl(response.data);

			link.setAttribute('download', getDocumentFileName(getState().account, billingDocument));
			document.body.appendChild(link);
			link.click();

			closeModal();

			showSuccessPopup(
				i18n.t('translation:billingModule.documents.successMessages.generatedPdf')
			);
		} else {
			const obj = JSON.parse(decodedString);
			const errorMessage = obj['message'];
			setServerErrorMessage(errorMessage);
		}
	};

function getDocumentFileName(account, document) {
	const { id, number, type, issueDate } = document;

	const labName = document.labLegalName ? document.labLegalName : document.issuer.name;
	const clinicName = document.clinicLegalName ? document.clinicLegalName : document.receiver.name;

	// if current user is from Lab, show Document Receiver Name (Clinic), else
	// if the user is from Clinic, show Document Issuer Name (Lab)
	let counterparty = account.labId ? clinicName : labName;

	const formattedDate = formatDateToYYYYMMDD(moment(issueDate).toDate());
	const formattedNumber = getDocumentDisplayNumber(id, number);
	const localizedType = parseBillingDocumentType(type);

	return `${formattedDate}_${counterparty}_${formattedNumber}_${localizedType}.${DOCUMENT_EXPORT_FILE_EXTENSION.PDF}`;
}
