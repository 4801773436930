import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Tab extends React.Component {
	state = { currentTab: 0 };

	renderTabButtons = () => {
		const { tabs } = this.props;
		return tabs.map((tab, index) => {
			const buttonClass = index === this.state.currentTab ? 'active-tab' : 'not-active-tab';
			return (
				<div
					className={buttonClass}
					key={index + 'tab'}
					onClick={() => {
						this.setState({ currentTab: index });
					}}>
					<FontAwesomeIcon icon={tab.icon} />
					<span>{tab.title}</span>
				</div>
			);
		});
	};

	render() {
		const { tabs } = this.props;

		return (
			<Fragment>
				<div className="tab-buttons">{this.renderTabButtons()}</div>
				<div className="tab-content">{tabs[this.state.currentTab].content}</div>
			</Fragment>
		);
	}
}

export default Tab;
