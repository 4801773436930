import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fields } from '../../../../common/Fields';
import i18n from '../../../../../i18n';
import Modal from 'react-modal';
import { getFormValues, reduxForm } from 'redux-form';
import DefaultButton from '../../../../common/DefaultButton';
import { connect } from 'react-redux';
import { DEFAULT_BACKEND_DATE_FORMAT } from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import { editHolidays, removeHolidays, setHolidays } from '../../../../../actions';

class MarkHolidayModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
			this.props.initialize(this.props.initialValues);
		}
	}

	render() {
		const editButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.props.handleSubmit(this.onSubmit)}
			/>
		);

		const deleteButton = !this.props.initialValues.id ? null : (
			<DefaultButton
				title={i18n.t('translation:common.buttons.delete')}
				key={i18n.t('translation:common.buttons.delete')}
				onClick={this.onRemove}
				danger
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.onCloseModal();
				}}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.onCloseModal();
					}}
				/>
				<h2>{moment(this.props.initialValues.date).format('LL')}</h2>
				{Fields.commonFields.description({ className: 'w-100 mt-s' })}
				<div className="buttons">
					{editButton}
					{deleteButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	onCloseModal = () => {
		this.setState({ commonError: '' });
		this.props.destroy();
		this.props.closeModal();
	};

	onRemove = () => {
		this.props.removeHolidays(
			this.props.account.labId,
			this.props.year,
			this.onCloseModal,
			this.props.initialValues.id
		);
	};

	onSubmit = (formValues) => {
		const holidayId = this.props.initialValues.id;

		const data = {
			...formValues,
			date: moment(this.props.initialValues.date).format(DEFAULT_BACKEND_DATE_FORMAT)
		};

		if (holidayId) {
			this.props.editHolidays(
				this.props.account.labId,
				data,
				this.props.year,
				this.onCloseModal,
				holidayId
			);
		} else {
			this.props.setHolidays(
				this.props.account.labId,
				data,
				this.props.year,
				this.onCloseModal
			);
		}
	};
}

const markDaysForm = reduxForm({
	form: 'markDaysForm'
})(MarkHolidayModal);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('markDaysForm')(state)
	};
};

export default connect(mapStateToProps, {
	setHolidays,
	editHolidays,
	removeHolidays
})(markDaysForm);
