import React, { Fragment } from 'react';
import Modal from 'react-modal';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../../../common/Fields';
import {
	calculateTaskTime,
	getLabProductTemplate,
	getOrdersByOrderType,
	updateTask
} from '../../../../../actions';
import {
	getTaskStatusId,
	isEmptyObject,
	isGlobalAdmin,
	parseTaskStatus
} from '../../../../../utils/appUtils';
import { formUtils } from '../../../../../utils/formUtils';
import { PAGES_PATHS, PLANNING_STATUS } from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import navigationUtils from '../../../../../utils/navigationUtils';

class EditStaffTaskModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			saveWithConflict: false,
			isConflictSectionShown: false,
			conflictSectionMsg: [],
			readOnly: false,
			serverError: '',
			goToCalendar: false
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { initialValues } = this.props;

		if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
			let refinedInitialValues = {};
			refinedInitialValues['availableUsers'] = initialValues.id;
			refinedInitialValues['orderType'] = null;

			this.props.initialize(refinedInitialValues);
		}

		if (
			prevProps.formValues &&
			this.props.formValues &&
			prevProps.formValues.orderType !== this.props.formValues.orderType
		) {
			if (this.props.formValues.orderType !== null && this.props.formValues.orderType != 0) {
				this.props.getOrdersByOrderType(this.props.formValues.orderType);
			}
		}

		if (prevProps.orders !== this.props.orders) {
			if (this.props.formValues.orderType == 0) {
				this.props.change('ordersList', []);
			} else {
				this.props.change('ordersList', this.props.orders);
			}
		}

		if (
			prevProps.formValues &&
			this.props.formValues &&
			prevProps.formValues.ordersList !== this.props.formValues.ordersList
		) {
			this.resetFields('orderList');
		}

		if (
			prevProps.formValues &&
			this.props.formValues &&
			prevProps.formValues.orderItems !== this.props.formValues.orderItems
		) {
			this.resetFields('orderItems');
			const selectedOrder =
				this.props.orders && this.props.formValues
					? this.props.orders.find(
							(values) => values.id == this.props.formValues.ordersList
					  )
					: [];
			const orderItems =
				selectedOrder && selectedOrder.orderItems ? selectedOrder.orderItems : [];
			const selectedOrderItem = orderItems
				? orderItems.find((values) => values.id == this.props.formValues.orderItems)
				: {};

			if (
				selectedOrderItem &&
				selectedOrderItem.templateId &&
				selectedOrderItem.templateId !== 0
			) {
				this.props.getLabProductTemplate(
					selectedOrderItem.id,
					selectedOrderItem.templateId
				);
			}
		}

		if (
			prevProps.formValues &&
			this.props.formValues &&
			prevProps.formValues.orderTask !== this.props.formValues.orderTask
		) {
			const selectedOrder =
				this.props.orders && this.props.formValues
					? this.props.orders.find(
							(values) => values.id == this.props.formValues.ordersList
					  )
					: [];
			const orderItems =
				selectedOrder && selectedOrder.orderItems ? selectedOrder.orderItems : [];
			const orderItemTasks = orderItems
				? orderItems.find((values) => values.id == this.props.formValues.orderItems)
				: {};
			const selectedTask = orderItemTasks
				? orderItemTasks.tasks.find(
						(values) => values.id == this.props.formValues.orderTask
				  )
				: {};

			const id = initialValues.id;

			//reset fields before loading the values
			this.resetFields('tasks');

			if (selectedTask && selectedTask.assigneeName !== null) {
				this.props.change('taskAssignee', selectedTask.assigneeName);
			}

			if (selectedTask) {
				const status = selectedTask.status ? parseTaskStatus(selectedTask.status) : null;
				this.props.change('taskStatus', status);
			}

			if (
				selectedTask &&
				selectedTask.startTime !== undefined &&
				selectedTask.startTime !== null
			) {
				this.props.change('startDateTime_' + id, new Date(selectedTask.startTime));
			}

			if (
				selectedTask &&
				selectedTask.endTime !== undefined &&
				selectedTask.endTime !== null
			) {
				this.props.change('endDateTime_' + id, new Date(selectedTask.endTime));
			}

			if (
				selectedTask &&
				selectedTask.generalNote !== undefined &&
				selectedTask.generalNote !== null
			) {
				this.props.change('notes', selectedTask.generalNote);
			}
		}

		if (this.state.goToCalendar) {
			navigationUtils.navigate(PAGES_PATHS.CALENDAR_STAFF);
			this.setState({ goToCalendar: false });
		}
	}

	render() {
		const modalTitle = i18n.t('translation:taskManager.calendar.staff.editModal.title');

		if (isEmptyObject(this.props.initialValues)) {
			return <Fragment />;
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll edit-calendar-order-item-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2 className="task-name">{modalTitle}</h2>
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{this.renderAvailableStaff()}
					{this.renderОrderType()}
					{this.renderOrders()}
					{this.renderOrderItems()}
					{this.renderOrderTasks()}
					<p className="mb-xs">
						{i18n.t(
							'translation:taskManager.calendar.staff.editModal.notPlannedTasksLegend'
						)}
					</p>
					{this.renderAssigneeName()}
					{this.renderTaskStatus()}
					<div className="time-div">
						{this.renderTaskStartTime()}
						{this.renderTaskEndTime()}
					</div>
					<span className="align-items-start text-danger">{this.state.serverError}</span>
					{this.renderCommonNote()}
					{this.conflictSection()}
					{this.renderButtons()}
				</form>
			</Modal>
		);
	}

	resetFields(level) {
		const id = this.props.initialValues.id;

		if (level == 'orderList') {
			this.props.formValues['orderItems'] = null;
			this.props.formValues['orderTask'] = null;
		} else if (level == 'orderItems') {
			this.props.formValues['orderTask'] = null;
		}

		this.props.change('taskAssignee', null);
		this.props.change('taskStatus', null);
		this.props.change('startDateTime_' + id, null);
		this.props.change('endDateTime_' + id, null);
		this.props.change('notes', null);
	}

	renderAvailableStaff = () => {
		const { initialValues } = this.props;

		const assigneeName = Fields.templateFields.nameLocal({
			id: initialValues ? initialValues.id : null,
			value: initialValues.fullName ? initialValues.fullName : '-',
			readOnly: true,
			className: 'read-only-item',
			label: i18n.t('translation:taskManager.common.table.assignee')
		});

		return assigneeName;
	};

	renderОrderType = () => {
		const { initialValues } = this.props;

		const orderTypes = [
			{
				name: i18n.t('translation:taskManager.calendar.staff.editModal.notPlanned'),
				value: PLANNING_STATUS.NOT_PLANNED
			},
			{
				name: i18n.t('translation:taskManager.calendar.staff.editModal.dueToday'),
				value: PLANNING_STATUS.DUE_TODAY
			},
			{
				name: i18n.t('translation:taskManager.calendar.staff.editModal.overdue'),
				value: PLANNING_STATUS.OVERDUE
			}
		];

		const readOnlyClass = this.state.readOnly ? ' read-only-field' : '';

		const orderTypesSelect = Fields.commonFields.orderType({
			className: 'p-0 mt-s' + readOnlyClass,
			options: formUtils.options.getOrderType(orderTypes),
			id: initialValues.value
		});

		return orderTypesSelect;
	};

	renderOrders = () => {
		const { orders, formValues } = this.props;

		const ordersByType =
			formValues && formValues.orderType !== null && formValues.orderType != 0 ? orders : [];
		const readOnlyClass = this.state.readOnly ? ' read-only-field' : '';
		const ordersSelect = Fields.commonFields.orders({
			className: 'p-0 mt-s' + readOnlyClass,
			options: formUtils.options.getOrders(ordersByType)
		});

		return ordersSelect;
	};

	renderOrderItems = () => {
		const { formValues, orders } = this.props;

		const selectedOrder =
			orders && formValues ? orders.find((values) => values.id == formValues.ordersList) : {};
		const orderItems = !isEmptyObject(selectedOrder) ? selectedOrder.orderItems : [];
		const readOnlyClass = this.state.readOnly ? ' read-only-field' : '';

		const ordersSelect = Fields.commonFields.orderItems({
			className: 'p-0 mt-s' + readOnlyClass,
			options: formUtils.options.getOrderItems(orderItems)
		});

		return ordersSelect;
	};

	renderOrderTasks = () => {
		const { formValues, orders } = this.props;

		const selectedOrder =
			orders && formValues ? orders.find((values) => values.id == formValues.ordersList) : {};
		const orderItems = !isEmptyObject(selectedOrder) ? selectedOrder.orderItems : [];
		const orderItemTasks = orderItems.find((values) => values.id == formValues.orderItems);
		const itemTasks = orderItemTasks && orderItemTasks.tasks ? orderItemTasks.tasks : [];
		const readOnlyClass = this.state.readOnly ? ' read-only-field' : '';

		const ordersSelect = Fields.commonFields.orderTasks({
			className: 'p-0 mt-s' + readOnlyClass,
			options: formUtils.options.getOrderItemTasks(itemTasks)
		});

		return ordersSelect;
	};

	renderTaskStartTime = () => {
		const { initialValues, orders, formValues } = this.props;
		const selectedOrder =
			orders && formValues
				? orders.find((values) => values.id == this.props.formValues.ordersList)
				: [];
		const orderItems =
			selectedOrder && selectedOrder.orderItems ? selectedOrder.orderItems : [];
		const orderItemTasks = orderItems
			? orderItems.find((values) => values.id == this.props.formValues.orderItems)
			: {};
		const selectedTask = orderItemTasks
			? orderItemTasks.tasks.find((values) => values.id == this.props.formValues.orderTask)
			: {};
		const readOnlyClass = isGlobalAdmin(this.props.account) ? ' read-only-field' : '';

		return Fields.workingHoursFields.startDateTime({
			className: 'first time-field p-0 mt-xs' + readOnlyClass,
			id: initialValues.id,
			onChange: (event, isStartTime) => {
				if (typeof event == 'object') {
					const data = {
						startTime: isStartTime ? moment(event).format('YYYY-MM-DD HH:mm') : null,
						endTime: !isStartTime ? moment(event).format('YYYY-MM-DD HH:mm') : null,
						duration: selectedTask.duration ? selectedTask.duration : null,
						name: selectedTask.title
					};

					this.onChangeTimeField(isStartTime, data);
				}
			}
		});
	};

	renderTaskEndTime = () => {
		const { initialValues, orders, formValues } = this.props;
		const selectedOrder =
			orders && formValues
				? orders.find((values) => values.id == this.props.formValues.ordersList)
				: [];
		const orderItems =
			selectedOrder && selectedOrder.orderItems ? selectedOrder.orderItems : [];
		const orderItemTasks = orderItems
			? orderItems.find((values) => values.id == this.props.formValues.orderItems)
			: {};
		const selectedTask = orderItemTasks
			? orderItemTasks.tasks.find((values) => values.id == this.props.formValues.orderTask)
			: {};
		const readOnlyClass = isGlobalAdmin(this.props.account) ? ' read-only-field' : '';

		return Fields.workingHoursFields.endDateTime({
			className: 'first time-field p-0 mt-xs end-time-field' + readOnlyClass,
			id: initialValues.id,
			onChange: (event, isStartTime) => {
				if (typeof event == 'object') {
					const data = {
						startTime: isStartTime ? moment(event).format('YYYY-MM-DD HH:mm') : null,
						endTime: !isStartTime ? moment(event).format('YYYY-MM-DD HH:mm') : null,
						duration: selectedTask.duration ? selectedTask.duration : null,
						name: selectedTask.title
					};

					this.onChangeTimeField(isStartTime, data);
				}
			}
		});
	};

	onChangeTimeField = (isStartTime, data) => {
		const { initialValues, formValues, account, currentTemplate } = this.props;

		const id = initialValues.id;
		const labId = account.labId;
		const startDateTimeField = formValues['startDateTime_' + id]
			? formValues['startDateTime_' + id]
			: null;
		const endDateTimeField = formValues['endDateTime_' + id]
			? formValues['endDateTime_' + id]
			: null;

		for (let index in currentTemplate['phases']) {
			const task = currentTemplate['phases'][index];
			if (task.name == data.name && task.duration !== null) {
				data['duration'] = task.duration;
			}
		}

		delete data['name'];

		if (data['duration'] == null) {
			return;
		}

		if (
			(startDateTimeField == null && endDateTimeField !== null) ||
			(startDateTimeField !== null && endDateTimeField == null)
		) {
			this.props.calculateTaskTime(
				labId,
				isStartTime,
				data,
				(data) => {
					if (isStartTime && endDateTimeField == null) {
						this.props.change('endDateTime_' + id, new Date(data.endTime));
					} else if (!isStartTime && startDateTimeField == null) {
						this.props.change('startDateTime_' + id, new Date(data.startTime));
					}
				},
				this.setServerError
			);
		}
	};

	renderAssigneeName = () => {
		const { formValues } = this.props;

		const assignee = Fields.orderItemFields.taskAssigneeName({
			readOnly: true,
			className:
				formValues &&
				formValues.taskAssignee &&
				formValues.orderTask &&
				formValues.orderTask != 0
					? 'read-only-item mt-xs'
					: 'hidden-item',
			label: i18n.t(
				'translation:taskManager.calendar.staff.editModal.orderTaskCurrentAssignee'
			)
		});

		return assignee;
	};

	renderTaskStatus = () => {
		const { formValues } = this.props;

		const taskStatus = Fields.orderItemFields.taskStatusValue({
			readOnly: true,
			className:
				formValues &&
				formValues.taskStatus &&
				formValues.orderTask &&
				formValues.orderTask != 0
					? 'read-only-item mt-xs'
					: 'hidden-item',
			label: i18n.t('translation:taskManager.calendar.staff.editModal.orderTaskCurrentStatus')
		});

		return taskStatus;
	};

	renderCommonNote = () => {
		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = this.state.readOnly || isReadOnly ? ' read-only-field' : '';

		return Fields.commonFields.commonNote({ className: 'w-100 mt-s' + readOnlyClass });
	};

	conflictSection = () => {
		if (!this.state.isConflictSectionShown) {
			return <Fragment />;
		}

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.no')}
				key={i18n.t('translation:taskManager.common.buttons.no')}
				onClick={this.setConflictSection}
				secondary
			/>
		);
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.yes')}
				key={i18n.t('translation:taskManager.common.buttons.yes')}
				onClick={() => this.saveDataConflictSection(false)}
			/>
		);
		const yesToCalendarButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.toCalendar')}
				key={i18n.t('translation:taskManager.common.buttons.toCalendar')}
				onClick={() => this.saveDataConflictSection(true)}
			/>
		);

		const conflictsList =
			this.state.conflictSectionMsg.length > 0 ? (
				<ul>
					{this.state.conflictSectionMsg.map((item, index) => {
						return <li key={'conflict' + index}>{item}</li>;
					})}
				</ul>
			) : null;

		return (
			<div className="mb-s">
				<label className="orange-label mt-s">
					{i18n.t('translation:taskManager.orders.attention')}
				</label>
				<div className="conflict-section">
					<p>{i18n.t('translation:taskManager.orders.conflictsAppear')}</p>
					{conflictsList}
					<p>{i18n.t('translation:taskManager.orders.confirmConflicts')}</p>
					<div className="buttons task-buttons pb-0">
						{yesButton} {yesToCalendarButton} {cancelButton}
					</div>
				</div>
			</div>
		);
	};

	renderButtons = () => {
		if (this.state.isConflictSectionShown) {
			return <Fragment />;
		}

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const saveButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				key={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.handleSubmit(this.onFormSubmit);
				}}
			/>
		);

		return (
			<div className="buttons">
				{saveButton}
				{cancelButton}
			</div>
		);
	};

	onFormSubmit = (formValues) => {
		const { initialValues } = this.props;
		let errors = {};
		for (let value in formValues) {
			const id = initialValues.id;
			const orderType = formValues['orderType'];
			const ordersList = formValues['ordersList'];
			const orderItems = formValues['orderItems'];
			const orderTask = formValues['orderTask'];
			const startDateTimeField = formValues['startDateTime_' + id]
				? formValues['startDateTime_' + id]
				: null;
			const endDateTimeField = formValues['endDateTime_' + id]
				? formValues['endDateTime_' + id]
				: null;

			const taskFields = {
				orderType: i18n.t('translation:taskManager.calendar.staff.editModal.orderTypes'),
				orderNumber: i18n.t('translation:taskManager.common.fields.filters.orderNumber'),
				orderItem: i18n.t('translation:taskManager.calendar.staff.editModal.orderItem'),
				orderTask: i18n.t('translation:taskManager.calendar.staff.editModal.orderTask')
			};

			if (orderType == null || orderType == 0) {
				errors['orderType'] = i18n.t('translation:taskManager.errors.orderTypeErrorMsg', {
					word1: taskFields.orderType
				});
			}

			if (ordersList == undefined || ordersList == 0 || typeof ordersList == 'object') {
				errors['ordersList'] = i18n.t('translation:taskManager.errors.orderTypeErrorMsg', {
					word1: taskFields.orderNumber
				});
			}

			if (orderItems == undefined || orderItems == 0 || typeof orderItems == 'object') {
				errors['orderItems'] = i18n.t('translation:taskManager.errors.orderTypeErrorMsg', {
					word1: taskFields.orderItem
				});
			}

			if (orderTask == undefined || orderTask == 0 || typeof orderTask == 'object') {
				errors['orderTask'] = ' ';
			}

			if (moment(endDateTimeField).isBefore(startDateTimeField)) {
				const error = i18n.t('translation:taskManager.errors.startDateErrorMsg');
				errors['startDateTime_' + id] = ' ';
				this.setServerError(error);
			} else if (moment(endDateTimeField).isSame(startDateTimeField)) {
				const error = i18n.t('translation:taskManager.errors.sameDateErrorMsg');
				errors['startDateTime_' + id] = ' ';
				this.setServerError(error);
			}
		}

		for (let index in errors) {
			if (errors[index] == undefined) {
				delete errors[index];
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		let taskValues = {};

		const selectedOrder =
			this.props.orders && this.props.formValues
				? this.props.orders.find((values) => values.id == this.props.formValues.ordersList)
				: [];
		const orderItems =
			selectedOrder && selectedOrder.orderItems ? selectedOrder.orderItems : [];
		const orderItemTasks = orderItems
			? orderItems.find((values) => values.id == this.props.formValues.orderItems)
			: {};
		const selectedTask = orderItemTasks
			? orderItemTasks.tasks.find((values) => values.id == this.props.formValues.orderTask)
			: {};

		for (let value in formValues) {
			const id = initialValues.id;

			taskValues['forceUpdate'] = this.state.saveWithConflict;

			taskValues['title'] = selectedTask.title;
			taskValues['startTime'] = formValues['startDateTime_' + id]
				? moment(formValues['startDateTime_' + id]).format('YYYY-MM-DD HH:mm')
				: null;
			taskValues['endTime'] = formValues['endDateTime_' + id]
				? moment(formValues['endDateTime_' + id]).format('YYYY-MM-DD HH:mm')
				: null;
			taskValues['assignee'] = formValues['availableUsers']
				? { id: Number(formValues['availableUsers']) }
				: { id: Number(formValues['taskAssignee']) };
			taskValues['status'] = formValues['taskStatus']
				? getTaskStatusId(formValues['taskStatus'])
				: null;
			taskValues['statusNote'] = formValues['statusNote'] ? formValues['statusNote'] : null;
			taskValues['generalNote'] = formValues['notes'] ? formValues['notes'] : null;
		}

		const taskId = selectedTask.id;
		this.props.updateTask(taskId, taskValues, this.setServerError, null, this.closeModal);
	};

	setServerError = (serverError, isConflict) => {
		if (isConflict) {
			this.setState({
				conflictSectionMsg: serverError,
				isConflictSectionShown: true,
				saveWithConflict: false,
				readOnly: true
			});
		} else {
			this.setState({ serverError });
		}
	};

	setConflictSection = () => {
		this.setState({
			isConflictSectionShown: false,
			saveWithConflict: false,
			readOnly: false
		});
	};

	saveDataConflictSection = (goToCalendar) => {
		this.setState({
			saveWithConflict: true,
			readOnly: false,
			goToCalendar: goToCalendar ? goToCalendar : false
		});

		const { formValues } = this.props;
		this.onFormSubmit(formValues);
	};

	closeModal = () => {
		this.setState({
			saveWithConflict: false,
			conflictSectionMsg: [],
			isConflictSectionShown: false,
			readOnly: false,
			serverError: ''
		});
		this.props.destroy();
		this.props.closeModal();
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		availableStaff: state.tasks.labStaff,
		currentTask: state.tasks.currentTasks[0],
		formValues: getFormValues('editCalendarTasksForm')(state),
		orders: state.calendar.calendarOrders,
		filters: getFormValues('calendarOrdersFiltersForm')(state) || {},
		currentTemplate: state.tasks.currentTemplate
	};
};

const editCalendarTasksForm = reduxForm({ form: 'editCalendarTasksForm' })(EditStaffTaskModal);

export default connect(mapStateToProps, {
	calculateTaskTime,
	updateTask,
	getOrdersByOrderType,
	getLabProductTemplate
})(editCalendarTasksForm);
