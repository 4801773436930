import React from 'react';

import { parseColorCodeToBackgroundColor } from '../../utils/appUtils';

class Badge extends React.Component {
	render() {
		const style = parseColorCodeToBackgroundColor(this.props.backgroundColor);
		const className = this.props.className
			? 'badge-label ' + this.props.className
			: 'badge-label';

		return (
			<div className={className} style={style}>
				<span>{this.props.name}</span>
			</div>
		);
	}
}

export default Badge;
