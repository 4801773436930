import {
	GET_DASHBOARD_CURRENT_EMPLOYEE_TASKS,
	GET_DASHBOARD_CURRENT_ORDERS,
	GET_DASHBOARD_MONTHLY_ORDERS
} from '../../actions/types';

const initState = { daily: {}, monthly: [] };

const calendarDashboardReducer = (state = { ...initState }, action) => {
	if (action.type === GET_DASHBOARD_CURRENT_ORDERS) {
		state = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_DASHBOARD_CURRENT_EMPLOYEE_TASKS) {
		state.daily = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_DASHBOARD_MONTHLY_ORDERS) {
		state.monthly = [...action.payload];
		return { ...state };
	}

	return state;
};

export default calendarDashboardReducer;
