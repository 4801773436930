import React, { Fragment } from 'react';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import { parseUsers } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import SubHeader from '../../common/SubHeader';
import { connect } from 'react-redux';
import { clearReducers, getAllWaitingForApproval, setPagination } from '../../../actions';
import Pagination from '../../common/Pagination';
import { COMPANY_STATUS, PAGINATION_TYPES } from '../../../utils/constants';
import { isEmptyObject } from '../../../utils/appUtils';
import Statistics from './Statistics';
import ClinicsTable from '../../common/ClinicsTable';
import LabsTable from '../../common/LabsTable';

class DashboardGlobalAdmin extends React.Component {
	componentDidMount() {
		this.props.getAllWaitingForApproval(this.props.page || 1);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.getAllWaitingForApproval(this.props.page);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.users.content)
			) {
				this.props.setPagination(
					PAGINATION_TYPES.WAITING_FOR_APPROVAL_USERS,
					this.props.page - 1
				);
			}
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<Header title={i18n.t('translation:dashboard.dashboard')} />
				<SubHeader first />
				<div className="col-12 p-0">
					<Statistics />

					<SubHeader title={i18n.t('translation:dashboard.clinicsWaitingApproval')} />
					<div className="mt-s">
						<ClinicsTable status={COMPANY_STATUS.WAITING_FOR_APPROVAL} />
					</div>

					<SubHeader title={i18n.t('translation:dashboard.labsWaitingApproval')} />
					<div className="mt-s">
						<LabsTable status={COMPANY_STATUS.WAITING_FOR_APPROVAL} />
					</div>

					<SubHeader title={i18n.t('translation:dashboard.usersWaitingApproval')} />
					<div className="mt-s">{this.renderUsers()}</div>
					{this.renderPagination()}
				</div>
			</Fragment>
		);
	}

	renderUsers = () => {
		const users = parseUsers(this.props.users.content);

		return (
			<Table
				columns={[
					i18n.t('translation:common.table.name'),
					i18n.t('translation:common.table.phone'),
					i18n.t('translation:common.table.email'),
					i18n.t('translation:common.table.profession'),
					i18n.t('translation:common.table.organization'),
					i18n.t('translation:common.table.status')
				]}
				data={users}
			/>
		);
	};

	renderPagination = () => {
		const { users } = this.props;
		if (users) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={users.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.WAITING_FOR_APPROVAL_USERS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		users: state.users.users,
		page: state.pagination[PAGINATION_TYPES.WAITING_FOR_APPROVAL_USERS]
	};
};

export default connect(mapStateToProps, {
	getAllWaitingForApproval,
	setPagination,
	clearReducers
})(DashboardGlobalAdmin);
