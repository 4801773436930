import { getAxiosWithToken } from '../../utils/webApi';
import { CLEAR_ACCOUNT_LAB_ID, GET_ABSENCE_TYPES, LOAD_ACCOUNT_LAB_ID } from '../types';

export const getAbsenceTypes = () => async (dispatch) => {
	const response = await getAxiosWithToken().get('/attendance/all-absence-types');
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({ type: GET_ABSENCE_TYPES, payload: returnedData.data });
	}
};

export const getShiftsAndAbsences = (id) => async () => {
	const response = await getAxiosWithToken().get(
		`/attendance/list-shifts-and-absence-types?lab-id=${id}`
	);
	let returnedData = response.data;
	return returnedData.data;
};

export const loadAccountLabId = (labId) => async (dispatch) => {
	dispatch({ type: LOAD_ACCOUNT_LAB_ID, payload: labId });
};

export const clearAccountLabId = () => async (dispatch) => {
	dispatch({ type: CLEAR_ACCOUNT_LAB_ID });
};
