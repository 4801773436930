import React, { Fragment } from 'react';
import SubHeader from '../../common/SubHeader';
import i18n from '../../../i18n';
import { Fields } from '../../common/Fields';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../utils/formUtils';
import { connect } from 'react-redux';
import navigationUtils from '../../../utils/navigationUtils';
import {
	isGlobalAdmin,
	isLabTechnician,
	isMyCompany,
	isNew,
	isPracticeRoute
} from '../../../utils/appUtils';
import { getAllCurrencyCodes } from '../../../actions';
import { SERVER_SETTING_NAMES } from '../../../utils/constants';

class CompanyDetails extends React.Component {
	componentDidMount() {
		this.props.getAllCurrencyCodes();
	}

	render() {
		return (
			<div className={'row col-12 pr-0'}>
				<div className="col-6">{this.renderGeneralInformation()}</div>
				<div className="col-6 justify-content-between d-flex flex-wrap pr-0">
					{this.renderBankInformation()}
					{this.props.commonInformation ? this.props.commonInformation() : null}
				</div>
			</div>
		);
	}

	renderGeneralInformation = () => {
		const readableInput =
			!isGlobalAdmin(this.props.account) &&
			!isMyCompany() &&
			!this.props.isLabAdminAndCanEdit;

		const readableCurrencyCode =
			!(isGlobalAdmin(this.props.account) && isNew(navigationUtils.getRoute())) &&
			!isMyCompany() &&
			!this.props.isLabAdminAndCanEdit;
		const dropdownOptions = formUtils.options.getCurrencyCodes(this.getCurrencyCodes());

		return (
			<Fragment>
				<SubHeader first title={i18n.t('translation:common.companyInformation')} />
				<div className="col-12 p-0 data-section mt-s">
					<div className="content">
						<div className="row p-0">
							<div className="col-6">
								{Fields.commonFields.name({ className: 'first', readableInput })}
							</div>
							<div className="col-6">
								{Fields.commonFields.phone({ className: 'first', readableInput })}
							</div>
						</div>
						<div className="row p-0">
							<div className="col-12">
								{Fields.commonFields.legalName({ readableInput })}
							</div>
						</div>
						<div className="row p-0">
							<div className="col-6">
								<Fragment>
									{Fields.commonFields.email({ readableInput })}
									{Fields.commonFields.owner({ readableInput })}
									{Fields.commonFields.uic({ readableInput })}
									{Fields.commonFields.vatNumber({ readableInput })}
								</Fragment>
							</div>
							<div className="col-6">
								{Fields.commonFields.registeredOffice({
									readableInput,
									className: 'input-container company-info-form'
								})}
								{this.isLabRoute() && !isLabTechnician(this.props.account)
									? Fields.commonFields.currencyCode({
											options: dropdownOptions,
											readableInput: readableCurrencyCode
									  })
									: null}
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	getCurrencyCodes = () => {
		const currencyCodes = this.props.serverSettings[SERVER_SETTING_NAMES.ALL_CURRENCY_CODES];

		return currencyCodes ? currencyCodes : [];
	};

	isLabRoute = () => !isPracticeRoute(navigationUtils.getRoute());

	renderBankInformation = () => {
		const readableInput =
			isGlobalAdmin(this.props.account) ||
			(!isMyCompany() && !this.props.isLabAdminAndCanEdit);

		return (
			<div className={'col-12 pl-0 pr-0'}>
				<SubHeader first title={i18n.t('translation:common.bankInformation')} />
				<div className="col-12 p-0 data-section mt-s">
					<div className="content">
						<div className="row p-0">
							<div className="col-6">
								{Fields.commonFields.IBAN({ className: 'first', readableInput })}
								{Fields.commonFields.bankName({ readableInput })}
							</div>
							<div className="col-6">
								{Fields.commonFields.BIC({ className: 'first', readableInput })}
								{Fields.commonFields.bankAddress({ readableInput })}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

const companyDetailsForm = reduxForm({
	form: 'companyDetailsForm',
	enableReinitialize: true,
	validate: formUtils.validateCompanyDetails,
	destroyOnUnmount: false,
	onSubmit: formUtils.remoteSubmits.onCompanyDetailsSubmit
})(CompanyDetails);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		settings: state.settings,
		serverSettings: state.serverSettings,
		initialValues: isNew(navigationUtils.getRoute())
			? {}
			: isPracticeRoute(navigationUtils.getRoute())
			? state.clinics.currentClinic
			: state.labs.currentLab
	};
};

export default connect(mapStateToProps, { getAllCurrencyCodes })(companyDetailsForm);
