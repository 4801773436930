import React, { Fragment } from 'react';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { customDateInput, MultipleSelectInput, textInput } from '../../../common/Fields';
import DefaultButton from '../../../common/DefaultButton';
import i18n from '../../../../i18n';
import { isEmptyObject, parseOrderInvoicingStatusFilterType } from '../../../../utils/appUtils';
import {
	INVOICING_FILTER_INPUT_NAME,
	ORDER_INVOICING_STATUS,
	PAGINATION_TYPES
} from '../../../../utils/constants';
import { setPagination } from '../../../../actions';
import { formUtils } from '../../../../utils/formUtils';
import ClientsDropdownField from '../common/UI/ClientsDropdownField';

class InvoicingOrdersFilters extends React.Component {
	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters pt-0">
					<form onSubmit={this.onFilterButtonClick}>{this.renderFilterInputs()}</form>
				</div>
			</div>
		);
	}

	renderFilterInputs = () => {
		const orderInvoicingStatuses = [];

		for (let type in ORDER_INVOICING_STATUS) {
			orderInvoicingStatuses.push({
				name: parseOrderInvoicingStatusFilterType(type),
				id: type
			});
		}

		return (
			<Fragment>
				<div className="row pt-4">
					<div className="padding-x-15">{this.renderOrderNumberInput()}</div>
					<div className="col">
						<ClientsDropdownField
							name={INVOICING_FILTER_INPUT_NAME.CLIENT_ID}
							clients={this.props.clinics}
							onChange={(event) => this.onClientChange(event.target)}
						/>
					</div>
					<div className="col">
						{this.renderOrderInvoicingStatusInput(orderInvoicingStatuses)}
					</div>
					<div className="col">{this.renderPatientNameInput()}</div>
					<div className="d-flex flex-row justify-content-center align-items-end col-3 offset-xl-1">
						{this.renderFilterButton()}
					</div>
				</div>
				<div className="row pt-4">
					<div className="col-4">{this.renderCreateDateRangeInputs()}</div>
					<div className="col-4">{this.renderDeliveryDateRangeInputs()}</div>
					<div className="col-4">{this.renderPaymentDueDateRangeInputs()}</div>
				</div>
			</Fragment>
		);
	};

	renderOrderNumberInput() {
		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.LAB_ORDER_ID}
				label={i18n.t('translation:billingModule.common.number')}
				customProps={{ className: 'small-input mr-0' }}
				component={textInput}
				normalize={formUtils.normalize.number}
			/>
		);
	}

	renderOrderInvoicingStatusInput(orderInvoicingStatuses) {
		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.STATUSES}
				label={i18n.t('translation:billingModule.common.orderInvoicingStatus')}
				customProps={{
					className: 'mr-0',
					options: orderInvoicingStatuses
				}}
				component={MultipleSelectInput}
			/>
		);
	}

	renderPatientNameInput() {
		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.PATIENT_NAME}
				label={i18n.t('translation:billingModule.common.patient')}
				customProps={{ className: 'mr-0' }}
				component={textInput}
			/>
		);
	}

	renderPaymentDueDateRangeInputs() {
		return (
			<Fragment>
				<label>{i18n.t('translation:billingModule.common.paymentDueDateToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						component={customDateInput}
					/>
				)}
			</Fragment>
		);
	}

	renderCreateDateRangeInputs() {
		return (
			<Fragment>
				<label>{i18n.t('translation:billingModule.common.createToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={INVOICING_FILTER_INPUT_NAME.CREATE_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						component={customDateInput}
					/>
				)}
			</Fragment>
		);
	}

	renderDeliveryDateRangeInputs() {
		return (
			<Fragment>
				<label>{i18n.t('translation:billingModule.common.deliveryToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						component={customDateInput}
					/>
				)}
			</Fragment>
		);
	}

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};

	renderFilterButton() {
		return <DefaultButton title={i18n.t('translation:billingModule.common.filter')} />;
	}

	onFilterButtonClick = (e) => {
		e.preventDefault();

		this.props.handleSubmit((formValues) => {
			let clientId;

			if (formValues.clientId) {
				clientId = formValues.clientId;
				delete formValues.clientId;
			}

			this.props.setFilters(formValues);
			this.props.setPagination(PAGINATION_TYPES.BILLING_ORDERS, 1);
			this.props.change(INVOICING_FILTER_INPUT_NAME.CLIENT_ID, clientId);
		})();
	};

	onClientChange = (target) => {
		let filters = this.props.filters;

		if (!isEmptyObject(filters)) {
			for (const filterName in filters) {
				if (
					filterName !== INVOICING_FILTER_INPUT_NAME.CLIENT_ID ||
					filterName !== INVOICING_FILTER_INPUT_NAME.CLIENT_NAME
				) {
					filters = Object.keys(filters).reduce(
						(acc, curr) => ({ ...acc, [curr]: '' }),
						{}
					);

					if (filters[filterName]) {
						this.props.formValues[filterName] = '';
					}
				}
			}
		}

		const { options } = target;
		const clientName = options[options.selectedIndex].text;

		this.props.setFilters({ clientName: clientName });
		this.props.change(INVOICING_FILTER_INPUT_NAME.CLIENT_NAME, clientName);
	};
}

const invoicingOrdersFiltersForm = reduxForm({
	form: 'invoicingOrdersFiltersForm',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validateFilters
})(InvoicingOrdersFilters);

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		clinics: state.clinics.clinics,
		formValues: getFormValues('invoicingOrdersFiltersForm')(state)
	};
};

export default connect(mapStateToProps, { setPagination, change })(invoicingOrdersFiltersForm);

function validateFilters(formValues) {
	let errors = {};

	if (
		formValues.paymentDueDateFrom &&
		formValues.paymentDueDateTo &&
		formValues.paymentDueDateFrom.getTime() > formValues.paymentDueDateTo.getTime()
	) {
		errors[INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	if (
		formValues.createDateFrom &&
		formValues.createDateTo &&
		formValues.createDateFrom.getTime() > formValues.createDateTo.getTime()
	) {
		errors[INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	if (
		formValues.deliveryDateFrom &&
		formValues.deliveryDateTo &&
		formValues.deliveryDateFrom.getTime() > formValues.deliveryDateTo.getTime()
	) {
		errors[INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	return errors;
}
