import React from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { BALANCE_FORM_FIELD, PAGINATION_TYPES } from '../../../../utils/constants';
import { customDateInput } from '../../../common/Fields';
import i18n from '../../../../i18n';
import DefaultButton from '../../../common/DefaultButton';
import { searchStatement } from '../../../../actions/billing/billingStatementActions';

class StatementFilters extends React.Component {
	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};

	renderDatesInputs() {
		return (
			<>
				<label>{i18n.t('translation:billingModule.balance.period')}</label>
				{this.renderIntervalFields(
					<Field
						name={BALANCE_FORM_FIELD.PERIOD_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from'),
							onChange: () => {
								this.props.touch(BALANCE_FORM_FIELD.PERIOD_DATE_FROM);
							},
							onClear: () => this.props.touch(BALANCE_FORM_FIELD.PERIOD_DATE_FROM),
							renderClearButton: true,
							maxDate: new Date()
						}}
						component={customDateInput}
					/>,
					<Field
						name={BALANCE_FORM_FIELD.PERIOD_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to'),
							renderClearButton: true,
							onClear: () => this.props.touch(BALANCE_FORM_FIELD.PERIOD_DATE_TO),
							onChange: () => {
								this.props.touch(BALANCE_FORM_FIELD.PERIOD_DATE_TO);
							},
							minDate:
								this.props.formValues &&
								this.props.formValues[BALANCE_FORM_FIELD.PERIOD_DATE_FROM]
						}}
						component={customDateInput}
					/>
				)}
			</>
		);
	}

	renderButton() {
		return <DefaultButton title={i18n.t('translation:billingModule.common.buttons.apply')} />;
	}

	onSubmit = (event) => {
		event.preventDefault();
		if (this.props.invalid) {
			return;
		}

		if (this.props.page === 1) {
			this.props.searchStatement({
				page: 1,
				filters: { ...this.props.formValues, labId: this.props.account.labId }
			});
		} else {
			this.props.setCurrentPage(0);
		}
	};

	render() {
		return (
			<form className="col-12 p-0 data-section mb-4" onSubmit={this.onSubmit}>
				<div className="content filters align-items-center">
					<div className="d-flex col-12 p-0">
						<div className="col-6 p-0">{this.renderDatesInputs()}</div>
						<div className="d-flex col-6 p-0 justify-content-end align-items-center">
							{this.renderButton()}
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const validateStatementForm = (formValues, props) => {
	const { touch } = props;
	let errors = {};

	const startDate = formValues[BALANCE_FORM_FIELD.PERIOD_DATE_FROM];
	const endDate = formValues[BALANCE_FORM_FIELD.PERIOD_DATE_TO];

	if (!startDate || !formValues[BALANCE_FORM_FIELD.PERIOD_DATE_FROM].toString().trim().length) {
		touch(BALANCE_FORM_FIELD.PERIOD_DATE_FROM);
		errors[BALANCE_FORM_FIELD.PERIOD_DATE_FROM] = i18n.t(
			'translation:common.errors.requiredField'
		);
	}

	if (!endDate || !formValues[BALANCE_FORM_FIELD.PERIOD_DATE_TO].toString().trim().length) {
		touch(BALANCE_FORM_FIELD.PERIOD_DATE_TO);
		errors[BALANCE_FORM_FIELD.PERIOD_DATE_TO] = i18n.t(
			'translation:common.errors.requiredField'
		);
	}

	// if (
	// 	startDate &&
	// 	startDate.toString().trim().length &&
	// 	endDate &&
	// 	endDate.toString().trim().length
	// ) {
	// 	const startDateMilliseconds = new Date(moment(startDate).format('LL')).getTime();
	// 	const endDateMilliseconds = new Date(moment(endDate).format('LL')).getTime();
	//
	// 	if (startDateMilliseconds > endDateMilliseconds) {
	// 		errors[BALANCE_FORM_FIELD.PERIOD_DATE_TO] = i18n.t(
	// 			'translation:billingModule.balance.errors.endDateBeforeStartDate'
	// 		);
	// 	}
	// }

	return errors;
};

const getInitialValues = () => {
	const now = new Date();
	return {
		[BALANCE_FORM_FIELD.PERIOD_DATE_FROM]: now,
		[BALANCE_FORM_FIELD.PERIOD_DATE_TO]: now
	};
};

const statementForm = reduxForm({
	form: 'statementForm',
	initialValues: getInitialValues(),
	validate: (formValues, props) => validateStatementForm(formValues, props),
	destroyOnUnmount: false
})(StatementFilters);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('statementForm')(state),
		currentLab: state.labs.currentLab,
		page: state.pagination[PAGINATION_TYPES.STATEMENT]
	};
};

export default connect(mapStateToProps, { searchStatement })(statementForm);
