import { GET_ALL_CURRENCY_CODES, GET_SETTING_FROM_SERVER } from '../actions/types';
import { SERVER_SETTING_NAMES } from '../utils/constants';

const serverSettingReducer = (state = {}, action) => {
	if (!action.payload) {
		return state;
	}

	const { name, value } = action.payload;

	switch (action.type) {
		case GET_SETTING_FROM_SERVER:
			return {
				...state,
				[name]: value
			};

		case GET_ALL_CURRENCY_CODES:
			return {
				...state,
				[SERVER_SETTING_NAMES.ALL_CURRENCY_CODES]: action.payload
			};

		default:
			return state;
	}
};

export default serverSettingReducer;
