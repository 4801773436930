import React, { Fragment } from 'react';
import { initialize, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';

class ColorSwitch extends React.Component {
	componentDidMount() {
		const initialValues = this.props.initialValues;
		this.props.initialize(initialValues);
	}

	render() {
		return (
			<Fragment>
				{Fields.orderFields.colorSwitch({
					blueLabel: true,
					disabled: !this.props.initialValues.colorSwitch,
					className: 'first',
					radioButtons: [
						{ name: 'Vita Classic', value: 'VITA_CLASSIC' },
						{ name: 'Vita 3D Master', value: 'VITA_3D_MASTER' },
						{ name: 'Ivoclar Chromascop', value: 'IVOCLAR_CHROMASCOP' }
					]
				})}
			</Fragment>
		);
	}
}

const colorSwitchForm = reduxForm({ form: 'colorSwitchForm', enableReinitialize: true })(
	ColorSwitch
);

const mapStateToProps = (state) => {
	return { currentOrder: state.orders.currentOrder };
};

export default connect(mapStateToProps, { initialize })(colorSwitchForm);
