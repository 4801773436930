import React, { Fragment } from 'react';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import { isEmptyObject, isLabAdmin } from '../../../../utils/appUtils';
import DefaultButton from '../../../common/DefaultButton';
import SubHeader from '../../../common/SubHeader';
import { connect } from 'react-redux';
import {
	getLabInventoryOnly,
	getLabInventoryWithAllProducts,
	getLocalTemplates
} from '../../../../actions';
import NestedTable from '../../../common/NestedTable';
import { getDropdownData, parseTemplates } from '../../../../utils/tableUtils';
import LocalInventoryPhasesModal from './LocalInventoryPhasesModal';

class LocalTemplates extends React.Component {
	state = { dropdown: {}, isTemplateModalOpen: false, template: {} };

	componentDidMount() {
		const { account } = this.props;
		const { labId } = account;
		if (isLabAdmin(account)) {
			this.props.getLabInventoryWithAllProducts(labId);
			this.props.getLocalTemplates(labId, this.props.match.params.id);
		}
		// else if (isLabTechnician(account)) {
		//     this.props.getLabInventoryOnly(labId);
		// }
	}

	render() {
		const item = this.getItem();

		return (
			<Fragment>
				<LocalInventoryPhasesModal
					isModalOpen={this.state.isTemplateModalOpen}
					closeModal={this.closeTemplateModal}
					item={item}
					template={this.state.template}
				/>
				<Header title={i18n.t('translation:taskManager.inventory.templatesForProduct')} />
				<div className="col-12 p-0">
					{this.renderSubHeader()}
					{this.renderNestedTable()}
				</div>
			</Fragment>
		);
	}

	getItem = () => {
		return this.props.inventory.find((item) => {
			return item.id == this.props.match.params.id;
		});
	};

	closeTemplateModal = () => {
		this.setState({ isTemplateModalOpen: false });
	};

	openTemplateModal = (template) => {
		this.setState({ isTemplateModalOpen: true, template });
	};

	renderSubHeader = () => {
		const addProductButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				key={i18n.t('translation:common.buttons.add')}
				onClick={() => {
					this.openTemplateModal({});
				}}
			/>
		);

		const item = this.getItem();
		const title = item ? item.type + ' - ' + item.material : '';
		return <SubHeader title={title} buttons={[addProductButton]} first />;
	};

	setDropdown = (elementIndex, isExpanded) => {
		this.setState({ dropdown: getDropdownData(elementIndex, this.state.dropdown, isExpanded) });
	};

	renderNestedTable = () => {
		const item = this.getItem();

		const productId = item ? item.id : undefined;

		const columns = [
			i18n.t('translation:taskManager.common.table.templatePhase'),
			i18n.t('translation:taskManager.common.table.time'),
			i18n.t('translation:inventory.table.type'),
			i18n.t('translation:taskManager.common.table.default'),
			i18n.t('translation:common.table.action')
		];

		const data = parseTemplates(
			this.props.templates,
			this.openTemplateModal,
			productId,
			this.props.account.labId
		);

		if (!isEmptyObject(data)) {
			return (
				<NestedTable
					columns={columns}
					data={data}
					setDropdown={this.setDropdown}
					dropdown={this.state.dropdown}
					expand
				/>
			);
		}
	};
}

const mapStateToProps = (state) => {
	return {
		inventory: state.inventory.labInventory,
		account: state.account,
		templates: state.templates,
		phases: state.phases
	};
};

export default connect(mapStateToProps, {
	getLabInventoryOnly,
	getLabInventoryWithAllProducts,
	getLocalTemplates
})(LocalTemplates);
