import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../errors/NotFound';
import InternalServerError from '../errors/InternalServerError';
import UnhandledError from '../errors/UnhandledError';
import DashboardLocalAdmin from '../pages/dashboards/DashboardLocalAdmin';
import Clients from '../pages/clinics/Clients';
import { PAGES_PATHS, TRANSACTION_TYPE } from '../../utils/constants';
import UserDetails from '../pages/users/UserDetails';
import LabDetails from '../pages/labs/LabDetails';
import LabInventory from '../pages/inventory/LabInventory';
import ClinicDetails from '../pages/clinics/ClinicDetails';
import OrderDetails from '../pages/orders/OrderDetails';
import Orders from '../pages/orders/Orders';
import PriceList from '../pages/inventory/PriceList';
import LocalTemplates from '../pages/taskManager/inventory/LocalTemplates';
import WorkSchedule from '../pages/taskManager/planner/workSchedule/WorkSchedule';
import Holidays from '../pages/taskManager/planner/holidays/Holidays';
import Staff from '../pages/taskManager/planner/staff/Staff';
import WorkingHours from '../pages/taskManager/planner/workingHours/WorkingHours';
import CalendarOrders from '../pages/taskManager/calendar/orders/CalendarOrders';
import CalendarDashboard from '../pages/taskManager/calendar/dashboard/CalendarDashboard';
import { getLabModules } from '../../actions';
import { isTaskManagerEnabled } from '../../utils/appUtils';
import CalendarStaff from '../pages/taskManager/calendar/staff/CalendarStaff';
import Settings from '../pages/billingModule/settings/Settings';
import Statement from '../pages/billingModule/statement/Statement';
import Documents from '../pages/billingModule/documents/Documents';
import IncomesOutcomes from '../pages/billingModule/incomesOutcomes/IncomesOutcomes';
import Invoicing from '../pages/billingModule/invoicing/Invoicing';
import IncomeDetails from '../pages/billingModule/incomesOutcomes/incomeDetails/IncomeDetails';
import OutcomeDetails from '../pages/billingModule/incomesOutcomes/outcomeDetails.js/OutcomeDetails';
import Takings from '../pages/billingModule/debtorDays/DebtorDays';
import StatementOpenClose from '../pages/billingModule/statement/StatementOpenClose';

class LocalAdminRoutes extends Component {
	componentDidMount() {
		this.props.getLabModules(this.props.account.labId);
	}

	render = () => (
		<Fragment>
			<Switch>
				<Route
					path={PAGES_PATHS.HOME}
					exact
					component={
						isTaskManagerEnabled(this.props.modules)
							? CalendarDashboard
							: DashboardLocalAdmin
					}
				/>
				<Route path={PAGES_PATHS.MY_LABORATORY} exact component={DashboardLocalAdmin} />
				<Route path={PAGES_PATHS.MY_ACCOUNT} exact component={UserDetails} />
				<Route path={PAGES_PATHS.USER_DETAILS} exact component={UserDetails} />
				<Route path={PAGES_PATHS.MY_LABORATORY_DETAILS} exact component={LabDetails} />
				<Route path={PAGES_PATHS.INVENTORY} exact component={LabInventory} />
				<Route path={PAGES_PATHS.CLIENTS} exact component={Clients} />
				<Route path={PAGES_PATHS.CLIENT_DETAILS} exact component={ClinicDetails} />
				<Route path={PAGES_PATHS.CLIENT_PRICE_LIST} exact component={PriceList} />
				<Route path={PAGES_PATHS.ORDERS} exact component={Orders} />
				<Route path={PAGES_PATHS.ORDERS_DETAILS} exact component={OrderDetails} />
				<Route path={PAGES_PATHS.LOCAL_TEMPLATES} exact component={LocalTemplates} />
				<Route path={PAGES_PATHS.PLANNER} exact component={WorkSchedule} />
				<Route path={PAGES_PATHS.PLANNER_WORK_SCHEDULE} exact component={WorkSchedule} />
				<Route path={PAGES_PATHS.PLANNER_WORKING_HOURS} exact component={WorkingHours} />
				<Route path={PAGES_PATHS.PLANNER_HOLIDAYS} exact component={Holidays} />
				<Route path={PAGES_PATHS.PLANNER_STAFF} exact component={Staff} />
				<Route path={PAGES_PATHS.CALENDAR_STAFF} exact component={CalendarStaff} />
				<Route path={PAGES_PATHS.CALENDAR_ORDERS} exact component={CalendarOrders} />
				<Route path={PAGES_PATHS.BILLING_INVOICING} exact component={Invoicing} />
				<Route path={PAGES_PATHS.BILLING_STATEMENT} exact component={Statement} />
				<Route
					path={PAGES_PATHS.BILLING_STATEMENT_OPENING}
					exact
					component={StatementOpenClose}
				/>
				<Route
					path={PAGES_PATHS.BILLING_STATEMENT_CLOSING}
					exact
					component={StatementOpenClose}
				/>
				<Route path={PAGES_PATHS.BILLING_STATEMENT} exact component={Statement} />
				<Route path={PAGES_PATHS.BILLING_DEBTOR_DAYS} exact component={Takings} />
				<Route path={PAGES_PATHS.BILLING_DOCUMENTS} exact component={Documents} />
				<Route
					path={PAGES_PATHS.BILLING_INCOMES_OUTCOMES}
					exact
					component={IncomesOutcomes}
				/>
				<Route path={PAGES_PATHS.BILLING_INCOMES_DETAILS} exact component={IncomeDetails} />
				<Route
					path={PAGES_PATHS.BILLING_OUTCOMES_DETAILS}
					exact
					component={OutcomeDetails}
				/>
				<Route path={PAGES_PATHS.BILLING_SETTINGS} exact component={Settings} />
				<Route path="/404" component={NotFound} />
				<Route path="/500" exact component={InternalServerError} />
				<Route path="/unhandledError" exact component={UnhandledError} />
				<Route component={NotFound} />
			</Switch>
		</Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		modules: state.modules
	};
};

export default connect(mapStateToProps, { getLabModules })(LocalAdminRoutes);
