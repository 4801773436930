import React from 'react';
import i18n from '../i18n';
import {
	BILLING_DOCUMENT_TYPES,
	BILLING_PRODUCT_UNIT_TYPE,
	COUNTRY_CODES_BILLING_MODULE,
	COUNTRY_DISPLAY_NAMES,
	FILTER_FIELDS,
	GLOBAL_PRODUCT_CATEGORY,
	INVOICE_FIELD_NAME,
	INVOICING_METHODS,
	LANGUAGE_CODES,
	LANGUAGE_DISPLAY_NAMES,
	LANGUAGE_TABLE_FIELD_PREFIXES,
	MAX_TEXT_FIELD_LENGTH,
	MIN_TEXT_FIELD_LENGTH,
	ORDER_FIELD_STATUSES,
	ORDER_FIELDS,
	ORDER_ITEM_FIELDS,
	ORDER_STATUS,
	ORGANIZATION_TYPE,
	PAGES_PATHS,
	TASK_STATUS,
	TAX_TYPE,
	TRANSACTION_METHOD,
	USER_ROLE,
	USER_TYPE
} from './constants';
import navigationUtils from './navigationUtils';
import {
	getArrayOfPropertyValues,
	getSelectedTaxType,
	isEmptyObject,
	isLabAdmin,
	isNew,
	isOrderDraft,
	isOrderOpened,
	isPassiveClinic,
	isPracticeRoute,
	parseGlobalProductCategory,
	parseOrganizationType,
	parseRole,
	parseUserType
} from './appUtils';
import {
	createClinic,
	createLab,
	updateClinic,
	updateDraft,
	updateLab,
	updatePassiveDraft
} from '../actions';
import store from '../store';
import _ from 'lodash';
import { Fields } from '../components/common/Fields';
import moment from './moment';
import { getFormValues, submit } from 'redux-form';
import { parseInvoicingCurrency } from './tableUtils';
import { parseLocalizedString } from '../components/pages/billingModule/invoicing/billingDocumentModal/utils/documentUtils';

const ibanTools = require('ibantools');

const twoDecimalReg = /^(\d+([.|,]\d{0,2})?|\.?\d{1,2})$/;
const emailReg =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const userReg = /^[a-zA-Z0-9_.-]+$/;
const phoneReg = /^[+]?[0-9\s]{1,}$/im;
const onlyDigitReg = /^\d+$/;

export const commonValidation = (formValues, errors, fieldName) => {
	let error = '';

	function required() {
		if (
			!formValues[fieldName] ||
			(formValues[fieldName] &&
				typeof formValues[fieldName] === 'string' &&
				formValues[fieldName].trim() === '')
		) {
			error = i18n.t('translation:common.errors.requiredField');
		}
		return this;
	}

	function minLength(minLength) {
		if (formValues[fieldName] && formValues[fieldName].length < minLength) {
			error = i18n.t('translation:common.errors.fieldMinLength', {
				word1: minLength
			});
		}
		return this;
	}

	function maxLength(maxLength) {
		if (formValues[fieldName] && formValues[fieldName].length > maxLength) {
			error = i18n.t('translation:common.errors.fieldMaxLength', {
				word1: maxLength
			});
		}
		return this;
	}

	function validate() {
		if (error) {
			return (errors[fieldName] = error);
		} else {
			return errors[fieldName];
		}
	}

	return {
		required,
		minLength,
		maxLength,
		validate
	};
};

export const formUtils = {
	validateLogin: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'userNameOrEmail').required().validate();
		commonValidation(formValues, errors, 'password').required().validate();
		return errors;
	},
	validateSignUp: (formValues, props) => {
		let errors = {};
		if (!emailReg.test(String(formValues.email).toLowerCase())) {
			errors.email = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'email')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();

		commonValidation(formValues, errors, 'username')
			.required()
			.minLength(MIN_TEXT_FIELD_LENGTH.USERNAME)
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (!userReg.test(String(formValues.username).toLowerCase())) {
			errors.username = i18n.t('translation:auth.errors.userNameNotValid');
		}
		commonValidation(formValues, errors, 'password')
			.required()
			.minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (formValues.password !== formValues.repeatPassword) {
			errors.repeatPassword = i18n.t('translation:common.errors.passwordsMismatch');
		}
		commonValidation(formValues, errors, 'repeatPassword').required().validate();
		commonValidation(formValues, errors, 'firstName')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		commonValidation(formValues, errors, 'lastName')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();

		if (!phoneReg.test(String(formValues.phone).toLowerCase())) {
			errors.phone = i18n.t('translation:auth.errors.phoneNotValid');
		}
		commonValidation(formValues, errors, 'phone')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		commonValidation(formValues, errors, 'address')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();

		commonValidation(formValues, errors, 'userType').required().validate();
		commonValidation(formValues, errors, 'organizationType').required().validate();
		commonValidation(formValues, errors, 'termsAndConditions').required().validate();
		commonValidation(formValues, errors, 'cookiesPolicy').required().validate();
		commonValidation(formValues, errors, 'privacyPolicy').required().validate();

		// Validate company (organization) information
		if (
			formValues.organizationType === ORGANIZATION_TYPE.EXISTING_CLINIC ||
			formValues.organizationType === ORGANIZATION_TYPE.EXISTING_LAB
		) {
			if (!emailReg.test(String(formValues.organizationEmail).toLowerCase())) {
				errors.organizationEmail = i18n.t('translation:auth.errors.emailNotValid');
			}
			commonValidation(formValues, errors, 'organizationEmail')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.validate();
		} else {
			// this is a new clinic / lab
			commonValidation(formValues, errors, 'name')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.validate();
			commonValidation(formValues, errors, 'legalName')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.validate();
			commonValidation(formValues, errors, 'registeredOffice')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
				.validate();
			commonValidation(formValues, errors, 'owner').maxLength(100).validate();
			commonValidation(formValues, errors, 'uic').required().maxLength(20).validate();
			if (!emailReg.test(String(formValues.organizationEmail).toLowerCase())) {
				errors.organizationEmail = i18n.t('translation:auth.errors.emailNotValid');
			}
			commonValidation(formValues, errors, 'organizationEmail')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.validate();
			if (!phoneReg.test(String(formValues.organizationPhone).toLowerCase())) {
				errors.organizationPhone = i18n.t('translation:auth.errors.phoneNotValid');
			}
			commonValidation(formValues, errors, 'organizationPhone')
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
				.validate();

			if (formValues.organizationType === ORGANIZATION_TYPE.NEW_LAB) {
				commonValidation(formValues, errors, 'currencyCode')
					.required()
					.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
					.validate();
			}
		}

		return errors;
	},
	validateForgottenPassword: (formValues, props) => {
		let errors = {};
		if (!emailReg.test(String(formValues.email).toLowerCase())) {
			errors.email = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'email')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		return errors;
	},
	validateNewPassword: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'password')
			.required()
			.minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (formValues.password !== formValues.repeatPassword) {
			errors.repeatPassword = i18n.t('translation:common.errors.passwordsMismatch');
		}
		commonValidation(formValues, errors, 'repeatPassword').required().validate();
		return errors;
	},
	validatePasswordChange: (formValues, props) => {
		const errors = {};

		commonValidation(formValues, errors, 'password')
			.required()
			.minLength(MIN_TEXT_FIELD_LENGTH.PASSWORD)
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();

		if (formValues.password !== formValues.repeatPassword) {
			errors.repeatPassword = i18n.t('translation:common.errors.passwordsMismatch');
		}

		commonValidation(formValues, errors, 'repeatPassword').required().validate();
		commonValidation(formValues, errors, 'oldPassword').required().validate();
		commonValidation(formValues, errors, 'role').required().validate();

		return errors;
	},
	validateCompanyDetails: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'name')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'legalName')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'registeredOffice')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(formValues, errors, 'owner').maxLength(100).validate();
		commonValidation(formValues, errors, 'uic').required().maxLength(20).validate();
		if (!emailReg.test(String(formValues.email).toLowerCase())) {
			errors.email = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'email')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		if (!phoneReg.test(String(formValues.phone).toLowerCase())) {
			errors.phone = i18n.t('translation:auth.errors.phoneNotValid');
		}
		commonValidation(formValues, errors, 'phone')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		commonValidation(formValues, errors, 'iban')
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		if (formValues.iban && !ibanTools.isValidIBAN(formValues.iban)) {
			errors.iban = i18n.t('translation:common.errors.ibanNotValid');
		}
		commonValidation(formValues, errors, 'bic')
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (formValues.bic && !ibanTools.isValidBIC(formValues.bic)) {
			errors.bic = i18n.t('translation:common.errors.bicNotValid');
		}
		commonValidation(formValues, errors, 'bankAddress')
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(formValues, errors, 'bankName')
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'currencyCode')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		return errors;
	},
	validateComments: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'comment')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		return errors;
	},
	validateFeedbackModal: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'topic')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'description')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.VERY_LONG)
			.validate();
		return errors;
	},
	validateSendUserInvitationModal: (formValues, props) => {
		let errors = {};

		if (!emailReg.test(String(formValues.email).toLowerCase())) {
			errors.email = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'email')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();

		if (formValues.sendInAnotherLanguage) {
			commonValidation(formValues, errors, 'languageCode').required().validate();
		}

		return errors;
	},
	validateSendLinkInvitationModal: (formValues, props) => {
		let errors = {};

		if (!emailReg.test(String(formValues.organizationEmail).toLowerCase())) {
			errors.organizationEmail = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'organizationEmail')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();

		return errors;
	},
	validateUserDetails: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'role').required().validate();
		return errors;
	},
	validateUserModal: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'username')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (!emailReg.test(String(formValues.email).toLowerCase())) {
			errors.email = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'email')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'firstName')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		commonValidation(formValues, errors, 'lastName')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (!phoneReg.test(String(formValues.phone).toLowerCase())) {
			errors.phone = i18n.t('translation:auth.errors.phoneNotValid');
		}
		commonValidation(formValues, errors, 'phone')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		return errors;
	},
	validateGlobalProductLanguagesTable: (formValues, props) => {
		let errors = {};

		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.BG}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.BG}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();

		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.DE}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.DE}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();

		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.EN}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.EN}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();

		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${LANGUAGE_CODES.TR}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(
			formValues,
			errors,
			`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${LANGUAGE_CODES.TR}`
		)
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();

		return errors;
	},
	validateAddLocationModal: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'name')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'legalName')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'address')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		if (!emailReg.test(String(formValues.email).toLowerCase())) {
			errors.email = i18n.t('translation:auth.errors.emailNotValid');
		}
		commonValidation(formValues, errors, 'email')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		if (!phoneReg.test(String(formValues.phone).toLowerCase())) {
			errors.phone = i18n.t('translation:auth.errors.phoneNotValid');
		}
		commonValidation(formValues, errors, 'phone')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		return errors;
	},
	validateAddOrEditOrderPhoto: (formValues, props) => {
		let errors = {};
		commonValidation(formValues, errors, 'description')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		return { ...errors };
	},
	validateFilters: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, FILTER_FIELDS.LAB_ORDER_ID)
			.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
			.validate();
		if (+formValues[FILTER_FIELDS.PRICE_TO] < +formValues[FILTER_FIELDS.PRICE_FROM]) {
			errors[FILTER_FIELDS.PRICE_FROM] = i18n.t('translation:orders.errors.fromToError');
		}
		if (
			formValues[FILTER_FIELDS.CREATE_DATE_FROM] &&
			formValues[FILTER_FIELDS.CREATE_DATE_TO] &&
			formValues[FILTER_FIELDS.CREATE_DATE_FROM].getTime() >
				formValues[FILTER_FIELDS.CREATE_DATE_TO].getTime()
		) {
			errors[FILTER_FIELDS.CREATE_DATE_FROM] = i18n.t(
				'translation:orders.errors.fromToError'
			);
		}
		if (
			formValues[FILTER_FIELDS.DELIVERY_DATE_FROM] &&
			formValues[FILTER_FIELDS.DELIVERY_DATE_TO] &&
			formValues[FILTER_FIELDS.DELIVERY_DATE_FROM].getTime() >
				formValues[FILTER_FIELDS.DELIVERY_DATE_TO].getTime()
		) {
			errors[FILTER_FIELDS.DELIVERY_DATE_FROM] = i18n.t(
				'translation:orders.errors.fromToError'
			);
		}
		if (
			formValues[FILTER_FIELDS.PAYMENT_DUE_DATE_FROM] &&
			formValues[FILTER_FIELDS.PAYMENT_DUE_DATE_TO] &&
			formValues[FILTER_FIELDS.PAYMENT_DUE_DATE_FROM].getTime() >
				formValues[FILTER_FIELDS.PAYMENT_DUE_DATE_TO].getTime()
		) {
			errors[FILTER_FIELDS.PAYMENT_DUE_DATE_FROM] = i18n.t(
				'translation:orders.errors.fromToError'
			);
		}
		return { ...errors };
	},
	validateStaffFilters: (formValues) => {
		let errors = {};
		if (
			formValues[FILTER_FIELDS.START_DATE] &&
			formValues[FILTER_FIELDS.END_DATE] &&
			formValues[FILTER_FIELDS.START_DATE].getTime() >
				formValues[FILTER_FIELDS.END_DATE].getTime()
		) {
			errors[FILTER_FIELDS.START_DATE] = i18n.t('translation:orders.errors.fromToError');
		}
		if (!formValues[FILTER_FIELDS.START_DATE] && formValues[FILTER_FIELDS.END_DATE]) {
			errors[FILTER_FIELDS.START_DATE] = i18n.t('translation:common.errors.requiredField');
		}
		if (formValues[FILTER_FIELDS.START_DATE] && !formValues[FILTER_FIELDS.END_DATE]) {
			errors[FILTER_FIELDS.END_DATE] = i18n.t('translation:common.errors.requiredField');
		}
		if (
			formValues[FILTER_FIELDS.DUE_DATE_FROM] &&
			formValues[FILTER_FIELDS.DUE_DATE_TO] &&
			formValues[FILTER_FIELDS.DUE_DATE_FROM].getTime() >
				formValues[FILTER_FIELDS.DUE_DATE_TO].getTime()
		) {
			errors[FILTER_FIELDS.DUE_DATE_FROM] = i18n.t('translation:orders.errors.fromToError');
		}
		if (!formValues[FILTER_FIELDS.DUE_DATE_FROM] && formValues[FILTER_FIELDS.DUE_DATE_TO]) {
			errors[FILTER_FIELDS.DUE_DATE_FROM] = i18n.t('translation:common.errors.requiredField');
		}
		if (formValues[FILTER_FIELDS.DUE_DATE_FROM] && !formValues[FILTER_FIELDS.DUE_DATE_TO]) {
			errors[FILTER_FIELDS.DUE_DATE_TO] = i18n.t('translation:common.errors.requiredField');
		}
		return { ...errors };
	},
	validateOrderDetails: (formValues, props) => {
		let errors = {};
		if (isOrderDraft(props.currentOrder)) {
			commonValidation(formValues, errors, 'notes')
				.maxLength(MAX_TEXT_FIELD_LENGTH.VERY_LONG)
				.validate();
			commonValidation(formValues, errors, 'patientName')
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.validate();
		}
		return { ...errors };
	},
	validateGlobalInventoryAddPhase: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'name_BG_0')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(formValues, errors, 'name_DE_0')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		commonValidation(formValues, errors, 'name_GB_0')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		return { ...errors };
	},
	validateLocalInventoryAddPhase: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'name_0')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		return { ...errors };
	},
	validateLocalInventoryEditTemplate: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'name')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();
		return { ...errors };
	},
	validateShift: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'name')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'code')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.ULTRA_SHORT)
			.validate();
		commonValidation(formValues, errors, 'startTime').required().validate();
		commonValidation(formValues, errors, 'endTime').required().validate();
		commonValidation(formValues, errors, 'hours').required().validate();
		commonValidation(formValues, errors, 'minutes_0').required().validate();
		return { ...errors };
	},
	validateStaffMarkDays: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'shiftAndAbsence')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		commonValidation(formValues, errors, 'endDate').required().validate();
		commonValidation(formValues, errors, 'startDate').required().validate();
		commonValidation(formValues, errors, 'notes')
			.maxLength(MAX_TEXT_FIELD_LENGTH.VERY_LONG)
			.validate();
		return { ...errors };
	},
	validateStaffRemoveDays: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'endDate').required().validate();
		commonValidation(formValues, errors, 'startDate').required().validate();
		return { ...errors };
	},
	validateMarkHoliday: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'description')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
			.validate();
		return { ...errors };
	},
	validateExportStaff: (formValues) => {
		let errors = {};
		commonValidation(formValues, errors, 'month').required().validate();
		commonValidation(formValues, errors, 'year').required().validate();
		return { ...errors };
	},
	validateAddTask: (formValues) => {
		let errors = {};

		if (formValues['startDateTime_0'] && formValues['endDateTime_0']) {
			if (
				formValues['endDateTime_0'] &&
				moment(formValues['endDateTime_0']).isBefore(formValues['startDateTime_0'])
			) {
				errors['startDateTime_0'] = i18n.t(
					'translation:taskManager.errors.startDateErrorMsg'
				);
			}
			if (moment(formValues['endDateTime_0']).isSame(formValues['startDateTime_0'])) {
				errors['endDateTime_0'] = i18n.t('translation:taskManager.errors.sameDateErrorMsg');
			}
		}

		commonValidation(formValues, errors, 'name_0')
			.required()
			.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
			.validate();

		return { ...errors };
	},
	validateBillingBGInitialDocNum: (formValues, touch) => {
		if (!formValues) {
			return {};
		}

		let errors = {};

		if (!formValues || isEmptyObject(formValues)) {
			errors = {
				country: i18n.t('translation:common.errors.requiredField'),
				initialDocumentNumber: i18n.t('translation:common.errors.requiredField')
			};
			return errors;
		}

		commonValidation(formValues, errors, 'country').required().validate();
		commonValidation(formValues, errors, 'initialDocumentNumber').required().validate();

		if (isEmptyObject(errors)) {
			let digitOnly = /^\d+$/;

			if (formValues['initialDocumentNumber'].length !== 10) {
				errors['initialDocumentNumber'] = i18n.t(
					'translation:billingModule.settings.errors.fieldExactLength',
					{ exactDigits: 10 }
				);
			}

			if (!digitOnly.test(formValues['initialDocumentNumber'])) {
				errors['initialDocumentNumber'] = i18n.t(
					'translation:billingModule.settings.errors.digitsOnlyField'
				);
			}
		}

		return { ...errors };
	},
	validateTaxType: (formValues) => {
		let errors = {};

		if (!formValues || isEmptyObject(formValues)) {
			errors = {
				taxType: i18n.t('translation:common.errors.requiredField')
			};
			return errors;
		}

		commonValidation(formValues, errors, 'taxType').required().validate();

		return { ...errors };
	},
	validateTaxGroup: (formValues) => {
		let errors = {};

		commonValidation(formValues, errors, 'taxGroupName')
			.required()
			.minLength(1)
			.maxLength(16)
			.validate();

		commonValidation(formValues, errors, 'taxGroupRate').required().minLength(1).validate();

		return { ...errors };
	},
	validateOverdueInterval: (formValues) => {
		let errors = {};

		commonValidation(formValues, errors, 'overdueIntervalFromDay')
			.required()
			.maxLength(3)
			.validate();

		if (formValues.overdueIntervalFromDay) {
			if (formValues.overdueIntervalFromDay.toString().startsWith('-')) {
				errors.overdueIntervalFromDay = i18n.t(
					'translation:billingModule.settings.errors.negativeValue'
				);
			} else if (!onlyDigitReg.test(formValues.overdueIntervalFromDay)) {
				errors.overdueIntervalFromDay = i18n.t(
					'translation:billingModule.settings.errors.onlyWholeNumberAllowed'
				);
			}
		}

		commonValidation(formValues, errors, 'overdueIntervalToDay')
			.required()
			.maxLength(3)
			.validate();

		if (formValues.overdueIntervalToDay) {
			if (formValues.overdueIntervalToDay.toString().startsWith('-')) {
				errors.overdueIntervalToDay = i18n.t(
					'translation:billingModule.settings.errors.negativeValue'
				);
			} else if (!onlyDigitReg.test(formValues.overdueIntervalToDay)) {
				errors.overdueIntervalToDay = i18n.t(
					'translation:billingModule.settings.errors.onlyWholeNumberAllowed'
				);
			}
		}

		return { ...errors };
	},
	validateFreeInvoiceAddProduct: (formValues, currentIndex) => {
		let errors = {};

		const formKeys = [
			`${INVOICE_FIELD_NAME.ITEM_NAME_}${currentIndex}`,
			`${INVOICE_FIELD_NAME.ITEM_UNIT_}${currentIndex}`,
			`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${currentIndex}`,
			`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${currentIndex}`,
			`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${currentIndex}`
		];

		for (let i = 0; i < formKeys.length; i++) {
			commonValidation(formValues, errors, formKeys[i]).required().validate();
		}

		if (
			formValues[`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${currentIndex}`].trim() ===
			'_'
		) {
			errors[`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${currentIndex}`] = ' ';
		}

		return { ...errors };
	},
	validateFreeInvoice: (formValues, taxType, touch, country) => {
		if (!formValues || isEmptyObject(formValues)) {
			return {};
		}

		let errors = {};

		commonValidation(formValues, errors, INVOICE_FIELD_NAME.TRANSACTION_METHOD)
			.required()
			.validate();

		formUtils.validateInvoiceDiscount(formValues, errors);
		formUtils.validateInvoiceContractorsInfo(formValues, errors, taxType);
		formUtils.validateInvoiceDates(formValues, errors, touch, country);

		return { ...errors };
	},
	validateOrderInvoice: (formValues, taxType, touch, country) => {
		let errors = {};

		commonValidation(formValues, errors, INVOICE_FIELD_NAME.TRANSACTION_METHOD)
			.required()
			.validate();

		commonValidation(formValues, errors, INVOICE_FIELD_NAME.INVOICE_NUMBER)
			.required()
			.validate();

		formUtils.validateInvoiceDiscount(formValues, errors);
		formUtils.validateInvoiceContractorsInfo(formValues, errors, taxType);
		formUtils.validateInvoiceDates(formValues, errors, touch, country);

		const ordersIds = formValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS];

		for (let orderId of ordersIds) {
			const orderInvoiceMethod =
				formValues[`${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderId}`];

			if (orderInvoiceMethod === INVOICING_METHODS.VALUE) {
				commonValidation(
					formValues,
					errors,
					`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_}${orderId}`
				)
					.required()
					.validate();
			} else {
				commonValidation(
					formValues,
					errors,
					`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_ROUND_}${orderId}`
				)
					.required()
					.validate();
			}

			commonValidation(
				formValues,
				errors,
				`${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderId}`
			)
				.required()
				.validate();
		}

		return { ...errors };
	},
	validateCreditDebitInvoice: (formValues, taxType, touch, country, documentType) => {
		let errors = {};

		commonValidation(formValues, errors, INVOICE_FIELD_NAME.TRANSACTION_METHOD)
			.required()
			.validate();
		if (documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			commonValidation(formValues, errors, INVOICE_FIELD_NAME.INVOICE_NUMBER)
				.required()
				.validate();
		}

		const documentInvoicesIds = formValues[INVOICE_FIELD_NAME.DOCUMENT_INVOICES_IDS];
		const itemsIdsFormField = formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS];

		for (let invoiceId of documentInvoicesIds) {
			const itemIds = itemsIdsFormField[invoiceId];

			let invoiceHasOneCreditedDebitedItem = false;

			itemIds.forEach((itemId) => {
				const id = `${invoiceId}_${itemId}`;

				if (documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
					const creditAmount =
						formValues[`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${id}`] ?? 0;
					const remainingCreditAmount = Number(
						getItemRemainingCreditAmount(formValues, id)
					).toFixed(2);

					if (creditAmount && Number(remainingCreditAmount) < Number(creditAmount)) {
						errors[`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${id}`] = ' ';
					}
					if (creditAmount) {
						invoiceHasOneCreditedDebitedItem = true;
					}
				} else {
					const debitAmount =
						formValues[`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${id}`] ?? 0;
					if (debitAmount) {
						invoiceHasOneCreditedDebitedItem = true;
						return;
					}
				}
			});

			if (!invoiceHasOneCreditedDebitedItem) {
				const firstItemId = `${invoiceId}_${itemIds[0]}`;
				errors[`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${firstItemId}`] = ' ';
			}
		}

		formUtils.validateInvoiceContractorsInfo(formValues, errors, taxType);
		formUtils.validateInvoiceDates(formValues, errors, touch, country);

		commonValidation(formValues, errors, INVOICE_FIELD_NAME.DOCUMENT_REASON)
			.required()
			.validate();

		return { ...errors };
	},
	validateInvoiceDiscount: (formValues, errors) => {
		const invoiceTotal = formValues[`${INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX}`];

		const discount = formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND];

		if (discount) {
			const discountTotal = formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX];

			commonValidation(
				formValues,
				errors,
				INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND
			)
				.required()
				.validate();

			if (Number(discountTotal) > Number(invoiceTotal)) {
				errors[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND] = i18n.t(
					'translation:billingModule.invoicing.errors.discountGreaterThanTotal'
				);
			}
		}
	},
	validateInvoiceDates: (formValues, errors, touch, country) => {
		const { DUE_DATE, DATE_OF_TAX_EVENT, DATE_OF_ISSUE } = INVOICE_FIELD_NAME;
		const invoiceDueDate = formValues[DUE_DATE];
		const dateOfTaxEvent = formValues[DATE_OF_TAX_EVENT];
		const dateOfIssue = formValues[DATE_OF_ISSUE];

		const dateOfIssueMilliseconds = formUtils.getRoundDateMilliseconds(dateOfIssue);
		const dueDateMilliseconds = formUtils.getRoundDateMilliseconds(invoiceDueDate);
		const dateOfTaxEventMilliseconds = formUtils.getRoundDateMilliseconds(dateOfTaxEvent);

		if (dueDateMilliseconds && dateOfIssueMilliseconds) {
			if (dueDateMilliseconds < dateOfIssueMilliseconds) {
				touch(DUE_DATE);
				errors[DUE_DATE] = i18n.t(
					'translation:billingModule.invoicing.errors.dateBeforeIssue'
				);
			}
		}

		if (
			dateOfIssueMilliseconds &&
			dateOfTaxEventMilliseconds &&
			dateOfIssueMilliseconds < dateOfTaxEventMilliseconds
		) {
			touch(DATE_OF_TAX_EVENT);
			errors[DATE_OF_TAX_EVENT] = i18n.t(
				'translation:billingModule.invoicing.errors.dateAfterIssue'
			);
		}
		const isCountryBG = country.toLowerCase() === LANGUAGE_CODES.BG.toLowerCase();

		isCountryBG &&
			formUtils.validateBGInvoiceDateRangeRules(errors, dateOfIssue, dateOfTaxEvent, touch);
	},
	getRoundDateMilliseconds: (dateString) => {
		if (!dateString) {
			return dateString;
		}

		const date = new Date(dateString);
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		const dateMilliseconds = date.getTime();

		return dateMilliseconds;
	},
	validateBGInvoiceDateRangeRules: (errors, dateOfIssueValue, dateOfTaxEventValue, touch) => {
		if (dateOfIssueValue && dateOfTaxEventValue) {
			const dateOfIssue = new Date(dateOfIssueValue);
			dateOfIssue.setHours(0);
			dateOfIssue.setMinutes(0);
			dateOfIssue.setSeconds(0);
			dateOfIssue.setMilliseconds(0);

			const dateOfTaxEvent = new Date(dateOfTaxEventValue);
			dateOfTaxEvent.setHours(0);
			dateOfTaxEvent.setMinutes(0);
			dateOfTaxEvent.setSeconds(0);
			dateOfTaxEvent.setMilliseconds(0);

			if (dateOfIssue && dateOfTaxEvent) {
				const difference = moment.duration(moment(dateOfTaxEvent).diff(dateOfIssue));
				const dateDifference = difference.asDays();

				if (dateDifference < -5) {
					touch(INVOICE_FIELD_NAME.DATE_OF_ISSUE);
					errors[INVOICE_FIELD_NAME.DATE_OF_ISSUE] = i18n.t(
						'translation:billingModule.invoicing.errors.invoiceDateRange'
					);
				}
			}
		}
	},
	validateInvoiceContractorsInfo: (formValues, errors, taxType) => {
		commonValidation(formValues, errors, 'dateOfIssue').required().validate();
		commonValidation(formValues, errors, 'dateOfTaxEvent').required().validate();
		commonValidation(formValues, errors, 'composedBy').required().validate();
		commonValidation(formValues, errors, 'recipientCompanyName')
			.required()
			.maxLength(100)
			.validate();
		commonValidation(formValues, errors, 'recipientUic').required().maxLength(20).validate();
		commonValidation(formValues, errors, 'recipientRegistrationAddress')
			.required()
			.maxLength(250)
			.validate();
		commonValidation(formValues, errors, 'recipientVatNumber')
			.required()
			.maxLength(20)
			.validate();
		commonValidation(formValues, errors, 'recipientResponsiblePerson')
			.maxLength(100)
			.validate();
		commonValidation(formValues, errors, 'supplierCompanyName')
			.required()
			.maxLength(100)
			.validate();
		commonValidation(formValues, errors, 'supplierUic').required().maxLength(20).validate();
		commonValidation(formValues, errors, 'supplierRegistrationAddress')
			.required()
			.maxLength(250)
			.validate();
		commonValidation(formValues, errors, 'supplierVatNumber')
			.required()
			.maxLength(20)
			.validate();
		commonValidation(formValues, errors, 'supplierResponsiblePerson').maxLength(100).validate();
		commonValidation(formValues, errors, 'verbally').required().validate();
		commonValidation(formValues, errors, 'notes').validate();
		commonValidation(formValues, errors, 'dueDate').required().validate();

		if (
			formValues.recipientUic &&
			formValues.supplierUic &&
			formValues.recipientUic === formValues.supplierUic
		) {
			errors.recipientUic = i18n.t('translation:common.errors.sameUicError');
			errors.supplierUic = i18n.t('translation:common.errors.sameUicError');
		}

		if (
			formValues.recipientVatNumber &&
			formValues.supplierVatNumber &&
			formValues.recipientVatNumber === formValues.supplierVatNumber
		) {
			errors.recipientVatNumber = i18n.t(
				'translation:billingModule.invoicing.errors.sameVatError',
				{ taxType: getSelectedTaxType(taxType) }
			);
			errors.supplierVatNumber = i18n.t(
				'translation:billingModule.invoicing.errors.sameVatError',
				{ taxType: getSelectedTaxType(taxType) }
			);
		}

		if (formValues.excludeTax) {
			commonValidation(formValues, errors, 'reasonForTaxExclude')
				.required()
				.maxLength()
				.validate();
		}

		// Bank Details Validations
		// Validate only if payment is selected and selected via Bank
		if (
			formValues.transactionMethod &&
			formValues.transactionMethod === TRANSACTION_METHOD.BANK
		) {
			// IBAN validations
			commonValidation(formValues, errors, INVOICE_FIELD_NAME.IBAN)
				.required()
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.validate();

			if (formValues.iban && !ibanTools.isValidIBAN(formValues.iban)) {
				errors.iban = i18n.t('translation:common.errors.ibanNotValid');
			}

			// BIC validations
			commonValidation(formValues, errors, INVOICE_FIELD_NAME.BIC)
				.maxLength(MAX_TEXT_FIELD_LENGTH.SHORT)
				.required()
				.validate();

			if (formValues.bic && !ibanTools.isValidBIC(formValues.bic)) {
				errors.bic = i18n.t('translation:common.errors.bicNotValid');
			}

			// Bank Name validations
			commonValidation(formValues, errors, INVOICE_FIELD_NAME.BANK_NAME)
				.maxLength(MAX_TEXT_FIELD_LENGTH.NORMAL)
				.required()
				.validate();
		}
	},

	validateRequiredTableField: (value) => {
		if (!value || (typeof value === 'string' && !value.trim().length)) {
			return ' ';
		}

		return undefined;
	},
	validateRequiredField: (value) => {
		if (value && value.toString().trim().length) {
			return undefined;
		}

		return i18n.t('translation:common.errors.requiredField');
	},
	validateDateNotPass: (date) => {
		if (!date) {
			return i18n.t('translation:common.errors.requiredField');
		}
		const nowDateMilliseconds = new Date(moment().format('LL')).getTime();
		const dateMilliseconds = new Date(date).getTime();

		if (dateMilliseconds < nowDateMilliseconds) {
			return i18n.t('translation:billingModule.invoicing.errors.dateAtPast');
		}

		return undefined;
	},
	renderErrorMessage: (meta) => {
		if (meta.error && meta.touched) {
			return <span className="text-danger position-absolute">{meta.error}</span>;
		}
	},
	getFieldStyle: (meta) => {
		let className = 'form-control form-field white-input-field';
		if (meta.error && meta.touched) {
			className += ' alert-danger';
		}

		return className;
	},

	options: {
		getLanguages: () => [
			{ name: '', value: '' },
			{ name: LANGUAGE_DISPLAY_NAMES.BG, value: LANGUAGE_CODES.BG },
			{ name: LANGUAGE_DISPLAY_NAMES.DE, value: LANGUAGE_CODES.DE },
			{ name: LANGUAGE_DISPLAY_NAMES.EN, value: LANGUAGE_CODES.EN },
			{ name: LANGUAGE_DISPLAY_NAMES.TR, value: LANGUAGE_CODES.TR }
		],
		getUserType: () => [
			{ name: '', value: '' },
			{ name: parseUserType(USER_TYPE.DENTIST), value: USER_TYPE.DENTIST },
			{ name: parseUserType(USER_TYPE.DENTAL_TECHNICIAN), value: USER_TYPE.DENTAL_TECHNICIAN }
		],
		getClinicType: () => [
			{ name: '', value: '' },
			{
				name: parseOrganizationType(ORGANIZATION_TYPE.NEW_CLINIC),
				value: ORGANIZATION_TYPE.NEW_CLINIC
			},
			{
				name: parseOrganizationType(ORGANIZATION_TYPE.EXISTING_CLINIC),
				value: ORGANIZATION_TYPE.EXISTING_CLINIC
			}
		],
		getLabType: () => [
			{ name: '', value: '' },
			{
				name: parseOrganizationType(ORGANIZATION_TYPE.NEW_LAB),
				value: ORGANIZATION_TYPE.NEW_LAB
			},
			{
				name: parseOrganizationType(ORGANIZATION_TYPE.EXISTING_LAB),
				value: ORGANIZATION_TYPE.EXISTING_LAB
			}
		],
		getUserRole: () => [
			{ name: '', value: '' },
			{ name: parseRole(USER_ROLE.USER), value: USER_ROLE.USER },
			{ name: parseRole(USER_ROLE.LOCAL_ADMIN), value: USER_ROLE.LOCAL_ADMIN }
		],
		getCurrencyCodes: (currencyCodes) => {
			let options = currencyCodes.map((item) => {
				return { name: item, value: item };
			});
			options.splice(0, 0, { name: '', value: '' });
			return options;
		},
		getGlobalProductCategory: () => [
			{ name: '', value: '' },
			{
				name: parseGlobalProductCategory(GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH),
				value: GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH
			},
			{
				name: parseGlobalProductCategory(GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE),
				value: GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE
			},
			{
				name: parseGlobalProductCategory(
					GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE_CONSECUTIVE_TEETH
				),
				value: GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE_CONSECUTIVE_TEETH
			}
		],
		getAvailableStaff: (staff) => {
			let options = staff.map((item) => {
				return { name: item.fullName, value: item.id };
			});
			options.unshift({ name: '', value: '' });
			return options;
		},
		availableEmployees: (staff) => {
			if (staff == undefined) {
				return [];
			}
			let options = staff.map((item) => {
				return { name: item.name, id: item.id };
			});
			return options;
		},
		getActiveUsers: (staff) => {
			let options = staff.map((item) => {
				return { name: item.firstName + ' ' + item.lastName, value: item.id };
			});
			options.unshift({ name: '', value: '' });
			return options;
		},
		getOrderType: (types) => {
			let options = types.map((item) => {
				return { name: item.name, value: item.value };
			});
			options.unshift({ name: '', value: 0 });
			return options;
		},
		getTasksTemplates: (data) => {
			let options = data.map((item) => {
				return { name: item.name, value: item.id };
			});
			options.unshift({
				name: i18n.t('translation:taskManager.orders.withoutTemplate'),
				value: 0
			});
			return options;
		},
		getOrders: (data) => {
			let options = data.map((item) => {
				return { name: item.labOrderId, value: item.id };
			});
			options.unshift({ name: '', value: 0 });
			return options;
		},
		getOrderItems: (data) => {
			let options = data.map((item) => {
				return { name: item.name, value: item.id };
			});
			options.unshift({ name: '', value: 0 });
			return options;
		},
		getOrderItemTasks: (data) => {
			let options = data.map((item) => {
				const statusName =
					item.startTime && item.endTime && item.assigneeName
						? item.title
						: item.title + '*';
				return {
					name: statusName,
					value: item.id,
					disabled: item.status == TASK_STATUS.COMPLETED
				};
			});
			options.unshift({ name: '', value: 0 });
			return options;
		},
		getDocInitNumCountriesItems: () => [
			{ name: '', value: '' },
			{
				name: COUNTRY_DISPLAY_NAMES.BG,
				value: COUNTRY_CODES_BILLING_MODULE.BG
			},
			{
				name: COUNTRY_DISPLAY_NAMES.DE,
				value: COUNTRY_CODES_BILLING_MODULE.DE
			},
			{
				name: COUNTRY_DISPLAY_NAMES.UK,
				value: COUNTRY_CODES_BILLING_MODULE.EN
			},
			{
				name: COUNTRY_DISPLAY_NAMES.TR,
				value: COUNTRY_CODES_BILLING_MODULE.TR
			},
			{
				name: 'Other',
				value: COUNTRY_CODES_BILLING_MODULE.OTHER
			}
		],
		getProductUnitOptions: () => [
			{ name: '', value: '' },
			{
				name: i18n.t('translation:billingModule.invoicing.table.freeInvoice.hoursUnit'),
				value: BILLING_PRODUCT_UNIT_TYPE.HOURS
			},
			{
				name: i18n.t('translation:billingModule.invoicing.table.freeInvoice.numberUnit'),
				value: BILLING_PRODUCT_UNIT_TYPE.NUMBER
			}
		],
		getTaxTypeOptions: () => [
			{
				name: i18n.t('translation:billingModule.common.vat'),
				value: TAX_TYPE.VAT
			},
			{
				name: i18n.t('translation:billingModule.common.salesTax'),
				value: TAX_TYPE.SALES_TAX
			}
		],
		getInvoicingMethodOptions: () => [
			{ name: '', value: '' },
			{
				name: i18n.t('translation:billingModule.invoicing.table.orderInvoice.percent'),
				value: INVOICING_METHODS.PERCENTAGE
			},
			{
				name: i18n.t('translation:billingModule.invoicing.table.orderInvoice.fixedAmount'),
				value: INVOICING_METHODS.VALUE
			}
		],
		getProductTaxGroupsOptions: (taxGroups, itemId, formValues) => {
			const { ITEM_INITIAL_TAX_PERCENT_TAX_NAME_ } = INVOICE_FIELD_NAME;
			const options = [{ name: '', value: '' }];

			taxGroups.map((tax, index) => {
				options.push({
					name: `${tax.percentage}% - ${tax.name}`,
					value: `${tax.percentage}_${tax.name}`
				});
			});

			const itemInitialTax =
				formValues && formValues[`${ITEM_INITIAL_TAX_PERCENT_TAX_NAME_}${itemId}`];

			if (itemInitialTax !== undefined) {
				const itemTaxInvolvedInOptions =
					options.findIndex((option) => option.value === itemInitialTax) !== -1;
				if (!itemTaxInvolvedInOptions) {
					const itemTaxName = itemInitialTax.split('_')[1];
					const itemTaxPercentage = itemInitialTax.split('_')[0];
					options.push({
						name: `${itemTaxPercentage}% - ${itemTaxName}`,
						value: `${itemTaxPercentage}_${itemTaxName}`
					});
				}
			}

			return options;
		},
		getZeroTaxOption: function (taxGroups) {
			return this.getProductTaxGroupsOptions(taxGroups).find((option) =>
				option.value.startsWith('0')
			);
		}
	},
	remoteSubmits: {
		onCompanyDetailsSubmit: (formValues) => {
			const route = navigationUtils.getRoute();
			if (isPracticeRoute(route)) {
				if (isNew(route)) {
					store.dispatch(createClinic(formValues));
				} else {
					if (formValues.iban) {
						formValues.iban = formValues.iban.replace(/ /g, '');
					}
					let practiceId =
						route === PAGES_PATHS.MY_PRACTICE_DETAILS
							? store.getState().account.clinicId
							: route.split('/').pop();
					store.dispatch(updateClinic(formValues, practiceId));
				}
			} else {
				if (isNew(route)) {
					store.dispatch(createLab(formValues));
				} else {
					if (formValues.iban) {
						formValues.iban = formValues.iban.replace(/ /g, '');
					}
					let labId =
						route === PAGES_PATHS.MY_LABORATORY_DETAILS
							? store.getState().account.labId
							: route.split('/').pop();
					store.dispatch(updateLab(formValues, labId));
				}
			}
		},
		onTableSubmit: (formValues, dispatch, props) => {
			if (typeof props.onTableFormSubmit == 'function') {
				props.onTableFormSubmit(formValues);
			}
		},
		onDraftOrderSubmit: (formValues, dispatch, props) => {
			const { currentOrder } = props;
			if (isOrderDraft(currentOrder)) {
				const clinic = formUtils.getFieldByType(
					currentOrder.fields,
					ORDER_FIELDS.CLINIC
				).value;
				let data = { ...getFormValues('orderDetailsForm')(store.getState()) };
				const editableFields = formUtils.getEditableFields(currentOrder.fields);
				for (let type in data) {
					if (data[type] instanceof Date) {
						data[type] = moment(data[type]).format('YYYY-MM-DD');
					} else {
						data[type] = data[type] || null;
					}
				}
				data = _.pick(data, editableFields);
				data.isPatientNameVisibleToLab = data.patientNameVisibleToLab || false;
				data.deliveryLocationId = +data.deliveryLocation || null;
				data.labTechnicianId = +data.labTechnician || null;
				data.returnLocationId = +data.returnLocation || null;
				data = _.omit(data, [
					'deliveryLocation',
					'labTechnician',
					'returnLocation',
					'patientNameVisibleToLab'
				]);
				if (isPassiveClinic(clinic)) {
					store.dispatch(updatePassiveDraft(currentOrder.id, data));
				} else {
					store.dispatch(updateDraft(currentOrder.id, data));
				}
			}
		}
	},
	normalize: {
		percentOrEmpty: (value, previousValue) =>
			!isNaN(value) &&
			((value !== '' && value > -1 && value < 101) || value === '') &&
			value.length <= 5
				? value
				: previousValue,
		number: (value, previousValue, allValues) =>
			Number.isInteger(+value) && !value.includes('.') ? value : previousValue,
		percent: (value, previousValue, allValues) =>
			parseFloat(value) &&
			+value <= 100 &&
			value !== '' &&
			!value.includes('-') &&
			value.length <= 5
				? +value[0] === 0
					? value.substring(1)
					: value
				: +value === 0
				? 0
				: previousValue,
		warranty: (value, previousValue, allValues) =>
			(Number.isInteger(+value) &&
				+value <= 1200 &&
				!value.includes(' ') &&
				!value.includes('.')) ||
			value === '0'
				? value
				: value === ''
				? 0
				: previousValue,
		price: (value, previousValue, allValues) =>
			(+value <= 999999.99 && twoDecimalReg.test(value)) || value === '0' || value === ''
				? value
				: value === ''
				? 0
				: previousValue,
		activeUsers: (value, previousValue, allValues) =>
			parseFloat(value) &&
			+value <= 999999 &&
			value !== '' &&
			!value.includes('-') &&
			!value.includes('.') &&
			value.length <= 6
				? +value[0] === 0
					? value.substring(1)
					: value
				: +value === 0
				? 0
				: previousValue,
		hour24: (value, previousValue, allValues) =>
			Number.isInteger(+value) &&
			!value.includes('.') &&
			!value.includes('-') &&
			!value.includes('+') &&
			Number(value) < 24
				? value
				: previousValue,
		hour: (value, previousValue, allValues) =>
			Number.isInteger(+value) &&
			!value.includes('.') &&
			!value.includes('-') &&
			!value.includes('+')
				? value
				: previousValue,
		invoiceDueDays: (value, previousValue, allValues) =>
			Number.isInteger(+value) &&
			Number(value) > 0 &&
			+value <= 365 &&
			!value.includes(' ') &&
			!value.includes('.')
				? parseInt(value)
				: value === ''
				? ''
				: previousValue,
		labOrder: (value, previousValue, allValues) =>
			/^[0-9,]*$/.test(value) ? value : previousValue,
		productQuantity: (value, previousValue, allValues) =>
			Number.isInteger(+value) && !value.includes('.') && value > -1 && value.length < 5
				? value
				: previousValue
	},
	getFieldByType: (fields, type) => {
		return _.find(fields, function (field) {
			return field.type === type;
		});
	},

	renderOrderField: (field, customProps) => {
		if (field) {
			const fieldName = _.camelCase(field.type);
			return Fields.orderFields[fieldName]({
				className: 'first',
				readableInput: field.state === ORDER_FIELD_STATUSES.READ_ONLY,
				blueLabel: true,
				...customProps
			});
		}
	},
	renderItemField: (field, customProps) => {
		if (field) {
			const fieldName = _.camelCase(field.type);
			return Fields.orderItemFields[fieldName]({
				readableInput: field.state === ORDER_FIELD_STATUSES.READ_ONLY,
				...customProps
			});
		}
	},
	getMandatoryFieldErrors: (mandatoryFields, formValues) => {
		const errors = {};
		for (let field of mandatoryFields) {
			if (!formValues[field] && formValues[field] !== false) {
				errors[field] = i18n.t('translation:common.errors.requiredField');
			}
		}
		return errors;
	},
	getMandatoryFields: (allFields = []) => {
		// NOTE: In isolated cases (paying the order amount in full and maybe others),
		// the argument doesn't come as an array but as an object where the usual
		// array items are separate properties. Converting the object to array in the latter case
		if (!Array.isArray(allFields)) {
			allFields = getArrayOfPropertyValues(allFields);
		}

		const mandatory = [];
		for (let field of allFields) {
			if (field.state === 'Mandatory') {
				mandatory.push(_.camelCase(field.type));
			}
		}
		_.remove(mandatory, function (field) {
			return field === _.camelCase(ORDER_ITEM_FIELDS.TEETH);
		});
		return mandatory;
	},
	getEditableFields: (allFields = []) => {
		// NOTE: In isolated cases (paying the order amount in full and maybe others),
		// the argument doesn't come as an array but as an object where the usual
		// array items are separate properties. Converting the object to array in the latter case
		if (!Array.isArray(allFields)) {
			allFields = getArrayOfPropertyValues(allFields);
		}

		const editableFields = [];
		for (let field of allFields) {
			if (field.state === 'Mandatory' || field.state === 'Optional') {
				editableFields.push(_.camelCase(field.type));
			}
		}
		return editableFields;
	},
	getReadOnlyFields: (allFields = [], includeAmountDue = true) => {
		// NOTE: In isolated cases (paying the order amount in full and maybe others),
		// the argument doesn't come as an array but as an object where the usual
		// array items are separate properties. Converting the object to array in the latter case
		if (!Array.isArray(allFields)) {
			allFields = getArrayOfPropertyValues(allFields);
		}

		const readOnly = [];
		for (let field of allFields) {
			if (
				field.state === ORDER_FIELD_STATUSES.READ_ONLY &&
				(field.type !== ORDER_FIELDS.AMOUNT_DUE || includeAmountDue)
			) {
				readOnly.push(_.camelCase(field.type));
			}
		}
		return readOnly;
	},
	getErrorsOnOrderSave: () => {
		const currentOrder = store.getState().orders.currentOrder;
		const fields = currentOrder.fields;
		const formValues = {
			...getFormValues('orderDetailsForm')(store.getState())
		};
		const editableFields = formUtils.getEditableFields(fields);
		const mandatoryFields = formUtils.getMandatoryFields(fields);
		const mandatoryFieldsError = isOrderDraft(currentOrder)
			? {}
			: formUtils.getMandatoryFieldErrors(mandatoryFields, formValues);
		return {
			...formUtils.getOrderDetailFieldError(formValues, editableFields),
			...mandatoryFieldsError
		};
	},
	getErrorsOnOrderChangeStatus: (status) => {
		const currentOrder = store.getState().orders.currentOrder;
		const fields = currentOrder.fields;
		const account = store.getState().account;
		const formValues = {
			...getFormValues('orderDetailsForm')(store.getState())
		};
		const editableFields = formUtils.getEditableFields(fields);
		const mandatoryFields = formUtils.getMandatoryFields(fields);
		const mandatoryFieldsError = formUtils.getMandatoryFieldErrors(mandatoryFields, formValues);
		const errors = {
			...formUtils.getOrderDetailFieldError(formValues, editableFields),
			...mandatoryFieldsError
		};
		if (
			isLabAdmin(account) &&
			isOrderOpened(currentOrder) &&
			status !== ORDER_STATUS.INFORMATION_REQUIRED
		) {
			if (!formValues.availableDentalTechnician) {
				errors.availableDentalTechnician = i18n.t(
					'translation:common.errors.requiredField'
				);
			}
			if (!formValues.availableMaterials) {
				errors.availableMaterials = i18n.t('translation:common.errors.requiredField');
			}
			if (!formValues.clientPaysOnTime) {
				errors.clientPaysOnTime = i18n.t('translation:common.errors.requiredField');
			}
		}
		return errors;
	},
	getOrderDetailFieldError: (formValues, editableFields) => {
		const errors = {};
		if (
			editableFields.includes(_.camelCase(ORDER_FIELDS.NOTES)) &&
			formValues[_.camelCase(ORDER_FIELDS.NOTES)] &&
			formValues[_.camelCase(ORDER_FIELDS.NOTES)].length > MAX_TEXT_FIELD_LENGTH.VERY_LONG
		) {
			errors[_.camelCase(ORDER_FIELDS.NOTES)] = i18n.t(
				'translation:common.errors.fieldMaxLength',
				{ word1: MAX_TEXT_FIELD_LENGTH.VERY_LONG }
			);
		}
		if (
			editableFields.includes(_.camelCase(ORDER_FIELDS.PATIENT_NAME)) &&
			formValues[_.camelCase(ORDER_FIELDS.PATIENT_NAME)] &&
			formValues[_.camelCase(ORDER_FIELDS.PATIENT_NAME)].length > MAX_TEXT_FIELD_LENGTH.NORMAL
		) {
			errors[_.camelCase(ORDER_FIELDS.PATIENT_NAME)] = i18n.t(
				'translation:common.errors.fieldMaxLength',
				{ word1: MAX_TEXT_FIELD_LENGTH.NORMAL }
			);
		}
		if (
			editableFields.includes(_.camelCase(ORDER_FIELDS.PAYMENT_TERMS)) &&
			formValues[_.camelCase(ORDER_FIELDS.PAYMENT_TERMS)] &&
			formValues[_.camelCase(ORDER_FIELDS.PAYMENT_TERMS)].length >
				MAX_TEXT_FIELD_LENGTH.VERY_LONG
		) {
			errors[_.camelCase(ORDER_FIELDS.PAYMENT_TERMS)] = ' ';
		}

		return errors;
	},
	getOrderItemFieldErrors: (formValues, editableFields) => {
		const errors = {};
		if (
			editableFields.includes(_.camelCase(ORDER_ITEM_FIELDS.NOTES)) &&
			formValues[_.camelCase(ORDER_ITEM_FIELDS.NOTES)] &&
			formValues[_.camelCase(ORDER_ITEM_FIELDS.NOTES)].length >
				MAX_TEXT_FIELD_LENGTH.VERY_LONG
		) {
			errors[_.camelCase(ORDER_ITEM_FIELDS.NOTES)] = i18n.t(
				'translation:common.errors.fieldMaxLength',
				{ word1: MAX_TEXT_FIELD_LENGTH.VERY_LONG }
			);
		}
		return errors;
	},
	updateDraft: () => {
		store.dispatch(submit('orderDetailsForm'));
	}
};

export const createCustomPropsForCurrency = (lab) => {
	return lab
		? {
				currencyPrefix: lab.currencySymbol,
				currencySuffix: lab.localizedCurrencyAbbreviation,
				floatWithPrecisionTwo: true
		  }
		: {};
};

export const getItemAmountNoTax = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_AMOUNT_NO_TAX_}${itemID}`] ?? 0;

export const getItemUnitPriceNoTax = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${itemID}`] ?? 0;

export const getItemQuantity = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${itemID}`] ?? 0;

export const getItemInvoicingDiscount = (formValues, id) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_INVOICING_DISCOUNT_}${id}`] ?? 0;

export const getItemRemainingCreditAmount = (formValues, id) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_REMAINING_CREDIT_AMOUNT_}${id}`] ?? 0;

export const getItemCreditDebitNoRound = (formValues, id) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_NO_ROUND_}${id}`] ?? 0;

export const getItemAdvance = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_ADVANCE_NO_TAX_}${itemID}`] ?? 0;

export const getItemCatalogNumber = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_CATALOG_NUMBER_}${itemID}`] ??
	i18n.t('translation:billingModule.common.notSet');

export const getItemName = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_NAME_}${itemID}`];

export const getLocalizationItemName = (formValues, lang, id) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.ITEM_NAME[lang]}${id}`];

export const getItemTaxName = (formValues, itemID) => {
	const itemTaxPercent_Name =
		formValues[`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${itemID}`] ?? ' _ ';
	const itemTaxNameSplit = itemTaxPercent_Name.split('_');

	return `${itemTaxNameSplit[0]}% - ${itemTaxNameSplit[1]}`;
};
export const getItemTax = (formValues, itemID) => {
	const itemTaxPercentName =
		formValues[`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${itemID}`];
	if (itemTaxPercentName) {
		return formValues[`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${itemID}`].split(
			'_'
		)[0];
	}

	return 0;
};

export const getItemTotalTax = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_TOTAL_TAX_}${itemID}`] ?? 0;

export const getItemUnit = (formValues, itemID) => {
	const unit = formValues[`${INVOICE_FIELD_NAME.ITEM_UNIT_}${itemID}`];
	if (unit === BILLING_PRODUCT_UNIT_TYPE.HOURS) {
		return i18n.t('translation:billingModule.invoicing.table.freeInvoice.hoursUnit');
	}
	if (unit === BILLING_PRODUCT_UNIT_TYPE.NUMBER) {
		return i18n.t('translation:billingModule.invoicing.table.freeInvoice.numberUnit');
	}
	return '';
};
export const getItemTotalPriceWithTax = (formValues, itemID) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_TOTAL_PRICE_WITH_TAX_}${itemID}`] ?? 0;

export const getOrderTotal = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_TOTAL_NO_TAX_}${orderID}`] ?? 0;

export const getOrderTotalTax = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_TOTAL_TAX_}${orderID}`] ?? 0;

export const getOrderTotalAdvanceWithTax = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_TOTAL_ADVANCE_WITH_TAX_}${orderID}`] ?? 0;

export const getOrderPercentForInvoicingRound = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_ROUND_}${orderID}`] ?? 0;

export const getOrderPercentForInvoicing = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_}${orderID}`] ?? 0;

export const getOrderFixedAmountForInvoicingRound = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_}${orderID}`] ?? 0;

export const getOrderFixedAmountForInvoicing = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_}${orderID}`] ?? 0;

export const getOrderInvoicingMethod = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderID}`];

export const getOrderUnInvoicedAmountInPercentage = (formValues, orderID) =>
	formValues[`${INVOICE_FIELD_NAME.ORDER_UN_INVOICED_AMOUNT_IN_PERCENTAGE_}${orderID}`] ?? 0;

export const getDocumentItemsIDS = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS] ?? [];

export const getBankName = (formValues) => formValues[INVOICE_FIELD_NAME.BANK_NAME] ?? '';

export const getLocalizationBankName = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.BANK_NAME[lang]}`] ?? '';

export const getSupplierAddress = (formValues) =>
	formValues[INVOICE_FIELD_NAME.SUPPLIER_REGISTRATION_ADDRESS] ?? '';

export const getReceiverAddress = (formValues) =>
	formValues[INVOICE_FIELD_NAME.RECIPIENT_REGISTRATION_ADDRESS] ?? '';

export const getLocalizationSupplierAddress = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.SUPPLIER_ADDRESS[lang]}`] ?? '';

export const getLocalizationReceiverAddress = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.RECEIVER_ADDRESS[lang]}`] ?? '';

export const getComposedBy = (formValues) => formValues[INVOICE_FIELD_NAME.COMPOSED_BY] ?? '';

export const getLocalizationComposedBy = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.COMPOSED_BY[lang]}`] ?? '';

export const getSupplierName = (formValues) =>
	formValues[INVOICE_FIELD_NAME.SUPPLIER_COMPANY_NAME] ?? '';

export const getDocumentReason = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_REASON] ?? '';

export const getTaxExcludeReason = (formValues) =>
	formValues[INVOICE_FIELD_NAME.REASON_FOR_TAX_EXCLUDE] ?? '';

export const getReceiverName = (formValues) => {
	if (formValues[INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME]) {
		const split = formValues[INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME].split('_%_');
		return split[1];
	}
	return '';
};

export const getLocalizationSupplierName = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.SUPPLIER_NAME[lang]}`] ?? '';

export const getLocalizationReceiverName = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.RECEIVER_NAME[lang]}`] ?? '';

export const getSupplierResponsiblePerson = (formValues) =>
	formValues[INVOICE_FIELD_NAME.SUPPLIER_RESPONSIBLE_PERSON] ?? '';

export const getReceiverResponsiblePerson = (formValues) =>
	formValues[INVOICE_FIELD_NAME.RECIPIENT_RESPONSIBLE_PERSON] ?? '';

export const getLocalizationSupplierResponsiblePerson = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.SUPPLIER_RESPONSIBLE_PERSON[lang]}`] ?? '';

export const getLocalizationReceiverResponsiblePerson = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.RECEIVER_RESPONSIBLE_PERSON[lang]}`] ?? '';

export const getLocalizationVerbally = (formValues, lang) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.VERBALLY[lang]}`] ?? '';

export const getDocumentTotalNoTax = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_NO_TAX] ?? 0;

export const getDocumentTotalWithTax = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX] ?? 0;

export const getDocumentTotalTax = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_TAX] ?? 0;

export const getDocumentDiscountWithTax = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX] ?? 0;

export const getDocumentDiscountNoTaxNoRound = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND] ?? 0;

export const getDocumentDiscountNoTaxRound = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND] ?? 0;

export const getDocumentDiscountNote = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NOTE];

export const getInvoiceTotalWithTax = (formValues, invoiceID) =>
	formValues[`${INVOICE_FIELD_NAME.INVOICE_TOTAL_WITH_TAX_}${invoiceID}`] ?? 0;

export const getInvoiceTotalTax = (formValues, invoiceID) =>
	formValues[`${INVOICE_FIELD_NAME.INVOICE_TOTAL_TAX_}${invoiceID}`] ?? 0;

export const changeDocumentDueDate = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DUE_DATE, value);

export const changeDocumentDateOfIssue = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DATE_OF_ISSUE, value);

export const changeDocumentVerbally = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.VERBALLY, value);

export const changeDocumentNotes = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.NOTES, value);

export const changeOrderInvoicingMethod = (change, orderID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderID}`, value);

export const changeOrderFixedAmountForInvoicingRound = (change, orderID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_}${orderID}`,
		value
	);
export const changeOrderFixedAmountForInvoicing = (change, orderID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_}${orderID}`,
		value
	);

export const changeOrderPercentForInvoicingRound = (change, orderID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_ROUND_}${orderID}`,
		value
	);

export const changeOrderPercentForInvoicing = (change, orderID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_}${orderID}`,
		value
	);

export const changeOrderTotalAdvanceWithTax = (change, orderID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ORDER_TOTAL_ADVANCE_WITH_TAX_}${orderID}`,
		value
	);

export const changeOrderTotalTax = (change, orderID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ORDER_TOTAL_TAX_}${orderID}`, value);

export const changeOrderTotalNoTax = (change, orderID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ORDER_TOTAL_NO_TAX_}${orderID}`, value);

export const changeOrderUnInvoicedAmountInPercentage = (change, orderID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ORDER_UN_INVOICED_AMOUNT_IN_PERCENTAGE_}${orderID}`,
		value
	);

export const getDocumentOrdersIDS = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS] ?? [];

export const getLocalizedItemName = (formValues, lang, itemId) =>
	formValues[`${INVOICE_FIELD_NAME.LOCALIZATION.ITEM_NAME[lang]}${itemId}`];

export const changeDocumentOrdersIDS = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS, value);

export const getMapOrderIdToId = (formValues) => formValues[INVOICE_FIELD_NAME.MAP_ORDER_ID_ID];

export const changeMapOrderIdToId = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.MAP_ORDER_ID_ID, value);

//holds billingItemsIDS
export const changeDocumentItemsIDS = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS, value);

export const changeMapItemIdBillingItemId = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.MAP_ITEM_ID_BILLING_ITEM_ID, value);

export const changeDocumentTotalWithTax = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX, value);

export const changeDocumentTotalNoTax = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_TOTAL_NO_TAX, value);

export const changeDocumentTotalTax = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_TOTAL_TAX, value);

export const changeDocumentDiscountNoTaxRound = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND, value);

export const changeDocumentDiscountNoTaxNoRound = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_NO_ROUND, value);

export const changeDocumentDiscountNote = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NOTE, value);

export const changeDocumentDiscountShowInInvoice = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.SHOW_IN_INVOICE, value);

export const getDocumentTaxExclude = (formValues) =>
	formValues[INVOICE_FIELD_NAME.EXCLUDE_TAX] ? true : false;

export const changeDocumentReasonTaxExclude = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.REASON_FOR_TAX_EXCLUDE, value);

export const changeDocumentTaxExclude = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.EXCLUDE_TAX, value);

export const getDocumentDiscountTaxNotFormat = (formValues) => {
	return formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED];
};

//if 20.5% -> must be 0.205
export const changeDocumentDiscountTaxNotFormat = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED, value);

//if 20.5% -> must be 20.5
export const changeDocumentDiscountTaxFormat = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_ROUNDED, value);
export const changeDocumentDiscountWithTax = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX, value);

export const getDocumentInvoicesIDS = (formValues) =>
	formValues[INVOICE_FIELD_NAME.DOCUMENT_INVOICES_IDS] ?? [];

export const changeDocumentInvoicesIDS = (change, value) =>
	change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_INVOICES_IDS, value);

export const changeItemCatalogNumber = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_CATALOG_NUMBER_}${itemID}`, value ?? '');

export const changeItemAdvanceNoTax = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_ADVANCE_NO_TAX_}${itemID}`, value);

export const changeItemId = (change, orderItemId, id) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_ID_}${orderItemId}`, id);

export const getItemId = (formValues, orderItemId) =>
	formValues[`${INVOICE_FIELD_NAME.ITEM_ID_}${orderItemId}`];

export const changeItemName = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_NAME_}${itemID}`, value);

export const changeItemUnit = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_UNIT_}${itemID}`, value);

export const changeItemQuantity = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${itemID}`, value);

export const changeItemTotalPriceWithTax = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_TOTAL_PRICE_WITH_TAX_}${itemID}`, value);

export const changeItemTotalTax = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_TOTAL_TAX_}${itemID}`, value);

export const changeItemUnitPriceNoTax = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${itemID}`, value);

export const changeItemTaxPercentTaxName = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${itemID}`, value);

export const changeItemInitialTaxPercentTaxName = (change, itemID, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.ITEM_INITIAL_TAX_PERCENT_TAX_NAME_}${itemID}`,
		value
	);

export const changeItemAmountNoTax = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_AMOUNT_NO_TAX_}${itemID}`, value);

export const changeItemCreditDebitRound = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${itemID}`, value);

export const changeItemCreditDebitNoRound = (change, itemID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_NO_ROUND_}${itemID}`, value);

export const changeItemInvoicingDiscount = (change, id, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_INVOICING_DISCOUNT_}${id}`, value);

export const changeItemRamainingCreditAmount = (change, id, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.ITEM_REMAINING_CREDIT_AMOUNT_}${id}`, value);

export const changeInvoiceTotalTax = (change, invoiceID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.INVOICE_TOTAL_TAX_}${invoiceID}`, value);

export const changeInvoiceTotalWithTax = (change, invoiceID, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.INVOICE_TOTAL_WITH_TAX_}${invoiceID}`, value);

export const changeLocaliseBankName = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.BANK_NAME[lang]}`, value);

export const changeLocaliseComposerBy = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.COMPOSED_BY[lang]}`, value);

export const changeLocaliseDiscountDescription = (change, lang, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.LOCALIZATION.DISCOUNT_DESCRIPTION[lang]}`,
		value
	);

export const changeLocaliseDocumentReason = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.DOCUMENT_REASON[lang]}`, value);

export const changeLocaliseSupplierAddress = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.SUPPLIER_ADDRESS[lang]}`, value);

export const changeLocaliseSupplierName = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.SUPPLIER_NAME[lang]}`, value);

export const changeLocaliseSupplierResponsiblePerson = (change, lang, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.LOCALIZATION.SUPPLIER_RESPONSIBLE_PERSON[lang]}`,
		value
	);

export const changeLocaliseReceiverName = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.RECEIVER_NAME[lang]}`, value);

export const changeLocaliseReceiverAddress = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.RECEIVER_ADDRESS[lang]}`, value);

export const changeLocaliseReceiverResponsiblePerson = (change, lang, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.LOCALIZATION.RECEIVER_RESPONSIBLE_PERSON[lang]}`,
		value
	);

export const changeLocaliseTaxNotIncludedReason = (change, lang, value) =>
	change(
		'freeInvoiceForm',
		`${INVOICE_FIELD_NAME.LOCALIZATION.TAX_NOT_INCLUDED_REASON[lang]}`,
		value
	);

export const changeLocaliseVerbally = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.VERBALLY[lang]}`, value);

export const changeLocaliseNotes = (change, lang, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.NOTES[lang]}`, value);

export const changeLocaliseItemName = (change, lang, id, value) =>
	change('freeInvoiceForm', `${INVOICE_FIELD_NAME.LOCALIZATION.ITEM_NAME[lang]}${id}`, value);

export const fixRoundingDiff = (result, resultItems, onDifference) => {
	let resultItemsRoundedTotal = 0.0;

	for (let i = 0; i < resultItems.length; i++) {
		resultItemsRoundedTotal += Number(Number(resultItems[i]).toFixed(2));
	}

	const roundingDiff = result - resultItemsRoundedTotal;

	if (roundingDiff > 0) {
		onDifference(roundingDiff);
	}
};
