import {
	HIDE_AVATAR_MODAL,
	HIDE_MESSAGE_MODAL,
	SHOW_AVATAR_MODAL,
	SHOW_MESSAGE_MODAL
} from '../actions/types';

const initState = {
	messageModal: { isModalOpen: false, modalTitle: '', modalDescription: '', buttons: [] },
	feedbackModal: { isOpen: false },
	avatarModal: { isOpen: false, image: null },
	loader: {}
};

const portalReducer = (state = { ...initState }, action) => {
	switch (action.type) {
		case SHOW_MESSAGE_MODAL:
			state.messageModal = { isModalOpen: true, ...action.payload };
			state.messageModal = { ...state.messageModal };
			return { ...state };
		case HIDE_MESSAGE_MODAL:
			state.messageModal = {
				isModalOpen: false,
				modalTitle: '',
				modalDescription: '',
				isOnFront: false,
				buttons: []
			};
			state.messageModal = { ...state.messageModal };
			return { ...state };
		case SHOW_AVATAR_MODAL:
			return { ...state, avatarModal: { isOpen: true, ...action.payload } };
		case HIDE_AVATAR_MODAL:
			return { ...state, avatarModal: { isOpen: false, image: null } };
		default:
			return state;
	}
};

export default portalReducer;
