import React, { Fragment } from 'react';
import Table from '../../common/Table';
import i18n from '../../../i18n';
import { parseStaffWaitingApproval } from '../../../utils/tableUtils';
import SubHeader from '../../common/SubHeader';
import { isAdmin } from '../../../utils/appUtils';

class StaffWaitingApproval extends React.Component {
	render() {
		const { account } = this.props;

		// Only admins can see this section
		if (!isAdmin(account)) {
			return null;
		}

		const users = parseStaffWaitingApproval(this.props.users);

		// Render the section only if there are some users waiting approval
		if (users === null || users.length === 0) {
			return null;
		}

		return (
			<Fragment>
				<SubHeader title={i18n.t('translation:dashboard.usersWaitingApproval')} />
				<div className="col-12 mt-s p-0">
					<Table
						columns={[
							i18n.t('translation:common.table.name'),
							i18n.t('translation:common.table.phone'),
							i18n.t('translation:common.table.email'),
							i18n.t('translation:common.table.status')
						]}
						data={users}
					/>
				</div>
			</Fragment>
		);
	}
}

export default StaffWaitingApproval;
