import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../errors/NotFound';
import InternalServerError from '../errors/InternalServerError';
import UnhandledError from '../errors/UnhandledError';
import Home from '../pages/dashboards/DashboardGlobalAdmin';
import Users from '../pages/users/Users';
import UserDetails from '../pages/users/UserDetails';
import GlobalInventory from '../pages/inventory/GlobalInventory';
import Admins from '../pages/Admins';
import Clinics from '../pages/clinics/Clinics';
import ClinicDetails from '../pages/clinics/ClinicDetails';
import LabDetails from '../pages/labs/LabDetails';
import Labs from '../pages/labs/Labs';
import { PAGES_PATHS } from '../../utils/constants';
import OrderDetails from '../pages/orders/OrderDetails';
import Orders from '../pages/orders/Orders';
import WorkSchedule from '../pages/taskManager/planner/workSchedule/WorkSchedule';
import WorkingHours from '../pages/taskManager/planner/workingHours/WorkingHours';
import Holidays from '../pages/taskManager/planner/holidays/Holidays';
import Staff from '../pages/taskManager/planner/staff/Staff';
import CalendarOrders from '../pages/taskManager/calendar/orders/CalendarOrders';
import CalendarStaff from '../pages/taskManager/calendar/staff/CalendarStaff';

class GlobalAdminRoutes extends Component {
	render = () => (
		<Fragment>
			<Switch>
				<Route path={PAGES_PATHS.HOME} exact component={Home} />
				<Route path={PAGES_PATHS.USERS} exact component={Users} />
				<Route path={PAGES_PATHS.USER_DETAILS} exact component={UserDetails} />
				<Route path={PAGES_PATHS.INVENTORY} exact component={GlobalInventory} />
				<Route path={PAGES_PATHS.ADMINISTRATORS} exact component={Admins} />
				<Route path={PAGES_PATHS.ADMINISTRATOR_DETAILS} exact component={UserDetails} />
				<Route path={PAGES_PATHS.PRACTICES} exact component={Clinics} />
				<Route path={PAGES_PATHS.PRACTICE_DETAILS} exact component={ClinicDetails} />
				<Route path={PAGES_PATHS.LABORATORY_DETAILS} exact component={LabDetails} />
				<Route path={PAGES_PATHS.LABORATORIES} exact component={Labs} />
				<Route path={PAGES_PATHS.ORDERS} exact component={Orders} />
				<Route path={PAGES_PATHS.ORDERS_DETAILS} exact component={OrderDetails} />
				<Route path={PAGES_PATHS.MY_ACCOUNT} exact component={UserDetails} />
				<Route path={PAGES_PATHS.PLANNER} exact component={WorkSchedule} />
				<Route path={PAGES_PATHS.PLANNER_WORK_SCHEDULE} exact component={WorkSchedule} />
				<Route path={PAGES_PATHS.PLANNER_WORKING_HOURS} exact component={WorkingHours} />
				<Route path={PAGES_PATHS.PLANNER_HOLIDAYS} exact component={Holidays} />
				<Route path={PAGES_PATHS.PLANNER_STAFF} exact component={Staff} />
				<Route path={PAGES_PATHS.CALENDAR_ORDERS} exact component={CalendarOrders} />
				<Route path={PAGES_PATHS.CALENDAR_STAFF} exact component={CalendarStaff} />
				<Route path="/404" component={NotFound} />
				<Route path="/500" exact component={InternalServerError} />
				<Route path="/unhandledError" exact component={UnhandledError} />
				<Route component={NotFound} />
			</Switch>
		</Fragment>
	);
}

export default GlobalAdminRoutes;
