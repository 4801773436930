import { getAxiosWithToken } from '../../utils/webApi';
import { GET_STAFF } from '../types';
import { addZeroIfOneDigit, showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';
import { DOCUMENT_EXPORT_FILE_EXTENSION, ERROR_CODES } from '../../utils/constants';
import { hideMessageModal, showMessageModal } from '../portalActions';
import DefaultButton from '../../components/common/DefaultButton';
import store from '../../store';
import React from 'react';
import { createExcelFileUrl, getFileName } from '../common';

export const getStaff = (data, labId) => async (dispatch) => {
	const response = await getAxiosWithToken().post('/attendance/search-work-schedule', data, {
		params: {
			'lab-id': labId
		}
	});
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({ type: GET_STAFF, payload: returnedData.data });
	}
};

export const setWorkdays =
	(data, closeModal, getStaff, setErrorMessage, overwriteExisting = false) =>
	async (dispatch) => {
		const response = await getAxiosWithToken(false, false).post('/attendance/set-workdays', {
			...data,
			overwriteExisting
		});
		let returnedData = response.data;

		if (returnedData.success) {
			closeModal();
			getStaff();
			showSuccessPopup(i18n.t('translation:taskManager.common.successfullyMarkedDays'));
		} else {
			if (returnedData.code === ERROR_CODES.WORK_DAYS_OVERWRITE) {
				closeModal();
				const yesButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.yes')}
						onClick={() => {
							store.dispatch(hideMessageModal());
							store.dispatch(
								setWorkdays(data, closeModal, getStaff, setErrorMessage, true)
							);
						}}
					/>
				);
				const noButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.no')}
						onClick={() => {
							store.dispatch(hideMessageModal());
						}}
						secondary
					/>
				);
				dispatch(
					showMessageModal(
						i18n.t('translation:common.warning'),
						returnedData.userMessage,
						[yesButton, noButton]
					)
				);
			} else {
				setErrorMessage(returnedData.userMessage);
			}
		}
	};

export const removeWorkdays = (data, closeModal, getStaff, setErrorMessage) => async (dispatch) => {
	const response = await getAxiosWithToken(false, false).post(
		'/attendance/remove-workdays',
		data
	);
	let returnedData = response.data;

	if (returnedData.success) {
		closeModal();
		getStaff();
		showSuccessPopup(i18n.t('translation:taskManager.common.successfullyMarkedDays'));
	} else {
		setErrorMessage(returnedData.userMessage);
	}
};

export const exportStaff = (labId, data, closeModal) => async (dispatch, getState) => {
	closeModal();
	const response = await getAxiosWithToken(false).get('/attendance/export-work-schedule', {
		responseType: 'arraybuffer',
		params: { 'lab-id': labId, ...data }
	});
	const fileName = `Form76_${addZeroIfOneDigit(data.month)}_${data.year}`;
	const link = document.createElement('a');
	link.href = createExcelFileUrl(response.data);
	link.setAttribute('download', getFileName({}, fileName, DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL));

	document.body.appendChild(link);
	link.click();

	showSuccessPopup(i18n.t('translation:orders.successfullyDownloadedExcelFile'));
};
