import React, { Fragment } from 'react';
import i18n from '../../../../../../../i18n';
import { BILLING_DOCUMENT_TYPES } from '../../../../../../../utils/constants';

class Header extends React.Component {
	state = {
		debitColumns: [
			{
				text: i18n.t('translation:inventory.table.catalogNumber'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.product'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.unit'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.quantity'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.unitPrice'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.common.taxBase'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.creditNote.debitAmount'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.tax'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.totalPrice'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: '',
				colSpan: 1
			}
		],
		creditColumns: [
			{
				text: i18n.t('translation:inventory.table.catalogNumber'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.product'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.unit'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.quantity'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.unitPrice'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.common.taxBase'),
				colSpan: 2,
				noBreakWordStyle: true
			},
			{
				text: i18n.t(
					'translation:billingModule.invoicing.table.creditNote.discountFromInvoicing'
				),
				colSpan: 2
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.creditNote.invoicedAmount'),
				colSpan: 2
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.creditNote.taxBase'),
				colSpan: 2,
				hoverDescription: i18n.t(
					'translation:billingModule.invoicing.table.creditNote.taxBaseDescription'
				)
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.tax'),
				colSpan: 1,
				noBreakWordStyle: true
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.creditNote.totalAmount'),
				colSpan: 2,
				hoverDescription: i18n.t(
					'translation:billingModule.invoicing.table.creditNote.totalAmountDescription'
				)
			},
			{
				text: '',
				colSpan: 1
			}
		]
	};

	render() {
		const columnNumber =
			this.props.invoiceType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ? 18 : 14;

		const columns =
			this.props.invoiceType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE
				? this.state.creditColumns
				: this.state.debitColumns;

		return (
			<thead
				aria-colspan={columnNumber}
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 100
				}}>
				<tr aria-colspan={columnNumber} className={'prod-info'}>
					{columns.map((column, index) => {
						const tableHeaderStyle = index === columns.length - 1 ? 'text-left' : '';

						const { noBreakWordStyle, colSpan, text, hoverDescription } = column;

						const headerDescriptionProps = {
							datatoggle: 'tooltip',
							dataplacement: 'right',
							title: hoverDescription
						};

						const descriptionProps = hoverDescription ? headerDescriptionProps : {};

						const title = hoverDescription ? `${text}*` : text;

						return (
							<Fragment key={index + 'tableColumn'}>
								<th
									style={noBreakWordStyle ? noBreakWordStyles : undefined}
									className={tableHeaderStyle}
									key={index + 'h'}
									scope="col"
									colSpan={colSpan}
									{...descriptionProps}>
									<div className={'d-inline'}>{title}</div>
								</th>
							</Fragment>
						);
					})}
				</tr>
			</thead>
		);
	}
}

export default Header;

const noBreakWordStyles = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
};
