import React from 'react';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { customDateInput, MultipleSelectInput, textInput } from '../../../common/Fields';
import DefaultButton from '../../../common/DefaultButton';
import i18n from '../../../../i18n';
import {
	isClinicAdmin,
	isLabAdmin,
	parseBillingDocumentFilterType,
	parseDocumentsStatusFilterType,
	parseOrderInvoicingStatusFilterType
} from '../../../../utils/appUtils';
import {
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_STATUS,
	FILTER_FIELDS,
	INVOICING_FILTER_INPUT_NAME,
	ORDER_INVOICING_STATUS,
	PAGINATION_TYPES
} from '../../../../utils/constants';
import { getLinkedClinicsWithoutPagination, setPagination } from '../../../../actions';
import { formUtils } from '../../../../utils/formUtils';
import ClientsDropdownField from '../common/UI/ClientsDropdownField';

class DocumentsFilters extends React.Component {
	componentDidMount() {
		if (isLabAdmin(this.props.account)) {
			this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);
		}
	}

	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters pt-0">
					<form onSubmit={this.onFilterButtonClick}>{this.renderFilterInputs()}</form>
				</div>
			</div>
		);
	}

	renderStatusInput() {
		const documentStatuses = [];

		const statuses = isLabAdmin(this.props.account)
			? [
					BILLING_DOCUMENT_STATUS.DRAFT,
					BILLING_DOCUMENT_STATUS.SENT_FOR_APPROVAL,
					BILLING_DOCUMENT_STATUS.OPENED,
					BILLING_DOCUMENT_STATUS.PAID_PARTIALLY,
					BILLING_DOCUMENT_STATUS.PAID,
					BILLING_DOCUMENT_STATUS.REJECTED
			  ]
			: [
					BILLING_DOCUMENT_STATUS.SENT_FOR_APPROVAL,
					BILLING_DOCUMENT_STATUS.OPENED,
					BILLING_DOCUMENT_STATUS.PAID_PARTIALLY,
					BILLING_DOCUMENT_STATUS.PAID
			  ];
		for (let status of statuses) {
			documentStatuses.push({
				name: parseDocumentsStatusFilterType(status),
				id: status
			});
		}

		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.STATUSES}
				label={i18n.t('translation:billingModule.common.status')}
				customProps={{
					className: 'col-3 mr-0',
					options: documentStatuses
				}}
				component={MultipleSelectInput}
			/>
		);
	}

	renderFilterInputs = () => {
		const documentTypes = [];

		for (let type in BILLING_DOCUMENT_FILTER_TYPES) {
			documentTypes.push({ name: parseBillingDocumentFilterType(type), id: type });
		}

		return (
			<>
				<div className="row pt-4">
					<div className="col-xl-2 col-lg-3 col-3">{this.renderNumberInput()}</div>
					<div className="col-3">{this.renderTypeInput(documentTypes)}</div>
					<div className="col-3">{this.renderNameInput()}</div>
					<div
						style={{ minWidth: 0 }}
						className="d-flex flex-row justify-content-center align-items-end col-3 offset-xl-1">
						{this.renderFilterButton()}
					</div>
				</div>
				<div className="row pt-4">
					<div className="col-3">{this.renderOrderNumberInput()}</div>
					{this.renderStatusInput()}
					<div className="col-4">{this.renderIssueDatesInputs()}</div>
				</div>
			</>
		);
	};

	renderIssueDatesInputs() {
		return (
			<>
				<label>{i18n.t('translation:billingModule.common.issueDate')}</label>
				{this.renderIntervalFields(
					<Field
						name={FILTER_FIELDS.ISSUE_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={FILTER_FIELDS.ISSUE_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						component={customDateInput}
					/>
				)}
			</>
		);
	}

	renderOrderNumberInput() {
		return (
			<Field
				name={FILTER_FIELDS.LAB_ORDER_ID}
				label={i18n.t('translation:billingModule.common.orderNumber')}
				customProps={{ className: 'mr-0' }}
				component={textInput}
				normalize={formUtils.normalize.number}
			/>
		);
	}

	renderFilterButton() {
		return <DefaultButton title={i18n.t('translation:billingModule.common.filter')} />;
	}

	renderNameInput() {
		if (isLabAdmin(this.props.account)) {
			return (
				<ClientsDropdownField
					clients={this.props.clinics}
					name={'clinicSelect'}
					onChange={(event) => this.onClientChange(event.target)}
				/>
			);
		}

		if (isClinicAdmin(this.props.account)) {
			return this.renderLabLegalNameInput();
		}
	}

	renderLabLegalNameInput() {
		return (
			<Field
				name={FILTER_FIELDS.LAB_LEGAL_NAME}
				label={i18n.t('translation:billingModule.common.lab')}
				customProps={{ className: 'mr-0' }}
				component={textInput}
			/>
		);
	}

	onClientChange = (target) => {
		const { options } = target;
		const clinicLegalName = options[options.selectedIndex].text;
		this.props.change(FILTER_FIELDS.CLINIC_LEGAL_NAME, clinicLegalName);
	};

	renderTypeInput(documentTypes) {
		return (
			<Field
				name={FILTER_FIELDS.TYPES}
				label={i18n.t('translation:billingModule.common.type')}
				customProps={{
					className: 'mr-0',
					options: documentTypes
				}}
				component={MultipleSelectInput}
			/>
		);
	}

	renderNumberInput() {
		return (
			<Field
				name={FILTER_FIELDS.NUMBER}
				label={i18n.t('translation:billingModule.common.number')}
				customProps={{ className: 'mr-0' }}
				normalize={formUtils.normalize.number}
				component={textInput}
			/>
		);
	}

	onFilterButtonClick = (e) => {
		e.preventDefault();
		this.props.handleSubmit((formValues) => {
			this.props.onSubmit();
			this.props.setPagination(PAGINATION_TYPES.BILLING_DOCUMENTS, 1);
		})();
	};

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};
}

const filtersForm = reduxForm({
	form: 'billingDocumentFiltersForm',
	validate: validateFilters,
	destroyOnUnmount: true
})(DocumentsFilters);

const mapStateToProps = (state) => {
	return {
		clinics: state.clinics.clinics,
		account: state.account,
		settings: state.settings,
		formValues: getFormValues('billingDocumentFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	getLinkedClinicsWithoutPagination,
	change
})(filtersForm);

function validateFilters(formValues) {
	let errors = {};

	if (
		formValues.issueDateFrom &&
		formValues.issueDateTo &&
		formValues.issueDateFrom.getTime() > formValues.issueDateTo.getTime()
	) {
		errors['issueDateFrom'] = i18n.t('translation:billingModule.errors.fromToError');
	}

	return errors;
}
