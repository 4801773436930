import React, { Fragment } from 'react';
import i18n from '../../../i18n';
import Modal from 'react-modal';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import fileIcon from '../../../resources/icons/file-big.png';
import {
	getFileLocation,
	isPhotoFile,
	isPredefinedPhotoFile,
	isPredefinedVideoFile,
	isVideoFile
} from '../../../utils/fileUtils';
import { localizeFileTypeName } from '../../../utils/appUtils';
import DefaultButton from '../../common/DefaultButton';
import DownloadButton from '../../common/DownloadButton';

class FileDetailsModal extends React.Component {
	state = { windowHeight: window.innerHeight };

	handleResize = (e) => {
		this.setState({ windowHeight: window.innerHeight });
	};

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	render = () => {
		const { file } = this.props;

		if (file === undefined || file === null) {
			return null;
		}

		const photoFile = isPhotoFile(file);
		const videoFile = isVideoFile(file);
		const predefinedFile = isPredefinedPhotoFile(file) || isPredefinedVideoFile(file);

		return (
			<Modal
				isOpen={this.props.isOpen}
				className="custom-modal photo-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.props.onClose(null);
					}}
				/>
				{predefinedFile ? (
					<h2 className="mb-xs photo-modal-title">
						{localizeFileTypeName(file.fileSubType)}
					</h2>
				) : (
					<h2 className="mb-xs">{i18n.t('translation:orders.additionalFile')}</h2>
				)}

				{photoFile ? (
					<img
						className="img-fluid"
						src={getFileLocation(file.fileName)}
						alt={file.name}
					/>
				) : null}

				{videoFile ? (
					<Player>
						<source
							className="video-react-big-play-button img-fluid"
							src={getFileLocation(file.fileName)}
							alt={file.name}
						/>
					</Player>
				) : null}

				{!photoFile && !videoFile ? (
					<Fragment>
						<div className="nonPhotoFileRegion">
							<div>
								<img src={fileIcon} alt={''} />
							</div>
						</div>
						<div className="input-container full-width">
							<label className="input-label">
								{i18n.t('translation:common.fields.fileType')}
							</label>
							<div>{file.localizedFileTypeName}</div>
						</div>
					</Fragment>
				) : null}

				{!predefinedFile ? (
					<div className="input-container full-width">
						<label className="input-label">
							{i18n.t('translation:common.fields.description')}
						</label>
						<div>{file.description}</div>
					</div>
				) : null}

				<div className="buttons">
					<DownloadButton
						href={getFileLocation(file.fileName)}
						title={i18n.t('translation:common.buttons.download')}
					/>
					{this.renderEditButton(file)}
					{this.renderDeleteButton(file)}
					<DefaultButton
						title={i18n.t('translation:common.buttons.close')}
						onClick={() => {
							this.props.onClose(null);
						}}
						secondary
					/>
				</div>
			</Modal>
		);
	};

	renderEditButton = (file) => {
		if (!this.props.canEditFileData) {
			return null;
		}
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.edit')}
				onClick={() => {
					this.props.onClose();
					this.props.onEdit(file);
				}}
			/>
		);
	};

	renderDeleteButton = (file) => {
		if (!this.props.canDeleteFiles) {
			return null;
		}
		return (
			<DefaultButton
				danger
				title={i18n.t('translation:common.buttons.delete')}
				onClick={() => {
					this.props.onClose();
					this.props.onDelete(file);
				}}
			/>
		);
	};
}

export default FileDetailsModal;
