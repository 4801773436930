import React, { Fragment } from 'react';
import {
	getSelectedTaxType,
	isBillingModuleEnabled,
	isClinicAdmin,
	isLabAdmin,
	parseBillingDocumentFilterType
} from '../../../../utils/appUtils';
import {
	BILLING_DOCUMENT_FILTER_TYPES,
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	PAGES_PATHS,
	TABLE_ACTIONS
} from '../../../../utils/constants';
import { getListWithTooltip, parseInvoicingCurrency } from '../../../../utils/tableUtils';
import i18n from '../../../../i18n';
import Table from '../../../common/Table';
import Pagination from '../../../common/Pagination';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from '../../../../utils/moment';
import {
	confirmBillingDocument,
	hideMessageModal,
	rejectBillingDocument,
	showMessageModal
} from '../../../../actions';
import DefaultButton from '../../../common/DefaultButton';
import BillingDocumentModal, {
	BILLING_DOCUMENT_MODE
} from '../invoicing/billingDocumentModal/BillingDocumentModal';
import LinkButton from '../../../common/LinkButton';
import ExportPdfModal from './ExportPdfModal';
import Status from '../invoicing/billingDocumentModal/sections/common/Status';
import { getDocumentDisplayNumber } from '../utils';

class DocumentsTable extends React.Component {
	state = {
		isModalOpen: false,
		document: null,
		mode: null,
		isExportPdfModalOpen: false
	};

	render() {
		return (
			<Fragment>
				{this.renderDocuments()}
				{this.renderPagination()}
				{this.renderBillingDocumentModal()}
				{this.renderExportPdfModal()}
			</Fragment>
		);
	}

	getLocalizedTypeColumn(type) {
		let filterType;

		if (
			type === BILLING_DOCUMENT_TYPES.FREE_INVOICE ||
			type === BILLING_DOCUMENT_TYPES.ORDER_INVOICE
		) {
			filterType = BILLING_DOCUMENT_FILTER_TYPES.INVOICE;
		}

		if (type === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			filterType = BILLING_DOCUMENT_FILTER_TYPES.CREDIT_NOTE;
		}

		if (type === BILLING_DOCUMENT_TYPES.DEBIT_NOTE) {
			filterType = BILLING_DOCUMENT_FILTER_TYPES.DEBIT_NOTE;
		}

		if (!filterType) {
			return 'UNKNOWN';
		}

		return parseBillingDocumentFilterType(filterType);
	}

	parseBillingDocuments = (documents) => {
		let documentRows = [];

		const isLabLocalAdmin = isLabAdmin(this.props.account);
		const isClinicLocalAdmin = isClinicAdmin(this.props.account);

		for (let index in documents) {
			const document = documents[index];

			const issueDate = this.getIssueDateColumn(document);
			const ordersIds = this.getOrdersIdsColumn(document);
			const type = this.getLocalizedTypeColumn(document.type);
			const amount = this.getAmountColumn(document);
			const paid = this.getPaidColumn(document);
			const notPaid = this.getNotPaidColumn(document);
			const interceptedAmount = this.getInterceptedAmountColumn(document);
			const status = <Status status={document.status} />;

			if (isLabLocalAdmin) {
				documentRows.push([
					this.getNumberColumn(document),
					issueDate,
					ordersIds,
					type,
					this.getClinicLegalNameColumn(document),
					amount,
					paid,
					notPaid,
					interceptedAmount,
					status,
					this.getLabAdminActionButtonsColumn(document)
				]);
			}

			if (isClinicLocalAdmin) {
				documentRows.push([
					this.getNumberColumn(document),
					issueDate,
					ordersIds,
					type,
					this.getLabLegalNameColumn(document),
					amount,
					paid,
					notPaid,
					interceptedAmount,
					status,
					this.getClinicAdminActionButtonsColumn(document)
				]);
			}
		}

		return documentRows;
	};

	getNumberColumn(document) {
		let number = getDocumentDisplayNumber(document.id, document.number);

		if (isLabAdmin(this.props.account)) {
			return (
				<span className={'link'} onClick={() => this.openDocumentModal(document)}>
					{number}
				</span>
			);
		}

		return number;
	}

	getPaidColumn(document) {
		return this.getValueToDisplay(document.paidAmount ?? 0, document);
	}

	getNotPaidColumn(document) {
		return this.getValueToDisplay(document.remainingAmount ?? 0, document);
	}

	getInterceptedAmountColumn(document) {
		return this.getValueToDisplay(document.interceptedAmount ?? 0, document);
	}

	getValueToDisplay(value, document) {
		if (document.type === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			value = value * -1;
		}
		return parseInvoicingCurrency(
			value,
			document.localizedCurrencyAbbreviation,
			document.currencySymbol
		);
	}

	getAmountColumn(document) {
		return this.getValueToDisplay(document.total, document);
	}

	getClinicLegalNameColumn(document) {
		return (
			<Link to={PAGES_PATHS.CLIENTS + '/' + document.clinicId}>
				{document.clinicLegalName}
			</Link>
		);
	}

	getLabLegalNameColumn(document) {
		return (
			<Link to={PAGES_PATHS.SUPPLIERS + '/' + document.labId}>{document.labLegalName}</Link>
		);
	}

	getOrdersIdsColumn(document) {
		return getListWithTooltip(document.labOrderIds, '-');
	}

	getIssueDateColumn(document) {
		return moment(document.issueDate).format('DD.MM.YYYY');
	}

	getDocumentNumberColumn(document) {
		if (isBillingModuleEnabled(this.props.modules) && isLabAdmin(this.props.account)) {
			return (
				<LinkButton
					className={'p-0'}
					title={document.number}
					onClick={() => {
						this.openDocumentModal(document);
					}}
				/>
			);
		}

		return document.number;
	}

	getLabAdminActionButtonsColumn(document) {
		const preview = {
			type: TABLE_ACTIONS.VIEW,
			action: () => this.openDocumentModal(document)
		};

		const edit = {
			type: TABLE_ACTIONS.EDIT,
			action: () => this.openDocumentModal(document)
		};

		const download = {
			type: TABLE_ACTIONS.DOWNLOAD,
			action: () => {
				this.openExportPdfModal(document);
			}
		};

		if (!isBillingModuleEnabled(this.props.modules)) {
			return [download];
		}

		if (document.status !== BILLING_DOCUMENT_STATUS.DRAFT) {
			if (document.status === BILLING_DOCUMENT_STATUS.REJECTED) {
				return [preview];
			}
			return [preview, download];
		}

		return [edit, download];
	}

	getClinicAdminActionButtonsColumn(document) {
		const download = {
			type: TABLE_ACTIONS.DOWNLOAD,
			action: () => {
				this.openExportPdfModal(document);
			}
		};

		if (
			document.type !== BILLING_DOCUMENT_TYPES.DEBIT_NOTE &&
			document.type !== BILLING_DOCUMENT_TYPES.FREE_INVOICE
		) {
			return [download];
		}

		const documentStatus = document.status;

		if (documentStatus !== BILLING_DOCUMENT_STATUS.SENT_FOR_APPROVAL) {
			return [download];
		}

		const confirmButton = (
			<DefaultButton
				title={i18n.t('translation:billingModule.documents.buttons.confirm')}
				onClick={() => {
					this.props.confirmBillingDocument({
						documentId: document.id,
						onSuccess: () => {
							this.props.onConfirmDocumentSuccess &&
								this.props.onConfirmDocumentSuccess();
							this.props.hideMessageModal();
						}
					});
				}}
			/>
		);

		const rejectButton = (
			<DefaultButton
				title={i18n.t('translation:billingModule.documents.buttons.reject')}
				onClick={() => {
					this.props.rejectBillingDocument({
						documentId: document.id,
						onSuccess: () => {
							this.props.onConfirmDocumentSuccess &&
								this.props.onConfirmDocumentSuccess();
							this.props.hideMessageModal();
						}
					});
				}}
				class="btn base-button btn-outline-danger"
			/>
		);

		const confirm = {
			type: TABLE_ACTIONS.WARNING,

			action: () =>
				this.props.showMessageModal(
					i18n.t('translation:common.warning'),
					i18n.t('translation:billingModule.documents.confirmDocumentModal'),
					[confirmButton, rejectButton]
				)
		};

		return [confirm, download];
	}

	openDocumentModal = (document) => {
		this.setState({
			isModalOpen: true,
			document: document,
			mode:
				document.status === BILLING_DOCUMENT_STATUS.DRAFT
					? BILLING_DOCUMENT_MODE.EDIT
					: BILLING_DOCUMENT_MODE.PREVIEW
		});
	};

	onModalClose = () => {
		this.setState({ isModalOpen: false, document: null });
		this.props.onModalClose && this.props.onModalClose();
	};

	renderDocuments = () => {
		const taxType = getSelectedTaxType(this.props.billingSettings.taxType);

		const labAdminColumnsNames = [
			i18n.t('translation:billingModule.common.table.number'),
			i18n.t('translation:billingModule.common.table.issueDate'),
			i18n.t('translation:billingModule.common.table.orders'),
			i18n.t('translation:billingModule.common.table.type'),
			i18n.t('translation:billingModule.common.table.client'),
			i18n.t('translation:billingModule.common.table.amountWithTax', { taxType: taxType }),
			i18n.t('translation:billingModule.common.table.paid'),
			i18n.t('translation:billingModule.common.table.notPaid'),
			<span
				title={i18n.t('translation:billingModule.documents.table.interceptedTooltip')}
				key={'span'}>
				{i18n.t('translation:billingModule.documents.table.interceptedAmount')}*
			</span>,
			i18n.t('translation:billingModule.common.table.status'),
			i18n.t('translation:billingModule.common.table.action')
		];

		const clinicAdminColumnNames = [
			i18n.t('translation:billingModule.common.table.number'),
			i18n.t('translation:billingModule.common.table.issueDate'),
			i18n.t('translation:billingModule.common.table.orders'),
			i18n.t('translation:billingModule.common.table.type'),
			i18n.t('translation:billingModule.common.table.lab'),
			i18n.t('translation:billingModule.common.table.amountWithTax', {
				taxType: i18n.t('translation:billingModule.common.table.tax')
			}),
			i18n.t('translation:billingModule.common.table.paid'),
			i18n.t('translation:billingModule.common.table.notPaid'),
			<span
				title={i18n.t('translation:billingModule.documents.table.interceptedTooltip')}
				key={'span'}>
				{i18n.t('translation:billingModule.documents.table.interceptedAmount')}*
			</span>,
			i18n.t('translation:billingModule.common.table.status'),
			i18n.t('translation:billingModule.common.table.action')
		];

		const isLabLocalAdmin = isLabAdmin(this.props.account);
		const isClinicLocalAdmin = isClinicAdmin(this.props.account);

		let columnsNames = [];

		if (isLabLocalAdmin) {
			columnsNames = labAdminColumnsNames;
		}

		if (isClinicLocalAdmin) {
			columnsNames = clinicAdminColumnNames;
		}

		const documents = this.parseBillingDocuments(this.props.documents);

		return (
			<Fragment>
				<div className="col-12 mt-s p-0 documents-table">
					<Table columns={columnsNames} data={documents} firstCellNoWrap={true} />
				</div>
			</Fragment>
		);
	};

	renderPagination = () => {
		const { documents } = this.props;
		if (documents && this.props.paginated) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={this.props.totalElements}
					setCurrentPage={this.props.setCurrentPage}
				/>
			);
		}
	};

	renderBillingDocumentModal = () => {
		if (this.state.isModalOpen) {
			return (
				<BillingDocumentModal
					mode={this.state.mode}
					selectedDocumentId={this.state.document.id}
					clinicId={this.state.document.clinicId}
					documentType={this.state.document.type}
					isModalOpen={true}
					onModalClose={this.onModalClose}
					previousScreenFilters={this.props.filters}
					onDocumentChange={this.props.onDocumentChange}
				/>
			);
		}

		return null;
	};

	openExportPdfModal = (document) => {
		this.setState({
			document: document,
			isExportPdfModalOpen: true
		});
	};

	renderExportPdfModal = () => {
		const { isExportPdfModalOpen, document } = this.state;

		if (!isExportPdfModalOpen) {
			return null;
		}

		return (
			<ExportPdfModal
				isModalOpen={isExportPdfModalOpen}
				closeModal={this.closeExportPdfModal}
				document={document}
			/>
		);
	};

	closeExportPdfModal = () => {
		this.setState({
			isExportPdfModalOpen: false
		});
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		billingSettings: state.billingSettings,
		modules: state.modules
	};
};

export default connect(mapStateToProps, {
	getSelectedTaxType,
	confirmBillingDocument,
	rejectBillingDocument,
	showMessageModal,
	hideMessageModal
})(DocumentsTable);
