import React, { Fragment } from 'react';
import Header from '../../common/Header';
import { parseInventory } from '../../../utils/tableUtils';
import i18n from '../../../i18n';
import {
	isBillingModuleEnabled,
	isEmptyObject,
	isLabAdmin,
	isLabTechnician,
	isLocalInventory,
	isTaskManagerEnabled
} from '../../../utils/appUtils';
import Table from '../../common/Table';
import DefaultButton from '../../common/DefaultButton';
import { clearSubmitErrors, getFormValues, SubmissionError, submit } from 'redux-form';
import navigationMethods from '../../../utils/navigationUtils';
import { PAGES_PATHS, USER_ROLE } from '../../../utils/constants';
import { connect } from 'react-redux';
import {
	showMessageModal,
	updateIndividualInventory,
	updateMyLabInventory,
	getBillingSettings,
	getTaxGroups
} from '../../../actions';
import navigationUtils from '../../../utils/navigationUtils';

class UserInventory extends React.Component {
	constructor(props) {
		super(props);

		if (isLabAdmin(this.props.account)) {
			this.props.getBillingSettings(this.props.account.labId);
		}
	}

	render() {
		return (
			<Fragment>
				<Header title={this.getTitle()} buttons={[this.renderSaveInventoryButton()]} />
				<div className="col-12 p-0">
					<hr />
					{this.renderInventory(
						isTaskManagerEnabled(this.props.modules),
						isBillingModuleEnabled(this.props.modules)
					)}
				</div>
			</Fragment>
		);
	}

	getTitle = () => {
		if (isLocalInventory(navigationMethods.getRoute())) {
			return i18n.t('translation:inventory.inventory');
		}
		return i18n.t('translation:inventory.inventory') + ' - ' + this.props.currentClinic.name;
	};

	renderInventory = (isTaskManagerEnabled, isBillingModuleEnabled) => {
		const inventory = parseInventory(
			this.props.inventory,
			this.props.account,
			this.props.currentLab,
			this.props.formValues,
			isTaskManagerEnabled,
			isBillingModuleEnabled,
			this.props.taxGroups
		);
		const columns = [
			i18n.t('translation:inventory.table.catalogNumber'),
			i18n.t('translation:inventory.table.type'),
			i18n.t('translation:inventory.table.material'),
			i18n.t('translation:inventory.table.category')
		];

		const isLocalInventoryState = isLocalInventory(navigationMethods.getRoute());
		const userRole = this.props.account.role;

		if (isLabAdmin(this.props.account)) {
			columns.push(i18n.t('translation:inventory.table.color'));
			columns.push(i18n.t('translation:common.table.warranty'));

			columns.push(i18n.t('translation:billingModule.common.taxBase'));

			if (!isLocalInventoryState) {
				//columns.push('');
			}
			columns.push(i18n.t('translation:common.table.taxGroup'));
		}

		if (isLocalInventoryState && isTaskManagerEnabled && userRole !== USER_ROLE.USER) {
			columns.push(i18n.t('translation:common.table.action'));
		}

		let tableClassName =
			isLabAdmin(this.props.account) && !isLocalInventoryState
				? 'editable-inventory top-up-table'
				: 'editable-inventory';

		const initialValues = {};

		for (let item of this.props.inventory) {
			if (item.warrantyInMonths || item.price) {
				initialValues[`warranty_${item.id}`] = item.warrantyInMonths;
				initialValues[`price_${item.id}`] = item.price;

				if (item.taxGroupId) {
					initialValues[`taxGroupId_${item.id}`] = item.taxGroupId;
				}

				if (item.catalogNumber) {
					initialValues[`catalogNumber_${item.id}`] = item.catalogNumber;
				}
			}
		}

		return (
			<Table
				className={tableClassName}
				columns={columns}
				data={inventory}
				onSubmit={this.onTableFormSubmit}
				initialValues={{ ...initialValues }}
				leftAlignLastColumn={isLabTechnician(this.props.account)}
			/>
		);
		// }
	};

	renderSaveInventoryButton = () => {
		if (isLabAdmin(this.props.account)) {
			return (
				<DefaultButton
					title={i18n.t('translation:common.buttons.save')}
					onClick={() => {
						this.props.submit('tableForm');
					}}
				/>
			);
		}
	};

	onTableFormSubmit = (formValues) => {
		let errors = {};

		function validateWarranty(value) {
			const warrantyValue = parseFloat(formValues[`warranty_${value.split('_')[1]}`]);
			if (isNaN(warrantyValue) || warrantyValue < 0) {
				errors[`warranty_${value.split('_')[1]}`] = ' ';
			}
		}

		function validateTaxGroup(value) {
			if (!formValues[`taxGroupId_${value.split('_')[1]}`]) {
				errors[`taxGroupId_${value.split('_')[1]}`] = ' ';
			}
		}

		function validatePrice(value) {
			const priceValue = parseFloat(formValues[`price_${value.split('_')[1]}`]);
			if (isNaN(priceValue) || priceValue <= 0) {
				errors[`price_${value.split('_')[1]}`] = ' ';
			}
		}

		for (let value in formValues) {
			const valueName = value.split('_')[0];

			if (valueName === 'warranty' && formValues[value]) {
				validatePrice(value);
				validateTaxGroup(value);
			} else if (valueName === 'price' && formValues[value]) {
				validateWarranty(value);
				validateTaxGroup(value);
			} else if (valueName === 'taxGroupId' && formValues[value]) {
				validatePrice(value);
				validateWarranty(value);
			} else if (valueName === 'catalogNumber' && formValues[value]) {
				// Catalog Number is optional but if is filled, we need to warn the user that it
				// won't be saved without the other mandatory fields
				validatePrice(value);
				validateWarranty(value);
				validateTaxGroup(value);
			} else {
				this.props.clearSubmitErrors('tableForm');
			}
		}

		if (!isEmptyObject(errors)) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:common.errors.fillAllInventoryFields')
			);
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		const labProducts = [];
		let currentProduct = {};

		for (let product in formValues) {
			let valueName = product.split('_')[0];

			if (valueName === 'warranty') {
				currentProduct.warrantyInMonths = formValues[product];
				currentProduct.id = product.split('_')[1];
				currentProduct.price = formValues[`price_${product.split('_')[1]}`];
				currentProduct.taxGroupId = formValues[`taxGroupId_${product.split('_')[1]}`];
				currentProduct.catalogNumber = formValues[`catalogNumber_${product.split('_')[1]}`];

				if (
					currentProduct.warrantyInMonths &&
					currentProduct.price &&
					currentProduct.taxGroupId
				) {
					labProducts.push(currentProduct);
				}
				currentProduct = {};
			}
		}
		if (navigationMethods.getRoute() === PAGES_PATHS.INVENTORY) {
			this.props.updateMyLabInventory(this.props.account.labId, {
				labProducts
			});
		} else {
			this.props.updateIndividualInventory(
				this.props.account.labId,
				this.props.currentClinic.id,
				{ labProducts }
			);
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		currentLab: state.labs.currentLab,
		currentClinic: state.clinics.currentClinic,
		modules: state.modules,
		billingSettings: state.billingSettings,
		taxGroups: state.billingSettings.taxGroups,
		formValues: getFormValues('tableForm')(state)
	};
};

export default connect(mapStateToProps, {
	updateMyLabInventory,
	showMessageModal,
	updateIndividualInventory,
	getBillingSettings,
	getTaxGroups,
	submit,
	clearSubmitErrors
})(UserInventory);
