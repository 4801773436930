import React, { Fragment } from 'react';
import Modal from 'react-modal';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../../../common/Fields';
import {
	calculateTaskTime,
	getAvailableLabStaff,
	getCalendarOrderItems,
	getCalendarOrders,
	getCalendarStaffTasks,
	getCalendarStaffWithTasks,
	getOrderDetails,
	listPossibleAssignees,
	loadSelectedAssignee,
	updateTask
} from '../../../../../actions';
import {
	getTasksStatuses,
	getTaskStatusId,
	isAllNull,
	isEmptyObject,
	isGlobalAdmin,
	isLabTechnician,
	parseTaskStatus
} from '../../../../../utils/appUtils';
import { formUtils } from '../../../../../utils/formUtils';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	PAGES_PATHS,
	STAFFCHART_MODES,
	TASK_STATUS
} from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import TestDatesSection from '../../orders/TestDatesSection';
import _ from 'lodash';
import navigationUtils from '../../../../../utils/navigationUtils';

class EditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			saveWithConflict: false,
			isConflictSectionShown: false,
			conflictSectionMsg: [],
			readOnly: false,
			serverError: '',
			goToCalendar: false
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
			const { initialValues, singleEmployee } = this.props;

			if (!isGlobalAdmin(this.props.account) && !singleEmployee) {
				let assigneesDataProps = {};
				assigneesDataProps['taskHasDuration'] =
					initialValues.duration !== null && initialValues.duration !== '';
				assigneesDataProps['endTime'] = initialValues.endTime
					? moment(initialValues.endTime).format('YYYY-MM-DD HH:mm')
					: null;
				assigneesDataProps['startTime'] = initialValues.startTime
					? moment(initialValues.startTime).format('YYYY-MM-DD HH:mm')
					: null;

				this.props
					.listPossibleAssignees(assigneesDataProps, this.setServerError)
					.then((response) => {
						if (response) {
							this.props.loadSelectedAssignee(this.refineStaff(response));
						}
					});
			}

			let refinedInitialValues = {};
			refinedInitialValues['id'] = initialValues.id;
			refinedInitialValues['title'] = initialValues.title;
			refinedInitialValues['startDateTime_' + initialValues.id] = initialValues.startTime
				? new Date(initialValues.startTime)
				: null;
			refinedInitialValues['endDateTime_' + initialValues.id] = initialValues.endTime
				? new Date(initialValues.endTime)
				: null;
			refinedInitialValues['duration'] = initialValues.duration;
			refinedInitialValues['availableStaff_' + initialValues.id] = initialValues.assignee
				? [initialValues.assignee.id]
				: [];
			refinedInitialValues['notes'] = initialValues.generalNote;
			refinedInitialValues['completionTime'] = initialValues.completionTime;
			refinedInitialValues['status'] = initialValues.status
				? parseTaskStatus(initialValues.status)
				: parseTaskStatus(TASK_STATUS.OPENED);
			refinedInitialValues['taskStatuses'] = initialValues.status;
			refinedInitialValues['statusNote'] = initialValues.statusNote;

			this.props.initialize(refinedInitialValues);
		}

		if (
			prevProps.initialValues.assignee !== this.props.initialValues.assignee &&
			this.props.initialValues.assignee
		) {
			this.props.change('availableStaff_' + this.props.initialValues.id, [
				this.props.initialValues.assignee.id
			]);
		}

		if (this.state.goToCalendar) {
			navigationUtils.navigate(PAGES_PATHS.CALENDAR_STAFF);
			this.setState({ goToCalendar: false });
		}
	}

	render() {
		const { order, currentOrderItem, initialValues, filters } = this.props;
		const modalTitle = initialValues.title;
		let subTitle = '';

		if (order && currentOrderItem) {
			const orderTitle = i18n.t('translation:orders.orderNumber', {
				word1: order.labOrderId
			});
			const orderItemTitle = currentOrderItem.name;
			subTitle = orderTitle + '/' + orderItemTitle;
		} else {
			subTitle = initialValues.name;
		}
		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = this.state.readOnly || isReadOnly ? ' read-only-field' : '';
		const readOnlyClassSingleEmployee =
			this.state.readOnly || isReadOnly || this.props.singleEmployee
				? ' read-only-field'
				: '';

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll edit-calendar-order-item-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2 className="task-name">{modalTitle}</h2>
				<div className="mt-3 mb-2">
					<p className="m-0">{subTitle}</p>
				</div>
				<TestDatesSection
					initialValues={initialValues}
					isExcludedFromPlanning={false}
					customProps={{ className: 'mt-xs mb-xs test-dates-section' }}
				/>
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<div className="time-div">
						{Fields.workingHoursFields.startDateTime({
							className: 'first time-field p-0 mt-xs' + readOnlyClassSingleEmployee,
							id: initialValues.id,
							onChange: (event, isStartTime) => {
								if (typeof event == 'object') {
									const id = initialValues['id'];
									const data = {
										startTime: isStartTime
											? moment(event).format('YYYY-MM-DD HH:mm')
											: null,
										endTime: !isStartTime
											? moment(event).format('YYYY-MM-DD HH:mm')
											: null,
										duration: initialValues['duration']
											? initialValues['duration']
											: null,
										name: initialValues['title']
									};

									this.onChangeTimeField(isStartTime, data, id);
								}
							}
						})}
						{Fields.workingHoursFields.endDateTime({
							className:
								'first time-field end-time-field p-0 mt-xs' +
								readOnlyClassSingleEmployee,
							id: initialValues.id,
							onChange: (event, isStartTime) => {
								if (typeof event == 'object') {
									const id = initialValues['id'];
									const data = {
										startTime: isStartTime
											? moment(event).format('YYYY-MM-DD HH:mm')
											: null,
										endTime: !isStartTime
											? moment(event).format('YYYY-MM-DD HH:mm')
											: null,
										duration: initialValues['duration']
											? initialValues['duration']
											: null,
										name: initialValues['title']
									};

									this.onChangeTimeField(isStartTime, data, id);
								}
							}
						})}
					</div>
					<div className="task-section">
						{this.renderAvailableStaff()}
						{this.renderTaskStatus()}

						{Fields.commonFields.statusNotes({
							className: 'w-100 mt-s' + readOnlyClass,
							id: initialValues.id
						})}
						{Fields.commonFields.commonNote({
							className: 'w-100 mt-s' + readOnlyClassSingleEmployee
						})}
					</div>
					<span className="align-items-start text-danger">{this.state.serverError}</span>
					{this.conflictSection()}
					{this.renderButtons()}
				</form>
			</Modal>
		);
	}

	refineStaff = (staff) => {
		const filteredData = [];
		staff.map(function (item, key) {
			const filteredItem = {
				id: item.id,
				name: item.fullName
			};
			filteredData.push(filteredItem);
		});

		return filteredData;
	};

	renderAvailableStaff = () => {
		const { initialValues, availableStaff } = this.props;

		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = this.state.readOnly || isReadOnly ? ' read-only-field' : '';

		const assigneeName = Fields.templateFields.nameLocal({
			id: initialValues.assignee ? initialValues.id : null,
			value: initialValues.assignee ? initialValues.assignee.name : '-',
			readOnly: true,
			className: 'read-only-item',
			label: i18n.t('translation:taskManager.common.table.assignee')
		});

		const availableAssignees = Fields.commonFields.availableEmployees({
			selectionLimit: 1,
			options: formUtils.options.availableEmployees(availableStaff),
			className: 'p-0 mt-s' + readOnlyClass,
			id: initialValues.id
		});

		if (isGlobalAdmin(this.props.account) || this.props.singleEmployee) {
			return assigneeName;
		} else {
			return availableAssignees;
		}
	};

	renderTaskStatus = () => {
		const { initialValues, account } = this.props;

		const isReadOnly = isGlobalAdmin(account);
		const readOnlyClass = this.state.readOnly || isReadOnly ? ' read-only-field' : '';

		const taskStatusReadOnly = Fields.templateFields.nameLocal({
			value: initialValues.status
				? parseTaskStatus(initialValues.status)
				: parseTaskStatus(TASK_STATUS.OPENED),
			readOnly: true,
			className: 'read-only-item mt-xs',
			label: i18n.t('translation:taskManager.common.fields.taskStatus')
		});

		const taskStatus = Fields.commonFields.taskStatus({
			name: 'status',
			className: !isLabTechnician(account)
				? 'w-100 mt-m' + readOnlyClass
				: 'p-0 mt-xs' + readOnlyClass,
			options: getTasksStatuses()
		});

		if (isGlobalAdmin(account)) {
			return taskStatusReadOnly;
		} else {
			return taskStatus;
		}
	};

	onChangeTimeField = (isStartTime, data, id) => {
		const labId = this.props.account.labId;
		const { formValues, currentTemplate } = this.props;

		// setting up durations from template
		for (let index in currentTemplate['phases']) {
			const task = currentTemplate['phases'][index];
			if (task.name == data.name && task.duration !== null) {
				data['duration'] = task.duration;
			}
		}
		delete data['name'];

		if (data['duration'] == null || data['duration'] == '0:00') {
			return;
		}

		let assigneesDataProps = {};
		assigneesDataProps['taskHasDuration'] = true;
		assigneesDataProps['startTime'] = data.startTime
			? moment(data.startTime).format('YYYY-MM-DD HH:mm')
			: formValues['startDateTime_' + id]
			? moment(formValues['startDateTime_' + id]).format('YYYY-MM-DD HH:mm')
			: null;
		assigneesDataProps['endTime'] = data.endTime
			? moment(data.endTime).format('YYYY-MM-DD HH:mm')
			: formValues['endDateTime_' + id]
			? moment(formValues['endDateTime_' + id]).format('YYYY-MM-DD HH:mm')
			: null;

		this.props.calculateTaskTime(labId, isStartTime, data, (taskData) => {
			const endDateTimeField = formValues['endDateTime_' + id]
				? formValues['endDateTime_' + id]
				: null;
			const startDateTimeField = formValues['startDateTime_' + id]
				? formValues['startDateTime_' + id]
				: null;

			this.setState({ serverError: '' });

			if (isStartTime && endDateTimeField == null) {
				this.props.change('endDateTime_' + id, new Date(taskData.endTime));
				assigneesDataProps['startTime'] = moment(data.startTime).format('YYYY-MM-DD HH:mm');
				assigneesDataProps['endTime'] = moment(taskData.endTime).format('YYYY-MM-DD HH:mm');
			} else if (!isStartTime && startDateTimeField == null) {
				this.props.change('startDateTime_' + id, new Date(taskData.startTime));
				assigneesDataProps['startTime'] = moment(taskData.startTime).format(
					'YYYY-MM-DD HH:mm'
				);
				assigneesDataProps['endTime'] = moment(data.endTime).format('YYYY-MM-DD HH:mm');
			}

			this.props
				.listPossibleAssignees(assigneesDataProps, this.setServerError)
				.then((response) => {
					if (response) {
						this.props.loadSelectedAssignee(this.refineStaff(response));
					}
				});
		});
	};

	conflictSection = () => {
		if (!this.state.isConflictSectionShown) {
			return <Fragment />;
		}

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.no')}
				key={i18n.t('translation:taskManager.common.buttons.no')}
				onClick={this.setConflictSection}
				secondary
			/>
		);
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.yes')}
				key={i18n.t('translation:taskManager.common.buttons.yes')}
				onClick={() => this.saveDataConflictSection(false)}
			/>
		);
		const yesToStaffButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.toStaff')}
				key={i18n.t('translation:taskManager.common.buttons.toStaff')}
				onClick={() => this.saveDataConflictSection(true)}
			/>
		);

		const conflictsList =
			this.state.conflictSectionMsg.length > 0 ? (
				<ul>
					{this.state.conflictSectionMsg.map((item, index) => {
						return <li key={'conflict' + index}>{item}</li>;
					})}
				</ul>
			) : null;

		return (
			<div className="mb-s">
				<label className="orange-label mt-s">
					{i18n.t('translation:taskManager.orders.attention')}
				</label>
				<div className="conflict-section">
					<p>{i18n.t('translation:taskManager.orders.conflictsAppear')}</p>
					{conflictsList}
					<p>{i18n.t('translation:taskManager.orders.confirmConflicts')}</p>
					<div className="buttons task-buttons pb-0">
						{yesButton} {yesToStaffButton} {cancelButton}
					</div>
				</div>
			</div>
		);
	};

	renderButtons = () => {
		if (this.state.isConflictSectionShown) {
			return <Fragment />;
		}

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const saveButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				key={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.handleSubmit(this.onFormSubmit);
				}}
				isReadOnly={isGlobalAdmin(this.props.account)}
			/>
		);

		return (
			<div className="buttons">
				{saveButton}
				{cancelButton}
			</div>
		);
	};

	setConflictSection = () => {
		this.setState({
			conflictSectionMsg: [],
			isConflictSectionShown: false,
			readOnly: false
		});
	};

	saveDataConflictSection = (goToCalendar) => {
		this.setState(
			{
				saveWithConflict: true,
				conflictSectionMsg: '',
				goToCalendar: goToCalendar ? goToCalendar : false
			},
			() => this.onFormSubmit(this.props.formValues)
		);
	};

	onFormSubmit = (formValues) => {
		let errors = {};
		for (let value in formValues) {
			const id = value.split('_')[1];
			if (id) {
				const endDateTimeField = formValues['endDateTime_' + id]
					? formValues['endDateTime_' + id]
					: null;
				const startDateTimeField = formValues['startDateTime_' + id]
					? formValues['startDateTime_' + id]
					: null;

				if (moment(endDateTimeField).isBefore(startDateTimeField)) {
					const error = i18n.t('translation:taskManager.errors.startDateErrorMsg');
					errors['startDateTime_' + id] = ' ';
					this.setServerError(error);
				} else if (moment(endDateTimeField).isSame(startDateTimeField)) {
					const error = i18n.t('translation:taskManager.errors.sameDateErrorMsg');
					errors['startDateTime_' + id] = ' ';
					this.setServerError(error);
				}
			}
		}

		for (let index in errors) {
			if (errors[index] == undefined) {
				delete errors[index];
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		let initialValues = {};
		for (let value in formValues) {
			initialValues['forceUpdate'] = this.state.saveWithConflict;
			initialValues['title'] = this.props.initialValues.title;

			const id = value.split('_')[1];
			if (id) {
				initialValues['startTime'] = formValues['startDateTime_' + id]
					? moment(formValues['startDateTime_' + id]).format('YYYY-MM-DD HH:mm')
					: null;
				initialValues['endTime'] = formValues['endDateTime_' + id]
					? moment(formValues['endDateTime_' + id]).format('YYYY-MM-DD HH:mm')
					: null;
				initialValues['assignee'] =
					formValues['availableStaff_' + id] &&
					formValues['availableStaff_' + id].length > 0
						? { id: Number(formValues['availableStaff_' + id]) }
						: null;
			}

			initialValues['status'] = formValues['status']
				? getTaskStatusId(formValues['status'])
				: parseTaskStatus(TASK_STATUS.OPENED);
			initialValues['statusNote'] = formValues['statusNote']
				? formValues['statusNote']
				: null;
			initialValues['generalNote'] = formValues['notes'] ? formValues['notes'] : null;
		}

		const taskId = this.props.initialValues.id;
		this.props.updateTask(
			taskId,
			initialValues,
			this.setServerError,
			this.getOrderItemData,
			this.closeModal
		);
	};

	setServerError = (serverError, isConflict) => {
		if (isConflict) {
			this.setState({
				conflictSectionMsg: serverError,
				isConflictSectionShown: true,
				serverError: '',
				saveWithConflict: false,
				readOnly: true
			});
		} else {
			this.setState({ serverError });
		}
	};

	closeModal = () => {
		this.setState({
			date: moment(),
			saveWithConflict: false,
			conflictSectionMsg: [],
			isConflictSectionShown: false,
			readOnly: false,
			serverError: ''
		});
		this.props.destroy();
		this.props.closeModal();

		const currentOrderId = this.props.order ? this.props.order.id : null;
		if (currentOrderId) {
			this.props.getOrderDetails(currentOrderId, true);
		}
	};

	getOrderItemData = () => {
		const { order, filters, staffChart, mode, singleEmployee } = this.props;

		if (staffChart) {
			const accountId = this.props.account.id;
			const labId = this.props.account.labId;

			if (mode === STAFFCHART_MODES.WEEKS) {
				let startDate = moment(this.props.date)
					.startOf('isoWeek')
					.format(DEFAULT_BACKEND_DATE_FORMAT);
				let endDate = moment(this.props.date)
					.endOf('isoWeek')
					.format(DEFAULT_BACKEND_DATE_FORMAT);

				if (!isEmptyObject(filters) && !isAllNull(filters)) {
					startDate = filters.startDate
						? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
					endDate = filters.endDate
						? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
				}

				if (singleEmployee) {
					this.props.getCalendarStaffTasks(
						labId,
						{
							...filters,
							startDate,
							endDate
						},
						accountId,
						false,
						true
					);
				} else {
					this.props.getCalendarStaffWithTasks(
						labId,
						{
							...filters,
							startDate,
							endDate
						},
						false
					);
				}
			} else {
				let startDate = moment(this.props.date)
					.startOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);
				let endDate = moment(this.props.date)
					.endOf('day')
					.format(DEFAULT_BACKEND_DATE_FORMAT);

				if (!isEmptyObject(filters) && !isAllNull(filters)) {
					startDate = filters.startDate
						? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
					endDate = filters.endDate
						? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
						: null;
				}

				if (singleEmployee) {
					this.props.getCalendarStaffTasks(
						labId,
						{
							...filters,
							startDate,
							endDate
						},
						accountId,
						true,
						true
					);
				} else {
					this.props.getCalendarStaffWithTasks(
						labId,
						{
							...filters,
							startDate,
							endDate
						},
						true
					);
				}
			}
		} else if (!isEmptyObject(filters)) {
			const startDate = filters.startDate
				? moment(filters.startDate).format('YYYY-MM-DD')
				: null;
			const endDate = filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : null;
			const taskStatuses = filters.taskStatuses ? filters.taskStatuses : null;
			const taskAssigneeIdList = filters.taskAssigneeIdList
				? filters.taskAssigneeIdList
				: null;
			this.props.getCalendarOrderItems(
				order.id,
				startDate,
				endDate,
				taskStatuses,
				taskAssigneeIdList
			);
		} else if (this.props.orderDetails) {
			const startDate = null;
			const endDate = null;
			this.props.getCalendarOrderItems(order.id, startDate, endDate);
		} else {
			const startDate = moment().startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate = moment().endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
			this.props.getCalendarOrderItems(order.id, startDate, endDate);
		}

		this.closeModal();
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		availableStaff: state.tasks.labStaff,
		currentTask: state.tasks.currentTasks[0],
		formValues: getFormValues('editForm')(state),
		currentTemplate: state.tasks.currentTemplate
	};
};

const editForm = reduxForm({ form: 'editForm' })(EditModal);

export default connect(mapStateToProps, {
	getAvailableLabStaff,
	updateTask,
	calculateTaskTime,
	listPossibleAssignees,
	getCalendarOrders,
	getCalendarOrderItems,
	getCalendarStaffWithTasks,
	getCalendarStaffTasks,
	loadSelectedAssignee,
	getOrderDetails
})(editForm);
