import React from 'react';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import {
	clearReducers,
	createIndividualInventory,
	getClinicDetails,
	getIndividualInventory,
	getLabDetails,
	hideMessageModal,
	showMessageModal
} from '../../../actions';
import { connect } from 'react-redux';
import UserInventory from './UserInventory';
import { isLocalAdmin } from '../../../utils/appUtils';

class PriceList extends React.Component {
	componentDidMount() {
		const clinicId = this.props.match.params.id;
		const labId = this.props.account.labId;
		this.props.getLabDetails(labId);
		this.props.getClinicDetails(clinicId);
		this.props.getIndividualInventory(labId, clinicId).then((response) => {
			if (isLocalAdmin(this.props.account)) {
				if (!response.length) {
					const yesButton = (
						<DefaultButton
							title={i18n.t('translation:common.buttons.yes')}
							onClick={() => {
								this.props.createIndividualInventory(labId, clinicId);
								this.props.hideMessageModal();
							}}
						/>
					);
					const noButton = (
						<DefaultButton
							title={i18n.t('translation:common.buttons.no')}
							onClick={() => {
								this.props.hideMessageModal();
							}}
							secondary
						/>
					);
					this.props.showMessageModal(
						i18n.t('translation:common.confirmation'),
						i18n.t('translation:clinics.createPriceListWarning'),
						[yesButton, noButton]
					);
				}
			}
		});
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return <UserInventory inventory={this.props.inventory} />;
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		inventory: state.inventory.individualInventory
	};
};

export default connect(mapStateToProps, {
	getClinicDetails,
	getIndividualInventory,
	clearReducers,
	getLabDetails,
	showMessageModal,
	hideMessageModal,
	createIndividualInventory
})(PriceList);
