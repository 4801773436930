import React, { Fragment } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { formUtils } from '../../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import i18n from '../../../i18n';
import DataRow from '../../common/DataRow';
import {
	isGlobalAdmin,
	isLabAdmin,
	isLabTechnician,
	parseStatus,
	registeredInClinic
} from '../../../utils/appUtils';
import { ORDER_STATUS, PAGINATION_TYPES } from '../../../utils/constants';
import { setPagination } from '../../../actions';
import _ from 'lodash';

class Filters extends React.Component {
	state = { isFilterCollapsed: true };

	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters pt-0">
					<form onSubmit={this.onFilterButtonClick}>
						{this.renderFirstFilterRow()}
						{this.renderSecondFilterRow()}
						{this.renderLastFilterRow()}
					</form>
				</div>
			</div>
		);
	}

	renderFirstFilterRow = () => {
		const statuses = [];
		for (let status in ORDER_STATUS) {
			statuses.push({ name: parseStatus(status), id: status });
		}
		if (isLabTechnician(this.props.account)) {
			_.remove(statuses, function (status) {
				return status.id === ORDER_STATUS.DRAFT;
			});
		}

		const supplier = registeredInClinic(this.props.account) ? (
			<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
				{Fields.filterFields.supplier({ className: 'mr-m' })}
			</div>
		) : null;

		const client =
			isLabTechnician(this.props.account) || isLabAdmin(this.props.account) ? (
				<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
					{Fields.filterFields.client({ className: 'mr-m' })}
				</div>
			) : null;

		const status = !isGlobalAdmin(this.props.account) ? (
			<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-0 mt-s mb-s">
				{Fields.filterFields.status({ className: 'mr-m', options: statuses })}
			</div>
		) : null;

		const dentist = registeredInClinic(this.props.account) ? (
			<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
				{Fields.filterFields.dentist({ className: 'mr-m' })}
			</div>
		) : null;

		const labTechnician =
			!isGlobalAdmin(this.props.account) &&
			!registeredInClinic(this.props.account) &&
			!isLabTechnician(this.props.account) ? (
				<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
					{Fields.filterFields.labTechnician({ className: 'mr-m' })}
				</div>
			) : null;
		const practice = isGlobalAdmin(this.props.account) ? (
			<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
				{Fields.filterFields.practice({ className: 'mr-m' })}
			</div>
		) : null;
		const lab = isGlobalAdmin(this.props.account) ? (
			<div className="col-xl col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
				{Fields.filterFields.lab({ className: 'mr-m' })}
			</div>
		) : null;

		return (
			<div className="row m-0">
				<div className="mt-s">
					{Fields.filterFields.number({ className: 'small-input mr-m' })}
				</div>
				{supplier}
				{client}
				{labTechnician}
				{dentist}
				{status}
				{practice}
				{lab}
				<div className="col-xl col-lg-6 col-6 pr-xl-0 pl-sm-0 pl-0"></div>
				<div className="filter-button mr-s">
					<DefaultButton
						title={i18n.t('translation:orders.buttons.filter')}
						onClick={this.onFilterButtonClick}
					/>
					{this.renderTrigger()}
				</div>
			</div>
		);
	};

	onFilterButtonClick = (e) => {
		e.preventDefault();
		this.props.handleSubmit((formValues) => {
			this.props.setFilters(formValues);
			this.props.setPagination(PAGINATION_TYPES.ORDERS, 1);
		})();
	};

	renderSecondFilterRow = () => {
		if (!isGlobalAdmin(this.props.account) && !this.state.isFilterCollapsed) {
			return (
				<div className="row">
					<div className="col-xl-3 col-lg-6 col-6">
						<DataRow
							data={[
								{
									title: i18n.t('translation:orders.fields.filters.createdAt'),
									value: this.renderIntervalFields(
										Fields.filterFields.createDateFrom({
											className: 'mr-s d-block'
										}),
										Fields.filterFields.createDateTo({
											className: 'mr-s d-block'
										})
									)
								}
							]}
							noColumns
						/>
					</div>
					<div className="col-xl-3 col-lg-6 col-6">
						<DataRow
							data={[
								{
									title: i18n.t('translation:orders.fields.filters.deliveryDate'),
									value: this.renderIntervalFields(
										Fields.filterFields.deliveryDateFrom({
											className: 'mr-s d-block'
										}),
										Fields.filterFields.deliveryDateTo({
											className: 'mr-s d-block'
										})
									)
								}
							]}
							noColumns
						/>
					</div>
					<div className="col-xl-3 col-lg-6 col-6">
						{!isLabTechnician(this.props.account) ? (
							<DataRow
								data={[
									{
										title: i18n.t(
											'translation:orders.fields.filters.paymentDueDate'
										),
										value: this.renderIntervalFields(
											Fields.filterFields.paymentDueDateFrom({
												className: 'mr-s d-block'
											}),
											Fields.filterFields.paymentDueDateTo({
												className: 'mr-s d-block'
											})
										)
									}
								]}
								noColumns
							/>
						) : (
							<Fragment />
						)}
					</div>
					<div className="col-xl-3 col-lg-6 col-6">
						{!isLabTechnician(this.props.account) ? (
							<DataRow
								data={[
									{
										title: i18n.t('translation:orders.fields.filters.price'),
										value: this.renderIntervalFields(
											Fields.filterFields.priceFrom({
												className: 'small-input mr-s d-block'
											}),
											Fields.filterFields.priceTo({
												className: 'small-input mr-s d-block'
											})
										)
									}
								]}
								noColumns
							/>
						) : (
							<Fragment />
						)}
					</div>
				</div>
			);
		}
	};

	renderLastFilterRow = () => {
		if (!isGlobalAdmin(this.props.account) && !this.state.isFilterCollapsed) {
			const patientName = (
				<div className="col-xl-3 col-lg-6 col-6 pl-xl-0 pl-sm-0 mt-s pl-0">
					{Fields.filterFields.patientName({ className: 'mr-m' })}
				</div>
			);

			return <div className="row m-0">{patientName}</div>;
		}
	};

	collapseExpandFilter = () => {
		this.setState({ isFilterCollapsed: !this.state.isFilterCollapsed });
	};

	renderTrigger = () => {
		if (!isGlobalAdmin(this.props.account)) {
			return !this.state.isFilterCollapsed ? (
				<span
					className="arrow-up arrow-up-sub-header ml-xs"
					onClick={this.collapseExpandFilter}
				/>
			) : (
				<span
					className="arrow-down arrow-down-sub-header ml-xs"
					onClick={this.collapseExpandFilter}
				/>
			);
		}
	};

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};
}

const filtersForm = reduxForm({
	form: 'filtersForm',
	validate: formUtils.validateFilters,
	destroyOnUnmount: false
})(Filters);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		settings: state.settings,
		formValues: getFormValues('filtersForm')(state)
	};
};

export default connect(mapStateToProps, { setPagination })(filtersForm);
