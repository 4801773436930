import React, { Fragment } from 'react';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import IncomesOutcomesFilters from './IncomesOutcomesFilters';
import IncomesOutcomesTable from './IncomesOutcomesTable';
import { PAGINATION_TYPES, TRANSACTION_TYPE } from '../../../../utils/constants';
import DropdownButton from '../../../common/DropdownButton';
import {
	clearPagination,
	hideMessageModal,
	searchTransactions,
	setPagination,
	showMessageModal
} from '../../../../actions';
import { getFormValues } from 'redux-form';
import ExportTransactionsButton from './ExportTransactionsButton';
import IncomeOutcomeModal, { IncomeOutcomeModalContentType } from './IncomeOutcomeModal';

class IncomesOutcomes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			modalContentType: null,
			modalIsCreation: null,
			modalContentNumber: null,
			modalContentId: null
		};
	}

	componentDidMount() {
		this.setCurrentPage(1);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.searchTransactions({
				page: this.props.page,
				filters: this.props.formValues,
				labId: this.props.account.labId
			});
		}
	}

	componentWillUnmount() {
		this.props.clearPagination();
	}

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.TRANSACTIONS, page);
	};

	isIncome() {
		return this.props.transactionType === TRANSACTION_TYPE.DEBIT;
	}

	renderAddTransactionButton() {
		const buttons = [
			i18n.t('translation:billingModule.incomesOutcomes.buttons.addIncome'),
			i18n.t('translation:billingModule.incomesOutcomes.buttons.addOutcome')
		];

		const title = i18n.t('translation:billingModule.incomesOutcomes.buttons.addTransaction');

		return (
			<DropdownButton
				title={title}
				buttons={buttons}
				short={true}
				statusButton={false}
				isSecondButton={true}
				onButtonClick={(title) => this.onAddTransactionButtonClick(title)}
			/>
		);
	}

	onAddTransactionButtonClick(title) {
		if (title === i18n.t('translation:billingModule.incomesOutcomes.buttons.addIncome')) {
			this.setState({
				isModalOpen: true,
				modalContentType: IncomeOutcomeModalContentType.INCOME,
				modalIsCreation: true,
				modalContentId: null,
				modalContentNumber: null
			});
		} else {
			this.setState({
				isModalOpen: true,
				modalContentType: IncomeOutcomeModalContentType.OUTCOME,
				modalIsCreation: true,
				modalContentId: null,
				modalContentNumber: null
			});
		}
	}

	renderHeader() {
		return (
			<Header
				title={i18n.t(`translation:billingModule.incomesOutcomes.header`)}
				buttons={[this.renderAddTransactionButton(), this.renderExportButton()]}
			/>
		);
	}

	renderExportButton = () => {
		return <ExportTransactionsButton />;
	};

	modalCloseHandler = () => {
		this.setState({
			isModalOpen: false,
			modalContentType: null,
			modalIsCreation: null,
			modalContentNumber: null,
			modalContentId: null
		});
	};

	onActionButtonClickHandler =
		({ modalIsCreation, modalContentType, modalContentId, modalContentNumber }) =>
		() => {
			this.setState({
				isModalOpen: true,
				modalContentType,
				modalIsCreation,
				modalContentNumber,
				modalContentId
			});
		};

	onIncomeOutcomeChange() {
		this.props.searchTransactions({
			page: this.props.page ?? 1,
			filters: this.props.formValues,
			labId: this.props.account.labId
		});
	}

	render() {
		return (
			<Fragment>
				{this.renderHeader()}
				<IncomesOutcomesFilters />
				<IncomesOutcomesTable
					transactions={this.props.transactions?.content ?? []}
					totalElements={this.props.transactions?.totalElements}
					page={this.props.page}
					paginated={true}
					setCurrentPage={this.setCurrentPage}
					onActionButtonClick={this.onActionButtonClickHandler}
				/>
				<IncomeOutcomeModal
					isOpen={this.state.isModalOpen}
					contentType={this.state.modalContentType}
					isCreation={this.state.modalIsCreation}
					number={this.state.modalContentNumber}
					onClose={this.modalCloseHandler}
					contentId={this.state.modalContentId}
					onDocumentChange={() => this.onIncomeOutcomeChange()}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		page: state.pagination[PAGINATION_TYPES.TRANSACTIONS],
		transactions: state.billingTransactions.transactions,
		formValues: getFormValues('incomesOutcomesFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	searchTransactions,
	setPagination,
	showMessageModal,
	hideMessageModal,
	clearPagination
})(IncomesOutcomes);
