import { CLEAR_REDUCER, GET_STAFF } from '../../actions/types';

const initState = [];

const staffReducer = (state = [...initState], action) => {
	if (action.type === GET_STAFF) {
		state = action.payload;
		return [...state];
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return [...state];
	}

	return state;
};

export default staffReducer;
