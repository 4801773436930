import React, { Fragment } from 'react';
import i18n from '../../../../i18n';
import { Fields } from '../../../common/Fields';
import TableButton from '../../../common/TableButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../../utils/formUtils';

class GlobalInventoryAddPhaseForm extends React.Component {
	render() {
		return (
			<Fragment>
				<label className="blue-label mt-s">
					{i18n.t('translation:taskManager.inventory.addPhase')}
				</label>
				<form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
					<div className="modal-section table-languages">
						<table className="table">
							<tbody>
								<tr>
									<td className="pl-xl-4">
										{Fields.templateFields.nameGlobal({
											language: 'BG',
											className: 'first phase-name',
											id: 0
										})}
									</td>
									<td>
										{Fields.templateFields.nameGlobal({
											language: 'DE',
											className: 'first phase-name',
											id: 0
										})}
									</td>
									<td>
										{Fields.templateFields.nameGlobal({
											language: 'GB',
											className: 'first phase-name',
											id: 0
										})}
									</td>
									<td>
										{Fields.templateFields.duration({
											className: 'first',
											id: 0
										})}
									</td>
									<td className="pr-xl-4">
										<TableButton
											icon={faPlus}
											onPress={this.props.handleSubmit(this.props.onSubmit)}
											className="ml-auto mt-4"
											tooltip={i18n.t(
												'translation:taskManager.common.buttons.tooltips.add'
											)}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</form>
			</Fragment>
		);
	}
}

const addPhaseForm = reduxForm({
	form: 'globalInventoryPhasesForm',
	validate: formUtils.validateGlobalInventoryAddPhase
})(GlobalInventoryAddPhaseForm);

export default addPhaseForm;
