import { getAxiosWithToken } from '../../utils/webApi';
import {
	GET_DASHBOARD_CURRENT_EMPLOYEE_TASKS,
	GET_DASHBOARD_CURRENT_ORDERS,
	GET_DASHBOARD_MONTHLY_ORDERS
} from '../types';

export const getDashboardOrdersItems = (labId) => async (dispatch) => {
	const response = await getAxiosWithToken(false).get(`/order/dashboard?lab-id=${labId}`);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({ type: GET_DASHBOARD_CURRENT_ORDERS, payload: returnedData.data });
	}
};

export const getDashboardEmployeeTasks = (labId) => async (dispatch) => {
	const response = await getAxiosWithToken(false).get(`/task/employee-dashboard`);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({ type: GET_DASHBOARD_CURRENT_EMPLOYEE_TASKS, payload: returnedData.data });
	}
};

export const getDashboardMonthlyOrdersItems = (labId, year, month) => async (dispatch) => {
	const response = await getAxiosWithToken(false).get(
		`/order/dashboard/monthly?lab-id=${labId}&year=${year}&month=${month}`
	);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({ type: GET_DASHBOARD_MONTHLY_ORDERS, payload: returnedData.data });
	}
};
