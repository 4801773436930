import { SEARCH_STATEMENT, GET_STATEMENT_BALANCE } from '../../actions/types';

const initState = {
	statement: {},
	balance: {}
};

const billingStatementReducer = (state = initState, action) => {
	if (action.type === SEARCH_STATEMENT) {
		state.statement = action.payload;
		return { ...state };
	}

	if (action.type === GET_STATEMENT_BALANCE) {
		state.balance = action.payload;
		return { ...state };
	}

	return initState;
};

export default billingStatementReducer;
