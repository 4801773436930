import React, { Fragment } from 'react';
import i18n from '../../../../i18n';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmptyObject } from '../../../../utils/appUtils';

class TestDatesSection extends React.Component {
	state = { shownTestDates: true };

	render() {
		const { initialValues, isExcludedFromPlanning, customProps } = this.props;

		let testDates = Object.keys(initialValues)
			.filter(function (k) {
				return k.includes('Test');
			})
			.reduce(function (newData, k) {
				if (initialValues[k] !== null) {
					newData[k] = initialValues[k];
				}
				return newData;
			}, {});

		if (isExcludedFromPlanning || isEmptyObject(testDates)) {
			return <Fragment />;
		}

		const faIcon = this.state.shownTestDates ? (
			<FontAwesomeIcon className="arrow-button" icon={faCaretUp} />
		) : (
			<FontAwesomeIcon className="arrow-button" icon={faCaretDown} />
		);
		const className =
			customProps && customProps.className
				? customProps.className
				: 'mt-s test-dates-section';

		return (
			<div className={className}>
				<div className="conflict-section p-2">
					<div className="header">
						<label className="orange-label pl-2">
							{i18n.t('translation:taskManager.orders.attentionTestDates')}
						</label>
						<button onClick={this.toggleTestDates} className="icon-button w-auto">
							{faIcon}
						</button>
					</div>
					<ul className="list-group ml-4 mb-1" hidden={!this.state.shownTestDates}>
						{Object.keys(testDates).map((item) => {
							return testDates[item] !== null ? (
								<li className="ml-2">
									{' '}
									{this.getName(item)} - {testDates[item]}
								</li>
							) : null;
						})}
					</ul>
				</div>
			</div>
		);
	}

	getName = (type) => {
		const name = {
			metalTestDate: i18n.t('translation:orders.fields.metalTest'),
			ceramicTestDate: i18n.t('translation:orders.fields.ceramicTest'),
			individualSpoonTestDate: i18n.t('translation:orders.fields.individualSpoonTest'),
			biteTestDate: i18n.t('translation:orders.fields.biteTest'),
			arrangedTeethTestDate: i18n.t('translation:orders.fields.arrangedTeethTest'),
			orderItemMetalTestDate: i18n.t('translation:orders.fields.metalTest'),
			orderItemCeramicTestDate: i18n.t('translation:orders.fields.ceramicTest'),
			orderItemIndividualSpoonTestDate: i18n.t(
				'translation:orders.fields.individualSpoonTest'
			),
			orderItemBiteTestDate: i18n.t('translation:orders.fields.biteTest'),
			orderItemArrangedTeethTestDate: i18n.t('translation:orders.fields.arrangedTeethTest')
		};
		return name[type];
	};

	toggleTestDates = () => {
		this.setState({ shownTestDates: !this.state.shownTestDates });
	};
}

export default TestDatesSection;
