import React from 'react';
import Table from '../../../../common/Table';
import i18n from '../../../../../i18n';
import { connect } from 'react-redux';
import { parseStaffUsers } from '../../../../../utils/tableUtils';

class UsersTable extends React.Component {
	render() {
		const columns = [i18n.t('translation:taskManager.planner.table.employee')];
		const data = parseStaffUsers(this.props.staff);

		return (
			<Table
				columns={columns}
				data={data}
				leftAlignLastColumn={true}
				className={'users-table table-fix-header'}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		staff: state.staff
	};
};

export default connect(mapStateToProps)(UsersTable);
