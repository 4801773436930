import { STATEMENTS_CONNECTED_ITEM_TYPES, TRANSACTION_STATUS } from '../../../utils/constants';
import i18n from '../../../i18n';
import React from 'react';
import { getListWithTooltip } from '../../../utils/tableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';

export const getLocalizedConnectedTo = (connectedItemTypes) => {
	if (!connectedItemTypes || !connectedItemTypes.length) {
		return '-';
	}

	const localizedType = {
		[STATEMENTS_CONNECTED_ITEM_TYPES.INVOICE]: i18n.t(
			'translation:billingModule.balance.connectedWith.invoice'
		),
		[STATEMENTS_CONNECTED_ITEM_TYPES.DEBIT_NOTE]: i18n.t(
			'translation:billingModule.balance.connectedWith.debitNote'
		),
		[STATEMENTS_CONNECTED_ITEM_TYPES.CREDIT_NOTE]: i18n.t(
			'translation:billingModule.balance.connectedWith.creditNote'
		),
		[STATEMENTS_CONNECTED_ITEM_TYPES.ORDER]: i18n.t(
			'translation:billingModule.balance.connectedWith.order'
		),
		[STATEMENTS_CONNECTED_ITEM_TYPES.DEBIT_TRANSACTION]: i18n.t(
			'translation:billingModule.balance.connectedWith.income'
		)
	};

	const types = [...connectedItemTypes].map((currentItemType) => {
		if (
			currentItemType === STATEMENTS_CONNECTED_ITEM_TYPES.INVOICE ||
			currentItemType === STATEMENTS_CONNECTED_ITEM_TYPES.FREE_INVOICE ||
			currentItemType === STATEMENTS_CONNECTED_ITEM_TYPES.ORDER_INVOICE
		) {
			return STATEMENTS_CONNECTED_ITEM_TYPES.INVOICE;
		}
		return currentItemType;
	});

	const unique = Array.from(new Set(types)).map((el) => localizedType[el]);

	return getListWithTooltip(unique, '-', 3);
};

export const getDocumentDisplayNumber = (id, number) => {
	if (number) {
		return number;
	}

	return `${id} (${i18n.t('translation:billingModule.invoicing.common.draft')})`;
};

export const formatDateToYYYYMMDD = (date) => {
	if (!date) {
		return 'no_date';
	}

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}${month}${day}`;
};

export const renderTransactionStatus = (status) => {
	function renderCompletedStatus() {
		return (
			<div className="status completed">
				<FontAwesomeIcon icon={faCheck} className="status-icon" />
				<span>
					{i18n.t('translation:billingModule.incomesOutcomes.statuses.completed')}
				</span>
			</div>
		);
	}

	function renderCanceledStatus() {
		return (
			<div className="status negative">
				<FontAwesomeIcon icon={faBan} className="status-icon" />
				<span>{i18n.t('translation:billingModule.incomesOutcomes.statuses.canceled')}</span>
			</div>
		);
	}

	function renderPendingStatus() {
		return (
			<div className="status pending">
				<FontAwesomeIcon icon={faSpinner} className="status-icon" />
				<span>{i18n.t('translation:billingModule.incomesOutcomes.statuses.pending')}</span>
			</div>
		);
	}

	function renderPartiallyStatus() {
		return (
			<div className="status neutral">
				<FontAwesomeIcon icon={faClock} className="status-icon" />
				<span>
					{i18n.t('translation:billingModule.incomesOutcomes.statuses.partially')}
				</span>
			</div>
		);
	}

	if (status === TRANSACTION_STATUS.COMPLETED) {
		return renderCompletedStatus();
	}

	if (status === TRANSACTION_STATUS.CANCELED) {
		return renderCanceledStatus();
	}

	if (status === TRANSACTION_STATUS.PENDING) {
		return renderPendingStatus();
	}

	if (status === TRANSACTION_STATUS.PARTIALLY) {
		return renderPartiallyStatus();
	}
};
