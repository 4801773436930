import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import GlobalProductDetails from './GlobalProductDetails';
import GlobalProductLanguagesTable from './GlobalProductLanguagesTable';
import { addGlobalProduct, updateGlobalProduct } from '../../../actions';
import { PAGINATION_TYPES, REDUX_FORM_NAMES } from '../../../utils/constants';

class GlobalProductModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	render() {
		const saveButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.submit(REDUX_FORM_NAMES.GLOBAL_PRODUCT_DETAILS);
					this.props.submit(REDUX_FORM_NAMES.TABLE);
				}}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={this.props.closeModal}
				secondary
			/>
		);

		const modalTitle = this.props.product
			? i18n.t('translation:common.editProduct')
			: i18n.t('translation:common.addProduct');

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={this.props.closeModal}
				/>

				<h2>{modalTitle}</h2>

				<div className="global-product-modal-content">
					<div className="row">
						<div className="col-12 mt-s">
							<GlobalProductDetails initialValues={this.props.initialValues} />
						</div>
					</div>
					<div className="row">
						<div className="col-12 mt-s">
							<GlobalProductLanguagesTable
								product={this.props.product}
								currentPage={this.props.page}
								closeModal={this.props.closeModal}
							/>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-s">
						<div className="buttons">
							{saveButton}
							{cancelButton}
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	const product = state.inventory.currentGlobalProduct;

	return {
		product: product,
		page: state.pagination[PAGINATION_TYPES.GLOBAL_PRODUCTS]
	};
};

export default connect(mapStateToProps, {
	submit,
	addGlobalProduct,
	updateGlobalProduct
})(GlobalProductModal);
