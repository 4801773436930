import React from 'react';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import { getClinicDetails } from '../../../../../../../actions';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';

class BankDetailsSection extends React.Component {
	componentDidMount() {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE &&
			this.props.mode === BILLING_DOCUMENT_MODE.CREATE
		) {
			const clinicId = this.props.clinicId;
			this.props.getClinicDetails(clinicId);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.currentDocument !== this.props.currentDocument &&
			this.props.currentDocument
		) {
			this.fillInitialData();
		}
		if (this.props.currentClinic !== prevProps.currentClinic) {
			const { bankName, bic, iban } = this.props.currentClinic;

			this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.BANK_NAME, bankName);

			this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.BIC, bic);

			this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.IBAN, iban);
		}
	}

	fillInitialData() {
		const { bankName, bic, iban } = this.props.currentDocument;

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.BANK_NAME, bankName);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.BIC, bic);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.IBAN, iban);
	}

	renderSectionHeader() {
		let text =
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE
				? i18n.t('translation:billingModule.invoicing.bankDetailsRecipient')
				: i18n.t('translation:billingModule.invoicing.bankDetailsSupplier');

		return <div className="d-flex align-items-center w-100 custom-table-header">{text}</div>;
	}

	render() {
		return (
			<div className="col-6 i-section">
				{this.renderSectionHeader()}
				<div className="section mt-0 pt-3 pb-0">
					{Fields.billingModule.invoicing.iban({
						additionalLabelClass: 'col-4 pl-0',
						readableInput: isReadableInput(this.props.mode)
					})}
					{Fields.billingModule.invoicing.bic({
						additionalLabelClass: 'col-4 pl-0',
						readableInput: isReadableInput(this.props.mode)
					})}
					{Fields.billingModule.invoicing.bankName({
						additionalLabelClass: 'col-4 pl-0',
						readableInput: isReadableInput(this.props.mode)
					})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentDocument: state.billingDocument.currentDocument,
		currentClinic: state.clinics.currentClinic
	};
};

export default connect(mapStateToProps, { change, getClinicDetails })(BankDetailsSection);
