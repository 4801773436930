import React, { Component, Fragment } from 'react';
import { ORDER_SORT_DIRECTORY, REDUX_FORM_NAMES } from '../../utils/constants';
import i18n from '../../i18n';
import { initialize, reduxForm } from 'redux-form';
import { formUtils } from '../../utils/formUtils';
import { connect } from 'react-redux';
import { isEmptyObject } from '../../utils/appUtils';
import {
	preparePriceForDisplay,
	renderActionButton,
	renderOrderItemCategoryIcon
} from '../../utils/tableUtils';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Table extends Component {
	state = { initialValues: {} };

	componentDidMount() {
		const { initialValues } = this.props;
		this.props.initialize(initialValues);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { initialValues } = this.props;
		if (isEmptyObject(initialValues)) {
			return;
		}
		if (this.props.simpleInitialization) {
			for (let initialValue in initialValues) {
				if (prevProps.initialValues[initialValue] !== initialValues[initialValue]) {
					this.props.initialize(initialValues);
					break;
				}
			}
		} else {
			for (let initialValue in initialValues) {
				if (
					// eslint-disable-next-line no-prototype-builtins
					!this.state.initialValues.hasOwnProperty(initialValue) ||
					this.state.initialValues[initialValue] !== initialValues[initialValue]
				) {
					this.props.initialize({ ...this.state.initialValues, ...initialValues });
					this.setState({
						initialValues: { ...this.state.initialValues, ...initialValues }
					});
					break;
				}
			}
		}
	}

	componentWillUnmount() {
		this.setState({ initialValues: {} });
	}

	render() {
		const tableClassName = this.props.className ? this.props.className : '';

		return (
			<div className={tableClassName}>
				<table className="table">
					<thead>
						<tr>{this.renderHeader()}</tr>
					</thead>
					<tbody>
						{this.renderData()}
						{this.renderNoItemsMessage()}
						{this.renderTotal()}
						{this.renderTotalTime()}
						{this.renderItemsListFooter()}
					</tbody>
				</table>
			</div>
		);
	}

	renderHeader = () => {
		const { columns, sortKeys, currentSort, setCurrentSort, headerLastCellInheritAlign } =
			this.props;

		function renderArray(currentSort, currentColumnSortKey) {
			if (currentSort.sortKey === currentColumnSortKey && currentColumnSortKey) {
				if (currentSort.sortDirection === ORDER_SORT_DIRECTORY.ASC) {
					return <FontAwesomeIcon icon={faArrowUp} className="ml-xs" />;
				} else {
					return <FontAwesomeIcon icon={faArrowDown} className="ml-xs" />;
				}
			}
		}

		return columns.map((column, index) => {
			const onColumnClick = () => {
				if (sortKeys) {
					const sortKey = sortKeys[index];
					if (sortKey) {
						const sortDirection =
							currentSort.sortKey === sortKey
								? currentSort.sortDirection === ORDER_SORT_DIRECTORY.ASC
									? ORDER_SORT_DIRECTORY.DESC
									: ORDER_SORT_DIRECTORY.ASC
								: ORDER_SORT_DIRECTORY.ASC;
						setCurrentSort({ sortKey, sortDirection });
					}
				}
			};
			let tableHeaderStyle = {};
			if (index === columns.length - 1 && this.props.leftAlignLastColumn) {
				tableHeaderStyle = leftAlignStyle;
			}
			if (index === columns.length - 1 && headerLastCellInheritAlign) {
				tableHeaderStyle = { textAlign: 'inherit' };
			}
			const tableColumn = sortKeys && sortKeys[index] ? 'd-inline pointer' : 'd-inline';
			const tooltip = this.props.tooltips ? this.props.tooltips[index] : null;

			const { totalPriceWithoutTax, averageTax, totalPriceWithTax } = this.props;
			const isOrderDetailsTable =
				totalPriceWithoutTax && averageTax && totalPriceWithTax ? true : false;
			const columnWidth = isOrderDetailsTable && index > 6 ? '9%' : 'auto';

			return (
				<Fragment key={index + 'tableColumn'}>
					<th
						key={index + 'h'}
						title={tooltip}
						scope="col"
						style={tableHeaderStyle}
						width={columnWidth}>
						<div className={tableColumn} onClick={onColumnClick}>
							{column}
							{sortKeys ? renderArray(currentSort, sortKeys[index]) : null}
						</div>
					</th>
				</Fragment>
			);
		});
	};

	renderData = () => {
		const { data } = this.props;
		return data?.map((dataRow, index) => {
			return <tr key={index + 'r'}>{this.renderRow(dataRow, index)}</tr>;
		});
	};

	renderRow = (dataRow, rowIndex) => {
		const columnNames = this.props.columns;
		const typeAndMaterialLabel = i18n.t('translation:orders.table.type');
		const { orderItemCategories } = this.props;

		return dataRow.map((data, index) => {
			const tableElementStyle =
				index === 0 ? 'pl-xl-4' : index === dataRow.length - 1 ? 'pr-xl-4' : null;

			const styleValue =
				index === dataRow.length - 1 && this.props.leftAlignLastColumn
					? leftAlignStyle
					: {};
			if (index === 0 && this.props.firstCellNoWrap) {
				styleValue.whiteSpace = 'nowrap';
			}
			if (Array.isArray(data)) {
				return (
					<td className={tableElementStyle} key={index + 'd'} style={styleValue}>
						{renderActionButton(data)}
					</td>
				);
			} else if (columnNames[index] === typeAndMaterialLabel) {
				// If we show the "type and material" column for an order item, we need to render
				// the icon representing the item's category as well
				return (
					<td className={tableElementStyle} key={index + 'd'} style={styleValue}>
						{data}
						<span className="order-item-category-icon">
							{renderOrderItemCategoryIcon(orderItemCategories[rowIndex])}
						</span>
					</td>
				);
			} else {
				return (
					<td className={tableElementStyle} key={index + 'd'} style={styleValue}>
						{data}
					</td>
				);
			}
		});
	};

	renderNoItemsMessage = () => {
		const noItemsMessage = this.props.noItemsMessage
			? this.props.noItemsMessage
			: i18n.t('translation:common.table.noItems');
		const colSpan = this.props.columns.length;

		if (this.props.data?.length === 0) {
			return (
				<tr>
					<td colSpan={colSpan} className="text-center">
						{noItemsMessage}
					</td>
				</tr>
			);
		}
	};

	renderTotal = () => {
		const { amount, amountColumn } = this.props;
		const colSpanText = amountColumn;
		const columnsLeft = this.props.columns.length - colSpanText;

		let valueStyle, titleStyle;

		titleStyle = 'pl-xl-4';

		valueStyle = 'pr-xl-4';

		if (amount && amountColumn) {
			return (
				<tr className="total">
					<td className={titleStyle} colSpan={colSpanText}>
						{i18n.t('translation:common.table.total')}
					</td>
					<td className={valueStyle} colSpan={columnsLeft}>
						{preparePriceForDisplay(amount, this.props.lab)}
					</td>
				</tr>
			);
		}
	};

	renderTotalTime = () => {
		const { totalTime, totalTimeColumn } = this.props;
		const columnsLeft = this.props.columns.length - totalTimeColumn;

		let valueStyle;

		valueStyle = 'pr-xl-4';

		if (totalTime && totalTimeColumn) {
			return (
				<tr className="total">
					<td className={valueStyle} colSpan={totalTimeColumn}></td>
					<td className={valueStyle} colSpan={columnsLeft}>
						{i18n.t('translation:common.table.total')} {totalTime}
					</td>
				</tr>
			);
		}
	};

	renderItemsListFooter = () => {
		if (!this.props.canAccessPaymentData) {
			return null;
		}
		const { totalPriceWithoutTax, averageTax, totalPriceWithTax } = this.props;

		let valueStyle;
		valueStyle = 'pr-xl-4';

		if (totalPriceWithoutTax && averageTax && totalPriceWithTax) {
			return (
				<tr className="total">
					<td className={valueStyle} colSpan={7}>
						{i18n.t('translation:common.table.total')}
					</td>
					<td className={valueStyle} colSpan={1}>
						{totalPriceWithoutTax}
					</td>
					<td className={valueStyle} colSpan={1}>
						{averageTax}
					</td>
					<td className={valueStyle} colSpan={1}></td>
					<td className={valueStyle} colSpan={1}>
						{totalPriceWithTax}
					</td>
					<td className={valueStyle} colSpan={1}></td>
				</tr>
			);
		}
	};
}

const tableForm = reduxForm({
	form: REDUX_FORM_NAMES.TABLE,
	onSubmit: formUtils.remoteSubmits.onTableSubmit
})(Table);

const leftAlignStyle = {
	textAlign: 'left'
};

export default connect(null, { initialize })(tableForm);
