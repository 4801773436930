import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import SubHeader from '../../common/SubHeader';
import { reduxForm } from 'redux-form';
import { Fields } from '../../common/Fields';
import { PAGES_PATHS } from '../../../utils/constants';
import DefaultButton from '../../common/DefaultButton';
import { savePreferences } from '../../../actions';
import navigationUtils from '../../../utils/navigationUtils';

class UserPreferences extends React.Component {
	render = () => {
		if (navigationUtils.getRoute() !== PAGES_PATHS.MY_ACCOUNT) {
			return null;
		}

		return (
			<div className="data-section">
				<SubHeader title={i18n.t('translation:users.preferences.subHeader')} />
				<div className="content mt-s">
					<form onSubmit={this.props.handleSubmit(this.onSavePreferences)}>
						<div className="row p-0">
							<div className="col-12 align-self-center">
								{Fields.commonFields.receiveEmailNotifications({
									className: 'first'
								})}
								{Fields.commonFields.receiveMarketingInformation()}
							</div>
						</div>
						<div className="mt-s">{this.getSaveButton()}</div>
					</form>
				</div>
			</div>
		);
	};

	onSavePreferences = (formValues) => {
		const { receiveEmailNotifications, receiveMarketingInformation } = formValues;
		this.props.savePreferences(this.props.accountId, {
			receiveEmailNotifications,
			receiveMarketingInformation
		});
	};

	getSaveButton = () => {
		return <DefaultButton title={i18n.t('translation:common.buttons.save')} />;
	};
}

const userPreferencesForm = reduxForm({
	form: 'userPreferencesForm',
	enableReinitialize: true
})(UserPreferences);

export default connect(null, { savePreferences })(userPreferencesForm);
