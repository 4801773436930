import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { newPassword } from '../../../actions/loginActions';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../utils/formUtils';
import queryString from 'query-string';

class NewPassword extends Component {
	getEncryptedValueFromQueryString() {
		return queryString.parse(this.props.location.search).value;
	}

	render() {
		return (
			<Fragment>
				<form
					className="authentication-form"
					onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<h2>{i18n.t('translation:auth.forgotPassword')}</h2>
					<hr className="full-width-element" />
					{Fields.commonFields.password()}
					{Fields.commonFields.repeatPassword()}
					<DefaultButton
						onPress={this.onFormSubmit}
						title={i18n.t('translation:common.buttons.send')}
					/>
				</form>
			</Fragment>
		);
	}

	onFormSubmit = (formValues) => {
		this.props.newPassword(formValues.password, this.getEncryptedValueFromQueryString());
	};
}

const mapStateToProps = (state) => {
	return {
		settings: state.settings
	};
};

const newPasswordForm = reduxForm({
	form: 'newPasswordForm',
	validate: formUtils.validateNewPassword
})(NewPassword);

export default connect(mapStateToProps, { newPassword })(newPasswordForm);
