import React, { Fragment } from 'react';
import {
	ORDER_INVOICING_STATUS,
	ORDER_STATUS,
	TASK_STATUS,
	USER_STATUS
} from '../../utils/constants';
import { faBan, faCheck, faClock, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseStatus } from '../../utils/appUtils';

class Status extends React.Component {
	completedStatuses = [
		USER_STATUS.ACTIVE,
		ORDER_STATUS.COMPLETED,
		ORDER_INVOICING_STATUS.FULLY_INVOICED
	];
	activeStatuses = [
		ORDER_STATUS.IN_PROGRESS,
		ORDER_STATUS.ACCEPTED,
		USER_STATUS.INACTIVE,
		ORDER_STATUS.SENT,
		ORDER_STATUS.IN_TESTING,
		ORDER_STATUS.RECEIVED,
		ORDER_STATUS.PRODUCED
	];
	awaitingStatuses = [
		USER_STATUS.WAITING_FOR_APPROVAL,
		ORDER_STATUS.OPENED,
		ORDER_STATUS.MOUNTED_TEMPORARILY,
		ORDER_STATUS.MOUNTED,
		ORDER_STATUS.CHANGES_NOT_ACCEPTED,
		ORDER_STATUS.DRAFT,
		ORDER_STATUS.INFORMATION_REQUIRED,
		ORDER_STATUS.WORK_REQUIRED,
		ORDER_STATUS.ON_HOLD
	];
	negativeStatuses = [
		USER_STATUS.DEACTIVATED,
		ORDER_STATUS.REJECTED,
		ORDER_STATUS.CHANGES_NOT_ACCEPTED,
		TASK_STATUS.CANCELED,
		ORDER_INVOICING_STATUS.NOT_INVOICED
	];
	neutralStatuses = [ORDER_INVOICING_STATUS.PARTIALLY_INVOICED];

	render() {
		if (!this.props.status) {
			return <Fragment />;
		}
		return (
			<div className={this.getClassName()}>
				{!this.props.withoutIcon && (
					<FontAwesomeIcon icon={this.getIcon()} className="status-icon" />
				)}
				<span>{parseStatus(this.props.status)}</span>
			</div>
		);
	}

	getClassName = () => {
		let className = this.props.withoutIcon ? 'status-without-icon' : 'status';
		if (this.completedStatuses.includes(this.props.status)) {
			className += ' completed';
		} else if (this.activeStatuses.includes(this.props.status)) {
			className += ' active';
		} else if (this.awaitingStatuses.includes(this.props.status)) {
			className += ' awaiting';
		} else if (this.negativeStatuses.includes(this.props.status)) {
			className += ' negative';
		} else if (this.neutralStatuses.includes(this.props.status)) {
			className += ' neutral';
		}
		if (this.props.calendarStatus) {
			className += ' calendar-status';
		}
		return className;
	};

	getIcon = () => {
		let icon;
		const { status } = this.props;
		if (this.completedStatuses.includes(status)) {
			icon = faCheck;
		} else if (this.activeStatuses.includes(status)) {
			icon = faSpinner;
		} else if (this.awaitingStatuses.includes(status)) {
			icon = faClock;
		} else if (this.negativeStatuses.includes(status)) {
			icon =
				status === USER_STATUS.DEACTIVATED || status === USER_STATUS.INACTIVE
					? faBan
					: faTrash;
		}
		return icon;
	};
}

export default Status;
