import { GET_MODULE } from '../actions/types';

const initState = [];

const moduleReducer = (state = [...initState], action) => {
	if (action.type === GET_MODULE) {
		state = action.payload;
		return [...state];
	}

	return state;
};

export default moduleReducer;
