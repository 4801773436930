import React, { Fragment } from 'react';
import i18n from '../../../../../i18n';
import { connect } from 'react-redux';
import BillingDocumentModal, {
	BILLING_DOCUMENT_MODE
} from '../../invoicing/billingDocumentModal/BillingDocumentModal';
import {
	getLabDetails,
	getBillingSettings,
	clearPreCreateModalDocumentReducer,
	showMessageModal,
	hideMessageModal
} from '../../../../../actions';
import {
	BILLING_DOCUMENT_TYPES,
	INVOICE_TYPES,
	INVOICING_FILTER_INPUT_NAME,
	ORDER_INVOICING_STATUS
} from '../../../../../utils/constants';
import CreateInvoiceModalOrdersTable from './CreateInvoiceModalOrdersTable';
import CreateInvoiceModalOrdersFilters from './CreateInvoiceModalOrdersFilters';
import DefaultButton from '../../../../common/DefaultButton';
import navigationUtils from '../../../../../utils/navigationUtils';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEmptyObject } from '../../../../../utils/appUtils';
import { getFormValues, reset, change } from 'redux-form';

class CreateInvoiceModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDocumentModalOpened: false,
			invoiceType: undefined,
			selectedOrders: [],
			selectedOrdersIds: [],
			clinicId: null,
			filters: {},
			sort: {}
		};

		this.props.getLabDetails(this.props.account.labId);
		this.props.getBillingSettings(this.props.account.labId);
	}

	componentDidMount() {
		if (!isEmptyObject(this.props.previousScreenFilters)) {
			this.setState((prevState) => {
				return {
					...prevState,
					filters: {
						...prevState.filters,
						...this.props.previousScreenFilters
					}
				};
			});
		}
	}

	componentWillUnmount() {
		this.props.clearPreCreateModalDocumentReducer();
	}

	render() {
		const modalTitle = i18n.t('translation:billingModule.common.setDetailsModalTitle');

		return (
			<Fragment>
				<Modal
					className="custom-modal full-page-modal pre-create-modal"
					overlayClassName="custom-overlay"
					isOpen={this.props.isModalOpen}>
					<div className="fixed-top p-3">
						<FontAwesomeIcon
							className="close-icon"
							icon={faTimes}
							onClick={() => {
								this.closeModal();
							}}
						/>
						<h2>{modalTitle}</h2>
					</div>
					<div className="modal-content">
						<CreateInvoiceModalOrdersFilters
							setFilters={this.setFilters}
							filters={this.props.previousScreenFilters}
							onSelectClient={this.onSelectClient}
							onSelectInvoiceType={this.onSelectInvoiceType}
						/>
						{this.renderOrdersTable()}
						<div className="buttons task-buttons">
							{this.renderContinueButton()}
							{this.renderCloseButton()}
						</div>
					</div>
					<div className="modal-error-message w-100 p-3 text-center">
						{this.state.serverErrorMessage}
					</div>
				</Modal>
				{this.renderBillingDocumentModal()}
			</Fragment>
		);
	}

	renderOrdersTable = () => {
		if (
			this.state.clinicId &&
			this.props.createInvoiceFormValues?.invoiceType === INVOICE_TYPES.LINKED_TO_ORDERS
		) {
			return (
				<CreateInvoiceModalOrdersTable
					filters={this.state.filters}
					onSort={this.onSort}
					onSelectOrder={this.onSelectOrder}
					selectedOrdersIds={this.state.selectedOrdersIds}
					invoiceType={this.state.invoiceType}
				/>
			);
		}

		return null;
	};

	renderBillingDocumentModal = () => {
		if (this.state.isDocumentModalOpened) {
			return (
				<BillingDocumentModal
					mode={BILLING_DOCUMENT_MODE.CREATE}
					isModalOpen={this.state.isDocumentModalOpened}
					onModalClose={this.closeModal}
					documentType={this.state.invoiceType}
					selectedOrdersIds={this.state.selectedOrdersIds}
					clinicId={this.state.clinicId}
					previousScreenFilters={this.props.previousScreenFilters}
					onDocumentChange={this.props.onDocumentChange}
				/>
			);
		}
		return null;
	};

	renderContinueButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:billingModule.common.buttons.continue')}
				key={i18n.t('translation:billingModule.common.buttons.continue')}
				type="submit"
				onClick={() => this.onContinueButtonClick()}
			/>
		);
	};

	renderCloseButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				type="button"
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);
	};

	setFilters = (filters) => {
		this.setState((prevState) => {
			return {
				...prevState,
				filters: {
					...filters
				}
			};
		});
	};

	onSort = (sort) => {
		this.setState((prevState) => {
			return {
				...prevState,
				filters: {
					...prevState.filters
				},
				sort: {
					...prevState.sort,
					...sort
				}
			};
		});
	};

	onSelectOrder = (order) => {
		const selectedOrders = this.state.selectedOrders;

		const isAlreadySelected = selectedOrders.some(
			(selectedOrder) => selectedOrder.labOrderId === order.labOrderId
		);

		if (isAlreadySelected) {
			const updatedOrders = selectedOrders.filter(
				(selectedOrder) => selectedOrder.labOrderId !== order.labOrderId
			);
			this.setState({ selectedOrders: updatedOrders });

			let selectedOrdersIds = [];
			updatedOrders.forEach((order) => selectedOrdersIds.push(order.labOrderId));
			this.setState({ selectedOrdersIds });
		} else {
			selectedOrders.push(order);
			this.setState({ selectedOrders });

			let selectedOrdersIds = [];
			selectedOrders.forEach((order) => selectedOrdersIds.push(order.id));
			this.setState({ selectedOrdersIds });
		}
	};

	onSelectClient = (clinicId, clientName) => {
		this.props.change(
			'createInvoiceSetDetailsFiltersForm',
			INVOICING_FILTER_INPUT_NAME.CLIENT_NAME,
			clientName
		);
		this.props.change(
			'createInvoiceSetDetailsFiltersForm',
			INVOICING_FILTER_INPUT_NAME.CLIENT_ID,
			clinicId
		);

		this.resetSelectedOrders();
		this.setState((prevState) => {
			return {
				...prevState,
				filters: {
					...prevState.filters,
					clientName
				},
				clinicId
			};
		});

		this.props.reset('tableForm');
	};

	onSelectInvoiceType = (selectedInvoiceType) => {
		const invoiceType =
			selectedInvoiceType === INVOICE_TYPES.FREE
				? BILLING_DOCUMENT_TYPES.FREE_INVOICE
				: BILLING_DOCUMENT_TYPES.ORDER_INVOICE;

		this.setState((prevState) => {
			return {
				...prevState,
				filters: {
					...prevState.filters
				},
				invoiceType
			};
		});
	};

	onContinueButtonClick = () => {
		const { country, initialDocumentNumber } = this.props.billingSettings;
		const selectedOrders = this.state.selectedOrders;

		const goToSettingsButton = (
			<DefaultButton
				title={i18n.t('translation:billingModule.invoicing.buttons.goToSettings')}
				onClick={() => {
					this.props.hideMessageModal();
					navigationUtils.navigate('/finance/settings');
				}}
				primary
				extraWide
			/>
		);

		if (!this.state.clinicId && !this.props.createInvoiceFormValues?.invoiceType) {
			this.props.showMessageModal(
				i18n.t('translation:common.warning'),
				i18n.t('translation:billingModule.common.warnings.selectClientAndInvoiceType'),
				[],
				false,
				true
			);
			return;
		}

		if (!this.state.clinicId) {
			this.props.showMessageModal(
				i18n.t('translation:common.warning'),
				i18n.t('translation:billingModule.common.warnings.selectClient'),
				[],
				false,
				true
			);
			return;
		}

		if (this.state.clinicId && !this.props.createInvoiceFormValues?.invoiceType) {
			this.props.showMessageModal(
				i18n.t('translation:common.warning'),
				i18n.t('translation:billingModule.common.warnings.selectInvoiceType'),
				[],
				false,
				true
			);
			return;
		}

		if (!country || !initialDocumentNumber) {
			this.props.showMessageModal(
				i18n.t('translation:common.warning'),
				i18n.t('translation:billingModule.invoicing.warnings.initialDocNumberNotSet'),
				[goToSettingsButton],
				false,
				true
			);
			return;
		}

		if (!this.props.createInvoiceFormValues?.invoiceType) {
			this.props.showMessageModal(
				i18n.t('translation:common.errors.error'),
				i18n.t('translation:billingModule.invoicing.errors.noSelectedInvoiceType'),
				[],
				false,
				true
			);
			return;
		}

		if (this.props.createInvoiceFormValues?.invoiceType === INVOICE_TYPES.FREE) {
			if (selectedOrders.length > 0) {
				this.props.showMessageModal(
					i18n.t('translation:common.errors.error'),
					i18n.t(
						'translation:billingModule.invoicing.errors.selectedOrdersOnFreeInvoice'
					),
					[],
					false,
					true
				);
				return;
			}

			this.setState((prevState) => {
				return {
					...prevState,
					isDocumentModalOpened: true,
					invoiceType: BILLING_DOCUMENT_TYPES.FREE_INVOICE,
					clinicId: this.state.clinicId || undefined,
					filters: {
						...prevState.filters
					}
				};
			});
		}

		if (this.props.createInvoiceFormValues?.invoiceType === INVOICE_TYPES.LINKED_TO_ORDERS) {
			if (selectedOrders.length === 0) {
				this.props.showMessageModal(
					i18n.t('translation:common.errors.error'),
					i18n.t('translation:billingModule.invoicing.errors.noSelectedOrder'),
					[],
					false,
					true
				);
				return;
			}

			if (this.hasInvoicedOrders(selectedOrders)) {
				this.props.showMessageModal(
					i18n.t('translation:common.errors.error'),
					i18n.t('translation:billingModule.invoicing.errors.hasInvoicedOrders'),
					[],
					false,
					true
				);
				return;
			}

			this.setState((prevState) => {
				return {
					...prevState,
					isDocumentModalOpened: true,
					invoiceType: BILLING_DOCUMENT_TYPES.ORDER_INVOICE,
					clinicId: this.state.clinicId,
					filters: {
						...prevState.filters
					}
				};
			});
		}
	};

	closeModal = () => {
		this.props.onModalClose();
	};

	hasInvoicedOrders = (orders) => {
		return orders.some((order) => order.status === ORDER_INVOICING_STATUS.FULLY_INVOICED);
	};

	resetSelectedOrders = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				selectedOrders: [],
				selectedOrdersIds: [],
				filters: {
					...prevState.filters
				}
			};
		});
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		billingSettings: state.billingSettings,
		createInvoiceFormValues: getFormValues('createInvoiceSetDetailsFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	getBillingSettings,
	clearPreCreateModalDocumentReducer,
	showMessageModal,
	hideMessageModal,
	reset,
	change
})(CreateInvoiceModal);
