import React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import navigationUtils from '../../utils/navigationUtils';

class BackButton extends React.Component {
	render() {
		return (
			<FontAwesomeIcon
				icon={faChevronLeft}
				className="back-button"
				onClick={() => {
					navigationUtils.goBack();
				}}
			/>
		);
	}
}

export default BackButton;
