import React from 'react';
import SubHeader from '../../../../../../common/SubHeader';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import {
	changeItemAmountNoTax,
	changeItemTaxPercentTaxName,
	changeItemTotalPriceWithTax,
	changeItemTotalTax,
	formUtils,
	getDocumentTaxExclude,
	getItemAmountNoTax,
	getItemTotalPriceWithTax
} from '../../../../../../../utils/formUtils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TableButton from '../../../../../../common/TableButton';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { isEmptyObject } from '../../../../../../../utils/appUtils';
import { parseInvoicingCurrency } from '../../../../../../../utils/tableUtils';

class AddProduct extends React.Component {
	state = {
		showErrors: false
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentItemID !== this.props.currentItemID) {
			changeItemAmountNoTax(this.props.change, this.props.currentItemID, 0);
			changeItemTotalPriceWithTax(this.props.change, this.props.currentItemID, 0);

			if (getDocumentTaxExclude(this.props.formValues)) {
				this.excludeCurrentItemTax();
			}
		}

		if (
			getDocumentTaxExclude(prevProps.formValues) !==
			getDocumentTaxExclude(this.props.formValues)
		) {
			this.syncItemTaxWithTaxExclude();
		}
	}

	syncItemTaxWithTaxExclude() {
		if (getDocumentTaxExclude(this.props.formValues)) {
			//in case there is an item already with total price and tax in add section
			const currentItemAmount = getItemAmountNoTax(
				this.props.formValues,
				this.props.currentItemID
			);

			changeItemTotalPriceWithTax(
				this.props.change,
				this.props.currentItemID,
				currentItemAmount
			);
			changeItemTotalTax(this.props.change, this.props.currentItemID, 0);

			this.excludeCurrentItemTax();
		} else {
			changeItemTaxPercentTaxName(this.props.change, this.props.currentItemID, ' _ ');
		}
	}

	excludeCurrentItemTax() {
		const zeroTaxOption = formUtils.options.getZeroTaxOption(this.props.taxGroups);
		changeItemTaxPercentTaxName(
			this.props.change,
			this.props.currentItemID,
			zeroTaxOption.value
		);
	}

	render() {
		return (
			<div className={'container col-lg-12 col-md-12 col-sm-12 p-0'}>
				<SubHeader
					title={i18n.t('translation:billingModule.invoicing.addProductServiceSubheader')}
				/>
				<div className="col-12 p-0">
					<div className={'section row flex-nowrap mr-0 ml-0'}>
						{this.renderInvoiceProductNameField()}
						{this.renderInvoiceProductUnitField()}
						{this.renderInvoiceProductQuantityField()}
						{this.renderInvoiceProductPriceField()}
						{this.renderInvoiceProductAmountField()}
						{this.renderInvoiceProductTaxGroupsField()}
						{this.renderInvoiceProductTotalPrice()}
						{this.renderSubmitButton()}
					</div>
				</div>
			</div>
		);
	}

	renderSubmitButton() {
		return (
			<div
				className="d-flex align-items-center justify-content-end"
				style={{ width: '4%', paddingTop: '14px' }}>
				<TableButton
					icon={faPlus}
					onPress={this.onSubmit}
					tooltip={i18n.t('translation:billingModule.common.buttons.tooltips.add')}
				/>
			</div>
		);
	}

	renderInvoiceProductTotalPrice() {
		return (
			<div style={{ width: '15%' }} className={'cell'}>
				<label>
					{i18n.t('translation:billingModule.invoicing.table.common.totalPrice')}
				</label>
				<div className="readable-input-row-padding">
					{parseInvoicingCurrency(
						getItemTotalPriceWithTax(this.props.formValues, this.props.currentItemID),
						this.props.localizedCurrencyAbbreviation,
						this.props.currencySymbol
					)}
				</div>
			</div>
		);
	}

	renderInvoiceProductTaxGroupsField() {
		return (
			<div style={{ width: '9%' }} className={'cell'}>
				{Fields.billingModule.invoicing.invoiceProductTaxGroups(this.props.currentItemID, {
					options: formUtils.options.getProductTaxGroupsOptions(this.props.taxGroups),
					label: i18n.t('translation:billingModule.invoicing.table.common.tax'),
					onChange: (e) =>
						this.props.onItemTaxChange(e.target.value, this.props.currentItemID),
					validate:
						this.state.showErrors === true
							? (value) => {
									if (value && value.trim() === '_') {
										return i18n.t('translation:common.errors.requiredField');
									}
									return formUtils.validateRequiredField(value);
							  }
							: undefined,
					errorMessage: true,
					readableInput: getDocumentTaxExclude(this.props.formValues)
				})}
			</div>
		);
	}

	renderInvoiceProductAmountField() {
		return (
			<div style={{ width: '14%' }} className={'cell'}>
				<label>{i18n.t('translation:billingModule.common.taxBase')}</label>
				<div className="readable-input-row-padding">
					{parseInvoicingCurrency(
						getItemAmountNoTax(this.props.formValues, this.props.currentItemID),
						this.props.localizedCurrencyAbbreviation,
						this.props.currencySymbol
					)}
				</div>
			</div>
		);
	}

	renderInvoiceProductPriceField() {
		return (
			<div style={{ width: '12%' }} className={'cell'}>
				<label>
					{i18n.t('translation:billingModule.invoicing.table.common.unitPrice')}
				</label>
				{Fields.billingModule.invoicing.invoiceProductPrice(this.props.currentItemID, {
					localizedCurrencyAbbreviation: this.props.localizedCurrencyAbbreviation,
					currencySymbol: this.props.currencySymbol,
					onChange: (e) =>
						this.props.onItemUnitPriceChange(e.target.value, this.props.currentItemID),
					validate:
						this.state.showErrors === true ? formUtils.validateRequiredField : undefined
				})}
			</div>
		);
	}

	renderInvoiceProductQuantityField() {
		return (
			<div style={{ width: '8%' }} className={'cell'}>
				{Fields.billingModule.invoicing.invoiceProductQuantity(this.props.currentItemID, {
					label: i18n.t('translation:billingModule.invoicing.table.common.quantity'),
					onChange: (e) =>
						this.props.onItemQuantityChange(e.target.value, this.props.currentItemID),
					validate:
						this.state.showErrors === true
							? formUtils.validateRequiredField
							: undefined,
					errorMessage: true
				})}
			</div>
		);
	}

	renderInvoiceProductUnitField() {
		return (
			<div style={{ width: '9%' }} className={'cell'}>
				{Fields.billingModule.invoicing.invoiceProductUnit(this.props.currentItemID, {
					options: formUtils.options.getProductUnitOptions(),
					label: i18n.t('translation:billingModule.invoicing.table.common.unit'),
					validate:
						this.state.showErrors === true
							? formUtils.validateRequiredField
							: undefined,
					errorMessage: true
				})}
			</div>
		);
	}

	renderInvoiceProductNameField() {
		return (
			<div className={'cell'} style={{ width: '29%' }}>
				{Fields.billingModule.invoicing.invoiceProductName(this.props.currentItemID, {
					validate:
						this.state.showErrors === true
							? formUtils.validateRequiredField
							: undefined,
					errorMessage: true,
					maxLength: 250
				})}
			</div>
		);
	}

	onSubmit = () => {
		let errors = {};

		errors = formUtils.validateFreeInvoiceAddProduct(
			this.props.formValues,
			this.props.currentItemID
		);

		if (!isEmptyObject(errors)) {
			const erroredFields = Object.keys(errors);
			this.setState({ showErrors: true });
			this.props.touch(...erroredFields);
		} else {
			if (this.state.showErrors === true) {
				this.setState({ showErrors: undefined });
			}
			this.props.onSubmit();
		}
	};
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		account: state.account,
		taxGroups: state.billingSettings.taxGroups
	};
};

export default connect(mapStateToProps, { change })(AddProduct);
