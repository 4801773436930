import { getAxiosWithToken } from '../../utils/webApi';
import {
	CLEAR_TASKS_REDUCER,
	GET_AVAILABLE_STAFF,
	GET_CURRENT_TEMPLATE,
	GET_CURRENT_TEMPLATE_STATUS,
	GET_CURRENT_TEMPLATE_TASKS,
	GET_PRODUCT_TEMPLATES
} from '../types';
import { deleteLocalTemplates } from './templateActions';
import { isEmptyObject, showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';
import { ERROR_CODES } from '../../utils/constants';
import store from '../../store';

export const getOrderItemTasks = (orderId, orderItemId) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/order/${orderId}/item/${orderItemId}/task`);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CURRENT_TEMPLATE,
			payload: returnedData.data.template
		});
		dispatch({
			type: GET_CURRENT_TEMPLATE_TASKS,
			payload: returnedData.data.tasks
		});
		dispatch({
			type: GET_CURRENT_TEMPLATE_STATUS,
			payload: returnedData.data.isExcludedFromPlanning
		});
	}

	// return returnedData;
};

export const updateOrderItemTasks =
	(
		orderId,
		orderItemId,
		data,
		setErrorMessage,
		closeModal,
		forceUpdate,
		updateTemplate,
		newTemplateData,
		productId
	) =>
	async (dispatch, getState) => {
		const response = await getAxiosWithToken(false).put(
			`/order/${orderId}/item/${orderItemId}/task?forceUpdate=${forceUpdate}`,
			data
		);
		let returnedData = response.data;
		if (returnedData.success) {
			if (updateTemplate) {
				updateTemplate(returnedData.data);
			}
			closeModal();
			showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedSettings'));
		} else {
			if (returnedData.code == ERROR_CODES.TASK_CONFLICTS) {
				const userMessage = returnedData.userMessage.split(';').slice(0, -1);
				setErrorMessage(userMessage, true);
			} else {
				setErrorMessage(returnedData.userMessage);
			}

			// Delete new created template if there is conflict or server error
			if (!isEmptyObject(newTemplateData)) {
				const templateId = newTemplateData.id;
				const labId = getState().account.labId;
				store.dispatch(deleteLocalTemplates(templateId, labId, productId, true));
			}
		}
		return returnedData;
	};

export const getProductTemplates = (labId, productId) => async (dispatch) => {
	if (labId == null || productId == null) {
		return;
	}

	const response = await getAxiosWithToken().get(
		`/product/lab-product-templates/${labId}/${productId}`
	);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_PRODUCT_TEMPLATES,
			payload: returnedData.data
		});
	}

	// return returnedData;
};

export const getAvailableLabStaff = (labId) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/lab/${labId}/get-staff`);
	let returnedData = response.data;
	if (returnedData.success) {
		let filteredData = [];

		returnedData.data.map(function (item, key) {
			if (item.status == 'ACTIVE') {
				const filteredItem = {
					id: item.id,
					firstName: item.firstName,
					lastName: item.lastName
				};
				filteredData.push(filteredItem);
			}
		});

		dispatch({
			type: GET_AVAILABLE_STAFF,
			payload: filteredData
		});
	}
};

export const calculateTaskTime =
	(labId, isStartTime, data, callback, setError = () => {}) =>
	async (dispatch) => {
		const response = await getAxiosWithToken(false).post(
			`/task/calculate-time?labId=${labId}`,
			data
		);
		let returnedData = response.data;

		if (returnedData.success) {
			callback(returnedData.data);
		} else {
			setError(returnedData.userMessage);
		}
	};

export const clearTaskReducers = () => async (dispatch) => {
	dispatch({ type: CLEAR_TASKS_REDUCER });
};

export const addNewTemplate =
	(labId, productId, data, callback, setErrorMessage) => async (dispatch) => {
		const response = await getAxiosWithToken(false).post(
			`/product/lab-product-template/${labId}/${productId}`,
			data
		);
		let returnedData = response.data;

		if (returnedData.success) {
			if (callback) {
				callback(returnedData.data);
			}
		} else {
			setErrorMessage(returnedData.userMessage, false, true);
		}
	};
