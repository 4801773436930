import React, { Fragment } from 'react';
import i18n from '../../../../i18n';
import { Fields } from '../../../common/Fields';
import TableButton from '../../../common/TableButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../../utils/formUtils';
import moment from '../../../../utils/moment';

class AddTasksForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startTime: null,
			endTime: null,
			staff: [],
			isStaffLoaded: false
		};
	}

	componentDidMount() {
		let assigneesDataProps = {};
		assigneesDataProps['taskHasDuration'] = true;
		assigneesDataProps['endTime'] = null;
		assigneesDataProps['startTime'] = null;

		this.props
			.listPossibleAssignees(assigneesDataProps, this.props.setServerError)
			.then((response) => {
				if (response) {
					this.setState({ staff: this.props.refineStaff(response), isStaffLoaded: true });
				}
			});
	}

	render() {
		if (this.props.isExcludedFromPlanning) {
			return <Fragment />;
		}

		const readOnlyClass = this.props.readOnly ? ' read-only-field' : '';

		return (
			<Fragment>
				<label className="blue-label mt-s">
					{i18n.t('translation:taskManager.orders.addTasks')}
				</label>
				<form
					onSubmit={this.props.handleSubmit(this.props.onSubmit)}
					className="add-tasks-form">
					<div className="add-tasks-container">
						<div className="table-tasks">
							<table className="table">
								<tbody>
									<tr>
										<td>
											{Fields.templateFields.nameLocal({
												className: 'first task-td w-100' + readOnlyClass,
												id: 0
											})}
										</td>
										<td>
											{Fields.workingHoursFields.startDateTime({
												className: 'first task-td' + readOnlyClass,
												id: 0,
												onChange: (value) =>
													this.onChangeTimeField(value, true)
											})}
										</td>
										<td>
											{Fields.workingHoursFields.endDateTime({
												className: 'first task-td' + readOnlyClass,
												id: 0,
												onChange: (value) =>
													this.onChangeTimeField(value, false)
											})}
										</td>
										<td>
											{Fields.commonFields.availableEmployees({
												selectionLimit: 1,
												options: formUtils.options.availableEmployees(
													this.state.staff
												),
												className: 'first assignees-select' + readOnlyClass,
												id: 0
											})}
										</td>
										<td>
											<div className="table-buttons-container">
												<TableButton
													icon={faPlus}
													onPress={this.props.handleSubmit(
														this.props.onSubmit
													)}
													className={'mt-3 tasks-add-btn' + readOnlyClass}
													tooltip={i18n.t(
														'translation:taskManager.common.buttons.tooltips.add'
													)}
												/>
											</div>
										</td>
										<td className="pr-xl-4">
											{Fields.tableFields.generalNote({
												className: 'td-notes' + readOnlyClass,
												id: 0
											})}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<br />
				</form>
			</Fragment>
		);
	}

	onChangeTimeField = (value, isStartTime) => {
		if (isStartTime) {
			this.setState({ startTime: moment(value).format('YYYY-MM-DD HH:mm') });
		} else {
			this.setState({ endTime: moment(value).format('YYYY-MM-DD HH:mm') });
		}

		setTimeout(() => {
			let assigneesDataProps = {};
			assigneesDataProps['taskHasDuration'] = false;
			assigneesDataProps['endTime'] = this.state.endTime;
			assigneesDataProps['startTime'] = this.state.startTime;

			this.props
				.listPossibleAssignees(assigneesDataProps, this.props.setServerError)
				.then((response) => {
					if (response) {
						this.setState({ staff: this.props.refineStaff(response) });
					}
				});
		}, 100);
	};
}

const addTasksForm = reduxForm({ form: 'addTasksForm', validate: formUtils.validateAddTask })(
	AddTasksForm
);

export default addTasksForm;
