import { GET_SHIFTS } from '../../actions/types';

const initState = [];

const shiftReducer = (state = [...initState], action) => {
	if (action.type === GET_SHIFTS) {
		state = action.payload;
		return [...state];
	}

	return state;
};

export default shiftReducer;
