import React, { Fragment } from 'react';
import ReactPagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { getSettingFromServer } from '../../actions';
import { SERVER_SETTING_NAMES } from '../../utils/constants';

class Pagination extends React.Component {
	handlePageChange = (pageNumber) => {
		this.props.setCurrentPage(pageNumber);
	};

	componentDidMount = () => {
		this.props.getSettingFromServer(SERVER_SETTING_NAMES.UI_DEFAULT_TABLE_PAGE_SIZE);
	};

	render() {
		const totalElements = this.props.total;
		if (!totalElements || totalElements === 0) {
			return <Fragment />;
		}

		const defaultTablePageSizeAsInt = parseInt(
			this.props.serverSettings[SERVER_SETTING_NAMES.UI_DEFAULT_TABLE_PAGE_SIZE]
		);
		const currentPage = this.props.currentPage ? this.props.currentPage : 1;
		return (
			<div className="text-center justify-content-center d-flex pagination-container row ">
				<ReactPagination
					activePage={currentPage}
					itemsCountPerPage={defaultTablePageSizeAsInt}
					totalItemsCount={totalElements}
					pageRangeDisplayed={5}
					onChange={this.handlePageChange}
					activeClass={'page-item active'}
					activeLinkClass={'page-link'}
					itemClass={'page-item'}
					linkClass={'page-link'}
					disabledClass={'page-item disabled'}
					innerClass={'pagination col-6 pr-0 justify-content-center'}
					prevPageText={'<'}
					nextPageText={'>'}
					firstPageText={'<<'}
					lastPageText={'>>'}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		serverSettings: state.serverSettings
	};
};

export default connect(mapStateToProps, { getSettingFromServer })(Pagination);
