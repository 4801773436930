import React from 'react';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import {
	changeDocumentNotes,
	changeDocumentTaxExclude,
	changeDocumentVerbally,
	getDocumentDiscountWithTax,
	getDocumentTaxExclude,
	getDocumentTotalNoTax,
	getDocumentTotalTax,
	getDocumentTotalWithTax
} from '../../../../../../../utils/formUtils';
import { parseInvoicingCurrency } from '../../../../../../../utils/tableUtils';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import { hideMessageModal, showMessageModal } from '../../../../../../../actions';
import { getSelectedTaxType } from '../../../../../../../utils/appUtils';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import DefaultButton from '../../../../../../common/DefaultButton';
import { subtract } from '../../utils/documentUtils';

class InvoiceSummarySection extends React.Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { currentDocument } = this.props;

		if (prevProps.currentDocument !== currentDocument && currentDocument) {
			this.fillInitialData(currentDocument);
		}
	}

	fillInitialData(currentDocument) {
		const { totalInText, notes } = currentDocument;
		changeDocumentVerbally(this.props.change, totalInText);
		changeDocumentNotes(this.props.change, notes);
	}

	renderTaxBase() {
		const documentTotalNoTax = parseInvoicingCurrency(
			getDocumentTotalNoTax(this.props.formValues, true),
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);

		return documentTotalNoTax;
	}

	getTaxExcludeReadable() {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			return true;
		}

		if (this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return true;
		}

		return false;
	}

	render() {
		const readOnly = isReadableInput(this.props.mode);

		return (
			<div className="col-6 i-section">
				{this.renderHeader()}
				<div className="section mt-0 pt-3 pb-3">
					{this.renderTaxbase()}
					{this.renderTotalTax()}
					{this.renderAmount()}
					{this.renderDiscount()}
					{this.renderTotal()}
					{this.renderVerbally(readOnly)}
					{this.renderNotes(readOnly)}
					{this.renderExcludeTax()}
					{this.renderTaxExcludeReason(readOnly)}
				</div>
			</div>
		);
	}

	onExcludeTaxChange() {
		const currentTaxExcluded = getDocumentTaxExclude(this.props.formValues);
		if (!currentTaxExcluded) {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						this.props.hideMessageModal();
						changeDocumentTaxExclude(this.props.change, !currentTaxExcluded);
					}}
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => this.props.hideMessageModal()}
					secondary
				/>
			);
			this.props.showMessageModal(
				i18n.t('translation:common.warning'),
				i18n.t('translation:billingModule.invoicing.warnings.excludeTax'),
				[yesButton, noButton],
				false,
				true
			);
		} else {
			changeDocumentTaxExclude(this.props.change, false);
		}
	}

	renderExcludeTax() {
		return (
			<div className="d-flex align-items-center custom-table-row">
				<label className="col-4 pl-0">
					{i18n.t('translation:billingModule.invoicing.excludeTax')}
				</label>
				{Fields.billingModule.invoicing.excludeTax({
					noLabel: true,
					onChange: () => this.onExcludeTaxChange(),
					readableInput: this.getTaxExcludeReadable(),
					checked: this.props.formValues[INVOICE_FIELD_NAME.EXCLUDE_TAX]
				})}
			</div>
		);
	}

	renderVerbally(readOnly) {
		return Fields.billingModule.invoicing.verbally({
			additionalLabelClass: 'col-4 pl-0',
			readableInput: readOnly
		});
	}

	renderNotes(readOnly) {
		return Fields.billingModule.invoicing.notes({
			additionalLabelClass: 'col-4 pl-0',
			readableInput: readOnly
		});
	}

	renderTotalTax() {
		return (
			<div className="d-flex align-items-center custom-table-row">
				<label className="col-4 pl-0">
					{i18n.t('translation:billingModule.invoicing.calculatedVat', {
						taxType: getSelectedTaxType(this.props.billingSettings.taxType)
					})}
				</label>
				<span>
					{parseInvoicingCurrency(
						getDocumentTotalTax(this.props.formValues),
						this.props.localizedCurrencyAbbreviation,
						this.props.currencySymbol
					)}
				</span>
			</div>
		);
	}

	renderTaxbase() {
		return (
			<div className="d-flex align-items-center custom-table-row">
				<label className="col-4 pl-0">
					{i18n.t('translation:billingModule.invoicing.taxBase')}
				</label>
				<span>{this.renderTaxBase()}</span>
			</div>
		);
	}

	renderHeader() {
		return (
			<div className="d-flex align-items-center w-100 custom-table-header">
				{i18n.t('translation:billingModule.invoicing.invoiceSummary')}
			</div>
		);
	}

	renderTaxExcludeReason() {
		if (getDocumentTaxExclude(this.props.formValues)) {
			return Fields.billingModule.invoicing.reasonForTaxExclude({
				additionalLabelClass: 'col-4 pl-0',
				readableInput: this.getTaxExcludeReadable()
			});
		}

		return null;
	}

	renderTotal() {
		let label = i18n.t('translation:billingModule.invoicing.finalPaymentAmount');

		if (this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			label = i18n.t(
				'translation:billingModule.invoicing.table.creditNote.creditNoteDocumentTotal'
			);
		}

		if (this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE) {
			label = i18n.t(
				'translation:billingModule.invoicing.table.creditNote.debitNoteDocumentTotal'
			);
		}

		return (
			<div className="d-flex align-items-center custom-table-row">
				<label className="col-4 pl-0">{label}</label>
				<span>{this.getTotalAmount()}</span>
			</div>
		);
	}

	renderAmount() {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			return null;
		}
		return (
			<div className="d-flex align-items-center custom-table-row">
				<label className="col-4 pl-0">
					{i18n.t('translation:billingModule.invoicing.paymentAmount')}
				</label>
				<div>
					{parseInvoicingCurrency(
						getDocumentTotalWithTax(this.props.formValues),
						this.props.localizedCurrencyAbbreviation,
						this.props.currencySymbol
					)}
				</div>
			</div>
		);
	}

	renderDiscount() {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			return null;
		}

		return (
			<div className="d-flex align-items-center custom-table-row">
				<label className="col-4 pl-0">
					{i18n.t('translation:billingModule.invoicing.discountIncludedVat', {
						taxType: getSelectedTaxType(this.props.billingSettings.taxType)
					})}
				</label>
				<span>
					{parseInvoicingCurrency(
						getDocumentDiscountWithTax(this.props.formValues),
						this.props.localizedCurrencyAbbreviation,
						this.props.currencySymbol
					)}
				</span>
			</div>
		);
	}

	getTotalAmount = () => {
		const invoiceTotal = this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX];

		const discountAmount = this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_WITH_TAX];

		let totalAmount = parseInvoicingCurrency(
			invoiceTotal,
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);

		if (discountAmount && invoiceTotal) {
			totalAmount = parseInvoicingCurrency(
				subtract(invoiceTotal, discountAmount),
				this.props.localizedCurrencyAbbreviation,
				this.props.currencySymbol
			);
		}

		return totalAmount;
	};
}

const mapStateToProps = (state) => {
	return {
		billingSettings: state.billingSettings,
		currentDocument: state.billingDocument.currentDocument,
		invoiceData: state.billingDocument.invoiceData,
		formValues: getFormValues('freeInvoiceForm')(state)
	};
};

export default connect(mapStateToProps, {
	change,
	showMessageModal,
	hideMessageModal
})(InvoiceSummarySection);
