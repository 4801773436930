import React, { Component } from 'react';
import { INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import i18n from '../../../../../../../i18n';
import { ORDER_PRICE_MODIFIED } from '../../tables/orderInvoiceTable/OrderInvoiceTable';
import { parseInvoicingCurrency } from '../../../../../../../utils/tableUtils';

class OrderValueModified extends Component {
	getLocalizedValueModifiedInfo = () => {
		const modificationType = this.props.modificationType;
		if (modificationType.type === ORDER_PRICE_MODIFIED.INCREASE) {
			return i18n.t(
				'translation:billingModule.invoicing.table.orderInvoice.valueChangeDueOrderIncrease'
			);
		} else if (modificationType.type === ORDER_PRICE_MODIFIED.INCREASE_AND_DECREASE) {
			return i18n.t(
				'translation:billingModule.invoicing.table.orderInvoice.valueChangeDueOrderIncreaseAndDiscount'
			);
		}

		return i18n.t(
			'translation:billingModule.invoicing.table.orderInvoice.valueChangeDueOrderDiscount'
		);
	};

	render() {
		const modificationType = this.props.modificationType;

		if (!modificationType) {
			return (
				<td style={noBreakWordStyle} colSpan={this.props.colSpan}>
					{this.props.value}
				</td>
			);
		}

		return (
			<td
				colSpan={this.props.colSpan}
				data-toggle="tooltip"
				data-placement="right"
				title={this.getLocalizedValueModifiedInfo(modificationType)}
				style={noBreakWordStyle}>
				{this.props.value}*
			</td>
		);
	}
}

export default OrderValueModified;

const noBreakWordStyle = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
};
