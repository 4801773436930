import React, { Fragment } from 'react';
import SubHeader from '../../../common/SubHeader';
import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import {
	isGlobalAdmin,
	isLocalAdmin,
	isMyCompany,
	isTaskManagerEnabled
} from '../../../../utils/appUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUmbrellaBeach, faUsers } from '@fortawesome/free-solid-svg-icons';
import navigationUtils from '../../../../utils/navigationUtils';
import { PAGES_PATHS } from '../../../../utils/constants';
import {
	getGeneralWorkingHours,
	getLabDetails,
	getLabModules,
	loadAccountLabId
} from '../../../../actions';

class PlannerSection extends React.Component {
	componentDidMount() {
		this.props.getLabModules(this.props.account.labId);
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <Fragment />;
		}

		if (
			!(isLocalAdmin(this.props.account) || isGlobalAdmin(this.props.account)) ||
			(!isGlobalAdmin(this.props.account) && !isMyCompany())
		) {
			return <Fragment />;
		}

		return (
			<div className="col-6">
				<SubHeader title={i18n.t('translation:taskManager.labs.planner')} />
				<div className="col-12 data-section mt-s p-0">
					<div className="col-12">
						<div className="content row planner">
							<div
								className="col-4 p-0"
								onClick={() => this.navigateTo('workingHours')}>
								<FontAwesomeIcon
									className="clickable-icon"
									icon={faClock}
									size="lg"
								/>
								<span>{i18n.t('translation:taskManager.labs.workingHours')}</span>
							</div>
							<div className="col-4 p-0" onClick={() => this.navigateTo('holidays')}>
								<FontAwesomeIcon
									className="clickable-icon"
									icon={faUmbrellaBeach}
									size="lg"
								/>
								<span>{i18n.t('translation:taskManager.labs.holidays')}</span>
							</div>
							<div className="col-4 p-0" onClick={() => this.navigateTo('staff')}>
								<FontAwesomeIcon
									className="clickable-icon"
									icon={faUsers}
									size="lg"
								/>
								<span>{i18n.t('translation:taskManager.labs.employees')}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	navigateTo = (place) => {
		const id = this.props.company.id;

		if (place == 'workingHours') {
			navigationUtils.navigate(PAGES_PATHS.PLANNER_WORKING_HOURS, null, null, { id: id });
		}

		if (place == 'holidays') {
			navigationUtils.navigate(PAGES_PATHS.PLANNER_HOLIDAYS, null, null, { id: id });
		}

		if (place == 'staff') {
			navigationUtils.navigate(PAGES_PATHS.PLANNER_STAFF, null, null, { id: id });
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		currentLab: state.labs.currentLab,
		modules: state.modules
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	loadAccountLabId,
	getLabModules,
	getGeneralWorkingHours
})(PlannerSection);
