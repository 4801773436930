import { getAxiosWithToken } from '../utils/webApi';
import { COMPANY_STATUS, LOADER_TYPES, PAGES_PATHS } from '../utils/constants';
import {
	ADD_CLINIC_LOCATION,
	CLEAR_CURRENT_CLINIC,
	DELETE_CLINIC_LOCATION,
	DELETE_CURRENT_CLINIC,
	GET_CLINIC_DETAILS,
	GET_CLINICS_WITH_PAGINATION,
	GET_LABS_WITH_PAGINATION,
	GET_USERS,
	UPDATE_CLINIC,
	UPDATE_CLINIC_LOCATION
} from './types';
import navigationUtils from '../utils/navigationUtils';
import navigationMethods from '../utils/navigationUtils';
import i18n from '../i18n';
import { isGlobalAdmin, showSuccessPopup } from '../utils/appUtils';
import { getFormValues } from 'redux-form';
import { startLoader } from './loaderActions';

export const getClinics = (page, status) => async (dispatch) => {
	page = page - 1;
	const url =
		status === COMPANY_STATUS.WAITING_FOR_APPROVAL
			? '/clinic/get-all-waiting-for-approval'
			: '/clinic/get-all';
	const response = await getAxiosWithToken().get(url, {
		params: { page }
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CLINICS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const getClinicDetails = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/clinic/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CLINIC_DETAILS,
			payload: returnedData.data
		});
	}
};

export const fetchClinicDetails = async (id) => {
	const response = await getAxiosWithToken().get(`/clinic/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		return returnedData.data;
	}
};

export const createClinic = (data) => async (dispatch, getState) => {
	getAxiosWithToken()
		.post('/clinic', data)
		.then((response) => {
			let returnedData = response.data;
			if (returnedData.success) {
				getAxiosWithToken()
					.put(
						`/clinic/${returnedData.data.id}/change-max-active-users`,
						{},
						{
							params: {
								'max-number': getFormValues('activeUsersForm')(getState())[
									'maxNumber'
								]
							}
						}
					)
					.then((response) => {
						showSuccessPopup(i18n.t('translation:clinics.successfulCreatedClinic'));
						navigationUtils.navigate(PAGES_PATHS.PRACTICES);
					});
			}
		});
};

export const updateClinic = (data, id) => async (dispatch, getState) => {
	let message = i18n.t('translation:clinics.successfulUpdatedClinic');
	if (isGlobalAdmin(getState().account)) {
		getAxiosWithToken()
			.put(`/clinic/${id}`, data)
			.then((resp) => {
				let returnedData = resp.data;
				if (returnedData.success) {
					dispatch({
						type: UPDATE_CLINIC,
						payload: returnedData.data
					});
				}
				getAxiosWithToken()
					.put(
						`/clinic/${id}/change-max-active-users`,
						{},
						{
							params: {
								'max-number': getFormValues('activeUsersForm')(getState())[
									'maxNumber'
								]
							}
						}
					)
					.then((resp) => {
						const userMessage = resp.data.userMessage;
						if (userMessage) {
							dispatch(getClinicDetails(id));
							dispatch(getClinicStaff(id));
							message += '. ' + userMessage;
						}
						showSuccessPopup(message);
					});
			});
	} else {
		getAxiosWithToken()
			.put(`/clinic/${id}`, data)
			.then((resp) => {
				let returnedData = resp.data;
				if (returnedData.success) {
					dispatch({
						type: UPDATE_CLINIC,
						payload: returnedData.data
					});
					showSuccessPopup(message);
				}
			});
	}
};

export const addClinicLocation = (data, id, closeModal) => async (dispatch) => {
	closeModal();
	const response = await getAxiosWithToken().post(`/clinic/${id}/location`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulAddedLocation'));
		dispatch({
			type: ADD_CLINIC_LOCATION,
			payload: returnedData.data
		});
	}
};

export const updateClinicLocation =
	(data, clinicId, locationId, closeModal) => async (dispatch) => {
		closeModal();
		const response = await getAxiosWithToken().put(
			`/clinic/${clinicId}/location/${locationId}`,
			data
		);
		let returnedData = response.data;
		if (returnedData.success) {
			showSuccessPopup(i18n.t('translation:common.successfulUpdatedLocation'));
			dispatch({
				type: UPDATE_CLINIC_LOCATION,
				payload: returnedData.data
			});
		}
	};

export const deleteClinicLocation = (clinicId, locationId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/clinic/${clinicId}/location/${locationId}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:common.successfulDeletedLocation'));
		dispatch({
			type: DELETE_CLINIC_LOCATION,
			payload: locationId
		});
	}
};

export const getClinicsLegalNames = async () => {
	const response = await getAxiosWithToken().get('/clinic/get-all-legal-names');
	let returnedData = response.data;
	if (returnedData.success) {
		return returnedData.data;
	}
	return [];
};

export const getLinkedLabs = (clinicId, page) => async (dispatch) => {
	page = page - 1;
	const response = await getAxiosWithToken().get(`/link/get-linked-labs/${clinicId}`, {
		params: { page: page }
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_LABS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const activateClinic = (id, waitingForApproval) => async (dispatch, getState) => {
	const maxNumber = getFormValues('activeUsersForm')(getState())['maxNumber'] || 0;
	if (waitingForApproval && maxNumber) {
		dispatch(startLoader(LOADER_TYPES.GLOBAL_LOADER));
		getAxiosWithToken(true, false)
			.put(
				`/clinic/${id}/change-max-active-users`,
				{},
				{
					params: {
						'max-number': maxNumber
					}
				}
			)
			.then((resp) => {
				activate();
			});
	} else {
		activate();
	}

	async function activate() {
		const users = getState().users.users;
		const maxActiveUsers = getState().clinics.currentClinic.maxActiveUsers;
		const response = await getAxiosWithToken().put(`/clinic/${id}/activate`);
		let returnedData = response.data;
		if (returnedData.success) {
			if (users && users.content && users.content.length > maxActiveUsers) {
				showSuccessPopup(
					i18n.t('translation:clinics.successfullyActivatedClinicWithoutStaff')
				);
			} else {
				showSuccessPopup(i18n.t('translation:clinics.successfullyActivatedClinic'));
			}
			dispatch({
				type: GET_CLINIC_DETAILS,
				payload: returnedData.data
			});
			dispatch(getClinicStaff(id));
		}
	}
};

export const denyClinic = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/clinic/${id}/refuse-activation`);
	let returnedData = response.data;
	if (returnedData.success) {
		navigationMethods.goBack();
		showSuccessPopup(i18n.t('translation:clinics.successfullyDeniedClinic'));
	}
};

export const deactivateClinic = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/clinic/${id}/deactivate`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:clinics.successfullyDeactivatedClinic'));
		dispatch({
			type: GET_CLINIC_DETAILS,
			payload: returnedData.data
		});
		dispatch(getClinicStaff(id));
	}
};

export const markClinicAsPassive = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/clinic/${id}/passive`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CLINIC_DETAILS,
			payload: returnedData.data
		});
		showSuccessPopup(i18n.t('translation:clinics.successfulMarketClinicAsPassive'));
	}
};

export const markClinicAsNotPassive = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/clinic/${id}/not-passive`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_CLINIC_DETAILS,
			payload: returnedData.data
		});
		showSuccessPopup(i18n.t('translation:clinics.successfulMarketClinicAsNonPassive'));
	}
};

export const getClinicStaff = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/clinic/${id}/get-staff`);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_USERS,
			payload: returnedData.data
		});
	}
};

export const clearCurrentClinic = () => async (dispatch) => {
	dispatch({
		type: CLEAR_CURRENT_CLINIC
	});
};
