import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Main from './Main';
import Unauthenticated from './Unauthenticated';
import {
	isAuthenticated,
	loadAccount,
	loadSettings,
	loadToken,
	logout,
	saveLanguage
} from '../../actions';
import { Router } from 'react-router';
import { history } from '../../index';
import { parseFlagToLanguage, parseLanguageToFlag } from '../../utils/appUtils';
import i18n from '../../i18n';
import MessageModal from '../common/MessageModal';
import { NotificationContainer } from 'react-notifications';
import SpinnerModal from '../common/SpinnerModal';
import Decimal from 'decimal.js';

class App extends React.Component {
	state = { isLoaded: false };

	UNSAFE_componentWillMount() {
		this.props.loadSettings().then((settings) => {
			if (!settings || !settings.language) {
				let language = navigator.language || navigator.userLanguage;
				language = parseLanguageToFlag(language);
				i18n.changeLanguage(parseFlagToLanguage(language)).then(() => {
					this.props.saveLanguage(language);
				});
			} else {
				let language = this.props.settings.language;
				i18n.changeLanguage(parseFlagToLanguage(language)).then(() => {
					this.props.saveLanguage(language);
				});
			}
			this.props.loadAccount().then(() => {
				this.props.loadToken().then(() => {
					this.setState({ isLoaded: true });
				});
			});
		});
	}

	render() {
		if (this.state.isLoaded) {
			return (
				<Router history={history}>
					{/* The error boundary component contains a Link. And Link components always need to be inside a router */}
					{/*<ErrorBoundary>*/}
					{this.props.isAuthenticated ? <Main /> : <Unauthenticated />}
					<MessageModal />
					<SpinnerModal />
					{/*</ErrorBoundary>*/}
					<NotificationContainer />
				</Router>
			);
		} else {
			return <Fragment />;
		}
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: isAuthenticated(),
		settings: state.settings
	};
};

export default connect(mapStateToProps, {
	loadSettings,
	saveLanguage,
	loadToken,
	loadAccount,
	logout
})(App);
