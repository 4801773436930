import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../errors/NotFound';
import InternalServerError from '../errors/InternalServerError';
import UnhandledError from '../errors/UnhandledError';
import { PAGES_PATHS } from '../../utils/constants';
import UserDetails from '../pages/users/UserDetails';
import DashboardDentist from '../pages/dashboards/DashboardDentist';
import Suppliers from '../pages/labs/Suppliers';
import LabDetails from '../pages/labs/LabDetails';
import OrderDetails from '../pages/orders/OrderDetails';
import Orders from '../pages/orders/Orders';

class DentistRoutes extends Component {
	render = () => (
		<Fragment>
			<Switch>
				<Route path={PAGES_PATHS.HOME} exact component={DashboardDentist} />
				<Route path={PAGES_PATHS.MY_ACCOUNT} exact component={UserDetails} />
				<Route path={PAGES_PATHS.USER_DETAILS} exact component={UserDetails} />
				<Route path={PAGES_PATHS.SUPPLIERS} exact component={Suppliers} />
				<Route path={PAGES_PATHS.SUPPLIER_DETAILS} exact component={LabDetails} />
				<Route path={PAGES_PATHS.ORDERS_CREATE} exact component={OrderDetails} />
				<Route path={PAGES_PATHS.ORDERS} exact component={Orders} />
				<Route path={PAGES_PATHS.ORDERS_DETAILS} exact component={OrderDetails} />
				<Route path="/404" component={NotFound} />
				<Route path="/500" exact component={InternalServerError} />
				<Route path="/unhandledError" exact component={UnhandledError} />
				<Route component={NotFound} />
			</Switch>
		</Fragment>
	);
}

export default DentistRoutes;
