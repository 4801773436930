import React, { Fragment } from 'react';
import { getFormValues } from 'redux-form';
import { INVOICE_FIELD_NAME } from '../../../../../../utils/constants';
import i18n from '../../../../../../i18n';
import { Fields } from '../../../../../common/Fields';
import { connect } from 'react-redux';
import {
	formUtils,
	getDocumentTaxExclude,
	getItemAmountNoTax,
	getItemTotalPriceWithTax
} from '../../../../../../utils/formUtils';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import TableButton from '../../../../../common/TableButton';
import { parseInvoicingCurrency } from '../../../../../../utils/tableUtils';
import { getSelectedTaxType } from '../../../../../../utils/appUtils';
import { isReadableInput } from '../../../../../../utils/billingUtils';
import { BILLING_DOCUMENT_MODE } from '../BillingDocumentModal';
import SubHeader from '../../../../../common/SubHeader';

class ProductsTable extends React.Component {
	render() {
		return (
			<Fragment>
				<SubHeader
					title={i18n.t('translation:billingModule.invoicing.addProductsTableSubheader')}
				/>
				<table className="table col-12 position-relative">
					<thead>
						<tr style={{ backgroundColor: '#13253b' }}>{this.renderHeader()}</tr>
					</thead>
					<tbody>
						{this.renderData()}
						{this.renderNoItemsMessage()}
						{this.renderTotal()}
					</tbody>
				</table>
			</Fragment>
		);
	}

	renderTotal = () => {
		const total = parseInvoicingCurrency(
			this.props.formValues[`${INVOICE_FIELD_NAME.DOCUMENT_TOTAL_WITH_TAX}`],
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);

		if (this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return (
				<tr className="total">
					<td colSpan={7} className={'text-right'}>
						{`${i18n.t(
							'translation:billingModule.invoicing.table.freeInvoice.totalFooter',
							{ taxType: getSelectedTaxType(this.props.billingSettings.taxType) }
						)}`}
						&emsp;&emsp;
						{total}
					</td>
				</tr>
			);
		}

		if (this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS].length) {
			return (
				<tr className="total">
					<td className={'pl-xl-4 text-right'} colSpan={6}>
						{i18n.t(
							'translation:billingModule.invoicing.table.freeInvoice.totalFooter',
							{ taxType: getSelectedTaxType(this.props.billingSettings.taxType) }
						)}
					</td>
					<td className={'pl-xl-4'} colSpan={2}>
						{total}
					</td>
				</tr>
			);
		}
	};

	renderNoItemsMessage = () => {
		const noItemsMessage = i18n.t('translation:billingModule.invoicing.noItemsAdded');

		if (!this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS].length) {
			return (
				<tr>
					<td colSpan={8} className="text-center">
						{noItemsMessage}
					</td>
				</tr>
			);
		}

		return null;
	};

	renderHeader = () => {
		const columns = [
			{
				text: i18n.t('translation:billingModule.invoicing.table.freeInvoice.productName'),
				width: 29
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.unit'),
				width: 9
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.quantity'),
				width: 8
			},
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.unitPrice'),
				width: 12
			},
			{
				text: i18n.t('translation:billingModule.common.taxBase'),
				width: 14
			},
			{ text: i18n.t('translation:billingModule.invoicing.table.common.tax'), width: 9 },
			{
				text: i18n.t('translation:billingModule.invoicing.table.common.totalPrice'),
				width: this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW ? 19 : 15
			}
		];

		if (this.props.mode !== BILLING_DOCUMENT_MODE.PREVIEW) {
			columns.push({ text: ' ', width: 4 });
		}

		if (!columns.length) {
			return null;
		}

		return columns.map((column, index) => {
			return (
				<Fragment key={index + 'tableColumn'}>
					<th
						key={index + 'h'}
						scope="col"
						style={{
							width: `${column.width}%`,
							position: 'sticky',
							top: 0,
							zIndex: 100,
							backgroundColor: '#13253b'
						}}>
						<div className={'d-inline'}>{column.text}</div>
					</th>
				</Fragment>
			);
		});
	};

	renderData = () => {
		const itemsIDS = this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS];

		if (itemsIDS?.length === 0) {
			return;
		}

		return this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ITEMS_IDS].map((index, ind) => {
			return this.renderRow(index);
		});
	};

	getReadableTaxGroup() {
		if (this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return true;
		}

		return getDocumentTaxExclude(this.props.formValues);
	}

	renderRow = (index) => {
		const { formValues } = this.props;

		const readableInput = isReadableInput(this.props.mode);

		const name = formValues[`${INVOICE_FIELD_NAME.ITEM_NAME_}${index}`];

		return (
			<tr key={index} className={'col-12 p-0'}>
				<td style={{ width: '29%' }} className="d-flex flex-nowrap w-100">
					{name}
				</td>
				{this.renderItemUnit(index, readableInput)}
				{this.renderItemQuantity(index, readableInput)}
				{this.renderItemUnitPrice(index, readableInput)}
				{this.renderItemAmount(index)}
				{this.renderItemTax(index)}
				{this.renderItemTotalPrice(index)}
				{this.renderRemoveItemButton(index)}
			</tr>
		);
	};

	renderRemoveItemButton(index) {
		if (this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return null;
		}

		return (
			<td style={{ width: '4%' }}>
				<TableButton
					icon={faTrash}
					onPress={() => this.props.onItemRemove(index)}
					danger
					tooltip={i18n.t('translation:billingModule.common.buttons.tooltips.delete')}
				/>
			</td>
		);
	}

	renderItemTotalPrice(index) {
		const style =
			this.props.mode === BILLING_DOCUMENT_MODE.PREVIEW ? { width: '19%' } : { width: '15%' };

		return (
			<td style={style}>
				{parseInvoicingCurrency(
					getItemTotalPriceWithTax(this.props.formValues, index),
					this.props.localizedCurrencyAbbreviation,
					this.props.currencySymbol
				)}
			</td>
		);
	}

	renderItemTax(index) {
		return (
			<td style={{ width: '9%' }}>
				{Fields.billingModule.invoicing.invoiceProductTaxGroups(index, {
					options: formUtils.options.getProductTaxGroupsOptions(
						this.props.taxGroups,
						index,
						this.props.formValues
					),
					noLabel: true,
					readableInput: this.getReadableTaxGroup(),
					onChange: (e) => this.props.onItemTaxChange(e.target.value, index),
					validate: (value) => {
						if (value && value.trim() === '_') {
							return ' ';
						}
						return formUtils.validateRequiredField(value);
					}
				})}
			</td>
		);
	}

	renderItemAmount(index) {
		return (
			<td style={{ width: '14%' }}>
				{parseInvoicingCurrency(
					getItemAmountNoTax(this.props.formValues, index),
					this.props.localizedCurrencyAbbreviation,
					this.props.currencySymbol
				)}
			</td>
		);
	}

	renderItemUnitPrice(index, readableInput) {
		return (
			<td style={{ width: '12%' }}>
				{Fields.billingModule.invoicing.invoiceProductPrice(index, {
					localizedCurrencyAbbreviation: this.props.localizedCurrencyAbbreviation,
					currencySymbol: this.props.currencySymbol,
					onChange: (e) => this.props.onItemUnitPriceChange(e.target.value, index),
					validate: formUtils.validateRequiredTableField,
					readableInput
				})}
			</td>
		);
	}

	renderItemQuantity(index, readableInput) {
		return (
			<td style={{ width: '8%' }}>
				{Fields.billingModule.invoicing.invoiceProductQuantity(index, {
					onChange: (e) => this.props.onItemQuantityChange(e.target.value, index),
					className: 'table-input',
					validate: formUtils.validateRequiredTableField,
					readableInput
				})}
			</td>
		);
	}

	renderItemUnit(index, readableInput) {
		return (
			<td style={{ width: '9%' }}>
				{Fields.billingModule.invoicing.invoiceProductUnit(index, {
					options: formUtils.options.getProductUnitOptions(),
					className: 'table-input',
					validate: formUtils.validateRequiredTableField,
					readableInput
				})}
			</td>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		taxGroups: state.billingSettings.taxGroups,
		billingSettings: state.billingSettings
	};
};

export default connect(mapStateToProps, {})(ProductsTable);
