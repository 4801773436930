import React, { Component } from 'react';
import { Field } from 'redux-form';
import i18n from '../../../../../i18n';
import { leftLabelledSelectInput } from '../../../../common/Fields';
import DropdownField from './DropdownField';
import _ from 'lodash';

class ClientsDropdownField extends Component {
	render() {
		let options = [{ name: '', value: '' }];

		if (_.isArray(this.props.clients)) {
			this.props.clients?.forEach((client) =>
				options.push({ name: client.legalName, value: client.id })
			);
		}

		return (
			<DropdownField
				name={this.props.name}
				label={i18n.t('translation:billingModule.common.client')}
				customProps={{
					className: 'mr-0',
					innerClassName: 'pl-0 pr-0',
					options: options,
					blueLabel: this.props.blueLabel,
					disabled: this.props.disabled
				}}
				onChange={this.props.onChange}
			/>
		);
	}
}

export default ClientsDropdownField;
