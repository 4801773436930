import React from 'react';
import { Fields } from '../../../common/Fields';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../../utils/formUtils';

class LocalInventoryEditTemplateForm extends React.Component {
	render() {
		return (
			<form>
				{Fields.commonFields.name({ className: 'w-100 mt-s' })}
				{Fields.templateFields.isDefault({ className: 'mt-s' })}
			</form>
		);
	}
}

const EditTemplateForm = reduxForm({
	form: 'localInventoryEditTemplateForm',
	validate: formUtils.validateLocalInventoryEditTemplate,
	onSubmit: () => {}
})(LocalInventoryEditTemplateForm);

export default EditTemplateForm;
