import { getAxiosWithToken } from '../../utils/webApi';
import {
	CREATE_OVERDUE_INTERVAL,
	CREATE_TAX_GROUP,
	DELETE_OVERDUE_INTERVAL,
	DELETE_TAX_GROUP,
	GET_BILLING_SETTINGS,
	GET_LAB_TAX_GROUPS,
	UPDATE_BILLING_SETTINGS_INIT_DOC_NUMBER,
	UPDATE_BILLING_SETTINGS_TAX_TYPE,
	UPDATE_OVERDUE_INTERVAL,
	UPDATE_TAX_GROUP
} from '../types';
import { showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';

export const getBillingSettings = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/billing/settings?lab-id=${id}`);
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_BILLING_SETTINGS,
			payload: returnedData.data
		});
	}
};

export const updateBillingDocumentNumber =
	(settingsId, billingSettingsRequestDto) => async (dispatch) => {
		const response = await getAxiosWithToken().put(
			`/billing/settings/${settingsId}`,
			billingSettingsRequestDto
		);

		let returnedData = response.data;

		if (returnedData.success) {
			showSuccessPopup(
				i18n.t(
					'translation:billingModule.settings.successMessages.savedInitialDocumentNumber'
				)
			);

			dispatch({
				type: UPDATE_BILLING_SETTINGS_INIT_DOC_NUMBER,
				payload: returnedData.data
			});
		}
	};

export const updateTaxType = (settingsId, updateTaxTypeDto) => async (dispatch) => {
	const response = await getAxiosWithToken().post(
		`/billing/settings/${settingsId}/update-tax-type`,
		updateTaxTypeDto
	);

	let returnedData = response.data;

	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:billingModule.settings.successMessages.savedTaxType'));

		dispatch({
			type: UPDATE_BILLING_SETTINGS_TAX_TYPE,
			payload: returnedData.data
		});
	}
};

export const getTaxGroups = (labId) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/billing/settings/tax-group?lab-id=${labId}`);
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_LAB_TAX_GROUPS,
			payload: returnedData.data
		});
	}
};

export const createTaxGroup =
	(settingsId, taxGroup, closeModal, setServerErrorMessage) => async (dispatch) => {
		const response = await getAxiosWithToken(false).post(
			`/billing/settings/${settingsId}/tax-group`,
			taxGroup
		);

		let returnedData = response.data;

		if (returnedData.success) {
			closeModal();

			showSuccessPopup(
				i18n.t('translation:billingModule.settings.successMessages.createdTaxGroup')
			);

			dispatch({
				type: CREATE_TAX_GROUP,
				payload: returnedData.data
			});
		} else {
			setServerErrorMessage(returnedData.userMessage);
		}
	};

export const updateTaxGroup =
	(settingsId, taxGroupId, taxGroup, closeModal, setServerErrorMessage) => async (dispatch) => {
		const response = await getAxiosWithToken(false).put(
			`/billing/settings/${settingsId}/tax-group/${taxGroupId}`,
			taxGroup
		);

		let returnedData = response.data;

		if (returnedData.success) {
			closeModal();

			showSuccessPopup(
				i18n.t('translation:billingModule.settings.successMessages.updatedTaxGroup')
			);

			dispatch({
				type: UPDATE_TAX_GROUP,
				payload: returnedData.data
			});
		} else {
			setServerErrorMessage(returnedData.userMessage);
		}
	};

export const deleteTaxGroup = (settingsId, taxGroupId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(
		`/billing/settings/${settingsId}/tax-group/${taxGroupId}`
	);

	let returnedData = response.data;

	if (returnedData.success) {
		showSuccessPopup(
			i18n.t('translation:billingModule.settings.successMessages.deletedTaxGroup')
		);

		dispatch({
			type: DELETE_TAX_GROUP,
			payload: taxGroupId
		});
	}
};

export const createOverdueInterval =
	(settingsId, overdueInterval, closeModal, setServerErrorMessage) => async (dispatch) => {
		const response = await getAxiosWithToken(false).post(
			`/billing/settings/${settingsId}/overdue-interval`,
			overdueInterval
		);

		let returnedData = response.data;

		if (returnedData.success) {
			closeModal();

			showSuccessPopup(
				i18n.t('translation:billingModule.settings.successMessages.createdOverdueInterval')
			);

			dispatch({
				type: CREATE_OVERDUE_INTERVAL,
				payload: returnedData.data
			});
		} else {
			setServerErrorMessage(returnedData.userMessage);
		}
	};

export const updateOverdueInterval =
	(settingsId, overdueIntervalId, overdueInterval, closeModal, setServerErrorMessage) =>
	async (dispatch) => {
		const response = await getAxiosWithToken(false).put(
			`/billing/settings/${settingsId}/overdue-interval/${overdueIntervalId}`,
			overdueInterval
		);

		let returnedData = response.data;

		if (returnedData.success) {
			closeModal();

			showSuccessPopup(
				i18n.t('translation:billingModule.settings.successMessages.updatedOverdueInterval')
			);

			dispatch({
				type: UPDATE_OVERDUE_INTERVAL,
				payload: returnedData.data
			});
		} else {
			setServerErrorMessage(returnedData.userMessage);
		}
	};

export const deleteOverdueInterval = (settingsId, overdueIntervalId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(
		`/billing/settings/${settingsId}/overdue-interval/${overdueIntervalId}`
	);

	let returnedData = response.data;

	if (returnedData.success) {
		showSuccessPopup(
			i18n.t('translation:billingModule.settings.successMessages.deletedOverdueInterval')
		);

		dispatch({
			type: DELETE_OVERDUE_INTERVAL,
			payload: overdueIntervalId
		});
	}
};
