import React, { Component } from 'react';
import { Field } from 'redux-form';
import i18n from '../../../../../i18n';
import { leftLabelledSelectInput } from '../../../../common/Fields';

class DropdownField extends Component {
	render() {
		return (
			<Field
				name={this.props.name}
				label={this.props.label}
				customProps={this.props.customProps}
				onChange={this.props.onChange}
				component={leftLabelledSelectInput}
			/>
		);
	}
}

export default DropdownField;
