import React, { useEffect, useState } from 'react';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fields } from '../../../../common/Fields';
import { getFormValues, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from '../../../../../utils/moment';
import { addZeroIfOneDigit, isEmptyObject } from '../../../../../utils/appUtils';
import { createShift, updateShift } from '../../../../../actions';
import { formUtils } from '../../../../../utils/formUtils';

const ShiftsTypesModal = ({
	closeModal,
	isModalOpen,
	handleSubmit,
	initialValues,
	initialize,
	destroy
}) => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.account);
	const formValues = useSelector((state) => getFormValues('shiftsTypesForm')(state));
	const end = formValues ? formValues.endTime : null;
	const start = formValues ? formValues.startTime : null;
	const breakDurationInMinutes = formValues
		? +formValues.hours * 60 + (+formValues.minutes_0 || 0)
		: null;
	const [serverError, setServerError] = useState();

	useEffect(() => {
		Modal.setAppElement('#root');
	}, []);

	useEffect(() => {
		if (isModalOpen) {
			if (!isEmptyObject(initialValues)) {
				initialize(initialValues);
			} else {
				initialize({ fields: '' });
			}
		}
	}, [isModalOpen]);

	const onCloseModal = () => {
		setServerError('');
		destroy();
		closeModal();
	};

	const onSubmit = (formValues) => {
		const data = { ...formValues };

		data.startTime = moment(data.startTime).format('HH:mm');
		data.endTime = moment(data.endTime).format('HH:mm');
		data.breakDuration = data.hours + ':' + data.minutes_0;

		delete data.duration;
		if (initialValues.id) {
			data.id = initialValues.id;
			dispatch(updateShift(account.labId, data, setServerError, onCloseModal));
		} else {
			dispatch(createShift(account.labId, data, setServerError, onCloseModal));
		}
	};

	const editButton = (
		<DefaultButton
			title={i18n.t('translation:common.buttons.save')}
			type="submit"
			key={i18n.t('translation:common.buttons.save')}
			onClick={handleSubmit(onSubmit)}
		/>
	);

	const cancelButton = (
		<DefaultButton
			title={i18n.t('translation:common.buttons.close')}
			key={i18n.t('translation:common.buttons.close')}
			onClick={() => {
				onCloseModal();
			}}
			secondary
		/>
	);

	const modalTitle = i18n.t('translation:taskManager.planner.addShift');

	const excludeStartTimes = end
		? [new Date().setHours(new Date(end).getHours(), new Date(end).getMinutes())]
		: null;
	const excludeEndTimes = start
		? [new Date().setHours(new Date(start).getHours(), new Date(start).getMinutes())]
		: null;

	const getTime = () => {
		const startTime = moment(start);
		const endTime = moment(end);
		if (endTime.isBefore(startTime)) {
			endTime.add(1, 'days');
		}
		startTime.startOf('minute');
		endTime.startOf('minute');
		startTime.add(breakDurationInMinutes, 'minutes');
		const minutes = endTime.diff(startTime, 'minutes');
		let hours = endTime.diff(startTime, 'hours');
		hours = minutes < 0 && !hours ? '-' + hours : hours;

		return start && end
			? hours + ':' + addZeroIfOneDigit(minutes % 60)
			: i18n.t('translation:taskManager.common.notEntered');
	};

	const renderServerError = () => {
		return serverError ? (
			<span className="align-items-start text-danger">{serverError}</span>
		) : null;
	};

	return (
		<Modal
			isOpen={isModalOpen}
			className="custom-modal min-width"
			overlayClassName="custom-overlay">
			<FontAwesomeIcon
				className="close-icon"
				icon={faTimes}
				onClick={() => {
					onCloseModal();
				}}
			/>
			<h2>{modalTitle}</h2>
			{Fields.workingHoursFields.type()}
			{Fields.workingHoursFields.code()}
			<div className="row">
				<div className="col-12 d-flex mt-s">
					{Fields.workingHoursFields.start({
						className: 'w-50',
						excludeTimes: excludeStartTimes
					})}
					{Fields.workingHoursFields.end({
						className: 'w-50',
						excludeTimes: excludeEndTimes
					})}
				</div>
			</div>
			{Fields.workingHoursFields.breakDuration({ className: 'mt-s', id: 0 })}
			<label className="mt-s">{i18n.t('translation:taskManager.common.time')}</label>
			<span>{getTime()}</span>
			{renderServerError()}
			<div className="buttons">
				{editButton}
				{cancelButton}
			</div>
		</Modal>
	);
};

const ShiftsTypesForm = reduxForm({
	form: 'shiftsTypesForm',
	validate: formUtils.validateShift
})(ShiftsTypesModal);

export default ShiftsTypesForm;
