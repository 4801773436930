import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import store from '../../../store';
import Table from '../../common/Table';
import Pagination from '../../common/Pagination';
import GlobalProductModal from './GlobalProductModal';
import Header from '../../common/Header';
import DefaultButton from '../../common/DefaultButton';
import { isEmptyObject, parseGlobalProductCategory } from '../../../utils/appUtils';
import {
	clearCurrentGlobalProduct,
	clearReducers,
	deleteGlobalProduct,
	getGlobalProductById,
	getGlobalProductPhasesTemplates,
	getGlobalProducts,
	hideMessageModal,
	setPagination,
	showMessageModal
} from '../../../actions';
import { EMPTY_STRING, PAGINATION_TYPES, TABLE_ACTIONS } from '../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import GlobalInventoryPhasesModal from '../taskManager/inventory/GlobalInventoryPhasesModal';

class GlobalInventory extends React.Component {
	state = {
		isPhaseModalOpen: false,
		isModalOpen: false,
		modalInitialValues: { category: EMPTY_STRING },
		currentItem: {}
	};

	UNSAFE_componentWillMount() {
		const defaultPage = 1;
		this.props.getGlobalProducts(this.props.page || defaultPage);
		if (!this.props.page) {
			this.setCurrentPage(defaultPage);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.getGlobalProducts(this.props.page);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.inventory.content)
			) {
				this.props.setPagination(PAGINATION_TYPES.GLOBAL_PRODUCTS, this.props.page - 1);
			}
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render = () => {
		return (
			<Fragment>
				<GlobalInventoryPhasesModal
					isModalOpen={this.state.isPhaseModalOpen}
					closeModal={this.closePhaseModal}
					item={this.state.currentItem}
					phases={this.props.phases}
					page={this.props.page || 1}
				/>
				<GlobalProductModal
					isModalOpen={this.state.isModalOpen}
					closeModal={this.closeModal}
					initialValues={this.state.modalInitialValues}
				/>
				<Header
					title={i18n.t('translation:inventory.inventory')}
					buttons={[this.renderAddButton()]}
				/>
				<div className="col-12 p-0">
					<hr />
					{this.renderInventoryTable()}
					{this.renderPagination()}
				</div>
			</Fragment>
		);
	};

	closePhaseModal = () => {
		this.setState({ isPhaseModalOpen: false });
	};

	openPhaseModal = () => {
		this.setState({ isPhaseModalOpen: true });
	};

	renderInventoryTable = () => {
		return (
			<Table
				columns={[
					i18n.t('translation:inventory.table.type'),
					i18n.t('translation:inventory.table.material'),
					i18n.t('translation:inventory.table.category'),
					i18n.t('translation:inventory.table.color'),
					i18n.t('translation:taskManager:common.table.template'),
					i18n.t('translation:common.table.action')
				]}
				data={this.prepareTableRecords(this.props.inventory.content, this.openPhaseModal)}
				noItemsMessage={i18n.t('translation:common.table.noItems')}
			/>
		);
	};

	prepareTableRecords = (products, openPhaseModal) => {
		let tableRecords = [];
		for (let index in products) {
			const product = products[index];
			const editButton = {
				type: TABLE_ACTIONS.EDIT,
				action: () => {
					this.showModalWithInitialization(product);
				}
			};
			const phasesButton = {
				type: TABLE_ACTIONS.TEMPLATES_GLOBAL,
				action: () => {
					this.props.getGlobalProductPhasesTemplates(product.id, openPhaseModal);
					this.setState({ currentItem: product });
				}
			};
			const deleteButton = {
				type: TABLE_ACTIONS.DELETE,
				action: () => {
					this.onDeleteButtonPress(product);
				}
			};

			const hasColor = product.hasColor ? (
				<FontAwesomeIcon
					className="note-icon"
					icon={faCheck}
					title={i18n.t('translation:inventory.table.tooltips.hasColor')}
				/>
			) : (
				<FontAwesomeIcon
					icon={faTimes}
					className="note-icon"
					title={i18n.t('translation:inventory.table.tooltips.noColor')}
				/>
			);

			const hasGlobalTemplate = product.hasGlobalTemplate ? (
				<FontAwesomeIcon
					className="note-icon"
					icon={faCheck}
					title={i18n.t(
						'translation:taskManager.inventory.table.tooltips.hasGlobalTemplate'
					)}
				/>
			) : (
				<FontAwesomeIcon
					icon={faTimes}
					className="note-icon"
					title={i18n.t(
						'translation:taskManager.inventory.table.tooltips.noGlobalTemplate'
					)}
				/>
			);

			const currentProduct = [
				product.type,
				product.material,
				parseGlobalProductCategory(product.category),
				hasColor,
				hasGlobalTemplate,
				[editButton, phasesButton, deleteButton]
			];

			tableRecords.push(currentProduct);
		}

		return tableRecords;
	};

	showModalWithInitialization = (product) => {
		this.props.getGlobalProductById(product.id);
		this.setState({
			isModalOpen: true,
			modalInitialValues: { category: product.category, hasColor: !product.hasColor }
		});
	};

	onDeleteButtonPress = (product) => {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					store.dispatch(deleteGlobalProduct(product.id, this.props.page));
					store.dispatch(hideMessageModal());
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
				secondary
			/>
		);
		store.dispatch(
			showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:common.deleteProductWarning'),
				[yesButton, noButton]
			)
		);
	};

	renderAddButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				key={i18n.t('translation:common.buttons.add')}
				onClick={this.openModal}
			/>
		);
	};

	openModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen });
		this.props.clearCurrentGlobalProduct();
	};

	closeModal = () => {
		this.setState({
			isModalOpen: false,
			modalInitialValues: { category: EMPTY_STRING }
		});
		this.props.clearCurrentGlobalProduct();
	};

	renderPagination = () => {
		const inventory = this.props.inventory;
		if (inventory) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={inventory.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.GLOBAL_PRODUCTS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		phases: state.phases,
		inventory: state.inventory.globalInventory,
		page: state.pagination[PAGINATION_TYPES.GLOBAL_PRODUCTS]
	};
};

export default connect(mapStateToProps, {
	getGlobalProductById,
	clearCurrentGlobalProduct,
	getGlobalProducts,
	deleteGlobalProduct,
	setPagination,
	clearReducers,
	getGlobalProductPhasesTemplates
})(GlobalInventory);
