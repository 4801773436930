import {
	ADD_LAB_LOCATION,
	CLEAR_REDUCER,
	DELETE_LAB_LOCATION,
	GET_LAB_DETAILS,
	GET_LABS_WITH_PAGINATION,
	UPDATE_LAB,
	UPDATE_LAB_LOCATION
} from '../actions/types';
import _ from 'lodash';

const initState = {
	currentLab: {},
	labs: []
};

const labsReducer = (state = { ...initState }, action) => {
	if (action.type === GET_LABS_WITH_PAGINATION) {
		state.labs = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_LAB_DETAILS) {
		state.currentLab = { ...action.payload };
		return { ...state };
	}

	if (action.type === UPDATE_LAB) {
		state.currentLab = { ...action.payload };
		return { ...state };
	}

	if (action.type === ADD_LAB_LOCATION) {
		state.currentLab.locations.push(action.payload);
		state.currentLab.locations = [...state.currentLab.locations];
		state.currentLab = { ...state.currentLab };
		return { ...state };
	}

	if (action.type === UPDATE_LAB_LOCATION) {
		let index = _.findIndex(state.currentLab.locations, { id: action.payload.id });
		state.currentLab.locations.splice(index, 1, action.payload);
		state.currentLab.locations = [...state.currentLab.locations];
		state.currentLab = { ...state.currentLab };
		return { ...state };
	}

	if (action.type === DELETE_LAB_LOCATION) {
		_.remove(state.currentLab.locations, function (location) {
			return +location.id === +action.payload;
		});
		state.currentLab.locations = [...state.currentLab.locations];
		state.currentLab = { ...state.currentLab };
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default labsReducer;
