import React, { Fragment } from 'react';
import { getSelectedTaxType, isEmptyObject } from '../../../../../utils/appUtils';
import { PAGINATION_TYPES, SORT_TYPES } from '../../../../../utils/constants';
import { parseInvoicingOrders } from '../../../../../utils/tableUtils';
import i18n from '../../../../../i18n';
import Table from '../../../../common/Table';
import Pagination from '../../../../common/Pagination';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
	getBillingOrders,
	clearPreCreateModalDocumentReducer,
	setPagination,
	clearPagination
} from '../../../../../actions';
import store from '../../../../../store';
import { PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS } from '../../../../../actions/types';

class CreateInvoiceModalOrdersTable extends React.Component {
	componentDidMount() {
		if (this.props.onSort) {
			this.props.onSort(this.props.sort);
		}

		this.props.getBillingOrders(
			this.props.page || 1,
			this.props.filters,
			this.props.account.labId,
			true,
			(responseData) => {
				store.dispatch({
					type: PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS,
					payload: responseData
				});
			}
		);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.page && this.props.page !== 1 && isEmptyObject(this.props.orders)) {
			this.props.setPagination(PAGINATION_TYPES.BILLING_ORDERS, this.props.page - 1);
			return;
		}

		if (prevProps.page !== this.props.page || prevProps.filters !== this.props.filters) {
			this.props.getBillingOrders(
				this.props.page || 1,
				this.props.filters,
				this.props.account.labId,
				true,
				(responseData) => {
					store.dispatch({
						type: PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS,
						payload: responseData
					});
				}
			);
		}
	}

	componentWillUnmount() {
		this.props.clearPreCreateModalDocumentReducer();
		this.props.clearPagination(PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_ORDERS);
	}

	render() {
		return (
			<Fragment>
				{this.renderOrders()}
				{this.renderPagination()}
			</Fragment>
		);
	}

	renderOrders = () => {
		const taxType = getSelectedTaxType(this.props.billingSettings.taxType);
		const amountWithTaxColumnName = i18n.t(
			'translation:billingModule.common.table.amountWithTax',
			{ taxType: taxType }
		);

		const columnsNames = [
			i18n.t('translation:billingModule.common.table.number'),
			i18n.t('translation:billingModule.common.table.client'),
			amountWithTaxColumnName,
			i18n.t('translation:billingModule.common.table.invoicedAmount'),
			i18n.t('translation:billingModule.common.table.remainingAmount'),
			i18n.t('translation:billingModule.common.table.paymentDateTo'),
			i18n.t('translation:billingModule.common.table.status'),
			i18n.t('translation:billingModule.common.table.invoicing')
		];

		const ordersFromState = this.props.orders.content ? this.props.orders.content : [];

		const orders = parseInvoicingOrders(
			ordersFromState,
			this.props.onSelectOrder,
			this.props.formValues,
			true
		);

		return (
			<div className="col-12 mt-s p-0">
				<Table columns={columnsNames} data={orders} />
			</div>
		);
	};

	renderPagination = () => {
		const orders = this.props.orders;

		if (orders.content) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={orders.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_ORDERS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		page: state.pagination[PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_ORDERS],
		orders: state.billingDocument.preCreateModal.orders,
		sort: state.sort[SORT_TYPES.ORDER_SORT],
		billingSettings: state.billingSettings,
		formValues: getFormValues('tableForm')(state) || {}
	};
};

export default connect(mapStateToProps, {
	getBillingOrders,
	clearPreCreateModalDocumentReducer,
	setPagination,
	clearPagination
})(CreateInvoiceModalOrdersTable);
