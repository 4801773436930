import React, { Fragment } from 'react';
import i18n from '../../../i18n';
import DropdownButton from '../../common/DropdownButton';
import { BILLING_DOCUMENT_TYPES } from '../../../utils/constants';
import CreateCreditOrDebitNoteModal from '../billingModule/common/createCreditOrDebitNoteModal/CreateCreditOrDebitNoteModal';
import BillingDocumentModal, {
	BILLING_DOCUMENT_MODE
} from '../billingModule/invoicing/billingDocumentModal/BillingDocumentModal';
import { connect } from 'react-redux';
import {
	clearCurrentDocument,
	getLinkedClinicsWithoutPagination,
	getOrderDetails
} from '../../../actions';

class IssueDocumentButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			buttons: [
				i18n.t('translation:billingModule.common.buttons.invoice'),
				i18n.t('translation:billingModule.common.buttons.creditNote'),
				i18n.t('translation:billingModule.common.buttons.debitNote')
			],
			isBillingModalOpen: false,
			isCreateCreditOrDebitNoteModalOpen: false,
			invoiceType: null
		};
	}

	componentDidMount() {
		this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);
	}

	onIssueDocumentButtonClick(buttonTitle) {
		this.props.clearCurrentDocument();

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.invoice')) {
			this.setState((prevState) => {
				return {
					...prevState,
					isBillingModalOpen: true
				};
			});
			return;
		}

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.creditNote')) {
			this.setState((prevState) => {
				return {
					...prevState,
					isCreateCreditOrDebitNoteModalOpen: true,
					invoiceType: BILLING_DOCUMENT_TYPES.CREDIT_NOTE
				};
			});
			return;
		}

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.debitNote')) {
			this.setState((prevState) => {
				return {
					...prevState,
					isCreateCreditOrDebitNoteModalOpen: true,
					invoiceType: BILLING_DOCUMENT_TYPES.DEBIT_NOTE
				};
			});
		}
	}

	closeCreateCreditOrDebitNoteModal = () => {
		this.props.getOrderDetails(this.props.orderId);
		this.setState((prevState) => {
			return {
				...prevState,
				isCreateCreditOrDebitNoteModalOpen: false,
				invoiceType: null
			};
		});
	};

	closeBillingDocumentModal = () => {
		this.props.getOrderDetails(this.props.orderId);
		this.setState((prevState) => {
			return {
				...prevState,
				isBillingModalOpen: false,
				invoiceType: null
			};
		});
	};

	renderBillingDocumentModal() {
		if (this.state.isBillingModalOpen) {
			return (
				<BillingDocumentModal
					mode={BILLING_DOCUMENT_MODE.CREATE}
					isModalOpen={true}
					onModalClose={this.closeBillingDocumentModal}
					documentType={BILLING_DOCUMENT_TYPES.ORDER_INVOICE}
					selectedOrdersIds={[this.props.orderId]}
					clinicId={this.props.clinicId}
				/>
			);
		}
		return null;
	}

	renderCreateCreditOrDebitNoteModal() {
		if (this.state.isCreateCreditOrDebitNoteModalOpen) {
			return (
				<CreateCreditOrDebitNoteModal
					isModalOpen={true}
					onModalClose={this.closeCreateCreditOrDebitNoteModal}
					previousScreenFilters={{
						clinicLegalName: this.props.clinicLegalName,
						clinicSelect: this.props.clinicId,
						labOrderId: this.props.labOrderId
					}}
					invoiceType={this.state.invoiceType}
					isMainFiltersReadOnly={true}
				/>
			);
		}
		return null;
	}

	getButtons() {
		if (this.props.hasIssuedInvoice) {
			if (this.props.orderHasLeftForInvoice) {
				return this.state.buttons;
			}
			return [this.state.buttons[1], this.state.buttons[2]];
		}

		return [this.state.buttons[0]];
	}

	render() {
		const title = i18n.t('translation:billingModule.common.buttons.issue');
		return (
			<Fragment>
				<DropdownButton
					title={title}
					buttons={this.getButtons()}
					short={true}
					onButtonClick={(buttonTitle) => this.onIssueDocumentButtonClick(buttonTitle)}
				/>
				{this.renderBillingDocumentModal()}
				{this.renderCreateCreditOrDebitNoteModal()}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};

export default connect(mapStateToProps, {
	getLinkedClinicsWithoutPagination,
	getOrderDetails,
	clearCurrentDocument
})(IssueDocumentButton);
