import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import React from 'react';
import IncomeDetails from './incomeDetails/IncomeDetails';
import OutcomeDetails from './outcomeDetails.js/OutcomeDetails';
import i18n from '../../../../i18n';

export const IncomeOutcomeModalContentType = {
	INCOME: 'INCOME',
	OUTCOME: 'OUTCOME'
};

class IncomeOutcomeModal extends React.Component {
	getTitle() {
		if (this.props.isCreation) {
			if (this.props.contentType === IncomeOutcomeModalContentType.OUTCOME) {
				return i18n.t(
					'translation:billingModule.incomeOutcomeDetails.outcomeDetails.createHeader'
				);
			}

			return i18n.t(
				'translation:billingModule.incomeOutcomeDetails.incomeDetails.createHeader'
			);
		}

		const number = this.props.number;

		if (this.props.contentType === IncomeOutcomeModalContentType.OUTCOME) {
			return i18n.t(
				'translation:billingModule.incomeOutcomeDetails.outcomeDetails.editHeader',
				{
					number
				}
			);
		}

		return i18n.t('translation:billingModule.incomeOutcomeDetails.incomeDetails.editHeader', {
			number
		});
	}

	render() {
		return (
			<Modal
				className="custom-modal full-page-modal income-outcome-modal pl-4"
				overlayClassName="custom-overlay"
				isOpen={this.props.isOpen}>
				<div className="fixed-top p-3">
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={this.props.onClose}
					/>
					<h2>{this.getTitle()}</h2>
				</div>
				<div className="modal-content">
					{this.props.contentType === IncomeOutcomeModalContentType.INCOME && (
						<IncomeDetails
							isCreation={this.props.isCreation}
							onClose={this.props.onClose}
							incomeId={this.props.contentId}
							onDocumentChange={this.props.onDocumentChange}
						/>
					)}
					{this.props.contentType === IncomeOutcomeModalContentType.OUTCOME && (
						<OutcomeDetails
							isCreation={this.props.isCreation}
							onClose={this.props.onClose}
							outcomeId={this.props.contentId}
							onDocumentChange={this.props.onDocumentChange}
						/>
					)}
				</div>
			</Modal>
		);
	}
}

export default IncomeOutcomeModal;
