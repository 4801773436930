import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ShiftTypes from './ShiftsTypes';
import { getGeneralWorkingHours } from '../../../../../actions/taskManager/workingHoursActions';
import { submit } from 'redux-form';
import { getLabDetails, getLabModules } from '../../../../../actions';
import WeeklyWorkingHours from './WeeklyWorkingHours';
import { getLabId, isGlobalAdmin, isTaskManagerEnabled } from '../../../../../utils/appUtils';
import NotFound from '../../../../errors/NotFound';

class WorkingHours extends React.Component {
	UNSAFE_componentWillMount() {
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getLabModules(labId);
		this.props.getGeneralWorkingHours(labId);

		if (isGlobalAdmin(this.props.account)) {
			this.props.getLabDetails(labId);
		}
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <NotFound />;
		}

		const labId = getLabId(this.props.location, this.props.account);

		return (
			<Fragment>
				<WeeklyWorkingHours currentLab={this.props.currentLab} />
				<ShiftTypes account={this.props.account} labId={labId} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		modules: state.modules,
		currentLab: state.labs.currentLab,
		workingHours: state.workingHours
	};
};

export default connect(mapStateToProps, {
	getLabModules,
	getLabDetails,
	getGeneralWorkingHours,
	submit
})(WorkingHours);
