import React from 'react';
import { Link } from 'react-router-dom';
import {
	getName,
	getProfession,
	isClinicAdmin,
	isCompanyActive,
	isCompanyDeactivated,
	isDentistUser,
	isEmptyObject,
	isGlobalAdmin,
	isLabAdmin,
	isLabTechnician,
	isLocalAdmin,
	isLocalInventory,
	isPassiveClinic,
	isPracticeRoute,
	isTaskManagerEnabled,
	parseGlobalProductCategory,
	parsePrice,
	parseRole,
	registeredInClinic,
	truncate
} from './appUtils';
import { createCustomPropsForCurrency, formUtils } from './formUtils';
import Status from '../components/common/Status';
import DocumentStatus from '../components/pages/billingModule/invoicing/billingDocumentModal/sections/common/Status';

import {
	COMPANY_STATUS,
	DEFAULT_TIME_FORMAT,
	GLOBAL_PRODUCT_CATEGORY,
	LANGUAGE_CODES,
	LANGUAGE_DISPLAY_NAMES,
	ORDER_FIELDS,
	PAGES_PATHS,
	PLANNING_STATUS,
	TABLE_ACTIONS,
	TASK_STATUS,
	USER_STATUS,
	WORK_ALLOCATIONS
} from './constants';
import DefaultButton from '../components/common/DefaultButton';
import i18n from '../i18n';
import store from '../store';
import {
	clearReducers,
	createIndividualInventory,
	createOrder,
	deleteClinicLocation,
	deleteItem,
	deleteLabLocation,
	deleteLink,
	deleteLocalTemplates,
	deleteOverdueInterval,
	deleteShift,
	deleteTaxGroup,
	getLabProductTemplate,
	getOrderItemTasks,
	getProductTemplates,
	getTaskData,
	hideMessageModal,
	listPossibleAssignees,
	removeInvitationToUser,
	sendInvitationToUser,
	showMessageModal,
	updateWorkingHours
} from '../actions';
import navigationUtils from './navigationUtils';
import { Fields, tableCheckbox } from '../components/common/Fields';
import moment from './moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBan,
	faCaretDown,
	faCaretUp,
	faCheck,
	faDownload,
	faEdit,
	faExclamationTriangle,
	faEye,
	faInfoCircle,
	faLaptop,
	faLink,
	faListUl,
	faPaperPlane,
	faShoppingCart,
	faTeethOpen,
	faTimes,
	faTooth,
	faTrash,
	faUnlink
} from '@fortawesome/free-solid-svg-icons';
import colorWarningIcon from '../resources/icons/alert-outline.svg';
import nonConsecutiveTeethIcon from '../resources/icons/missing-teeth.svg';
import TableButton from '../components/common/TableButton';
import { addZeroIfOneDigit } from './appUtils.js';
import { Field } from 'redux-form';
import { subtract } from '../components/pages/billingModule/invoicing/billingDocumentModal/utils/documentUtils';

export const renderActionButton = (data) => {
	const buttons = data.map((action, index, className) => {
		if (action) {
			if (action.type === TABLE_ACTIONS.CHECK) {
				return (
					<TableButton
						key={index + 'bv'}
						icon={faCheck}
						onPress={action.action}
						tooltip={
							action.tooltip
								? action.tooltip
								: i18n.t('translation:billingModule.common.table.confirm')
						}
						disabled={action.disabled}
						warning={action.warning}
						danger={action.danger}
						lightButton={action.lightButton}
						className={action.nestedTableButton ? ' nested-table-button' : ''}
					/>
				);
			}
			if (action.type === TABLE_ACTIONS.DOWNLOAD) {
				return (
					<TableButton
						key={index + 'bv'}
						icon={faDownload}
						onPress={action.action}
						tooltip={
							action.tooltip
								? action.tooltip
								: i18n.t('translation:billingModule.common.table.download')
						}
						disabled={action.disabled}
						warning={action.warning}
						danger={action.danger}
						lightButton={action.lightButton}
						className={action.nestedTableButton ? ' nested-table-button' : ''}
					/>
				);
			}
			if (action.type === TABLE_ACTIONS.VIEW) {
				return (
					<TableButton
						key={index + 'bv'}
						icon={faEye}
						onPress={action.action}
						tooltip={
							action.tooltip
								? action.tooltip
								: i18n.t('translation:common.table.view')
						}
						disabled={action.disabled}
						warning={action.warning}
						danger={action.danger}
						lightButton={action.lightButton}
						className={action.nestedTableButton ? ' nested-table-button' : ''}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.ORDER) {
				return (
					<TableButton
						key={index + 'bp'}
						icon={faShoppingCart}
						onPress={action.action}
						tooltip={i18n.t('translation:common.table.order')}
						lightButton={action.lightButton}
						disabled={action.disabled}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.EDIT) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faEdit}
						onPress={action.action}
						lightButton={action.lightButton}
						warning={action.warning}
						danger={action.danger}
						tooltip={
							action.tooltip
								? action.tooltip
								: i18n.t('translation:common.table.edit')
						}
						className={
							action.nestedTableButton
								? action.className
									? action.className + ' nested-table-button'
									: ' nested-table-button'
								: ''
						}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.WARNING) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faExclamationTriangle}
						onPress={action.action}
						warning={action.warning}
						danger={action.danger}
						lightButton={action.lightButton}
						tooltip={action.tooltip}
						className={action.nestedTableButton ? ' nested-table-button' : ''}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.LINK) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faLink}
						danger
						onPress={action.action}
						tooltip={i18n.t('translation:common.table.link')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.UNLINK) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faUnlink}
						danger
						onPress={action.action}
						tooltip={i18n.t('translation:common.table.unlink')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.SEND_AGAIN) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faPaperPlane}
						onPress={action.action}
						tooltip={i18n.t('translation:common.table.sendAgain')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.CANCEL) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faBan}
						danger
						onPress={action.action}
						tooltip={i18n.t('translation:common.table.cancel')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.TEMPLATES_GLOBAL) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faListUl}
						onPress={action.action}
						tooltip={
							action.disabled
								? i18n.t(
										'translation:taskManager.common.buttons.tooltips.noWarrantyAndPrice'
								  )
								: i18n.t(
										'translation:taskManager.common.buttons.tooltips.globalTemplate'
								  )
						}
						disabled={action.disabled}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.TEMPLATES_LOCAL) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faListUl}
						onPress={action.action}
						tooltip={
							action.disabled
								? i18n.t(
										'translation:taskManager.common.buttons.tooltips.noWarrantyAndPrice'
								  )
								: i18n.t(
										'translation:taskManager.common.buttons.tooltips.localTemplates'
								  )
						}
						disabled={action.disabled}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.CLEAR) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faTimes}
						danger
						onPress={action.action}
						tooltip={i18n.t('translation:taskManager.common.table.clear')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.INFO) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faInfoCircle}
						onPress={action.action}
						className="task-info-icon w-auto ml-xs"
						tooltip={i18n.t('translation:taskManager.common.table.generalNote')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.EXPAND) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faCaretDown}
						onPress={action.action}
						className={`task-caret-down-icon w-auto`}
						tooltip={i18n.t('translation:taskManager.common.table.expand')}
					/>
				);
			}

			if (action.type === TABLE_ACTIONS.COLLAPSE) {
				return (
					<TableButton
						key={index + 'bs'}
						icon={faCaretUp}
						onPress={action.action}
						className={`task-caret-down-icon w-auto`}
						tooltip={i18n.t('translation:taskManager.common.table.collapse')}
					/>
				);
			}

			return (
				<TableButton
					key={index + 'b'}
					icon={faTrash}
					danger
					onPress={action.action}
					className={action.className}
					tooltip={i18n.t('translation:common.table.delete')}
				/>
			);
		}
		return null;
	});

	return <div className="table-buttons-container">{buttons}</div>;
};

export const parseUsers = (users) => {
	let refinedUsers = [];

	for (let index in users) {
		const user = users[index];
		const name = <Link to={PAGES_PATHS.USERS + '/' + user.id}>{getName(user)}</Link>;
		const status = <Status status={user.status} />;
		const profession = getProfession(user);
		const organization = user.clinicName || user.labName;
		const currentUser = [name, user.phone, user.email, profession, organization, status];
		refinedUsers.push(currentUser);
	}
	return refinedUsers;
};

export const parseLabStaff = (users) => {
	let refinedUsers = [];

	for (let index in users) {
		const user = users[index];
		const name = <Link to={PAGES_PATHS.USERS + '/' + user.id}>{getName(user)}</Link>;
		const status = <Status status={user.status} />;
		const role = parseRole(user.role);
		const currentUser = [name, user.phone, user.email, role, status];
		refinedUsers.push(currentUser);
	}
	return refinedUsers;
};

export const parseClinicStaff = (users) => {
	let refinedUsers = [];

	for (let index in users) {
		const user = users[index];
		const name = <Link to={PAGES_PATHS.USERS + '/' + user.id}>{getName(user)}</Link>;
		const status = <Status status={user.status} />;
		const currentUser = [name, user.phone, user.email, status];
		refinedUsers.push(currentUser);
	}
	return refinedUsers;
};

export const parseStaffWaitingApproval = (users) => {
	let refinedUsers = [];

	for (let index in users) {
		const user = users[index];
		if (user.status !== USER_STATUS.WAITING_FOR_APPROVAL) {
			continue;
		}
		const name = <Link to={PAGES_PATHS.USERS + '/' + user.id}>{getName(user)}</Link>;
		const status = <Status status={user.status} />;
		const currentUser = [name, user.phone, user.email, status];
		refinedUsers.push(currentUser);
	}
	return refinedUsers;
};

export const parseAdmins = (admins) => {
	let refinedAdmins = [];

	for (let index in admins) {
		const admin = admins[index];
		const name = <Link to={PAGES_PATHS.ADMINISTRATORS + '/' + admin.id}>{getName(admin)}</Link>;
		const status = <Status status={admin.status} />;
		const currentAdmin = [name, admin.phone, admin.email, status];
		refinedAdmins.push(currentAdmin);
	}
	return refinedAdmins;
};

export const parseClinics = (clinics, status) => {
	let refinedClinics = [];
	for (let index in clinics) {
		const clinic = clinics[index];
		const passiveIndicator = isPassiveClinic(clinic) ? (
			<FontAwesomeIcon
				className="passive-clinic-icon"
				title={i18n.t('translation:clinics.passiveClinic')}
				icon={faLaptop}
			/>
		) : null;
		const deactivatedIndicator = isCompanyDeactivated(clinic) ? (
			<FontAwesomeIcon
				className="deactivated-icon"
				title={i18n.t('translation:clinics.deactivatedClinic')}
				icon={faBan}
			/>
		) : null;
		const name = (
			<div
				className="pointer d-inline"
				onClick={() => {
					store.dispatch(clearReducers());
					navigationUtils.navigate(PAGES_PATHS.PRACTICES + '/' + clinic.id);
				}}>
				<span className="link">{clinic.legalName}</span>
				{passiveIndicator || deactivatedIndicator ? <span>&nbsp;&nbsp;&nbsp;</span> : null}
				{passiveIndicator}
				{deactivatedIndicator}
			</div>
		);
		const currentClinic = [
			name,
			clinic.phone,
			clinic.email,
			truncate(clinic.registeredOffice, 60)
		];
		if (status === COMPANY_STATUS.WAITING_FOR_APPROVAL) {
			const status = <Status status={clinic.status} />;
			currentClinic.push(status);
		}
		refinedClinics.push(currentClinic);
	}

	return refinedClinics;
};

export const parseOrderItems = (
	items,
	order,
	showModalWithInitialization,
	colors,
	colorScheme,
	showModalTasksWithInitialization,
	modules
) => {
	let refinedItems = [];

	function onDeleteButtonPress(item) {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					store.dispatch(deleteItem(order.id, item.id));
					store.dispatch(hideMessageModal());
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
				secondary
			/>
		);
		store.dispatch(
			showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:orders.deleteItemWarning'),
				[yesButton, noButton]
			)
		);
	}

	function getColorCode(item) {
		let colorCode = _.find(colors, function (color) {
			return color.id === item.id;
		});
		if (!item.productHasColor) {
			return i18n.t('translation:orders.productWithoutColor');
		} else if (colorCode && colorCode.colorCode) {
			return colorCode.colorCode || i18n.t('translation:orders.noConversion');
		} else {
			return i18n.t('translation:orders.notSpecified');
		}
	}

	for (let index in items) {
		const item = items[index];

		let teeth = [];
		item.teeth.forEach((current) => {
			teeth.push(current.fdiPosition);
		});
		teeth = teeth.join(', ');
		const product = `${item.productType} (${item.productMaterial.toLowerCase()})`;
		const tests =
			item.metalTestDate ||
			item.ceramicTestDate ||
			item.individualSpoonTestDate ||
			item.biteTestDate ||
			item.metalTestDate ? (
				<FontAwesomeIcon
					className="note-icon"
					icon={faCheck}
					title={i18n.t('translation:orders.table.tooltips.tests')}
				/>
			) : (
				<FontAwesomeIcon
					icon={faTimes}
					className="note-icon"
					title={i18n.t('translation:orders.table.tooltips.noTests')}
				/>
			);
		let colorCode = _.find(colors, function (color) {
			return color.id === item.id;
		});
		colorCode = (
			<div>
				<span>{getColorCode(item)}</span>
				{item.colorScheme !== colorScheme &&
				colorCode &&
				colorCode.colorCode &&
				item.productHasColor ? (
					<img
						className="normal-icon-size color-warning-icon"
						title={i18n.t('translation:orders.fields.tooltips.notOriginalColor')}
						src={colorWarningIcon}
						alt="color-warning-icon"
					/>
				) : null}
			</div>
		);
		const notes = item.notes ? (
			<FontAwesomeIcon
				className="note-icon"
				icon={faCheck}
				title={i18n.t('translation:orders.table.tooltips.note')}
			/>
		) : (
			<FontAwesomeIcon
				icon={faTimes}
				className="note-icon"
				title={i18n.t('translation:orders.table.tooltips.noNote')}
			/>
		);
		const currentItem = [
			product,
			colorCode,
			teeth,
			item.quantity,
			notes,
			tests,
			item.warrantyInMonths
		];
		if (order.canAccessPaymentData) {
			const priceValue = item.price;
			const lab = formUtils.getFieldByType(order.fields, ORDER_FIELDS.LAB).value;
			const displayValue = parseInvoicingCurrency(
				priceValue,
				lab.localizedCurrencyAbbreviation,
				lab.currencySymbol
			);
			const taxValue = `${item.taxPercentage}% - ${item.taxName}`;
			const priceWithTax = getItemPriceWithTax(item.price, item.taxPercentage);
			const priceWithTaxValue = parseInvoicingCurrency(
				priceWithTax,
				lab.localizedCurrencyAbbreviation,
				lab.currencySymbol
			);

			let totalPriceWithTax = priceWithTax;
			if (item.productCategory === GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH) {
				totalPriceWithTax = getItemTotalPriceWithTax(priceWithTax, item.quantity);
			}
			const totalPriceWithTaxValue = parseInvoicingCurrency(
				totalPriceWithTax,
				lab.localizedCurrencyAbbreviation,
				lab.currencySymbol
			);

			currentItem.push(displayValue);
			currentItem.push(taxValue);
			currentItem.push(priceWithTaxValue);
			currentItem.push(totalPriceWithTaxValue);
		}

		const deleteButton = order.canAddAndRemoveOrderItems
			? {
					type: TABLE_ACTIONS.DELETE,
					action: () => {
						onDeleteButtonPress(item);
					}
			  }
			: null;
		const editButton = order.canUpdateOrderItems
			? {
					type: TABLE_ACTIONS.EDIT,
					action: () => {
						showModalWithInitialization(item);
					}
			  }
			: null;
		const viewButton = !order.canUpdateOrderItems
			? {
					type: TABLE_ACTIONS.VIEW,
					action: () => {
						showModalWithInitialization(item);
					}
			  }
			: null;

		const tasksButton =
			order.canUpdateOrderItemTasks && isTaskManagerEnabled(modules)
				? {
						type: TABLE_ACTIONS.TEMPLATES_LOCAL,
						action: () => {
							const labId = formUtils.getFieldByType(order.fields, ORDER_FIELDS.LAB)
								.value.id;
							store.dispatch(
								getProductTemplates(labId, order.orderItems[index].productId)
							);
							// open task modal
							setTimeout(() => {
								let data = {
									taskHasDuration: false,
									startTime: null,
									endTime: null
								};

								store.dispatch(listPossibleAssignees(data));
								store.dispatch(
									getOrderItemTasks(order.id, order.orderItems[index].id)
								);
							}, 500);

							setTimeout(() => {
								showModalTasksWithInitialization(item);
							}, 500);
						}
				  }
				: null;

		currentItem.push([editButton, viewButton, deleteButton, tasksButton]);

		refinedItems.push(currentItem);
	}
	return refinedItems;
};

const calculateOrderItemPrice = (item) => {
	let price = item.price;
	if (item.productCategory === GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH && item.quantity > 0) {
		price = item.quantity * item.price;
	}

	return parsePrice(price);
};

export const parseGlobalPhases = (phases, onDeletePhase) => {
	let refinedPhases = [];
	for (let index in phases) {
		const phase = phases[index];
		const currentPhase = [];
		const id = phase.id;
		currentPhase.push(
			Fields.templateFields.nameGlobal({
				id,
				language: 'BG',
				className: 'first phase-name',
				noLabel: true,
				noErrorMessage: true
			})
		);
		currentPhase.push(
			Fields.templateFields.nameGlobal({
				id,
				language: 'DE',
				className: 'first phase-name',
				noLabel: true,
				noErrorMessage: true
			})
		);
		currentPhase.push(
			Fields.templateFields.nameGlobal({
				id,
				language: 'GB',
				className: 'first phase-name',
				noLabel: true,
				noErrorMessage: true
			})
		);
		currentPhase.push(
			Fields.templateFields.duration({
				id,
				className: 'first',
				noLabel: true,
				noErrorMessage: true
			})
		);
		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeletePhase(id);
			}
		};
		currentPhase.push([deleteButton]);

		refinedPhases.push(currentPhase);
	}

	return refinedPhases;
};

export const parseLocalPhases = (phases, onDeletePhase) => {
	let refinedPhases = [];
	for (let index in phases) {
		const phase = phases[index];
		const currentPhase = [];
		const id = phase.id;
		currentPhase.push(
			Fields.templateFields.nameLocal({
				id,
				className: 'first phase-name',
				noLabel: true,
				noErrorMessage: true
			})
		);
		currentPhase.push(
			Fields.templateFields.duration({
				id,
				className: 'first',
				noLabel: true,
				noErrorMessage: true
			})
		);
		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeletePhase(id);
			}
		};
		currentPhase.push([deleteButton]);

		refinedPhases.push(currentPhase);
	}

	return refinedPhases;
};

export const parseClients = (clientLinks, isLabAdmin, labId, renderInvoiceDueDays) => {
	let refinedClients = [];

	function onEditButtonPress(client) {
		if (client.isIndividualPriceListEnabled === null) {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						store.dispatch(hideMessageModal());
						store
							.dispatch(createIndividualInventory(labId, client.clinic.id))
							.then((response) => {
								navigationUtils.navigate(
									PAGES_PATHS.CLIENT_PRICE_LIST,
									{},
									{ id: client.clinic.id }
								);
							});
					}}
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						store.dispatch(hideMessageModal());
					}}
					secondary
				/>
			);
			store.dispatch(
				showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:clinics.createPriceListWarning'),
					[yesButton, noButton]
				)
			);
		} else {
			navigationUtils.navigate(PAGES_PATHS.CLIENT_PRICE_LIST, {}, { id: client.clinic.id });
		}
	}

	for (let index in clientLinks) {
		const client = clientLinks[index]['clinic'];

		const passiveIndicator = isPassiveClinic(client) ? (
			<FontAwesomeIcon
				className="passive-clinic-icon"
				title={i18n.t('translation:clinics.passiveClinic')}
				icon={faLaptop}
			/>
		) : null;
		const deactivatedIndicator = isCompanyDeactivated(client) ? (
			<FontAwesomeIcon
				className="deactivated-icon"
				title={i18n.t('translation:clinics.deactivatedClinic')}
				icon={faBan}
			/>
		) : null;

		const name = (
			<div
				className="pointer d-inline"
				onClick={() => {
					navigationUtils.navigate(PAGES_PATHS.CLIENTS + '/' + client.id);
				}}>
				<span className="link">{client.name}</span>
				{passiveIndicator || deactivatedIndicator ? <span>&nbsp;&nbsp;&nbsp;</span> : null}
				{passiveIndicator}
				{deactivatedIndicator}
			</div>
		);

		const currentClient = [
			name,
			client.phone,
			client.email,
			truncate(client.registeredOffice, 60)
		];
		if (isLabAdmin) {
			const editButton = {
				type: TABLE_ACTIONS.EDIT,
				action: () => {
					onEditButtonPress(clientLinks[index]);
				}
			};

			currentClient.push([editButton]);
			currentClient.push(
				Fields.tableFields.tableElementPriceList(clientLinks[index].id, {
					disabled: clientLinks[index].isIndividualPriceListEnabled === null
				})
			);
			currentClient.push(
				Fields.tableFields.tableElementDiscount(clientLinks[index].id, {
					className: 'small-input'
				})
			);

			if (renderInvoiceDueDays) {
				currentClient.push(
					Fields.tableFields.tableElementInvoiceDueDays(
						clientLinks[index].id,
						i18n.t('translation:clinics.table.days'),
						{ className: 'small-input left-align-input' }
					)
				);
			}
		} else {
			const viewButton = {
				type: TABLE_ACTIONS.VIEW,
				action: () => {
					navigationUtils.navigate(PAGES_PATHS.CLIENT_PRICE_LIST, {}, { id: client.id });
				}
			};

			currentClient.push([viewButton]);
			currentClient.push(
				Fields.tableFields.tableElementPriceList(clientLinks[index].id, {
					disabled: true
				})
			);
		}

		refinedClients.push(currentClient);
	}

	return refinedClients;
};

export const parseSuppliers = (suppliers) => {
	let clinicId = store.getState().account.clinicId;

	let refinedSuppliers = [];
	for (let index in suppliers) {
		const supplier = suppliers[index]['lab'];
		const discount = suppliers[index].discountPercentage
			? suppliers[index].discountPercentage + ' %'
			: '';
		const orderButton = {
			type: TABLE_ACTIONS.ORDER,
			disabled: !isCompanyActive(supplier),
			action: () => {
				store.dispatch(createOrder(clinicId, supplier.id));
			}
		};

		const deactivatedIndicator = isCompanyDeactivated(supplier) ? (
			<FontAwesomeIcon
				className="deactivated-icon"
				title={i18n.t('translation:labs.deactivatedLab')}
				icon={faBan}
			/>
		) : null;

		const name = (
			<div
				className="pointer d-inline"
				onClick={() => {
					navigationUtils.navigate(PAGES_PATHS.SUPPLIERS + '/' + supplier.id);
				}}>
				<span className="link">{supplier.legalName}</span>
				{deactivatedIndicator ? <span>&nbsp;&nbsp;&nbsp;</span> : null}
				{deactivatedIndicator}
			</div>
		);

		const currentSupplier = [
			name,
			supplier.phone,
			supplier.email,
			truncate(supplier.registeredOffice, 60)
		];
		if (!isDentistUser(store.getState().account)) {
			currentSupplier.push(discount);
		}
		currentSupplier.push([orderButton]);
		refinedSuppliers.push(currentSupplier);
	}

	return refinedSuppliers;
};

export const parseLabs = (labs, status) => {
	let refinedLabs = [];

	for (let index in labs) {
		const lab = labs[index];
		const deactivatedIndicator = isCompanyDeactivated(lab) ? (
			<FontAwesomeIcon
				className="deactivated-icon"
				title={i18n.t('translation:labs.deactivatedLab')}
				icon={faBan}
			/>
		) : null;

		const name = (
			<div
				className="pointer d-inline"
				onClick={() => {
					store.dispatch(clearReducers());
					navigationUtils.navigate(PAGES_PATHS.LABORATORIES + '/' + lab.id);
				}}>
				<span className="link">{lab.legalName}</span>
				{deactivatedIndicator ? <span>&nbsp;&nbsp;&nbsp;</span> : null}
				{deactivatedIndicator}
			</div>
		);

		const currentLab = [
			name,
			lab.phone,
			lab.email,
			truncate(lab.registeredOffice, 60),
			lab.currencyCode
		];

		if (status === COMPANY_STATUS.WAITING_FOR_APPROVAL) {
			const status = <Status status={lab.status} />;
			currentLab.push(status);
		}

		refinedLabs.push(currentLab);
	}
	return refinedLabs;
};

export const parseLocations = (
	locations,
	canEditLocations,
	isPassiveClinic,
	showModalWithInitialization
) => {
	let refinedLocations = [];
	const { account, clinics } = store.getState();
	const companyId = getCompanyId(account, clinics.currentClinic, isPassiveClinic);

	function onDeleteButtonPress(location) {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					if (isPassiveClinic) {
						store.dispatch(deleteClinicLocation(companyId, location.id));
					} else if (isClinicAdmin(account)) {
						store.dispatch(deleteClinicLocation(companyId, location.id));
					} else {
						store.dispatch(deleteLabLocation(companyId, location.id));
					}
					store.dispatch(hideMessageModal());
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
				secondary
			/>
		);
		store.dispatch(
			showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:common.deleteLocationWarning'),
				[yesButton, noButton]
			)
		);
	}

	for (let index in locations) {
		const location = locations[index];
		const currentLocation = [
			location.name,
			truncate(location.address),
			location.phone,
			location.email
		];
		if (canEditLocations) {
			const deleteButton = {
				type: TABLE_ACTIONS.DELETE,
				action: () => {
					onDeleteButtonPress(location);
				}
			};
			const editButton = {
				type: TABLE_ACTIONS.EDIT,
				action: () => {
					showModalWithInitialization(location);
				}
			};
			currentLocation.push([editButton, deleteButton]);
		}
		refinedLocations.push(currentLocation);
	}
	return refinedLocations;
};

const getCompanyId = (account, clinicData, isPassiveClinic) => {
	if (isGlobalAdmin(account) || (isLabAdmin(account) && isPassiveClinic)) {
		// The complicated condition that is satisfied here means that
		// we are adding/updating location of a passive clinic
		return clinicData.id;
	} else {
		return registeredInClinic(account) ? account.clinicId : account.labId;
	}
};

export const parseLinkedCompanies = (companyLinks) => {
	let refinedCompanies = [];

	function onActionButtonPress(name, id) {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					store.dispatch(deleteLink(id));

					store.dispatch(hideMessageModal());
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
				secondary
			/>
		);
		store.dispatch(
			showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:common.unlinkCompanyWarning', { word1: name }),
				[yesButton, noButton]
			)
		);
	}

	const companyType = isPracticeRoute(navigationUtils.getRoute()) ? 'lab' : 'clinic';

	for (let index in companyLinks) {
		let companyLink = companyLinks[index][companyType];
		const route = isPracticeRoute(navigationUtils.getRoute())
			? PAGES_PATHS.LABORATORIES + '/' + companyLink.id
			: PAGES_PATHS.PRACTICES + '/' + companyLink.id;
		const name = <Link to={route}>{companyLink.legalName}</Link>;
		const deleteButton = {
			type: TABLE_ACTIONS.UNLINK,
			action: () => {
				onActionButtonPress(companyLink.legalName, companyLinks[index].id);
			}
		};
		const currentCompany = [
			name,
			truncate(companyLink.registeredOffice, 60),
			companyLink.phone,
			companyLink.email,
			[deleteButton]
		];
		refinedCompanies.push(currentCompany);
	}
	return refinedCompanies;
};

export const parseCompaniesThatSentInvitations = (
	invitations,
	areClinics,
	acceptLinkInvitationCallback
) => {
	const pathPart = areClinics ? PAGES_PATHS.CLIENTS : PAGES_PATHS.SUPPLIERS;

	let rows = [];
	for (const invitation of invitations) {
		const company = areClinics ? invitation.clinic : invitation.lab;

		const deactivatedTooltip = areClinics
			? i18n.t('translation:clinics.deactivatedClinic')
			: i18n.t('translation:labs.deactivatedLab');
		const deactivatedIndicator = isCompanyDeactivated(company) ? (
			<FontAwesomeIcon className="deactivated-icon" title={deactivatedTooltip} icon={faBan} />
		) : null;

		const name = (
			<div
				className="pointer d-inline"
				onClick={() => {
					const url = pathPart + '/' + company.id;
					navigationUtils.navigate(url);
				}}>
				<span className="link">{company.name}</span>
				{deactivatedIndicator ? <span>&nbsp;&nbsp;&nbsp;</span> : null}
				{deactivatedIndicator}
			</div>
		);

		const currentRow = [
			name,
			company.phone,
			company.email,
			truncate(company.registeredOffice, 60)
		];
		if (!areClinics) {
			currentRow.push(company.currencyCode);
		}

		const linkButton = {
			type: TABLE_ACTIONS.LINK,
			action: () => {
				acceptLinkInvitationCallback(invitation.id, areClinics);
			}
		};

		currentRow.push([linkButton]);

		rows.push(currentRow);
	}

	return rows;
};

export const parseSentInvitationsToUsers = (invitations) => {
	function onCancelInvitation(email) {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					store.dispatch(removeInvitationToUser(email));
					store.dispatch(hideMessageModal());
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
				secondary
			/>
		);

		store.dispatch(
			showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:common.warnings.cancelInvitationToUser'),
				[yesButton, noButton]
			)
		);
	}

	let rows = [];
	for (const invitation of invitations) {
		const { email, createDate, languageCode } = invitation;
		const reSendingInvitation = true;
		const currentRow = [email, createDate];

		const sendAgainButton = {
			type: TABLE_ACTIONS.SEND_AGAIN,
			action: () =>
				store.dispatch(sendInvitationToUser(email, languageCode, reSendingInvitation))
		};

		const cancelButton = {
			type: TABLE_ACTIONS.CANCEL,
			action: () => onCancelInvitation(email)
		};

		currentRow.push([sendAgainButton, cancelButton]);

		rows.push(currentRow);
	}

	return rows;
};

export const parseHistory = (history) => {
	let refinedHistory = [];

	for (let index in history) {
		const item = history[index];
		const createDate = item.createDate
			? moment(item.createDate).format('L, LT')
			: i18n.t('translation:common.notSet');
		const description = item.changes;
		const descriptionTitle =
			description.length > 1 ? i18n.t('translation:orders.changedParams') : description[0];
		const descriptionList =
			description.length > 1 ? (
				<ul>
					{description.map((item, noteIndex) => {
						return <li key={'history' + index + noteIndex}>{item}</li>;
					})}
				</ul>
			) : null;
		const descriptionView = (
			<div>
				{descriptionTitle}
				{descriptionList}
			</div>
		);
		const currentItem = [createDate, item.authorName, descriptionView];

		refinedHistory.push(currentItem);
	}
	return refinedHistory;
};

export const prepareSingleGlobalProductForLanguageTable = () => {
	return [
		[
			LANGUAGE_DISPLAY_NAMES.BG,
			Fields.tableFields.tableElementProductType(LANGUAGE_CODES.BG),
			Fields.tableFields.tableElementProductMaterial(LANGUAGE_CODES.BG)
		],
		[
			LANGUAGE_DISPLAY_NAMES.DE,
			Fields.tableFields.tableElementProductType(LANGUAGE_CODES.DE),
			Fields.tableFields.tableElementProductMaterial(LANGUAGE_CODES.DE)
		],
		[
			LANGUAGE_DISPLAY_NAMES.EN,
			Fields.tableFields.tableElementProductType(LANGUAGE_CODES.EN),
			Fields.tableFields.tableElementProductMaterial(LANGUAGE_CODES.EN)
		],
		[
			LANGUAGE_DISPLAY_NAMES.TR,
			Fields.tableFields.tableElementProductType(LANGUAGE_CODES.TR),
			Fields.tableFields.tableElementProductMaterial(LANGUAGE_CODES.TR)
		]
	];
};

const topUp = (currentPrice, price, currencyProps) => {
	if (currentPrice > 0 && price && currentPrice != price) {
		const prefix = currencyProps.currencyPrefix;
		const suffix = currencyProps.currencySuffix;
		const currentPriceClassName =
			price > currentPrice ? 'current-price red-text' : 'current-price green-text';
		price = prefix + price + suffix;
		currentPrice = prefix + currentPrice + suffix;
		return (
			<span className="top-up">
				<span className={currentPriceClassName}>{currentPrice}</span>&nbsp;
				<span className="price">{price}</span>
			</span>
		);
	} else {
		return null;
	}
};

export const parseInventory = (
	inventory,
	account,
	lab,
	formValues,
	isTaskManagerEnabled,
	isBillingModuleEnabled,
	taxGroups
) => {
	let refinedInventory = [];
	const individualPriceListRoute = navigationUtils.getRoute().split('/')[3];

	for (let index in inventory) {
		const item = inventory[index];
		const currentItem = [
			item.catalogNumber ? item.catalogNumber : i18n.t('translation:common.notSet'),
			item.type,
			item.material,
			parseGlobalProductCategory(item.category)
		];
		const hasColor = item.hasColor ? (
			<FontAwesomeIcon
				className="note-icon"
				icon={faCheck}
				title={i18n.t('translation:inventory.table.tooltips.hasColor')}
			/>
		) : (
			<FontAwesomeIcon
				icon={faTimes}
				className="note-icon"
				title={i18n.t('translation:inventory.table.tooltips.noColor')}
			/>
		);

		if (isLabAdmin(account)) {
			currentItem[0] = Fields.tableFields.tableElementCatalogNumber(item.id, {
				maxLength: 32
			});
			currentItem.push(hasColor);
			currentItem.push(Fields.tableFields.tableElementWarranty(item.id));

			let topUpView;

			if (!isLocalInventory(navigationUtils.getRoute())) {
				topUpView = topUp(
					formValues[`price_${item.id}`],
					item.basePrice,
					createCustomPropsForCurrency(lab)
				);
			}

			currentItem.push(
				<div className={'d-flex align-items-center'}>
					{Fields.tableFields.tableElementPrice(
						item.id,
						createCustomPropsForCurrency(lab)
					)}
					<div className={'ml-2'}>{topUpView}</div>
				</div>
			);

			currentItem.push(
				Fields.tableFields.tableElementTaxGroup(item.id, {
					noLabel: true,
					options: taxGroups
				})
			);
		}

		if (registeredInClinic(account) && isLocalAdmin(account)) {
			currentItem.push(item.warrantyInMonths);

			const priceValue = parseFloat(item.price.toFixed(2));
			const displayValue = preparePriceForDisplay(priceValue, lab);
			currentItem.push(displayValue);
		}

		// if (isLocalInventory(navigationUtils.getRoute()) && isLabAdmin(account)) {
		// 	currentItem.push(
		// 		Fields.tableFields.tableElementTaxGroup(item.id, {
		// 			noLabel: true,
		// 			options: taxGroups
		// 		})
		// 	);
		// }

		if (
			isLocalInventory(navigationUtils.getRoute()) &&
			isLabAdmin(account) &&
			isTaskManagerEnabled
		) {
			const phasesButton = {
				disabled: false,
				type: TABLE_ACTIONS.TEMPLATES_LOCAL,
				action: () => {
					navigationUtils.navigate(PAGES_PATHS.LOCAL_TEMPLATES, null, {
						id: item.id
					});
				}
			};
			currentItem.push([phasesButton]);
		}

		refinedInventory.push(currentItem);
	}
	return refinedInventory;
};

export const parseOrders = (orders, account, isFilterPage) => {
	let refinedOrders = [];
	for (let index in orders) {
		const order = orders[index];
		let currentOrder;
		const number = (
			<Link to={PAGES_PATHS.ORDERS + '/' + order.id}>
				{prepareIntForDisplay(order.labOrderId)}
			</Link>
		);
		const createDate = order.createDate
			? moment(order.createDate).format('L')
			: i18n.t('translation:common.notSet');
		const deliveryDate = order.deliveryDate
			? moment(order.deliveryDate).format('L')
			: i18n.t('translation:common.notSet');
		const paymentDueDate = order.paymentDueDate
			? moment(order.paymentDueDate).format('L')
			: i18n.t('translation:common.notSet');
		const status = <Status status={order.status} />;
		const labTechnicianName = order.labTechnicianName ? (
			<Link to={PAGES_PATHS.USERS + '/' + order.labTechnicianId}>
				{order.labTechnicianName}
			</Link>
		) : (
			i18n.t('translation:common.notSet')
		);
		const dentistName = (
			<Link to={PAGES_PATHS.USERS + '/' + order.dentistId}>{order.dentistName}</Link>
		);
		if (isLabTechnician(account)) {
			const clinicName = <span>{order.clinicName}</span>;
			currentOrder = [
				number,
				clinicName,
				labTechnicianName,
				createDate,
				deliveryDate,
				status
			];
			if (!isFilterPage) {
				_.remove(currentOrder, function (el) {
					return el === labTechnicianName;
				});
			}
		}
		if (isLabAdmin(account)) {
			const clinicName = (
				<Link to={PAGES_PATHS.CLIENTS + '/' + order.clinicId}>{order.clinicName}</Link>
			);
			currentOrder = [
				number,
				clinicName,
				labTechnicianName,
				createDate,
				deliveryDate,
				paymentDueDate,
				status,
				parseInvoicingCurrency(
					order.price,
					order.localizedCurrencyAbbreviation,
					order.currencySymbol
				)
			];
		}
		if (registeredInClinic(account)) {
			const labName = (
				<Link to={PAGES_PATHS.SUPPLIERS + '/' + order.labId}>{order.labName}</Link>
			);
			if (isLocalAdmin(account)) {
				currentOrder = [
					number,
					labName,
					dentistName,
					createDate,
					deliveryDate,
					paymentDueDate,
					status,
					parseInvoicingCurrency(
						order.price,
						order.localizedCurrencyAbbreviation,
						order.currencySymbol
					)
				];
			} else {
				currentOrder = [number, labName, dentistName, createDate, deliveryDate, status];
			}
		}
		if (isGlobalAdmin(account)) {
			const labName = (
				<Link to={PAGES_PATHS.LABORATORIES + '/' + order.labId}>{order.labLegalName}</Link>
			);
			const clinicName = (
				<Link to={PAGES_PATHS.PRACTICES + '/' + order.clinicId}>
					{order.clinicLegalName}
				</Link>
			);
			currentOrder = [
				number,
				clinicName,
				dentistName,
				labName,
				labTechnicianName,
				createDate,
				status
			];
		}
		refinedOrders.push(currentOrder);
	}
	return refinedOrders;
};

export const prepareIntForDisplay = (intNumber) => {
	if (!intNumber) {
		return null;
	}

	return parseInt(intNumber).toLocaleString(navigator.language, {
		minimumFractionDigits: 0
	});
};

export const prepareFloatForDisplay = (floatNumber, zeroAccepted) => {
	if (!floatNumber && !zeroAccepted) {
		return null;
	}

	return parseFloat(floatNumber).toLocaleString(navigator.language, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
};

export const parseInvoicingCurrency = (
	currency,
	localizedCurrencyAbbreviation,
	currencySymbol,
	prefix
) => {
	if (
		currency === undefined ||
		currency === null ||
		currency.toString().trim().length === 0 ||
		currency.toString().trim() === '_' //some empty tax options values are equal to " _ "
	) {
		currency = 0;
	}

	const isNegative = Number(currency) < 0;

	let valueToDisplay = currency;

	if (isNegative) {
		//make it positive
		valueToDisplay = Number(currency) * -1;
	}

	//format
	valueToDisplay = Number(
		Number(parseFloat(valueToDisplay.toString())).toFixed(2)
	).toLocaleString(navigator.language, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});

	if (isNegative) {
		valueToDisplay = `- ${valueToDisplay}`;
	}

	let style = { whiteSpace: 'nowrap' };

	if (isNegative) {
		style = { color: 'red', whiteSpace: 'nowrap' };
	}

	if (localizedCurrencyAbbreviation) {
		if (prefix) {
			return (
				<span
					style={
						style
					}>{`${prefix}${valueToDisplay} ${localizedCurrencyAbbreviation}`}</span>
			);
		}
		return <span style={style}>{`${valueToDisplay} ${localizedCurrencyAbbreviation}`}</span>;
	}
	if (currencySymbol) {
		if (prefix) {
			return <span style={style}>{`${prefix}${currencySymbol} ${valueToDisplay}`}</span>;
		}
		return <span style={style}>{`${currencySymbol} ${valueToDisplay}`}</span>;
	}
	if (prefix) {
		return <span style={style}>{`${prefix}${valueToDisplay}`}</span>;
	}

	return <span style={style}>{valueToDisplay}</span>;
};

export const preparePercentForDisplay = (percent) => {
	if (!percent) {
		return 0;
	}

	let roundedPercent = Number(Number(percent).toFixed(2));

	const percentIntegerPart = Math.trunc(percent);
	let roundedPercentReminder = 0;
	if (percentIntegerPart) {
		roundedPercentReminder = roundedPercent % percentIntegerPart;
	}
	if (roundedPercentReminder > 0) {
		return roundedPercent;
	}
	if (percentIntegerPart) {
		return percentIntegerPart;
	}
	return roundedPercent;
};

export const preparePriceForDisplay = (floatNumber, lab, zeroAccepted) => {
	if (!floatNumber && !zeroAccepted) {
		return null;
	}

	if (floatNumber === undefined || floatNumber === null) {
		return null;
	}

	let valueToDisplay = prepareFloatForDisplay(floatNumber, zeroAccepted);
	if (lab) {
		if (isEmptyObject(lab.currencySymbol)) {
			valueToDisplay = valueToDisplay + ' ' + lab.localizedCurrencyAbbreviation;
		} else {
			valueToDisplay = lab.currencySymbol + ' ' + valueToDisplay;
		}
	}

	return valueToDisplay;
};

export const preparePriceForDisplayWithCurrency = (
	floatNumber,
	currencySymbol,
	currencyAbbreviation
) => {
	if (!floatNumber) {
		return null;
	}

	let valueToDisplay = prepareFloatForDisplay(floatNumber);
	if (isEmptyObject(currencySymbol)) {
		valueToDisplay = valueToDisplay + ' ' + currencyAbbreviation;
	} else {
		valueToDisplay = currencySymbol + valueToDisplay;
	}

	return valueToDisplay;
};

export const renderSimpleTable = (data, customClass = '', header = null, renderNoItemsMessage) => {
	const tableClass = customClass ? `${customClass} table` : 'table';
	return (
		<div>
			<table
				className={tableClass}
				style={{
					width: '100%',
					tableLayout: 'fixed'
				}}>
				{renderHeader(header)}
				<tbody>{renderData(data)}</tbody>
			</table>
		</div>
	);

	function renderHeader(header) {
		if (header) {
			return (
				<thead>
					<tr>
						{header.map((data, index) => (
							<th key={index + 'headC'} style={data.style} {...data.props}>
								{data.content}
							</th>
						))}
					</tr>
				</thead>
			);
		}

		return null;
	}

	function renderData(data) {
		if (data.length === 0 && renderNoItemsMessage && header) {
			return (
				<tr key={'keyr'} className="w-100" aria-colspan={header.length}>
					<td className="text-center w-100" colSpan={header.length}>
						{renderNoItemsMessage}
					</td>
				</tr>
			);
		}

		return data.map((dataRow, index) => {
			return <tr key={index + 'r'}>{renderRow(dataRow, index)}</tr>;
		});
	}

	function renderRow(dataRow, rowIndex) {
		return dataRow.map((data, index) => {
			const tableElementStyle =
				index === 0 ? 'pl-xl-4' : index === dataRow.length - 1 ? 'pr-xl-4' : null;
			return (
				<td className={tableElementStyle} key={`${rowIndex}${index}d`}>
					{data}
				</td>
			);
		});
	}
};

export const renderOrderItemCategoryIcon = (itemCategory) => {
	var icon = null;
	var iconToolTip = null;
	switch (itemCategory) {
		case GLOBAL_PRODUCT_CATEGORY.CONSECUTIVE_TEETH:
			icon = <FontAwesomeIcon icon={icon} title={iconToolTip} />;
			icon = faTooth;
			iconToolTip = i18n.t('translation:orders.fields.tooltips.consecutiveTeeth');
			break;

		case GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE:
			return (
				<img
					className="elongated-icon-size default-svg-icon-color"
					title={i18n.t('translation:orders.fields.tooltips.constantPrice')}
					src={nonConsecutiveTeethIcon}
					alt=""
				/>
			);

		case GLOBAL_PRODUCT_CATEGORY.CONSTANT_PRICE_CONSECUTIVE_TEETH:
			icon = faTeethOpen;
			iconToolTip = i18n.t(
				'translation:orders.fields.tooltips.constantPriceConsecutiveTeeth'
			);
			break;

		default:
			return null;
	}

	return (
		<FontAwesomeIcon
			icon={icon}
			title={iconToolTip}
			className="font-awesome-normal-icon-size"
		/>
	);
};

export const parseTemplates = (templates, openModal, productId, labId) => {
	const data = templates.map((template, index) => {
		const phases = template.phases.map((phase) => {
			return [phase.name, phase.duration, '', '', ''];
		});

		const isGlobalTemplate = template.isGlobalTemplate
			? i18n.t('translation:taskManager.inventory.table.global')
			: i18n.t('translation:taskManager.inventory.table.local');

		const isDefault = template.isDefault ? (
			<FontAwesomeIcon className="note-icon white" icon={faCheck} />
		) : (
			<FontAwesomeIcon icon={faTimes} className="note-icon white" />
		);

		const editButton = {
			type: TABLE_ACTIONS.EDIT,
			action: () => {
				openModal(template);
			}
		};

		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeleteButtonPress(template);
			}
		};

		function onDeleteButtonPress(item) {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						store.dispatch(deleteLocalTemplates(item.id, labId, productId, false));
						store.dispatch(hideMessageModal());
					}}
					danger
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						store.dispatch(hideMessageModal());
					}}
					secondary
				/>
			);
			store.dispatch(
				showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:taskManager.inventory.deleteTemplateWarning'),
					[yesButton, noButton]
				)
			);
		}

		const buttons = template.isGlobalTemplate ? [] : [editButton, deleteButton];

		return [
			phases,
			template.name,
			template.totalDuration,
			isGlobalTemplate,
			isDefault,
			buttons
		];
	});

	return data;
};

export const parseCalendarOrdersNestedTable = (
	orders,
	openQuickViewModal,
	openEditModal,
	account
) => {
	const data = orders.map((order, i) => {
		const orderStatus = <Status status={order.status} calendarStatus />;
		const items = order.orderItems
			? order.orderItems.map((item, index) => {
					const tasks = item.tasks
						? item.tasks.map((task, index) => {
								const tooltip =
									task.planningStatus === PLANNING_STATUS.NOT_PLANNED
										? i18n.t(
												'translation:taskManager.common.buttons.tooltips.editNotPlanned'
										  )
										: task.planningStatus === PLANNING_STATUS.OVERDUE
										? i18n.t(
												'translation:taskManager.common.buttons.tooltips.editOverdue'
										  )
										: '';
								const taskStatus = <Status status={task.status} />;
								const editButton = {
									tooltip,
									nestedTableButton: true,
									danger: task.planningStatus === PLANNING_STATUS.OVERDUE,
									warning: task.planningStatus === PLANNING_STATUS.NOT_PLANNED,
									type: TABLE_ACTIONS.EDIT,
									action: () => {
										// Open modal
										const orderItemId = item.id;
										const templateId = item.templateId;
										console.log('Open EDIT TASK modal');

										if (orderItemId && templateId && templateId !== 0) {
											store.dispatch(
												getLabProductTemplate(orderItemId, templateId)
											);
										}
										store.dispatch(
											getTaskData(task.id, (currentTask) => {
												openEditModal(currentTask, item, order);
											})
										);
									}
								};

								return [
									task.title,
									task.startTime
										? moment(task.startTime).format('YYYY-MM-DD HH:mm')
										: '',
									task.endTime
										? moment(task.endTime).format('YYYY-MM-DD HH:mm')
										: '',
									task.duration,
									taskStatus,
									[editButton]
								];
						  })
						: [];
					if (order.id === 599) {
						// Medzhit - eslint error because it is empty
					}
					const tooltip =
						item.planningStatus === PLANNING_STATUS.NOT_PLANNED
							? i18n.t(
									'translation:taskManager.common.buttons.tooltips.alertNotPlanned'
							  )
							: item.planningStatus === PLANNING_STATUS.OVERDUE
							? i18n.t('translation:taskManager.common.buttons.tooltips.alertOverdue')
							: '';
					const indicatorButton =
						item.planningStatus === PLANNING_STATUS.NOT_PLANNED
							? {
									tooltip,
									warning: true,
									nestedTableButton: true,
									type: TABLE_ACTIONS.WARNING,
									action: () => {}
							  }
							: item.planningStatus === PLANNING_STATUS.OVERDUE
							? {
									tooltip,
									nestedTableButton: true,
									danger: true,
									type: TABLE_ACTIONS.WARNING,
									action: () => {}
							  }
							: null;
					return [
						tasks,
						item.name,
						item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
						item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
						item.duration,
						'',
						[indicatorButton]
					];
			  })
			: [];
		const tooltip =
			order.planningStatus === PLANNING_STATUS.NOT_PLANNED
				? i18n.t('translation:taskManager.common.buttons.tooltips.viewNotPlanned')
				: order.planningStatus === PLANNING_STATUS.OVERDUE
				? i18n.t('translation:taskManager.common.buttons.tooltips.viewOverdue')
				: '';
		const viewButton =
			order.planningStatus === PLANNING_STATUS.NOT_PLANNED
				? {
						tooltip,
						warning: true,
						nestedTableButton: true,
						lightButton: true,
						type: TABLE_ACTIONS.VIEW,
						action: () => {
							openQuickViewModal(order);
						}
				  }
				: order.planningStatus === PLANNING_STATUS.OVERDUE
				? {
						tooltip,
						nestedTableButton: true,
						danger: true,
						lightButton: true,
						type: TABLE_ACTIONS.VIEW,
						action: () => {
							openQuickViewModal(order);
						}
				  }
				: {
						tooltip,
						lightButton: true,
						nestedTableButton: true,
						type: TABLE_ACTIONS.VIEW,
						action: () => {
							openQuickViewModal(order);
						}
				  };
		const name = (
			<Link to={PAGES_PATHS.ORDERS + `/${order.id}`}>
				{i18n.t('translation:orders.orderNumber', { word1: order.labOrderId })}
			</Link>
		);
		return [
			items,
			name,
			order.startTime && order.hasTasks
				? moment(order.startTime).format('YYYY-MM-DD HH:mm')
				: '',
			order.endTime && order.hasTasks ? moment(order.endTime).format('YYYY-MM-DD HH:mm') : '',
			order.duration,
			orderStatus,
			[viewButton]
		];
	});

	return data;
};

export const parseStaffNestedTable = (staff, openEditTaskModal) => {
	const data = staff.map((staff, index) => {
		const tasks = staff.tasks
			? staff.tasks.map((task) => {
					const editButton = {
						nestedTableButton: true,
						type: TABLE_ACTIONS.EDIT,
						action: () => {
							// Open modal
							console.log('Open edit');

							store
								.dispatch(
									getTaskData(task.id, (currentTask) => {
										let updatedTask = {
											...task,
											...currentTask
										};
										openEditTaskModal(updatedTask);
									})
								)
								.then((response) => {
									const orderItemId = response.data.orderItemId;
									const templateId = response.data.templateId;
									store.dispatch(getLabProductTemplate(orderItemId, templateId));
								});
						}
					};
					return [
						i18n.t('translation:taskManager.common.number') + task.name,
						task.totalDuration,
						'',
						[editButton]
					];
			  })
			: [];

		const tooltip =
			staff.workAllocation === WORK_ALLOCATIONS.UNDERUSED
				? i18n.t('translation:taskManager.calendar.staff.undertime')
				: WORK_ALLOCATIONS.OUTSIDE_ASSIGNED
				? i18n.t('translation:taskManager.calendar.staff.outsideAssigned')
				: i18n.t('translation:taskManager.calendar.staff.overtime');

		const indicatorButton = staff.workAllocation
			? {
					tooltip,
					warning: true,
					nestedTableButton: true,
					type: TABLE_ACTIONS.WARNING,
					action: () => {}
			  }
			: null;

		return [tasks, staff.fullName, staff.totalDuration, staff.id, [indicatorButton]];
	});

	return data;
};

export const parseStaffTasksNestedTable = (tasks, openEditTaskModal) => {
	const data = tasks.map((task, index) => {
		function getEditButtonClassName(task) {
			const isTaskFinished =
				task.status !== TASK_STATUS.COMPLETED && task.status !== TASK_STATUS.CANCELED;
			const isTaskOnHold = task.status == TASK_STATUS.ON_HOLD;
			if (isTaskOnHold) {
				return 'task-blocked';
			} else if (moment().isAfter(task.endTime, 'day') && isTaskFinished) {
				return 'task-overdue';
			} else if (moment().isSame(task.endTime, 'day') && isTaskFinished) {
				return 'task-due-today';
			} else if (
				moment().add(1, 'day').endOf('day').isSame(task.endTime, 'day') &&
				isTaskFinished
			) {
				return 'task-due-tomorrow';
			} else {
				return '';
			}
		}

		function getEditButtonTooltip(task) {
			const isTaskFinished =
				task.status !== TASK_STATUS.COMPLETED && task.status !== TASK_STATUS.CANCELED;
			const isTaskOnHold = task.status == TASK_STATUS.ON_HOLD;
			if (isTaskOnHold) {
				return i18n.t('translation:taskManager.common.buttons.tooltips.editBlockTask');
			} else if (moment().isAfter(task.endTime, 'day') && isTaskFinished) {
				return i18n.t('translation:taskManager.common.buttons.tooltips.editOverdue');
			} else if (moment().isSame(task.endTime, 'day') && isTaskFinished) {
				return i18n.t('translation:taskManager.common.buttons.tooltips.editTodayTask');
			} else if (
				moment().add(1, 'day').endOf('day').isSame(task.endTime, 'day') &&
				isTaskFinished
			) {
				return i18n.t('translation:taskManager.common.buttons.tooltips.editTomorrowTask');
			} else {
				return i18n.t('translation:taskManager.common.buttons.tooltips.edit');
			}
		}

		const editButton = {
			nestedTableButton: true,
			className: getEditButtonClassName(task),
			tooltip: getEditButtonTooltip(task),
			type: TABLE_ACTIONS.EDIT,
			action: () => {
				// Open modal
				console.log('parseStaffTasksNestedTable');

				store.dispatch(
					getTaskData(task.id, (currentTask) => {
						let updatedTask = {
							...task,
							...currentTask
						};
						openEditTaskModal(updatedTask);
					})
				);
			}
		};
		const icon = task.generalNote ? (
			<FontAwesomeIcon icon={faInfoCircle} className={'note-icon'} title={task.generalNote} />
		) : null;
		const taskStatus = <Status status={task.status} />;
		const name = (
			<span>
				{i18n.t('translation:taskManager.common.number') + task.name} {icon}
			</span>
		);
		return [
			name,
			moment(task.startTime).format('DD.MM.YYYY' + ', ' + DEFAULT_TIME_FORMAT),
			'',
			moment(task.endTime).format('DD.MM.YYYY' + ', ' + DEFAULT_TIME_FORMAT),
			taskStatus,
			[editButton]
		];
	});

	return data;
};

export const parseStaffChartDays = (staff) => {
	const data = staff.length
		? staff.map((staff, index) => {
				const tasks = staff.tasks
					? staff.tasks.map((task) => {
							return ['', task.timeSlotWorkDurations];
					  })
					: [];

				return [tasks, '', staff.timeSlotWorkDurations];
		  })
		: [[[], '', '']];

	return data;
};

export const parseStaffChartHours = (staff) => {
	const data = staff.length
		? staff.map((staff, index) => {
				const tasks = staff.tasks
					? staff.tasks.map((task) => {
							return [
								'',
								task.timeSlotWorkDurations[0]
									? task.timeSlotWorkDurations[0].timeSlotWorkDurations
									: []
							];
					  })
					: [];

				return [
					tasks,
					'',
					staff.timeSlotWorkDurations[0]
						? staff.timeSlotWorkDurations[0].timeSlotWorkDurations
						: []
				];
		  })
		: [[[], '', '']];

	return data;
};

export const parseStaffTasksChartDays = (tasks) => {
	const data = tasks.length
		? tasks.map((task, index) => {
				return ['', task.timeSlotWorkDurations];
		  })
		: [['', []]];

	return data;
};

export const parseStaffTasksChartHours = (tasks) => {
	const data = tasks.length
		? tasks.map((task, index) => {
				return [
					'',
					task.timeSlotWorkDurations[0]
						? task.timeSlotWorkDurations[0].timeSlotWorkDurations
						: []
				];
		  })
		: [['', []]];

	return data;
};

export const parseOrderDetailsCalendarOrdersNestedTable = (
	orders,
	openQuickViewModal,
	openEditModal,
	account
) => {
	const data = orders[0].orderItems
		? orders[0].orderItems.map((item, index) => {
				const clonedTasks = _.cloneDeep(item.tasks);
				const tasks = clonedTasks
					? clonedTasks.map((task, index) => {
							const tooltip =
								task.planningStatus === PLANNING_STATUS.NOT_PLANNED
									? i18n.t(
											'translation:taskManager.common.buttons.tooltips.editNotPlanned'
									  )
									: task.planningStatus === PLANNING_STATUS.OVERDUE
									? i18n.t(
											'translation:taskManager.common.buttons.tooltips.editOverdue'
									  )
									: '';
							const taskStatus = <Status status={task.status} />;
							const editButton = !isLabTechnician(account)
								? {
										tooltip,
										nestedTableButton: true,
										danger: task.planningStatus === PLANNING_STATUS.OVERDUE,
										warning:
											task.planningStatus === PLANNING_STATUS.NOT_PLANNED,
										type: TABLE_ACTIONS.EDIT,
										action: () => {
											// Open modal
											console.log('Open edit modal');

											store
												.dispatch(
													getTaskData(task.id, (currentTask) => {
														openEditModal(currentTask, item, orders[0]);
													})
												)
												.then((response) => {
													const orderItemId = response.data.orderItemId;
													const templateId = response.data.templateId;
													store.dispatch(
														getLabProductTemplate(
															orderItemId,
															templateId
														)
													);
												});
										}
								  }
								: null;

							return [
								task.title,
								task.startTime
									? moment(task.startTime).format('YYYY-MM-DD HH:mm')
									: '',
								task.endTime ? moment(task.endTime).format('YYYY-MM-DD HH:mm') : '',
								taskStatus,
								task.assignee,
								[editButton]
							];
					  })
					: [];
				const tooltip =
					item.planningStatus === PLANNING_STATUS.NOT_PLANNED
						? i18n.t('translation:taskManager.common.buttons.tooltips.alertNotPlanned')
						: item.planningStatus === PLANNING_STATUS.OVERDUE
						? i18n.t('translation:taskManager.common.buttons.tooltips.alertOverdue')
						: '';
				const indicatorButton =
					item.planningStatus === PLANNING_STATUS.NOT_PLANNED
						? {
								tooltip,
								warning: true,
								nestedTableButton: true,
								type: TABLE_ACTIONS.WARNING,
								action: () => {}
						  }
						: item.planningStatus === PLANNING_STATUS.OVERDUE
						? {
								tooltip,
								nestedTableButton: true,
								danger: true,
								type: TABLE_ACTIONS.WARNING,
								action: () => {}
						  }
						: null;
				return [
					tasks,
					item.name,
					item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
					item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
					'',
					'',
					[indicatorButton]
				];
		  })
		: [];

	return data;
};

export const parseOrderDetailsCalendarOrdersChart = (orders) => {
	if (orders[0].orderItems && !orders[0].orderItems.length) {
		return [[[], '', '', '', '', '', '']];
	}
	const data = orders[0].orderItems
		? orders[0].orderItems.map((item, index) => {
				const tasks = item.tasks
					? item.tasks.map((task, index) => {
							const taskStatus = <Status status={task.status} />;

							return [
								task.title,
								task.startTime
									? moment(task.startTime).format('YYYY-MM-DD HH:mm')
									: '',
								task.endTime ? moment(task.endTime).format('YYYY-MM-DD HH:mm') : '',
								task.duration,
								taskStatus,
								true
							];
					  })
					: [];

				return [
					tasks,
					item.name,
					item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
					item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
					item.duration,
					'',
					true
				];
		  })
		: [];

	return data;
};

export const parseCalendarOrdersChart = (orders, openQuickViewModal) => {
	if (!orders.length) {
		return [[[], '', '', '', '', '', '']];
	}
	const data = orders.map((order, index) => {
		const orderStatus = <Status status={order.status} calendarStatus />;
		const items = order.orderItems
			? order.orderItems.map((item, index) => {
					const tasks = item.tasks
						? item.tasks.map((task, index) => {
								const taskStatus = <Status status={task.status} />;

								return [
									task.title,
									task.startTime
										? moment(task.startTime).format('YYYY-MM-DD HH:mm')
										: '',
									task.endTime
										? moment(task.endTime).format('YYYY-MM-DD HH:mm')
										: '',
									task.duration,
									taskStatus,
									true
								];
						  })
						: [];
					if (order.id === 599) {
						// Medzhit - eslint error because it is empty
					}

					return [
						tasks,
						item.name,
						item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
						item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
						item.duration,
						'',
						true
					];
			  })
			: [];

		const name = i18n.t('translation:orders.orderNumber', {
			word1: order.labOrderId
		});
		return [
			items,
			name,
			order.startTime ? moment(order.startTime).format('YYYY-MM-DD HH:mm') : '',
			order.endTime ? moment(order.endTime).format('YYYY-MM-DD HH:mm') : '',
			order.duration,
			orderStatus,
			order.hasTasks
		];
	});

	return data;
};

export const parseWorkingHours = (data, tableFormValues) => {
	let refinedWorkingHours = [];
	for (let index in data) {
		const currentDay = [];
		const day = data[index];
		const dayName = i18n.t('translation:taskManager.common.table.day_' + day.dayOfTheWeek);
		const startId = day.id + 's';
		const endId = day.id + 'e';
		const duration = day.duration;

		const onClearButtonPress = (item) => {
			let workingHours = transformTableWorkingHours(tableFormValues, data);
			const newData = resetObjValues(workingHours, item);
			store.dispatch(updateWorkingHours(newData));
		};

		const clearButton = {
			type: TABLE_ACTIONS.CLEAR,
			action: () => {
				onClearButtonPress(day);
			}
		};

		currentDay.push(dayName);
		currentDay.push(
			Fields.templateFields.duration({
				startId,
				id: startId,
				className: 'first',
				noLabel: true,
				noErrorMessage: true,
				isDayOfWeek: true
			})
		);
		currentDay.push(
			Fields.templateFields.duration({
				endId,
				id: endId,
				className: 'first',
				noLabel: true,
				noErrorMessage: true,
				isDayOfWeek: true
			})
		);

		currentDay.push(duration);
		currentDay.push([clearButton]);

		refinedWorkingHours.push(currentDay);
	}

	return refinedWorkingHours;
};

export const parseItemTasks = (
	data,
	staff,
	callback,
	onChange,
	shownNotes,
	toggleNote,
	readOnly
) => {
	let itemTasks = [];
	const readOnlyClass = readOnly ? ' read-only-field' : '';

	function onDeleteButtonPress(item) {
		callback(item.id);
	}

	for (let index in data) {
		const currentTask = [];
		const task = data[index];
		const id = task.id;
		const isNoteHidden = !shownNotes.includes(id);

		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeleteButtonPress(task);
			},
			className: 'delete-task-button' + readOnlyClass
		};

		const infoIcon = { type: TABLE_ACTIONS.INFO };

		const expandIcon = {
			type: isNoteHidden ? TABLE_ACTIONS.EXPAND : TABLE_ACTIONS.COLLAPSE,
			action: () => {
				toggleNote(id);
			},
			isExpanded: !isNoteHidden,
			id: task.id
		};

		currentTask.push(
			Fields.templateFields.nameLocal({
				id,
				className: 'first w-100 task-td' + readOnlyClass,
				noLabel: true
			})
		);
		currentTask.push(
			Fields.workingHoursFields.startDateTime({
				id,
				className: 'first task-start w-20 task-td' + readOnlyClass,
				noLabel: true,
				onChange: (event, isStartTime) => {
					if (typeof event == 'object') {
						const data = {
							startTime: isStartTime
								? moment(event).format('YYYY-MM-DD HH:mm')
								: null,
							endTime: !isStartTime ? moment(event).format('YYYY-MM-DD HH:mm') : null,
							duration: task.duration ? task.duration : null,
							name: task.name
						};

						onChange(isStartTime, data, id);
					}
				}
			})
		);
		currentTask.push(
			Fields.workingHoursFields.endDateTime({
				id,
				className: 'first w-20 task-td' + readOnlyClass,
				noLabel: true,
				onChange: (event, isStartTime) => {
					if (typeof event == 'object') {
						const data = {
							startTime: isStartTime
								? moment(event).format('YYYY-MM-DD HH:mm')
								: null,
							endTime: !isStartTime ? moment(event).format('YYYY-MM-DD HH:mm') : null,
							duration: task.duration ? task.duration : null,
							name: task.name
						};

						onChange(isStartTime, data, id);
					}
				}
			})
		);

		currentTask.push(
			Fields.commonFields.availableEmployees({
				selectionLimit: 1,
				options: formUtils.options.availableEmployees(task.staff),
				className: 'first assignees-select' + readOnlyClass,
				id: id,
				noLabel: true
			})
		);

		if (task.generalNote) {
			currentTask.push([infoIcon, deleteButton, expandIcon]);
		} else {
			currentTask.push([deleteButton, expandIcon]);
		}

		let noteClass = isNoteHidden
			? `td-notes td-notes-${id} td-notes-hidden`
			: `td-notes td-notes-${id}`;
		currentTask.push(
			Fields.tableFields.generalNote({
				className: noteClass,
				id: id,
				noLabel: true
			})
		);
		itemTasks.push(currentTask);
	}

	return itemTasks;
};

export const parseShifts = (shifts, labId, openModalWithInitialization) => {
	let refinedShifts = [];

	function onDeleteButtonPress(item) {
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					store.dispatch(deleteShift(labId, item.id));
					store.dispatch(hideMessageModal());
				}}
				danger
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
				secondary
			/>
		);
		store.dispatch(
			showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:taskManager.planner.deleteShiftWarning'),
				[yesButton, noButton]
			)
		);
	}

	for (let index in shifts) {
		let shift = shifts[index];
		const editButton = {
			type: TABLE_ACTIONS.EDIT,
			action: () => {
				openModalWithInitialization(shift);
			}
		};

		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeleteButtonPress(shift);
			}
		};

		const currentShift = [
			shift.name,
			shift.code,
			shift.startTime,
			shift.endTime,
			shift.workShiftDuration,
			shift.breakDuration,
			[editButton, deleteButton]
		];
		refinedShifts.push(currentShift);
	}
	return refinedShifts;
};

export const parseStaffUsers = (staff) => {
	let refinedStaff = [];

	for (let index in staff) {
		let user = staff[index];

		const className = user.isActive ? '' : 'text-dark-gray';

		const currentStaff = [
			<span
				key={user.id}
				title={
					user.isActive ? null : i18n.t('translation:taskManager.common.disabledEmployee')
				}
				className={className}>
				{user.userName}
			</span>
		];
		refinedStaff.push(currentStaff);
	}
	return refinedStaff;
};

export const parseTaxGroups = (taxGroups, openModal, settingsId) => {
	const data = taxGroups?.map((taxGroup, index) => {
		const editButton = {
			type: TABLE_ACTIONS.EDIT,
			action: () => {
				openModal(taxGroup);
			}
		};
		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeleteButtonPress(taxGroup);
			}
		};

		const buttons = [editButton, deleteButton];

		function onDeleteButtonPress(item) {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						store.dispatch(deleteTaxGroup(settingsId, taxGroup.id));
						store.dispatch(hideMessageModal());
					}}
					danger
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						store.dispatch(hideMessageModal());
					}}
					secondary
				/>
			);

			store.dispatch(
				showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:billingModule.settings.warnings.deleteTaxGroupWarning'),
					[yesButton, noButton]
				)
			);
		}

		return [taxGroup.name, `${taxGroup.percentage} %`, buttons];
	});

	return data;
};

export const parseOverdueIntervals = (overdueIntervals, openModal, settingsId) => {
	const data = overdueIntervals?.map((overdueInterval, index) => {
		const editButton = {
			type: TABLE_ACTIONS.EDIT,
			action: () => {
				openModal(overdueInterval);
			}
		};
		const deleteButton = {
			type: TABLE_ACTIONS.DELETE,
			action: () => {
				onDeleteButtonPress(overdueInterval);
			}
		};

		const buttons = [editButton, deleteButton];

		function onDeleteButtonPress(item) {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						store.dispatch(deleteOverdueInterval(settingsId, overdueInterval.id));
						store.dispatch(hideMessageModal());
					}}
					danger
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						store.dispatch(hideMessageModal());
					}}
					secondary
				/>
			);

			store.dispatch(
				showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t(
						'translation:billingModule.settings.warnings.deleteOverdueIntervalWarning'
					),
					[yesButton, noButton]
				)
			);
		}

		const overdueIntervalText = i18n.t(
			'translation:billingModule.settings.overdueIntervalFromDayToDay',
			{
				fromDay: overdueInterval.fromDay,
				toDay: overdueInterval.toDay
			}
		);

		return [overdueIntervalText, buttons];
	});

	return data;
};

const resetObjValues = (array, obj) => {
	array.filter(function (day) {
		if (day.id == obj.id) {
			day.startTime = null;
			day.endTime = null;
			day.duration = null;
		}
	});

	return array;
};

export const getDropdownData = (elementIndex, dropdown, isExpanded) => {
	if (Array.isArray(elementIndex)) {
		const dropdown = {};
		elementIndex.forEach((index) => (dropdown[index] = isExpanded));
		return dropdown;
	} else {
		return { ...dropdown, [elementIndex]: isExpanded };
	}
};

const transformTableWorkingHours = (tableFormValues) => {
	let workingHours = [];

	for (let value in tableFormValues) {
		let currentValues = {};
		const index = value.split('_')[1];
		const id = value.split('_')[1].slice(0, -1);

		currentValues['id'] = Number(id);
		workingHours.push(currentValues);

		for (let i = 0; i < workingHours.length; i++) {
			if (workingHours[i].id == id) {
				if (index.endsWith('s') && tableFormValues[value]) {
					workingHours[i]['startTime'] = addZeroIfOneDigit(
						tableFormValues['hours_' + index]
					)
						.concat(':')
						.concat(tableFormValues['minutes_' + index]);
				}
				if (index.endsWith('e') && tableFormValues[value]) {
					workingHours[i]['endTime'] = addZeroIfOneDigit(
						tableFormValues['hours_' + index]
					)
						.concat(':')
						.concat(tableFormValues['minutes_' + index]);
				}
				if (index.endsWith('d') && tableFormValues[value]) {
					workingHours[i]['dayOfTheWeek'] = tableFormValues['dayOfTheWeek_' + id + 'd'];
				}
				if (index.endsWith('t') && tableFormValues[value]) {
					workingHours[i]['duration'] = tableFormValues['duration_' + id + 't'];
				}
			}
		}
	}

	let updatedWorkingHours = workingHours.reduce(function (acc, curr) {
		let findIndex = acc.findIndex(function (item) {
			return item.id === curr.id;
		});

		if (findIndex === -1) {
			acc.push(curr);
		} else {
			acc[findIndex] = Object.assign({}, acc[findIndex], curr);
		}
		return acc;
	}, []);

	return updatedWorkingHours;
};

export const parseInvoicingOrders = (orders, onSelectOrder, formValues, hasCheckboxSelection) => {
	const data = orders?.map((order) => {
		const {
			id,
			labOrderId,
			clinicId,
			clinicLegalName,
			price,
			invoicedAmount,
			remainingAmount,
			localizedCurrencyAbbreviation,
			currencySymbol,
			paymentDueDate,
			status
		} = order;

		const orderNumber = <Link to={PAGES_PATHS.ORDERS + '/' + id}>{labOrderId}</Link>;
		const client = <Link to={PAGES_PATHS.CLIENTS + '/' + clinicId}>{clinicLegalName}</Link>;
		const amountWithVat = parseInvoicingCurrency(
			price,
			localizedCurrencyAbbreviation,
			currencySymbol
		);
		const invoicedAmountValue = parseInvoicingCurrency(
			invoicedAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);
		const remainingAmountValue = parseInvoicingCurrency(
			remainingAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);
		const paymentDueDateValue = moment(paymentDueDate).format('DD.MM.YYYY');
		const invoicingStatus = <DocumentStatus status={status} />;

		const invoicingCheckBox = (
			<div className="d-flex justify-content-center">
				<Field
					name={`toBeInvoiced_${id}`}
					customProps={{
						inputClassName: 'mr-0',
						checkedValue: formValues[`toBeInvoiced_${id}`]
					}}
					component={tableCheckbox}
					onChange={() => onSelectOrder(order)}
				/>
			</div>
		);

		const orderDataTableRow = [
			orderNumber,
			client,
			amountWithVat,
			invoicedAmountValue,
			remainingAmountValue,
			paymentDueDateValue,
			invoicingStatus
		];

		if (hasCheckboxSelection) {
			orderDataTableRow.push(invoicingCheckBox);
		}

		return orderDataTableRow;
	});

	return data;
};

export const getListWithTooltip = (list, emptyListMessage, maxLength = 3, truncate) => {
	let content;
	if (list && list.length > 0) {
		if (list.length <= maxLength) {
			content = <span>{list.join('; ')}</span>;
		} else {
			content = <span title={list.join('; ')}>{list.slice(0, maxLength).join('; ')}*</span>;
		}
	} else {
		return <span>{emptyListMessage}</span>;
	}
	if (truncate) {
		return (
			<span
				className="d-inline-block text-truncate"
				style={{ maxWidth: '100%' }}
				title={list.join('; ')}>
				{content}
			</span>
		);
	}
	return content;
};

export const parseBillingDocuments = (billingDocuments, onSelectDocument, formValues) => {
	const data = billingDocuments?.map((document) => {
		const {
			id,
			number,
			issueDate,
			labOrderIds,
			total,
			paidAmount,
			remainingAmount,
			interceptedAmount,
			status,
			taxIncluded,
			localizedCurrencyAbbreviation,
			currencySymbol
		} = document;

		const documentNumber = number;
		const issueDateValue = moment(issueDate).format('DD.MM.YYYY');
		const totalAmount = parseInvoicingCurrency(
			total,
			localizedCurrencyAbbreviation,
			currencySymbol
		);
		const paid = parseInvoicingCurrency(
			paidAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);
		const unpaidAmount = parseInvoicingCurrency(
			remainingAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);

		const intercepted = parseInvoicingCurrency(
			interceptedAmount,
			localizedCurrencyAbbreviation,
			currencySymbol
		);

		const ordersIds = getListWithTooltip(labOrderIds, '-');
		let taxStatusIcon;

		if (taxIncluded) {
			taxStatusIcon = <FontAwesomeIcon icon={faTimes} className="note-icon" />;
		} else {
			taxStatusIcon = <FontAwesomeIcon icon={faCheck} className="note-icon" />;
		}

		const creditDebitCheckBox = (
			<div className="d-flex justify-content-center">
				<Field
					name={`creditDebit_${id}`}
					customProps={{
						inputClassName: 'mr-0',
						checkedValue: formValues[`creditDebit_${id}`]
					}}
					component={tableCheckbox}
					onChange={() => onSelectDocument(document)}
				/>
			</div>
		);

		const statusComponent = <DocumentStatus status={status} />;

		const orderDataTableRow = [
			documentNumber,
			issueDateValue,
			ordersIds,
			totalAmount,
			paid,
			unpaidAmount,
			intercepted,
			taxStatusIcon,
			statusComponent,
			creditDebitCheckBox
		];

		return orderDataTableRow;
	});

	return data;
};

export const getItemTaxNameById = (taxGroups, taxGroupId) => {
	return taxGroups.filter((taxGroup) => taxGroup.id === Number(taxGroupId))[0].name;
};

export const getItemTaxPercentageById = (taxGroups, taxGroupId) => {
	return `${taxGroups.filter((taxGroup) => taxGroup.id === Number(taxGroupId))[0].percentage}%`;
};

export const getItemTaxInfoById = (taxGroups, taxGroupId) => {
	const tax = taxGroups.filter((taxGroup) => taxGroup.id === Number(taxGroupId))[0];
	return `${tax.percentage}% - ${tax.name}`;
};

export const getItemPriceWithTax = (price, tax) => {
	return (price * tax) / 100 + price;
};

export const getItemTotalPriceWithTax = (price, quantity) => {
	return price * quantity;
};

export const getOrderItemsTotalPriceWithoutTax = (orderItems) => {
	let total = 0;

	orderItems.forEach((item) => {
		const { CONSECUTIVE_TEETH } = GLOBAL_PRODUCT_CATEGORY;
		const { productCategory, price, taxPercentage, quantity } = item;

		if (productCategory === CONSECUTIVE_TEETH) {
			total += price * quantity;
		} else {
			total += price;
		}
	});

	return total;
};

export const getOrderItemsTotalPriceWithTax = (orderItems) => {
	let total = 0;

	orderItems.forEach((item) => {
		const { CONSECUTIVE_TEETH } = GLOBAL_PRODUCT_CATEGORY;
		const { productCategory, price, taxPercentage, quantity } = item;

		if (productCategory === CONSECUTIVE_TEETH) {
			const itemPriceWithTax = getItemPriceWithTax(price, taxPercentage);
			total += getItemTotalPriceWithTax(itemPriceWithTax, quantity);
		} else {
			const itemPriceWithTax = getItemPriceWithTax(price, taxPercentage);
			total += itemPriceWithTax;
		}
	});

	return total;
};

export const getOrderItemsAverageTax = (orderItems) => {
	const totalPriceWithTax = getOrderItemsTotalPriceWithTax(orderItems);
	const totalPriceWithoutTax = getOrderItemsTotalPriceWithoutTax(orderItems);

	const calculatedTaxAmount = totalPriceWithTax - totalPriceWithoutTax;
	const averageTax = (calculatedTaxAmount * 100) / totalPriceWithoutTax;

	if (!averageTax || averageTax === 0) {
		return `0%`;
	}

	const roundedTaxAverage = Number(averageTax).toFixed(2);
	const taxIntegerPart = Math.trunc(Number(roundedTaxAverage));

	if (taxIntegerPart === Number(roundedTaxAverage)) {
		return `${taxIntegerPart}%`;
	}
	return `${roundedTaxAverage}%`;
};
