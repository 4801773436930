import React from 'react';
import FlagDropdown from './LanguageSelector';
import sloganLogo from '../../resources/images/sloganLogo.png';

class AuthenticationHeaderBar extends React.Component {
	render() {
		return (
			<div className="authentication-header">
				<img src={sloganLogo} alt="" className="col-4 offset-4" />
				<div className="col-4 d-flex">
					<FlagDropdown />
				</div>
			</div>
		);
	}
}

export default AuthenticationHeaderBar;
