import { getAxiosWithToken } from '../utils/webApi';
import {
	GET_ALL_SUPPORTED_FILE_EXTENSIONS,
	GET_COLORS,
	GET_ORDER,
	GET_ORDERS_WITH_PAGINATION,
	GET_SUPPORTED_PHOTO_EXTENSIONS,
	GET_SUPPORTED_VIDEO_EXTENSIONS,
	UPDATE_HISTORY,
	UPDATE_ORDER,
	UPDATE_ORDER_FIELD
} from './types';
import navigationUtils from '../utils/navigationUtils';
import {
	DOCUMENT_EXPORT_FILE_EXTENSION,
	FILTER_FIELDS,
	LOADER_TYPES,
	ORDER_FIELDS,
	ORDER_STATUS,
	PAGES_PATHS,
	SPECIAL_ORDER_FILE_TYPES,
	SUCCESS_CODES
} from '../utils/constants';
import { hideMessageModal, showMessageModal } from './portalActions';
import i18n from '../i18n';
import React from 'react';
import DefaultButton from '../components/common/DefaultButton';
import {
	getRefinedOrderFields,
	isEmptyObject,
	isLabAdmin,
	isLabTechnician,
	isOrderCompleted,
	isTaskManagerEnabled,
	parsePrice,
	registeredInClinic,
	showSuccessPopup
} from '../utils/appUtils';
import { change, getFormValues, reset } from 'redux-form';
import { startLoader, stopLoader, stopLoaderImmediately } from './loaderActions';
import { formUtils } from '../utils/formUtils';
import { fileNotTooLarge } from '../utils/fileUtils';
import _ from 'lodash';
import { createExcelFileUrl, getFileName } from './common';
import moment from '../utils/moment';
import { getAllCalendarOrderItems } from './taskManager/calendarActions';

export const getOrderDetails =
	(id, showLoader = true) =>
	async (dispatch, getState) => {
		const response = await getAxiosWithToken(true, showLoader).get(`/order/${id}`);
		let returnedData = response.data;
		if (returnedData.success) {
			dispatch({
				type: GET_ORDER,
				payload: returnedData.data
			});
		}
		return returnedData.data;
	};

export const createOrder = (clinicId, labId) => async (dispatch) => {
	const response = await getAxiosWithToken().post(
		'/order',
		{},
		{
			params: {
				'clinic-id': clinicId,
				'lab-id': labId
			}
		}
	);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_ORDER,
			payload: returnedData.data
		});
		navigationUtils.navigate(PAGES_PATHS.ORDERS + `/${returnedData.data.id}`);
	}
};

export const updateOrder =
	(id, originalStatus, data, onSuccessfulUpdate = () => {}, showSuccessMessage = true) =>
	async (dispatch, getState) => {
		const allowUpdate =
			registeredInClinic(getState().account) ||
			isLabTechnician(getState().account) ||
			data.status === ORDER_STATUS.REJECTED ||
			data.status === ORDER_STATUS.INFORMATION_REQUIRED
				? true
				: await isInformationRequiredNeededOnUpdateOrder(id, data);
		const closeTasks = async (orderId) => {
			const response = await getAxiosWithToken(false).put(`/order/${orderId}/close-tasks`);
			let returnedData = response.data;
			if (returnedData.success) {
				console.log('closeTasks', returnedData);
			}

			return returnedData;
		};

		const update = async () => {
			const response = await getAxiosWithToken().put(`/order/${id}`, data);
			let returnedData = response.data;
			if (returnedData.success) {
				const modules = getState().modules;
				if (
					returnedData.data.status === ORDER_STATUS.ACCEPTED &&
					isTaskManagerEnabled(modules)
				) {
					dispatch(
						getAllCalendarOrderItems(
							getState().account.labId,
							{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: [returnedData.data.labOrderId] },
							true
						)
					);
				}
				if (returnedData.code == SUCCESS_CODES.CLOSE_TASKS) {
					const yesButton = (
						<DefaultButton
							title={i18n.t('translation:common.buttons.yes')}
							onClick={() => {
								closeTasks(id).then((response) => {
									dispatch(
										getAllCalendarOrderItems(
											getState().account.labId,
											{
												[FILTER_FIELDS.LAB_ORDER_ID_LIST]: [
													returnedData.data.labOrderId
												]
											},
											true
										)
									);
									dispatch(hideMessageModal());
								});
							}}
						/>
					);
					const noButton = (
						<DefaultButton
							title={i18n.t('translation:common.buttons.no')}
							onClick={() => {
								dispatch(hideMessageModal());
							}}
							secondary
						/>
					);

					dispatch(
						showMessageModal(
							i18n.t('translation:common.confirmation'),
							returnedData.userMessage,
							[yesButton, noButton]
						)
					);
				}

				if (showSuccessMessage) {
					let notificationMessage = i18n.t('translation:orders.successfulUpdatedOrder');
					if (originalStatus === ORDER_STATUS.DRAFT && originalStatus !== data.status) {
						notificationMessage = i18n.t('translation:orders.successfullyOpenedOrder');
					}

					showSuccessPopup(notificationMessage);
					dispatch({
						type: GET_ORDER,
						payload: returnedData.data
					});
				}
				onSuccessfulUpdate();
			}
		};
		if (allowUpdate) {
			update();
		} else {
			dispatch(stopLoaderImmediately(LOADER_TYPES.GLOBAL_LOADER));
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						update();
						dispatch(hideMessageModal());
					}}
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						dispatch(hideMessageModal());
					}}
					secondary
				/>
			);
			dispatch(
				showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:orders.informationRequiredNeeded'),
					[yesButton, noButton]
				)
			);
		}
	};

export const deleteOrder = (id) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/order/${id}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:orders.successfulDeletedOrder'));
		navigationUtils.goBack();
	}
};

export const createItem = (id, data, setTeethErrorMessage, closeModal) => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));
	const response = await getAxiosWithToken(false, false).post(`/order/${id}/item`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:orders.successfulAddedItem'));
		dispatch(getOrderDetails(id));
		closeModal();
	} else {
		setTeethErrorMessage(returnedData.userMessage);
	}
	dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
};

export const updateItem =
	(orderId, itemId, data, setTeethErrorMessage, closeModal) => async (dispatch, getState) => {
		const account = getState().account;
		const allowUpdate =
			registeredInClinic(account) || isLabTechnician(account)
				? true
				: await isInformationRequiredNeededOnUpdateItem(orderId, itemId, data);
		const update = async (isModalClosed) => {
			dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));
			const response = await getAxiosWithToken(false, false).put(
				`/order/${orderId}/item/${itemId}`,
				data
			);
			let returnedData = response.data;
			if (returnedData.success) {
				showSuccessPopup(i18n.t('translation:orders.successfulEditedItem'));
				dispatch(getOrderDetails(orderId));
				if (!isModalClosed) {
					closeModal();
				}
			} else {
				setTeethErrorMessage(returnedData.userMessage);
			}
			dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
		};
		if (allowUpdate) {
			update(false);
		} else {
			closeModal();
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						const fields = getState().orders.currentOrder.fields;
						const editableFields = formUtils.getEditableFields(fields);
						const orderDetailsFormValues = getFormValues('orderDetailsForm')(
							getState()
						);
						if (isLabAdmin(getState().account)) {
							const orderStatus = getState().orders.currentOrder.status;
							dispatch(
								updateOrder(
									orderId,
									orderStatus,
									{
										status: orderStatus,
										fields: getRefinedOrderFields(
											orderDetailsFormValues,
											editableFields
										)
									},
									() => {
										update(true);
										dispatch(hideMessageModal());
									},
									false
								)
							);
						} else {
							update(true);
							dispatch(hideMessageModal());
						}
					}}
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						dispatch(hideMessageModal());
					}}
					secondary
				/>
			);
			dispatch(
				showMessageModal(
					i18n.t('translation:common.confirmation'),
					i18n.t('translation:orders.informationRequiredNeeded'),
					[yesButton, noButton]
				)
			);
		}
	};

export const deleteItem = (orderId, itemId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/order/${orderId}/item/${itemId}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:orders.successfulDeletedItem'));
		dispatch(getOrderDetails(orderId));
	}
};

export const updatePaidAmount =
	(orderId, value, isAmountPaidEntirely, price, amountDue) => async (dispatch) => {
		if (!(value == 0 && price == amountDue)) {
			const response = await getAxiosWithToken().put(
				`/order/${orderId}/update-paid-amount`,
				{},
				{
					params: {
						value
					}
				}
			);
			let returnedData = response.data;
			if (returnedData.success) {
				if (isOrderCompleted(returnedData.data)) {
					dispatch(getOrderDetails(orderId));
				} else {
					const amountDue = formUtils.getFieldByType(
						returnedData.data.fields,
						ORDER_FIELDS.AMOUNT_DUE
					);
					const paidAmount = formUtils.getFieldByType(
						returnedData.data.fields,
						ORDER_FIELDS.PAID_AMOUNT
					);
					const lastPaymentDate = formUtils.getFieldByType(
						returnedData.data.fields,
						ORDER_FIELDS.LAST_PAYMENT_DATE
					);
					dispatch(
						change(
							'orderDetailsForm',
							_.camelCase(ORDER_FIELDS.AMOUNT_DUE),
							parsePrice(amountDue.value)
						)
					);
					dispatch(
						change(
							'orderDetailsForm',
							_.camelCase(ORDER_FIELDS.PAID_AMOUNT),
							parsePrice(paidAmount.value)
						)
					);
					dispatch(
						change(
							'orderDetailsForm',
							_.camelCase(ORDER_FIELDS.LAST_PAYMENT_DATE),
							lastPaymentDate.value
						)
					);
					dispatch({ type: UPDATE_HISTORY, payload: returnedData.data });
					if (isAmountPaidEntirely) {
						dispatch({
							type: UPDATE_ORDER,
							payload: { canUpdatePaidAmount: returnedData.data.canUpdatePaidAmount }
						});
						dispatch({
							type: UPDATE_ORDER_FIELD,
							payload: paidAmount
						});
					}
				}
				showSuccessPopup(i18n.t('translation:orders.successfulPaidAmount'));
			}
		}
	};

const isInformationRequiredNeededOnUpdateOrder = async (id, data) => {
	const response = await getAxiosWithToken().post(
		`/order/${id}/is-change-to-information-required-status-needed`,
		data
	);
	return !response.data.data;
};

const isInformationRequiredNeededOnUpdateItem = async (orderId, itemId, data) => {
	const response = await getAxiosWithToken(false, false).post(
		`/order/${orderId}/item/${itemId}/is-change-to-information-required-status-needed`,
		data
	);
	return !response.data.data;
};

export const getNotCompletedOrders = (page, sort) => async (dispatch) => {
	page = page - 1;

	const response = await getAxiosWithToken().get('/order/get-all-not-completed', {
		params: {
			page: page,
			sortColumn: sort.sortKey,
			sortDirection: sort.sortDirection
		}
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_ORDERS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const getColors = () => async (dispatch) => {
	const response = await getAxiosWithToken().get('/order/get-color-codes-of-all-schemes');
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_COLORS,
			payload: returnedData.data
		});
	}
};

export const getColorCodeEquivalent = (scheme, newScheme, code) => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.COLOR_LOADER));
	const response = await getAxiosWithToken(false, false).get('/order/convert-color-code', {
		params: {
			scheme,
			'new-scheme': newScheme,
			'color-code': code
		}
	});
	dispatch(stopLoader(LOADER_TYPES.COLOR_LOADER));
	return response;
};

export const switchColorScheme = (orderId, scheme) => async (dispatch) => {
	const response = await getAxiosWithToken().get(`/order/${orderId}/convert-all-color-codes`, {
		params: {
			scheme
		}
	});
	return response;
};

export const createComment = (id, content) => async (dispatch) => {
	dispatch(reset('commentsForm'));
	const response = await getAxiosWithToken().post(`/order/${id}/comment`, { content });
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch(getOrderDetails(id));
		//dispatch({type: GET_COMMENTS, payload: returnedData.data});
		showSuccessPopup(i18n.t('translation:orders.successfulAddedComment'));
	}
};

export const getOrders = (page, sort, filters) => async (dispatch) => {
	page = page - 1;
	const filterData = { ...filters };
	if (!isEmptyObject(filters)) {
		for (let filter in filterData) {
			if (filterData[filter] instanceof Date) {
				filterData[filter] = moment(filterData[filter]).format('YYYY-MM-DD');
			}
		}
	}

	const response = await getAxiosWithToken().post('/order/search', filterData, {
		params: {
			page: page,
			sortColumn: sort.sortKey,
			sortDirection: sort.sortDirection
		}
	});
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_ORDERS_WITH_PAGINATION,
			payload: returnedData.data
		});
	}
};

export const updateDraft = (id, data) => async (dispatch) => {
	const response = await getAxiosWithToken(false, false).put(`/order/${id}/update-draft`, data);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch(getOrderDetails(id, false));
	}
};

export const updatePassiveDraft = (id, data) => async (dispatch) => {
	const response = await getAxiosWithToken(false, false).put(
		`/order/${id}/update-passive-clinic-draft`,
		data
	);
	let returnedData = response.data;
	if (returnedData.success) {
		dispatch(getOrderDetails(id, false));
	}
};

export const getSupportedPhotoExtensions = () => async (dispatch) => {
	const response = await getAxiosWithToken().get(
		`/order/supported-file-extensions?file-type=${SPECIAL_ORDER_FILE_TYPES.PHOTO}`
	);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_SUPPORTED_PHOTO_EXTENSIONS,
			payload: returnedData.data
		});
	}
};

export const getSupportedVideoExtensions = () => async (dispatch) => {
	const response = await getAxiosWithToken().get(
		`/order/supported-file-extensions?file-type=${SPECIAL_ORDER_FILE_TYPES.VIDEO}`
	);

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_SUPPORTED_VIDEO_EXTENSIONS,
			payload: returnedData.data
		});
	}
};

export const getAllSupportedFileExtensions = (fileType) => async (dispatch) => {
	const response = await getAxiosWithToken().get('/order/all-supported-file-extensions');

	let returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_ALL_SUPPORTED_FILE_EXTENSIONS,
			payload: returnedData.data
		});
	}
};

export const uploadFile =
	(orderId, fileType, fileSubType, description, fileStream, fileExtension) =>
	async (dispatch) => {
		dispatch(startLoader(LOADER_TYPES.GLOBAL_LOADER));

		const maxSizeResponse = await getAxiosWithToken(false, false).get(
			`/order/file-max-size-in-mb?file-extension=${fileExtension}`
		);
		const maxSize = parseFloat(maxSizeResponse.data.data);
		if (!fileNotTooLarge(fileStream, maxSize)) {
			dispatch(stopLoaderImmediately(LOADER_TYPES.GLOBAL_LOADER));
			return;
		}

		let formData = new FormData();

		// NOTE: The fileType value will be null, if it isn't a predefined photo file that is
		// being uploaded. In this case the back-end will automatically figure out what exactly
		// type of file this is while uploading it to the server
		if (fileType == null) {
			fileType = '';
		}

		let fileData = {
			description,
			fileType,
			fileSubType
		};

		let fileParams = { fileExtension };

		formData.append('file-data', JSON.stringify(fileData));
		formData.append('file-stream', fileStream);
		formData.append('file-params', JSON.stringify(fileParams));

		const response = await getAxiosWithToken(false, false).post(
			`/order/${orderId}/file`,
			formData,
			{ 'Content-Type': 'multipart/form-data' }
		);

		dispatch(stopLoaderImmediately(LOADER_TYPES.GLOBAL_LOADER));

		let returnedData = response.data;
		if (returnedData.success) {
			showSuccessPopup(i18n.t('translation:orders.successfullyUploadedFile'));
			dispatch(getOrderDetails(orderId));
		}
	};

export const exportOrders = (sort, filters) => async (dispatch, getState) => {
	const response = await getAxiosWithToken(false).post(
		`/order/export?sortColumn=${sort.sortKey}&sortDirection=${sort.sortDirection}`,
		filters,
		{ responseType: 'arraybuffer' }
	);

	const link = document.createElement('a');
	link.href = createExcelFileUrl(response.data);
	link.setAttribute(
		'download',
		getFileName(getState().account, 'orders', DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL)
	);

	document.body.appendChild(link);
	link.click();

	showSuccessPopup(i18n.t('translation:orders.successfullyDownloadedExcelFile'));
};

export const updateFile = (orderId, fileId, description) => async (dispatch) => {
	const response = await getAxiosWithToken().put(`/order/${orderId}/file/${fileId}`, {
		description
	});
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:orders.successfullyUpdatedFile'));
		dispatch(getOrderDetails(orderId));
	}
};

export const deleteFile = (orderId, fileId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/order/${orderId}/file/${fileId}`);
	let returnedData = response.data;
	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:orders.successfullyDeletedFile'));
		dispatch(getOrderDetails(orderId));
	}
};
