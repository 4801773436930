import { GET_ABSENCE_TYPES } from '../../actions/types';

const initState = [];

const absenceReducer = (state = [...initState], action) => {
	if (action.type === GET_ABSENCE_TYPES) {
		state = action.payload;
		return [...state];
	}

	return state;
};

export default absenceReducer;
