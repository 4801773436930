import React, { Fragment } from 'react';
import Table from '../../common/Table';
import i18n from '../../../i18n';
import { connect } from 'react-redux';
import { parseUsers } from '../../../utils/tableUtils';
import Header from '../../common/Header';
import { clearReducers, getAllNonAdminUsers, setPagination } from '../../../actions';
import Pagination from '../../common/Pagination';
import { PAGINATION_TYPES } from '../../../utils/constants';
import { isEmptyObject } from '../../../utils/appUtils';

class Users extends React.Component {
	componentDidMount() {
		this.props.getAllNonAdminUsers(this.props.page || 1);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.getAllNonAdminUsers(this.props.page);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.users.content)
			) {
				this.props.setPagination(PAGINATION_TYPES.NON_ADMIN_USERS, this.props.page - 1);
			}
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<Header title={i18n.t('translation:users.users')} buttons={[]} />
				<div className="col-12 p-0">
					<hr />
					{this.renderUsers()}
					{this.renderPagination()}
				</div>
			</Fragment>
		);
	}

	renderUsers = () => {
		const users = parseUsers(this.props.users.content);

		return (
			<Table
				columns={[
					i18n.t('translation:common.table.name'),
					i18n.t('translation:common.table.phone'),
					i18n.t('translation:common.table.email'),
					i18n.t('translation:common.table.role'),
					i18n.t('translation:common.table.organization'),
					i18n.t('translation:common.table.status')
				]}
				data={users}
			/>
		);
	};

	renderPagination = () => {
		const { users } = this.props;
		if (users) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={users.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.NON_ADMIN_USERS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		users: state.users.users,
		page: state.pagination[PAGINATION_TYPES.NON_ADMIN_USERS]
	};
};

export default connect(mapStateToProps, { getAllNonAdminUsers, setPagination, clearReducers })(
	Users
);
