import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from './Fields';
import DefaultButton from './DefaultButton';
import i18n from '../../i18n';
import { formUtils } from '../../utils/formUtils';
import {
	addClinicLocation,
	addLabLocation,
	updateClinicLocation,
	updateLabLocation
} from '../../actions';
import {
	isGlobalAdmin,
	isLabAdmin,
	isPracticeRoute,
	registeredInClinic
} from '../../utils/appUtils';
import navigationUtils from '../../utils/navigationUtils';

class AddLocationModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const initialValues = nextProps.initialValues
				? nextProps.initialValues
				: {
						address: '',
						name: '',
						phone: '',
						email: ''
				  };
			this.props.initialize(initialValues);
		}
	}

	render() {
		const addButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.props.handleSubmit(this.onFormSubmit)}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={this.closeModalAndDestroy}
				secondary
			/>
		);

		const modalTitle = this.props.initialValues
			? i18n.t('translation:common.editLocation')
			: i18n.t('translation:common.addLocation');

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={this.closeModalAndDestroy}
				/>
				<h2>{modalTitle}</h2>
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{Fields.commonFields.name()}
					{Fields.commonFields.phone()}
					{Fields.commonFields.email()}
					{Fields.commonFields.address()}
				</form>
				<div className="buttons">
					{addButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	onFormSubmit = (formValues) => {
		const companyId = this.getCompanyId();
		const locationId = this.props.initialValues ? this.props.initialValues.id : null;
		if (isPracticeRoute(navigationUtils.getRoute())) {
			if (locationId) {
				this.props.updateClinicLocation(
					formValues,
					companyId,
					locationId,
					this.closeModalAndDestroy
				);
			} else {
				this.props.addClinicLocation(formValues, companyId, this.closeModalAndDestroy);
			}
		} else {
			if (locationId) {
				this.props.updateLabLocation(
					formValues,
					companyId,
					locationId,
					this.closeModalAndDestroy
				);
			} else {
				this.props.addLabLocation(formValues, companyId, this.closeModalAndDestroy);
			}
		}
	};

	getCompanyId = () => {
		const { account } = this.props;
		if (isGlobalAdmin(account) || (isLabAdmin(account) && this.props.isClinic)) {
			// The complicated condition that is satisfied here means that
			// we are adding/updating location of a passive clinic
			return this.props.currentClinic.id;
		} else {
			return registeredInClinic(account) ? account.clinicId : account.labId;
		}
	};

	closeModalAndDestroy = () => {
		this.props.closeModal();
		this.props.destroy();
	};
}

const addLocationsForm = reduxForm({
	form: 'addLocationsForm',
	validate: formUtils.validateAddLocationModal
})(AddLocationModal);

const mapStateToProps = (state) => {
	return { account: state.account, currentClinic: state.clinics.currentClinic };
};

export default connect(mapStateToProps, {
	addClinicLocation,
	addLabLocation,
	updateClinicLocation,
	updateLabLocation
})(addLocationsForm);
