import {
	CLEAR_DEBTOR_DAYS_DOCUMENTS,
	GET_DEBTOR_DAYS,
	GET_DEBTOR_DAYS_DOCUMENTS
} from '../../actions/types';

const initState = {
	debtorDays: {},
	debtorDaysDocuments: {}
};

const billingDebtorDaysReducer = (state = initState, action) => {
	if (action.type === GET_DEBTOR_DAYS) {
		const sorted = action.payload.content.sort(
			(one, two) => Number(one.overdueIntervalFrom) - Number(two.overdueIntervalFrom)
		);
		action.payload.content = sorted;
		state.debtorDays = action.payload;
		return { ...state };
	}

	if (action.type === GET_DEBTOR_DAYS_DOCUMENTS) {
		state.debtorDaysDocuments = action.payload;
		return { ...state };
	}

	if (action.type === CLEAR_DEBTOR_DAYS_DOCUMENTS) {
		state.debtorDaysDocuments = action.payload;
		return initState;
	}

	return initState;
};

export default billingDebtorDaysReducer;
