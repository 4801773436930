import {
	CLEAR_CURRENT_DOCUMENT,
	CLEAR_ORDER_INVOICE_DATA,
	CLEAR_PRE_CREATE_MODAL,
	GET_BILLING_DOCUMENT_DETAILS,
	GET_NEXT_BILLING_DOCUMENT_NUMBER,
	GET_ORDER_INVOICE_DATA,
	PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS,
	PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS,
	SEARCH_BILLING_DOCUMENTS,
	SEARCH_BILLING_ORDERS
} from '../../actions/types';

const preCreateModalInitialState = {
	documents: {},
	orders: {}
};

const initialState = {
	documents: {},
	orders: {},
	preCreateModal: preCreateModalInitialState,
	invoiceData: [],
	nextDocumentNumber: null,
	currentDocument: null
};

const billingDocumentReducer = (state = initialState, action) => {
	if (action.type === SEARCH_BILLING_DOCUMENTS) {
		state.documents = action.payload;
		return { ...state };
	}

	if (action.type === GET_NEXT_BILLING_DOCUMENT_NUMBER) {
		state.nextDocumentNumber = action.payload;
		return { ...state };
	}

	if (action.type === SEARCH_BILLING_ORDERS) {
		state.orders = action.payload;
		return { ...state };
	}

	if (action.type === GET_ORDER_INVOICE_DATA) {
		return { ...state, invoiceData: [...action.payload] };
	}

	if (action.type === CLEAR_ORDER_INVOICE_DATA) {
		state.invoiceData = [];
		return { ...state };
	}

	if (action.type === GET_BILLING_DOCUMENT_DETAILS) {
		return {
			...state,
			currentDocument: {
				...action.payload
			}
		};
	}

	if (action.type === CLEAR_CURRENT_DOCUMENT) {
		return { ...state, currentDocument: null };
	}

	// Pre-Create Modal

	if (action.type === PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS) {
		state.preCreateModal.documents = action.payload;
		return { ...state };
	}

	if (action.type === PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS) {
		state.preCreateModal.orders = action.payload;
		return { ...state };
	}

	if (action.type === CLEAR_PRE_CREATE_MODAL) {
		state.preCreateModal = { documents: {}, orders: {} };
		return { ...state };
	}

	return state;
};

export default billingDocumentReducer;
