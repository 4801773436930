import React, { Fragment } from 'react';
import i18n from '../../../../../i18n';
import { isEmptyObject, isGlobalAdmin } from '../../../../../utils/appUtils';
import NestedTable from '../../../../common/NestedTable';
import moment from '../../../../../utils/momentFromChosenLanguage';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	DEFAULT_TIME_FORMAT,
	STAFFCHART_MODES,
	WORK_ALLOCATIONS
} from '../../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import EditStaffTaskModal from './EditStaffTaskModal';
import EditModal from '../orders/EditModal';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

class StaffChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date: moment(),
			idEditStaffTaskModalOpen: false,
			isEditTaskModalOpen: false,
			showByHour: false,
			currentAssignee: {}
		};
	}

	render() {
		let nestedTableClassName = 'col-5 pr-0 pl-0 staff-chart-nested-table';
		let chartClassName = 'col-7 pl-0 pr-0';

		if (this.props.labTechnician) {
			nestedTableClassName = 'col-6 pr-0 pl-0 staff-chart-nested-table';
			chartClassName = 'col-6 pl-0 pr-0';
		}

		return (
			<Fragment>
				<ScrollSync horizontal={false}>
					<Fragment>
						<div className={nestedTableClassName}>{this.renderNestedTable()}</div>
						<div className={chartClassName}>{this.renderChart()}</div>
					</Fragment>
				</ScrollSync>
				<EditStaffTaskModal
					isModalOpen={this.state.idEditStaffTaskModalOpen}
					closeModal={this.closeEditStaffTaskModal}
					initialValues={this.state.currentAssignee}
					users={this.props.users}
				/>
				<EditModal
					isModalOpen={this.props.isEditTaskModalOpen}
					closeModal={this.props.closeEditTaskModal}
					initialValues={this.props.currentTask}
					filters={this.props.filters}
					staffChart
					singleEmployee={this.props.singleEmployee ? true : false}
					mode={this.props.mode}
					date={this.state.date}
				/>
			</Fragment>
		);
	}

	openEditStaffTaskModal = (day, hours) => {
		const { staff } = this.props;
		let assignee = {};

		if (isGlobalAdmin(this.props.account)) {
			return;
		}

		if (day) {
			staff.map((item) => {
				for (let i = 0; i < item.timeSlotWorkDurations.length; i++) {
					const staffTimeslot = item.timeSlotWorkDurations[i];
					if (
						staffTimeslot.date == day.date &&
						staffTimeslot.duration == day.duration &&
						staffTimeslot.assigneeId == day.assigneeId
					) {
						assignee = item;
						assignee['timeSlotWorkDurations'] = day;
						break;
					}
				}
			});
		}

		if (hours) {
			staff.map((item) => {
				for (let i = 0; i < item.timeSlotWorkDurations.length; i++) {
					const staffTimeslot = item.timeSlotWorkDurations[i];
					for (let j = 0; j < staffTimeslot.timeSlotWorkDurations.length; j++) {
						const staffHoursTimeslot = staffTimeslot.timeSlotWorkDurations[j];
						if (
							staffHoursTimeslot.time == hours.time &&
							staffHoursTimeslot.duration == hours.duration &&
							staffHoursTimeslot.assigneeId == hours.assigneeId
						) {
							assignee = item;
							assignee['timeSlotWorkDurations'] = hours;
							assignee['timeSlotDate'] = staffTimeslot.date;
							break;
						}
					}
				}
			});
		}

		const showByHour = hours ? true : false;
		this.setState({
			idEditStaffTaskModalOpen: true,
			currentAssignee: assignee,
			showByHour: showByHour
		});
	};

	closeEditStaffTaskModal = () => {
		if (typeof this.props.getCalendarStaff === 'function') {
			if (this.state.showByHour) {
				const startDate = moment(this.state.currentAssignee.timeSlotDate).format(
					DEFAULT_BACKEND_DATE_FORMAT
				);
				const endDate = moment(this.state.currentAssignee.timeSlotDate).format(
					DEFAULT_BACKEND_DATE_FORMAT
				);

				this.props.getCalendarStaff(
					this.state.showByHour,
					{
						...this.state.filters,
						startDate,
						endDate
					},
					this.props.account.labId
				);
			} else {
				const filters = this.props.filters;
				let startDate =
					filters && filters.startDate
						? moment(filters.startDate).format('DD.MM.YYYY')
						: this.state.date.clone().startOf('week').format('DD.MM.YYYY');
				let endDate =
					filters && filters.endDate
						? moment(filters.endDate).format('DD.MM.YYYY')
						: this.state.date.clone().endOf('week').format('DD.MM.YYYY');

				this.props.getCalendarStaff(
					this.state.showByHour,
					{
						...this.props.filters,
						startDate,
						endDate
					},
					this.props.account.labId
				);
			}
		}
		this.setState({ idEditStaffTaskModalOpen: false });
	};

	renderMode = () => {
		const daysModeClassName =
			this.props.mode === STAFFCHART_MODES.WEEKS ? 'active-mode' : 'not-active-mode';
		const hoursModeClassName =
			this.props.mode === STAFFCHART_MODES.HOURS ? 'active-mode' : 'not-active-mode';

		return (
			<div className="d-flex flex-nowrap justify-content-end mr-s">
				<span
					className={daysModeClassName}
					onClick={() => this.props.triggerMode(STAFFCHART_MODES.WEEKS)}>
					{i18n.t('translation:taskManager.calendar.staff.table.days')}
				</span>
				&nbsp;&nbsp;&nbsp;
				<span
					className={hoursModeClassName}
					onClick={() => this.props.triggerMode(STAFFCHART_MODES.HOURS)}>
					{i18n.t('translation:taskManager.calendar.staff.table.hours')}
				</span>
			</div>
		);
	};

	renderNestedTable = () => {
		const data = this.props.nestedTableData;

		return (
			<ScrollSyncPane group="vertical">
				<div className="table-fix-header">
					<NestedTable
						columns={this.props.columns}
						data={[...data]}
						setDropdown={this.props.setDropdown}
						dropdown={this.props.dropdown}
						onGlobalDropdownClick={this.props.onGlobalDropdownClick}
					/>
				</div>
			</ScrollSyncPane>
		);
	};

	renderChart = () => {
		return (
			<Fragment>
				<ScrollSyncPane group="vertical">
					<div className="calendar-table staff-chart">
						<div>
							<div className="days chart-header">{this.renderDays()}</div>
							{this.renderData()}
						</div>
					</div>
				</ScrollSyncPane>
				<div className="row m-0 staff-date">
					<div className="col-2 p-0 left-side"></div>
					<div className="col-8 p-0">{this.renderDate()}</div>
					<div className="col-2 p-0 mode">{this.renderMode()}</div>
				</div>
			</Fragment>
		);
	};

	addWeek = () => {
		const date = this.state.date.add(1, 'W');
		const startDate = moment(date).startOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
		if (typeof this.props.onAddWeek === 'function') {
			this.props.onAddWeek(startDate, endDate);
		}
		this.setState({ date });
	};

	subtractWeek = () => {
		const date = this.state.date.subtract(1, 'W');
		const startDate = moment(date).startOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('week').format(DEFAULT_BACKEND_DATE_FORMAT);
		if (typeof this.props.onSubtractWeek === 'function') {
			this.props.onSubtractWeek(startDate, endDate);
		}
		this.setState({ date });
	};

	addDay = () => {
		const date = this.state.date.add(1, 'days');
		const startDate = moment(date).startOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
		if (typeof this.props.onAddDay === 'function') {
			this.props.onAddDay(startDate, endDate);
		}
		this.setState({ date });
	};

	subtractDay = () => {
		const date = this.state.date.subtract(1, 'days');
		const startDate = moment(date).startOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('day').format(DEFAULT_BACKEND_DATE_FORMAT);
		if (typeof this.props.onSubtractDay === 'function') {
			this.props.onSubtractDay(startDate, endDate);
		}
		this.setState({ date });
	};

	renderDate = () => {
		const filters = this.props.filters;

		if (this.props.mode === STAFFCHART_MODES.WEEKS) {
			const arrowLeft = !filters.startDate ? (
				<FontAwesomeIcon
					onClick={this.subtractWeek}
					icon={faSortDown}
					className="fa-rotate-90 pointer"
				/>
			) : null;
			const arrowRight = !filters.endDate ? (
				<FontAwesomeIcon
					onClick={this.addWeek}
					icon={faSortDown}
					className="fa-rotate-270 pointer"
				/>
			) : null;

			let startDate =
				filters && filters.startDate
					? moment(filters.startDate).format('DD.MM.YYYY')
					: this.state.date.clone().startOf('week').format('DD.MM.YYYY');
			let endDate =
				filters && filters.endDate
					? moment(filters.endDate).format('DD.MM.YYYY')
					: this.state.date.clone().endOf('week').format('DD.MM.YYYY');

			const date = startDate && endDate ? startDate + ' - ' + endDate : '';
			return (
				<div className="range position-relative">
					{arrowLeft}
					<p className="m-0">{date}</p>
					{arrowRight}
				</div>
			);
		} else {
			const arrowLeft = !filters.startDate ? (
				<FontAwesomeIcon
					onClick={this.subtractDay}
					icon={faSortDown}
					className="fa-rotate-90 pointer"
				/>
			) : null;
			const arrowRight = !filters.endDate ? (
				<FontAwesomeIcon
					onClick={this.addDay}
					icon={faSortDown}
					className="fa-rotate-270 pointer"
				/>
			) : null;

			const date =
				filters && filters.startDate && moment(filters.startDate).isSame(moment(), 'day')
					? `${moment(filters.startDate)
							.clone()
							.startOf('day')
							.format('DD.MM.YYYY')} (${i18n.t(
							'translation:taskManager.common.table.today'
					  )})`
					: filters && filters.startDate
					? moment(filters.startDate).startOf('day').format('DD.MM.YYYY')
					: this.state.date.startOf('day').isSame(moment(), 'day')
					? `${this.state.date.clone().startOf('day').format('DD.MM.YYYY')} (${i18n.t(
							'translation:taskManager.common.table.today'
					  )})`
					: this.state.date.startOf('day').format('DD.MM.YYYY');
			return (
				<div className="range position-relative">
					{arrowLeft}
					<p className="m-0">{date}</p>
					{arrowRight}
				</div>
			);
		}
	};

	renderDays = () => {
		const date = this.state.date.clone();
		const filters = this.props.filters;

		if (this.props.mode === STAFFCHART_MODES.WEEKS) {
			let startDate =
				filters && filters.startDate
					? moment(filters.startDate)
					: date.clone().startOf('week');
			let endDate =
				filters && filters.endDate ? moment(filters.endDate) : date.clone().endOf('week');

			const days = [];
			let day = startDate;

			while (day <= endDate) {
				days.push(day.toDate());
				day = day.clone().add(1, 'days');
			}

			const borderRight = (isLast) => (isLast ? {} : { borderRight: 'none' });
			const borderLeft = (isFirst) => (isFirst ? { borderLeft: 'none' } : {});

			if (!isEmptyObject(days)) {
				return days.map((day, index) => (
					<div
						className="day"
						style={{
							...borderLeft(index === 0),
							...borderRight(index === days.length - 1)
						}}
						key={'day' + index}>
						{moment(day).isSame(moment(), 'day')
							? `${moment(day).format('DD.MM')} (${i18n.t(
									'translation:taskManager.common.table.today'
							  )})`
							: moment(day).format('DD.MM')}
					</div>
				));
			} else {
				return <div className="day w-100" />;
			}
		} else {
			let startDate =
				filters && filters.startDate
					? moment(filters.startDate).clone().startOf('day')
					: date.clone().startOf('day');
			let endDate =
				filters && filters.endDate
					? moment(filters.endDate).clone().endOf('day')
					: date.clone().endOf('day');

			const hours = [];
			let hour = startDate;

			while (hour <= endDate) {
				hours.push(hour.toDate());
				hour = hour.clone().add(1, 'hours');
			}

			const borderRight = (isLast) => (isLast ? {} : { borderRight: 'none' });
			const borderLeft = (isFirst) => (isFirst ? { borderLeft: 'none' } : {});

			if (!isEmptyObject(hours)) {
				return hours.map((hour, index) => (
					<div
						className="hour"
						style={{
							...borderLeft(index === 0),
							...borderRight(index === hours.length - 1)
						}}
						key={'day' + index}>
						{moment(hour).format('HH:mm')}
					</div>
				));
			} else {
				return <div className="hour w-100" />;
			}
		}
	};

	renderData = () => {
		const data = this.props.chartData;
		return data.map((dataRow, index) => {
			return this.renderGroup(dataRow, index, 0);
		});
	};

	renderContent = (data) => {
		let badgeClassName = (day) =>
			day.workAllocation === WORK_ALLOCATIONS.UNDERUSED
				? 'chart-badge-day blue'
				: day.workAllocation === WORK_ALLOCATIONS.OUTSIDE_ASSIGNED
				? 'chart-badge-day yellow'
				: day.workAllocation === undefined ||
				  day.workAllocation === null ||
				  day.workAllocation === WORK_ALLOCATIONS.FULLY_UTILIZED
				? 'chart-badge-day'
				: 'chart-badge-day red';

		if (this.props.mode === STAFFCHART_MODES.WEEKS) {
			const date = this.state.date.clone();
			const filters = this.props.filters;
			let startDate =
				filters && filters.startDate
					? moment(filters.startDate)
					: date.clone().startOf('week');
			let endDate =
				filters && filters.endDate ? moment(filters.endDate) : date.clone().endOf('week');

			const weekends = this.props.weekends;
			const days = [];
			let day = startDate;

			while (day <= endDate) {
				days.push(day.toDate());
				day = day.clone().add(1, 'd');
			}

			const dayData = (day) =>
				data && data[1].length
					? data[1].filter((dayData) => {
							return dayData.date === moment(day).format(DEFAULT_BACKEND_DATE_FORMAT);
					  })
					: [{}];

			const badge = (day, index) => {
				day = dayData(day);

				if (day[0]) {
					return (
						<div
							className={badgeClassName(day[0], index)}
							onClick={() =>
								day[0].workAllocation === WORK_ALLOCATIONS.UNDERUSED
									? // console.log('OPEN EDIT MODAL')
									  this.openEditStaffTaskModal(day[0])
									: console.log('no')
							}>
							{day[0].duration}
						</div>
					);
				}
			};

			if (days) {
				return days.map((day, index) => {
					const dayOfWeek = moment(day).day() ? moment(day).day() : 7;
					const className = weekends.includes(dayOfWeek)
						? 'chart-data-day light-blue'
						: 'chart-data-day';
					return (
						<div className={className} key={'day' + index}>
							{badge(day, index)}
						</div>
					);
				});
			}
		} else {
			const date = this.state.date.clone();
			const filters = this.props.filters;
			let startDate =
				filters && filters.startDate
					? moment(filters.startDate).clone().startOf('day')
					: date.clone().startOf('day');
			let endDate =
				filters && filters.endDate
					? moment(filters.endDate).clone().endOf('day')
					: date.clone().endOf('day');

			const hours = [];
			let hour = startDate;

			while (hour <= endDate) {
				hours.push(hour.toDate());
				hour = hour.clone().add(1, 'h');
			}

			const hourData = (hour) =>
				data && data[1].length
					? data[1].filter((hourData) => {
							return hourData.time === moment(hour).format(DEFAULT_TIME_FORMAT);
					  })
					: [{}];

			const badge = (hour, index) => {
				hour = hourData(hour);

				if (hour[0]) {
					return (
						<div
							className={badgeClassName(hour[0], index)}
							onClick={() =>
								hour[0].workAllocation === WORK_ALLOCATIONS.UNDERUSED
									? this.openEditStaffTaskModal(null, hour[0])
									: console.log('no hour')
							}>
							{hour[0].duration}
						</div>
					);
				}
			};

			if (hours && data[1]) {
				return hours.map((hour, index) => {
					const className = 'chart-data-hour';
					return (
						<div className={className} key={'day' + index}>
							{badge(hour, index)}
						</div>
					);
				});
			}
		}
	};

	renderGroup = (primaryData, parentIndex, levelIndex, index = 0) => {
		let primaryRow;

		parentIndex = parentIndex.toString();

		const elementIndex = parentIndex + '-' + index;

		const uniqueIndex = levelIndex.toString() + '-' + index.toString() + '-' + parentIndex;
		if (Array.isArray(primaryData[0])) {
			if (
				this.props.dropdown &&
				!this.props.dropdown[parentIndex] &&
				parentIndex.split('-').length > 1
			) {
				return;
			}

			let className =
				this.props.dropdown && !this.props.dropdown[elementIndex]
					? 'collapsed chart-row level-' + levelIndex
					: 'chart-row level-' + levelIndex;

			// const colSpan = this.props.columns.length + 1;
			// const noItemsMessage = this.props.noItemsMessage ? this.props.noItemsMessage :
			// i18n.t('translation:common.table.noItems'); const noItems = [<tr className={className}
			// key={uniqueIndex}><td className={'text-center'} colSpan={colSpan}>{noItemsMessage}</td></tr>]

			const secondaryRows = primaryData[0].length
				? primaryData[0].map((currentData, index) => {
						return this.renderGroup(currentData, elementIndex, levelIndex + 1, index);
				  })
				: this.props.dropdown && !this.props.dropdown[elementIndex]
				? []
				: [];

			primaryData.shift();

			primaryData.push('');

			primaryRow = (
				<div className={className} key={uniqueIndex}>
					{this.renderContent(primaryData)}
				</div>
			);

			return [primaryRow, ...secondaryRows];
		} else {
			if (this.props.dropdown && !this.props.dropdown[parentIndex]) {
				return;
			}

			primaryData.push('');

			const content = this.renderContent(primaryData);

			return (
				<div key={uniqueIndex} className={'chart-row level-' + levelIndex}>
					{content}
				</div>
			);
		}
	};
}

export default StaffChart;
