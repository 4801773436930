import { GET_TEMPLATES } from '../../actions/types';

const initState = [];

const templateReducer = (state = [...initState], action) => {
	if (action.type === GET_TEMPLATES) {
		state = action.payload;
		return [...state];
	}

	return state;
};

export default templateReducer;
