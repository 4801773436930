import React, { Fragment } from 'react';
import { getSelectedTaxType, isEmptyObject } from '../../../../utils/appUtils';
import { PAGINATION_TYPES, SORT_TYPES } from '../../../../utils/constants';
import { parseInvoicingOrders } from '../../../../utils/tableUtils';
import i18n from '../../../../i18n';
import Table from '../../../common/Table';
import Pagination from '../../../common/Pagination';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { getBillingOrders, setPagination } from '../../../../actions';
import { SEARCH_BILLING_ORDERS } from '../../../../actions/types';
import store from '../../../../store';

class InvoicingOrdersTable extends React.Component {
	componentDidMount() {
		this.props.getBillingOrders(
			this.props.page || 1,
			this.props.filters,
			this.props.account.labId,
			false,
			(responseData) => {
				store.dispatch({
					type: SEARCH_BILLING_ORDERS,
					payload: responseData
				});
			}
		);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.page && this.props.page !== 1 && isEmptyObject(this.props.orders)) {
			this.props.setPagination(PAGINATION_TYPES.BILLING_ORDERS, this.props.page - 1);
			return;
		}

		if (prevProps.page !== this.props.page || this.props.filters !== prevProps.filters) {
			this.props.getBillingOrders(
				this.props.page || 1,
				this.props.filters,
				this.props.account.labId,
				false,
				(responseData) => {
					store.dispatch({
						type: SEARCH_BILLING_ORDERS,
						payload: responseData
					});
				}
			);
		}
	}

	render() {
		return (
			<Fragment>
				{this.renderOrders()}
				{this.renderPagination()}
			</Fragment>
		);
	}

	renderOrders = () => {
		const columnsNames = [
			i18n.t('translation:billingModule.common.table.number'),
			i18n.t('translation:billingModule.common.table.client'),
			i18n.t('translation:billingModule.common.taxBase'),
			i18n.t('translation:billingModule.common.table.invoicedAmount'),
			i18n.t('translation:billingModule.common.table.remainingAmount'),
			i18n.t('translation:billingModule.common.table.paymentDateTo'),
			i18n.t('translation:billingModule.common.table.status')
		];

		const ordersFromState = this.props.orders.content ? this.props.orders.content : [];
		const orders = parseInvoicingOrders(
			ordersFromState,
			this.props.onSelectOrder,
			this.props.formValues,
			false
		);

		return (
			<Fragment>
				<div className="col-12 mt-s p-0">
					<Table columns={columnsNames} data={orders} />
				</div>
			</Fragment>
		);
	};

	renderPagination = () => {
		const { orders } = this.props;
		if (orders) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={orders.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.BILLING_ORDERS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		page: state.pagination[PAGINATION_TYPES.BILLING_ORDERS],
		orders: state.billingDocument.orders,
		formValues: getFormValues('tableForm')(state) || {},
		billingSettings: state.billingSettings
	};
};

export default connect(mapStateToProps, {
	getBillingOrders,
	setPagination
})(InvoicingOrdersTable);
