import { getAxiosWithoutToken, getAxiosWithToken } from '../utils/webApi';
import store from '../store';
import {
	ACCESSING_SITE_FOR_FIRST_TIME,
	ATTEMPT_LOGIN,
	CLEAR_REDUCER,
	LOAD_ACCOUNT,
	LOAD_TOKEN,
	LOGOUT,
	MARK_SITE_AS_ALREADY_ACCESSED,
	TOGGLE_LOGIN
} from './types';
import navigationUtils from '../utils/navigationUtils';
import {
	ACCESSING_SITE_FOR_FIRST_TIME_IN_LOCAL_STORAGE,
	CURRENT_USER_IN_LOCAL_STORAGE,
	EMPTY_STRING,
	PAGES_PATHS,
	TOKEN_ITEM_IN_LOCAL_STORAGE
} from '../utils/constants';
import { isEmptyObject, showSuccessPopup } from '../utils/appUtils';
import { createFileFromBase64ImageData } from '../utils/fileUtils';
import i18n from '../i18n';
import { reset } from 'redux-form';
import { hideMessageModal, showMessageModal } from './portalActions';
import DefaultButton from '../components/common/DefaultButton';
import React from 'react';
import { clearReducers } from './common';
import { clearPagination } from './paginationActions';
import { clearSort } from './sortActions';

const setToken = (token) => localStorage.setItem(TOKEN_ITEM_IN_LOCAL_STORAGE, token);

const removeToken = () => {
	localStorage.removeItem(TOKEN_ITEM_IN_LOCAL_STORAGE);

	// IMPORTANT: making sure to send action type that will immediately remove the token value
	// in the global cache (Redux store). By doing this, we ensure that there will
	// be no weird case when we send a request to the server with the old token
	// after the user has re-logged in and received a new token
	// Additionally, we are clearing the reducers, so there are no race conditions which will
	// cause the use of "stale" data from the previously logged-in account
	// after re-login with different account
	return [
		{
			type: LOAD_TOKEN,
			payload: EMPTY_STRING
		},
		{
			type: CLEAR_REDUCER
		}
	];
};

export const setCurrentUser = (currentUser) =>
	localStorage.setItem(CURRENT_USER_IN_LOCAL_STORAGE, JSON.stringify(currentUser));

const removeCurrentUser = () => localStorage.removeItem(CURRENT_USER_IN_LOCAL_STORAGE);

export const isAccessingSiteForFirstTime = () => async (dispatch) => {
	const storageValue = localStorage.getItem(ACCESSING_SITE_FOR_FIRST_TIME_IN_LOCAL_STORAGE);
	const booleanValue = storageValue != null ? JSON.parse(storageValue) : null;

	var accessingForFirstTime = booleanValue == null || booleanValue;

	dispatch({ type: ACCESSING_SITE_FOR_FIRST_TIME, payload: accessingForFirstTime });
};

export const markSiteAsAlreadyAccessed = () => {
	localStorage.setItem(ACCESSING_SITE_FOR_FIRST_TIME_IN_LOCAL_STORAGE, JSON.stringify(false));
	return {
		type: MARK_SITE_AS_ALREADY_ACCESSED
	};
};

export const loadAccount = () => async (dispatch) => {
	const userDataInJson = localStorage.getItem(CURRENT_USER_IN_LOCAL_STORAGE);
	const account = userDataInJson != null ? JSON.parse(userDataInJson) : null;
	if (account) {
		dispatch({ type: LOAD_ACCOUNT, payload: account });
	}
	return account;
};

export const isAuthenticated = () => {
	return !isEmptyObject(store.getState().account);
};

export const loadToken = () => async (dispatch) => {
	const token = localStorage.getItem(TOKEN_ITEM_IN_LOCAL_STORAGE);
	if (token) {
		dispatch({ type: LOAD_TOKEN, payload: token });
	}
	return token;
};

export const attemptLogin = (usernameOrEmail, password) => async (dispatch) => {
	const response = await getAxiosWithoutToken().post(
		'/user/login',
		JSON.stringify({
			usernameOrEmail,
			password
		})
	);
	let returnedData = response.data;
	if (returnedData.success) {
		setToken(returnedData.data.token.value);
		setCurrentUser(returnedData.data.user);
		dispatch({
			type: ATTEMPT_LOGIN,
			payload: returnedData.data
		});
		navigationUtils.navigate(PAGES_PATHS.HOME);
	}
};

export const frontEndLogout = () => async (dispatch) => {
	removeToken();
	removeCurrentUser();
	dispatch({
		type: LOGOUT
	});
	dispatch(clearReducers());
	dispatch(clearPagination());
	dispatch(clearSort());
	navigationUtils.navigate(PAGES_PATHS.HOME);
};

export const logout = () => async (dispatch) => {
	await getAxiosWithToken().delete('/user/log-out');
	dispatch(frontEndLogout());
};

export const sendForgottenPasswordEmail = (email) => async (dispatch) => {
	const response = await getAxiosWithoutToken().get('/user/forgot-password', {
		params: { email }
	});
	if (response.data.success) {
		if (response.data.success) {
			const successButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.ok')}
					onClick={() => {
						navigationUtils.navigate(PAGES_PATHS.HOME);
						dispatch(hideMessageModal());
					}}
				/>
			);
			dispatch(
				showMessageModal(
					i18n.t('translation:common.success'),
					i18n.t('translation:auth.successfulSendRestorePasswordEmail'),
					[successButton]
				)
			);
		}
	}
};

export const toggleLogin = () => {
	return {
		type: TOGGLE_LOGIN
	};
};

export const newPassword = (newPassword, encryptedValue) => async (dispatch) => {
	const response = await getAxiosWithoutToken().post('/user/reset-password', {
		newPassword,
		userIdentification: encryptedValue
	});
	if (response.data.success) {
		if (response.data.success) {
			window.confirm(i18n.t('translation:auth.successfulRestoredPassword'));
			navigationUtils.navigate(PAGES_PATHS.HOME);
		}
	}
};

export const changePassword = (currentPassword, newPassword) => async (dispatch) => {
	const response = await getAxiosWithToken().post('/user/change-password', {
		currentPassword,
		newPassword
	});
	if (response.data.success) {
		dispatch(reset('userDetailsForm'));
		showSuccessPopup(i18n.t('translation:users.successfulChangedPassword'));
	}
};

export const signUp = (user, avatarImageData, fileType) => async (dispatch) => {
	let formData = new FormData();
	formData.append('user', JSON.stringify(user));
	if (avatarImageData) {
		const fileExtension = fileType.split('/')[1];
		formData.append('avatar-file', createFileFromBase64ImageData(avatarImageData));
		formData.append('avatar-data', JSON.stringify({ fileExtension }));
	}

	const response = await getAxiosWithoutToken().post('/user/sign-up', formData, {
		'Content-Type': 'multipart/form-data'
	});

	if (response.data.success) {
		const successButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.ok')}
				onClick={() => {
					navigationUtils.navigate(PAGES_PATHS.HOME);
					dispatch(hideMessageModal());
				}}
			/>
		);
		dispatch(
			showMessageModal(
				i18n.t('translation:auth.successfullyCreatedAccountTitle'),
				i18n.t('translation:auth.successfullyCreatedAccountMessage'),
				[successButton]
			)
		);
	}
};
