import {
	CLEAR_TASKS_REDUCER,
	GET_AVAILABLE_STAFF,
	GET_CURRENT_TEMPLATE,
	GET_CURRENT_TEMPLATE_STATUS,
	GET_CURRENT_TEMPLATE_TASKS,
	GET_PRODUCT_TEMPLATES
} from '../../actions/types';

const initState = {
	labStaff: [],
	templates: [],
	isExcludedFromPlanning: false,
	currentTemplate: {},
	currentTasks: []
};

const addTasksReducer = (state = { ...initState }, action) => {
	if (action.type === GET_AVAILABLE_STAFF) {
		state.labStaff = [...action.payload];
		return { ...state };
	}
	if (action.type === GET_PRODUCT_TEMPLATES) {
		state.templates = [...action.payload];
		return { ...state };
	}
	if (action.type === GET_CURRENT_TEMPLATE) {
		state.currentTemplate = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_CURRENT_TEMPLATE_TASKS) {
		state.currentTasks = [...action.payload];
		return { ...state };
	}

	if (action.type === GET_CURRENT_TEMPLATE_STATUS) {
		state.isExcludedFromPlanning = action.payload;
		return { ...state };
	}

	if (action.type === CLEAR_TASKS_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default addTasksReducer;
