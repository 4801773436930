import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import SubHeader from '../../common/SubHeader';
import Table from '../../common/Table';
import LinkedClinics from './LinkedClinics';
import { parseInventory, parseLabStaff } from '../../../utils/tableUtils';
import CompanyDetails from '../common/CompanyDetails';
import CompanyLocations from '../common/CompanyLocations';
import DefaultButton from '../../common/DefaultButton';
import { submit } from 'redux-form';
import store from '../../../store';
import {
	getCompanyActivateButtonTitle,
	getCompanyStatusText,
	isCompanyActive,
	isEmptyObject,
	isGlobalAdmin,
	isLocalAdmin,
	isMyCompany,
	isNew,
	isWaitingForApproval,
	registeredInClinic
} from '../../../utils/appUtils';
import navigationUtils from '../../../utils/navigationUtils';
import {
	activateLab,
	areClinicAndLabLinked,
	clearReducers,
	createOrder,
	deactivateLab,
	denyLab,
	getLabDetails,
	getLabInventoryOnly,
	getLabModules,
	getLabStaff,
	hideMessageModal,
	showMessageModal
} from '../../../actions';
import { COMPANY_STATUS, PAGES_PATHS } from '../../../utils/constants';
import ActiveUsersSection from '../common/ActiveUsersSection';
import PlannerSection from '../taskManager/labs/PlannerSection';
import ActivateModuleSection from '../taskManager/labs/ActivateModuleSection';
import CalendarSection from '../taskManager/labs/CalendarSection';

class LabDetails extends React.Component {
	UNSAFE_componentWillMount() {
		const route = navigationUtils.getRoute();
		const labIdParam = this.props.match.params.id;

		if (labIdParam === 'create') {
			return;
		}

		const labId =
			route === PAGES_PATHS.MY_LABORATORY_DETAILS ? this.props.account.labId : labIdParam;

		this.props.getLabModules(labId);
	}

	componentDidMount() {
		const route = navigationUtils.getRoute();
		if (!isNew(route)) {
			const labId =
				route === PAGES_PATHS.MY_LABORATORY_DETAILS
					? this.props.account.labId
					: this.props.match.params.id;
			this.props.getLabDetails(labId);
			if (isGlobalAdmin(this.props.account)) {
				this.props.getLabStaff(labId);
			}
			if (registeredInClinic(this.props.account)) {
				this.props.getLabInventoryOnly(labId, true, this.props.account.clinicId);
				this.props.areClinicAndLabLinked(this.props.account.clinicId, labId);
			}
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<Header
					title={this.getHeaderTitle()}
					backButton
					buttons={[
						this.renderActivateLabButton(),
						this.renderDenyLabButton(),
						this.renderSaveLabButton(),
						this.renderOrderButton()
					]}
				/>
				<div className="col-12 p-0">
					<hr />
					<div className="row">
						<CompanyDetails commonInformation={() => this.renderGeneralInformation()} />
					</div>
					<div className="row">
						<ActiveUsersSection
							company={this.props.currentLab}
							initialValues={{ maxNumber: this.props.currentLab.maxActiveUsers }}
						/>
						<ActivateModuleSection
							company={this.props.initialValues}
							account={this.props.account}
							isLabCreation={this.props.match.params.id === 'create'}
						/>
					</div>
					<div className="row">
						<PlannerSection company={this.props.currentLab} />
						<CalendarSection company={this.props.currentLab} />
					</div>
					<div className="row">
						<div className="col-12">{this.renderInventory()}</div>
					</div>
					<div className="row">
						<div className="col-12">
							<CompanyLocations
								locations={
									isNew(navigationUtils.getRoute())
										? []
										: this.props.currentLab.locations
								}
							/>
						</div>
					</div>
					{this.renderLinkedClinics()}
					<div className="row">
						<div className="col-12">{this.renderUsers()}</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getHeaderTitle = () => {
		if (isNew(navigationUtils.getRoute())) {
			return i18n.t('translation:labs.createNewLab');
		}

		const lab = this.props.currentLab;
		if (isEmptyObject(lab)) {
			return '';
		}
		const statusText = !isCompanyActive(lab) ? `(${getCompanyStatusText(lab)})` : '';
		return `${lab.name} ${statusText}`;
	};

	renderLinkedClinics = () => {
		if (isGlobalAdmin(this.props.account)) {
			return (
				<div className="row">
					<div className="col-12">
						<LinkedClinics />
					</div>
				</div>
			);
		}
	};

	renderSaveLabButton = () => {
		if (!isMyCompany() && !isGlobalAdmin(this.props.account)) {
			return <Fragment />;
		}

		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.submit('companyDetailsForm');
				}}
			/>
		);
	};

	renderActivateLabButton = () => {
		if (!isGlobalAdmin(this.props.account) || isNew(navigationUtils.getRoute())) {
			return <Fragment />;
		}

		const lab = this.props.currentLab;
		const buttonTitle = getCompanyActivateButtonTitle(lab);

		return <DefaultButton title={buttonTitle} onClick={this.onActivateButtonPress} />;
	};

	renderDenyLabButton = () => {
		if (
			!isGlobalAdmin(this.props.account) ||
			isNew(navigationUtils.getRoute()) ||
			!isWaitingForApproval(this.props.currentLab)
		) {
			return <Fragment />;
		}

		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.deny')}
				danger
				onClick={this.onDenyButtonPress}
			/>
		);
	};

	onDenyButtonPress = () => {
		const lab = this.props.currentLab;

		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					this.props.denyLab(lab.id);
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			i18n.t('translation:labs.denyLabWarning'),
			[yesButton, noButton]
		);
	};

	onActivateButtonPress = () => {
		const lab = this.props.currentLab;
		const waitingForApproval = lab.status === COMPANY_STATUS.WAITING_FOR_APPROVAL;
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					if (isCompanyActive(lab)) {
						this.props.deactivateLab(lab.id);
					} else {
						this.props.activateLab(lab.id, waitingForApproval);
					}
					this.props.hideMessageModal();
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);

		const confirmationMessage = isCompanyActive(lab)
			? i18n.t('translation:labs.deactivateLabWarning')
			: i18n.t('translation:labs.activateLabWarning');
		this.props.showMessageModal(
			i18n.t('translation:common.confirmation'),
			confirmationMessage,
			[yesButton, noButton]
		);
	};

	renderOrderButton = () => {
		if (!registeredInClinic(this.props.account) || !this.props.clinicAndLabLinked) {
			return <Fragment />;
		}

		const clinicId = store.getState().account.clinicId;
		const { currentLab } = this.props;

		return (
			<DefaultButton
				title={i18n.t('translation:labs.buttons.createOrder')}
				onClick={() => {
					this.props.createOrder(clinicId, currentLab.id);
				}}
				disabled={!isCompanyActive(currentLab)}
			/>
		);
	};

	renderGeneralInformation = () => {
		if (
			(!isMyCompany() && !isGlobalAdmin(this.props.account)) ||
			isNew(navigationUtils.getRoute())
		) {
			return <Fragment />;
		}

		return (
			<div className={'col-12 align-self-end p-0'}>
				<SubHeader title={i18n.t('translation:common.generalInformation')} first />
				<div className="col-12 data-section mt-s p-0">
					<div className="content h-s">
						<div className="data-row">
							<label className="input-label">
								{i18n.t('translation:common.registrationCode')}
							</label>
							<span>{this.props.currentLab.registrationCode}</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderInventory = () => {
		if (registeredInClinic(this.props.account)) {
			const { inventory, account, currentLab } = this.props;

			const preparedInventory = parseInventory(inventory, account, currentLab);
			const columns = [
				i18n.t('translation:inventory.table.catalogNumber'),
				i18n.t('translation:inventory.table.type'),
				i18n.t('translation:inventory.table.material'),
				i18n.t('translation:inventory.table.category')
			];

			if (registeredInClinic(account) && isLocalAdmin(account)) {
				columns.push(i18n.t('translation:common.table.warranty'));
				columns.push(i18n.t('translation:common.table.price'));
			}

			return (
				<Fragment>
					<SubHeader title={i18n.t('translation:inventory.inventory')} />
					<Table
						columns={columns}
						data={preparedInventory}
						lab={currentLab}
						onSubmit={this.onTableFormSubmit}
						className={'mt-s'}
					/>
				</Fragment>
			);
		}
	};

	renderUsers = () => {
		if (isNew(navigationUtils.getRoute()) || !isGlobalAdmin(this.props.account)) {
			return;
		}

		const users = parseLabStaff(this.props.users);

		return (
			<Fragment>
				<SubHeader title={i18n.t('translation:common.users')} />
				<div className="col-12 mt-s p-0">
					<Table
						columns={[
							i18n.t('translation:common.table.name'),
							i18n.t('translation:common.table.phone'),
							i18n.t('translation:common.table.email'),
							i18n.t('translation:common.table.role'),
							i18n.t('translation:common.table.status')
						]}
						data={users}
					/>
				</div>
			</Fragment>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		currentLab: state.labs.currentLab,
		settings: state.settings,
		initialValues: state.labs.currentLab,
		modules: state.modules,
		users: state.users.users.content,
		inventory: state.inventory.labInventory,
		clinicAndLabLinked: state.invitations.clinicAndLabLinked
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	showMessageModal,
	hideMessageModal,
	getLabStaff,
	getLabInventoryOnly,
	activateLab,
	deactivateLab,
	areClinicAndLabLinked,
	clearReducers,
	createOrder,
	denyLab,
	submit,
	getLabModules
})(LabDetails);
