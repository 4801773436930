import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common/Header';
import i18n from '../../../../../i18n';
import {
	getLabId,
	getLanguageCode,
	isEmptyObject,
	isGlobalAdmin,
	isNumeric,
	isTaskManagerEnabled
} from '../../../../../utils/appUtils';
import NotFound from '../../../../errors/NotFound';
import UsersTable from './UsersTable';
import {
	clearReducers,
	getAbsenceTypes,
	getLabDetails,
	getLabModules,
	getShiftsAndAbsences,
	getStaff
} from '../../../../../actions';
import ShiftsTable from './ShiftsTable';
import Filters from './Filters';
import moment from '../../../../../utils/moment';
import StatisticTable from './StatisticTable';
import DefaultButton from '../../../../common/DefaultButton';
import {
	COMMON_REDUCER_TYPES,
	DEFAULT_BACKEND_DATE_FORMAT,
	LANGUAGE_CODES
} from '../../../../../utils/constants';
import { getFormValues } from 'redux-form';
import ExortModal from './ExportModal';
import AbsenceBadges from '../../../../common/AbsenceBadges';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

class Staff extends React.Component {
	constructor(props) {
		super(props);
		this.state = { date: moment(), shiftsAndAbsences: [], filters: {}, isModalOpen: false };
	}

	componentDidMount() {
		const labId = getLabId(this.props.location, this.props.account);
		this.props.getLabModules(labId);
		this.props.getShiftsAndAbsences(labId).then((resp) => {
			this.setState({ shiftsAndAbsences: resp });
		});

		const startDate = moment(this.state.date)
			.startOf('month')
			.format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(this.state.date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);

		this.props.getStaff({ startDate, endDate }, labId);
		this.props.getAbsenceTypes();

		if (isGlobalAdmin(this.props.account)) {
			this.props.getLabDetails(labId);
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <NotFound />;
		}

		const labId = getLabId(this.props.location, this.props.account);
		const simpleTitle = i18n.t('translation:taskManager.planner.employees');
		const headerTitle = isGlobalAdmin(this.props.account)
			? this.props.currentLab.name + ' - ' + simpleTitle
			: simpleTitle;
		const backButtonShown = isGlobalAdmin(this.props.account) ? true : false;

		return (
			<Fragment>
				<ExortModal
					isModalOpen={this.state.isModalOpen}
					closeModal={this.closeModal}
					initialValues={this.getInitialValues()}
				/>
				<Header title={headerTitle} backButton={backButtonShown} />
				<Filters
					shiftsAndAbsences={this.state.shiftsAndAbsences}
					setFilters={this.setFilters}
					labId={labId}
				/>
				{this.renderTable()}
			</Fragment>
		);
	}

	getInitialValues = () => {
		const date = moment();
		const month = +date.format('MM');
		const year = +date.format('YYYY');
		return { month, year };
	};

	openModal = () => {
		this.setState({ isModalOpen: true });
	};

	closeModal = () => {
		this.setState({ isModalOpen: false });
	};

	renderExportButton =
		getLanguageCode() === LANGUAGE_CODES.BG ? (
			<DefaultButton
				title={i18n.t('translation:taskManager.planner.buttons.export')}
				onClick={this.openModal}
			/>
		) : null;

	renderTable = () => {
		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = isReadOnly ? ' read-only-field' : '';

		if (isEmptyObject(this.props.staff)) {
			return (
				<h5 className="text-center mt-s">{i18n.t('translation:common.table.noItems')}</h5>
			);
		} else {
			return (
				<div className="position-relative">
					<div className="header">
						<div
							className={
								'buttons ml-auto mt-s d-flex justify-content-end' + readOnlyClass
							}>
							<DefaultButton
								title={i18n.t('translation:taskManager.common.buttons.markDays')}
								onClick={() => {
									this.props.openModal();
								}}
							/>
							{this.renderExportButton}
						</div>
					</div>
					<ScrollSync horizontal={false}>
						<div className="staff d-flex fixed-header-chart planner-height">
							<ScrollSyncPane group="vertical">
								<UsersTable />
							</ScrollSyncPane>
							<ShiftsTable
								shiftsAndAbsences={this.state.shiftsAndAbsences}
								date={moment(this.state.date)}
								filters={this.state.filters}
								getStaff={this.getStaff}
								addMonth={this.addMonth}
								subtractMonth={this.subtractMonth}
								openModal={this.openModal}
								staff={this.props.staff}
								setOpenMultiMarkDaysModal={(openModal) =>
									(this.openModal = openModal)
								}
							/>
							<ScrollSyncPane group="vertical">
								<StatisticTable />
							</ScrollSyncPane>
							<div className="absence-badges mb-m">
								<AbsenceBadges
									absences={this.props.absences}
									workedDaysAbsence={true}
								/>
							</div>
						</div>
					</ScrollSync>
				</div>
			);
		}
	};

	setFilters = (filters) => {
		this.getStaff();
		this.setState({ filters });
	};

	addMonth = () => {
		const date = this.state.date.add(1, 'M');
		const startDate = moment(date).startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		const labId = getLabId(this.props.location, this.props.account);
		this.props.getStaff({ startDate, endDate }, labId);
		this.setState({ date });
	};

	subtractMonth = () => {
		const date = this.state.date.subtract(1, 'M');
		const startDate = moment(date).startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		const endDate = moment(date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		const labId = getLabId(this.props.location, this.props.account);
		this.props.getStaff({ startDate, endDate }, labId);
		this.setState({ date });
	};

	getStaff = () => {
		const filters = this.props.filters;
		if (!isEmptyObject(filters)) {
			const date = { ...filters };
			if (!isNumeric(filters.shiftAndAbsence)) {
				date.absenceType = filters.shiftAndAbsence;
				date.shiftId = null;
			} else {
				date.absenceType = null;
				date.shiftId = filters.shiftAndAbsence;
			}
			delete date.shiftAndAbsence;
			const startDate = filters.startDate
				? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
				: moment(this.state.date).startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate = filters.endDate
				? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
				: moment(this.state.date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
			const labId = getLabId(this.props.location, this.props.account);
			this.props.getStaff({ ...date, startDate, endDate }, labId);
		} else {
			const startDate = moment(this.state.date)
				.startOf('month')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			const endDate = moment(this.state.date)
				.endOf('month')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			const labId = getLabId(this.props.location, this.props.account);
			this.props.getStaff({ startDate, endDate }, labId);
		}
	};
}

const mapStateToProps = (state) => {
	return {
		modules: state.modules,
		absences: state.absences,
		account: state.account,
		filters: getFormValues('staffFiltersForm')(state),
		openModal: state.common[COMMON_REDUCER_TYPES.OPEN_MODAL],
		staff: state.staff,
		currentLab: state.labs.currentLab
	};
};

export default connect(mapStateToProps, {
	getStaff,
	getAbsenceTypes,
	clearReducers,
	getShiftsAndAbsences,
	getLabModules,
	getLabDetails
})(Staff);
