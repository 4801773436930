import React, { Fragment } from 'react';
import CompanyLocations from './CompanyLocations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CompanyDataSection extends React.Component {
	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content">
					<div className="row m-0">
						{this.props.columns.map((column, index) => {
							return (
								<Fragment key={index + 'column'}>
									{this.renderColumn(
										column,
										`col-${12 / this.props.columns.length} pl-0`
									)}
								</Fragment>
							);
						})}
					</div>
					<div className="row m-0">
						<CompanyLocations locations={this.props.locations} dataSection />
					</div>
				</div>
			</div>
		);
	}

	renderColumn = (data, className) => {
		data = data.map((element, index) => {
			return (
				<div className="data-col" key={index + 'column'}>
					<FontAwesomeIcon icon={element.icon} className="label-icons" />
					<label className="blue-label">{element.title}</label>
					{typeof element.value === 'object' && element.value ? (
						element.value.map((value, index) => (
							<span key={index + 'columnElement'}>{value}</span>
						))
					) : (
						<span>{element.value}</span>
					)}
				</div>
			);
		});
		return <div className={className}>{data}</div>;
	};
}

export default CompanyDataSection;
