import {
	ATTEMPT_LOGIN,
	CLEAR_ACCOUNT_LAB_ID,
	LOAD_ACCOUNT,
	LOAD_ACCOUNT_LAB_ID,
	LOGOUT
} from '../actions/types';

const initialState = {};

const accountReducer = (state = { ...initialState }, action) => {
	if (action.type === LOAD_ACCOUNT) {
		state = action.payload;
		return { ...state };
	}

	if (action.type === LOAD_ACCOUNT_LAB_ID) {
		state.labId = action.payload;
		return { ...state };
	}
	if (action.type === CLEAR_ACCOUNT_LAB_ID) {
		state.labId = null;
		console.log('state', state);
		return { ...state };
	}

	if (action.type === ATTEMPT_LOGIN) {
		state = action.payload.user;
		return { ...state };
	}

	if (action.type === LOGOUT) {
		state = {};
		return { ...state };
	}

	return state;
};

export default accountReducer;
