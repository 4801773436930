import React, { Fragment } from 'react';
import Table from '../../common/Table';
import i18n from '../../../i18n';
import { connect } from 'react-redux';
import { parseHistory } from '../../../utils/tableUtils';

class History extends React.Component {
	render() {
		return <Fragment>{this.renderHistory()}</Fragment>;
	}

	renderHistory = () => {
		const history = parseHistory(this.props.history);
		return (
			<Table
				columns={[
					i18n.t('translation:orders.table.date'),
					i18n.t('translation:orders.table.changedBy'),
					i18n.t('translation:orders.table.description')
				]}
				className="history"
				data={history}
			/>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		history: state.orders.currentOrder.changeHistory,
		settings: state.settings
	};
};

export default connect(mapStateToProps)(History);
