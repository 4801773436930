import React, { Fragment } from 'react';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import { PAGES_PATHS } from '../../../utils/constants';
import navigationUtils from '../../../utils/navigationUtils';
import ClinicsTable from '../../common/ClinicsTable';

class Clinics extends React.Component {
	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:clinics.clinics')}
					buttons={[this.renderAddClinicButton()]}
				/>
				<div className="col-12 p-0">
					<hr />
					<ClinicsTable />
				</div>
			</Fragment>
		);
	}

	renderAddClinicButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				onClick={() => {
					navigationUtils.navigate(PAGES_PATHS.PRACTICE_CREATE);
				}}
			/>
		);
	};
}

export default Clinics;
