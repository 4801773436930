import axios from 'axios';
import { LOADER_TYPES, TEACS_BACK_END_BASE_URL } from './constants';
import { history } from '../index';
import i18n from '../i18n';
import store from '../store';
import { parseFlagToLanguage } from './appUtils';
import {
	frontEndLogout,
	hideMessageModal,
	loadLanguage,
	showMessageModal,
	startLoader,
	stopLoader,
	stopLoaderImmediately
} from '../actions';
import DefaultButton from '../components/common/DefaultButton';
import React from 'react';

export const getAxiosWithoutToken = (showLoader = true) => {
	let axiosInstance = axios.create({
		baseURL: TEACS_BACK_END_BASE_URL,
		headers: {
			'Content-Type': 'application/json',
			'Accept-Language': parseFlagToLanguage(loadLanguage())
		}
	});
	axiosInstance.interceptors.request.use(
		(config) => {
			if (showLoader) {
				store.dispatch(startLoader(LOADER_TYPES.GLOBAL_LOADER));
			}
			return config;
		},
		(error) => {
			if (showLoader) {
				store.dispatch(stopLoader(LOADER_TYPES.GLOBAL_LOADER));
			}
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		(response) => {
			serverValidationErrorHandling(response);
			if (showLoader) {
				store.dispatch(stopLoader(LOADER_TYPES.GLOBAL_LOADER));
			}
			return response;
		},
		(error) => {
			serverDefaultErrorHandling(error);
			if (showLoader) {
				store.dispatch(stopLoader(LOADER_TYPES.GLOBAL_LOADER));
			}
			return Promise.reject(error);
		}
	);

	return axiosInstance;
};

// This is deliberately exported as function (instead of simply 'export axios.create(...)').
// The other version was causing a problem with the token - adding a ' ' in the beginning.
// And this is used as a workaround.
export const getAxiosWithToken = (validationErrors = true, showLoader = true, onStopLoading) => {
	let axiosInstance = axios.create({
		baseURL: TEACS_BACK_END_BASE_URL,
		headers: {
			Authorization: store.getState().token,
			'Accept-Language': parseFlagToLanguage(loadLanguage())
		}
	});

	// Used for global error handling for any error responses from the back-end API.
	axiosInstance.interceptors.request.use(
		(config) => {
			if (showLoader) {
				store.dispatch(startLoader(LOADER_TYPES.GLOBAL_LOADER));
			}
			return config;
		},
		(error) => {
			if (showLoader) {
				store.dispatch(stopLoader(LOADER_TYPES.GLOBAL_LOADER));
			}
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		(response) => {
			if (validationErrors) {
				serverValidationErrorHandling(response);
			}
			if (showLoader) {
				store.dispatch(stopLoader(LOADER_TYPES.GLOBAL_LOADER, onStopLoading));
			}
			return response;
		},
		(error) => {
			serverDefaultErrorHandling(error);
			return Promise.reject(error);
		}
	);

	return axiosInstance;
};

const serverValidationErrorHandling = (response) => {
	if (!response.data.success) {
		store.dispatch(stopLoaderImmediately(LOADER_TYPES.GLOBAL_LOADER));
		const closeButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					store.dispatch(hideMessageModal());
				}}
			/>
		);
		// Showing the localized error message (userMessage). 'message' property holds the message in English
		const description =
			response.data.userMessage || i18n.t('translation:common.errors.unhandledError');
		store.dispatch(
			showMessageModal(i18n.t('translation:common.errors.error'), description, [closeButton])
		);
	}
};

const serverDefaultErrorHandling = (error) => {
	store.dispatch(stopLoader(LOADER_TYPES.GLOBAL_LOADER));
	store.dispatch(stopLoader(LOADER_TYPES.COLOR_LOADER));
	store.dispatch(stopLoader(LOADER_TYPES.PRODUCT_LOADER));
	if (!error.response) {
		history.push('/500');
		return;
	}
	switch (error.response.status) {
		case 404:
			history.push('/404');
			break;
		case 401:
			store.dispatch(frontEndLogout());
			break;
		case 500:
			history.push('/500');
			break;
		default:
			history.push('/unhandledError');
			break;
	}

	return Promise.reject(error);
};
