import React, { Fragment } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, SubmissionError } from 'redux-form';
import { Fields } from '../../../common/Fields';
import i18n from '../../../../i18n';
import DefaultButton from '../../../common/DefaultButton';
import { formUtils } from '../../../../utils/formUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { createOverdueInterval, updateOverdueInterval } from '../../../../actions';
import { isEmptyObject } from '../../../../utils/appUtils';

class OverdueIntervalModal extends React.Component {
	state = {
		showErrorMessage: false,
		errorMessage: '',
		showServerErrorMessage: false,
		serverErrorMessage: ''
	};

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
			let refinedInitialValues = {};

			refinedInitialValues['overdueIntervalFromDay'] =
				this.props.initialValues.overdueIntervalFromDay;
			refinedInitialValues['overdueIntervalToDay'] =
				this.props.initialValues.overdueIntervalToDay;
			refinedInitialValues['overdueIntervalId'] = this.props.initialValues.overdueIntervalId;

			this.props.initialize(refinedInitialValues);

			this.clearErrorMessage();
		}
	}

	render() {
		const saveButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.onFormSubmit}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const modalTitle = i18n.t('translation:billingModule.settings.overdueIntervalModalTitle');

		return (
			<Modal
				className="custom-modal"
				overlayClassName="custom-overlay"
				isOpen={this.props.isModalOpen}>
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				{this.renderContent()}
				<div className="buttons">
					{saveButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	renderContent = () => {
		return (
			<Fragment>
				<form className="mb-4" onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{Fields.billingModule.settings.overdueIntervalFromDay({
						onChange: () => this.clearErrorMessage()
					})}
					{Fields.billingModule.settings.overdueIntervalToDay({
						onChange: () => this.clearErrorMessage()
					})}
					{this.state.showErrorMessage && (
						<div className="modal-error-message">
							{i18n.t(
								'translation:billingModule.settings.errors.invalidOverdueIntervalValue'
							)}
						</div>
					)}
					{<div className="modal-error-message">{this.state.serverErrorMessage}</div>}
				</form>
			</Fragment>
		);
	};

	onFormSubmit = () => {
		this.props.handleSubmit((formValues) => {
			let errors = {};

			if (
				Number(formValues.overdueIntervalFromDay) >= Number(formValues.overdueIntervalToDay)
			) {
				const errorMessage = i18n.t(
					'translation:billingModule.settings.errors.invalidOverdueIntervalValue'
				);
				errors['overdueIntervalFromDay'] = ' ';
				errors['overdueIntervalToDay'] = ' ';
				this.setState({ showErrorMessage: true, errorMessage: errorMessage });
				return;
			}

			for (let index in errors) {
				if (errors[index] == undefined) {
					delete errors[index];
				}
			}

			if (!isEmptyObject(errors)) {
				throw new SubmissionError({
					...errors,
					_error: 'error'
				});
			}

			const overdueInterval = {
				fromDay: Number(formValues.overdueIntervalFromDay),
				toDay: Number(formValues.overdueIntervalToDay)
			};

			if (this.props.initialValues.overdueIntervalId) {
				this.props.updateOverdueInterval(
					this.props.billingSettings.id,
					this.props.initialValues.overdueIntervalId,
					overdueInterval,
					this.closeModal,
					this.setServerErrorMessage
				);

				return;
			}

			this.props.createOverdueInterval(
				this.props.billingSettings.id,
				overdueInterval,
				this.closeModal,
				this.setServerErrorMessage
			);
		})();

		return;
	};

	closeModal = () => {
		this.setState({ selectedOverdueInterval: {} });
		this.props.destroy();
		this.props.closeModal();
	};

	setServerErrorMessage = (serverErrorMessage) => {
		this.setState({
			showServerErrorMessage: true,
			serverErrorMessage: serverErrorMessage
		});
	};

	clearErrorMessage = () => {
		this.setState({
			showErrorMessage: false,
			errorMessage: '',
			showServerErrorMessage: false,
			serverErrorMessage: ''
		});
	};
}

const overdueIntervalForm = reduxForm({
	form: 'overdueIntervalForm',
	validate: formUtils.validateOverdueInterval,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true
})(OverdueIntervalModal);

const mapStateToProps = (state, props) => {
	return {
		billingSettings: state.billingSettings,
		formValues: getFormValues('overdueIntervalForm')(state)
	};
};

export default connect(mapStateToProps, {
	createOverdueInterval,
	updateOverdueInterval
})(overdueIntervalForm);
