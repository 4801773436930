import React, { Fragment } from 'react';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import { parseLabStaff } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import SubHeader from '../../common/SubHeader';
import { connect } from 'react-redux';
import { clearReducers, getLabDetails, getLabStaff } from '../../../actions';
import {
	faBookOpen,
	faCreditCard,
	faDollarSign,
	faEnvelope,
	faKey,
	faMapMarkedAlt,
	faPhone,
	faTooth
} from '@fortawesome/free-solid-svg-icons';
import CompanyDataSection from './../common/CompanyDataSection';
import Orders from './../common/Orders';

class DashboardUser extends React.Component {
	state = { isMainSectionOpen: true };

	UNSAFE_componentWillMount() {
		this.props.getLabStaff(this.props.account.labId);
		this.props.getLabDetails(this.props.account.labId);
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:dashboard.myLaboratory')}
					onTrigger={this.triggerMainSection}
					isOpen={this.state.isMainSectionOpen}
				/>
				{this.renderMainSection()}
				<div className="col-12 p-0">
					<SubHeader title={i18n.t('translation:dashboard.myOrders')} />
					<Orders />
					<SubHeader title={i18n.t('translation:dashboard.users')} />
					<div className="col-12 mt-s p-0">{this.renderUsers()}</div>
				</div>
			</Fragment>
		);
	}

	triggerMainSection = () => {
		this.setState({ isMainSectionOpen: !this.state.isMainSectionOpen });
	};

	renderMainSection = () => {
		const { currentLab } = this.props;
		const firstColumn = [
			{
				title: i18n.t('translation:common.name'),
				value: currentLab.name,
				icon: faTooth
			},
			{
				title: i18n.t('translation:common.email'),
				value: currentLab.email,
				icon: faEnvelope
			},
			{
				title: i18n.t('translation:common.registrationCode'),
				value: currentLab.registrationCode,
				icon: faKey
			}
		];

		const secondColumn = [
			{
				title: i18n.t('translation:common.legalName'),
				value: currentLab.legalName,
				icon: faBookOpen
			},
			{
				title: i18n.t('translation:common.registeredOffice'),
				value: currentLab.registeredOffice,
				icon: faMapMarkedAlt
			},
			{
				title: i18n.t('translation:common.phone'),
				value: currentLab.phone,
				icon: faPhone
			}
		];

		const thirdColumn = [
			{
				title: i18n.t('translation:common.bankInformation'),
				value: [
					`${i18n.t('translation:common.IBAN')}: ${currentLab.iban || ''}`,
					`${i18n.t('translation:common.BIC')}: ${currentLab.bic || ''}`,
					`${i18n.t('translation:common.bankName')}: ${currentLab.bankName || ''}`,
					`${i18n.t('translation:common.bankAddress')}: ${currentLab.bankAddress || ''}`
				],
				icon: faCreditCard
			},
			{
				title: i18n.t('translation:common.invoicingInformation'),
				value: [
					`${i18n.t('translation:common.fields.companyOwner')}: ${
						currentLab.owner || ''
					}`,
					`${i18n.t('translation:common.fields.uic')}: ${currentLab.uic || ''}`,
					`${i18n.t('translation:common.fields.vatNumber')}: ${
						currentLab.vatNumber || ''
					}`
				],
				icon: faDollarSign
			}
		];
		const columns = [firstColumn, secondColumn, thirdColumn];

		return this.state.isMainSectionOpen ? (
			<CompanyDataSection columns={columns} locations={currentLab.locations} />
		) : (
			<Fragment />
		);
	};

	renderUsers = () => {
		const users = parseLabStaff(this.props.users);

		return (
			<Table
				columns={[
					i18n.t('translation:common.table.name'),
					i18n.t('translation:common.table.phone'),
					i18n.t('translation:common.table.email'),
					i18n.t('translation:common.table.role'),
					i18n.t('translation:common.table.status')
				]}
				data={users}
			/>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		users: state.users.users.content,
		account: state.account,
		currentLab: state.labs.currentLab
	};
};

export default connect(mapStateToProps, { getLabStaff, getLabDetails, clearReducers })(
	DashboardUser
);
