import React from 'react';

class TableRowTrigger extends React.Component {
	render() {
		const colorArrowUp = this.props.color
			? { borderBottom: `7px solid ${this.props.color}` }
			: { borderBottom: `7px solid white` };
		const colorArrowDown = this.props.color
			? { borderTop: `7px solid ${this.props.color}` }
			: { borderTop: `7px solid white` };

		const trigger = !this.props.isClose ? (
			<td
				onClick={this.props.onTriggerClick}
				colSpan={1}
				style={{ position: 'relative', cursor: 'pointer' }}>
				<span
					className="arrow-up"
					style={{ position: 'relative', bottom: '10.5px', ...colorArrowUp }}
				/>
			</td>
		) : (
			<td
				onClick={this.props.onTriggerClick}
				colSpan={1}
				style={{ position: 'relative', cursor: 'pointer' }}>
				<span
					className="arrow-down"
					style={{ position: 'relative', top: '14.5px', ...colorArrowDown }}
				/>
			</td>
		);

		return trigger;
	}
}

export default TableRowTrigger;
