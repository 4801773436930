import React from 'react';
import { change, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { getDebtorDays } from '../../../../actions';
import { DEBTOR_DAYS_FIELD, PAGINATION_TYPES } from '../../../../utils/constants';
import DefaultButton from '../../../common/DefaultButton';
import i18n from '../../../../i18n';
import { customDateInput } from '../../../common/Fields';

class DebtorDaysFilters extends React.Component {
	renderFilterButton() {
		return (
			<div className="d-flex col-6 p-0 align-items-center justify-content-end">
				<DefaultButton title={i18n.t('translation:billingModule.common.buttons.apply')} />
			</div>
		);
	}

	renderDateInput = () => {
		return (
			<Field
				name={DEBTOR_DAYS_FIELD.TO_DATE}
				customProps={{
					className: 'd-block col-6 p-0 align-items-center justify-content-start',
					placeholder: i18n.t('translation:orders.fields.placeholders.to'),
					renderClearButton: true,
					label: i18n.t('translation:billingModule.takings.filters.toDate'),
					maxDate: new Date()
				}}
				component={customDateInput}
			/>
		);
	};

	onFilterButtonClick(e) {
		e.preventDefault();
		this.props.getDebtorDays(
			this.props.account.labId,
			0,
			this.props.formValues[DEBTOR_DAYS_FIELD.TO_DATE]
		);
	}

	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters">
					<form
						onSubmit={(e) => this.onFilterButtonClick(e)}
						className={'col-12 p-0 d-flex align-items-center justify-content-between'}>
						{this.renderDateInput()}
						{this.renderFilterButton()}
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('debtorDaysForm')(state),
		page: state.pagination[PAGINATION_TYPES.DEBTOR_DAYS]
	};
};

export default connect(mapStateToProps, { getDebtorDays, change })(DebtorDaysFilters);
