import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'react-modal';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fields } from './Fields';
import DefaultButton from './DefaultButton';
import { formUtils } from '../../utils/formUtils';
import { sendFeedback } from '../../actions';

class FeedbackModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.isOpen !== this.props.isOpen) {
			const initialValues = nextProps.initialValues
				? nextProps.initialValues
				: this.getInitialValues();
			this.props.initialize(initialValues);
		}
	}

	render() {
		const sendButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.send')}
				type="submit"
				key={i18n.t('translation:common.buttons.send')}
			/>
		);
		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={this.closeModalAndDestroy}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isOpen}
				className="custom-modal"
				overlayClassName="custom-overlay">
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={this.closeModalAndDestroy}
					/>
					<h2>{i18n.t('translation:feedbackModal.title')}</h2>
					{Fields.commonFields.topic()}
					{Fields.commonFields.description()}
					<div className="buttons">
						{sendButton} {cancelButton}
					</div>
				</form>
			</Modal>
		);
	}

	onFormSubmit = (formValues) => {
		this.props.sendFeedback(formValues, this.closeModalAndDestroy);
		this.resetState();
	};

	closeModalAndDestroy = () => {
		this.props.closeModal();
		this.props.destroy();
	};

	resetState() {
		this.setState(this.getInitialValues());
	}

	getInitialValues() {
		return {
			topic: '',
			description: ''
		};
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};

const feedbackModalForm = reduxForm({
	form: 'feedbackModalForm',
	validate: formUtils.validateFeedbackModal
})(FeedbackModal);

export default connect(mapStateToProps, { sendFeedback })(feedbackModalForm);
