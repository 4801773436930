import React, { Fragment } from 'react';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import DefaultButton from '../../common/DefaultButton';
import { PAGES_PATHS } from '../../../utils/constants';
import navigationUtils from '../../../utils/navigationUtils';
import LabsTable from '../../common/LabsTable';

class Labs extends React.Component {
	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:labs.labs')}
					buttons={[this.renderAddLabButton()]}
				/>
				<div className="col-12 p-0">
					<hr />
					<LabsTable />
				</div>
			</Fragment>
		);
	}

	renderAddLabButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.add')}
				onClick={() => {
					navigationUtils.navigate(PAGES_PATHS.LABORATORY_CREATE);
				}}
			/>
		);
	};
}

export default Labs;
