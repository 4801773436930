import React, { createRef, Fragment } from 'react';
import { Fields, leftLabelledSelectInput } from '../../../../../../common/Fields';
import i18n from '../../../../../../../i18n';
import {
	changeDocumentDateOfIssue,
	changeDocumentDueDate,
	formUtils
} from '../../../../../../../utils/formUtils';
import {
	BILLING_DOCUMENT_STATUS,
	BILLING_DOCUMENT_TYPES,
	DEFAULT_BACKEND_DATE_FORMAT,
	INVOICE_FIELD_NAME,
	TRANSACTION_METHOD
} from '../../../../../../../utils/constants';
import { change, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import {
	getInvoiceDueDays,
	getNextDocumentNumber,
	hideMessageModal,
	showMessageModal
} from '../../../../../../../actions';
import moment from '../../../../../../../utils/moment';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import DefaultButton from '../../../../../../common/DefaultButton';
import Status from './Status';

class GeneralInformationSection extends React.Component {
	state = {
		invoiceDueDays: 0,
		initialRecipientId: null,
		initialDateOfIssue: null
	};

	isRecipientInitialized = createRef();
	isDateOfIssueInitialized = createRef();
	keepCurrentDueDate = createRef();

	componentDidMount() {
		if (this.props.mode === BILLING_DOCUMENT_MODE.CREATE) {
			if (
				this.props.documentType !== BILLING_DOCUMENT_TYPES.DEBIT_NOTE &&
				this.props.documentType !== BILLING_DOCUMENT_TYPES.FREE_INVOICE
			) {
				this.props.getNextDocumentNumber(this.props.account.labId);
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { formValues, mode } = this.props;
		const { RECIPIENT_ID, SUPPLIER_ID } = INVOICE_FIELD_NAME;
		if (
			prevProps.currentDocument !== this.props.currentDocument &&
			this.props.currentDocument
		) {
			this.fillInitialData(this.props.currentDocument);
		}

		if (
			prevProps.formValues &&
			this.props.formValues[RECIPIENT_ID] &&
			prevProps.formValues[RECIPIENT_ID] !== this.props.formValues[RECIPIENT_ID]
		) {
			if (this.props.documentType !== BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
				this.props.getInvoiceDueDays(
					this.props.formValues[RECIPIENT_ID],
					this.props.formValues[SUPPLIER_ID],
					(invoiceDueDays) =>
						this.onInvoiceDueDaysChanged(invoiceDueDays, formValues, mode)
				);
			}
		}

		const dateOfIssue = this.props.formValues.dateOfIssue;

		//if creditNote keepCurrentDueDate will be always true
		if (prevProps.formValues && prevProps.formValues.dateOfIssue !== dateOfIssue) {
			if (!this.keepCurrentDueDate.current) {
				this.onDateOfIssueChange(dateOfIssue, mode, formValues);
			}
			this.keepCurrentDueDate.current = false;
		}
	}

	fillInitialData(currentDocument) {
		const {
			issueDate,
			number,
			status,
			numberDraft,
			taxEventDate,
			documentReason,
			creditReason,
			transactionMethod,
			dueDate,
			creator
		} = currentDocument;

		const initialRecipientId = currentDocument.clinicId;
		const initialDateOfIssue = currentDocument.issueDate;

		this.setState({ initialRecipientId, initialDateOfIssue });

		if (status === BILLING_DOCUMENT_STATUS.DRAFT) {
			this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.INVOICE_NUMBER, numberDraft);
		} else {
			this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.INVOICE_NUMBER, number);
		}

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.DATE_OF_ISSUE, new Date(issueDate));
		this.props.change(
			'freeInvoiceForm',
			INVOICE_FIELD_NAME.DATE_OF_TAX_EVENT,
			new Date(taxEventDate)
		);
		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.DOCUMENT_REASON, documentReason);
		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.CREDIT_REASON, creditReason);
		this.props.change(
			'freeInvoiceForm',
			INVOICE_FIELD_NAME.TRANSACTION_METHOD,
			transactionMethod
		);
		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.COMPOSED_BY, creator);
		if (this.props.documentType !== BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			changeDocumentDueDate(this.props.change, new Date(dueDate));
		}
	}

	onDateOfIssueChange(dateOfIssue, mode, formValues) {
		const { PREVIEW, EDIT } = BILLING_DOCUMENT_MODE;

		if (mode === PREVIEW) {
			return;
		}

		if (new Date(dateOfIssue).getTime() === new Date(this.state.initialDateOfIssue).getTime()) {
			this.isDateOfIssueInitialized.current = true;
			return;
		}

		if (mode === EDIT) {
			if (!this.isDateOfIssueInitialized.current) {
				return;
			}
		}

		if (this.props.documentType !== BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			this.changeDocumentDueDateOnDateOfIssueChange(formValues.dateOfIssue);
		}
	}

	changeDocumentDueDate(invoiceDueDays, formValues) {
		if (invoiceDueDays > 0) {
			const newDate = moment(formValues.dateOfIssue).add(invoiceDueDays, 'days').toDate();
			changeDocumentDueDate(this.props.change, newDate);
		} else {
			changeDocumentDueDate(this.props.change, '');
		}
	}

	changeDocumentDueDateOnDateOfIssueChange = (dateOfIssue) => {
		if (this.state.invoiceDueDays > 0) {
			const newDate = moment(dateOfIssue).add(this.state.invoiceDueDays, 'days').toDate();
			changeDocumentDueDate(this.props.change, newDate);
		} else {
			changeDocumentDueDate(this.props.change, '');
		}
	};

	onInvoiceDueDaysChanged = (invoiceDueDays, formValues, mode) => {
		const { RECIPIENT_ID } = INVOICE_FIELD_NAME;
		const { CREATE, PREVIEW, EDIT } = BILLING_DOCUMENT_MODE;

		if (mode === PREVIEW) {
			return;
		}

		this.setState({ invoiceDueDays });

		if (mode === CREATE) {
			this.changeDocumentDueDate(invoiceDueDays, formValues);
			if (invoiceDueDays > 0) {
				const newDate = moment(formValues.dateOfIssue).add(invoiceDueDays, 'days').toDate();
				this.props.onInitialDueDateChange(newDate);
			} else {
				this.props.onInitialDueDateChange(invoiceDueDays);
			}
		}
		if (mode === EDIT) {
			if (this.props.formValues[RECIPIENT_ID] == this.state.initialRecipientId) {
				if (!this.isRecipientInitialized.current) {
					this.isRecipientInitialized.current = true;
					return;
				}
			}

			if (!this.isRecipientInitialized.current) {
				return;
			}

			this.changeDocumentDueDate(invoiceDueDays, formValues);
		}
	};

	onDateOfIssueFieldChange = (dateOfIssueValue) => {
		const { DUE_DATE } = INVOICE_FIELD_NAME;
		const { formValues } = this.props;
		const dueDate = formValues[DUE_DATE];

		if (dueDate && this.props.documentType !== BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			this.showWarningMessageOnIssueDateChange(dateOfIssueValue);
		} else {
			changeDocumentDateOfIssue(this.props.change, dateOfIssueValue);
		}
	};

	showWarningMessageOnIssueDateChange(dateOfIssueValue) {
		const okButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.ok')}
				onClick={() => {
					this.props.hideMessageModal();
					changeDocumentDateOfIssue(this.props.change, dateOfIssueValue);
				}}
			/>
		);
		const closeButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				onClick={() => this.props.hideMessageModal()}
				secondary
			/>
		);

		this.props.showMessageModal(
			i18n.t('translation:common.warning'),
			<Fragment>
				<span>
					{i18n.t('translation:billingModule.invoicing.warnings.issueDateChange')}
				</span>
				<span className={'d-flex justify-content-center align-items-center'}>
					<input
						className={'mr-2'}
						id={'keepCurrentDueDate'}
						type={'checkBox'}
						onChange={({ target }) => {
							this.keepCurrentDueDate.current = target.value;
						}}
					/>
					<label
						htmlFor={'keepCurrentDueDate'}
						className={'font-weight-normal font-italic'}>
						{i18n.t('translation:billingModule.invoicing.warnings.keepCurrentDueDate')}
					</label>
				</span>
			</Fragment>,
			[okButton, closeButton],
			false,
			true
		);
	}

	renderCreditReason = () => {
		const { CREDIT_NOTE } = BILLING_DOCUMENT_TYPES;
		const { documentType } = this.props;

		if (documentType !== CREDIT_NOTE) {
			return null;
		}

		const options = [
			{
				name: i18n.t('translation:billingModule.invoicing.orderCorrection'),
				value: 'CORRECT_ORDER'
			},
			{
				name: i18n.t('translation:billingModule.invoicing.invoiceCorrection'),
				value: 'CORRECT_INVOICE'
			}
		];

		return Fields.billingModule.invoicing.creditInvoiceReason({
			options: options,
			onChange: (e) =>
				this.props.change(
					'freeInvoiceForm',
					INVOICE_FIELD_NAME.CREDIT_REASON,
					e.target.value
				),
			className: 'd-flex col-6 align-items-center pl-0',
			innerClassName: 'w-100',
			validate: formUtils.validateRequiredField,
			value: this.props.formValues[INVOICE_FIELD_NAME.CREDIT_REASON],
			readableInput: isReadableInput(this.props.mode)
		});
	};

	renderToInvoices = () => {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			const invoicesNumbersDates = [];

			let invoices = [];

			if (this.props.mode === BILLING_DOCUMENT_MODE.CREATE) {
				invoices = this.props.invoiceData;
			} else {
				invoices = this.props.currentDocument
					? this.props.currentDocument.invoices ?? []
					: [];
			}

			for (let i = 0; i < invoices.length; i++) {
				const invoiceNumber = invoices[i].number;
				const invoiceDate = this.parseDate(invoices[i].issueDate);

				invoicesNumbersDates.push(` ${invoiceNumber} / ${invoiceDate}`);
			}

			if (invoices.length > 3) {
				return (
					<div className={'d-flex align-items-center col-3 p-0'}>
						<div
							data-toggle={'tooltip'}
							data-placement={'right'}
							title={invoicesNumbersDates.join(',  ')}>
							className={'col-12 p-0 pr-3'}
							<span className="font-weight-bold pr-2">
								{`${i18n.t(
									'translation:billingModule.invoicing.table.creditNote.tiedToInvoices'
								)}`}
								*
							</span>
							<span>{invoicesNumbersDates.slice(0, 3).join(',  ')}</span>
						</div>
					</div>
				);
			}

			return (
				<div className={'d-flex align-items-center col-3 p-0'}>
					<div className={'col-12 p-0 pr-3'}>
						<span className="font-weight-bold pr-2">
							{`${i18n.t(
								'translation:billingModule.invoicing.table.creditNote.tiedToInvoices'
							)}`}
						</span>
						<span>{invoicesNumbersDates.join(',  ')}</span>
					</div>
				</div>
			);
		}

		return null;
	};

	parseDate = (date) => {
		return moment(date).format(DEFAULT_BACKEND_DATE_FORMAT);
	};

	renderComposeBy() {
		const additionalClass =
			this.props.documentType === BILLING_DOCUMENT_TYPES.ORDER_INVOICE ? 'pl-0' : ' ';
		return Fields.billingModule.invoicing.composedBy({
			readableInput: isReadableInput(this.props.mode),
			className: `col-3 d-flex align-items-center ${additionalClass}`
		});
	}

	renderTransactionMethod() {
		const readableInput = isReadableInput(this.props.mode);

		const additionalClass =
			this.props.documentType === BILLING_DOCUMENT_TYPES.ORDER_INVOICE ? 'pl-3' : 'p-0';

		const options = [
			{ name: '', value: '' },
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.bankTransfer'),
				value: TRANSACTION_METHOD.BANK
			},
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.posTerminal'),
				value: TRANSACTION_METHOD.POS
			},
			{
				name: i18n.t('translation:billingModule.incomeOutcomeDetails.common.cash'),
				value: TRANSACTION_METHOD.CASH
			}
		];

		return (
			<Field
				name={INVOICE_FIELD_NAME.TRANSACTION_METHOD}
				customProps={{
					className: `d-flex col-3 align-items-center ${additionalClass}`,
					innerClassName: 'w-100',
					options: options,
					readableInput,
					label: i18n.t('translation:billingModule.invoicing.paymentMethod')
				}}
				component={leftLabelledSelectInput}
			/>
		);
	}

	renderInvoiceReason = () => {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			const additionalClass =
				this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ? 'pr-0' : 'pl-0';
			return (
				<div className={`col-6 ${additionalClass}`}>
					{Fields.billingModule.invoicing.documentReason({
						readableInput: isReadableInput(this.props.mode),
						maxLength: 250
					})}
				</div>
			);
		}

		return null;
	};

	render() {
		const paddingClass = this.props.mode !== BILLING_DOCUMENT_MODE.PREVIEW ? 'pb-5' : 'pb-3';
		return (
			<div className={'container-fluid'}>
				<div className={`row pt-1 ${paddingClass}`}>
					<div className={'col-3 p-0'}>{this.renderInvoiceNumber()}</div>
					<div className={'col-3 pl-0'}>
						<div className="d-inline-flex align-items-center">
							{this.renderDateOfIssue()}
						</div>
					</div>
					<div className={'col-3 pr-0'}>
						<div className="d-inline-flex align-items-center">
							{this.renderDateOfTaxEvent()}
						</div>
					</div>
					<div className={'col-3 p-0'}>{this.renderDueDate()}</div>
				</div>
				<div className={`row pl-0 align-items-center ${paddingClass}`}>
					<div className={'col-3 p-0'}>
						<label className={'label pr-2'}>
							{i18n.t('translation:billingModule.common.status')}:
						</label>
						<Status status={this.props.currentDocument?.status} />
					</div>
					{this.renderInvoiceType()}
					{this.renderToInvoices()}
					{this.renderComposeBy()}
					{this.renderTransactionMethod()}
				</div>
				<div className={'row p-0'}>
					{this.renderCreditReason()}
					{this.renderInvoiceReason()}
				</div>
			</div>
		);
	}

	renderDateOfTaxEvent() {
		return (
			<>
				<label className="padding-right-12">
					{i18n.t('translation:billingModule.invoicing.dateOfTaxEvent')}
				</label>
				{Fields.billingModule.invoicing.dateOfTaxEvent({
					className: isReadableInput(this.props.mode) ? 'p-0 pb-1' : 'p-0',
					readableInput: isReadableInput(this.props.mode),
					maxDate:
						this.props.formValues &&
						this.props.formValues[INVOICE_FIELD_NAME.DATE_OF_ISSUE]
				})}
			</>
		);
	}

	renderDueDate() {
		if (this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			return null;
		}
		return (
			<div className="d-inline-flex align-items-center">
				<div className="d-flex align-items-center">
					<label className="label pr-2">
						{i18n.t('translation:billingModule.invoicing.dueDate')}
					</label>
					{Fields.billingModule.invoicing.dueDate({
						minDate: this.getDueDateMinDate(),
						readableInput: isReadableInput(this.props.mode),
						className: isReadableInput(this.props.mode) ? 'pb-1' : 'p-0',
						description:
							isReadableInput(this.props.mode) &&
							this.props.formValues[INVOICE_FIELD_NAME.DUE_DATE]
								? this.props.formValues[INVOICE_FIELD_NAME.DUE_DATE].toString()
								: ''
					})}
				</div>
			</div>
		);
	}

	getDueDateMinDate() {
		const dateOfIssue = this.props.formValues[INVOICE_FIELD_NAME.DATE_OF_ISSUE];
		const nowDate = new Date();

		if (dateOfIssue) {
			return dateOfIssue;
		}
		return nowDate;
	}

	renderDateOfIssue() {
		return (
			<>
				<label className="padding-right-12">
					{i18n.t('translation:billingModule.invoicing.dateOfIssue')}
				</label>
				{Fields.billingModule.invoicing.dateOfIssue({
					onChange: (val) => this.onDateOfIssueFieldChange(val),
					className: isReadableInput(this.props.mode) && 'pb-1',
					readableInput: isReadableInput(this.props.mode),
					controlled: true
				})}
			</>
		);
	}

	renderNumberIsDraft() {
		if (
			this.props.mode === BILLING_DOCUMENT_MODE.CREATE ||
			this.props.currentDocument?.status === BILLING_DOCUMENT_STATUS.DRAFT
		) {
			return (
				<div className={'w-100 pt-2 font-italic'}>
					{i18n.t('translation:billingModule.invoicing.common.docNumbIsDraft')}
				</div>
			);
		}

		return null;
	}

	renderNumber() {
		return (
			<>
				{Fields.billingModule.invoicing.invoiceNumber({
					readableInput: isReadableInput(this.props.mode),
					className: 'd-inline-flex align-items-center'
				})}
				{this.renderNumberIsDraft()}
			</>
		);
	}

	renderDisableInvoiceNumber() {
		return (
			<>
				<div className={'d-inline-flex align-items-center'}>
					<label className={'left-label'}>
						{i18n.t('translation:billingModule.invoicing.number')}
					</label>
					<div className={'w-100'}>
						<input
							className={'form-control form-field white-input-field'}
							value={''}
							disabled={true}
						/>
					</div>
				</div>
				<div className={'w-100 font-italic'}>
					{i18n.t('translation:billingModule.invoicing.common.docNumbSetAfterApproval')}
				</div>
			</>
		);
	}

	renderInvoiceNumberFreeDebitNote() {
		if (!this.props.currentDocument) {
			return this.renderDisableInvoiceNumber();
		}
		const { number } = this.props.currentDocument;

		if (!number) {
			return this.renderDisableInvoiceNumber();
		}

		return this.renderNumber();
	}

	renderInvoiceNumber() {
		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.FREE_INVOICE
		) {
			return this.renderInvoiceNumberFreeDebitNote();
		}
		return this.renderNumber();
	}

	renderInvoiceType() {
		if (this.props.documentType !== BILLING_DOCUMENT_TYPES.FREE_INVOICE) {
			return null;
		}

		return (
			<div className={'col-3 p-0'}>
				<div className="col-12 d-flex align-items-center pl-0 pr-2">
					<div className="font-weight-bold pr-2">
						{`${i18n.t('translation:billingModule.invoicing.invoiceType')}:`}
					</div>
					<div>{i18n.t('translation:billingModule.documentType.freeInvoice')}</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		country: state.billingSettings.country,
		account: state.account,
		invoiceData: state.billingDocument.invoiceData,
		currentDocument: state.billingDocument.currentDocument
	};
};

export default connect(mapStateToProps, {
	change,
	getNextDocumentNumber,
	getInvoiceDueDays,
	showMessageModal,
	hideMessageModal
})(GeneralInformationSection);
