import React from 'react';
import { getAxiosWithToken } from '../utils/webApi';
import { startLoader, stopLoaderImmediately } from './loaderActions';
import i18n from '../i18n';
import {
	ARE_CLINIC_AND_LAB_LINKED,
	CLEAR_SIGN_UP_USER_EMAIL,
	GET_ALL_RECEIVED_AND_PENDING_INVITATIONS,
	GET_ALL_SENT_USER_INVITATIONS,
	SET_SIGN_UP_USER_EMAIL
} from './types';
import { LINK_INVITATION_ACTION_RESULT, LOADER_TYPES } from '../utils/constants';
import { getLinkedClinics, getLinkedLabs, hideMessageModal, showMessageModal } from '.';
import DefaultButton from '../components/common/DefaultButton';
import { showSuccessPopup } from '../utils/appUtils';

export const sendInvitationToUser =
	(email, languageCode, reSendingInvitation, displayErrorMessage, closeModal) =>
	async (dispatch) => {
		dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

		const response = await getAxiosWithToken(false, false).post('/invitation/send-to-user', {
			email,
			languageCode,
			reSendingInvitation
		});

		let returnedData = response.data;
		if (returnedData.success) {
			// For this action, it is possible to receive custom message from backend even when the
			// operation is success
			const message = returnedData.userMessage
				? returnedData.userMessage
				: i18n.t('translation:common.notifications.successfullySentInvitation');
			if (returnedData.userMessage) {
				dispatch(showMessageModal(i18n.t('translation:common.success'), message));
			} else {
				showSuccessPopup(message);
			}

			if (closeModal) {
				closeModal();
			}
		} else {
			if (displayErrorMessage) {
				displayErrorMessage(returnedData.userMessage);
			}
		}

		dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
	};

export const removeInvitationToUser = (email) => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

	await getAxiosWithToken(false, false).delete(
		`/invitation/remove-invitation-to-user?email=${email}`
	);

	dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));

	dispatch(getAllSentUserInvitations());
};

export const sendLinkInvitation =
	(
		organizationId,
		isInvitationToClinic,
		email,
		displayErrorMessage,
		closeModal,
		showUserInvitationModal
	) =>
	async (dispatch) => {
		dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

		const urlPart = isInvitationToClinic ? 'send-to-clinic' : 'send-to-lab';
		const response = await getAxiosWithToken(false, false).post(
			`/invitation/${urlPart}?email=${email}`
		);

		let returnedData = response.data;
		if (returnedData.success) {
			closeModal();

			if (returnedData.data === LINK_INVITATION_ACTION_RESULT.LINK_CREATED) {
				const page = 1;

				// If a link between the clinic and lab was created directly, refreshing the
				// clients/suppliers list
				if (isInvitationToClinic) {
					showSuccessPopup(
						i18n.t('translation:common.notifications.alreadyInvitedByClient')
					);

					dispatch(getLinkedClinics(organizationId, page));
				} else {
					showSuccessPopup(
						i18n.t('translation:common.notifications.alreadyInvitedBySupplier')
					);

					dispatch(getLinkedLabs(organizationId, page));
				}
			} else if (returnedData.data === LINK_INVITATION_ACTION_RESULT.ORGANIZATION_NOT_EXIST) {
				const yesButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.yes')}
						onClick={() => {
							dispatch(hideMessageModal());

							dispatch({
								type: SET_SIGN_UP_USER_EMAIL,
								payload: email
							});

							showUserInvitationModal();
						}}
					/>
				);
				const noButton = (
					<DefaultButton
						title={i18n.t('translation:common.buttons.no')}
						onClick={() => dispatch(hideMessageModal())}
						secondary
					/>
				);

				dispatch(
					showMessageModal(
						i18n.t('translation:common.confirmation'),
						i18n.t('translation:common.warnings.inviteUserInstead'),
						[yesButton, noButton]
					)
				);
			} else {
				showSuccessPopup(
					i18n.t('translation:common.notifications.successfullySentInvitation')
				);
			}
		} else {
			displayErrorMessage(returnedData.userMessage);
		}

		dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
	};

export const acceptLinkInvitation = (invitationId, isSentFromClinic) => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

	const response = await getAxiosWithToken(false, false).put(
		`/invitation/${invitationId}/accept`
	);

	dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));

	if (response.data.success) {
		dispatch(getAllReceivedAndPendingInvitations());

		var message = isSentFromClinic
			? i18n.t('translation:dashboard.clientInvitationAccepted')
			: i18n.t('translation:dashboard.supplierInvitationAccepted');
		showSuccessPopup(message);
	}
};

export const getAllReceivedAndPendingInvitations = () => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

	const response = await getAxiosWithToken(false, false).get(
		'/invitation/get-all-received-and-pending-invitations'
	);

	const returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_ALL_RECEIVED_AND_PENDING_INVITATIONS,
			payload: returnedData.data
		});
	}

	dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
};

export const getAllSentUserInvitations = () => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

	const response = await getAxiosWithToken(false, false).get(
		'/invitation/get-all-sent-user-invitations'
	);

	const returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: GET_ALL_SENT_USER_INVITATIONS,
			payload: returnedData.data
		});
	}

	dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
};

export const areClinicAndLabLinked = (clinicId, labId) => async (dispatch) => {
	dispatch(startLoader(LOADER_TYPES.PRODUCT_LOADER));

	const response = await getAxiosWithToken(false, false).get(
		`/link/are-clinic-and-lab-linked?clinic-id=${clinicId}&lab-id=${labId}`
	);

	const returnedData = response.data;
	if (returnedData.success) {
		dispatch({
			type: ARE_CLINIC_AND_LAB_LINKED,
			payload: returnedData.data
		});
	}

	dispatch(stopLoaderImmediately(LOADER_TYPES.PRODUCT_LOADER));
};

export const clearSignUpUserEmail = () => async (dispatch) =>
	dispatch({
		type: CLEAR_SIGN_UP_USER_EMAIL
	});
