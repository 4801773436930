import React, { Fragment } from 'react';
import i18n from '../../../../../../../i18n';
import { connect } from 'react-redux';

class Header extends React.Component {
	state = {
		columns: [
			{
				text: i18n.t('translation:billingModule.invoicing.table.orderInvoice.order'),
				colSpan: 1
			},
			{
				text: i18n.t('translation:billingModule.common.taxBase'),
				colSpan: 3
			},
			{
				text: i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.invoicedPortion'
				),
				colSpan: 2
			},
			{
				text: i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.remainForInvoicing'
				),
				colSpan: 2
			},
			{
				text: i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.invoicingMethod'
				),
				colSpan: 2
			},
			{
				text: i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.invoicingPercent'
				),
				colSpan: 2
			},
			{
				text: i18n.t(
					'translation:billingModule.invoicing.table.orderInvoice.absoluteValue'
				),
				colSpan: 3
			},
			{
				text: '',
				colSpan: 1
			}
		]
	};

	render() {
		return (
			<thead
				style={{
					position: 'sticky',
					top: 0,
					zIndex: 100
				}}>
				<tr>
					{this.state.columns.map((column, index) => {
						const tableHeaderStyle =
							index === this.state.columns.length - 1 ? 'text-left' : null;

						return (
							<Fragment key={index + 'tableColumn'}>
								<th
									className={tableHeaderStyle}
									key={index + 'h'}
									scope="col"
									colSpan={column.colSpan}>
									<div className={'d-inline'}>{column.text}</div>
								</th>
							</Fragment>
						);
					})}
				</tr>
			</thead>
		);
	}
}

export default Header;
