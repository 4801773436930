import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import { formUtils } from '../../../utils/formUtils';
import i18n from '../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AvatarModal from '../../common/AvatarModal';
import {
	avatarValidation,
	getBase64EncodedCroppedImage,
	getFileExtension
} from '../../../utils/fileUtils';
import {
	getAllCurrencyCodes,
	getSettingFromServer,
	getSupportedPhotoExtensions,
	showAvatarModal,
	showMessageModal,
	signUp
} from '../../../actions';
import { Link } from 'react-router-dom';
import {
	ORGANIZATION_TYPE,
	PAGES_PATHS,
	SERVER_SETTING_NAMES,
	SIGN_UP_ORGANIZATION_TYPE,
	USER_TYPE
} from '../../../utils/constants';

class SignUp extends Component {
	constructor(props) {
		super(props);
		this.imageRef = React.createRef();
	}

	state = {
		entireImage: null,
		croppedImage: null,
		fileType: null,
		userType: null,
		organizationType: null
	};

	componentDidMount() {
		this.props.getSettingFromServer(SERVER_SETTING_NAMES.USER_AVATAR_MAX_SIZE_IN_MB);
		this.props.getSupportedPhotoExtensions();
		this.props.getAllCurrencyCodes();
	}

	render() {
		return (
			<Fragment>
				<form
					className="authentication-form mb-m"
					onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<AvatarModal onSaveAvatar={this.onSaveAvatar} />
					<h2>{i18n.t('translation:auth.newRegistration')}</h2>
					<hr className="full-width-element" />

					{this.renderPersonalInformation()}

					{this.renderCompanyInformation()}

					<DefaultButton type="submit" title={i18n.t('translation:auth.signUp')} />
					<Link to={PAGES_PATHS.HOME} className="text-button mt-xs">
						{i18n.t('translation:auth.backToLogin')}
					</Link>
				</form>
			</Fragment>
		);
	}

	renderPersonalInformation() {
		return (
			<Fragment>
				{this.renderAvatar()}
				<div className="sub-header mt-m">
					{i18n.t('translation:auth.personalInformation')}
					<hr />
				</div>
				<div className="row mr-auto ml-auto">
					<div className="col-lg-6 col-sm-12">
						{Fields.commonFields.userName()}
						{Fields.commonFields.firstName()}
						{Fields.commonFields.lastName()}
						{Fields.commonFields.phone()}
					</div>
					<div className="col-lg-6 col-sm-12">
						{Fields.commonFields.email()}
						{Fields.commonFields.password()}
						{Fields.commonFields.repeatPassword()}
						{Fields.commonFields.userType({
							options: formUtils.options.getUserType(),
							onChange: this.onUserTypeChanged
						})}
					</div>
				</div>
				<div className="row mr-auto ml-auto">
					<div className="col-12 mt-s">
						{Fields.commonFields.termsAndConditions()}
						{Fields.commonFields.cookiesPolicy()}
						{Fields.commonFields.privacyPolicy()}
						{Fields.commonFields.receiveMarketingInformation()}
					</div>
				</div>
			</Fragment>
		);
	}

	renderCompanyInformation() {
		if (this.state.userType !== null && this.state.userType !== '') {
			return (
				<Fragment>
					<div className="sub-header mt-m">
						{i18n.t('translation:auth.companyInformation')}
						<hr />
					</div>
					<div className="row mr-auto ml-auto">
						<div className="col-lg-6 col-sm-12">{this.renderOrganizationType()}</div>
						<div className="col-lg-6 col-sm-12">{this.renderOrganizationEmail()}</div>
					</div>
					{this.renderNewCompanyInformation()}
					{this.renderExistingOrganizationNote()}
				</Fragment>
			);
		} else {
			return null;
		}
	}

	renderNewCompanyInformation() {
		if (
			this.state.organizationType === ORGANIZATION_TYPE.NEW_CLINIC ||
			this.state.organizationType === ORGANIZATION_TYPE.NEW_LAB
		) {
			return (
				<Fragment>
					<div className="row mr-auto ml-auto"></div>
					<div className="row mr-auto ml-auto">
						<div className="col-lg-6 col-sm-12">
							{Fields.commonFields.name()}
							{Fields.commonFields.legalName()}
							{Fields.commonFields.owner()}
							{Fields.commonFields.uic()}
							{Fields.commonFields.vatNumber()}
						</div>
						<div className="col-lg-6 col-sm-12">
							{Fields.commonFields.organizationEmail()}
							{Fields.commonFields.organizationPhone()}
							{Fields.commonFields.registeredOffice({
								className: 'company-info-form input-container'
							})}
							{this.renderCurrencyCode()}
						</div>
					</div>
				</Fragment>
			);
		} else {
			return null;
		}
	}

	renderCurrencyCode = () => {
		if (this.state.organizationType === ORGANIZATION_TYPE.NEW_LAB) {
			return Fields.commonFields.currencyCode({
				options: formUtils.options.getCurrencyCodes(this.getCurrencyCodes())
			});
		} else {
			return null;
		}
	};

	getCurrencyCodes = () => {
		const currencyCodes = this.props.serverSettings[SERVER_SETTING_NAMES.ALL_CURRENCY_CODES];

		return currencyCodes ? currencyCodes : [];
	};

	renderOrganizationType = () => {
		if (this.state.userType === USER_TYPE.DENTIST) {
			return Fields.commonFields.organizationType({
				options: formUtils.options.getClinicType(),
				onChange: this.onOrganizationTypeChanged
			});
		} else if (this.state.userType === USER_TYPE.DENTAL_TECHNICIAN) {
			return Fields.commonFields.organizationType({
				options: formUtils.options.getLabType(),
				onChange: this.onOrganizationTypeChanged
			});
		} else {
			return null;
		}
	};

	renderOrganizationEmail = () => {
		if (
			this.state.organizationType === ORGANIZATION_TYPE.EXISTING_CLINIC ||
			this.state.organizationType === ORGANIZATION_TYPE.EXISTING_LAB
		) {
			return Fields.commonFields.organizationEmail();
		} else {
			return null;
		}
	};

	renderExistingOrganizationNote = () => {
		if (
			this.state.organizationType === ORGANIZATION_TYPE.EXISTING_CLINIC ||
			this.state.organizationType === ORGANIZATION_TYPE.EXISTING_LAB
		) {
			return (
				<div className="note">{i18n.t('translation:auth.existingOrganizationNote')}</div>
			);
		} else {
			return null;
		}
	};

	onUserTypeChanged = (e) => {
		this.setState({
			userType: e.target.value,
			organizationType: null
		});
	};

	onOrganizationTypeChanged = (e) => {
		this.setState({
			organizationType: e.target.value
		});
	};

	renderAvatar = () => {
		const removeButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.delete')}
				danger
				onClick={(e) => {
					e.preventDefault();
					this.setState({ croppedImage: null });
				}}
			/>
		);
		const editButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.update')}
				onClick={(e) => {
					e.preventDefault();
					this.imageRef.current.click();
				}}
			/>
		);

		const buttons = this.state.croppedImage ? (
			<div className="avatar-buttons">
				{editButton}
				{removeButton}
			</div>
		) : (
			<Fragment />
		);

		return (
			<div className="d-flex flex-column align-items-center mt-s">
				<label className="avatar-label">{i18n.t('translation:auth.avatar')}</label>

				<label htmlFor="image-upload">
					{this.state.croppedImage ? (
						<img src={this.state.croppedImage} alt="" className="avatar" />
					) : (
						<div className="avatar d-flex align-items-center justify-content-center">
							<FontAwesomeIcon icon={faPlus} className="avatar-icon" />
						</div>
					)}
				</label>
				<input
					ref={this.imageRef}
					type="file"
					id="image-upload"
					className="d-none"
					onChange={this.onAvatarFileSelected}
				/>
				{buttons}
			</div>
		);
	};

	onAvatarFileSelected = (e) => {
		const file = e.target.files[0];

		if (!this.isSupportedImageFile(getFileExtension(file.name))) {
			const fileListText = this.props.photoFileExtensions.join(', ');
			const errorMessage =
				i18n.t('translation:orders.errors.notSupportedPhotoFile') + fileListText + '.';

			this.props.showMessageModal(i18n.t('translation:common.errors.error'), errorMessage);

			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			const avatarMaxSizeInMbAsFloat = parseFloat(
				this.props.serverSettings[SERVER_SETTING_NAMES.USER_AVATAR_MAX_SIZE_IN_MB]
			);
			if (!avatarValidation(file, avatarMaxSizeInMbAsFloat)) {
				return;
			}

			const entireImage = reader.result;
			this.setState({
				entireImage,
				fileType: file.type
			});
			this.props.showAvatarModal(entireImage);
		};
		if (file) {
			reader.readAsDataURL(file);
		}
		e.target.value = null;
	};

	isSupportedImageFile(fileExtension) {
		return this.props.photoFileExtensions.includes(fileExtension.toLowerCase());
	}

	onSaveAvatar = async (croppedAreaPixels) => {
		var croppedImage = await getBase64EncodedCroppedImage(
			this.state.entireImage,
			croppedAreaPixels,
			this.state.fileType
		);
		this.setState({ croppedImage, entireImage: null });
	};

	onFormSubmit = async (formValues) => {
		this.props.signUp(
			this.createUserSignUpData(formValues),
			this.state.croppedImage,
			this.state.fileType
		);
	};

	createUserSignUpData({
		username,
		password,
		firstName,
		lastName,
		email,
		phone,
		receiveMarketingInformation,
		name,
		legalName,
		organizationEmail,
		organizationPhone,
		registeredOffice,
		currencyCode,
		uic,
		owner,
		vatNumber
	}) {
		const user = {
			username,
			password,
			firstName,
			lastName,
			email,
			phone,
			receiveMarketingInformation
		};

		if (
			this.state.organizationType === ORGANIZATION_TYPE.EXISTING_CLINIC ||
			this.state.organizationType === ORGANIZATION_TYPE.EXISTING_LAB
		) {
			user.organizationType =
				this.state.organizationType === ORGANIZATION_TYPE.EXISTING_CLINIC
					? SIGN_UP_ORGANIZATION_TYPE.CLINIC
					: SIGN_UP_ORGANIZATION_TYPE.LAB;
			user.organizationEmail = organizationEmail;
		} else {
			const newCompany = { name, legalName, registeredOffice, uic, owner, vatNumber };
			newCompany.email = organizationEmail;
			newCompany.phone = organizationPhone;

			if (this.state.organizationType === ORGANIZATION_TYPE.NEW_CLINIC) {
				user.newClinic = newCompany;
			} else {
				// this is a new lab
				newCompany.currencyCode = currencyCode;
				user.newLab = newCompany;
			}
		}

		return user;
	}
}

const signUpForm = reduxForm({ form: 'signUpForm', validate: formUtils.validateSignUp })(SignUp);

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		serverSettings: state.serverSettings,
		photoFileExtensions: state.orders.photoFileExtensions,
		initialValues: { receiveMarketingInformation: false }
	};
};

export default connect(mapStateToProps, {
	signUp,
	showAvatarModal,
	getSettingFromServer,
	getSupportedPhotoExtensions,
	getAllCurrencyCodes,
	showMessageModal
})(signUpForm);
