import moment from '../../utils/moment';
import { getAxiosWithToken } from '../../utils/webApi';
import { SEARCH_STATEMENT, GET_STATEMENT_BALANCE } from '../types';
import {
	BALANCE_FORM_FIELD,
	DOCUMENT_EXPORT_FILE_EXTENSION,
	DOCUMENT_EXPORT_TYPE
} from '../../utils/constants';
import { createCsvFileUrl, createExcelFileUrl, getFileName } from '../common';
import { showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';

export const searchStatement =
	({ page, filters }) =>
	async (dispatch) => {
		page = page - 1;
		filters = formatPeriodDates(filters);

		const response = await getAxiosWithToken().post('/billing/statement/search', filters, {
			params: {
				page: page
			}
		});

		if (response.data.success) {
			dispatch({
				type: SEARCH_STATEMENT,
				payload: response.data.data
			});
		}
	};

export const getStatementStartBalance =
	({ page, filters }) =>
	async (dispatch) => {
		const url = '/billing/statement/start-balance';

		dispatch(getStatementBalance({ url, page, filters }));
	};

export const getStatementEndBalance =
	({ page, filters }) =>
	async (dispatch) => {
		const url = '/billing/statement/end-balance';

		dispatch(getStatementBalance({ url, page, filters }));
	};

const getStatementBalance =
	({ url, page, filters }) =>
	async (dispatch) => {
		page = page - 1;
		filters = formatPeriodDates(filters);

		const response = await getAxiosWithToken().post(url, filters, {
			params: {
				page: page,
				size: 8
			}
		});

		if (response.data.success) {
			dispatch({
				type: GET_STATEMENT_BALANCE,
				payload: response.data.data
			});
		}
	};

const formatPeriodDates = (filters) => {
	filters[BALANCE_FORM_FIELD.PERIOD_DATE_FROM] = moment(
		filters[BALANCE_FORM_FIELD.PERIOD_DATE_FROM]
	).format('YYYY-MM-DD');

	filters[BALANCE_FORM_FIELD.PERIOD_DATE_TO] = moment(
		filters[BALANCE_FORM_FIELD.PERIOD_DATE_TO]
	).format('YYYY-MM-DD');

	return filters;
};

export const exportStatementsToExcelOrCsv = (type, dates, labId) => async (dispatch) => {
	const url = '/billing/statement/export';
	dispatch(exportStatements(type, dates, labId, url));
};

export const exportStatementsStartBalanceToExcelOrCsv =
	(type, dates, labId) => async (dispatch) => {
		const url = '/billing/statement/start-balance/export';
		dispatch(exportStatements(type, dates, labId, url));
	};

export const exportStatementsEndBalanceToExcelOrCsv = (type, dates, labId) => async (dispatch) => {
	const url = '/billing/statement/end-balance/export';
	dispatch(exportStatements(type, dates, labId, url));
};

const exportStatements = (type, dates, labId, url) => async (dispatch, getState) => {
	const mapButtonTitleExportType = {
		[i18n.t('translation:billingModule.common.buttons.exportToExcel')]:
			DOCUMENT_EXPORT_TYPE.EXCEL,
		default: DOCUMENT_EXPORT_TYPE.CSV
	};

	const mappedType = mapButtonTitleExportType[type] ?? mapButtonTitleExportType.default;

	const fileExtension =
		mappedType === DOCUMENT_EXPORT_TYPE.EXCEL
			? DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL
			: DOCUMENT_EXPORT_FILE_EXTENSION.CSV;

	dates = formatPeriodDates(dates);

	const response = await getAxiosWithToken(false).post(
		url,
		{ type: mappedType, filter: { ...dates, labId } },
		{ responseType: 'arraybuffer' }
	);

	const link = document.createElement('a');

	if (fileExtension === DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL) {
		link.href = createExcelFileUrl(response.data);
	} else {
		link.href = createCsvFileUrl(response.data);
	}

	link.setAttribute('download', getFileName(getState().account, 'statement', fileExtension));

	document.body.appendChild(link);
	link.click();

	if (fileExtension === DOCUMENT_EXPORT_FILE_EXTENSION.EXCEL) {
		showSuccessPopup(
			i18n.t('translation:billingModule.documents.successMessages.generatedExcel')
		);
	} else {
		showSuccessPopup(
			i18n.t('translation:billingModule.documents.successMessages.generatedCsv')
		);
	}
};
