import React, { Fragment } from 'react';
import ReactFlagsSelect from 'react-flags-select';
// import 'react-flags-select/css/react-flags-select.css';
import { COUNTRY_CODES, LANGUAGE_FLAG_NAMES } from '../../utils/constants';
import i18n from '../../i18n';
import { connect } from 'react-redux';
import { saveLanguage } from '../../actions';
import { parseFlagToLanguage, refreshPage } from '../../utils/appUtils';

class LanguageSelector extends React.Component {
	render() {
		if (!this.getLanguage()) {
			return <Fragment />;
		}

		return this.renderFlagDropdown();
	}

	renderFlagDropdown = () => {
		const defaultCountry = this.getLanguage();
		return (
			<ReactFlagsSelect
				selected={this.props.language}
				countries={COUNTRY_CODES}
				defaultCountry={defaultCountry}
				customLabels={LANGUAGE_FLAG_NAMES}
				showSelectedLabel={true}
				showSecondarySelectedLabel={true}
				onSelect={this.onSelectFlag}
				className="menu-flags"
			/>
		);
	};

	onSelectFlag = (countryCode) => {
		i18n.changeLanguage(parseFlagToLanguage(countryCode))
			.then(() => this.props.saveLanguage(countryCode))
			.then(() => refreshPage());
	};

	getLanguage = () => {
		return this.props.language;
	};
}

const mapStateToProps = (state) => {
	return { language: state.settings.language };
};

export default connect(mapStateToProps, { saveLanguage })(LanguageSelector);
