import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import i18n from '../../../i18n';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import fileIcon from '../../../resources/icons/file-big.png';
import DefaultButton from '../../common/DefaultButton';
import { Fields } from '../../common/Fields';
import { formUtils } from '../../../utils/formUtils';
import { getFileLocation, isPhotoFile, isVideoFile } from '../../../utils/fileUtils';

class EditFileDataModal extends React.Component {
	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isOpen !== this.props.isOpen) {
			this.props.initialize({ description: this.props.file.description });
		}
	}

	render = () => {
		const { file } = this.props;
		if (file === undefined || file === null) {
			return null;
		}

		const photoFile = isPhotoFile(file);
		const videoFile = isVideoFile(file);

		return (
			<Modal
				isOpen={this.props.isOpen}
				className="custom-modal photo-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon className="close-icon" icon={faTimes} onClick={this.closeModal} />

				<h2 className="mb-xs">{i18n.t('translation:orders.editFileDescription')}</h2>

				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					{photoFile ? (
						<div className="photo photo-rectangle photo-no-border">
							<div
								style={{
									backgroundImage: `url(${getFileLocation(file.fileName)})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat'
								}}></div>
						</div>
					) : null}

					{videoFile ? (
						<Player>
							<source
								className="video-react-big-play-button"
								src={getFileLocation(file.fileName)}
								alt={file.name}
							/>
						</Player>
					) : null}

					{!photoFile && !videoFile ? (
						<Fragment>
							<div className="nonPhotoFileRegion">
								<div>
									<img src={fileIcon} alt={''} />
								</div>
							</div>
							<div className="input-container full-width">
								<label className="input-label">
									{i18n.t('translation:common.fields.fileType')}
								</label>
								<div>{file.localizedFileTypeName}</div>
							</div>
						</Fragment>
					) : null}

					{Fields.commonFields.description()}

					<div className="buttons">
						<DefaultButton
							type="submit"
							title={i18n.t('translation:common.buttons.save')}
						/>
						<DefaultButton
							title={i18n.t('translation:common.buttons.close')}
							onClick={this.closeModal}
							secondary
						/>
					</div>
				</form>
			</Modal>
		);
	};

	onFormSubmit = (formValues) => {
		this.props.onUpdate(formValues['description']);
		this.closeModal();
	};

	closeModal = () => {
		this.props.onClose();
		this.props.destroy();
	};
}

const editFileDataForm = reduxForm({
	form: 'editFileDataForm',
	validate: formUtils.validateAddOrEditOrderPhoto
})(EditFileDataModal);

const mapStateToProps = (state) => {
	return {
		settings: state.settings
	};
};

export default connect(mapStateToProps, {})(editFileDataForm);
