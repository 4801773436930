import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common/Header';
import i18n from '../../../../../i18n';
import { getLabId, isGlobalAdmin, isTaskManagerEnabled } from '../../../../../utils/appUtils';
import NotFound from '../../../../errors/NotFound';
import Calendar from '../../../../common/Calendar';
import { getAbsenceTypes, getHolidays, getLabDetails, getLabModules } from '../../../../../actions';
import moment from '../../../../../utils/moment';
import { WORKDAY_TYPES } from '../../../../../utils/constants';
import SubHeader from '../../../../common/SubHeader';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarkHolidayModal from './MarkHolidayModal';

class Holidays extends React.Component {
	state = { date: moment().startOf('year'), isModalOpen: false, initialValues: {} };

	componentDidMount() {
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getLabModules(labId);
		this.props.getHolidays(labId, this.state.date.format('YYYY'));
		this.props.getAbsenceTypes();

		if (isGlobalAdmin(this.props.account)) {
			this.props.getLabDetails(labId);
		}
	}

	render() {
		if (!isTaskManagerEnabled(this.props.modules)) {
			return <NotFound />;
		}

		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = isReadOnly ? ' read-only-field' : '';

		const simpleTitle = i18n.t('translation:taskManager.planner.holidays');
		const headerTitle = isGlobalAdmin(this.props.account)
			? this.props.currentLab.name + ' - ' + simpleTitle
			: simpleTitle;
		const backButtonShown = isGlobalAdmin(this.props.account) ? true : false;

		return (
			<Fragment>
				<MarkHolidayModal
					isModalOpen={this.state.isModalOpen}
					closeModal={this.closeModal}
					year={this.state.date.format('YYYY')}
					initialValues={this.state.initialValues}
				/>
				<Header title={headerTitle} buttons={[]} backButton={backButtonShown} />
				{this.renderSubHeader()}
				<div className={'row' + readOnlyClass}>{this.renderCalendars()}</div>
			</Fragment>
		);
	}

	closeModal = () => {
		this.setState({ isModalOpen: false });
	};

	openModal = (initialValues) => {
		this.setState({ isModalOpen: true, initialValues });
	};

	renderSubHeader = () => {
		const nextButton = (
			<button onClick={this.addYear} className="icon-button w-auto">
				{<FontAwesomeIcon className="arrow-button" icon={faCaretRight} />}
			</button>
		);
		const backButton = (
			<button onClick={this.subtractYear} className="icon-button w-auto">
				{<FontAwesomeIcon className="arrow-button" icon={faCaretLeft} />}
			</button>
		);

		const title = (
			<div className="d-flex align-items-center">
				{backButton}
				<h2 className="mr-xs ml-xs">{this.state.date.format('YYYY')}</h2>
				{nextButton}
			</div>
		);

		return <SubHeader title={title} buttons={[]} />;
	};

	renderCalendars = () => {
		const events = this.props.holidays.map((holiday, index) => {
			return {
				id: holiday.id,
				start: holiday.date,
				end: holiday.date,
				type: WORKDAY_TYPES.PUBLIC_HOLIDAY,
				title: holiday.description
			};
		});

		return Array.from(Array(12).keys()).map((el, index) => {
			return (
				<div key={index} className="col-md-12 col-lg-6 col-xl-4 mt-s holiday-calendar">
					<Calendar
						holidays
						events={events}
						date={moment(this.state.date).add(index, 'M')}
						onSelectDay={this.openModal}
						onSelectEvent={this.openModal}
					/>
				</div>
			);
		});
	};

	addYear = () => {
		const labId = getLabId(this.props.location, this.props.account);
		const date = this.state.date.add(1, 'Y');
		this.props.getHolidays(labId, date.format('YYYY'));
		this.setState({ date });
	};

	subtractYear = () => {
		const labId = getLabId(this.props.location, this.props.account);
		const date = this.state.date.subtract(1, 'Y');
		this.props.getHolidays(labId, date.format('YYYY'));
		this.setState({ date });
	};
}

const mapStateToProps = (state) => {
	return {
		modules: state.modules,
		currentLab: state.labs.currentLab,
		account: state.account,
		holidays: state.holidays,
		staff: state.staff
	};
};

export default connect(mapStateToProps, {
	getHolidays,
	getAbsenceTypes,
	getLabModules,
	getLabDetails
})(Holidays);
