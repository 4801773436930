import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { PAGES_PATHS, SINGLE_SPACE } from '../../utils/constants';

const ReturnToHomePage = (props) => (
	<p>
		{i18n.t('translation:returnToHomePage.goToHomepageText')}
		{SINGLE_SPACE}
		<Link
			to={PAGES_PATHS.HOME}
			onClick={() => {
				if (props.onReturnToHomePage) {
					props.onReturnToHomePage();
				}
			}}>
			{i18n.t('translation:returnToHomePage.homePageLinkText')}
		</Link>
		.
	</p>
);

export default ReturnToHomePage;
