import React from 'react';
import { change, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../../../i18n';
import {
	getListWithTooltip,
	parseInvoicingCurrency,
	renderSimpleTable
} from '../../../../../utils/tableUtils';
import SubHeader from '../../../../common/SubHeader';
import {
	OUTCOME_DOCUMENT_TYPE,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_STATUS
} from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import { invoicingTableInput, text } from '../../../../common/Fields';
import { formUtils } from '../../../../../utils/formUtils';
import { add, subtract } from '../../invoicing/billingDocumentModal/utils/documentUtils';

class OutcomesDetailsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultHeader: [
				{
					content: i18n.t('translation:billingModule.common.table.number')
				},
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.outcomeDetails.dateOfIncome'
					)
				},
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.outcomeDetails.documents'
					)
				},
				{ content: i18n.t('translation:billingModule.common.table.amount') },

				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.table.digestedInIncomes'
					)
				},
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.table.digestedInOutcomes'
					)
				},
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.table.undigested'
					)
				},
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.table.digestedInThisOutcome'
					)
				}
			],
			creditNote: [
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.table.dateOfIssue'
					)
				},
				{
					content: i18n.t('translation:billingModule.incomeOutcomeDetails.table.orders')
				},
				{
					content: i18n.t(
						'translation:billingModule.incomeOutcomeDetails.table.digestedInCreditNotes'
					)
				}
			]
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentDocuments !== this.props.currentDocuments) {
			const { DOCUMENT_PAYED_AMOUNT_, DOCUMENTS_IDS, DOCUMENT_REMAINING_AMOUNT_ } =
				TRANSACTION_FORM_FIELD;

			const documentsIds = [];
			if (this.isOutcomeForCreditNotes()) {
				this.props.currentDocuments.forEach(({ id, paidAmount, remainingAmount }) => {
					documentsIds.push(id);
					this.props.change(
						'outcomesDetailsForm',
						`${DOCUMENT_PAYED_AMOUNT_}${id}`,
						paidAmount
					);
					this.props.change(
						'outcomesDetailsForm',
						`${DOCUMENT_REMAINING_AMOUNT_}${id}`,
						remainingAmount
					);
				});
			} else {
				this.props.currentDocuments.forEach(
					({ id, amount, amountRemaining, creditedAmount }) => {
						documentsIds.push(id);
						const payedAmount = subtract(amount, amountRemaining);
						this.props.change(
							'outcomesDetailsForm',
							`${DOCUMENT_PAYED_AMOUNT_}${id}`,
							payedAmount
						);
						this.props.change(
							'outcomesDetailsForm',
							`${DOCUMENT_REMAINING_AMOUNT_}${id}`,
							subtract(amountRemaining, creditedAmount)
						);
					}
				);
			}

			this.props.change('outcomesDetailsForm', DOCUMENTS_IDS, documentsIds);
		}
	}

	getCurrentDocuments() {
		const currentDocuments = [];
		this.props.currentDocuments.forEach(
			({
				id,
				type,
				number,
				issueDate,
				paidAmount,
				remainingAmount,
				labOrderIds,
				localizedCurrencyAbbreviation,
				currencySymbol,
				creditedAmount
			}) => {
				currentDocuments.push(
					this.getDocumentRow(
						id,
						number,
						issueDate,
						type,
						labOrderIds,
						paidAmount,
						remainingAmount,
						localizedCurrencyAbbreviation,
						currencySymbol,
						creditedAmount
					)
				);
			}
		);
		return currentDocuments;
	}

	getTransactionDocuments() {
		const currentDocuments = [];
		this.props.currentDocuments.forEach(
			({
				id,
				type,
				amount,
				amountRemaining,
				creditNotesNumbers,
				billingDocumentsNumbers,
				labTransactionId,
				localizedCurrencyAbbreviation,
				currencySymbol,
				status,
				transactionDate,
				creditedAmount
			}) => {
				currentDocuments.push(
					this.getTransactionRow(
						id,
						type,
						amount,
						amountRemaining,
						creditNotesNumbers,
						billingDocumentsNumbers,
						labTransactionId,
						localizedCurrencyAbbreviation,
						currencySymbol,
						creditedAmount,
						status,
						transactionDate
					)
				);
			}
		);

		return currentDocuments;
	}

	isCreditForInvoice() {
		return (
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_DOCUMENT_TYPE] ===
			OUTCOME_DOCUMENT_TYPE.DEBIT_NOTE
		);
	}

	getTableData() {
		if (this.props.isCreation) {
			if (!this.isCreditForInvoice()) {
				return this.getCurrentDocuments();
			}

			return this.getTransactionDocuments();
		}
		const { billingDocuments, linkedTransactions } = this.props;

		if (billingDocuments?.length) {
			const transactionDocuments = [];

			billingDocuments?.forEach(({ amount, billingDocument }) => {
				const {
					id,
					type,
					number,
					issueDate,
					paidAmount,
					remainingAmount,
					labOrderIds,
					localizedCurrencyAbbreviation,
					currencySymbol,
					totalRounded
				} = billingDocument;
				transactionDocuments.push(
					this.getDocumentRow(
						id,
						number,
						issueDate,
						type,
						labOrderIds,
						paidAmount,
						remainingAmount,
						localizedCurrencyAbbreviation,
						currencySymbol,
						amount,
						totalRounded
					)
				);
			});

			return transactionDocuments;
		} else {
			const transactionDocuments = [];
			linkedTransactions?.forEach(({ amount, debitTransaction }) => {
				const {
					id,
					type,
					transactionDate,
					labTransactionId,
					amountRemaining,
					creditNotesNumbers,
					billingDocumentsNumbers,
					localizedCurrencyAbbreviation,
					currencySymbol,
					creditedAmount
				} = debitTransaction;

				transactionDocuments.push(
					this.getTransactionRow(
						id,
						type,
						amount,
						amountRemaining,
						creditNotesNumbers,
						billingDocumentsNumbers,
						labTransactionId,
						localizedCurrencyAbbreviation,
						currencySymbol,
						creditedAmount,
						TRANSACTION_STATUS.COMPLETED,
						transactionDate,
						debitTransaction.amount
					)
				);
			});
			return transactionDocuments;
		}
	}

	makeItNegativeIfCreditNote(value) {
		if (this.isCreditForInvoice()) {
			return value;
		}

		return value * -1;
	}

	getDocumentRow(
		id,
		number,
		issueDate,
		type,
		labOrderIds,
		paidAmount,
		remainingAmount = 0,
		localizedCurrencyAbbreviation,
		currencySymbol,
		amount,
		totalRounded
	) {
		const documentTotal = this.props.isCreation
			? add(paidAmount, remainingAmount)
			: totalRounded;

		const payedAmountField = this.props.isCreation ? Number(paidAmount) : Number(paidAmount);

		const unpaidPart = this.props.isCreation
			? Number(remainingAmount)
			: subtract(totalRounded, payedAmountField);

		const documentAmount = this.props.isCreation ? documentTotal : amount;

		return [
			number,
			this.getIssueDate(issueDate),
			this.getOrdersIds(labOrderIds),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(documentTotal),
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(payedAmountField),
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(unpaidPart),
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			this.getAmountField(
				id,
				localizedCurrencyAbbreviation,
				currencySymbol,
				unpaidPart,
				documentAmount
			)
		];
	}

	getTransactionRow(
		id,
		type,
		amount,
		amountRemaining = 0,
		creditNotesNumbers,
		billingDocumentsNumbers,
		labTransactionId,
		localizedCurrencyAbbreviation,
		currencySymbol,
		creditedAmount = 0,
		status,
		transactionDate,
		totalAmount
	) {
		const transactionAmount = this.props.isCreation ? Number(amount) : totalAmount;
		const payedAmount = this.props.isCreation
			? subtract(amount, amountRemaining)
			: subtract(totalAmount, amountRemaining);

		const unpaidPart = this.props.isCreation
			? subtract(amountRemaining, creditedAmount)
			: Number(amountRemaining);

		return [
			labTransactionId,
			this.getIssueDate(transactionDate),
			this.getDocumentsNumbers(creditNotesNumbers, billingDocumentsNumbers),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(transactionAmount),
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(payedAmount),
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(creditedAmount),
				localizedCurrencyAbbreviation,
				currencySymbol
			),
			parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(unpaidPart),
				localizedCurrencyAbbreviation,
				currencySymbol,
				id
			),
			this.getAmountField(
				id,
				localizedCurrencyAbbreviation,
				currencySymbol,
				unpaidPart,
				amount
			)
		];
	}

	getDocumentsNumbers(creditNotesNumbers, billingDocumentsNumbers) {
		let documentsNumbers = creditNotesNumbers;

		if (!creditNotesNumbers || !creditNotesNumbers.length) {
			documentsNumbers = billingDocumentsNumbers;
		}

		return getListWithTooltip(
			documentsNumbers,
			i18n.t('translation:billingModule.incomesOutcomes.table.noDocuments'),
			1
		);
	}

	getOrdersIds(labOrderIds) {
		return getListWithTooltip(labOrderIds, '-');
	}

	getIssueDate(issueDate) {
		return moment(issueDate).format('DD.MM.YYYY');
	}

	getAmountField(id, localizedCurrencyAbbreviation, currencySymbol, remainingAmount = 0, amount) {
		const editable = this.props.isCreation;
		if (!editable) {
			return parseInvoicingCurrency(
				this.makeItNegativeIfCreditNote(amount),
				localizedCurrencyAbbreviation,
				currencySymbol
			);
		}

		const disabled = !(
			this.props.formValues.amount !== undefined && this.props.formValues.amount != 0
		);
		return (
			<Field
				name={`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`}
				customProps={{
					disabled: disabled
				}}
				component={invoicingTableInput}
				currency={true}
				localizedCurrencyAbbreviation={localizedCurrencyAbbreviation}
				currencySymbol={currencySymbol}
				normalize={formUtils.normalize.price}
				onChange={(e) =>
					this.onDocumentAmountChange(e.target.value, id, remainingAmount, amount)
				}
			/>
		);
	}

	onDocumentAmountChange(value, id, remainingAmount, amount) {
		const normalizeValue = formUtils.normalize.price(value);

		if (value !== normalizeValue) {
			return;
		}

		const previousValue = Number(
			this.props.formValues[`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`] ?? 0
		);
		const transactionAmount =
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_AMOUNT] ?? 0;

		let totalPayed = add(
			this.props.formValues[TRANSACTION_FORM_FIELD.TOTAL_PAYED],
			subtract(value, previousValue)
		);

		const reminder = subtract(transactionAmount, totalPayed);

		this.props.change(
			'outcomesDetailsForm',
			TRANSACTION_FORM_FIELD.TRANSACTION_REMINDER,
			reminder
		);
		this.props.change(
			'outcomesDetailsForm',
			`${TRANSACTION_FORM_FIELD.DOCUMENT_AMOUNT_}${id}`,
			value
		);
		this.props.change('outcomesDetailsForm', TRANSACTION_FORM_FIELD.TOTAL_PAYED, totalPayed);
	}

	renderSubheader() {
		const formValues = this.props.formValues;
		const { TRANSACTION_CLINIC_ID, TRANSACTION_DOCUMENT_TYPE } = TRANSACTION_FORM_FIELD;

		const outcomeClinicId = formValues[TRANSACTION_CLINIC_ID];
		const outcomeDocumentsType = formValues[TRANSACTION_DOCUMENT_TYPE];

		if (outcomeClinicId === undefined || !outcomeDocumentsType) {
			return null;
		}
		const text =
			outcomeDocumentsType === OUTCOME_DOCUMENT_TYPE.CREDIT_NOTE
				? i18n.t(
						'translation:billingModule.incomeOutcomeDetails.outcomeDetails.creditNotes'
				  )
				: i18n.t('translation:billingModule.incomeOutcomeDetails.outcomeDetails.incomes');

		return (
			<SubHeader
				title={
					<h2 className={'mt-m'} style={{ fontWeight: 'bold' }}>
						{text.toUpperCase()}
					</h2>
				}
				className="pb-2"
				buttons={[]}
			/>
		);
	}

	isOutcomeForCreditNotes() {
		return (
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_DOCUMENT_TYPE] ===
			OUTCOME_DOCUMENT_TYPE.CREDIT_NOTE
		);
	}

	getHeader() {
		const defaultHeader = this.state.defaultHeader;
		const creditHeader = this.state.creditNote;
		if (this.isOutcomeForCreditNotes()) {
			return [
				defaultHeader[0],
				creditHeader[0],
				creditHeader[1],
				defaultHeader[3],
				defaultHeader[5],
				defaultHeader[6],
				defaultHeader[7]
			];
		}
		return defaultHeader;
	}

	getMissingContentMessage() {
		const client = this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_CLINIC_ID];
		const documentsType =
			this.props.formValues[TRANSACTION_FORM_FIELD.TRANSACTION_DOCUMENT_TYPE];

		if (
			!client ||
			!client.toString().trim().length ||
			!documentsType ||
			!documentsType.toString().trim().length
		) {
			return i18n.t(
				'translation:billingModule.incomeOutcomeDetails.outcomeDetails.choseClientAndDocType'
			);
		}
		return i18n.t('translation:common.table.noItems');
	}

	render() {
		return (
			<div>
				{this.renderSubheader()}
				<div className={'mt-4 outcomes-table pb-5'}>
					{renderSimpleTable(
						this.getTableData(),
						'',
						this.getHeader(),
						this.getMissingContentMessage()
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentDocuments: state.billingTransactions.currentCreditDocuments,
		formValues: getFormValues('outcomesDetailsForm')(state)
	};
};

export default connect(mapStateToProps, { change })(OutcomesDetailsTable);
