import React, { Fragment } from 'react';
import { isEmptyObject } from '../../../../../utils/appUtils';
import {
	BILLING_DOCUMENT_STATUS,
	PAGINATION_TYPES,
	SORT_TYPES
} from '../../../../../utils/constants';
import { parseBillingDocuments } from '../../../../../utils/tableUtils';
import i18n from '../../../../../i18n';
import Table from '../../../../common/Table';
import Pagination from '../../../../common/Pagination';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
	getBillingDocuments,
	clearPreCreateModalDocumentReducer,
	setPagination,
	clearPagination
} from '../../../../../actions';
import { PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS } from '../../../../../actions/types';

class CreateCreditOrDebitNoteModalOrdersTable extends React.Component {
	componentDidMount() {
		if (this.props.onSort) {
			this.props.onSort(this.props.sort);
		}

		const filters = this.props.filters;

		this.props.getBillingDocuments({
			page: this.props.page || 1,
			filters: {
				...filters,
				statuses: [
					BILLING_DOCUMENT_STATUS.OPENED,
					BILLING_DOCUMENT_STATUS.PAID,
					BILLING_DOCUMENT_STATUS.PAID_PARTIALLY
				]
			},
			user: this.props.account,
			actionType: PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.page &&
			this.props.page !== 1 &&
			isEmptyObject(this.props.documents.content)
		) {
			this.props.setPagination(PAGINATION_TYPES.BILLING_DOCUMENTS, this.props.page - 1);
			return;
		}

		if (prevProps.page !== this.props.page || prevProps.filters !== this.props.filters) {
			this.props.getBillingDocuments({
				page: this.props.page || 1,
				filters: {
					...this.props.filters,
					statuses: [
						BILLING_DOCUMENT_STATUS.OPENED,
						BILLING_DOCUMENT_STATUS.PAID,
						BILLING_DOCUMENT_STATUS.PAID_PARTIALLY
					]
				},
				user: this.props.account,
				actionType: PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS
			});
		}
	}

	componentWillUnmount() {
		this.props.clearPreCreateModalDocumentReducer();
		this.props.clearPagination(PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_DOCUMENTS);
	}

	render() {
		return (
			<Fragment>
				{this.renderDocuments()}
				{this.renderPagination()}
			</Fragment>
		);
	}

	renderDocuments = () => {
		const columnsNames = [
			i18n.t('translation:billingModule.common.table.number'),
			i18n.t('translation:billingModule.common.table.issueDate'),
			i18n.t('translation:billingModule.common.table.orders'),
			i18n.t('translation:billingModule.common.table.amount'),
			i18n.t('translation:billingModule.common.table.paid'),
			i18n.t('translation:billingModule.common.table.unpaid'),
			<span
				title={i18n.t('translation:billingModule.documents.table.interceptedTooltip')}
				key={'span'}>
				{i18n.t('translation:billingModule.documents.table.interceptedAmount')}*
			</span>,
			i18n.t('translation:billingModule.common.table.taxExcluded'),
			i18n.t('translation:billingModule.common.status'),
			i18n.t('translation:billingModule.common.table.creditDebit')
		];

		const documentsFromState = this.props.documents.content ? this.props.documents.content : [];

		const data = parseBillingDocuments(
			documentsFromState,
			this.props.onSelectDocument,
			this.props.tableFormValues
		);

		return (
			<div className="col-12 mt-s p-0">
				<Table columns={columnsNames} data={data} />
			</div>
		);
	};

	renderPagination = () => {
		const documents = this.props.documents;

		if (documents.content) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={documents.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_DOCUMENTS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		page: state.pagination[PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_DOCUMENTS],
		documents: state.billingDocument.preCreateModal.documents,
		sort: state.sort[SORT_TYPES.ORDER_SORT],
		billingSettings: state.billingSettings,
		formValues: getFormValues('createCreditOrDebitNoteSetDetailsFiltersForm')(state) || {},
		invoicingFormValues: getFormValues('invoicingOrdersFiltersForm')(state),
		tableFormValues: getFormValues('tableForm')(state) || {}
	};
};

export default connect(mapStateToProps, {
	getBillingDocuments,
	clearPreCreateModalDocumentReducer,
	setPagination,
	clearPagination
})(CreateCreditOrDebitNoteModalOrdersTable);
