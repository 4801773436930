import React, { Fragment } from 'react';
import SubHeader from '../../common/SubHeader';
import i18n from '../../../i18n';
import { reduxForm } from 'redux-form';
import { Fields } from '../../common/Fields';
import { connect } from 'react-redux';
import { isGlobalAdmin, isLocalAdmin, isMyCompany } from '../../../utils/appUtils';
import navigationUtils from '../../../utils/navigationUtils';

class ActiveUsersSection extends React.Component {
	componentDidMount() {
		if (!(this.props.initialValues && this.props.initialValues.maxNumber)) {
			this.props.initialize({ maxNumber: 0 });
		}
	}

	renderInput = () => {
		if (isGlobalAdmin(this.props.account)) {
			return Fields.commonFields.maxActiveUsers({
				label: i18n.t('translation:common.fields.maxActiveUsers'),
				className: 'mt-0 flex-column align-items-start'
			});
		} else {
			return (
				<div className="data-row">
					<label className="input-label">
						{i18n.t('translation:common.fields.maxActiveUsers')}
					</label>
					<span>{this.props.initialValues.maxNumber}</span>
				</div>
			);
		}
	};

	render() {
		if (
			!(isLocalAdmin(this.props.account) || isGlobalAdmin(this.props.account)) ||
			(!isGlobalAdmin(this.props.account) && !isMyCompany())
		) {
			return <Fragment />;
		}

		const route = navigationUtils.getRoute().split('/')[1];
		const contentHeightClass =
			isGlobalAdmin(this.props.account) && route === 'labs' ? 'h-m' : 'h-s';

		return (
			<div className="col-6">
				<SubHeader title={i18n.t('translation:common.activeUsers')} />
				<div className="col-12 data-section mt-s p-0">
					<div className={`content ${contentHeightClass}`}>
						<div className="row p-0 h-100 align-items-center">
							<div className="col-12 p-0 d-flex flex-wrap">
								<div className="col-6">{this.renderInput()}</div>
								<div className="col-6">
									<div className="data-row">
										<label className="input-label">
											{i18n.t('translation:common.activeUsers')}
										</label>
										<span>{this.props.company.activeUserCount}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const activeUsersForm = reduxForm({ form: 'activeUsersForm', enableReinitialize: true })(
	ActiveUsersSection
);

const mapStateToProps = (state) => {
	return { account: state.account };
};

export default connect(mapStateToProps)(activeUsersForm);
