import React from 'react';
import { isEmptyObject } from '../../utils/appUtils';

const DataRow = ({ data, first, blueLabel, noColumns, fullWidth, className }) => {
	const dataRowClassName = first ? 'row ' : 'row mt-s';
	let dataColumnClassName = noColumns ? 'data-row' : 'data-row col-' + 12 / data.length;
	const labelClass = blueLabel ? 'blue-label' : undefined;

	function renderValue(value) {
		if (typeof value !== 'object') {
			return <span>{value}</span>;
		}
		return value;
	}

	if (fullWidth) {
		dataColumnClassName += ' full-width';
	}

	if (className) {
		dataColumnClassName += ` ${className}`;
	}

	function renderLabel(title) {
		if (title) {
			return <label className={labelClass}>{title}</label>;
		}
	}

	const dataColumns = data.map((item, index) => {
		if (isEmptyObject(item)) {
			return null;
		}

		return (
			<div
				key={index + 'dataRowColumn'}
				className={dataColumnClassName + (noColumns && index !== 0 ? ' ml-s' : '')}>
				{renderLabel(item.title)}
				{renderValue(item.value)}
			</div>
		);
	});
	return <div className={dataRowClassName + (noColumns ? ' mr-0 ml-0' : '')}>{dataColumns}</div>;
};

export default DataRow;
