import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { parseCompaniesThatSentInvitations } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import SubHeader from '../../common/SubHeader';
import {
	acceptLinkInvitation,
	clearReducers,
	getAllReceivedAndPendingInvitations
} from '../../../actions';

class NotAcceptedInvitations extends React.Component {
	componentDidMount() {
		this.props.getAllReceivedAndPendingInvitations();
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		if (this.props.notAcceptedInvitations.length < 1) {
			return null;
		}

		return (
			<Fragment>
				<SubHeader
					title={
						this.props.fromClinics
							? i18n.t('translation:dashboard.clientsWaitingApproval')
							: i18n.t('translation:dashboard.suppliersWaitingApproval')
					}
				/>
				<div className="col-12 mt-s p-0">{this.renderCompaniesTable()}</div>
			</Fragment>
		);
	}

	renderCompaniesTable = () => {
		const companies = parseCompaniesThatSentInvitations(
			this.props.notAcceptedInvitations,
			this.props.fromClinics,
			this.props.acceptLinkInvitation
		);

		let columns = [
			i18n.t('translation:common.table.name'),
			i18n.t('translation:common.table.phone'),
			i18n.t('translation:common.table.email'),
			i18n.t('translation:common.table.address')
		];
		if (!this.props.fromClinics) {
			columns.push(i18n.t('translation:common.currencyCode'));
		}
		columns.push(i18n.t('translation:common.table.action'));

		return <Table columns={columns} data={companies} />;
	};
}

const mapStateToProps = (state) => {
	return { notAcceptedInvitations: state.invitations.receivedAndNotAccepted };
};

export default connect(mapStateToProps, {
	getAllReceivedAndPendingInvitations,
	acceptLinkInvitation,
	clearReducers
})(NotAcceptedInvitations);
