import React, { Fragment } from 'react';
import i18n from '../../../../../i18n';
import DropdownButton from '../../../../common/DropdownButton';
import { connect } from 'react-redux';
import {
	clearCurrentDocument,
	getBillingSettings,
	getLabDetails,
	getLinkedClinicsWithoutPagination,
	hideMessageModal,
	showMessageModal
} from '../../../../../actions';
import { reset } from 'redux-form';
import { BILLING_DOCUMENT_TYPES } from '../../../../../utils/constants';
import CreateInvoiceModal from '../createInvoiceModal/CreateInvoiceModal';
import CreateCreditOrDebitNoteModal from '../createCreditOrDebitNoteModal/CreateCreditOrDebitNoteModal';
import { isLabAdmin } from '../../../../../utils/appUtils';

class IssueDocumentButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isCreateInvoiceModalOpened: false,
			isCreateCreditOrDebitNoteModalOpened: false,
			invoiceType: BILLING_DOCUMENT_TYPES.FREE_INVOICE,
			clinicId: null
		};
	}

	render() {
		if (!isLabAdmin(this.props.account)) {
			return null;
		}

		const buttons = [
			i18n.t('translation:billingModule.common.buttons.invoice'),
			i18n.t('translation:billingModule.common.buttons.creditNote'),
			i18n.t('translation:billingModule.common.buttons.debitNote')
		];

		const title = i18n.t('translation:billingModule.common.buttons.issue');

		return (
			<Fragment>
				<DropdownButton
					title={title}
					buttons={buttons}
					short={true}
					statusButton={false}
					isSecondButton={false}
					onButtonClick={this.onIssueDocumentButtonClick}
				/>
				{this.renderCreateInvoiceModal()}
				{this.renderCreateCreditOrDebitNoteModal()}
			</Fragment>
		);
	}

	renderCreateInvoiceModal() {
		if (this.state.isCreateInvoiceModalOpened) {
			return (
				<CreateInvoiceModal
					isModalOpen={this.state.isCreateInvoiceModalOpened}
					onModalClose={this.closeCreateInvoiceModal}
					previousScreenFilters={this.props.previousScreenFilters}
					clearPreviousScreenFilterValues={this.props.clearPreviousScreenFilterValues}
					invoiceType={this.state.invoiceType}
					clinicId={this.state.clinicId}
					onDocumentChange={this.props.onDocumentChange}
				/>
			);
		}
		return null;
	}

	renderCreateCreditOrDebitNoteModal() {
		if (this.state.isCreateCreditOrDebitNoteModalOpened) {
			return (
				<CreateCreditOrDebitNoteModal
					isModalOpen={this.state.isCreateCreditOrDebitNoteModalOpened}
					onModalClose={this.closeCreateCreditOrDebitNoteModal}
					previousScreenFilters={this.props.previousScreenFilters}
					clearPreviousScreenFilterValues={this.props.clearPreviousScreenFilterValues}
					invoiceType={this.state.invoiceType}
					clinicId={this.state.clinicId}
					onDocumentChange={this.props.onDocumentChange}
				/>
			);
		}
		return null;
	}

	onIssueDocumentButtonClick = (buttonTitle) => {
		this.props.clearCurrentDocument();

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.invoice')) {
			this.setState({ isCreateInvoiceModalOpened: true });
			return;
		}

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.creditNote')) {
			this.setState({
				isCreateCreditOrDebitNoteModalOpened: true,
				invoiceType: BILLING_DOCUMENT_TYPES.CREDIT_NOTE
			});
			return;
		}

		if (buttonTitle === i18n.t('translation:billingModule.common.buttons.debitNote')) {
			this.setState({
				isCreateCreditOrDebitNoteModalOpened: true,
				invoiceType: BILLING_DOCUMENT_TYPES.DEBIT_NOTE
			});
			return;
		}
	};

	closeCreateInvoiceModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				isCreateInvoiceModalOpened: false,
				invoiceType: BILLING_DOCUMENT_TYPES.FREE_INVOICE,
				clinicId: null
			};
		});
	};

	closeCreateCreditOrDebitNoteModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				isCreateCreditOrDebitNoteModalOpened: false,
				invoiceType: BILLING_DOCUMENT_TYPES.CREDIT_NOTE,
				clinicId: null
			};
		});
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	getBillingSettings,
	getLinkedClinicsWithoutPagination,
	showMessageModal,
	hideMessageModal,
	clearCurrentDocument,
	reset
})(IssueDocumentButton);
