import React, { Fragment } from 'react';
import { change, Field } from 'redux-form';
import { formUtils } from '../../utils/formUtils';
import i18n from '../../i18n';
import Autosuggest from 'react-autosuggest';
import {
	FILTER_FIELDS,
	INVOICE_FIELD_NAME,
	LANGUAGE_TABLE_FIELD_PREFIXES,
	ORDER_FIELDS,
	ORDER_ITEM_FIELDS
} from '../../utils/constants';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from '../../utils/moment';
import { faCheck, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseColorScheme } from '../../utils/appUtils';
import { Multiselect } from 'multiselect-react-dropdown';
import colorWarningIcon from '../../resources/icons/alert-outline.svg';
import { parseInvoicingCurrency, prepareFloatForDisplay } from '../../utils/tableUtils';

export const text = ({
	input,
	label,
	meta,
	customProps = {},
	defaultValue = '',
	currency,
	localizedCurrencyAbbreviation,
	currencySymbol
}) => {
	let className = 'data-row';

	if (customProps.className) {
		className += ` ${customProps.className}`;
	}
	const errorMessage = customProps.noErrorMessage ? (
		<Fragment />
	) : (
		formUtils.renderErrorMessage(meta)
	);

	let text = input.value;

	if (text !== undefined && text.toString().trim().length) {
		if (currency) {
			text = parseInvoicingCurrency(text, localizedCurrencyAbbreviation, currencySymbol);
		}
	} else {
		text = defaultValue;
	}

	return (
		<div className={className}>
			{label ? <label className={customProps.labelClass ?? ''}>{label}</label> : null}
			<div className="d-flex align-items-center">
				<span>{text}</span>
			</div>
			{errorMessage}
		</div>
	);
};

export const textInput = ({ input, label, meta, type, customProps, description }) => {
	let className = '';

	if (customProps.className) {
		className = customProps.className;
	} else {
		className = 'input-container';
	}

	if (customProps.horizontalLabel) {
		className += ' d-flex align-items-center m-0';
	}

	label = customProps.label ? customProps.label : label;

	let labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';

	if (customProps.horizontalLabel) {
		labelClass += ' pr-3 pl-3';
	}

	if (customProps.value || +customProps.value === 0) {
		meta.dispatch(change(meta.form, input.name, customProps.value));
		input.value = customProps.value;
	}

	const inputLabel = customProps.noLabel ? (
		<Fragment />
	) : (
		<label className={labelClass}>{label}</label>
	);

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row'}>
				{inputLabel}
				<div className="d-flex align-items-center">
					<span>{input.value || +input.value === 0 ? input.value : description}</span>
					{customProps.icon}
				</div>
			</div>
		);
	}

	const errorMessage = customProps.noErrorMessage ? (
		<Fragment />
	) : (
		formUtils.renderErrorMessage(meta)
	);

	if (customProps.horizontalLabel) {
		return (
			<div className={className}>
				{inputLabel}
				<div className="position-relative">
					<input
						autoComplete="none"
						className={formUtils.getFieldStyle(meta)}
						{...input}
						type={type}
						placeholder={customProps.placeholder}
						disabled={customProps.disabled}
						maxLength={customProps.maxLength}
					/>
					<div className="position-absolute w-100">{errorMessage}</div>
				</div>
			</div>
		);
	}

	return (
		<div className={className}>
			{inputLabel}
			<input
				autoComplete="none"
				className={formUtils.getFieldStyle(meta)}
				{...input}
				type={type}
				placeholder={customProps.placeholder}
				disabled={customProps.disabled}
				maxLength={customProps.maxLength}
			/>

			{errorMessage}
		</div>
	);
};

export const leftLabelledTextInput = ({ input, label, meta, type, customProps, description }) => {
	let className = '';

	if (customProps.className) {
		className = customProps.className;
	} else {
		className = 'd-flex align-items-center pt-1 pb-3';
	}

	label = customProps.label ? customProps.label : label;

	let labelClass = customProps.blueLabel ? 'blue-label' : 'left-label';

	if (customProps.additionalLabelClass) {
		labelClass = `${labelClass} ${customProps.additionalLabelClass}`;
	}

	if (customProps.value || +customProps.value === 0) {
		meta.dispatch(change(meta.form, input.name, customProps.value));
		input.value = customProps.value;
	}

	const inputLabel = customProps.noLabel ? (
		<Fragment />
	) : (
		<label className={labelClass}>{label}</label>
	);

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row'}>
				{inputLabel}
				<div className="d-flex align-items-center">
					<span className="p-0">
						{input.value || +input.value === 0 ? input.value : description}
					</span>
					{customProps.icon}
				</div>
			</div>
		);
	}

	const errorMessage = customProps.noErrorMessage ? (
		<Fragment />
	) : (
		formUtils.renderErrorMessage(meta)
	);

	return (
		<div className={className}>
			{inputLabel}
			<div className="w-100">
				<input
					autoComplete="none"
					className={formUtils.getFieldStyle(meta)}
					{...input}
					type={type}
					placeholder={customProps.placeholder}
					disabled={customProps.disabled}
					maxLength={customProps.maxLength}
				/>
				<div className="position-absolute w-100">{errorMessage}</div>
			</div>
		</div>
	);
};

export const customDateInput = ({ input, meta, customProps, description, label }) => {
	const className = customProps.className ? customProps.className : 'custom-date-input';
	label = customProps.label ? customProps.label : label;
	const labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';

	const dateFormat = customProps.dateFormat;

	const handleChange = (date) => {
		if (!customProps.controlled) {
			meta.dispatch(change(meta.form, input.name, date));
		}

		if (typeof customProps.onChange == 'function') {
			customProps.onChange(date);
		}
	};

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	};

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row'}>
				{label ? <label className={labelClass}>{label}</label> : null}
				<span>{input.value ? moment(input.value).format('L') : description}</span>
			</div>
		);
	}

	return (
		<Fragment>
			<div className={className + ' date d-block padding-right-12'}>
				{label ? <label className={labelClass}>{label}</label> : null}
				<div className="align-items-center d-flex">
					<DatePicker
						placeholderText={customProps.placeholder}
						onChangeRaw={handleDateChangeRaw}
						showPopperArrow={false}
						className={formUtils.getFieldStyle(meta)}
						selected={input.value}
						onChange={handleChange}
						disabled={customProps.disabled}
						minDate={customProps.minDate}
						maxDate={customProps.maxDate}
						calendarStartDay={1}
						dateFormat={dateFormat}
					/>
					{customProps.renderClearButton && renderClearButton(input, meta)}
				</div>
				{formUtils.renderErrorMessage(meta)}
			</div>
		</Fragment>
	);
};

export const checkbox = ({ input, label, meta, customProps }) => {
	label = customProps.label ? customProps.label : label;
	let labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';
	const className = customProps.className ? customProps.className + ' checkbox' : 'checkbox';
	const inputClassName = customProps.inputClassName
		? `${formUtils.getFieldStyle(meta)} ${customProps.inputClassName}`
		: formUtils.getFieldStyle(meta);

	const defaultChecked = customProps.defaultChecked ? customProps.defaultChecked : input.value;

	return (
		<div className={className}>
			{customProps.right && <label className={`${labelClass} pr-3`}>{label}</label>}
			<input
				autoComplete="none"
				defaultChecked={input.value}
				checked={defaultChecked}
				className={inputClassName}
				{...input}
				type="checkbox"
				disabled={customProps.disabled || customProps.readableInput}
			/>
			{!customProps.right && <label className={labelClass}>{label}</label>}
		</div>
	);
};

export const CustomCheckbox = ({ input, meta, customProps }) => {
	let labelClass = 'input-label';
	const className = customProps.className ? customProps.className + ' checkbox' : 'checkbox';
	const customCheckBoxClassName = customProps.disabled
		? 'custom-checkbox disabled-button'
		: 'custom-checkbox';
	const indicator = input.value ? (
		<FontAwesomeIcon className="note-icon" icon={faCheck} />
	) : customProps.activateModuleSection ? (
		''
	) : (
		<FontAwesomeIcon icon={faTimes} className="note-icon" />
	);
	const label = customProps.activateModuleSection ? (
		<label className="custom-label">{customProps.label}</label>
	) : (
		<label
			htmlFor={customProps.disabled ? input.name + 'disabled' : input.name}
			className={labelClass}>
			<div className={customCheckBoxClassName}>{indicator}</div>
		</label>
	);

	return (
		<div className={className}>
			<input
				autoComplete="none"
				defaultChecked={input.value}
				id={input.name}
				className={customProps.activateModuleSection ? '' : 'd-none'}
				{...input}
				type="checkbox"
				disabled={customProps.disabled || customProps.readableInput}
			/>
			{label}
		</div>
	);
};

export const tableCheckbox = ({ input, label, meta, customProps }) => {
	label = customProps.label ? customProps.label : label;
	let labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';
	const className = customProps.className ? customProps.className + ' checkbox' : 'checkbox';
	const inputClassName = customProps.inputClassName
		? `${formUtils.getFieldStyle(meta)} ${customProps.inputClassName}`
		: formUtils.getFieldStyle(meta);

	return (
		<div className={className}>
			{customProps.right && <label className={`${labelClass} pr-3`}>{label}</label>}
			<input
				autoComplete="none"
				checked={!!customProps.checkedValue}
				className={inputClassName}
				{...input}
				type="checkbox"
				disabled={customProps.disabled || customProps.readableInput}
			/>
			{!customProps.right && <label className={labelClass}>{label}</label>}
		</div>
	);
};

export const controlledCheckbox = ({ label, meta, customProps }) => {
	label = customProps.label ? customProps.label : label;
	const className = customProps.className;

	return (
		<div className={className}>
			{!customProps.noLabel && <label className="input-label pr-3">{label}</label>}
			<input
				checked={customProps.checked}
				className={formUtils.getFieldStyle(meta)}
				type="checkbox"
				disabled={customProps.disabled || customProps.readableInput}
				onChange={customProps.onChange}
			/>
		</div>
	);
};

export const invoicingTableInput = ({
	input,
	meta,
	type,
	customProps,
	localizedCurrencyAbbreviation,
	currencySymbol,
	prefix
}) => {
	const className = customProps.className
		? customProps.className + ' table-input'
		: 'table-input';

	if (customProps.readableInput) {
		return parseInvoicingCurrency(
			input.value,
			localizedCurrencyAbbreviation,
			currencySymbol,
			prefix
		);
	}

	const currencyPrefix = currencySymbol ? (
		<span className="currency d-inline" style={{ marginRight: '0.25rem' }}>
			{currencySymbol}
		</span>
	) : null;

	const suffix = localizedCurrencyAbbreviation ? (
		<span className="currency d-inline" style={{ marginLeft: '0.25rem' }}>
			{localizedCurrencyAbbreviation}
		</span>
	) : null;

	const label = customProps.label ? (
		<label className="input-label">{customProps.label}</label>
	) : null;

	return (
		<div className={className}>
			{label}
			<span style={{ marginRight: '0.25rem' }}>{prefix}</span>
			{currencyPrefix}
			<input
				autoComplete="off"
				className={formUtils.getFieldStyle(meta)}
				{...input}
				type={type}
				disabled={customProps.disabled}
				placeholder={customProps.placeholder}
				maxLength={customProps.maxLength}
			/>
			{suffix}
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

export const tableInput = ({ input, meta, type, customProps, description, currency }) => {
	const className = customProps.className
		? customProps.className + ' table-input'
		: 'table-input';

	let prefixValue = '';
	if (customProps.prefix && input.value && +input.value !== 0) {
		prefixValue = customProps.prefix;
	}
	if (customProps.currencyPrefix) {
		if (prefixValue.length > 0) {
			prefixValue += ' ';
		}
		prefixValue += customProps.currencyPrefix;
	}

	let prefix = '';
	if (prefixValue.length > 0) {
		// In case we are going to see editable text boxes, adding extra space between the prefix and
		// the text box
		prefix = !customProps.readableInput ? (
			<span style={{ marginRight: '0.25rem' }}>{prefixValue}</span>
		) : (
			<span>{prefixValue}</span>
		);
	} else {
		// This fixes a render bug when we have editable price (with currency)
		// and the currency has only abbreviation (shown after the amount)
		// and not currency symbol (shown before the amount)
		if (currency && !customProps.readableInput) {
			prefix = <span></span>;
		}
	}

	const suffix = currency ? (
		<span className="currency d-inline" style={{ marginLeft: '0.25rem' }}>
			{currency}
		</span>
	) : (
		<Fragment />
	);

	const label = customProps.label ? (
		<label className="input-label">{customProps.label}</label>
	) : (
		<Fragment />
	);
	if (customProps.value) {
		input.value = customProps.value;
	}

	if (customProps.readableInput) {
		let inputValue = null;
		if (input.value) {
			if (customProps.floatWithPrecisionTwo && +input.value !== 0) {
				inputValue = prepareFloatForDisplay(input.value);
			} else if (customProps.invoicingCurrency) {
				inputValue = parseInvoicingCurrency(input.value);
			} else {
				inputValue = input.value;
			}
		}

		return (
			<div className={className + ' readable-input data-row'}>
				{label}
				<span className="d-inline p-0">{prefix}</span>
				<span className="d-inline">{inputValue ? inputValue : description}</span>
				{suffix}
			</div>
		);
	} else {
		return (
			<div className={className}>
				{label}
				{prefix}
				<input
					autoComplete="off"
					className={formUtils.getFieldStyle(meta)}
					{...input}
					type={type}
					disabled={customProps.disabled}
					placeholder={customProps.placeholder}
					maxLength={customProps.maxLength}
				/>
				{suffix}
				{formUtils.renderErrorMessage(meta)}
			</div>
		);
	}
};

const renderClearButton = (input, meta, disabled, onChange) => {
	if (disabled) {
		return null;
	}
	if (input.value) {
		return (
			<FontAwesomeIcon
				className="clear-button"
				icon={faTimesCircle}
				onClick={() => {
					meta.dispatch(change(meta.form, input.name, ''));
					onChange && onChange();
				}}
			/>
		);
	}
	return null;
};

const tableDateInput = ({ input, meta, type, customProps, description, currency }) => {
	const className = customProps.className ? customProps.className : 'table-date-input';
	const prefix = customProps.prefix ? <span>{customProps.prefix}</span> : <Fragment />;
	const fieldCurrency = currency ? <span className="currency">{currency}</span> : <Fragment />;

	if (customProps.readableInput) {
		return (
			<div className={className + 'readable-input data-row'}>
				{prefix}
				<span>{input.value ? moment(input.value).format('L') : description}</span>
				{fieldCurrency}
			</div>
		);
	}

	const handleChange = (date) => {
		meta.dispatch(change(meta.form, input.name, date));
		if (input.name === _.camelCase(ORDER_FIELDS.PAYMENT_DUE_DATE)) {
			formUtils.updateDraft();
		}
	};

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	};

	return (
		<div className={className}>
			<DatePicker
				onChangeRaw={handleDateChangeRaw}
				showPopperArrow={false}
				className={formUtils.getFieldStyle(meta)}
				selected={input.value}
				onChange={handleChange}
				disabled={customProps.disabled}
				minDate={moment().toDate()}
				calendarStartDay={1}
				dateFormat={moment
					.localeData()
					.longDateFormat('L')
					.toLowerCase()
					.replace('mm', 'MM')
					.replace('m', 'M')}
			/>
			{renderClearButton(input, meta)}
		</div>
	);
};

export const dateInput = ({ input, meta, customProps, description, allowOldDate, label }) => {
	const className = customProps.className ? customProps.className : 'input-container';
	label = customProps.label ? customProps.label : label;
	const labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';
	let dateFormat = customProps.timeInput
		? 'HH:mm'
		: moment
				.localeData()
				.longDateFormat('L')
				.toLowerCase()
				.replace('mm', 'MM')
				.replace('m', 'M');
	let showTimeSelectOnly = customProps.timeInput;
	if (customProps.dateInput && customProps.timeInput) {
		dateFormat = 'yyyy-MM-dd, H:mm';
		showTimeSelectOnly = false;
	}

	const handleChange = (date) => {
		meta.dispatch(change(meta.form, input.name, date));
		if (input.name === _.camelCase(ORDER_FIELDS.DUE_DATE)) {
			formUtils.updateDraft();
		}

		if (typeof customProps.onChange == 'function') {
			const isStartTime = input.name.toString().startsWith('s');

			if (input.value && input.value !== '') {
				if (moment(input.value).format('HH:mm') !== '00:00') {
					customProps.onChange(input.value, isStartTime);
				} else {
					customProps.onChange(date, isStartTime);
				}
			} else if (moment(date).format('HH:mm') !== '00:00') {
				customProps.onChange(date, isStartTime);
			}
		}
	};

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	};

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row'}>
				{label ? <label className={labelClass}>{label}</label> : null}
				<span>{input.value ? moment(input.value).format('L') : description}</span>
			</div>
		);
	}

	return (
		<Fragment>
			<div className={className + ' date d-block'}>
				{label ? <label className={labelClass}>{label}</label> : null}
				<div className="align-items-center d-flex">
					<DatePicker
						placeholderText={customProps.placeholder}
						onChangeRaw={handleDateChangeRaw}
						showPopperArrow={false}
						className={formUtils.getFieldStyle(meta)}
						selected={input.value}
						onChange={handleChange}
						showTimeSelect={customProps.timeInput}
						showTimeSelectOnly={showTimeSelectOnly}
						timeIntervals={15}
						timeFormat={'HH:mm'}
						excludeTimes={customProps.excludeTimes}
						disabled={customProps.disabled}
						minDate={allowOldDate ? false : moment().toDate()}
						calendarStartDay={1}
						dateFormat={dateFormat}
					/>
					{renderClearButton(input, meta)}
				</div>
				{formUtils.renderErrorMessage(meta)}
			</div>
		</Fragment>
	);
};

export const radioInput = ({ input, label, meta, customProps }) => {
	const className = customProps.className ? customProps.className : 'input-container';
	label = customProps.label ? customProps.label : label;
	const labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';

	const radioButtons = customProps.radioButtons.map((button, index) => {
		const radioId = index + 'radioButton';
		return (
			<div key={radioId} className="radio">
				<input
					id={radioId}
					autoComplete="none"
					className={formUtils.getFieldStyle(meta)}
					{...input}
					type="radio"
					value={button.value}
					disabled={customProps.disabled}
					checked={input.value === button.value}
				/>
				<label htmlFor={radioId} className="input-label">
					{button.name}
				</label>
			</div>
		);
	});

	return (
		<div className={className}>
			<label className={labelClass}>{label}</label>
			{radioButtons}
		</div>
	);
};

export const textArea = ({ input, label, meta, customProps, description, maxLength }) => {
	const className = customProps.className ?? 'input-container';
	label = customProps.label ? customProps.label : label;
	const labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';
	const labelView = label ? <label className={labelClass}>{label}</label> : null;

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row'}>
				{labelView}
				<span>{input.value ? input.value : description}</span>
			</div>
		);
	}

	return (
		<div className={className}>
			{labelView}
			<textarea
				autoComplete="none"
				className={formUtils.getFieldStyle(meta)}
				{...input}
				disabled={customProps.disabled}
				placeholder={customProps.placeholder}
				maxLength={maxLength}
			/>
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

export const selectInput = ({ input, label, meta, customProps, description }) => {
	let options = customProps.options || [];

	options = options.map((option, index) => {
		if (option) {
			return (
				<option key={option.name + index} value={option.value} disabled={option.disabled}>
					{option.name}
				</option>
			);
		}
	});

	let className = customProps.horizontalLabel
		? 'd-flex col-12 pl-0 pr-0 align-items-center'
		: customProps.noLabel
		? ''
		: 'input-container';

	if (customProps.className) {
		className = ` ${customProps.className}`;
	}

	label = customProps.label ? customProps.label : label;
	let labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';

	if (customProps.horizontalLabel) {
		labelClass += ' pr-3';
	}

	let readOnlyValue = _.find(customProps.options, function (option) {
		return option.value === input.value;
	});
	if (readOnlyValue) {
		readOnlyValue = readOnlyValue.name;
	}

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row readable-input'}>
				{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
				<div className="d-flex align-items-center">
					<span>{readOnlyValue ? readOnlyValue : description}</span>
					{customProps.icon}
				</div>
			</div>
		);
	}

	if (customProps.horizontalLabel) {
		return (
			<div className={className}>
				{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
				<div className="position-relative col-9 pl-0 pr-0">
					<select
						className={formUtils.getFieldStyle(meta).concat(' w-100')}
						{...input}
						disabled={customProps.disabled}>
						{options}
					</select>
					<div className="position-absolute w-100">
						{formUtils.renderErrorMessage(meta)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={className}>
			{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
			<select
				className={formUtils.getFieldStyle(meta)}
				{...input}
				disabled={customProps.disabled}>
				{options}
			</select>
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

export const leftLabelledSelectInput = ({ input, label, meta, customProps, description }) => {
	let options = customProps.options || [];

	options = options.map((option, index) => {
		if (option) {
			return (
				<option key={option.name + index} value={option.value} disabled={option.disabled}>
					{option.name}
				</option>
			);
		}
	});

	let className = '';

	if (customProps.className) {
		className = customProps.className;
	} else {
		className = 'd-flex align-items-center pb-3 pt-1';
	}

	let innerClassName = 'position-relative ';

	if (customProps.innerClassName) {
		innerClassName = innerClassName + customProps.innerClassName;
	} else {
		innerClassName = innerClassName + 'col-8 pl-0 pr-0';
	}

	label = customProps.label ? customProps.label : label;

	let labelClass = customProps.blueLabel ? 'blue-label' : 'left-label';

	if (customProps.additionalLabelClass) {
		labelClass = `${labelClass} ${customProps.additionalLabelClass}`;
	}

	let readOnlyValue = _.find(customProps.options, function (option) {
		return option.value.toString() === input.value.toString();
	});

	if (readOnlyValue) {
		readOnlyValue = readOnlyValue.name;
	}

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row readable-input'}>
				{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
				<div className="d-flex align-items-center">
					<span className={'pt-0'}>{readOnlyValue ? readOnlyValue : description}</span>
					{customProps.icon}
				</div>
			</div>
		);
	}

	return (
		<div className={className}>
			{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
			<div className={innerClassName}>
				<select
					className={formUtils.getFieldStyle(meta).concat(' w-100')}
					{...input}
					disabled={customProps.disabled}>
					{options}
				</select>
				<div className="position-absolute w-100">{formUtils.renderErrorMessage(meta)}</div>
			</div>
		</div>
	);
};

export const MultipleSelectInput = ({ input, label, meta, customProps, description }) => {
	let options = customProps.options || [];

	const className = customProps.className ? customProps.className : 'input-container ';
	label = customProps.label ? customProps.label : label;
	const labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';
	let readOnlyValue = _.find(customProps.options, function (option) {
		return option.value === input.value;
	});
	if (readOnlyValue) {
		readOnlyValue = readOnlyValue.name;
	}

	if (customProps.readableInput) {
		return (
			<div className={className + ' data-row readable-input'}>
				{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
				<span>{readOnlyValue ? readOnlyValue : description}</span>
			</div>
		);
	}

	const selectedValues = _.filter(options, function (option) {
		return input.value.includes(option.id);
	});

	return (
		<div className={className}>
			{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}
			<Multiselect
				options={options}
				selectedValues={selectedValues} // Preselected value to persist in dropdown
				onSelect={(values) => {
					meta.dispatch(
						change(
							meta.form,
							input.name,
							values.map((value) => value.id)
						)
					);
				}} // Function will trigger on select event
				onRemove={(values) => {
					meta.dispatch(
						change(
							meta.form,
							input.name,
							values.map((value) => value.id)
						)
					);
				}} // Function will trigger on remove event
				displayValue="name" // Property name to display in the dropdown options
				{...input}
				placeholder={''}
				closeOnSelect={false}
				hidePlaceholder={true}
				avoidHighlightFirstOption={true}
				showCheckbox={true}
				selectionLimit={customProps.selectionLimit}
				onSearch={customProps.onSearch}
			/>
		</div>
	);
};

const hours = ({ input, label, meta, customProps }) => {
	const inputLabel = customProps.noLabel ? (
		<Fragment />
	) : (
		<label className={'input-label'}>{label}</label>
	);
	const className = customProps.className ? customProps.className : 'input-container ';

	return (
		<div className={className}>
			{inputLabel}
			<div className="d-flex hours">
				<input
					autoComplete="none"
					className={formUtils.getFieldStyle(meta)}
					{...input}
					type="text"
					placeholder={customProps.placeholder}
					disabled={customProps.disabled}
				/>
				{Fields.templateFields.minutes({ id: customProps.id })}
			</div>
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

const minutes = ({ input, meta, customProps }) => {
	const minutes = ['00', '15', '30', '45'].map((minutes) => (
		<option key={minutes} value={minutes}>
			{minutes}
		</option>
	));
	minutes.unshift(
		<option value={''} disabled hidden>
			{i18n.t('translation:taskManager.inventory.fields.placeholders.minutes')}
		</option>
	);

	return (
		<div className="minutes ml-xs">
			<select
				className={
					input.value
						? formUtils.getFieldStyle(meta) + ' color-dark'
						: formUtils.getFieldStyle(meta) + ' color-gray'
				}
				{...input}
				disabled={customProps.disabled}>
				{minutes}
			</select>
		</div>
	);
};

const colorInput = ({ input, label, meta, customProps }) => {
	const schemes =
		customProps.readableInput && !customProps.disabled
			? []
			: [<option key={'noColor'} value={''}></option>];
	for (let colorScheme in customProps.options) {
		schemes.push(
			<option key={colorScheme} value={colorScheme}>
				{parseColorScheme(colorScheme)}
			</option>
		);
	}

	const codes = [{ name: '', value: '' }];
	for (let colorCode of customProps.options[input.value] || []) {
		codes.push({ name: colorCode, value: colorCode });
	}

	label = customProps.label ? customProps.label : label;
	const labelClass = customProps.blueLabel ? 'blue-label' : 'input-label';

	const selectClass = formUtils.getFieldStyle(meta) + ' color-scheme';

	function renderColorCode() {
		const icon =
			customProps.isOriginalColorScheme || !customProps.colorCode ? null : (
				<img
					className="normal-icon-size color-warning-icon"
					title={i18n.t('translation:orders.fields.tooltips.notOriginalColor')}
					src={colorWarningIcon}
					alt="color-warning-icon"
				/>
			);
		if (!customProps.loader) {
			return Fields.orderItemFields.colorCode({
				className: 'color-code ml-xs',
				options: codes,
				description: !customProps.colorCode
					? i18n.t('translation:orders.notSpecified')
					: customProps.colorCodeEquivalent,
				readableInput: customProps.readableInput,
				disabled: customProps.disabled,
				icon
			});
		} else {
			return <div className="color-code ml-xs" />;
		}
	}

	return (
		<div className={customProps.className ? customProps.className : ''}>
			<div className="d-flex">
				<div>
					<div>
						<label className={labelClass}>{label}</label>
					</div>
					<select className={selectClass} {...input} disabled={customProps.disabled}>
						{schemes}
					</select>
				</div>
				<div>{renderColorCode()}</div>
			</div>
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

const autocompleteInput = ({
	input,
	label,
	meta,
	customProps,
	getSuggestions,
	clearSuggestions,
	suggestions
}) => {
	const className = customProps.className ? customProps.className : 'input-container';

	label = customProps.label ? customProps.label : label;

	const getSuggestionValue = (suggestion) => suggestion;
	const renderSuggestion = (suggestion) => (
		<div className="suggestion">{suggestion.legalName}</div>
	);
	const onSuggestionSelected = (event, { suggestion }) => {
		meta.dispatch(change(meta.form, input.name, suggestion.legalName));
	};

	return (
		<div className={className}>
			<label className="input-label">{label}</label>
			<Autosuggest
				suggestions={suggestions}
				onSuggestionSelected={onSuggestionSelected}
				onSuggestionsFetchRequested={getSuggestions}
				onSuggestionsClearRequested={clearSuggestions}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={{ ...input, className: formUtils.getFieldStyle(meta) }}
			/>
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

const taxGroupsDropdownInput = ({ input, label, meta, customProps, description }) => {
	let options = customProps.options || [];

	options = options.map((option, index) => {
		if (option) {
			return (
				<option key={option.name + index} value={option.id} disabled={option.disabled}>
					{i18n.t('translation:inventory.fields.taxGroupWithRate', {
						name: option.name,
						rate: option.percentage
					})}
				</option>
			);
		}
	});

	options.unshift(
		<option key={''} value={''}>
			{''}
		</option>
	);
	//options.unshift({ name: '', value: '' });

	let readOnlyValue = _.find(customProps.options, function (option) {
		return option.id === input.value;
	});

	if (readOnlyValue) {
		readOnlyValue = `${readOnlyValue.name} (${readOnlyValue.percentage}%)`;
	}

	if (customProps.readableInput) {
		return (
			<div className={'data-row readable-input'}>
				{/*{customProps.noLabel ? <Fragment /> : <label className={labelClass}>{label}</label>}*/}
				<div className="d-flex align-items-center">
					<span>{readOnlyValue}</span>
					{customProps.icon}
				</div>
			</div>
		);
	}

	return (
		<div className={customProps.className}>
			{customProps.noLabel ? <Fragment /> : <label>{customProps.label}</label>}
			<select
				className={formUtils.getFieldStyle(meta)}
				{...input}
				disabled={customProps.disabled}>
				{options}
			</select>
			{formUtils.renderErrorMessage(meta)}
		</div>
	);
};

export const Fields = {
	commonFields: {
		email: (customProps = {}) => (
			<Field
				name="email"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.email')}
				type="text"
				component={textInput}
			/>
		),
		password: (customProps = {}) => (
			<Field
				name="password"
				customProps={customProps}
				label={i18n.t('translation:common.fields.password')}
				type="password"
				component={textInput}
			/>
		),
		oldPassword: (customProps = {}) => (
			<Field
				name="oldPassword"
				customProps={customProps}
				label={i18n.t('translation:users.fields.oldPassword')}
				type="password"
				component={textInput}
			/>
		),
		repeatPassword: (customProps = {}) => (
			<Field
				name="repeatPassword"
				customProps={customProps}
				label={i18n.t('translation:common.fields.repeatPassword')}
				type="password"
				component={textInput}
			/>
		),
		receiveEmailNotifications: (customProps = {}) => (
			<Field
				name="receiveEmailNotifications"
				customProps={customProps}
				label={i18n.t('translation:users.preferences.receiveEmailNotifications')}
				component={checkbox}
			/>
		),
		userName: (customProps = {}) => (
			<Field
				name="username"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.userName')}
				type="text"
				component={textInput}
			/>
		),
		userNameOrEmail: (customProps = {}) => (
			<Field
				name="userNameOrEmail"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.userNameOrEmail')}
				type="text"
				component={textInput}
			/>
		),
		phone: (customProps = {}) => (
			<Field
				name="phone"
				customProps={{ ...customProps, placeholder: '+359 888 123 456' }}
				label={i18n.t('translation:auth.fields.phone')}
				type="text"
				component={textInput}
			/>
		),
		userType: (customProps = {}) => (
			<Field
				name="userType"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.type')}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		organizationType: (customProps = {}) => (
			<Field
				name="organizationType"
				customProps={customProps}
				label={i18n.t('translation:common.table.organization')}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		organizationEmail: (customProps = {}) => (
			<Field
				name="organizationEmail"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.email')}
				type="text"
				component={textInput}
				onChange={customProps.onChange}
			/>
		),
		organizationPhone: (customProps = {}) => (
			<Field
				name="organizationPhone"
				customProps={{ ...customProps, placeholder: '+359 888 123 456' }}
				label={i18n.t('translation:auth.fields.phone')}
				type="text"
				component={textInput}
			/>
		),
		registrationCode: (customProps = {}) => (
			<Field
				name="registrationCode"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.registrationCode')}
				type="text"
				component={textInput}
			/>
		),
		firstName: (customProps = {}) => (
			<Field
				name="firstName"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.firstName')}
				type="text"
				component={textInput}
			/>
		),
		lastName: (customProps = {}) => (
			<Field
				name="lastName"
				customProps={customProps}
				label={i18n.t('translation:auth.fields.lastName')}
				type="text"
				component={textInput}
			/>
		),
		role: (customProps = {}) => (
			<Field
				name="role"
				customProps={customProps}
				label={i18n.t('translation:users.fields.role')}
				component={selectInput}
			/>
		),
		name: (customProps = {}) => (
			<Field
				name="name"
				customProps={customProps}
				label={i18n.t('translation:common.fields.name')}
				type="text"
				component={textInput}
			/>
		),
		legalName: (customProps = {}) => (
			<Field
				name="legalName"
				customProps={customProps}
				label={i18n.t('translation:common.fields.legalName')}
				type="text"
				component={textInput}
			/>
		),
		address: (customProps = {}) => (
			<Field
				name="address"
				customProps={customProps}
				label={i18n.t('translation:common.fields.address')}
				type="text"
				component={textArea}
			/>
		),
		registeredOffice: (customProps = {}) => (
			<Field
				name="registeredOffice"
				customProps={customProps}
				label={i18n.t('translation:common.fields.registeredOffice')}
				component={textArea}
			/>
		),
		owner: (customProps = {}) => (
			<Field
				name="owner"
				customProps={customProps}
				label={i18n.t('translation:common.fields.companyOwner')}
				type="text"
				component={textInput}
			/>
		),
		uic: (customProps = {}) => (
			<Field
				name="uic"
				customProps={customProps}
				label={i18n.t('translation:common.fields.uic')}
				type="text"
				component={textInput}
			/>
		),
		vatNumber: (customProps = {}) => (
			<Field
				name="vatNumber"
				customProps={customProps}
				label={i18n.t('translation:common.fields.vatNumber')}
				type="text"
				component={textInput}
				maxLength={20}
			/>
		),
		currencyCode: (customProps = {}) => (
			<Field
				name="currencyCode"
				customProps={customProps}
				label={i18n.t('translation:common.currencyCode')}
				component={selectInput}
			/>
		),
		IBAN: (customProps = {}) => (
			<Field
				name="iban"
				customProps={customProps}
				label={i18n.t('translation:common.fields.IBAN')}
				type="text"
				component={textInput}
			/>
		),
		BIC: (customProps = {}) => (
			<Field
				name="bic"
				customProps={customProps}
				label={i18n.t('translation:common.fields.BIC')}
				type="text"
				component={textInput}
			/>
		),
		bankName: (customProps = {}) => (
			<Field
				name="bankName"
				customProps={customProps}
				label={i18n.t('translation:common.fields.bankName')}
				type="text"
				component={textInput}
			/>
		),
		bankAddress: (customProps = {}) => (
			<Field
				name="bankAddress"
				customProps={customProps}
				label={i18n.t('translation:common.fields.bankAddress')}
				type="text"
				component={textArea}
			/>
		),
		maxActiveUsers: (customProps = {}) => (
			<Field
				name="maxNumber"
				customProps={customProps}
				normalize={formUtils.normalize.activeUsers}
				type="text"
				component={tableInput}
			/>
		),
		globalProductCategory: (customProps = {}) => (
			<Field
				name="category"
				customProps={customProps}
				label={i18n.t('translation:inventory.fields.category')}
				component={selectInput}
			/>
		),
		type: (customProps = {}) => (
			<Field
				name="type"
				customProps={customProps}
				label={i18n.t('translation:inventory.fields.type')}
				type="text"
				component={textInput}
			/>
		),
		material: (customProps = {}) => (
			<Field
				name="material"
				customProps={customProps}
				label={i18n.t('translation:inventory.fields.material')}
				type="text"
				component={textInput}
			/>
		),
		noColor: (customProps = {}) => (
			<Field
				name="hasColor"
				customProps={customProps}
				label={i18n.t('translation:inventory.fields.noColor')}
				component={checkbox}
			/>
		),
		topic: (customProps = {}) => (
			<Field
				name="topic"
				customProps={customProps}
				label={i18n.t('translation:common.fields.topic')}
				type="text"
				component={textInput}
			/>
		),
		description: (customProps = {}) => (
			<Field
				name="description"
				customProps={customProps}
				label={i18n.t('translation:common.fields.description')}
				type="text"
				component={textArea}
			/>
		),
		fileType: (customProps = {}) => (
			<Field
				name="fileType"
				customProps={customProps}
				label={i18n.t('translation:common.fields.fileType')}
				type="text"
				component={textInput}
			/>
		),
		sendInAnotherLanguage: (customProps = {}) => (
			<Field
				name="sendInAnotherLanguage"
				customProps={customProps}
				label={i18n.t('translation:userInvitationModal.sendInAnotherLanguage')}
				onChange={customProps.onChange}
				component={checkbox}
			/>
		),
		languageCode: (customProps = {}) => (
			<Field
				name="languageCode"
				customProps={customProps}
				label={i18n.t('translation:userInvitationModal.language')}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		labs: (
			getSuggestions = () => {},
			clearSuggestions = () => {},
			suggestions = [],
			customProps = {}
		) => (
			<Field
				name="labs"
				customProps={customProps}
				suggestions={suggestions}
				getSuggestions={getSuggestions}
				label={i18n.t('translation:clinics.fields.labs')}
				type="text"
				component={autocompleteInput}
			/>
		),
		clinics: (
			getSuggestions = () => {},
			clearSuggestions = () => {},
			suggestions = [],
			customProps = {}
		) => (
			<Field
				name="clinics"
				customProps={customProps}
				suggestions={suggestions}
				getSuggestions={getSuggestions}
				label={i18n.t('translation:labs.fields.clinics')}
				type="text"
				component={autocompleteInput}
			/>
		),
		receiveMarketingInformation: (customProps = {}) => (
			<Field
				name="receiveMarketingInformation"
				customProps={customProps}
				label={i18n.t('translation:common.fields.receiveMarketingInformation')}
				component={checkbox}
			/>
		),
		termsAndConditions: (customProps = {}) => (
			<Field
				name="termsAndConditions"
				customProps={customProps}
				label={
					<span
						dangerouslySetInnerHTML={{
							__html: i18n.t('translation:common.fields.termsAndConditions')
						}}></span>
				}
				component={checkbox}
			/>
		),
		cookiesPolicy: (customProps = {}) => (
			<Field
				name="cookiesPolicy"
				customProps={customProps}
				label={
					<span
						dangerouslySetInnerHTML={{
							__html: i18n.t('translation:common.fields.cookiesPolicy')
						}}></span>
				}
				component={checkbox}
			/>
		),
		privacyPolicy: (customProps = {}) => (
			<Field
				name="privacyPolicy"
				customProps={customProps}
				label={
					<span
						dangerouslySetInnerHTML={{
							__html: i18n.t('translation:common.fields.privacyPolicy')
						}}></span>
				}
				component={checkbox}
			/>
		),
		activateModule: (customProps = {}) => (
			<Field name="activateModule" customProps={customProps} component={CustomCheckbox} />
		),
		shiftTypeAndAbsence: (customProps = {}) => (
			<Field
				name="shiftAndAbsence"
				customProps={customProps}
				label={i18n.t('translation:taskManager.planner.fields.type')}
				component={selectInput}
			/>
		),
		start: (customProps = {}) => (
			<Field
				name="startDate"
				customProps={customProps}
				label={i18n.t('translation:taskManager.planner.fields.start')}
				type="text"
				component={dateInput}
				allowOldDate={true}
			/>
		),
		end: (customProps = {}) => (
			<Field
				name="endDate"
				customProps={customProps}
				label={i18n.t('translation:taskManager.planner.fields.end')}
				type="text"
				component={dateInput}
				allowOldDate={true}
			/>
		),
		performer: (customProps = {}) => (
			<Field
				name={'performer'}
				label={i18n.t('translation:taskManager.common.fields.performer')}
				customProps={customProps}
				component={selectInput}
			/>
		),
		commonNote: (customProps = {}) => (
			<Field
				name={'notes'}
				label={i18n.t('translation:taskManager.common.fields.commonNote')}
				customProps={customProps}
				component={textArea}
			/>
		),
		availableStaff: (customProps = {}) => (
			<Field
				name={'availableStaff_' + customProps.id}
				customProps={customProps}
				label={i18n.t('translation:taskManager.common.table.assignee')}
				onChange={customProps.onChange}
				onFocus={customProps.onFocus}
				component={selectInput}
			/>
		),
		availableEmployees: (customProps = {}) => (
			<Field
				name={'availableStaff_' + customProps.id}
				customProps={customProps}
				label={i18n.t('translation:taskManager.common.table.assignee')}
				onChange={customProps.onChange}
				onFocus={customProps.onFocus}
				component={MultipleSelectInput}
			/>
		),
		availableUsers: (customProps = {}) => (
			<Field
				name={'availableUsers'}
				customProps={customProps}
				label={i18n.t('translation:taskManager.common.table.assignee')}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		orderType: (customProps = {}) => (
			<Field
				name="orderType"
				customProps={customProps}
				label={i18n.t('translation:taskManager.calendar.staff.editModal.orderTypes')}
				component={selectInput}
			/>
		),
		orders: (customProps = {}) => (
			<Field
				name="ordersList"
				customProps={customProps}
				label={i18n.t('translation:taskManager.common.fields.filters.orderNumber')}
				component={selectInput}
			/>
		),
		orderItems: (customProps = {}) => (
			<Field
				name="orderItems"
				customProps={customProps}
				label={i18n.t('translation:taskManager.calendar.staff.editModal.orderItem')}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		orderTasks: (customProps = {}) => (
			<Field
				name="orderTask"
				customProps={customProps}
				label={i18n.t('translation:taskManager.calendar.staff.editModal.orderTask')}
				component={selectInput}
			/>
		),
		tasksTemplates: (customProps = {}) => (
			<Field
				name="tasksTemplates"
				customProps={customProps}
				label={i18n.t('translation:taskManager.orders.templates')}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		month: (customProps = {}) => (
			<Field
				name={'month'}
				label={i18n.t('translation:taskManager.planner.fields.month')}
				customProps={customProps}
				component={selectInput}
			/>
		),
		year: (customProps = {}) => (
			<Field
				name={'year'}
				label={i18n.t('translation:taskManager.planner.fields.year')}
				customProps={customProps}
				component={selectInput}
			/>
		),
		taskStatus: (customProps = {}) => (
			<Field
				name={customProps.name ? customProps.name : FILTER_FIELDS.TASK_STATUSES}
				label={i18n.t('translation:taskManager.common.fields.taskStatus')}
				customProps={customProps}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		statusNotes: (customProps = {}) => (
			<Field
				name={'statusNote'}
				customProps={customProps}
				label={
					customProps.noLabel
						? null
						: i18n.t('translation:taskManager.common.fields.taskStatusNote')
				}
				type="text"
				component={textArea}
			/>
		)
	},
	tableFields: {
		tableElementPrice: (id, customProps = {}) => (
			<Field
				name={`price_${id}`}
				customProps={customProps}
				type="text"
				currency={
					customProps && customProps.currencySuffix ? customProps.currencySuffix : ''
				}
				normalize={formUtils.normalize.price}
				component={tableInput}
			/>
		),
		tableElementWarranty: (id, customProps = {}) => (
			<Field
				name={`warranty_${id}`}
				customProps={customProps}
				type="text"
				normalize={formUtils.normalize.warranty}
				component={tableInput}
			/>
		),
		tableElementCatalogNumber: (id, customProps = {}) => (
			<Field
				name={`catalogNumber_${id}`}
				customProps={customProps}
				type="text"
				component={tableInput}
			/>
		),
		tableElementDiscount: (id, customProps = {}) => (
			<Field
				name={`discount_${id}`}
				customProps={customProps}
				type="text"
				currency="%"
				normalize={formUtils.normalize.percent}
				component={tableInput}
			/>
		),
		tableElementProductType: (languageCode, customProps = {}) => {
			customProps.className = customProps.className
				? customProps.className + ' table-input-align-left'
				: 'table-input-align-left';
			return (
				<Field
					name={`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_TYPE}${languageCode}`}
					type="text"
					component={tableInput}
					customProps={customProps}
				/>
			);
		},
		tableElementProductMaterial: (languageCode, customProps = {}) => {
			customProps.className = customProps.className
				? customProps.className + ' table-input-align-left'
				: 'table-input-align-left';
			return (
				<Field
					name={`${LANGUAGE_TABLE_FIELD_PREFIXES.PRODUCT_MATERIAL}${languageCode}`}
					type="text"
					component={tableInput}
					customProps={customProps}
				/>
			);
		},
		tableElementPriceList: (id, customProps = {}) => (
			<Field
				name={`isIndividualPriceListEnabled_${id}`}
				customProps={customProps}
				component={CustomCheckbox}
			/>
		),
		tableElementInvoiceDueDays: (id, unit, customProps = {}) => (
			<Field
				name={`invoiceDueDays_${id}`}
				customProps={customProps}
				currency={unit}
				normalize={formUtils.normalize.invoiceDueDays}
				component={tableInput}
			/>
		),

		isUserMarked: (customProps = {}) => (
			<Field name={'user_' + customProps.id} customProps={customProps} component={checkbox} />
		),
		generalNote: (customProps = {}) => (
			<Field
				name={'generalNote_' + customProps.id}
				customProps={customProps}
				label={
					customProps.noLabel
						? null
						: i18n.t('translation:taskManager.common.table.generalNote')
				}
				type="text"
				component={textArea}
			/>
		),
		tableElementTaxGroup: (id, customProps = {}) => (
			<Field
				name={`taxGroupId_${id}`}
				customProps={customProps}
				type="text"
				//label={i18n.t(`translation:taskManager.common.fields.productName`)}
				//currency="%"
				//normalize={formUtils.normalize.percent}
				component={taxGroupsDropdownInput}
			/>
		)
	},
	orderFields: {
		paymentTerms: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PAYMENT_TERMS)}
				customProps={{ ...customProps, className: 'full-width-input' }}
				type="text"
				description={i18n.t('translation:orders.fields.willBeFilledFromSupplier')}
				onBlur={formUtils.updateDraft}
				component={tableInput}
			/>
		),
		initialPrice: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.INITIAL_PRICE)}
				type="text"
				customProps={customProps}
				currency={
					customProps && customProps.currencySuffix ? customProps.currencySuffix : ''
				}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
				component={invoicingTableInput}
			/>
		),
		priceIncrease: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PRICE_INCREASE)}
				type="text"
				normalize={formUtils.normalize.price}
				customProps={{ ...customProps }}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
				prefix={'+ '}
				component={invoicingTableInput}
			/>
		),
		paidAmount: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PAID_AMOUNT)}
				type="text"
				customProps={customProps}
				normalize={formUtils.normalize.price}
				component={invoicingTableInput}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
			/>
		),
		amountDue: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.AMOUNT_DUE)}
				type="text"
				customProps={customProps}
				component={invoicingTableInput}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
			/>
		),
		lastPaymentDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.LAST_PAYMENT_DATE)}
				type="text"
				customProps={customProps}
				description={i18n.t('translation:common.notSet')}
				component={tableDateInput}
			/>
		),
		price: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PRICE)}
				type="text"
				customProps={customProps}
				onBlur={formUtils.updateDraft}
				component={invoicingTableInput}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
			/>
		),
		tax: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.TAX)}
				type="text"
				customProps={customProps}
				onBlur={formUtils.updateDraft}
				component={invoicingTableInput}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
			/>
		),
		priceWithTax: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PRICE_WITH_TAX)}
				type="text"
				customProps={customProps}
				onBlur={formUtils.updateDraft}
				component={invoicingTableInput}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
			/>
		),
		discount: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.DISCOUNT)}
				description={0}
				customProps={customProps}
				type="text"
				normalize={formUtils.normalize.price}
				component={invoicingTableInput}
				localizedCurrencyAbbreviation={customProps.currencySuffix}
				currencySymbol={customProps.currencyPrefix}
				prefix={'- '}
			/>
		),
		paymentDueDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PAYMENT_DUE_DATE)}
				customProps={customProps}
				type="text"
				description={i18n.t('translation:orders.fields.willBeFilledFromSupplier')}
				onBlur={formUtils.updateDraft}
				component={tableDateInput}
			/>
		),
		lab: (customProps = {}) => (
			<Field
				name="lab"
				customProps={customProps}
				label={i18n.t('translation:orders.fields.supplier')}
				type="text"
				component={textInput}
			/>
		),
		clinic: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.CLINIC)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.client')}
				type="text"
				component={textInput}
			/>
		),
		labTechnician: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.LAB_TECHNICIAN)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.labTechnician')}
				type="text"
				onBlur={formUtils.updateDraft}
				component={selectInput}
			/>
		),
		createDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.CREATE_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.createDate')}
				type="text"
				description={i18n.t('translation:orders.fields.willBeGeneratedAfterSend')}
				component={dateInput}
			/>
		),
		dueDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.DUE_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.dueDate')}
				type="text"
				component={dateInput}
			/>
		),
		deliveryDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.DELIVERY_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.deliveryDate')}
				type="text"
				description={i18n.t('translation:orders.fields.canBeEnteredOnReceiving')}
				component={dateInput}
			/>
		),
		dueDateWithoutDescription: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.DUE_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.dueDate')}
				type="text"
				component={dateInput}
			/>
		),
		deliveryLocation: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.DELIVERY_LOCATION)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.deliveryLocation')}
				type="text"
				onBlur={formUtils.updateDraft}
				component={selectInput}
			/>
		),
		returnLocation: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.RETURN_LOCATION)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.returnLocation')}
				type="text"
				description={i18n.t('translation:orders.fields.willBeFilledFromSupplier')}
				onBlur={formUtils.updateDraft}
				component={selectInput}
			/>
		),
		patientName: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PATIENT_NAME)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.patientName')}
				type="text"
				onBlur={formUtils.updateDraft}
				component={textInput}
			/>
		),
		dentist: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.DENTIST)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.dentist')}
				type="text"
				component={textArea}
			/>
		),
		notes: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.NOTES)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.notes')}
				type="text"
				description={i18n.t('translation:common.notSet')}
				onBlur={formUtils.updateDraft}
				component={textArea}
			/>
		),
		availableDentalTechnician: (customProps = {}) => (
			<Field
				name="availableDentalTechnician"
				customProps={customProps}
				label={i18n.t('translation:orders.fields.availableDentalTechnician')}
				component={checkbox}
			/>
		),
		isPriorityOrder: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.IS_PRIORITY_ORDER)}
				customProps={customProps}
				component={checkbox}
			/>
		),
		availableMaterials: (customProps = {}) => (
			<Field
				name="availableMaterials"
				customProps={customProps}
				label={i18n.t('translation:orders.fields.availableMaterials')}
				component={checkbox}
			/>
		),
		patientNameVisibleToLab: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PATIENT_NAME_VISIBLE_TO_LAB)}
				customProps={customProps}
				label={i18n.t('translation:taskManager.orders.fields.isPatientNameVisibleToLab')}
				onChange={formUtils.updateDraft}
				component={checkbox}
			/>
		),
		clientPaysOnTime: (customProps = {}) => (
			<Field
				name="clientPaysOnTime"
				customProps={customProps}
				label={i18n.t('translation:orders.fields.clientPaysOnTime')}
				component={checkbox}
			/>
		),
		comment: (customProps = {}) => (
			<Field
				name="comment"
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.writeComment')
				}}
				type="text"
				component={textArea}
			/>
		),
		colorSwitch: (customProps = {}) => (
			<Field
				name="colorSwitch"
				customProps={customProps}
				label={i18n.t('translation:orders.fields.colorScheme')}
				component={radioInput}
			/>
		),
		description: (customProps = {}) => (
			<Field
				name="description"
				customProps={customProps}
				label={i18n.t('translation:common.fields.description')}
				type="text"
				component={textArea}
			/>
		)
	},
	orderItemFields: {
		productId: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.PRODUCT)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.item')}
				type="text"
				description={customProps.description}
				onChange={customProps.onChange}
				component={selectInput}
			/>
		),
		colorScheme: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.COLOR_SCHEME)}
				customProps={customProps}
				onChange={customProps.onChange}
				label={i18n.t('translation:orders.fields.colorScheme')}
				type="text"
				component={colorInput}
			/>
		),
		colorCode: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.COLOR_CODE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.color')}
				description={customProps.description}
				component={selectInput}
			/>
		),
		patientName: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_FIELDS.PATIENT_NAME)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.patientName')}
				type="text"
				component={textInput}
			/>
		),
		isGroup: (customProps = {}) => (
			<Field name="isGroup" customProps={customProps} type="checkbox" component={checkbox} />
		),
		noColorSpecified: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.NO_COLOR_SPECIFIED)}
				customProps={customProps}
				component={checkbox}
			/>
		),
		metalTestDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.METAL_TEST_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.metalTest')}
				description={i18n.t('translation:common.notSet')}
				component={dateInput}
			/>
		),
		ceramicTestDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.CERAMIC_TEST_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.ceramicTest')}
				description={i18n.t('translation:common.notSet')}
				component={dateInput}
			/>
		),
		individualSpoonTestDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.INDIVIDUAL_SPOON_TEST_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.individualSpoonTest')}
				description={i18n.t('translation:common.notSet')}
				component={dateInput}
			/>
		),
		biteTestDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.BITE_TEST_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.biteTest')}
				description={i18n.t('translation:common.notSet')}
				component={dateInput}
			/>
		),
		arrangedTeethTestDate: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.ARRANGED_TEETH_TEST_DATE)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.arrangedTeethTest')}
				description={i18n.t('translation:common.notSet')}
				component={dateInput}
			/>
		),
		warrantyInMonths: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.WARRANTY_IN_MONTHS)}
				customProps={customProps}
				normalize={formUtils.normalize.warranty}
				component={textInput}
			/>
		),
		notes: (customProps = {}) => (
			<Field
				name={_.camelCase(ORDER_ITEM_FIELDS.NOTES)}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.itemNotes')}
				type="text"
				description={i18n.t('translation:common.notSet')}
				component={textArea}
			/>
		),
		taskStatusValue: (customProps = {}) => (
			<Field
				name="taskStatus"
				customProps={customProps}
				label={i18n.t(`translation:common.fields.name`)}
				component={textInput}
			/>
		),
		taskAssigneeName: (customProps = {}) => (
			<Field
				name={'taskAssignee'}
				customProps={customProps}
				label={i18n.t(`translation:common.fields.name`)}
				component={textInput}
			/>
		)
	},
	filterFields: {
		number: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.LAB_ORDER_ID}
				label={i18n.t('translation:orders.fields.filters.number')}
				customProps={customProps}
				normalize={formUtils.normalize.number}
				component={textInput}
			/>
		),
		supplier: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.LAB_NAME}
				label={i18n.t('translation:orders.fields.filters.supplier')}
				customProps={customProps}
				component={textInput}
			/>
		),
		orderNumber: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.LAB_ORDER_ID_LIST}
				label={i18n.t('translation:taskManager.common.fields.filters.orderNumber')}
				customProps={customProps}
				normalize={formUtils.normalize.labOrder}
				component={textInput}
			/>
		),
		labTechnician: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.LAB_TECHNICIAN_NAME}
				label={i18n.t('translation:orders.fields.filters.labTechnician')}
				customProps={customProps}
				component={textInput}
			/>
		),
		createDateFrom: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.CREATE_DATE_FROM}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.from')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		createDateTo: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.CREATE_DATE_TO}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.to')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		deliveryDateFrom: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.DELIVERY_DATE_FROM}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.from')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		deliveryDateTo: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.DELIVERY_DATE_TO}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.to')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		paymentDueDateFrom: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.PAYMENT_DUE_DATE_FROM}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.from')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		paymentDueDateTo: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.PAYMENT_DUE_DATE_TO}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.to')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		dueDateFrom: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.DUE_DATE_FROM}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.from')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		dueDateTo: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.DUE_DATE_TO}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.to')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		priceFrom: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.PRICE_FROM}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.from')
				}}
				normalize={formUtils.normalize.price}
				component={tableInput}
			/>
		),
		priceTo: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.PRICE_TO}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.to')
				}}
				normalize={formUtils.normalize.price}
				component={tableInput}
			/>
		),
		dentist: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.DENTIST_NAME}
				label={i18n.t('translation:orders.fields.filters.dentist')}
				customProps={customProps}
				component={textInput}
			/>
		),
		client: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.CLINIC_NAME}
				label={i18n.t('translation:orders.fields.filters.client')}
				customProps={customProps}
				component={textInput}
			/>
		),
		practice: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.CLINIC_LEGAL_NAME}
				label={i18n.t('translation:orders.fields.filters.practice')}
				customProps={customProps}
				component={textInput}
			/>
		),
		lab: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.LAB_LEGAL_NAME}
				label={i18n.t('translation:orders.fields.filters.lab')}
				customProps={customProps}
				component={textInput}
			/>
		),
		status: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.STATUSES}
				label={i18n.t('translation:orders.fields.filters.status')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		orderStatus: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.ORDER_STATUSES}
				label={i18n.t('translation:taskManager.common.fields.filters.orderStatus')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		taskStatus: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.TASK_STATUSES}
				label={i18n.t('translation:taskManager.common.fields.filters.taskStatus')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		taskStatusStaff: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.TASK_STATUSES_STAFF}
				label={i18n.t('translation:taskManager.common.fields.filters.taskStatus')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		performer: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.PERFORMERS}
				label={i18n.t('translation:taskManager.common.fields.performer')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		clientList: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.CLINIC_LIST}
				label={i18n.t('translation:orders.fields.filters.client')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		labTechnicianList: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.LAB_TECHNICIAN_ID_LIST}
				label={i18n.t('translation:taskManager.common.fields.performer')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		taskAssigneeList: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.TASK_ASSIGNEE_ID_LIST}
				label={i18n.t('translation:taskManager.common.fields.taskAssignee')}
				customProps={customProps}
				component={MultipleSelectInput}
			/>
		),
		dateFrom: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.START_DATE}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.from')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		dateTo: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.END_DATE}
				customProps={{
					...customProps,
					placeholder: i18n.t('translation:orders.fields.placeholders.to')
				}}
				allowOldDate={true}
				component={dateInput}
			/>
		),
		shiftTypeAndAbsence: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.SHIFTS_AND_ABSENCES}
				customProps={customProps}
				label={i18n.t('translation:taskManager.common.fields.typeWorkingTime')}
				component={selectInput}
			/>
		),
		patientName: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.PATIENT_NAME}
				customProps={customProps}
				label={i18n.t('translation:orders.fields.patientName')}
				type="text"
				component={textInput}
			/>
		),
		taskName: (customProps = {}) => (
			<Field
				name={FILTER_FIELDS.TITLE}
				customProps={customProps}
				label={i18n.t('translation:taskManager.common.fields.filters.taskName')}
				type="text"
				component={textInput}
			/>
		)
	},
	templateFields: {
		nameGlobal: (customProps = {}) => (
			<Field
				name={'name_' + customProps.language + '_' + customProps.id}
				customProps={customProps}
				label={i18n.t(`translation:common.languages.${customProps.language}`)}
				component={textInput}
			/>
		),
		nameLocal: (customProps = {}) => (
			<Field
				name={'name_' + customProps.id}
				customProps={customProps}
				label={i18n.t(`translation:common.fields.name`)}
				component={textInput}
			/>
		),
		productName: (customProps = {}) => (
			<Field
				name={'name_' + customProps.id}
				customProps={customProps}
				label={i18n.t(`translation:taskManager.common.fields.productName`)}
				component={textInput}
			/>
		),
		isDefault: (customProps = {}) => (
			<Field
				name="isDefault"
				customProps={customProps}
				label={i18n.t('translation:taskManager.inventory.fields.default')}
				component={checkbox}
			/>
		),
		minutes: (customProps = {}) => (
			<Field
				name={'minutes_' + customProps.id}
				customProps={customProps}
				component={minutes}
			/>
		),
		duration: (customProps = {}) => (
			<Field
				name={'hours_' + customProps.id}
				customProps={{
					...customProps,
					placeholder: i18n.t(
						'translation:taskManager.inventory.fields.placeholders.hours'
					)
				}}
				label={i18n.t('translation:taskManager.inventory.fields.time')}
				normalize={
					customProps.isDayOfWeek ? formUtils.normalize.hour24 : formUtils.normalize.hour
				}
				component={hours}
			/>
		)
	},
	workingHoursFields: {
		type: (customProps = {}) => (
			<Field
				name="name"
				customProps={customProps}
				label={i18n.t('translation:taskManager.planner.fields.typeName')}
				type="text"
				component={textInput}
			/>
		),
		code: (customProps = {}) => (
			<Field
				name="code"
				customProps={customProps}
				label={i18n.t('translation:taskManager.planner.fields.code')}
				type="text"
				component={textInput}
			/>
		),
		start: (customProps = {}) => (
			<Field
				name="startTime"
				customProps={{ ...customProps, timeInput: true }}
				label={i18n.t('translation:taskManager.planner.fields.start')}
				type="text"
				component={dateInput}
			/>
		),
		end: (customProps = {}) => (
			<Field
				name="endTime"
				customProps={{ ...customProps, timeInput: true }}
				label={i18n.t('translation:taskManager.planner.fields.end')}
				type="text"
				component={dateInput}
			/>
		),
		startDateTime: (customProps = {}) => (
			<Field
				name={'startDateTime_' + customProps.id}
				customProps={{
					...customProps,
					timeInput: true,
					dateInput: true,
					onlyTimeInput: false,
					dateTimeFormat: 'YYYY-MM-DD HH:mm'
				}}
				label={
					customProps.noLabel
						? null
						: i18n.t('translation:taskManager.planner.fields.start')
				}
				allowOldDate={true}
				onChange={customProps.onChange}
				component={dateInput}
			/>
		),
		endDateTime: (customProps = {}) => (
			<Field
				name={'endDateTime_' + customProps.id}
				customProps={{
					...customProps,
					timeInput: true,
					dateInput: true,
					onlyTimeInput: false,
					dateTimeFormat: 'YYYY-MM-DD HH:mm'
				}}
				label={
					customProps.noLabel
						? null
						: i18n.t('translation:taskManager.planner.fields.end')
				}
				allowOldDate={true}
				onChange={customProps.onChange}
				component={dateInput}
			/>
		),
		breakDuration: (customProps = {}) => (
			<Field
				name={'hours'}
				customProps={{
					...customProps,
					placeholder: i18n.t(
						'translation:taskManager.inventory.fields.placeholders.hours'
					)
				}}
				label={i18n.t('translation:taskManager.planner.fields.breakDuration')}
				normalize={formUtils.normalize.hour24}
				component={hours}
			/>
		)
	},
	billingModule: {
		settings: {
			initialDocumentNumberCountry: (customProps = {}) => (
				<Field
					name="country"
					customProps={customProps}
					onChange={customProps.onChange}
					component={selectInput}
					validate={customProps.validate}
				/>
			),
			initialDocumentNumber: (customProps = {}) => (
				<Field
					name="initialDocumentNumber"
					type="text"
					customProps={customProps}
					onChange={customProps.onChange}
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			taxType: (customProps = {}) => (
				<Field
					name="taxType"
					customProps={customProps}
					onChange={customProps.onChange}
					component={selectInput}
					validate={customProps.validate}
				/>
			),
			taxGroupName: (customProps = {}) => (
				<Field
					name="taxGroupName"
					customProps={customProps}
					onChange={customProps.onChange}
					label={i18n.t('translation:billingModule.settings.common.name')}
					type="text"
					component={textInput}
				/>
			),
			taxGroupRate: (customProps = {}) => (
				<Field
					name="taxGroupRate"
					customProps={customProps}
					onChange={customProps.onChange}
					label={i18n.t('translation:billingModule.settings.common.rate')}
					type="number"
					component={textInput}
				/>
			),
			overdueIntervalFromDay: (customProps = {}) => (
				<Field
					name="overdueIntervalFromDay"
					customProps={customProps}
					onChange={customProps.onChange}
					label={i18n.t(
						'translation:billingModule.settings.common.overdueIntervalFromDay'
					)}
					type="number"
					component={textInput}
				/>
			),
			overdueIntervalToDay: (customProps = {}) => (
				<Field
					name="overdueIntervalToDay"
					customProps={customProps}
					onChange={customProps.onChange}
					label={i18n.t('translation:billingModule.settings.common.overdueIntervalToDay')}
					type="number"
					component={textInput}
				/>
			)
		},
		invoicing: {
			invoiceProductName: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_NAME_}${index}`}
					customProps={customProps}
					label={i18n.t(
						'translation:billingModule.invoicing.table.freeInvoice.productName'
					)}
					type="text"
					component={textInput}
					validate={customProps.validate ?? undefined}
					value={customProps.value}
				/>
			),
			invoiceProductUnit: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_UNIT_}${index}`}
					customProps={customProps}
					label={customProps.label}
					onChange={customProps.onChange}
					component={selectInput}
					validate={customProps.validate ?? undefined}
				/>
			),
			invoiceProductQuantity: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_QUANTITY_}${index}`}
					customProps={customProps}
					label={customProps.label}
					type="text"
					normalize={formUtils.normalize.productQuantity}
					onChange={customProps.onChange}
					component={textInput}
					validate={customProps.validate ?? undefined}
				/>
			),
			invoiceProductPrice: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_UNIT_PRICE_NO_TAX_}${index}`}
					customProps={customProps}
					type="number"
					onChange={customProps.onChange}
					normalize={formUtils.normalize.price}
					component={invoicingTableInput}
					validate={customProps.validate ?? undefined}
					localizedCurrencyAbbreviation={customProps.localizedCurrencyAbbreviation}
					currencySymbol={customProps.currencySymbol}
				/>
			),
			invoiceProductAmount: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_AMOUNT_NO_TAX_}${index}`}
					customProps={customProps}
					type="text"
					currency={customProps.currency}
					component={tableInput}
					validate={customProps.validate ?? undefined}
				/>
			),
			invoiceProductTaxGroups: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_TAX_PERCENT_TAX_NAME_}${index}`}
					customProps={customProps}
					onChange={customProps.onChange}
					label={customProps.label}
					type="text"
					component={selectInput}
					validate={customProps.validate ?? undefined}
					value={customProps.value}
				/>
			),
			invoiceProductTotalPrice: (index, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_TOTAL_PRICE_WITH_TAX_}${index}`}
					customProps={customProps}
					type="text"
					currency={customProps.currency}
					component={tableInput}
					onChange={customProps.onChange}
					validate={customProps.validate ?? undefined}
				/>
			),
			invoiceProductDiscount: (customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND}`}
					customProps={customProps}
					type="number"
					onChange={customProps.onChange}
					normalize={formUtils.normalize.price}
					component={invoicingTableInput}
					validate={customProps.validate}
					localizedCurrencyAbbreviation={customProps.localizedCurrencyAbbreviation}
					currencySymbol={customProps.currencySymbol}
				/>
			),
			invoiceProductDiscountNote: (customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NOTE}`}
					customProps={customProps}
					type="text"
					component={tableInput}
				/>
			),
			invoiceShowInInvoice: (customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.SHOW_IN_INVOICE}`}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.table.common.showInInvoice')}
					type="text"
					component={tableCheckbox}
				/>
			),
			invoiceProductAdvance: (id, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_ADVANCE_NO_TAX_}${id}`}
					customProps={customProps}
					type="text"
					component={tableInput}
					currency={customProps.currency}
				/>
			),
			invoiceProductCreditDebit: (id, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${id}`}
					customProps={customProps}
					type="text"
					component={invoicingTableInput}
					localizedCurrencyAbbreviation={customProps.localizedCurrencyAbbreviation}
					currencySymbol={customProps.currencySymbol}
					normalize={formUtils.normalize.price}
					validate={customProps.validate ?? undefined}
					onChange={(e) => customProps.onChange(e.target.value)}
				/>
			),
			invoicingMethod: (orderId, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ORDER_INVOICING_METHOD_}${orderId}`}
					customProps={customProps}
					type="text"
					component={selectInput}
					onChange={customProps.onChange}
					validate={customProps.validate}
				/>
			),
			percentInvoicing: (orderId, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ORDER_PERCENT_FOR_INVOICING_ROUND_}${orderId}`}
					customProps={customProps}
					type="text"
					component={invoicingTableInput}
					normalize={formUtils.normalize.percentOrEmpty}
					localizedCurrencyAbbreviation={'%'}
					onChange={customProps.onChange}
					validate={customProps.validate}
				/>
			),
			fixedAmountInvoicing: (orderId, customProps = {}) => (
				<Field
					name={`${INVOICE_FIELD_NAME.ORDER_FIXED_AMOUNT_FOR_INVOICING_ROUND_}${orderId}`}
					customProps={customProps}
					type="text"
					component={invoicingTableInput}
					normalize={formUtils.normalize.price}
					localizedCurrencyAbbreviation={customProps.localizedCurrencyAbbreviation}
					currencySymbol={customProps.currencySymbol}
					onChange={customProps.onChange}
					validate={customProps.validate}
				/>
			),
			invoiceNumber: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.INVOICE_NUMBER}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.number')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			invoiceLocalizationField: (customProps = {}) => (
				<Field
					key={customProps.name}
					name={customProps.name}
					customProps={customProps}
					component={tableInput}
					validate={customProps.validate}
				/>
			),
			dateOfIssue: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.DATE_OF_ISSUE}
					customProps={{
						...customProps,
						dateInput: true,
						dateFormat: 'dd.MM.yyyy'
					}}
					onChange={customProps.onChange}
					component={customDateInput}
					validate={customProps.validate}
				/>
			),
			composedBy: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.COMPOSED_BY}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.composedBy')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			dateOfTaxEvent: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.DATE_OF_TAX_EVENT}
					customProps={{
						...customProps,
						dateInput: true,
						dateFormat: 'dd.MM.yyyy'
					}}
					onChange={customProps.onChange}
					component={customDateInput}
				/>
			),
			recipientCompanyName: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.common.companyName')}
					type="text"
					onChange={customProps.onChange}
					component={leftLabelledSelectInput}
					validate={customProps.validate}
				/>
			),
			creditInvoiceReason: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.CREDIT_REASON}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.creditReason')}
					type="select"
					component={leftLabelledSelectInput}
					onChange={customProps.onChange}
					validate={customProps.validate}
				/>
			),
			supplierCompanyName: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.SUPPLIER_COMPANY_NAME}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.common.companyName')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			documentReason: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.DOCUMENT_REASON}
					customProps={customProps}
					type="text"
					component={leftLabelledTextInput}
					validate={formUtils.validateRequiredField}
					label={i18n.t(
						'translation:billingModule.invoicing.table.creditNote.invoiceReason'
					)}
				/>
			),
			uic: (name, customProps = {}) => (
				<Field
					name={name}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.common.uic')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			vatNumber: (name, customProps = {}) => (
				<Field
					name={name}
					customProps={customProps}
					label={customProps.label}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			registrationAddress: (name, customProps = {}) => (
				<Field
					name={name}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.common.registrationAddress')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			responsiblePerson: (name, customProps = {}) => (
				<Field
					name={name}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.common.responsiblePerson')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			verbally: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.VERBALLY}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.verbally')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			notes: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.NOTES}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.notes')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			dueDate: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.DUE_DATE}
					customProps={{
						...customProps,
						dateInput: true,
						dateFormat: 'dd.MM.yyyy'
					}}
					onChange={customProps.onChange}
					component={customDateInput}
					validate={customProps.validate}
					description={customProps.description}
				/>
			),
			excludeTax: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.EXCLUDE_TAX}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.excludeTax')}
					type={'checkbox'}
					component={controlledCheckbox}
					onChange={customProps.onChange}
				/>
			),
			reasonForTaxExclude: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.REASON_FOR_TAX_EXCLUDE}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.reasonForTaxExclude')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			iban: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.IBAN}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.iban')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			bic: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.BIC}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.bic')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			),
			bankName: (customProps = {}) => (
				<Field
					name={INVOICE_FIELD_NAME.BANK_NAME}
					customProps={customProps}
					label={i18n.t('translation:billingModule.invoicing.bankName')}
					type="text"
					component={leftLabelledTextInput}
					validate={customProps.validate}
				/>
			)
		}
	}
};
