import React from 'react';
import Badge from './Badge';
import i18n from '../../i18n';

class AbsenceBadges extends React.Component {
	render() {
		const absences = [...this.props.absences];

		if (this.props.workedDaysAbsence) {
			const workedDaysAbsence = {
				name: i18n.t('translation:taskManager.planner.daysWorked'),
				abbreviation: i18n.t('translation:taskManager.planner.daysWorkedAbbreviation'),
				backgroundColor: '13253b'
			};
			absences.unshift(workedDaysAbsence);
		}

		const getName = (absence) =>
			absence.abbreviation ? absence.name + ` (${absence.abbreviation})` : absence.name;

		return absences.map((absence, index) => {
			return (
				<Badge
					key={'badge' + index}
					backgroundColor={absence.backgroundColor}
					name={getName(absence)}
				/>
			);
		});
	}
}

export default AbsenceBadges;
