import React from 'react';
import {
	getItemAdvance,
	getItemAmountNoTax,
	getItemCatalogNumber,
	getItemName,
	getItemQuantity,
	getItemTaxName,
	getItemTotalPriceWithTax,
	getItemUnit,
	getItemUnitPriceNoTax
} from '../../../../../../../utils/formUtils';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { parseInvoicingCurrency } from '../../../../../../../utils/tableUtils';
import OrderValueModified from '../../sections/common/OrderValueModified';

class OrderItemRow extends React.Component {
	renderCell(value, currency, localizedCurrencyAbbreviation, currencySymbol) {
		return (
			<div className={'data-row'}>
				<span className={'d-inline'}>
					{currency
						? parseInvoicingCurrency(
								value,
								localizedCurrencyAbbreviation,
								currencySymbol
						  )
						: value}
				</span>
			</div>
		);
	}

	render() {
		const orderItemId = this.props.orderItemId;
		const formValues = this.props.formValues;
		const localizedCurrencyAbbreviation = this.props.localizedCurrencyAbbreviation;
		const currencySymbol = this.props.currencySymbol;
		const orderPriceModified = this.props.orderPriceModified;

		return (
			<tr key={orderItemId + 'orderItem'} className={'col-12 p-0'}>
				<td colSpan={1}>
					{this.renderCell(getItemCatalogNumber(formValues, orderItemId))}
				</td>
				<td colSpan={3}>{this.renderCell(getItemName(formValues, orderItemId))}</td>
				<td colSpan={1}>{this.renderCell(getItemUnit(formValues, orderItemId))}</td>
				<td colSpan={1}>{this.renderCell(getItemQuantity(formValues, orderItemId))}</td>
				{
					<OrderValueModified
						value={parseInvoicingCurrency(
							getItemUnitPriceNoTax(formValues, orderItemId),
							this.props.localizedCurrencyAbbreviation,
							this.props.currencySymbol
						)}
						modificationType={orderPriceModified}
						colSpan={2}
					/>
				}
				{
					<OrderValueModified
						value={parseInvoicingCurrency(
							getItemAmountNoTax(formValues, orderItemId),
							this.props.localizedCurrencyAbbreviation,
							this.props.currencySymbol
						)}
						modificationType={orderPriceModified}
						colSpan={2}
					/>
				}
				<td colSpan={2}>
					{this.renderCell(
						getItemAdvance(formValues, orderItemId),
						true,
						localizedCurrencyAbbreviation,
						currencySymbol
					)}
				</td>
				<td colSpan={1}>{this.renderCell(getItemTaxName(formValues, orderItemId))}</td>
				<td style={noBreakWordStyle} colSpan={2}>
					{this.renderCell(
						getItemTotalPriceWithTax(formValues, orderItemId),
						true,
						localizedCurrencyAbbreviation,
						currencySymbol
					)}
				</td>
				<td colSpan={1}></td>
			</tr>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state)
	};
};

export default connect(mapStateToProps, null)(OrderItemRow);

const noBreakWordStyle = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
};
