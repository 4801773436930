import React from 'react';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import { getSelectedTaxType } from '../../../../../../../utils/appUtils';
import {
	parseInvoicingCurrency,
	preparePercentForDisplay
} from '../../../../../../../utils/tableUtils';
import {
	changeDocumentDiscountNote,
	changeDocumentDiscountNoTaxNoRound,
	changeDocumentDiscountNoTaxRound,
	changeDocumentDiscountTaxFormat,
	changeDocumentDiscountTaxNotFormat,
	changeDocumentDiscountWithTax,
	getDocumentDiscountNoTaxNoRound,
	getDocumentDiscountNoTaxRound,
	getDocumentDiscountNote,
	getDocumentDiscountTaxNotFormat,
	getDocumentDiscountWithTax,
	getDocumentTotalNoTax,
	changeDocumentDiscountShowInInvoice,
	getDocumentTaxExclude
} from '../../../../../../../utils/formUtils';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import { add, divide, multiply } from '../../utils/documentUtils';

class DiscountSection extends React.Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		//todo -> check to move in free invoice

		if (
			prevProps.currentDocument !== this.props.currentDocument &&
			this.props.currentDocument
		) {
			this.fillInitialData();
			return;
		}

		if (
			getDocumentDiscountNoTaxRound(prevProps.formValues) !==
			getDocumentDiscountNoTaxRound(this.props.formValues)
		) {
			this.onDiscountChange(getDocumentDiscountNoTaxRound(this.props.formValues));
		}

		if (
			getDocumentDiscountTaxNotFormat(prevProps.formValues) !==
			getDocumentDiscountTaxNotFormat(this.props.formValues)
		) {
			this.onDiscountTaxChange(getDocumentDiscountTaxNotFormat(this.props.formValues));
		}

		const taxExcluded = getDocumentTaxExclude(this.props.formValues);

		if (prevProps.formValues && getDocumentTaxExclude(prevProps.formValues) !== taxExcluded) {
			this.onTaxExclude(
				taxExcluded,
				this.props.documentType,
				this.props.formValues,
				this.props.mode
			);
		}
	}

	onTaxExclude(taxExcluded, documentType, formValues, mode) {
		if (mode === BILLING_DOCUMENT_MODE.PREVIEW) {
			return;
		}
		const { CREDIT_NOTE, DEBIT_NOTE } = BILLING_DOCUMENT_TYPES;

		if (documentType === CREDIT_NOTE || documentType === DEBIT_NOTE || !taxExcluded) {
			return;
		}

		this.changeDiscountOnTaxExclude(formValues);
	}

	changeDiscountOnTaxExclude(formValues) {
		const discountWithoutTax =
			formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND] ?? 0;

		if (formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_NO_TAX_ROUND]) {
			changeDocumentDiscountNoTaxRound(this.props.change, discountWithoutTax);
			changeDocumentDiscountWithTax(this.props.change, discountWithoutTax);
		}

		changeDocumentDiscountTaxFormat(this.props.change, 0);
		changeDocumentDiscountTaxNotFormat(this.props.change, 0);
		this.props.change(INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED, 0);
	}

	fillInitialData() {
		const discountAmount = this.props.currentDocument.discountAmount;
		const discountTax = this.props.currentDocument.discountTax;
		const discountDescription = this.props.currentDocument.discountDescription;
		const discountShowInDocument = this.props.currentDocument.discountVisibleByReceiver;

		changeDocumentDiscountTaxNotFormat(this.props.change, divide(discountTax, 100));

		changeDocumentDiscountTaxFormat(this.props.change, Number(discountTax ?? 0).toFixed(2));
		changeDocumentDiscountNoTaxRound(this.props.change, Number(discountAmount ?? 0).toFixed(2));
		changeDocumentDiscountNoTaxNoRound(this.props.change, discountAmount);
		changeDocumentDiscountNote(this.props.change, discountDescription);
		changeDocumentDiscountShowInInvoice(this.props.change, discountShowInDocument);
	}

	render() {
		const readableInput = isReadableInput(this.props.mode);

		return (
			<div className="col-12 p-0 mt-5">
				{this.renderHeader()}
				<div className={'section w-100 mt-0 pt-3 discount-section'}>
					<div className={'row pl-3 flex-nowrap'}>
						<div
							className={'d-flex p-0 pr-3 align-items-center'}
							style={{ width: '30%' }}>
							<label className={'pr-2'}>
								{i18n.t(
									'translation:billingModule.invoicing.table.common.discount'
								)}
							</label>
							{Fields.billingModule.invoicing.invoiceProductDiscount({
								localizedCurrencyAbbreviation:
									this.props.localizedCurrencyAbbreviation,
								currencySymbol: this.props.currencySymbol,
								readableInput
							})}
						</div>
						<div className="d-flex w-100 p-0" style={{ width: '70%' }}>
							<div className={'col-6 d-flex align-items-center'}>
								<label className={'pr-2'}>
									{i18n.t(
										'translation:billingModule.invoicing.table.common.discountTax'
									)}
								</label>
								<span>
									{`${preparePercentForDisplay(
										this.props.formValues[
											INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_ROUNDED
										]
									)}%`}
								</span>
							</div>
							<div className="d-flex col-6 p-0 justify-content-end align-items-center pr-3">
								{Fields.billingModule.invoicing.invoiceShowInInvoice({
									checkedValue:
										this.props.formValues[
											`${INVOICE_FIELD_NAME.SHOW_IN_INVOICE}`
										],
									right: true,
									readableInput
								})}
							</div>
						</div>
					</div>
					<div className={'row mt-3'}>
						<div className="col-12 d-flex pl-3 align-items-center">
							<label className="pr-3">
								{i18n.t(
									'translation:billingModule.invoicing.table.common.discountWithTax',
									{
										taxType: getSelectedTaxType(
											this.props.billingSettings.taxType
										)
									}
								)}
							</label>
							<div className={'data-row'}>
								<span className={'d-inline'}>
									{parseInvoicingCurrency(
										getDocumentDiscountWithTax(this.props.formValues),
										this.props.localizedCurrencyAbbreviation,
										this.props.currencySymbol
									)}
								</span>
							</div>
						</div>
					</div>
					{this.renderDiscountNote(readableInput)}
				</div>
			</div>
		);
	}

	renderDiscountNote = (readableInput) => {
		if (
			this.props.mode !== BILLING_DOCUMENT_MODE.PREVIEW ||
			getDocumentDiscountNote(this.props.formValues)
		) {
			return (
				<div className={'row pl-3 mt-3 flex-nowrap align-items-center'}>
					<label className="pr-3">
						{i18n.t('translation:billingModule.invoicing.table.common.noteDiscount')}
					</label>
					<div className={'flex-fill pr-4'}>
						{Fields.billingModule.invoicing.invoiceProductDiscountNote({
							horizontalLabel: true,
							maxLength: 250,
							readableInput
						})}
					</div>
				</div>
			);
		}

		return null;
	};

	renderHeader = () => (
		<div className={'discount-header w-100'}>
			{i18n.t('translation:billingModule.invoicing.discountTableHeader')}
		</div>
	);

	getModifiedDiscountIfNeeded(discount) {
		let modifiedDiscount = discount;

		const documentTotalNoTax = getDocumentTotalNoTax(this.props.formValues);

		const documentTotalNoTaxFormatted = documentTotalNoTax
			? Number(documentTotalNoTax.toFixed(2))
			: 0;

		if (documentTotalNoTaxFormatted === Number(discount)) {
			modifiedDiscount = documentTotalNoTax;
		}

		return Number(modifiedDiscount);
	}

	onDiscountChange = (discount) => {
		const modifiedDiscount = this.getModifiedDiscountIfNeeded(discount);

		changeDocumentDiscountNoTaxNoRound(this.props.change, modifiedDiscount);

		let currentDiscountTax =
			this.props.formValues &&
			this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_DISCOUNT_TAX_NOT_FORMATTED];

		//in case of undefined or null
		if (!currentDiscountTax) {
			currentDiscountTax = 0;
		}

		this.onDiscountWithTaxChange(modifiedDiscount, currentDiscountTax);
	};

	onDiscountTaxChange = (tax) => {
		if (!this.props.formValues) {
			return;
		}

		const documentDiscountNoTaxNoRound = getDocumentDiscountNoTaxNoRound(this.props.formValues);

		if (!documentDiscountNoTaxNoRound) {
			return;
		}

		const updateDiscountWithTaxChange = add(
			documentDiscountNoTaxNoRound,
			multiply(documentDiscountNoTaxNoRound, tax)
		);

		changeDocumentDiscountWithTax(this.props.change, updateDiscountWithTaxChange);
	};

	onDiscountWithTaxChange = (discount, currentDiscountTax) => {
		if (!discount) {
			changeDocumentDiscountWithTax(this.props.change, '0.00');
		} else {
			const updateDiscountWithTaxChange = add(
				discount,
				multiply(discount, currentDiscountTax)
			);

			changeDocumentDiscountWithTax(this.props.change, updateDiscountWithTaxChange);
		}
	};
}

const mapStateToProps = (state) => {
	return {
		taxGroups: state.billingSettings.taxGroups,
		billingSettings: state.billingSettings,
		formValues: getFormValues('freeInvoiceForm')(state),
		currentDocument: state.billingDocument.currentDocument
	};
};

export default connect(mapStateToProps, { change })(DiscountSection);
