import {
	CLEAR_CURRENT_ORDER,
	CLEAR_REDUCER,
	GET_ALL_SUPPORTED_FILE_EXTENSIONS,
	GET_COLORS,
	GET_COMMENTS,
	GET_ORDER,
	GET_ORDERS_WITH_PAGINATION,
	GET_SUPPORTED_PHOTO_EXTENSIONS,
	GET_SUPPORTED_VIDEO_EXTENSIONS,
	UPDATE_HISTORY,
	UPDATE_ORDER,
	UPDATE_ORDER_FIELD
} from '../actions/types';

const initState = {
	currentOrder: {},
	orders: [],
	colors: {},
	photoFileExtensions: [],
	videoFileExtensions: [],
	allFileExtensions: [],
	fileExtensionsMaxSizes: new Map()
};

const ordersReducer = (state = { ...initState }, action) => {
	if (action.type === GET_ORDER) {
		state.currentOrder = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_ORDERS_WITH_PAGINATION) {
		state.orders = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_SUPPORTED_PHOTO_EXTENSIONS) {
		state.photoFileExtensions = action.payload;
		return { ...state };
	}

	if (action.type === GET_SUPPORTED_VIDEO_EXTENSIONS) {
		state.videoFileExtensions = action.payload;
		return { ...state };
	}

	if (action.type === GET_ALL_SUPPORTED_FILE_EXTENSIONS) {
		state.allFileExtensions = action.payload;
		return { ...state };
	}

	if (action.type === UPDATE_ORDER_FIELD) {
		const filedIndex = state.currentOrder.fields.findIndex((field) => {
			return field.type === action.payload.type;
		});
		state.currentOrder.fields[filedIndex] = action.payload;
		state.currentOrder.fields = { ...state.currentOrder.fields };
		state.currentOrder = { ...state.currentOrder };
		return { ...state };
	}

	if (action.type === UPDATE_ORDER) {
		state.currentOrder = { ...state.currentOrder, ...action.payload };
		return { ...state };
	}

	if (action.type === GET_COLORS) {
		state.colors = { ...action.payload };
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	if (action.type === CLEAR_CURRENT_ORDER) {
		state.currentOrder = {};
		return { ...state };
	}

	if (action.type === GET_COMMENTS) {
		state.currentOrder.comments = [...action.payload];
		state.currentOrder = { ...state.currentOrder };
		return { ...state };
	}

	if (action.type === UPDATE_HISTORY) {
		state.currentOrder.changeHistory = action.payload.changeHistory;
	}

	return state;
};

export default ordersReducer;
