import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fields } from '../../../../common/Fields';
import i18n from '../../../../../i18n';
import Modal from 'react-modal';
import { getFormValues, reduxForm } from 'redux-form';
import DefaultButton from '../../../../common/DefaultButton';
import { connect } from 'react-redux';
import moment from '../../../../../utils/moment';
import { editHolidays, exportStaff, removeHolidays, setHolidays } from '../../../../../actions';
import { formUtils } from '../../../../../utils/formUtils';

class ExportModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isModalOpen !== this.props.isModalOpen && this.props.isModalOpen) {
			this.props.initialize(this.props.initialValues);
		}
	}

	render() {
		const exportButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.planner.buttons.export')}
				type="submit"
				key={i18n.t('translation:taskManager.planner.buttons.export')}
				onClick={this.props.handleSubmit(this.onSubmit)}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.onCloseModal();
				}}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.onCloseModal();
					}}
				/>
				<h2>{i18n.t('translation:taskManager.planner.export')}</h2>
				<div className="row">
					<div className="col-12 d-flex mt-s">
						{Fields.commonFields.year({
							className: 'w-50',
							options: this.getYearOptions()
						})}
						{Fields.commonFields.month({
							className: 'w-50 ml-s',
							options: this.getMonthOptions()
						})}
					</div>
				</div>
				<div className="buttons">
					{exportButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	getMonthOptions = () => {
		const months = [];
		Array.from(Array(12).keys()).forEach((month, index) => {
			months.push({ name: index + 1, value: index + 1 });
		});
		return months;
	};

	getYearOptions = () => {
		const date = moment();
		const years = [];

		for (let i = 1; i <= 50; i++) {
			const year = date.format('YYYY');
			years.push({ name: year, values: year });
			date.subtract(1, 'Y');
		}
		return years;
	};

	onCloseModal = () => {
		this.props.destroy();
		this.props.closeModal();
	};

	onSubmit = (formValues) => {
		this.props.exportStaff(this.props.account.labId, formValues, this.onCloseModal);
	};
}

const exportForm = reduxForm({
	form: 'markDaysForm',
	validate: formUtils.validateExportStaff
})(ExportModal);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('exportForm')(state)
	};
};

export default connect(mapStateToProps, {
	setHolidays,
	editHolidays,
	removeHolidays,
	exportStaff
})(exportForm);
