import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	isBillingModuleEnabled,
	isEmptyObject,
	isLabAdmin,
	isLabTechnician,
	isTaskManagerEnabled
} from '../../utils/appUtils';
import {
	faBriefcase,
	faCalendar,
	faCalendarWeek,
	faClock,
	faDollarSign,
	faEdit,
	faFileInvoiceDollar,
	faFlask,
	faFolderOpen,
	faHome,
	faHospitalAlt,
	faRetweet,
	faSwatchbook,
	faTasks,
	faUmbrellaBeach,
	faUserCheck,
	faUsers,
	faUsersCog,
	faWrench,
	faHandHoldingUsd,
	faBalanceScale
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackModal from '../common/FeedbackModal';
import navigationUtils from '../../utils/navigationUtils';
import SideMenuDropdown from './SideMenuDropdown/SideMenuDropdown';
import { FEEDBACK_SIDE_MENU_ITEM, PAGES_PATHS, USER_ROLE } from '../../utils/constants';
import { history } from '../../index';
import { clearPagination, clearSort, getLabModules } from '../../actions';
import { destroy } from 'redux-form';
import i18n from '../../i18n';

class SideMenu extends Component {
	state = {
		page: navigationUtils.getRoute(),
		isFeedbackModalOpen: false
	};

	componentDidMount() {
		this.navigationListener = history.listen(({ pathname }) => {
			this.navigationHandler(pathname);
		});

		if (isLabAdmin(this.props.account) || isLabTechnician(this.props.account)) {
			this.props.getLabModules(this.props.account.labId);
		}
	}

	navigationHandler = (pathname) => {
		if (isEmptyObject(this.props.account) && this.state.page !== PAGES_PATHS.HOME) {
			this.setState({ page: PAGES_PATHS.HOME });
		} else {
			if (this.state.page !== pathname) {
				this.setState({ page: pathname });
			}
		}
	};

	componentWillUnmount() {
		this.navigationListener();
	}

	render() {
		return (
			<div className="side-bar-container">
				<FeedbackModal
					isOpen={this.state.isFeedbackModalOpen}
					closeModal={this.hideFeedbackModal}
				/>
				{this.renderButtons()}
			</div>
		);
	}

	hideFeedbackModal = () => {
		this.setState({ isFeedbackModalOpen: false });
	};

	getGlobalAdminButtons() {
		return [
			{
				name: i18n.t('translation:sideMenu.dashboard'),
				icon: faHome,
				path: PAGES_PATHS.HOME
			},
			{
				name: i18n.t('translation:sideMenu.clinics'),
				icon: faHospitalAlt,
				path: PAGES_PATHS.PRACTICES
			},
			{
				name: i18n.t('translation:sideMenu.laboratories'),
				icon: faFlask,
				path: PAGES_PATHS.LABORATORIES
			},
			{
				name: i18n.t('translation:sideMenu.users'),
				icon: faUsers,
				path: PAGES_PATHS.USERS
			},
			{
				name: i18n.t('translation:sideMenu.orders'),
				icon: faTasks,
				path: PAGES_PATHS.ORDERS
			},
			{
				name: i18n.t('translation:sideMenu.inventory'),
				icon: faSwatchbook,
				path: PAGES_PATHS.INVENTORY
			},
			{
				name: i18n.t('translation:sideMenu.admins'),
				icon: faUsersCog,
				path: PAGES_PATHS.ADMINISTRATORS
			}
		];
	}

	getLabLocalAdminButtons() {
		const localAdminPaths = [
			{
				name: i18n.t('translation:sideMenu.orders'),
				icon: faTasks,
				path: PAGES_PATHS.ORDERS
			},
			{
				name: i18n.t('translation:sideMenu.clients'),
				icon: faUsers,
				path: PAGES_PATHS.CLIENTS
			},
			{
				name: i18n.t('translation:sideMenu.inventory'),
				icon: faSwatchbook,
				path: PAGES_PATHS.INVENTORY
			}
		];

		if (isTaskManagerEnabled(this.props.modules)) {
			const plannerPaths = [
				{
					name: i18n.t('translation:taskManager.sideMenu.planner'),
					icon: faBriefcase,
					path: PAGES_PATHS.PLANNER,
					submenuItems: [
						{
							name: i18n.t('translation:taskManager.sideMenu.workSchedule'),
							icon: faUserCheck,
							path: PAGES_PATHS.PLANNER_WORK_SCHEDULE,
							submenu: true
						},
						{
							name: i18n.t('translation:taskManager.sideMenu.workingHours'),
							icon: faClock,
							path: PAGES_PATHS.PLANNER_WORKING_HOURS,
							submenu: true
						},
						{
							name: i18n.t('translation:taskManager.sideMenu.holidays'),
							icon: faUmbrellaBeach,
							path: PAGES_PATHS.PLANNER_HOLIDAYS,
							submenu: true
						},
						{
							name: i18n.t('translation:taskManager.sideMenu.employees'),
							icon: faUsers,
							path: PAGES_PATHS.PLANNER_STAFF,
							submenu: true
						}
					]
				}
			];
			const calendarOrders = [
				{
					name: i18n.t('translation:taskManager.sideMenu.calendar'),
					icon: faCalendarWeek,
					path: PAGES_PATHS.CALENDAR,
					submenuItems: [
						{
							name: i18n.t('translation:taskManager.sideMenu.orders'),
							icon: faTasks,
							path: PAGES_PATHS.CALENDAR_ORDERS,
							submenu: true
						},
						{
							name: i18n.t('translation:taskManager.sideMenu.staff'),
							icon: faUsers,
							path: PAGES_PATHS.CALENDAR_STAFF,
							submenu: true
						}
					]
				}
			];

			plannerPaths.forEach((item) => {
				localAdminPaths.push(item);
			});
			calendarOrders.forEach((item) => {
				localAdminPaths.push(item);
			});

			localAdminPaths.unshift({
				name: i18n.t('translation:sideMenu.myLaboratory'),
				icon: faHome,
				path: PAGES_PATHS.MY_LABORATORY
			});
			localAdminPaths.unshift({
				name: i18n.t('translation:sideMenu.dashboard'),
				icon: faCalendar,
				path: PAGES_PATHS.HOME
			});
		} else {
			localAdminPaths.unshift({
				name: i18n.t('translation:sideMenu.myLaboratory'),
				icon: faHome,
				path: PAGES_PATHS.HOME
			});
		}

		let localAdminSubmenuItems = [];

		const billingSettingsSubmenu = {
			name: i18n.t('translation:sideMenu.settings'),
			icon: faWrench,
			path: PAGES_PATHS.BILLING_SETTINGS
		};

		const documentsSubmenu = {
			name: i18n.t('translation:sideMenu.documents'),
			icon: faFolderOpen,
			path: PAGES_PATHS.BILLING_DOCUMENTS
		};

		if (isBillingModuleEnabled(this.props.modules)) {
			localAdminSubmenuItems.push(
				{
					name: i18n.t('translation:sideMenu.invoicing'),
					icon: faFileInvoiceDollar,
					path: PAGES_PATHS.BILLING_INVOICING
				},
				{
					name: i18n.t('translation:sideMenu.takings'),
					icon: faHandHoldingUsd,
					path: PAGES_PATHS.BILLING_DEBTOR_DAYS
				},
				documentsSubmenu,
				{
					name: i18n.t('translation:sideMenu.incomesOutcomes'),
					icon: faRetweet,
					path: PAGES_PATHS.BILLING_INCOMES_OUTCOMES
				},
				{
					name: i18n.t('translation:sideMenu.balance'),
					icon: faBalanceScale,
					path: PAGES_PATHS.BILLING_STATEMENT
				},
				billingSettingsSubmenu
			);
		} else {
			localAdminSubmenuItems.push(documentsSubmenu, billingSettingsSubmenu);
		}

		localAdminPaths.push({
			name: i18n.t('translation:sideMenu.finance'),
			icon: faDollarSign,
			path: PAGES_PATHS.BILLING_FINANCE,
			submenuItems: localAdminSubmenuItems
		});

		return localAdminPaths;
	}

	getLabUserButtons() {
		const userPaths = [
			{
				name: i18n.t('translation:sideMenu.orders'),
				icon: faTasks,
				path: PAGES_PATHS.ORDERS
			},
			{
				name: i18n.t('translation:sideMenu.inventory'),
				icon: faSwatchbook,
				path: PAGES_PATHS.INVENTORY
			}
		];

		if (isTaskManagerEnabled(this.props.modules)) {
			userPaths.unshift(
				{
					name: i18n.t('translation:sideMenu.dashboard'),
					icon: faCalendar,
					path: PAGES_PATHS.HOME
				},
				{
					name: i18n.t('translation:sideMenu.myLaboratory'),
					icon: faHome,
					path: PAGES_PATHS.MY_LABORATORY
				}
			);
			userPaths.push({
				name: i18n.t('translation:taskManager.sideMenu.planner'),
				icon: faBriefcase,
				path: PAGES_PATHS.PLANNER,
				submenuItems: [
					{
						name: i18n.t('translation:taskManager.sideMenu.workSchedule'),
						icon: faUserCheck,
						path: PAGES_PATHS.PLANNER_WORK_SCHEDULE,
						submenu: true,
						hidden: !isTaskManagerEnabled(this.props.modules)
					}
				],
				hidden: !isTaskManagerEnabled(this.props.modules)
			});
		} else {
			userPaths.unshift({
				name: i18n.t('translation:sideMenu.myLaboratory'),
				icon: faHome,
				path: PAGES_PATHS.HOME
			});
		}

		return userPaths;
	}

	getClinicBillingButton() {
		return {
			name: i18n.t('translation:sideMenu.finance'),
			icon: faDollarSign,
			path: PAGES_PATHS.BILLING_FINANCE,
			submenuItems: [
				{
					name: i18n.t('translation:sideMenu.documents'),
					icon: faFolderOpen,
					path: PAGES_PATHS.BILLING_DOCUMENTS
				}
			]
		};
	}

	getClinicLocalAdminButtons() {
		const buttons = this.getCommonClinicButtons();
		buttons.push(this.getClinicBillingButton());

		return buttons;
	}

	getClinicUserButtons() {
		return this.getCommonClinicButtons();
	}

	getCommonClinicButtons() {
		return [
			{
				name: i18n.t('translation:sideMenu.myClinic'),
				icon: faHome,
				path: PAGES_PATHS.HOME
			},
			{
				name: i18n.t('translation:sideMenu.orders'),
				icon: faTasks,
				path: PAGES_PATHS.ORDERS
			},
			{
				name: i18n.t('translation:sideMenu.suppliers'),
				icon: faUsers,
				path: PAGES_PATHS.SUPPLIERS
			}
		];
	}

	renderButtons = () => {
		if (isEmptyObject(this.props.account)) {
			return;
		}

		const userRole = this.getUserRole();

		const buttons = {
			[USER_ROLE.GLOBAL_ADMIN]: this.getGlobalAdminButtons(),
			LAB_LOCAL_ADMIN: this.getLabLocalAdminButtons(),
			LAB_USER: this.getLabUserButtons(),
			CLINIC_LOCAL_ADMIN: this.getClinicLocalAdminButtons(),
			CLINIC_USER: this.getClinicUserButtons()
		};

		const buttonsToRender = buttons[userRole];
		buttonsToRender.push(this.feedbackMenuItem());

		return buttonsToRender.map((button) => {
			return this.renderButton(button);
		});
	};

	getUserRole() {
		const userRole = this.props.account.role;
		const clinicId = this.props.account.clinicId;

		if (userRole === USER_ROLE.GLOBAL_ADMIN) {
			return userRole;
		}

		// if the User is part of a Clinic the User Role will be CLINIC_LOCAL_ADMIN or CLINIC_USER
		// if the User is not part of a Clinic the User Role will be LAB_LOCAL_ADMIN or LAB_USER
		return clinicId ? `CLINIC_${userRole}` : `LAB_${userRole}`;
	}

	feedbackMenuItem = () => {
		return {
			name: i18n.t('translation:sideMenu.feedback'),
			icon: faEdit,
			path: FEEDBACK_SIDE_MENU_ITEM,
			action: () => {
				this.setState({ isFeedbackModalOpen: true });
			}
		};
	};

	renderButton = (button) => {
		const buttonStyle = navigationUtils.isMenuItemActive(button.path);

		if (!button.hidden && button.submenuItems) {
			return (
				<SideMenuDropdown
					key={button.name}
					icon={button.icon}
					name={button.name}
					path={button.path}
					menuItems={button.submenuItems}
					onClick={this.onClick}
				/>
			);
		}

		return (
			<div
				key={button.path}
				onClick={() => {
					if (button.path === FEEDBACK_SIDE_MENU_ITEM) {
						button.action();
					} else {
						this.onClick(button.path);
					}
				}}
				className={`side-bar-menu ${buttonStyle}`}>
				<div className="d-flex">
					<FontAwesomeIcon icon={button.icon} className="side-bar-icon" />
					<span>{button.name}</span>
				</div>
			</div>
		);
	};

	onClick = (page) => {
		if (page !== this.state.page) {
			this.props.clearPagination(undefined);
			this.props.clearSort();
			this.props.destroy('filtersForm');
			this.props.destroy('calendarOrdersFiltersForm');
			this.setState({ page });
			navigationUtils.navigate(page);
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		modules: state.modules,
		settings: state.settings,
		feedbackModal: state.portal.feedbackModal
	};
};

export default connect(mapStateToProps, {
	clearPagination,
	clearSort,
	getLabModules,
	destroy
})(SideMenu);
