import React from 'react';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import {
	cancelTransaction,
	createTransaction,
	getBillingSettings,
	getLabDetails,
	getTransaction,
	hideMessageModal,
	setCurrentCreditDocuments,
	showMessageModal
} from '../../../../../actions';
import {
	OUTCOME_DOCUMENT_TYPE,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_STATUS,
	TRANSACTION_TYPE
} from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import OutcomesDetailsFilters from './OutcomeDetailsFilters';
import { getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OutcomeDetailsTable from './OutcomeDetailsTable';
import { isEmptyObject, showSuccessPopup } from '../../../../../utils/appUtils';
import { renderTransactionStatus } from '../../utils';

class OutcomeDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
		this.props.getLabDetails(this.props.account.labId);
		this.props.getBillingSettings(this.props.account.labId);
		if (!this.isCreation()) {
			const outcomeId = this.props.outcomeId;
			const transaction = await getTransaction(outcomeId);

			if (
				transaction.status === TRANSACTION_STATUS.COMPLETED ||
				transaction.status === TRANSACTION_STATUS.CANCELED
			) {
				this.setState({ ...transaction, reminder: 0 });
				return;
			}
		}
	}

	componentWillUnmount() {
		this.setState({});
		this.props.setCurrentCreditDocuments([]);
	}

	isCreation() {
		return this.props.isCreation;
	}

	renderSaveButton() {
		if (
			!this.isCreation() &&
			(!this.state.status ||
				this.state.status === TRANSACTION_STATUS.COMPLETED ||
				this.state.status === TRANSACTION_STATUS.CANCELED)
		) {
			return null;
		}
		return (
			<div className={'ml-3'}>
				<DefaultButton
					title={i18n.t('translation:orders.buttons.save')}
					onClick={(e) => this.onSaveButtonClick(e)}
				/>
			</div>
		);
	}

	renderCancelButton() {
		if (
			this.isCreation() ||
			!this.state.status ||
			this.state.status === TRANSACTION_STATUS.CANCELED
		) {
			return null;
		}
		return (
			<DefaultButton
				title={i18n.t(
					'translation:billingModule.incomeOutcomeDetails.incomeDetails.buttons.cancelOutcome'
				)}
				danger
				onClick={(e) => this.onCancelButtonClick(e)}
			/>
		);
	}

	onCancelButtonClick(e) {
		e.preventDefault();
		const outcomeId = this.state.id;
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.yes')}
				onClick={() => {
					cancelTransaction(outcomeId)
						.then(() => {
							this.createCreditTransactionCallback(
								i18n.t(
									'translation:billingModule.incomeOutcomeDetails.successMessages.cancelOutcome'
								)
							);
							this.props.hideMessageModal();
						})
						.catch((e) => this.props.hideMessageModal());
				}}
			/>
		);
		const noButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.no')}
				onClick={() => {
					this.props.hideMessageModal();
				}}
				secondary
			/>
		);
		this.props.showMessageModal(
			i18n.t('translation:common.warning'),
			i18n.t('translation:billingModule.incomeOutcomeDetails.outcomeDetails.cancelOutcome'),
			[yesButton, noButton],
			false,
			true
		);
	}

	onSaveButtonClick(e) {
		const {
			TRANSACTION_REFERENCES_NOTES,
			TRANSACTION_AMOUNT,
			TRANSACTION_DATE,
			TRANSACTION_METHOD,
			TRANSACTION_CLINIC_ID,
			DOCUMENTS_IDS,
			DOCUMENT_AMOUNT_,
			TRANSACTION_DOCUMENT_TYPE,
			TRANSACTION_COMMON_NOTES
		} = TRANSACTION_FORM_FIELD;

		if (this.props.invalid) {
			const errors = this.props.errors;

			if (errors[DOCUMENTS_IDS]) {
				this.props.showMessageModal(
					i18n.t('translation:common.errors.error'),
					i18n.t(
						'translation:billingModule.incomeOutcomeDetails.errors.documentAmountCreditTransaction'
					),
					[],
					false,
					true
				);
			}

			return;
		}

		//it is important this to stay here!
		//otherwise will submit the form twice if valid
		e.preventDefault();

		const formValues = this.props.formValues;

		const transactionMethod = formValues[TRANSACTION_METHOD];
		const transactionDate = moment(formValues[TRANSACTION_DATE]).format('YYYY-MM-DD');
		const amount = formValues[TRANSACTION_AMOUNT];
		const referenceNotes = formValues[TRANSACTION_REFERENCES_NOTES];
		const commonNotes = formValues[TRANSACTION_COMMON_NOTES];

		const clinicId = formValues[TRANSACTION_CLINIC_ID];
		const labId = this.props.account.labId;

		const billingDocuments = [];
		const linkedTransactions = [];
		const documentsIds = formValues[DOCUMENTS_IDS];

		const isItForIncomes =
			formValues[TRANSACTION_DOCUMENT_TYPE] === OUTCOME_DOCUMENT_TYPE.DEBIT_NOTE;

		if (isItForIncomes) {
			for (let id of documentsIds) {
				const amount = formValues[`${DOCUMENT_AMOUNT_}${id}`];
				if (amount) {
					linkedTransactions.push({
						amount: formValues[`${DOCUMENT_AMOUNT_}${id}`],
						debitTransactionId: id
					});
				}
			}
		} else {
			for (let id of documentsIds) {
				const amount = formValues[`${DOCUMENT_AMOUNT_}${id}`];
				if (amount) {
					billingDocuments.push({
						amount: formValues[`${DOCUMENT_AMOUNT_}${id}`],
						billingDocumentId: id
					});
				}
			}
		}

		const createTransactionRequest = {
			transactionDate,
			transactionMethod,
			amount,
			clinicId,
			referenceNotes,
			labId,
			commonNotes,
			type: TRANSACTION_TYPE.CREDIT
		};

		if (isItForIncomes) {
			createTransactionRequest.linkedTransactions = linkedTransactions;
		} else {
			createTransactionRequest.billingDocuments = billingDocuments;
		}

		createTransaction(createTransactionRequest).then(() => {
			this.props.onDocumentChange && this.props.onDocumentChange();
			this.createCreditTransactionCallback(
				i18n.t(
					'translation:billingModule.incomeOutcomeDetails.successMessages.createOutcome'
				)
			);
		});
	}

	createCreditTransactionCallback(message) {
		showSuccessPopup(message);
		this.props.onClose();
	}

	render() {
		return (
			<form
				onSubmit={this.props.handleSubmit(() => this.onSaveButtonClick())}
				className={'pr-4'}>
				<div className={'d-flex flex-row'}>
					<div className={'d-flex flex-row flex-fill justify-content-start'}>
						{this.state.status && renderTransactionStatus(this.state.status)}
					</div>
					<div
						className={
							'd-flex flex-row flex-fill justify-content-end align-items-center'
						}>
						{this.renderCancelButton()}
						{this.renderSaveButton()}
					</div>
				</div>
				<OutcomesDetailsFilters
					isCreation={this.isCreation()}
					clinicId={this.state.clinicId}
					referencesNotes={this.state.referenceNotes}
					transactionDate={this.state.transactionDate}
					transactionMethod={this.state.transactionMethod}
					amount={this.state.amount}
					billingDocuments={this.state.billingDocuments}
					commonNotes={this.state.commonNotes}
				/>
				<OutcomeDetailsTable
					isCreation={this.isCreation()}
					billingDocuments={this.state.billingDocuments}
					linkedTransactions={this.state.linkedTransactions}
					status={this.state.status}
				/>
			</form>
		);
	}
}

const validateOutcomeDetails = (formValues) => {
	const {
		TRANSACTION_AMOUNT,
		TRANSACTION_METHOD,
		TRANSACTION_DATE,
		TRANSACTION_REMINDER,
		TRANSACTION_CLINIC_ID,
		TRANSACTION_DOCUMENT_TYPE
	} = TRANSACTION_FORM_FIELD;

	const amount = formValues[TRANSACTION_AMOUNT];
	const reminder = formValues[TRANSACTION_REMINDER];

	const formFields = [
		TRANSACTION_AMOUNT,
		TRANSACTION_METHOD,
		TRANSACTION_DATE,
		TRANSACTION_CLINIC_ID,
		TRANSACTION_DOCUMENT_TYPE
	];

	let errors = {};

	for (let key of formFields) {
		if (!formValues[key]) {
			errors[key] = i18n.t('translation:common.errors.requiredField');
		}
	}
	if (amount && Number(reminder) < 0) {
		errors[TRANSACTION_REMINDER] = i18n.t(
			'translation:billingModule.incomeOutcomeDetails.errors.amountDistribute'
		);
	}

	if (Number(reminder) > 0) {
		errors[TRANSACTION_REMINDER] = i18n.t(
			'translation:billingModule.incomeOutcomeDetails.errors.distributeWholeAmount'
		);
	}

	if (Number(amount) === 0) {
		errors[TRANSACTION_AMOUNT] = i18n.t(
			'translation:billingModule.incomeOutcomeDetails.errors.amountGreaterThanZero'
		);
	}

	errors = validateOutcomeDocumentAmount(errors, formValues);

	return errors;
};

export const validateOutcomeDocumentAmount = (errors, formValues) => {
	const { DOCUMENTS_IDS, DOCUMENT_REMAINING_AMOUNT_, DOCUMENT_AMOUNT_, DOCUMENT_PAYED_AMOUNT_ } =
		TRANSACTION_FORM_FIELD;
	const documentsIds = formValues[DOCUMENTS_IDS];

	const valid = isEmptyObject(errors);

	if (documentsIds && documentsIds.length) {
		for (let i = 0; i < documentsIds.length; i++) {
			const id = documentsIds[i];
			const documentRemainingAmount = formValues[`${DOCUMENT_REMAINING_AMOUNT_}${id}`];
			const documentRemainingAmountRound = Number(documentRemainingAmount).toFixed(2);
			const documentAmount = formValues[`${DOCUMENT_AMOUNT_}${id}`];
			if (Number(documentRemainingAmountRound) < Number(documentAmount)) {
				//empty to not show error inside a table
				errors[`${DOCUMENT_AMOUNT_}${id}`] = ' ';
				//to show messageModal on submit and only if other field are valid
				if (valid) {
					errors[DOCUMENTS_IDS] = 'error';
				}
			}
		}
	}

	return errors;
};

const outcomesDetailsForm = reduxForm({
	form: 'outcomesDetailsForm',
	initialValues: {},
	validate: validateOutcomeDetails
})(OutcomeDetails);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		formValues: getFormValues('outcomesDetailsForm')(state),
		currentDocuments: state.billingTransactions.currentCreditDocuments,
		errors: getFormSyncErrors('outcomesDetailsForm')(state)
	};
};

export default connect(mapStateToProps, {
	getLabDetails,
	showMessageModal,
	setCurrentCreditDocuments,
	hideMessageModal,
	getBillingSettings
})(outcomesDetailsForm);
