import {
	CREATE_OVERDUE_INTERVAL,
	CREATE_TAX_GROUP,
	DELETE_OVERDUE_INTERVAL,
	DELETE_TAX_GROUP,
	GET_BILLING_SETTINGS,
	GET_LAB_TAX_GROUPS,
	UPDATE_BILLING_SETTINGS_INIT_DOC_NUMBER,
	UPDATE_BILLING_SETTINGS_TAX_TYPE,
	UPDATE_OVERDUE_INTERVAL,
	UPDATE_TAX_GROUP
} from '../../actions/types';
import _ from 'lodash';

const initState = {
	id: 0,
	country: '',
	initialDocumentNumber: '',
	taxType: '',
	taxGroups: [],
	overdueIntervals: [],
	editable: true
};

const billingModuleSettingsReducer = (state = { ...initState }, action) => {
	if (action.type === GET_BILLING_SETTINGS) {
		state = action.payload;
		return { ...state };
	}

	if (action.type === UPDATE_BILLING_SETTINGS_INIT_DOC_NUMBER) {
		const { country, initialDocumentNumber } = action.payload;
		state.country = country;
		state.initialDocumentNumber = initialDocumentNumber;
		return { ...state };
	}

	if (action.type === UPDATE_BILLING_SETTINGS_TAX_TYPE) {
		const { taxType } = action.payload;
		state.taxType = taxType;
		return { ...state };
	}

	if (action.type === GET_LAB_TAX_GROUPS) {
		state.taxGroups = action.payload;
		return { ...state };
	}

	if (action.type === CREATE_TAX_GROUP) {
		const groups = [...state.taxGroups];
		groups.push(action.payload);
		state.taxGroups = groups;
		return { ...state };
	}

	if (action.type === UPDATE_TAX_GROUP) {
		const groups = [...state.taxGroups];
		let index = _.findIndex(groups, {
			id: action.payload.id
		});
		groups.splice(index, 1, action.payload);
		state.taxGroups = groups;
		return { ...state };
	}

	if (action.type === DELETE_TAX_GROUP) {
		const groups = [...state.taxGroups];
		_.remove(groups, function (taxGroup) {
			return +taxGroup.id === +action.payload;
		});
		state.taxGroups = groups;
		return { ...state };
	}

	if (action.type === CREATE_OVERDUE_INTERVAL) {
		const overdueIntervals = [...state.overdueIntervals];
		overdueIntervals.push(action.payload);
		state.overdueIntervals = overdueIntervals;
		return { ...state };
	}

	if (action.type === UPDATE_OVERDUE_INTERVAL) {
		const overdueIntervals = [...state.overdueIntervals];
		let index = _.findIndex(overdueIntervals, {
			id: action.payload.id
		});
		overdueIntervals.splice(index, 1, action.payload);
		state.overdueIntervals = overdueIntervals;
		return { ...state };
	}

	if (action.type === DELETE_OVERDUE_INTERVAL) {
		const overdueIntervals = [...state.overdueIntervals];
		_.remove(overdueIntervals, function (overdueInterval) {
			return +overdueInterval.id === +action.payload;
		});
		state.overdueIntervals = overdueIntervals;
		return { ...state };
	}

	return state;
};

export default billingModuleSettingsReducer;
