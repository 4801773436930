import React, { Fragment } from 'react';
import i18n from '../../../../i18n';
import { Fields } from '../../../common/Fields';
import { reduxForm } from 'redux-form';
import { formUtils } from '../../../../utils/formUtils';

class AddTemplateForm extends React.Component {
	render() {
		if (this.props.isExcludedFromPlanning) {
			return <Fragment />;
		}

		return (
			<Fragment>
				<label className="blue-label mt-s">
					{i18n.t('translation:taskManager.orders.newTemplates')}
				</label>
				<form
					onSubmit={this.props.handleSubmit(this.props.onSubmit)}
					className="add-template-form">
					<div className="add-template-container">
						{Fields.commonFields.name({ className: 'w-100 mt-xs' })}
						{Fields.templateFields.isDefault({ className: 'mt-xs' })}
					</div>
					<br />
				</form>
			</Fragment>
		);
	}
}

const addTemplateForm = reduxForm({
	form: 'addTemplateForm',
	validate: formUtils.validateLocalInventoryAddPhase
})(AddTemplateForm);

export default addTemplateForm;
