import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	prepareFloatForDisplay,
	prepareIntForDisplay,
	preparePriceForDisplay
} from '../../../utils/tableUtils';
import { DISPLAY_TYPES } from '../../../utils/constants';

class StatisticsItem extends React.Component {
	render() {
		let { description, icon, onClick = () => {}, className } = this.props;
		className = className + ' mt-s statistic';
		return (
			<div className={className} onClick={onClick}>
				<div className="icon">
					<FontAwesomeIcon icon={icon} />
				</div>
				<div className="text text-right">
					<p className="m-0 title">{this.getValueToDisplay()}</p>
					<p className="m-0 description">{description}</p>
				</div>
			</div>
		);
	}

	getValueToDisplay = () => {
		const { value, displayType, lab } = this.props;
		switch (displayType) {
			case DISPLAY_TYPES.INTEGER:
				return prepareIntForDisplay(value);
			case DISPLAY_TYPES.FLOAT:
				return prepareFloatForDisplay(value);
			case DISPLAY_TYPES.PRICE:
				return preparePriceForDisplay(value, lab);
			default:
				return value;
		}
	};
}

export default StatisticsItem;
