import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import i18n from '../../../i18n';
import { formUtils } from '../../../utils/formUtils';
import { sendLinkInvitation } from '../../../actions';
import { EMPTY_STRING } from '../../../utils/constants';

class SendLinkInvitationModal extends React.Component {
	state = {
		errorExists: false,
		errorMessage: EMPTY_STRING
	};

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			// FIX: fixing a bug when the validation doesn't start/doesn't work if you open the component
			// twice in a row and directly press the submit button the second time. Achieved by using
			// Redux Forms' initialize with empty 'fields' property in the component lifecycle
			// method componentWillReceiveProps()
			this.props.initialize({ fields: '' });
		}
	}

	render() {
		const sendButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.send')}
				type="submit"
				key={i18n.t('translation:common.buttons.send')}
				onClick={this.props.handleSubmit(this.onFormSubmit)}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModalResettingState();
				}}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll send-invitation-modal"
				overlayClassName="custom-overlay">
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={() => {
							this.closeModalResettingState();
						}}
					/>
					{this.renderTitle()}

					{Fields.commonFields.organizationEmail({
						label: this.getEmailLabel(),
						className: 'input-container full-width',
						onChange: () => {
							this.setState({ errorMessage: '', errorExists: false });
						}
					})}

					{this.renderError()}
					<div className="buttons modal-dialog-buttons-below-error-message">
						{sendButton}
						{cancelButton}
					</div>
				</form>
			</Modal>
		);
	}

	renderTitle = () => (
		<h2>
			{this.props.isInvitationToClinic
				? i18n.t('translation:linkInvitationModal.clinicInvitationTitle')
				: i18n.t('translation:linkInvitationModal.labInvitationTitle')}
		</h2>
	);

	getEmailLabel = () =>
		this.props.isInvitationToClinic
			? i18n.t('translation:linkInvitationModal.emailLabelWhenInvitingClinic')
			: i18n.t('translation:linkInvitationModal.emailLabelWhenInvitingLab');

	renderError = () => {
		if (this.state.errorExists) {
			return (
				<div className="align-items-start text-danger position-absolute modal-dialog-error-message">
					{this.state.errorMessage}
				</div>
			);
		}
	};

	onFormSubmit = (formValues) => {
		this.hideErrorMessage();

		this.props.sendLinkInvitation(
			this.props.organizationId,
			this.props.isInvitationToClinic,
			formValues.organizationEmail,
			this.displayErrorMessage,
			this.closeModalResettingState,
			this.props.showUserInvitationModal
		);
	};

	closeModalResettingState = () => {
		this.resetState();

		this.props.closeModal();
		this.props.destroy();
	};

	resetState = () => {
		this.setState(this.getInitialValues());
		this.hideErrorMessage();
	};

	displayErrorMessage = (errorMessage) => {
		this.setState({
			errorExists: true,
			errorMessage
		});
	};

	hideErrorMessage = () => {
		this.setState({
			errorExists: false,
			errorMessage: EMPTY_STRING
		});
	};

	getInitialValues() {
		return {
			organizationEmail: EMPTY_STRING
		};
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};

const sendLinkInvitationForm = reduxForm({
	form: 'sendLinkInvitationForm',
	validate: formUtils.validateSendLinkInvitationModal
	// enableReinitialize: true,
	// destroyOnUnmount: true
})(SendLinkInvitationModal);

export default connect(mapStateToProps, { sendLinkInvitation })(sendLinkInvitationForm);
