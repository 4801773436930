import React from 'react';
import Modal from 'react-modal';
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import { formUtils } from '../../../utils/formUtils';
import { EMPTY_STRING, REDUX_FORM_NAMES, SINGLE_SPACE } from '../../../utils/constants';
import { isEmptyObject } from '../../../utils/appUtils';

class GlobalProductDetails extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const initialValues = nextProps.initialValues
				? nextProps.initialValues
				: {
						category: EMPTY_STRING
				  };
			this.props.initialize(initialValues);
		}
	}

	render() {
		const categoryOptions = formUtils.options.getGlobalProductCategory();

		return (
			<div>
				{Fields.commonFields.globalProductCategory({
					options: categoryOptions,
					className: 'global-product-modal-content'
				})}
				{Fields.commonFields.noColor({ className: 'mt-s p-0' })}
			</div>
		);
	}
}

const validateAndRaiseException = (formValues, dispatch, props) => {
	let errors = validateGlobalProductDetails(formValues);

	if (!isEmptyObject(errors)) {
		throw new SubmissionError({
			...errors,
			_error: 'error'
		});
	}
};

export const validateGlobalProductDetails = (formValues, dispatch, props) => {
	let errors = {};

	// NOTE: Manually checking if the field is empty, because for some weird reason
	// the redux-form validation (the commonValidation method) always returns
	// 'field empty' validation error, even when the field has value
	if (isEmptyObject(formValues.category)) {
		errors['category'] = SINGLE_SPACE;
	}

	return errors;
};

const componentReduxForm = reduxForm({
	form: REDUX_FORM_NAMES.GLOBAL_PRODUCT_DETAILS,
	onSubmit: validateAndRaiseException
})(GlobalProductDetails);

export default connect(null, {})(componentReduxForm);
