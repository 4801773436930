import React, { Fragment } from 'react';
import Header from '../common/Header';
import i18n from '../../i18n';
import { connect } from 'react-redux';
import { parseAdmins } from '../../utils/tableUtils';
import Table from '../common/Table';
import { clearReducers, getGlobalAdminUsers } from '../../actions';

class Admins extends React.Component {
	componentDidMount() {
		this.props.getGlobalAdminUsers();
	}

	render() {
		return (
			<Fragment>
				<Header title={i18n.t('translation:admins.admins')} />
				<div className="col-12 p-0">
					<hr />
					{this.renderUsers()}
				</div>
			</Fragment>
		);
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	renderUsers = () => {
		const admins = parseAdmins(this.props.admins.content);

		return (
			<Table
				columns={[
					i18n.t('translation:common.table.name'),
					i18n.t('translation:common.table.phone'),
					i18n.t('translation:common.table.email'),
					i18n.t('translation:common.table.status')
				]}
				data={admins}
			/>
		);
	};
}

const mapStateToProps = (state) => {
	return { admins: state.users.users };
};

export default connect(mapStateToProps, { getGlobalAdminUsers, clearReducers })(Admins);
