import React, { Component } from 'react';
import navigationUtils from '../../../utils/navigationUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SideMenuDropdownItem extends Component {
	render() {
		return (
			<li
				className={`d-flex align-items-center ${this.getActiveClass(this.props.item.path)}`}
				onClick={() => this.props.onClick(this.props.item.path)}>
				<FontAwesomeIcon icon={this.props.item.icon} className="side-bar-submenu-icon" />
				<span className="side-bar-menu-dropdown-item ml-1">{this.props.item.name}</span>
			</li>
		);
	}

	getActiveClass = (path) => {
		return navigationUtils.isMenuItemActive(path);
	};
}

export default SideMenuDropdownItem;
