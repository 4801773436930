import React, { Fragment } from 'react';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import {
	dateInput,
	MultipleSelectInput,
	textInput,
	leftLabelledSelectInput,
	customDateInput
} from '../../../../common/Fields';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import {
	isEmptyObject,
	parseInvoiceTypeFilterType,
	parseOrderInvoicingStatusFilterType
} from '../../../../../utils/appUtils';
import {
	INVOICE_TYPES,
	INVOICING_FILTER_INPUT_NAME,
	ORDER_INVOICING_STATUS,
	PAGINATION_TYPES
} from '../../../../../utils/constants';
import {
	setPagination,
	clearPagination,
	getLinkedClinicsWithoutPagination
} from '../../../../../actions';
import SubHeader from '../../../../common/SubHeader';

class CreateInvoiceModalOrdersFilters extends React.Component {
	state = {
		clientId: undefined,
		invoiceType: undefined,
		isFilterCollapsed: false
	};

	componentDidMount() {
		if (!this.props.clinics || !this.props.clinics.length) {
			this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);
		}
		this.setInitialValues();
	}

	render() {
		return (
			<div className="col-12 p-0 data-section">
				{this.renderClientNameAndInvoiceTypeInput()}
				{this.renderSelectClientAndInvoiceTypeMessage()}
				{this.renderFilterInputs()}
			</div>
		);
	}

	renderClientNameAndInvoiceTypeInput = () => {
		return (
			<div className="d-flex section">
				<div className="d-flex col-4">{this.renderClientNameInput()}</div>
				<div className="d-flex col-4">{this.renderInvoiceTypeInput()}</div>
			</div>
		);
	};

	renderSelectClientAndInvoiceTypeMessage = () => {
		if (!this.state.clientId || !this.state.invoiceType) {
			return (
				<p className="text-center mt-4">
					{i18n.t('translation:billingModule.common.selectClientAndInvoiceType')}
				</p>
			);
		}

		return null;
	};

	renderFilterInputs = () => {
		if (this.state.clientId && this.state.invoiceType === INVOICE_TYPES.LINKED_TO_ORDERS) {
			return (
				<Fragment>
					<SubHeader title={i18n.t('translation:billingModule.common.addOrders')} />
					{this.renderOrdersFilterInputs()}
				</Fragment>
			);
		}

		return null;
	};

	renderOrdersFilterInputs = () => {
		const orderInvoicingStatuses = [];

		for (let type in ORDER_INVOICING_STATUS) {
			if (type !== ORDER_INVOICING_STATUS.FULLY_INVOICED) {
				orderInvoicingStatuses.push({
					name: parseOrderInvoicingStatusFilterType(type),
					id: type
				});
			}
		}

		return (
			<div className="content section filters pt-0">
				<form
					onSubmit={this.props.handleSubmit((formValues) => {
						this.props.clearPagination(
							PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_ORDERS
						);
						this.props.setFilters(formValues);
						this.props.setPagination(PAGINATION_TYPES.BILLING_DOCUMENTS, 1);
					})}>
					<div className="d-flex flex-row pt-4">
						<div className="col-3">
							<div className="col-12 pl-0">{this.renderOrderNumberInput()}</div>
						</div>
						<div className="col-3">
							<div className="col-12 pl-0">
								{this.renderOrderInvoicingStatusInput(orderInvoicingStatuses)}
							</div>
						</div>
						<div className="col-3">
							<div className="col-12 px-0">{this.renderPatientNameInput()}</div>
						</div>
						<div className="d-flex flex-row justify-content-center align-items-end col offset-xl-1">
							{this.renderFilterButton()}
						</div>
					</div>
					{this.renderDateRangeInputs()}
				</form>
			</div>
		);
	};

	renderDateRangeInputs = () => {
		if (this.state.isFilterCollapsed) {
			return (
				<div className="d-flex flex-wrap pt-4">
					<div className="col-4">{this.renderCreateDateRangeInputs()}</div>
					<div className="col-4">{this.renderDeliveryDateRangeInputs()}</div>
					<div className="col-4">{this.renderPaymentDueDateRangeInputs()}</div>
				</div>
			);
		}

		return null;
	};

	renderClientNameInput() {
		let options = [{ name: '', value: '' }];

		this.props.clinics?.forEach((clinic) =>
			options.push({ name: clinic.legalName, value: clinic.id })
		);

		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.CLIENT_ID}
				label={i18n.t('translation:orders.fields.filters.client')}
				customProps={{
					className: 'd-flex align-items-center w-100 mb-0',
					options: options
				}}
				onChange={(event) => {
					this.onClientChange(event.target);
				}}
				component={leftLabelledSelectInput}
			/>
		);
	}

	renderInvoiceTypeInput() {
		let invoiceTypes = [{ name: '', value: '' }];

		for (let type in INVOICE_TYPES) {
			invoiceTypes.push({ name: parseInvoiceTypeFilterType(type), value: type });
		}

		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.INVOICE_TYPE}
				label={i18n.t('translation:billingModule.invoicing.invoiceType')}
				customProps={{
					className: 'd-flex align-items-center w-100 mb-0',
					options: invoiceTypes
				}}
				onChange={(event) => {
					this.props.onSelectInvoiceType(INVOICE_TYPES[`${event.target.value}`]);
					this.setState({ invoiceType: INVOICE_TYPES[`${event.target.value}`] });
				}}
				component={leftLabelledSelectInput}
			/>
		);
	}

	renderOrderNumberInput() {
		return (
			<Field
				type="number"
				name={INVOICING_FILTER_INPUT_NAME.LAB_ORDER_ID}
				label={i18n.t('translation:billingModule.common.number')}
				customProps={{ className: 'mr-0' }}
				//normalize={formUtils.normalize.number}
				component={textInput}
			/>
		);
	}

	renderOrderInvoicingStatusInput(orderInvoicingStatuses) {
		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.STATUSES}
				label={i18n.t('translation:billingModule.common.orderInvoicingStatus')}
				customProps={{
					className: 'mr-0',
					options: orderInvoicingStatuses
				}}
				component={MultipleSelectInput}
			/>
		);
	}

	renderPatientNameInput() {
		return (
			<Field
				name={INVOICING_FILTER_INPUT_NAME.PATIENT_NAME}
				label={i18n.t('translation:billingModule.common.patient')}
				customProps={{ className: 'mr-0' }}
				component={textInput}
			/>
		);
	}

	renderPaymentDueDateRangeInputs() {
		return (
			<Fragment>
				<label>{i18n.t('translation:billingModule.common.paymentDueDateToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						allowOldDate={true}
						component={dateInput}
					/>
				)}
			</Fragment>
		);
	}

	renderCreateDateRangeInputs() {
		return (
			<Fragment>
				<label>{i18n.t('translation:billingModule.common.createToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={INVOICING_FILTER_INPUT_NAME.CREATE_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						allowOldDate={true}
						component={dateInput}
					/>
				)}
			</Fragment>
		);
	}

	renderDeliveryDateRangeInputs() {
		return (
			<Fragment>
				<label>{i18n.t('translation:billingModule.common.deliveryToFrom')}</label>
				{this.renderIntervalFields(
					<Field
						name={INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.from')
						}}
						component={customDateInput}
					/>,
					<Field
						name={INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_TO}
						customProps={{
							className: 'mr-s d-block',
							placeholder: i18n.t('translation:orders.fields.placeholders.to')
						}}
						allowOldDate={true}
						component={dateInput}
					/>
				)}
			</Fragment>
		);
	}

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};

	renderFilterButton = () => {
		return (
			<div className="filter-button">
				<DefaultButton
					title={i18n.t('translation:billingModule.common.filter')}
					onClick={this.onFilterButtonClick}
				/>
				{this.renderTrigger()}
			</div>
		);
	};

	renderTrigger = () => {
		if (this.state.isFilterCollapsed) {
			return (
				<span
					className="arrow-up arrow-up-header ml-xs"
					onClick={this.collapseExpandFilter}
				/>
			);
		}

		return (
			<span
				className="arrow-down arrow-down-header ml-xs"
				onClick={this.collapseExpandFilter}
			/>
		);
	};

	onClientChange = (target) => {
		let filters = this.props.filters;

		/*if (!isEmptyObject(filters)) {
			for (const filterName in filters) {
				if (
					filterName === INVOICING_FILTER_INPUT_NAME.LAB_ORDER_ID ||
					filterName === INVOICING_FILTER_INPUT_NAME.STATUSES ||
					filterName === INVOICING_FILTER_INPUT_NAME.PATIENT_NAME ||
					filterName === INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM ||
					filterName === INVOICING_FILTER_INPUT_NAME.CREATE_DATE_TO ||
					filterName === INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM ||
					filterName === INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_TO ||
					filterName === INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM ||
					filterName === INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_TO
				) {
					filters[filterName] = '';
					this.props.formValues[filterName] = '';
				}
			}
		}*/

		// only clear other filters if not initial select of client
		/*if (this.state.clientId) {
			const formValues = this.props.formValues;

			if (formValues && !isEmptyObject(formValues)) {
				for (const valueKey in formValues) {
					if (valueKey !== INVOICING_FILTER_INPUT_NAME.INVOICE_TYPE) {
						formValues[valueKey] = '';
						this.props.formValues[valueKey] = '';
					}
				}
			}
		}*/

		const { options, value } = target;
		const clientName = options[options.selectedIndex].text;

		this.props.onSelectClient(value, clientName);
		this.setState({ clientId: value });
	};

	onFilterButtonClick = () => {
		this.props.clearPagination(PAGINATION_TYPES.PRE_CREATE_MODAL_BILLING_ORDERS);
	};

	setInitialValues = () => {
		const filters = this.props.filters;

		if (!isEmptyObject(filters)) {
			let updatedFilters = {};

			for (const filterName in filters) {
				if (filterName === INVOICING_FILTER_INPUT_NAME.CLIENT_ID) {
					this.props.change(INVOICING_FILTER_INPUT_NAME.CLIENT_ID, filters[filterName]);
					this.setState({ clientId: filters[filterName] });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.CLIENT_NAME) {
					this.props.change(INVOICING_FILTER_INPUT_NAME.CLIENT_NAME, filters[filterName]);

					updatedFilters.clientName = filters[filterName];

					const clinic = this.props.clinics.find(
						(clinic) => clinic.legalName === filters[filterName]
					);

					this.props.change(INVOICING_FILTER_INPUT_NAME.CLIENT_ID, clinic.id);
					this.props.onSelectClient(clinic.id);

					this.setState({ clientId: filters[filterName] });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.LAB_ORDER_ID) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.LAB_ORDER_ID,
						filters[filterName]
					);

					updatedFilters.labOrderId = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.STATUSES) {
					this.props.change(INVOICING_FILTER_INPUT_NAME.STATUSES, filters[filterName]);

					updatedFilters.statuses = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.PATIENT_NAME) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.PATIENT_NAME,
						filters[filterName]
					);

					updatedFilters.patientName = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM,
						filters[filterName]
					);

					updatedFilters.createDateFrom = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.CREATE_DATE_TO) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.CREATE_DATE_TO,
						filters[filterName]
					);

					updatedFilters.createDateTo = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM,
						filters[filterName]
					);

					updatedFilters.deliveryDateFrom = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_TO) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_TO,
						filters[filterName]
					);

					updatedFilters.deliveryDateTo = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM,
						filters[filterName]
					);

					updatedFilters.deliveryDateFrom = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM,
						filters[filterName]
					);

					updatedFilters.paymentDueDateFrom = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}

				if (filterName === INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_TO) {
					this.props.change(
						INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_TO,
						filters[filterName]
					);

					updatedFilters.paymentDueDateTo = filters[filterName];
					this.setState({ isFilterCollapsed: true });
				}
			}

			this.props.setFilters(updatedFilters);
		}
	};

	collapseExpandFilter = () => {
		this.setState({ isFilterCollapsed: !this.state.isFilterCollapsed });
	};
}

const createInvoiceSetDetailsFiltersForm = reduxForm({
	form: 'createInvoiceSetDetailsFiltersForm',
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: validateFilters
})(CreateInvoiceModalOrdersFilters);

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		account: state.account,
		currentLab: state.labs.currentLab,
		clinics: state.clinics.clinics,
		orders: state.billingDocument.orders,
		formValues: getFormValues('createInvoiceSetDetailsFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	clearPagination,
	getLinkedClinicsWithoutPagination,
	change
})(createInvoiceSetDetailsFiltersForm);

function validateFilters(formValues) {
	let errors = {};

	if (
		formValues.paymentDueDateFrom &&
		formValues.paymentDueDateTo &&
		formValues.paymentDueDateFrom.getTime() > formValues.paymentDueDateTo.getTime()
	) {
		errors[INVOICING_FILTER_INPUT_NAME.PAYMENT_DUE_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	if (
		formValues.createDateFrom &&
		formValues.createDateTo &&
		formValues.createDateFrom.getTime() > formValues.createDateTo.getTime()
	) {
		errors[INVOICING_FILTER_INPUT_NAME.CREATE_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	if (
		formValues.deliveryDateFrom &&
		formValues.deliveryDateTo &&
		formValues.deliveryDateFrom.getTime() > formValues.deliveryDateTo.getTime()
	) {
		errors[INVOICING_FILTER_INPUT_NAME.DELIVERY_DATE_FROM] = i18n.t(
			'translation:billingModule.errors.fromToError'
		);
	}

	return errors;
}
