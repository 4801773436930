import React, { Fragment } from 'react';
import {
	getListWithTooltip,
	parseInvoicingCurrency,
	renderSimpleTable
} from '../../../../utils/tableUtils';
import {
	STATEMENTS_CONNECTED_ITEM_TYPES,
	PAGES_PATHS,
	TRANSACTION_TYPE
} from '../../../../utils/constants';
import i18n from '../../../../i18n';
import { Link } from 'react-router-dom';
import moment from '../../../../utils/moment';
import { getLocalizedConnectedTo } from '../utils';
import IncomeOutcomeModal, {
	IncomeOutcomeModalContentType
} from '../incomesOutcomes/IncomeOutcomeModal';
import BillingDocumentModal, {
	BILLING_DOCUMENT_MODE
} from '../invoicing/billingDocumentModal/BillingDocumentModal';

class StatementTable extends React.Component {
	state = {
		localizedDocumentType: {
			INVOICE: i18n.t('translation:billingModule.incomesOutcomes.invoice'),
			ORDER_INVOICE: i18n.t('translation:billingModule.incomesOutcomes.invoice'),
			FREE_INVOICE: i18n.t('translation:billingModule.incomesOutcomes.invoice'),
			CREDIT_NOTE: i18n.t('translation:billingModule.incomesOutcomes.creditNote'),
			DEBIT_TRANSACTION: i18n.t('translation:billingModule.incomesOutcomes.income'),
			CREDIT_TRANSACTION: i18n.t('translation:billingModule.incomesOutcomes.outcome'),
			DEBIT_NOTE: i18n.t('translation:billingModule.incomesOutcomes.debitNote')
		},
		transactionModal: {
			isModalOpen: false,
			modalContentType: null,
			modalContentNumber: null,
			modalContentId: null
		},
		billingModal: { isModalOpen: false, invoiceType: null, selectedDocumentId: null }
	};

	getDocumentNumber({ number, type, id }) {
		let onClick;
		if (type === 'DEBIT_TRANSACTION' || type === 'CREDIT_TRANSACTION') {
			const modalContentType =
				type === 'DEBIT_TRANSACTION'
					? IncomeOutcomeModalContentType.INCOME
					: IncomeOutcomeModalContentType.OUTCOME;
			onClick = () =>
				this.setState((prevState) => {
					return {
						...prevState,
						billingModal: { ...prevState.billingModal },
						transactionModal: {
							isModalOpen: true,
							modalContentType: modalContentType,
							modalContentNumber: number,
							modalContentId: id
						}
					};
				});
		} else {
			onClick = () =>
				this.setState((prevState) => {
					return {
						...prevState,
						transactionModal: {
							...prevState.transactionModal
						},
						billingModal: {
							isModalOpen: true,
							invoiceType: type,
							selectedDocumentId: id
						}
					};
				});
		}

		return (
			<div className={'link'} onClick={onClick}>
				{number}
			</div>
		);
	}

	getClinicLegalNameColumn(clientId, clientLegalName) {
		return <Link to={PAGES_PATHS.CLIENTS + '/' + clientId}>{clientLegalName}</Link>;
	}

	getConnectedTo(connectedItemsTypes) {
		return getLocalizedConnectedTo(connectedItemsTypes);
	}

	getConnectedToNumbers(connectedItemsIds) {
		return getListWithTooltip(connectedItemsIds, '-', 3, true);
	}

	parseBalance(items, isCurrentBalance) {
		let data = [];
		if (!items || !items.length) {
			return data;
		}

		items.forEach(
			({
				id,
				date,
				type,
				clinicId,
				clinicLegalName,
				connectedItemsTypes,
				connectedItemsIds,
				amount,
				notPaidAmount,
				localizedCurrencyAbbreviation,
				currencySymbol,
				number,
				labTransactionId
			}) => {
				data.push([
					this.getDocumentNumber({ number, type, id, labTransactionId }),
					moment(date).format('DD.MM.YYYY'),
					this.state.localizedDocumentType[type],
					this.getClinicLegalNameColumn(clinicId, clinicLegalName),
					this.getConnectedTo(connectedItemsTypes, connectedItemsIds),
					this.getConnectedToNumbers(connectedItemsIds),
					this.getCurrencyCell(
						amount,
						localizedCurrencyAbbreviation,
						currencySymbol,
						type,
						isCurrentBalance
					),
					this.getCurrencyCell(
						notPaidAmount,
						localizedCurrencyAbbreviation,
						currencySymbol,
						type,
						isCurrentBalance
					)
				]);
			}
		);
		return data;
	}

	getCurrencyCell(amount, localizedCurrencyAbbreviation, currencySymbol, type, isCurrentBalance) {
		if (type === 'CREDIT_NOTE' || type === 'CREDIT_TRANSACTION') {
			amount = Number(amount) * -1;
		}

		if (type === 'DEBIT_TRANSACTION') {
			amount = Number(amount) * -1;
		}

		return parseInvoicingCurrency(amount, localizedCurrencyAbbreviation, currencySymbol);
	}

	getHeader() {
		const header = [
			{ content: i18n.t('translation:billingModule.common.table.number') },
			{ content: i18n.t('translation:billingModule.balance.table.date') },
			{ content: i18n.t('translation:billingModule.balance.table.type') },
			{ content: i18n.t('translation:billingModule.balance.table.client') },
			{ content: i18n.t('translation:billingModule.balance.table.connectedWith') },
			{ content: i18n.t('translation:billingModule.balance.table.numConnectedWith') },
			{ content: i18n.t('translation:billingModule.balance.table.amount') },
			{ content: i18n.t('translation:billingModule.balance.table.unpaidAmount') }
		];

		return header;
	}

	onBillingModalClose() {
		this.setState((prevState) => {
			return {
				...prevState,
				transactionModal: {
					...prevState.transactionModal
				},
				billingModal: {
					isModalOpen: false,
					invoiceType: null,
					selectedDocumentId: null
				}
			};
		});
	}

	onTransactionModalClose() {
		this.setState((prevState) => {
			return {
				...prevState,
				billingModal: { ...prevState.billingModal },
				transactionModal: {
					isModalOpen: false,
					modalContentType: null,
					modalContentNumber: null,
					modalContentId: null
				}
			};
		});
	}

	render() {
		return (
			<Fragment>
				{renderSimpleTable(
					this.parseBalance(this.props.items, this.props.isCurrentBalance),
					'balance-table',
					this.getHeader(),
					i18n.t('translation:common.table.noItems')
				)}
				{this.state.transactionModal.isModalOpen && (
					<IncomeOutcomeModal
						isOpen={this.state.transactionModal.isModalOpen}
						contentType={this.state.transactionModal.modalContentType}
						contentId={this.state.transactionModal.modalContentId}
						number={this.state.transactionModal.modalContentNumber}
						isCreation={false}
						onClose={() => this.onTransactionModalClose()}
						onDocumentChange={this.props.onIncomeOutcomeChange}
					/>
				)}
				{this.state.billingModal.isModalOpen && (
					<BillingDocumentModal
						mode={BILLING_DOCUMENT_MODE.PREVIEW}
						isModalOpen={this.state.billingModal.isModalOpen}
						onModalClose={() => this.onBillingModalClose()}
						documentType={this.state.billingModal.invoiceType}
						selectedDocumentId={this.state.billingModal.selectedDocumentId}
					/>
				)}
			</Fragment>
		);
	}
}

export default StatementTable;
