import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import i18n from '../../i18n';
import Slider from './Slider';
import DefaultButton from './DefaultButton';
import { hideAvatarModal } from '../../actions';
import { connect } from 'react-redux';
import { VISIBLE_AVATAR_DIMENSIONS } from '../../utils/constants';

class AvatarModal extends React.Component {
	minZoom = 1;
	maxZoom = 10;
	initialZoom = 5;
	zoomStep = 0.5;

	initialState = {
		croppedArea: { x: 0, y: 0 },
		croppedAreaPixels: null,
		zoom: this.initialZoom
	};

	constructor() {
		super();
		this.state = this.initialState;
	}

	render = () => {
		return (
			<Modal
				isOpen={this.props.isOpen}
				className="custom-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.props.hideAvatarModal();
					}}
				/>
				<h2 className="mb-xs">{i18n.t('translation:auth.cropAvatar')}</h2>
				<div className="cropper">
					<div>
						<Cropper
							image={this.props.image}
							cropSize={{
								width: VISIBLE_AVATAR_DIMENSIONS.WIDTH,
								height: VISIBLE_AVATAR_DIMENSIONS.HEIGHT
							}}
							crop={this.state.croppedArea}
							minZoom={this.minZoom}
							maxZoom={this.maxZoom}
							zoom={this.state.zoom}
							showGrid={false}
							aspect={1 / 1}
							onCropChange={this.onCropChange}
							onCropComplete={this.onCropComplete}
						/>
					</div>
				</div>
				<Slider
					value={this.state.zoom}
					min={this.minZoom}
					max={this.maxZoom}
					step={this.zoomStep}
					onChange={this.onChangeZoomSlider}
				/>
				<div className="buttons">
					<DefaultButton
						title={i18n.t('translation:common.buttons.save')}
						onClick={this.onSaveAvatar}
					/>
					<DefaultButton
						title={i18n.t('translation:common.buttons.close')}
						onClick={() => {
							this.props.hideAvatarModal();
						}}
						secondary
					/>
				</div>
			</Modal>
		);
	};

	onCropChange = (croppedArea) => {
		this.setState({ croppedArea });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({ croppedAreaPixels });
	};

	onChangeZoomSlider = (event) => {
		this.setState({ zoom: event.target.value });
	};

	onSaveAvatar = () => {
		this.props.onSaveAvatar(this.state.croppedAreaPixels);
		this.props.hideAvatarModal();
		this.setState({ ...this.initialState });
	};
}

const mapStateToProps = (state) => {
	return {
		isOpen: state.portal.avatarModal.isOpen,
		image: state.portal.avatarModal.image
	};
};

export default connect(mapStateToProps, { hideAvatarModal })(AvatarModal);
