import { ORDER_SORT_DIRECTORY, ORDER_SORT_KEYS, SORT_TYPES } from '../utils/constants';
import { CLEAR_SORT, SET_SORT } from '../actions/types';

const initState = {
	[SORT_TYPES.ORDER_SORT]: {
		sortKey: ORDER_SORT_KEYS.STATUS,
		sortDirection: ORDER_SORT_DIRECTORY.ASC
	}
};

const sortReducer = (state = { ...initState }, action) => {
	if (action.type === SET_SORT) {
		state[action.payload.type] = action.payload.sort;
		return { ...state };
	}

	if (action.type === CLEAR_SORT) {
		state = { ...initState };
		return { ...state };
	}

	return state;
};

export default sortReducer;
