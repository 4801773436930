import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import i18n from '../../../i18n';
import { parseClients } from '../../../utils/tableUtils';
import Table from '../../common/Table';
import DefaultButton from '../../common/DefaultButton';
import { LANGUAGE_CODES, PAGINATION_TYPES } from '../../../utils/constants';
import Pagination from '../../common/Pagination';
import SendUserInvitationModal from '../links/SendUserInvitationModal';
import SendLinkInvitationModal from '../links/SendLinkInvitationModal';
import {
	getLanguageCode,
	isBillingModuleEnabled,
	isEmptyObject,
	isLabAdmin,
	showErrorPopup
} from '../../../utils/appUtils';
import {
	clearReducers,
	getLinkedClinics,
	hideMessageModal,
	setPagination,
	showMessageModal,
	updateLinkedClients
} from '../../../actions';
import { change, getFormValues, submit } from 'redux-form';
import AddPassiveClinicModal from './AddPassiveClinicModal';
import _ from 'lodash';

class Clients extends React.Component {
	state = {
		isUserInvitationModalOpen: false,
		isLinkInvitationModalOpen: false,
		initialValues: {}
	};

	componentDidMount() {
		const labId = this.props.account.labId;
		this.props.getLinkedClinics(labId, this.props.page || 1);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const labId = this.props.account.labId;
		if (prevProps.page !== this.props.page) {
			this.props.getLinkedClinics(labId, this.props.page);
		} else {
			if (
				this.props.page &&
				this.props.page !== 1 &&
				isEmptyObject(this.props.clinics.content)
			) {
				this.props.setPagination(PAGINATION_TYPES.CLINICS, this.props.page - 1);
			}
		}
		const initialValues = this.parseInitialValues(this.props.clinics.content);
		if (
			Object.keys(initialValues)[0] &&
			// eslint-disable-next-line no-prototype-builtins
			!this.state.initialValues.hasOwnProperty(Object.keys(initialValues)[0])
		) {
			this.setState({ initialValues: { ...initialValues, ...this.state.initialValues } });
		}
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		return (
			<Fragment>
				<SendUserInvitationModal
					initialEmail={this.props.signUpUserEmail}
					isInvitationToDentist={true}
					isModalOpen={this.state.isUserInvitationModalOpen}
					closeModal={this.toggleUserInvitationModalVisibility}
				/>

				<SendLinkInvitationModal
					organizationId={this.props.account.labId}
					isInvitationToClinic={true}
					isModalOpen={this.state.isLinkInvitationModalOpen}
					closeModal={this.toggleLinkInvitationModalVisibility}
					showUserInvitationModal={this.toggleUserInvitationModalVisibility}
				/>

				<AddPassiveClinicModal
					isModalOpen={this.state.isPassiveClientModalOpen}
					closeModal={this.togglePassiveClientModalVisibility}
				/>

				<Header
					title={i18n.t('translation:clinics.clients')}
					buttons={[
						this.renderInviteDentistButton(),
						this.renderInviteClientButton(),
						this.renderAddPassiveClientButton(),
						this.renderSaveButton()
					]}
				/>
				<div className="col-12 p-0">
					<hr />
					{this.renderClients()}
					{this.renderPagination()}
				</div>
			</Fragment>
		);
	}

	renderInviteDentistButton = () => {
		return isLabAdmin(this.props.account) ? (
			<DefaultButton
				title={i18n.t('translation:clinics.buttons.inviteDentist')}
				onClick={() => {
					this.setState({ isUserInvitationModalOpen: true });
				}}
			/>
		) : null;
	};

	renderInviteClientButton = () => {
		const title = i18n.t('translation:clinics.buttons.inviteClient');
		const onClick = () => this.setState({ isLinkInvitationModalOpen: true });

		if (isLabAdmin(this.props.account)) {
			return getLanguageCode() === LANGUAGE_CODES.DE ||
				getLanguageCode() === LANGUAGE_CODES.TR ? (
				<DefaultButton title={title} onClick={onClick} extraWide />
			) : (
				<DefaultButton title={title} onClick={onClick} />
			);
		} else {
			return null;
		}
	};

	renderAddPassiveClientButton = () => {
		const title = i18n.t('translation:clinics.buttons.addPassiveClient');
		const onClick = () => this.setState({ isPassiveClientModalOpen: true });

		if (isLabAdmin(this.props.account)) {
			return getLanguageCode() === LANGUAGE_CODES.DE ||
				getLanguageCode() === LANGUAGE_CODES.TR ? (
				<DefaultButton title={title} onClick={onClick} extraWide />
			) : (
				<DefaultButton title={title} onClick={onClick} />
			);
		} else {
			return null;
		}
	};

	toggleUserInvitationModalVisibility = () => {
		this.setState({
			isUserInvitationModalOpen: !this.state.isUserInvitationModalOpen
		});
	};

	toggleLinkInvitationModalVisibility = () => {
		this.setState({
			isLinkInvitationModalOpen: !this.state.isLinkInvitationModalOpen
		});
	};

	togglePassiveClientModalVisibility = () => {
		this.setState({
			isPassiveClientModalOpen: !this.state.isPassiveClientModalOpen
		});
	};

	renderSaveButton = () => {
		return isLabAdmin(this.props.account) ? (
			<DefaultButton
				short
				secondary
				title={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.submit('tableForm');
				}}
			/>
		) : (
			<Fragment />
		);
	};

	parseInitialValues = (content) => {
		let initialValues = {};

		if (!content) {
			return initialValues;
		}

		for (let clientLink of content) {
			initialValues[`discount_${clientLink.id}`] = clientLink.discountPercentage;
			initialValues[`isIndividualPriceListEnabled_${clientLink.id}`] =
				clientLink.isIndividualPriceListEnabled;
			initialValues[`invoiceDueDays_${clientLink.id}`] = clientLink.invoiceDueDays;
		}
		return initialValues;
	};

	renderClients = () => {
		if (!isEmptyObject(this.props.clinics)) {
			const renderInvoiceDueDays =
				isBillingModuleEnabled(this.props.modules) && isLabAdmin(this.props.account);

			const clients =
				!isEmptyObject(this.props.clinics.content) && this.props.clinics.content[0].id
					? parseClients(
							this.props.clinics.content,
							isLabAdmin(this.props.account),
							this.props.account.labId,
							renderInvoiceDueDays
					  )
					: [];
			const columns = [
				i18n.t('translation:common.table.name'),
				i18n.t('translation:common.table.phone'),
				i18n.t('translation:common.table.email'),
				i18n.t('translation:common.table.address'),
				i18n.t('translation:clinics.table.individualPrices'),
				i18n.t('translation:clinics.table.priceList')
			];
			if (isLabAdmin(this.props.account)) {
				columns.push(i18n.t('translation:common.table.discount'));
				if (isBillingModuleEnabled(this.props.modules)) {
					columns.push(i18n.t('translation:clinics.table.invoiceDueDays'));
				}
			}

			let initialValues = this.parseInitialValues(this.props.clinics.content);

			initialValues = { ...initialValues, ...this.props.formValues };

			return (
				<Table
					columns={columns}
					data={clients}
					onSubmit={this.onTableFormSubmit}
					initialValues={initialValues}
					className={'clients'}
					headerLastCellInheritAlign={true}
				/>
			);
		}
	};

	onTableFormSubmit = (formValues) => {
		const isInvoiceDueDaysPresent =
			isBillingModuleEnabled(this.props.modules) && isLabAdmin(this.props.account);
		const links = [];

		let currentLink = {};
		let isIndividualPriceListDisabled = false;
		const individualPricelists = _.omitBy(
			formValues,
			(value, key) => !key.startsWith('isIndividualPriceListEnabled')
		);

		for (let link in formValues) {
			let valueName = link.split('_')[0];

			if (valueName === 'discount') {
				currentLink.percentage = formValues[link];
				currentLink.linkId = link.split('_')[1];
				currentLink.isIndividualPriceListEnabled =
					formValues[`isIndividualPriceListEnabled_${link.split('_')[1]}`];

				if (isInvoiceDueDaysPresent) {
					currentLink.invoiceDueDays = formValues[`invoiceDueDays_${link.split('_')[1]}`];
				}
				links.push(currentLink);
				currentLink = {};
			}
		}

		for (let link in individualPricelists) {
			if (
				!individualPricelists[link] &&
				individualPricelists[link] !== this.state.initialValues[link]
			) {
				isIndividualPriceListDisabled = true;
				break;
			}
		}

		if (isIndividualPriceListDisabled) {
			const yesButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.yes')}
					onClick={() => {
						this.props.hideMessageModal();
						updateLinkedClients(
							this.props.account.labId,
							{ links },
							this.callbackOnUpdateLinkedClinics
						);
					}}
				/>
			);
			const noButton = (
				<DefaultButton
					title={i18n.t('translation:common.buttons.no')}
					onClick={() => {
						this.props.hideMessageModal();
						showErrorPopup(i18n.t('translation:clinics.changesNotSaved'));
						for (let field in this.state.initialValues) {
							this.props.change('tableForm', field, this.state.initialValues[field]);
						}
					}}
					secondary
				/>
			);
			this.props.showMessageModal(
				i18n.t('translation:common.confirmation'),
				i18n.t('translation:clinics.individualPriceListDisabledWarning'),
				[yesButton, noButton]
			);
		} else {
			updateLinkedClients(
				this.props.account.labId,
				{ links },
				this.callbackOnUpdateLinkedClinics
			);
		}
	};

	callbackOnUpdateLinkedClinics = () => {
		const formValues = this.props.formValues;
		this.setState({ initialValues: { ...this.state.initialValues, ...formValues } });
	};

	renderPagination = () => {
		const { clinics } = this.props;
		if (clinics) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={clinics.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.CLINICS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		modules: state.modules,
		clinics: state.clinics.clinics,
		page: state.pagination[PAGINATION_TYPES.CLINICS],
		account: state.account,
		signUpUserEmail: state.invitations.signUpUserEmail,
		formValues: getFormValues('tableForm')(state)
	};
};

export default connect(mapStateToProps, {
	getLinkedClinics,
	setPagination,
	clearReducers,
	showMessageModal,
	hideMessageModal,
	change,
	submit
})(Clients);
