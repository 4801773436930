import React, { Fragment } from 'react';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Header from './Header';
import {
	BILLING_DOCUMENT_TYPES,
	DEFAULT_BACKEND_DATE_FORMAT,
	INVOICE_FIELD_NAME
} from '../../../../../../../utils/constants';
import { Fields } from '../../../../../../common/Fields';
import {
	changeDocumentInvoicesIDS,
	changeDocumentItemsIDS,
	changeDocumentOrdersIDS,
	changeDocumentReasonTaxExclude,
	changeDocumentTaxExclude,
	changeDocumentTotalNoTax,
	changeDocumentTotalTax,
	changeDocumentTotalWithTax,
	changeInvoiceTotalTax,
	changeInvoiceTotalWithTax,
	changeItemAmountNoTax,
	changeItemCatalogNumber,
	changeItemCreditDebitNoRound,
	changeItemCreditDebitRound,
	changeItemInvoicingDiscount,
	changeItemName,
	changeItemQuantity,
	changeItemRamainingCreditAmount,
	changeItemTaxPercentTaxName,
	changeItemTotalPriceWithTax,
	changeItemTotalTax,
	changeItemUnit,
	changeItemUnitPriceNoTax,
	changeMapItemIdBillingItemId,
	changeOrderTotalNoTax,
	changeOrderTotalTax,
	formUtils,
	getDocumentInvoicesIDS,
	getDocumentItemsIDS,
	getInvoiceTotalTax,
	getInvoiceTotalWithTax,
	getItemAmountNoTax,
	getItemCatalogNumber,
	getItemInvoicingDiscount,
	getItemName,
	getItemQuantity,
	getItemRemainingCreditAmount,
	getItemTax,
	getItemTaxName,
	getItemTotalPriceWithTax,
	getItemTotalTax,
	getItemUnit,
	getItemUnitPriceNoTax,
	getOrderTotal,
	getOrderTotalTax
} from '../../../../../../../utils/formUtils';
import TableRowTrigger from '../../sections/common/TableRowTrigger';
import i18n from '../../../../../../../i18n';
import moment from '../../../../../../../utils/moment';
import { ORDER_PRICE_MODIFIED } from '../orderInvoiceTable/OrderInvoiceTable';
import { parseInvoicingCurrency } from '../../../../../../../utils/tableUtils';
import TableCell from '../../sections/common/TableCell';
import OrderValueModified from '../../sections/common/OrderValueModified';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import { isReadableInput } from '../../../../../../../utils/billingUtils';
import { add, divide, multiply, subtract } from '../../utils/documentUtils';

class CreditDebitInvoiceTable extends React.Component {
	state = {
		//	orderTriggers[order.id] = { isDropdownClose: false };
		ordersTriggers: {},
		//	invoicesTriggers[invoice.id] = { isDropdownClose: false };
		invoicesTriggers: {},

		//ordersPriceHasDiscountIncrease[456] -> will be undefined if order with id 456 is not modified
		ordersPriceHasDiscountOrIncrease: {},
		//{[orderID]: ['id', 'id']}
		orderItemsIDS: {}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.invoiceData !== this.props.invoiceData ||
			prevProps.currentDocument !== this.props.currentDocument
		) {
			this.initialize();
		}

		if (prevProps.formValues !== this.props.formValues) {
			this.onInvoiceTotalChange(prevProps, this.props);
		}
	}

	getDocumentItemsIDS(newProps) {
		const invoicesIDS = getDocumentInvoicesIDS(this.props.formValues);
		const itemsIDS = [];

		for (let invoiceID of invoicesIDS) {
			const currentInvoiceItemsIDS = this.getInvoiceItemsIDS(invoiceID, newProps);

			itemsIDS.push(...currentInvoiceItemsIDS);
		}

		return itemsIDS;
	}

	getInvoiceItemsIDS(invoiceID, newProps) {
		return getDocumentItemsIDS(newProps.formValues)[invoiceID];
	}

	getItemTotalItemTotalTax(invoiceID, itemID, newProps) {
		const invoiceItemID = `${invoiceID}_${itemID}`;
		const itemTotal = getItemTotalPriceWithTax(newProps.formValues, invoiceItemID);
		const itemTotalTax = getItemTotalTax(newProps.formValues, invoiceItemID);

		return { itemTotal, itemTotalTax };
	}

	calculateItemTotalItemTotalTax(invoiceID, itemId, updatedCredit, newProps) {
		if (!updatedCredit) {
			return { itemTotal: 0, itemTotalTax: 0 };
		}

		const itemTax = getItemTax(newProps.formValues, `${invoiceID}_${itemId}`);

		const updatedItemTotalTax = multiply(multiply(updatedCredit, itemTax), 0.01);

		const updatedItemTotal = add(updatedCredit, updatedItemTotalTax);

		return { itemTotal: updatedItemTotal, itemTotalTax: updatedItemTotalTax };
	}

	onInvoiceTotalChange(prevProps, newProps) {
		const invoicesIDS = getDocumentInvoicesIDS(newProps.formValues);

		let documentTotalIsUpdated = false;

		let updatedDocumentTotal = 0.0;
		let updatedDocumentTotalTax = 0.0;

		for (let invoiceID of invoicesIDS) {
			const updatedInvoiceTotal = getInvoiceTotalWithTax(this.props.formValues, invoiceID);

			const updatedInvoiceTotalTax = getInvoiceTotalTax(this.props.formValues, invoiceID);

			const oldInvoiceTotal = getInvoiceTotalWithTax(prevProps.formValues, invoiceID);

			if (oldInvoiceTotal != updatedInvoiceTotal) {
				documentTotalIsUpdated = true;
			}

			updatedDocumentTotalTax = add(updatedDocumentTotalTax, updatedInvoiceTotalTax);
			updatedDocumentTotal = add(updatedDocumentTotal, updatedInvoiceTotal);
		}

		if (documentTotalIsUpdated) {
			changeDocumentTotalWithTax(this.props.change, updatedDocumentTotal);
			changeDocumentTotalTax(this.props.change, updatedDocumentTotalTax);
			changeDocumentTotalNoTax(
				this.props.change,
				subtract(updatedDocumentTotal, updatedDocumentTotalTax)
			);
		}
	}

	resetInvoiceTotal(invoiceID) {
		changeInvoiceTotalTax(this.props.change, invoiceID, 0);
		changeInvoiceTotalWithTax(this.props.change, invoiceID, 0);
	}

	resetOrderTotal(orderID) {
		changeOrderTotalTax(this.props.change, orderID, 0);
		changeOrderTotalNoTax(this.props.change, orderID, 0);
	}

	initialize(isInitializingAfterTaxExclude) {
		const updatedDocumentInvoicesIDS = [];
		const initialOrdersTriggers = {};
		let initialInvoicesTriggers = {};
		const updatedDocumentOrdersIDS = [];
		const updatedDocumentItemsIDS = [];
		const updatedOrdersPriceHasDiscountOrIncrease = {};
		const updatedOrderItemsIDS = {};

		const updatedCreditedDebitedItemsIds = {};

		let allOrdersHasItemsLengthLessThanFour = true;

		const documentData = this.getDocumentData();

		let invoiceTotalWithTax = 0.0;
		let invoiceTotalTax = 0.0;

		for (let invoice of documentData) {
			const invoiceID = invoice.id;
			const invoiceType = invoice.type;

			//todo -> check why I need this
			// this.resetInvoiceTotal(invoiceID, this.props);

			if (!this.isCreation()) {
				invoiceTotalWithTax = 0.0;
				invoiceTotalTax = 0.0;
			}

			initialInvoicesTriggers[invoiceID] = this.getTrigger(false);

			updatedDocumentInvoicesIDS.push(invoiceID);

			if (invoiceType === BILLING_DOCUMENT_TYPES.FREE_INVOICE) {
				const itemsIDS = [];

				for (let item of invoice.items) {
					//this is billingItemID
					itemsIDS.push(item.id);

					updatedCreditedDebitedItemsIds[item.id] = item.creditedItemId;

					this.fillInitialItemData(invoiceID, item);

					if (!this.isCreation()) {
						const itemCreditNoTax = item.creditedAmount;

						const itemCreditTax = multiply(
							multiply(itemCreditNoTax, item.taxPercentage),
							0.01
						);
						invoiceTotalWithTax = add(
							invoiceTotalWithTax,
							add(itemCreditNoTax, itemCreditTax)
						);
						invoiceTotalTax = add(invoiceTotalTax, itemCreditTax);
					}
				}

				updatedDocumentItemsIDS[invoiceID] = [...itemsIDS];
			} else {
				const invoiceOrdersIds = [];
				const invoiceOrders = invoice.orders;

				updatedDocumentItemsIDS[invoiceID] = [];

				let orderTotalNoTax = 0.0;
				let orderTotalTax = 0.0;

				for (let order of invoiceOrders) {
					const orderItemsIDS = [];

					const orderID = order.id;

					if (!this.isCreation()) {
						orderTotalNoTax = 0.0;
						orderTotalTax = 0.0;
					}

					updatedOrderItemsIDS[orderID] = [];

					initialOrdersTriggers[orderID] = this.getTrigger(false);
					invoiceOrdersIds.push(orderID);

					const orderDiscount = order.discount ?? 0;
					const orderIncrease = order.priceIncrease ?? 0;

					if (orderDiscount > 0 && orderIncrease > 0) {
						updatedOrdersPriceHasDiscountOrIncrease[orderID] = {
							type: ORDER_PRICE_MODIFIED.INCREASE_AND_DECREASE,
							amount: orderIncrease
						};
					} else if (orderDiscount > 0) {
						updatedOrdersPriceHasDiscountOrIncrease[orderID] = {
							type: ORDER_PRICE_MODIFIED.DECREASE,
							amount: orderDiscount
						};
					} else if (orderIncrease > 0) {
						updatedOrdersPriceHasDiscountOrIncrease[orderID] = {
							type: ORDER_PRICE_MODIFIED.INCREASE,
							amount: orderIncrease
						};
					}

					//todo -> check why I need this
					// this.resetOrderTotal(orderID, this.props);

					const orderItems = order.items;

					for (let item of orderItems) {
						//this is billingItemID
						orderItemsIDS.push(item.id);

						updatedCreditedDebitedItemsIds[item.id] = item.creditedItemId;

						this.fillInitialItemData(invoiceID, item);

						if (!this.isCreation()) {
							const itemCreditNoTax = item.creditedAmount;
							const itemCreditTax = multiply(
								multiply(itemCreditNoTax, item.taxPercentage),
								0.01
							);

							orderTotalNoTax = add(orderTotalNoTax, itemCreditNoTax);
							orderTotalTax = add(orderTotalTax, itemCreditTax);
							invoiceTotalWithTax = add(
								invoiceTotalWithTax,
								add(itemCreditNoTax, itemCreditTax)
							);
							invoiceTotalTax = add(invoiceTotalTax, itemCreditTax);
						}
					}

					updatedDocumentItemsIDS[invoiceID].push(...orderItemsIDS);
					updatedOrderItemsIDS[orderID].push(...orderItemsIDS);

					if (orderItems.length > 3) {
						allOrdersHasItemsLengthLessThanFour = false;
					}

					if (!this.isCreation()) {
						changeOrderTotalNoTax(this.props.change, orderID, orderTotalNoTax);
						changeOrderTotalTax(this.props.change, orderID, orderTotalTax);
					}
				}
				updatedDocumentOrdersIDS[invoiceID] = [...invoiceOrdersIds];
			}

			if (!this.isCreation()) {
				changeInvoiceTotalWithTax(this.props.change, invoiceID, invoiceTotalWithTax);
				changeInvoiceTotalTax(this.props.change, invoiceID, invoiceTotalTax);
			}
		}

		const { updatedInvoicesTriggers, updatedOrdersTriggers } = this.closeAllTriggersIfNeeded(
			allOrdersHasItemsLengthLessThanFour,
			initialInvoicesTriggers,
			initialOrdersTriggers,
			isInitializingAfterTaxExclude
		);

		changeDocumentOrdersIDS(this.props.change, updatedDocumentOrdersIDS);
		changeDocumentInvoicesIDS(this.props.change, updatedDocumentInvoicesIDS);
		changeDocumentItemsIDS(this.props.change, updatedDocumentItemsIDS);

		changeMapItemIdBillingItemId(this.props.change, updatedCreditedDebitedItemsIds);

		if (this.isCreation()) {
			const excludedTax = this.props.invoiceData[0].taxIncluded;
			const excludeTaxReason = this.props.invoiceData[0].taxNotIncludedReason;
			changeDocumentTaxExclude(this.props.change, !excludedTax);
			changeDocumentReasonTaxExclude(this.props.change, excludeTaxReason);
		}

		this.setState((prevState) => {
			return {
				...prevState,
				ordersTriggers: updatedOrdersTriggers,
				invoicesTriggers: updatedInvoicesTriggers,
				ordersPriceHasDiscountOrIncrease: updatedOrdersPriceHasDiscountOrIncrease,
				orderItemsIDS: updatedOrderItemsIDS
			};
		});
	}

	closeAllTriggersIfNeeded(
		allOrdersHasItemsLengthLessThanFour,
		invoicesTriggers,
		ordersTriggers,
		isInitializingAfterTaxExclude
	) {
		let updatedInvoicesTriggers = {};
		let updatedOrdersTriggers = {};
		if (!allOrdersHasItemsLengthLessThanFour) {
			for (let key in invoicesTriggers) {
				if (isInitializingAfterTaxExclude) {
					updatedInvoicesTriggers[key] = this.getTrigger(false);
				} else {
					updatedInvoicesTriggers[key] = this.getTrigger(true);
				}
			}

			for (let key in ordersTriggers) {
				if (isInitializingAfterTaxExclude) {
					updatedOrdersTriggers[key] = this.getTrigger(false);
				} else {
					updatedOrdersTriggers[key] = this.getTrigger(true);
				}
			}

			return {
				updatedInvoicesTriggers,
				updatedOrdersTriggers
			};
		}

		return {
			updatedInvoicesTriggers: invoicesTriggers,
			updatedOrdersTriggers: ordersTriggers
		};
	}

	fillInitialItemData = (invoiceID, item) => {
		const itemID = item.id;
		const name = item.name;
		const unit = item.unit;
		const quantity = item.quantity;
		const unitPrice = item.unitPrice;
		const taxName = item.taxName;
		const tax = item.taxPercentage;
		const catalogNumber = item.catalogNumber;
		const itemRemainingCreditAmount = item.remainingCreditAmount;
		const itemInvoicingDiscount = item.invoicingDiscount;
		const itemCreditedAmount = item.creditedAmount ?? 0;

		let advance;

		if (this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE) {
			advance = unitPrice;
		} else {
			advance = divide(item.advance, quantity);
		}

		const amount = multiply(advance, quantity);

		const itemInputFieldSuffix = `${invoiceID}_${itemID}`;

		changeItemInvoicingDiscount(this.props.change, itemInputFieldSuffix, itemInvoicingDiscount);

		if (!this.isCreation()) {
			changeItemRamainingCreditAmount(
				this.props.change,
				itemInputFieldSuffix,
				add(itemRemainingCreditAmount, itemCreditedAmount)
			);
		} else {
			changeItemRamainingCreditAmount(
				this.props.change,
				itemInputFieldSuffix,
				itemRemainingCreditAmount
			);
		}

		changeItemCatalogNumber(this.props.change, itemInputFieldSuffix, catalogNumber);
		changeItemName(this.props.change, itemInputFieldSuffix, name);
		changeItemQuantity(this.props.change, itemInputFieldSuffix, quantity);
		changeItemUnit(this.props.change, itemInputFieldSuffix, unit);
		changeItemUnitPriceNoTax(this.props.change, itemInputFieldSuffix, advance);
		changeItemTaxPercentTaxName(this.props.change, itemInputFieldSuffix, `${tax}_${taxName}`);
		changeItemAmountNoTax(this.props.change, itemInputFieldSuffix, amount);

		if (this.isCreation()) {
			changeItemTotalPriceWithTax(this.props.change, itemInputFieldSuffix, 0);
			changeItemCreditDebitRound(this.props.change, itemInputFieldSuffix, 0);
			changeItemCreditDebitNoRound(this.props.change, itemInputFieldSuffix, 0);
		} else {
			const itemTotalPriceWithTax = add(
				itemCreditedAmount,
				multiply(multiply(itemCreditedAmount, tax), 0.01)
			);
			changeItemTotalPriceWithTax(
				this.props.change,
				itemInputFieldSuffix,
				itemTotalPriceWithTax
			);
			changeItemCreditDebitRound(this.props.change, itemInputFieldSuffix, itemCreditedAmount);
			changeItemCreditDebitNoRound(
				this.props.change,
				itemInputFieldSuffix,
				itemCreditedAmount
			);
		}
	};

	getTrigger = (isClose) => {
		return { isDropdownClose: isClose };
	};

	renderInvoice(invoice, columnNumber) {
		const invoiceID = invoice.id;
		const invoiceType = invoice.type;

		const invoiceIssueDate = this.parseDate(invoice.issueDate);

		return (
			<Fragment key={'invKey' + invoiceID}>
				<tr aria-colspan={columnNumber} className={'table-blue-row'}>
					<td colSpan={columnNumber - 7}>
						{`${i18n.t(
							'translation:billingModule.invoicing.table.creditNote.invoiceNumber'
						)}: ${invoice.number} / ${invoiceIssueDate}`}
					</td>
					<td colSpan={6}></td>
					<TableRowTrigger
						isClose={
							this.state.invoicesTriggers[invoiceID] &&
							this.state.invoicesTriggers[invoiceID].isDropdownClose
						}
						onTriggerClick={() => this.onInvoiceTriggerClick(invoiceID, invoiceType)}
					/>
				</tr>
				{this.renderInvoiceData(invoice, columnNumber)}
			</Fragment>
		);
	}

	onOrderTriggerClick(orderID) {
		const updatedIsDropdownClose = !this.state.ordersTriggers[orderID].isDropdownClose;

		this.setState((prevState) => {
			return {
				...prevState,
				ordersTriggers: {
					...prevState.ordersTriggers,
					[orderID]: {
						isDropdownClose: updatedIsDropdownClose
					}
				}
			};
		});
	}

	onInvoiceTriggerClick(invoiceID, invoiceType) {
		const updatedIsDropdownClose = !this.state.invoicesTriggers[invoiceID].isDropdownClose;

		if (invoiceType === BILLING_DOCUMENT_TYPES.ORDER_INVOICE) {
			const invoiceOrdersIds =
				this.props.formValues[INVOICE_FIELD_NAME.DOCUMENT_ORDERS_IDS][`${invoiceID}`] ?? [];
			const updatedOrdersTriggers = {};

			for (let orderID of invoiceOrdersIds) {
				updatedOrdersTriggers[orderID] = this.getTrigger(updatedIsDropdownClose);
			}

			this.setState((prevState) => {
				return {
					...prevState,
					invoicesTriggers: {
						...prevState.invoicesTriggers,
						[invoiceID]: {
							isDropdownClose: updatedIsDropdownClose
						}
					},
					ordersTriggers: {
						...prevState.ordersTriggers,
						...updatedOrdersTriggers
					}
				};
			});

			return;
		}

		this.setState((prevState) => {
			return {
				...prevState,
				invoicesTriggers: {
					...prevState.invoicesTriggers,
					[invoiceID]: {
						isDropdownClose: updatedIsDropdownClose
					}
				}
			};
		});
	}

	getInvoiceTotalLabel(invoiceNumberDate) {
		if (this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			return `${i18n.t(
				'translation:billingModule.invoicing.table.creditNote.creditInvoiceTotal',
				{
					invoiceNumber: invoiceNumberDate
				}
			)}`;
		}

		return `${i18n.t('translation:billingModule.invoicing.table.creditNote.debitInvoiceTotal', {
			invoiceNumber: invoiceNumberDate
		})}`;
	}

	renderInvoiceTotal(invoiceID, invoiceNumber, invoiceIssueDate, columnNumber) {
		const invoiceTotal = getInvoiceTotalWithTax(this.props.formValues, invoiceID);
		const formattedInvoiceDate = this.parseDate(invoiceIssueDate);
		const invoiceNumberDate = `${invoiceNumber} / ${formattedInvoiceDate}`;

		return (
			<tr aria-colspan={columnNumber} style={{ fontWeight: 'bold' }}>
				<td colSpan={columnNumber - 3} className={'text-right'}>
					{this.getInvoiceTotalLabel(invoiceNumberDate)}
				</td>
				<td className={'text-left'} colSpan={3}>
					{parseInvoicingCurrency(
						invoiceTotal,
						this.props.localizedCurrencyAbbreviation,
						this.props.currencySymbol
					)}
				</td>
			</tr>
		);
	}

	renderInvoiceData(invoice, columnNumber) {
		const invoiceID = invoice.id;
		const invoiceType = invoice.type;

		const invoiceTriggerIsOpen =
			this.state.invoicesTriggers[invoiceID] &&
			!this.state.invoicesTriggers[invoiceID].isDropdownClose;

		if (!invoiceTriggerIsOpen) {
			return null;
		}

		if (invoiceType === BILLING_DOCUMENT_TYPES.ORDER_INVOICE) {
			return (
				<Fragment>
					{this.renderOrderData(invoice.orders, invoiceID, columnNumber)}
					{this.renderInvoiceTotal(
						invoiceID,
						invoice.number,
						invoice.issueDate,
						columnNumber
					)}
				</Fragment>
			);
		}

		return (
			<Fragment>
				{this.renderItemsData(
					invoice.items,
					invoiceID,
					BILLING_DOCUMENT_TYPES.FREE_INVOICE
				)}
				{this.renderInvoiceTotal(
					invoiceID,
					invoice.number,
					invoice.issueDate,
					columnNumber
				)}
			</Fragment>
		);
	}

	getInvoiceItemsAreVisible(invoiceID, invoiceType, orderID) {
		if (invoiceType === BILLING_DOCUMENT_TYPES.FREE_INVOICE) {
			return (
				this.state.invoicesTriggers[invoiceID] &&
				!this.state.invoicesTriggers[invoiceID].isDropdownClose
			);
		}
		return (
			this.state.ordersTriggers[orderID] &&
			!this.state.ordersTriggers[orderID].isDropdownClose
		);
	}

	updateOrderTotal(
		invoiceID,
		orderID,
		updatedItemID,
		updatedItemTotalWithTax,
		updatedItemTotalTax
	) {
		const orderItemsIDS = this.state.orderItemsIDS[orderID];
		let updatedOrderTotalNoTax = 0.0;
		let updatedOrderTotalTax = 0.0;

		for (let itemID of orderItemsIDS) {
			if (itemID === updatedItemID) {
				updatedOrderTotalNoTax = add(
					updatedOrderTotalNoTax,
					subtract(updatedItemTotalWithTax, updatedItemTotalTax)
				);

				updatedOrderTotalTax = add(updatedOrderTotalTax, updatedItemTotalTax);
			} else {
				const currentItemTotalWithTax = getItemTotalPriceWithTax(
					this.props.formValues,
					`${invoiceID}_${itemID}`
				);
				const currenItemTotalTax = getItemTotalTax(
					this.props.formValues,
					`${invoiceID}_${itemID}`
				);

				updatedOrderTotalNoTax = add(
					updatedOrderTotalNoTax,
					subtract(currentItemTotalWithTax, currenItemTotalTax)
				);
				updatedOrderTotalTax = add(updatedOrderTotalTax, currenItemTotalTax);
			}
		}

		changeOrderTotalNoTax(this.props.change, orderID, updatedOrderTotalNoTax);
		changeOrderTotalTax(this.props.change, orderID, updatedOrderTotalTax);
	}

	//if the user fill a number equal to the rounded itemRemainingCreditAmount -> use itemRemainingCreditAmount
	//Otherwise -> use normalized value

	useItemRemainingCreditIfNeeded(creditDebit, invoiceID, itemId) {
		let normalizedValue = formUtils.normalize.price(
			creditDebit,
			this.props.formValues[
				`${INVOICE_FIELD_NAME.ITEM_CREDIT_DEBIT_ROUND_}${invoiceID}_${itemId}`
			] ?? 0
		);

		if (this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			const creditValueRounded = Number(normalizedValue ?? 0).toFixed(2);
			const itemRemainingCreditAmount = getItemRemainingCreditAmount(
				this.props.formValues,
				`${invoiceID}_${itemId}`
			);
			const itemRemainingCreditAmountRounded = Number(itemRemainingCreditAmount ?? 0).toFixed(
				2
			);

			if (Number(creditValueRounded) === Number(itemRemainingCreditAmountRounded)) {
				normalizedValue = itemRemainingCreditAmount;
			}
		}

		return normalizedValue;
	}

	updateInvoiceTotalOrderTotal(itemId, invoiceID, creditDebit, invoiceType, orderId) {
		let updatedInvoiceTotalWithTax = 0.0;
		let updatedInvoiceTotalTax = 0.0;

		const documentItemsIDS = this.getDocumentItemsIDS(this.props);

		let currentItemTotalWithTax = 0;
		let currentItemTotalTax = 0;

		for (let itemID of documentItemsIDS) {
			currentItemTotalWithTax = 0;
			currentItemTotalTax = 0;

			if (itemID === itemId) {
				const { itemTotal, itemTotalTax } = this.calculateItemTotalItemTotalTax(
					invoiceID,
					itemID,
					creditDebit,
					this.props
				);

				currentItemTotalWithTax = itemTotal;
				currentItemTotalTax = itemTotalTax;

				if (invoiceType === BILLING_DOCUMENT_TYPES.ORDER_INVOICE) {
					this.updateOrderTotal(
						invoiceID,
						orderId,
						itemId,
						currentItemTotalWithTax,
						currentItemTotalTax
					);
				}

				const itemFormID = `${invoiceID}_${itemID}`;
				changeItemTotalPriceWithTax(this.props.change, itemFormID, itemTotal);
				changeItemTotalTax(this.props.change, itemFormID, itemTotalTax);
			} else {
				const { itemTotal, itemTotalTax } = this.getItemTotalItemTotalTax(
					invoiceID,
					itemID,
					this.props
				);

				currentItemTotalWithTax = itemTotal;
				currentItemTotalTax = itemTotalTax;
			}

			updatedInvoiceTotalTax = add(updatedInvoiceTotalTax, currentItemTotalTax);
			updatedInvoiceTotalWithTax = add(updatedInvoiceTotalWithTax, currentItemTotalWithTax);
		}

		changeInvoiceTotalWithTax(this.props.change, invoiceID, updatedInvoiceTotalWithTax);
		changeInvoiceTotalTax(this.props.change, invoiceID, updatedInvoiceTotalTax);
	}

	onCreditDebitChange(creditDebitNewValue, currentItemID, invoiceID, invoiceType, orderID) {
		const creditNormalizedValue = this.useItemRemainingCreditIfNeeded(
			creditDebitNewValue,
			invoiceID,
			currentItemID
		);

		changeItemCreditDebitNoRound(
			this.props.change,
			`${invoiceID}_${currentItemID}`,
			creditNormalizedValue
		);

		this.updateInvoiceTotalOrderTotal(
			currentItemID,
			invoiceID,
			creditNormalizedValue,
			invoiceType,
			orderID
		);
	}

	renderItemsData(items, invoiceID, invoiceType, orderID, columnNumber) {
		const itemsAreVisible = this.getInvoiceItemsAreVisible(invoiceID, invoiceType, orderID);

		if (!itemsAreVisible) {
			return null;
		}

		return items.map((item, index) => {
			const itemID = item.id;
			const inputFieldID = `${invoiceID}_${itemID}`;
			const currency = this.props.localizedCurrencyAbbreviation;

			return (
				<tr aria-colspan={columnNumber} key={'itemSKey' + index}>
					<td colSpan={1}>
						<TableCell
							value={getItemCatalogNumber(this.props.formValues, inputFieldID)}
						/>
					</td>
					<td colSpan={2}>
						<TableCell value={getItemName(this.props.formValues, inputFieldID)} />
					</td>
					<td colSpan={1}>
						<TableCell value={getItemUnit(this.props.formValues, inputFieldID)} />
					</td>
					<td colSpan={1}>
						<TableCell value={getItemQuantity(this.props.formValues, inputFieldID)} />
					</td>
					{this.renderProductPrice(inputFieldID, orderID)}

					{this.renderProductAmount(inputFieldID, orderID)}

					{this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE && (
						<>
							<td colSpan={2}>
								<TableCell
									value={getItemInvoicingDiscount(
										this.props.formValues,
										inputFieldID
									)}
									localizedCurrencyAbbreviation={
										this.props.localizedCurrencyAbbreviation
									}
									currencySymbol={this.props.currencySymbol}
									currency={true}
								/>
							</td>
							<td colSpan={2}>
								<TableCell
									value={getItemRemainingCreditAmount(
										this.props.formValues,
										inputFieldID
									)}
									localizedCurrencyAbbreviation={
										this.props.localizedCurrencyAbbreviation
									}
									currencySymbol={this.props.currencySymbol}
									currency={true}
								/>
							</td>
						</>
					)}
					{this.renderCreditField(
						inputFieldID,
						currency,
						itemID,
						invoiceID,
						invoiceType,
						orderID
					)}
					{this.renderDebitField(
						inputFieldID,
						currency,
						itemID,
						invoiceID,
						invoiceType,
						orderID
					)}
					<td colSpan={1}>
						<TableCell
							value={getItemTaxName(this.props.formValues, inputFieldID)}
							currency={false}
						/>
					</td>
					<td style={noBreakWordStyle} colSpan={2}>
						<TableCell
							value={getItemTotalPriceWithTax(this.props.formValues, inputFieldID)}
							localizedCurrencyAbbreviation={this.props.localizedCurrencyAbbreviation}
							currencySymbol={this.props.currencySymbol}
							currency={true}
						/>
					</td>
					<td colSpan={1}></td>
				</tr>
			);
		});
	}

	renderDebitField(inputFieldID, currency, itemID, invoiceID, invoiceType, orderID) {
		if (this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			return null;
		}

		const readableInput = isReadableInput(this.props.mode);

		return (
			<td colSpan={2}>
				{Fields.billingModule.invoicing.invoiceProductCreditDebit(inputFieldID, {
					localizedCurrencyAbbreviation: this.props.localizedCurrencyAbbreviation,
					currencySymbol: this.props.currencySymbol,
					onChange: (value) =>
						this.onCreditDebitChange(value, itemID, invoiceID, invoiceType, orderID),
					readableInput
				})}
			</td>
		);
	}

	renderCreditField(inputFieldID, currency, itemID, invoiceID, invoiceType, orderID) {
		if (this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE) {
			return null;
		}

		const readableInput = isReadableInput(this.props.mode);

		return (
			<td colSpan={2}>
				{Fields.billingModule.invoicing.invoiceProductCreditDebit(inputFieldID, {
					localizedCurrencyAbbreviation: this.props.localizedCurrencyAbbreviation,
					currencySymbol: this.props.currencySymbol,
					onChange: (value) =>
						this.onCreditDebitChange(value, itemID, invoiceID, invoiceType, orderID),
					readableInput
				})}
			</td>
		);
	}

	renderProductPrice(inputFieldID, orderID) {
		const currency = this.props.localizedCurrencyAbbreviation;

		const productPrice = getItemUnitPriceNoTax(this.props.formValues, inputFieldID);

		return (
			<OrderValueModified
				modificationType={this.state.ordersPriceHasDiscountOrIncrease}
				value={parseInvoicingCurrency(
					productPrice,
					this.props.localizedCurrencyAbbreviation,
					this.props.currencySymbol
				)}
				colSpan={2}
			/>
		);
	}

	renderProductAmount(inputFieldID, orderID) {
		const itemAmountNoTax = getItemAmountNoTax(this.props.formValues, inputFieldID);

		return (
			<OrderValueModified
				modificationType={this.state.ordersPriceHasDiscountOrIncrease[orderID]}
				value={parseInvoicingCurrency(
					itemAmountNoTax,
					this.props.localizedCurrencyAbbreviation,
					this.props.currencySymbol
				)}
				colSpan={2}
			/>
		);
	}

	getOrderTriggerIsOpen(orderID) {
		return (
			this.state.ordersTriggers[orderID] &&
			!this.state.ordersTriggers[orderID].isDropdownClose
		);
	}

	isCreation() {
		return this.props.mode === BILLING_DOCUMENT_MODE.CREATE;
	}

	getDocumentData() {
		if (this.isCreation()) {
			return this.props.invoiceData;
		}

		return this.props.currentDocument?.invoices ?? [];
	}

	renderOrderData(orders, invoiceID, columnNumber) {
		return orders.map((order, index) => {
			const orderID = order.id;
			const orderTriggerIsOpen = this.getOrderTriggerIsOpen(orderID);

			const orderNumber = order.labOrderId;

			if (!orderTriggerIsOpen) {
				return (
					<tr
						aria-colspan={columnNumber}
						className={'brown-table-row'}
						key={'invOrdID' + index}>
						<td colSpan={columnNumber - 1} style={{ fontWeight: 'bold' }}>{`${i18n.t(
							'translation:billingModule.invoicing.table.creditNote.orderNumber'
						)}: ${orderNumber}`}</td>
						<TableRowTrigger
							isClose={this.state.ordersTriggers[orderID].isDropdownClose}
							onTriggerClick={() => this.onOrderTriggerClick(orderID)}
							color={'black'}
						/>
					</tr>
				);
			}

			return (
				<Fragment key={'invOrdID' + index}>
					<tr aria-colspan={columnNumber} className={'brown-table-row'}>
						<td colSpan={columnNumber - 1} style={{ fontWeight: 'bold' }}>{`${i18n.t(
							'translation:billingModule.invoicing.table.creditNote.orderNumber'
						)}: ${orderNumber}`}</td>
						<TableRowTrigger
							isClose={this.state.ordersTriggers[orderID].isDropdownClose}
							onTriggerClick={() => this.onOrderTriggerClick(orderID)}
							color={'black'}
						/>
					</tr>
					{this.renderItemsData(
						order.items,
						invoiceID,
						BILLING_DOCUMENT_TYPES.ORDER_INVOICE,
						orderID,
						columnNumber
					)}
					{this.renderOrderTotal(orderID, orderNumber, columnNumber)}
				</Fragment>
			);
		});
	}

	getOrderTotalLabel(orderNumber) {
		if (this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE) {
			return i18n.t('translation:billingModule.invoicing.table.creditNote.creditOrderTotal', {
				orderNumber: orderNumber
			});
		}

		return i18n.t('translation:billingModule.invoicing.table.creditNote.debitOrderTotal', {
			orderNumber: orderNumber
		});
	}

	renderOrderTotal(orderID, orderNumber, columnNumber) {
		const orderTotalNoTax = getOrderTotal(this.props.formValues, orderID);
		const orderTotalTax = getOrderTotalTax(this.props.formValues, orderID);

		const orderTotalWithTax = parseInvoicingCurrency(
			add(orderTotalNoTax, orderTotalTax),
			this.props.localizedCurrencyAbbreviation,
			this.props.currencySymbol
		);

		return (
			<tr aria-colspan={columnNumber}>
				<td
					colSpan={columnNumber - 3}
					className={'text-right'}
					style={{ fontWeight: 'bold' }}>
					{this.getOrderTotalLabel(orderNumber)}
				</td>
				<td colSpan={3} className={'text-left'} style={{ fontWeight: 'bold' }}>
					{orderTotalWithTax}
				</td>
			</tr>
		);
	}

	parseDate = (date) => {
		return moment(date).format(DEFAULT_BACKEND_DATE_FORMAT);
	};

	render() {
		const columnNumber =
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ? 19 : 15;

		const documentData = this.getDocumentData();

		return (
			<table
				className="table col-12 mt-m credit-note-table w-100 position-relative"
				aria-colspan={columnNumber}>
				<Header invoiceType={this.props.documentType} />
				<tbody aria-colspan={columnNumber} className={'w-100'}>
					{documentData.map((invoice, index) => {
						return this.renderInvoice(invoice, columnNumber);
					})}
				</tbody>
			</table>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('freeInvoiceForm')(state),
		taxGroups: state.billingSettings.taxGroups,
		invoiceData: state.billingDocument.invoiceData,
		currentDocument: state.billingDocument.currentDocument
	};
};

export default connect(mapStateToProps, { change })(CreditDebitInvoiceTable);

const noBreakWordStyle = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
};
