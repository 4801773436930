import React from 'react';
import { connect } from 'react-redux';
import { getAbsenceByType, isEmptyObject } from '../../../../../utils/appUtils';
import i18n from '../../../../../i18n';

class StatisticTable extends React.Component {
	render() {
		return (
			<div className="statistic-table">
				<div>
					<div className="statistic-header">{this.renderAbbreviations()}</div>
					{this.renderData()}
				</div>
			</div>
		);
	}

	renderData = () => {
		const staff = this.props.staff;

		if (!isEmptyObject(staff)) {
			return staff.map((staff, index) => this.renderDataRow(staff.statistics, index));
		}
	};

	renderDataRow = (statistics, index) => {
		const data = statistics.map((statistic, index) =>
			this.renderDataElement(statistic, index === statistics.length - 1, index === 0, index)
		);

		return (
			<div key={'statistic_data_row' + index} className="data-row">
				{data}
			</div>
		);
	};

	renderDataElement = (statistic, isLast, isFirst, index) => {
		const borderLeft = isFirst ? { borderLeft: 'none' } : {};
		const borderRight = !isLast ? { borderRight: 'none' } : {};
		const style = { ...borderRight, ...borderLeft };
		const className = 'data-element';
		return (
			<div key={'sd' + index} className={className} style={style}>
				{statistic.days}
			</div>
		);
	};

	renderAbbreviations = () => {
		if (this.props.staff[0]) {
			const statistics = this.props.staff[0].statistics;

			const borderRight = (isLast) => (!isLast ? { borderRight: 'none' } : {});
			const borderLeft = (isFirst) => (isFirst ? { borderLeft: 'none' } : {});
			const absences = [...this.props.absences];
			const workedDaysAbsence = {
				name: i18n.t('translation:taskManager.planner.daysWorked'),
				abbreviation: i18n.t('translation:taskManager.planner.daysWorkedAbbreviation'),
				backgroundColor: '13253b',
				value: 'WORK_SHIFT'
			};
			absences.unshift(workedDaysAbsence);

			return statistics.map((statistic, index) => (
				<div
					className="abbreviation"
					style={{
						...borderLeft(index === 0),
						...borderRight(index === statistics.length - 1)
					}}
					key={'s' + index}>
					{getAbsenceByType(statistic.type, absences).abbreviation}
				</div>
			));
		}
	};
}

const mapStateToProps = (state) => {
	return {
		staff: state.staff,
		absences: state.absences
	};
};

export default connect(mapStateToProps)(StatisticTable);
