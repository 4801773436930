import { SET_COMMON_REDUCER } from '../actions/types';

const initState = {};

const commonReducer = (state = { ...initState }, action) => {
	if (action.type === SET_COMMON_REDUCER) {
		state[action.payload.name] = action.payload.value;
		return { ...state };
	}

	return state;
};

export default commonReducer;
