import { getAxiosWithToken } from '../../utils/webApi';
import { GET_CURRENT_TEMPLATE, GET_TEMPLATES } from '../types';
import { showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';

export const getLocalTemplates = (labId, productId) => async (dispatch) => {
	const response = await getAxiosWithToken().get(
		`/product/lab-product-templates/${labId}/${productId}`
	);
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({
			type: GET_TEMPLATES,
			payload: returnedData.data
		});
	}
};

export const getLabProductTemplate = (orderItemId, templateId) => async (dispatch) => {
	if (templateId == undefined || templateId == null) {
		return;
	}

	const response = await getAxiosWithToken().get(
		`/product/lab-product-template/?order-item-id=${orderItemId}&template-id=${templateId}`
	);

	let returnedData = response.data;

	if (returnedData.success && returnedData !== null) {
		dispatch({
			type: GET_CURRENT_TEMPLATE,
			payload: returnedData.data
		});
	}
};

export const createLocalTemplates =
	(labId, productId, data, setErrorMessage, closeModal) => async (dispatch) => {
		const response = await getAxiosWithToken(false).post(
			`/product/lab-product-template/${labId}/${productId}`,
			data
		);
		let returnedData = response.data;

		if (returnedData.success) {
			dispatch(getLocalTemplates(labId, productId));
			closeModal();
			showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedTemplate'));
		} else {
			setErrorMessage(returnedData.userMessage);
		}
	};

export const updateLocalTemplates =
	(id, data, labId, productId, setErrorMessage, closeModal) => async (dispatch) => {
		const response = await getAxiosWithToken(false).put(
			`/product/lab-product-template/${id}`,
			data
		);
		let returnedData = response.data;

		if (returnedData.success) {
			dispatch(getLocalTemplates(labId, productId));
			if (closeModal) {
				closeModal();
			}
			showSuccessPopup(i18n.t('translation:taskManager.common.successfullyUpdatedTemplate'));
		} else {
			setErrorMessage(returnedData.userMessage);
		}
	};

export const deleteLocalTemplates =
	(id, labId, productId, isNewTemplateWithoutTasks) => async (dispatch) => {
		const response = await getAxiosWithToken().delete(`/product/lab-product-template/${id}`);
		let returnedData = response.data;

		if (returnedData.success && !isNewTemplateWithoutTasks) {
			dispatch(getLocalTemplates(labId, productId));
			showSuccessPopup(
				i18n.t('translation:taskManager.inventory.successfullyDeletedTemplate')
			);
		}
	};
