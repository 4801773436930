import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TableButton extends Component {
	render() {
		const type = this.props.type ? this.props.type : 'button';

		let buttonStyle = 'table-button-container';

		if (this.props.danger) {
			buttonStyle += ' danger-button';
		}

		if (this.props.disabled) {
			buttonStyle += ' disabled-button';
		}

		if (this.props.warning) {
			buttonStyle += ' warning-button';
		}

		if (this.props.lightButton) {
			buttonStyle += ' light-button';
		}

		if (this.props.className) {
			buttonStyle += ' ' + this.props.className;
		}

		return (
			<div
				type={type}
				onClick={this.props.disabled ? () => {} : this.props.onPress}
				className={buttonStyle}
				data-toggle="tooltip"
				data-placement="right"
				title={this.props.tooltip}>
				<FontAwesomeIcon icon={this.props.icon} className="table-button-icon" />
			</div>
		);
	}
}

export default TableButton;
