import React from 'react';
import { connect } from 'react-redux';
import { getLabDetails, getStatistics } from '../../../actions';
import StatisticsItem from './StatisticsItem';
import {
	faCoins,
	faFlask,
	faHospitalAlt,
	faTasks,
	faUsers
} from '@fortawesome/free-solid-svg-icons';
import { DISPLAY_TYPES, STATISTIC_TYPES } from '../../../utils/constants';
import i18n from '../../../i18n';
import {
	isGlobalAdmin,
	isLabAdmin,
	isLabTechnician,
	registeredInClinic
} from '../../../utils/appUtils';

class Statistics extends React.Component {
	componentDidMount() {
		this.props.getStatistics();

		const { account } = this.props;
		if (isLabAdmin(account) || isLabTechnician(account)) {
			this.props.getLabDetails(account.labId);
		}
	}

	render() {
		return <div className="statistics">{this.renderStatistic()}</div>;
	}

	renderStatistic = () => {
		if (isLabAdmin(this.props.account)) {
			const clients = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.LINKED_COMPANIES)}
					value={this.props.statistics[STATISTIC_TYPES.LINKED_COMPANIES]}
					key={'lab-1'}
					icon={this.getIcon(STATISTIC_TYPES.LINKED_COMPANIES)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const orders = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.ORDERS)}
					key={'lab-2'}
					value={this.props.statistics[STATISTIC_TYPES.ORDERS]}
					icon={this.getIcon(STATISTIC_TYPES.ORDERS)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const profit = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.PAID_AMOUNT)}
					value={this.props.statistics[STATISTIC_TYPES.PAID_AMOUNT]}
					key={'lab-3'}
					icon={this.getIcon(STATISTIC_TYPES.PAID_AMOUNT)}
					lab={this.props.currentLab}
					displayType={DISPLAY_TYPES.PRICE}
				/>
			);
			return [clients, orders, profit];
		}
		if (registeredInClinic(this.props.account)) {
			const suppliers = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.LINKED_COMPANIES)}
					value={this.props.statistics[STATISTIC_TYPES.LINKED_COMPANIES]}
					key={'clinic-1'}
					icon={this.getIcon(STATISTIC_TYPES.LINKED_COMPANIES)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const orders = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.ORDERS)}
					key={'clinic-2'}
					value={this.props.statistics[STATISTIC_TYPES.ORDERS]}
					icon={this.getIcon(STATISTIC_TYPES.ORDERS)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const expense = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.PAID_AMOUNT)}
					value={this.props.statistics[STATISTIC_TYPES.PAID_AMOUNT]}
					key={'clinic-3'}
					icon={this.getIcon(STATISTIC_TYPES.PAID_AMOUNT)}
					// The payments to different labs may be in different currencies.
					// That's why we show only float number here, not a currency.
					displayType={DISPLAY_TYPES.FLOAT}
				/>
			);
			return [suppliers, orders, expense];
		}
		if (isGlobalAdmin(this.props.account)) {
			const users = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.USERS)}
					value={this.props.statistics[STATISTIC_TYPES.USERS]}
					key={'global-1'}
					icon={this.getIcon(STATISTIC_TYPES.USERS)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const practices = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.CLINICS)}
					value={this.props.statistics[STATISTIC_TYPES.CLINICS]}
					key={'global-2'}
					icon={this.getIcon(STATISTIC_TYPES.CLINICS)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const labs = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.LABS)}
					value={this.props.statistics[STATISTIC_TYPES.LABS]}
					key={'global-3'}
					icon={this.getIcon(STATISTIC_TYPES.LABS)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const orders = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.ORDERS)}
					key={'global-4'}
					value={this.props.statistics[STATISTIC_TYPES.ORDERS]}
					icon={this.getIcon(STATISTIC_TYPES.ORDERS)}
					displayType={DISPLAY_TYPES.INTEGER}
				/>
			);
			const profit = (
				<StatisticsItem
					description={this.getDescription(STATISTIC_TYPES.PAID_AMOUNT)}
					value={this.props.statistics[STATISTIC_TYPES.PAID_AMOUNT]}
					key={'global-5'}
					icon={this.getIcon(STATISTIC_TYPES.PAID_AMOUNT)}
					// The cumulative payments across the site are in different currencies.
					// So we only show float number here, not a price
					displayType={DISPLAY_TYPES.FLOAT}
				/>
			);
			return [users, practices, labs, orders, profit];
		}
	};

	getIcon = (type) => {
		const icons = {
			[STATISTIC_TYPES.USERS]: faUsers,
			[STATISTIC_TYPES.PAID_AMOUNT]: faCoins,
			[STATISTIC_TYPES.ORDERS]: faTasks,
			[STATISTIC_TYPES.CLINICS]: faHospitalAlt,
			[STATISTIC_TYPES.LABS]: faFlask
		};

		if (type === STATISTIC_TYPES.LINKED_COMPANIES || type === STATISTIC_TYPES.USERS) {
			return icons[STATISTIC_TYPES.USERS];
		}
		return icons[type];
	};

	getDescription = (type) => {
		const { account } = this.props;
		if (registeredInClinic(account)) {
			if (type === STATISTIC_TYPES.LINKED_COMPANIES) {
				return i18n.t('translation:dashboard.statistics.suppliers');
			}
			if (type === STATISTIC_TYPES.PAID_AMOUNT) {
				return i18n.t('translation:dashboard.statistics.expense');
			}
		}
		if (isLabAdmin(account)) {
			if (type === STATISTIC_TYPES.LINKED_COMPANIES) {
				return i18n.t('translation:dashboard.statistics.clients');
			}
			if (type === STATISTIC_TYPES.PAID_AMOUNT) {
				return i18n.t('translation:dashboard.statistics.profit');
			}
		}
		if (type === STATISTIC_TYPES.PAID_AMOUNT) {
			return i18n.t('translation:dashboard.statistics.profit');
		}
		if (type === STATISTIC_TYPES.ORDERS) {
			return i18n.t('translation:dashboard.statistics.orders');
		}
		if (type === STATISTIC_TYPES.USERS) {
			return i18n.t('translation:dashboard.statistics.users');
		}
		if (type === STATISTIC_TYPES.CLINICS) {
			return i18n.t('translation:dashboard.statistics.practices');
		}
		if (type === STATISTIC_TYPES.LABS) {
			return i18n.t('translation:dashboard.statistics.labs');
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		statistics: state.statistics,
		settings: state.settings,
		currentLab: state.labs.currentLab
	};
};

export default connect(mapStateToProps, {
	getStatistics,
	getLabDetails
})(Statistics);
