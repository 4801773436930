import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
	attemptLogin,
	hideMessageModal,
	isAccessingSiteForFirstTime,
	markSiteAsAlreadyAccessed,
	showMessageModal,
	toggleLogin
} from '../../../actions';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import { formUtils } from '../../../utils/formUtils';
import i18n from '../../../i18n';
import { PAGES_PATHS } from '../../../utils/constants';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';

class Login extends Component {
	componentDidMount = () => {
		this.props.isAccessingSiteForFirstTime().then(() => {
			this.showPolicyModal();
		});
	};

	showPolicyModal = () => {
		if (this.props.accessingSiteForFirstTime) {
			const renderContentAsHtml = true;
			this.props.showMessageModal(
				i18n.t('translation:policy.termsOfUseHeader'),
				this.getCookiePolicyPopupHtml(),
				[this.getCloseButton()],
				renderContentAsHtml
			);
		}
	};

	getCookiePolicyPopupHtml = () => {
		return (
			'<p class="modal-description-as-html">' +
			i18n.t('translation:policy.termsOfUsePopupText') +
			'</p><ul><li><a href="' +
			i18n.t('translation:policy.generalTermsUrl') +
			'" target="_blank" rel="noopener noreferrer">' +
			i18n.t('translation:policy.generalTermsLinkText') +
			'</a></li><li><a href="' +
			i18n.t('translation:policy.cookiePolicyUrl') +
			'" target="_blank" rel="noopener noreferrer">' +
			i18n.t('translation:policy.cookiePolicyLinkText') +
			'</a></li><li><a href="' +
			i18n.t('translation:policy.privacyPolicyUrl') +
			'" target="_blank" rel="noopener noreferrer">' +
			i18n.t('translation:policy.privacyPolicyLinkText') +
			'</a></li></ul>'
		);
	};

	getCloseButton = () => {
		return (
			<DefaultButton
				title={i18n.t('translation:common.buttons.agree')}
				onClick={() => {
					this.props.markSiteAsAlreadyAccessed();
					this.props.hideMessageModal();
				}}
			/>
		);
	};

	render() {
		return (
			<Fragment>
				<form
					className="authentication-form blue-labels"
					onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<h2>{i18n.t('translation:auth.signIn')}</h2>
					<hr className="full-width-element" />
					{Fields.commonFields.userNameOrEmail()}
					{Fields.commonFields.password()}
					{this.renderIsCapsLockActive()}

					<DefaultButton
						onPress={this.onFormSubmit}
						title={i18n.t('translation:auth.signIn')}
					/>
					<Link to={PAGES_PATHS.SIGN_UP} className="text-button mt-xs">
						{i18n.t('translation:auth.newRegistration')}
					</Link>
					<a
						href={i18n.t('translation:policy.generalTermsUrl')}
						target="_blank"
						rel="noopener noreferrer"
						className="text-button-underline mt-xs">
						{i18n.t('translation:auth.termsOfUse')}
					</a>
				</form>
				<Link
					to={PAGES_PATHS.FORGOTTEN_PASSWORD}
					className="text-button-underline mt-s mb-m">
					{i18n.t('translation:auth.forgotYourPassword')}
				</Link>
			</Fragment>
		);
	}

	renderIsCapsLockActive = () => {
		return (
			<ReactIsCapsLockActive>
				{(active) =>
					active ? (
						<span className="d-block text-center mt-s blue-label">
							{i18n.t('translation:common.capsLockActive')}
						</span>
					) : (
						<Fragment />
					)
				}
			</ReactIsCapsLockActive>
		);
	};

	onFormSubmit = (formValues) => {
		const { userNameOrEmail, password } = formValues;
		this.props.attemptLogin(userNameOrEmail, password);
	};
}

const signInForm = reduxForm({ form: 'signInForm', validate: formUtils.validateLogin })(Login);

const mapStateToProps = (state) => {
	return {
		settings: state.settings,
		accessingSiteForFirstTime: state.login.accessingSiteForFirstTime
	};
};

export default connect(mapStateToProps, {
	attemptLogin,
	toggleLogin,
	isAccessingSiteForFirstTime,
	markSiteAsAlreadyAccessed,
	showMessageModal,
	hideMessageModal
})(signInForm);
