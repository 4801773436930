export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const LOGOUT = 'LOGOUT';
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const ACCESSING_SITE_FOR_FIRST_TIME = 'ACCESSING_SITE_FOR_FIRST_TIME';
export const MARK_SITE_AS_ALREADY_ACCESSED = 'MARK_SITE_AS_ALREADY_ACCESSED';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const GET_SETTING_FROM_SERVER = 'GET_SETTING_FROM_SERVER';
export const GET_ALL_CURRENCY_CODES = 'GET_ALL_CURRENCY_CODES';
export const GET_USERS_WITH_PAGINATION = 'GET_USERS_WITH_PAGINATION';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const LOAD_TOKEN = 'LOAD_TOKEN';
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const GET_GLOBAL_PRODUCT = 'GET_GLOBAL_PRODUCT';
export const CLEAR_GLOBAL_PRODUCT = 'CLEAR_GLOBAL_PRODUCT';
export const GET_GLOBAL_INVENTORY = 'GET_GLOBAL_INVENTORY';
export const GET_LAB_INVENTORY = 'GET_LAB_INVENTORY';
export const GET_INDIVIDUAL_INVENTORY = 'GET_INDIVIDUAL_INVENTORY';
export const SET_PAGINATION = 'SET_PAGINATION';
export const CLEAR_PAGINATION = 'CLEAR_PAGINATION';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
export const CLEAR_CURRENT_ORDER = 'CLEAR_CURRENT_ORDER';
export const GET_USERS = 'GET_USERS';
export const SHOW_MESSAGE_MODAL = 'SHOW_MESSAGE_MODAL';
export const HIDE_MESSAGE_MODAL = 'HIDE_MESSAGE_MODAL';
export const SHOW_AVATAR_MODAL = 'SHOW_AVATAR_MODAL';
export const HIDE_AVATAR_MODAL = 'HIDE_AVATAR_MODAL';
export const GET_ORDER = 'GET_ORDER';
export const GET_SUPPORTED_PHOTO_EXTENSIONS = 'GET_SUPPORTED_PHOTO_EXTENSIONS';
export const GET_SUPPORTED_VIDEO_EXTENSIONS = 'GET_SUPPORTED_VIDEO_EXTENSIONS';
export const GET_ALL_SUPPORTED_FILE_EXTENSIONS = 'GET_ALL_SUPPORTED_FILE_EXTENSIONS';
export const GET_CLINICS_WITH_PAGINATION = 'GET_CLINICS_WITH_PAGINATION';
export const GET_CLINICS = 'GET_CLINICS';
export const GET_CLINIC_DETAILS = 'GET_CLINIC_DETAILS';
export const CLEAR_CURRENT_CLINIC = 'CLEAR_CURRENT_CLINIC';
export const GET_LABS_WITH_PAGINATION = 'GET_LABS_WITH_PAGINATION';
export const GET_LAB_DETAILS = 'GET_LAB_DETAILS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_FIELD = 'UPDATE_ORDER_FIELD';
export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const UPDATE_LAB = 'UPDATE_LAB';
export const ADD_LAB_LOCATION = 'ADD_LAB_LOCATION';
export const ADD_CLINIC_LOCATION = 'ADD_CLINIC_LOCATION';
export const UPDATE_LAB_LOCATION = 'UPDATE_LAB_LOCATION';
export const UPDATE_CLINIC_LOCATION = 'UPDATE_CLINIC_LOCATION';
export const DELETE_CLINIC_LOCATION = 'DELETE_CLINIC_LOCATION';
export const DELETE_LAB_LOCATION = 'DELETE_LAB_LOCATION';
export const GET_ALL_RECEIVED_AND_PENDING_INVITATIONS = 'GET_ALL_RECEIVED_AND_PENDING_INVITATIONS';
export const GET_ALL_SENT_USER_INVITATIONS = 'GET_ALL_SENT_USER_INVITATIONS';
export const SET_SIGN_UP_USER_EMAIL = 'SET_SIGN_UP_USER_EMAIL';
export const CLEAR_SIGN_UP_USER_EMAIL = 'CLEAR_SIGN_UP_USER_EMAIL';
export const ARE_CLINIC_AND_LAB_LINKED = 'ARE_CLINIC_AND_LAB_LINKED';
export const GET_ORDERS_WITH_PAGINATION = 'GET_ORDERS_WITH_PAGINATION';
export const GET_COLORS = 'GET_COLORS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const SWITCH_ORDER_ITEM_COLOR_SCHEME = 'SWITCH_ORDER_ITEM_COLOR_SCHEME';
export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const GET_STATISTICS = 'GET_STATISTICS';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const SET_SORT = 'SET_SORT';
export const CLEAR_SORT = 'CLEAR_SORT';
export const GET_GLOBAL_PRODUCT_PHASE_TEMPLATES = 'GET_GLOBAL_PRODUCT_PHASE_TEMPLATES';
export const GET_MODULE = 'GET_MODULE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_GENERAL_WORKING_HOURS = 'GET_GENERAL_WORKING_HOURS';
export const UPDATE_GENERAL_WORKING_HOURS = 'UPDATE_GENERAL_WORKING_HOURS';
export const GET_SHIFTS = 'GET_SHIFTS';
export const GET_AVAILABLE_STAFF = 'GET_AVAILABLE_STAFF';
export const GET_PRODUCT_TEMPLATES = 'GET_PRODUCT_TEMPLATES';
export const GET_CURRENT_TEMPLATE = 'GET_CURRENT_TEMPLATE';
export const GET_CURRENT_TEMPLATE_TASKS = 'GET_CURRENT_TEMPLATE_TASKS';
export const GET_CURRENT_TEMPLATE_STATUS = 'GET_CURRENT_TEMPLATE_STATUS';
export const CLEAR_TASKS_REDUCER = 'CLEAR_TASKS_REDUCER';
export const GET_STAFF = 'GET_STAFF';
export const GET_ABSENCE_TYPES = 'GET_ABSENCE_TYPES';
export const GET_HOLIDAYS = 'GET_HOLIDAYS';
export const SET_COMMON_REDUCER = 'SET_COMMON_REDUCER';
export const GET_CALENDAR_ORDERS = 'GET_CALENDAR_ORDERS';
export const GET_CALENDAR_STAFF = 'GET_CALENDAR_STAFF';
export const GET_CALENDAR_STAFF_TASKS = 'GET_CALENDAR_STAFF_TASKS';
export const CLEAR_CALENDAR_STAFF_TASKS_DATES = 'CLEAR_CALENDAR_STAFF_TASKS_DATES';
export const GET_CALENDAR_STAFF_TASKS_LAB_TECHNICIAN = 'GET_CALENDAR_STAFF_TASKS_LAB_TECHNICIAN';
export const GET_CALENDAR_ORDER_ITEMS = 'GET_CALENDAR_ORDER_ITEMS';
export const GET_DASHBOARD_CURRENT_ORDERS = 'GET_DASHBOARD_CURRENT_ORDERS';
export const GET_DASHBOARD_CURRENT_EMPLOYEE_TASKS = 'GET_DASHBOARD_CURRENT_EMPLOYEE_TASKS';
export const GET_DASHBOARD_MONTHLY_ORDERS = 'GET_DASHBOARD_MONTHLY_ORDERS';
export const LOAD_ACCOUNT_LAB_ID = 'LOAD_ACCOUNT_LAB_ID';
export const CLEAR_ACCOUNT_LAB_ID = 'CLEAR_ACCOUNT_LAB_ID';
export const GET_CALENDAR_STAFF_ORDERS = 'GET_CALENDAR_STAFF_ORDERS';

// Billing Settings
export const UPDATE_BILLING_SETTINGS_INIT_DOC_NUMBER = 'UPDATE_BILLING_SETTINGS';
export const UPDATE_BILLING_SETTINGS_TAX_TYPE = 'UPDATE_BILLING_SETTINGS_TAX_TYPE';
export const GET_BILLING_SETTINGS = 'GET_BILLING_SETTINGS';
export const CREATE_TAX_GROUP = 'CREATE_TAX_GROUP';
export const UPDATE_TAX_GROUP = 'UPDATE_TAX_GROUP';
export const DELETE_TAX_GROUP = 'DELETE_TAX_GROUP';
export const CREATE_OVERDUE_INTERVAL = 'CREATE_OVERDUE_INTERVAL';
export const UPDATE_OVERDUE_INTERVAL = 'UPDATE_OVERDUE_INTERVAL';
export const DELETE_OVERDUE_INTERVAL = 'DELETE_OVERDUE_INTERVAL';
export const GET_LAB_TAX_GROUPS = 'GET_LAB_TAX_GROUPS';

// Billing Documents
export const SEARCH_BILLING_DOCUMENTS = 'SEARCH_BILLING_DOCUMENTS';
export const PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS = 'PRE_CREATE_MODAL_SEARCH_BILLING_ORDERS';

export const GET_NEXT_BILLING_DOCUMENT_NUMBER = 'GET_NEXT_BILLING_DOCUMENT_NUMBER';
export const SEARCH_BILLING_ORDERS = 'SEARCH_BILLING_ORDERS';
export const PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS =
	'PRE_CREATE_MODAL_SEARCH_BILLING_DOCUMENTS';

export const GET_ORDER_INVOICE_DATA = 'GET_ORDER_INVOICE_DATA';
export const SET_ORDER_INVOICE_DATA = 'SET_ORDER_INVOICE_DATA';
export const CLEAR_ORDER_INVOICE_DATA = 'CLEAR_ORDER_INVOICE_DATA';
export const CLEAR_PRE_CREATE_MODAL = 'CLEAR_PRE_CREATE_MODAL';
export const GET_BILLING_DOCUMENT_DETAILS = 'GET_BILLING_DOCUMENT_DETAILS';
export const CLEAR_CURRENT_DOCUMENT = 'CLEAR_CURRENT_DOCUMENT';

//Transactions
export const SEARCH_TRANSACTIONS = 'SEARCH_TRANSACTIONS';
export const GET_CURRENT_DEBIT_DOCUMENTS = 'GET_CURRENT_DEBIT_DOCUMENTS';
export const GET_CURRENT_CREDIT_DOCUMENTS = 'GET_CURRENT_CREDIT_DOCUMENTS';

//Takings
export const GET_DEBTOR_DAYS = 'GET_DEBTOR_DAYS';
export const GET_DEBTOR_DAYS_DOCUMENTS = 'GET_DEBTOR_DAYS_DOCUMENTS';
export const CLEAR_DEBTOR_DAYS_DOCUMENTS = 'CLEAR_DEBTOR_DAYS_DOCUMENTS';

//Statements
export const SEARCH_STATEMENT = 'SEARCH_STATEMENT';
export const GET_STATEMENT_BALANCE = 'GET_STATEMENT_BALANCE';
