import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ForgottenPassword from '../pages/authentication/ForgottenPassword';
import NewPassword from '../pages/authentication/NewPassword';
import Login from '../pages/authentication/Login';
import SignUp from '../pages/authentication/SignUp';
import { PAGES_PATHS } from '../../utils/constants';
import NotFound from '../errors/NotFound';
import InternalServerError from '../errors/InternalServerError';
import UnhandledError from '../errors/UnhandledError';
import AuthenticationHeaderBar from '../common/AuthenticationHeaderBar';

class Unauthenticated extends React.Component {
	render() {
		return (
			<div className="authentication-container">
				<AuthenticationHeaderBar />
				<div className="authentication-body mt-m">
					<Switch>
						<Route path={PAGES_PATHS.SIGN_UP} exact component={SignUp} />
						<Route
							path={PAGES_PATHS.FORGOTTEN_PASSWORD}
							exact
							component={ForgottenPassword}
						/>
						<Route path={PAGES_PATHS.NEW_PASSWORD} exact component={NewPassword} />
						<Route path={PAGES_PATHS.HOME} exact component={Login} />
						<Route path="/500" exact component={InternalServerError} />
						<Route path="/404" component={NotFound} />
						<Route path="/unhandledError" exact component={UnhandledError} />
						<Route component={NotFound} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default Unauthenticated;
