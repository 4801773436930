import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import AvatarModal from '../../common/AvatarModal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../common/Fields';
import DefaultButton from '../../common/DefaultButton';
import i18n from '../../../i18n';
import { formUtils } from '../../../utils/formUtils';
import {
	avatarValidation,
	getBase64EncodedCroppedImage,
	getFileExtension,
	getFileLocation
} from '../../../utils/fileUtils';
import {
	getSettingFromServer,
	getSupportedPhotoExtensions,
	showAvatarModal,
	updateUser
} from '../../../actions';
import { isEmptyObject } from '../../../utils/appUtils';
import { SERVER_SETTING_NAMES } from '../../../utils/constants';

class UserModal extends React.Component {
	state = {
		entireImage: null,
		croppedImage: null,
		fileType: null,
		originalAvatarVisible: true,
		deleteAvatar: false,
		errorExists: false,
		errorMessage: ''
	};

	constructor(props) {
		super(props);
		this.imageRef = React.createRef();
	}

	componentDidMount() {
		this.props.getSupportedPhotoExtensions();
		this.props.getSettingFromServer(SERVER_SETTING_NAMES.USER_AVATAR_MAX_SIZE_IN_MB);

		Modal.setAppElement('#root');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const initialValues = nextProps.initialValues
				? nextProps.initialValues
				: {
						username: '',
						firstName: '',
						lastName: '',
						email: '',
						phone: ''
				  };
			this.props.initialize(initialValues);
		}
	}

	render() {
		const updateButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.props.handleSubmit(this.onFormSubmit)}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.closeModalResettingState();
				}}
				secondary
			/>
		);

		let customFieldProps = {};
		const { account } = this.props;
		const readOnlyTextFields = account.id !== this.props.userId;
		if (readOnlyTextFields) {
			customFieldProps = { readableInput: true };
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<form onSubmit={this.props.handleSubmit(this.onFormSubmit)}>
					<AvatarModal onSaveAvatar={this.onSaveAvatar} />
					<FontAwesomeIcon
						className="close-icon"
						icon={faTimes}
						onClick={() => {
							this.closeModalResettingState();
						}}
					/>
					<h2>{i18n.t('translation:common.editUserProfile')}</h2>
					{this.renderAvatar()}
					{Fields.commonFields.userName(customFieldProps)}
					{Fields.commonFields.email(customFieldProps)}
					{Fields.commonFields.firstName(customFieldProps)}
					{Fields.commonFields.lastName(customFieldProps)}
					{Fields.commonFields.phone(customFieldProps)}
					{this.renderError()}
					<div className="buttons modal-dialog-buttons-below-error-message">
						{updateButton}
						{cancelButton}
					</div>
				</form>
			</Modal>
		);
	}

	renderError = () => {
		if (this.state.errorExists) {
			return (
				<div className="align-items-start text-danger position-absolute modal-dialog-error-message">
					{this.state.errorMessage}
				</div>
			);
		}
	};

	renderAvatar = () => {
		const removeButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.delete')}
				danger
				onClick={(e) => {
					e.preventDefault();
					this.setState({
						croppedImage: null,
						originalAvatarVisible: false,
						deleteAvatar: true
					});
					// Hiding previous error message showing that the selected avatar file is too large
					this.hideErrorMessage();
				}}
			/>
		);
		const editButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.update')}
				onClick={(e) => {
					e.preventDefault();
					this.imageRef.current.click();
				}}
			/>
		);

		const imageSource = this.getImageSource();

		const buttons = imageSource ? (
			<div className="avatar-buttons">
				{editButton}
				{removeButton}
			</div>
		) : (
			<Fragment />
		);

		return (
			<div className="avatar-in-user-profile-form d-flex flex-column align-items-center mt-s">
				<label className="avatar-label">{i18n.t('translation:auth.avatar')}</label>

				<label htmlFor="image-upload">
					{imageSource ? (
						<img src={imageSource} alt="" className="avatar" />
					) : (
						<div className="avatar d-flex align-items-center justify-content-center">
							<FontAwesomeIcon icon={faPlus} className="avatar-icon" />
						</div>
					)}
				</label>
				<input
					ref={this.imageRef}
					type="file"
					id="image-upload"
					className="d-none"
					onChange={this.onAvatarFileSelected}
				/>
				{buttons}
			</div>
		);
	};

	onAvatarFileSelected = (e) => {
		this.hideErrorMessage();

		let file = e.target.files[0];

		if (!this.isSupportedImageFile(getFileExtension(file.name))) {
			const fileListText = this.props.photoFileExtensions.join(', ');
			const errorMessage =
				i18n.t('translation:orders.errors.notSupportedPhotoFile') + fileListText + '.';

			this.displayErrorMessage(errorMessage);

			return;
		}

		let reader = new FileReader();
		reader.onloadend = () => {
			const avatarMaxSizeInMbAsFloat = parseFloat(
				this.props.serverSettings[SERVER_SETTING_NAMES.USER_AVATAR_MAX_SIZE_IN_MB]
			);
			// Suppressing showing the error (in another modal that may be rendered behind the current, User modal).
			// Instead, showing the error in the current modal
			const showError = false;
			if (!avatarValidation(file, avatarMaxSizeInMbAsFloat, showError)) {
				const errorMessage = i18n.t('translation:common.errors.fileSizeError', {
					word1: avatarMaxSizeInMbAsFloat
				});
				this.displayErrorMessage(errorMessage);
				return;
			}

			const entireImage = reader.result;
			this.setState({
				entireImage,
				fileType: file.type
			});
			this.props.showAvatarModal(entireImage);
		};
		if (file) {
			reader.readAsDataURL(file);
		}
		e.target.value = null;
	};

	isSupportedImageFile(fileExtension) {
		return this.props.photoFileExtensions.includes(fileExtension.toLowerCase());
	}

	onSaveAvatar = async (croppedAreaPixels) => {
		var croppedImage = await getBase64EncodedCroppedImage(
			this.state.entireImage,
			croppedAreaPixels,
			this.state.fileType
		);
		this.setState({ originalAvatarVisible: false, croppedImage, entireImage: null });
	};

	onFormSubmit = (formValues) => {
		this.hideErrorMessage();

		this.props.updateUser(
			this.props.userId,
			this.createUserUpdateData(formValues),
			this.props.account,
			this.state.croppedImage,
			this.state.fileType,
			this.state.deleteAvatar,
			this.props.account.id,
			this.displayErrorMessage,
			this.props.closeModal
		);
	};

	getImageSource() {
		let imageSource = null;
		const avatarFileName = this.props.initialValues.avatarFileName;
		if (this.state.originalAvatarVisible && !isEmptyObject(avatarFileName)) {
			imageSource = getFileLocation(avatarFileName);
		} else if (this.state.croppedImage) {
			imageSource = this.state.croppedImage;
		} else {
			// Do nothing
		}
		return imageSource;
	}

	closeModalResettingState() {
		this.props.destroy();
		this.props.closeModal();
		this.setState({ originalAvatarVisible: true, errorExists: false, errorMessage: '' });
	}

	displayErrorMessage = (errorMessage) => {
		this.setState({ errorExists: true, errorMessage });
	};

	hideErrorMessage = (errorMessage) => {
		this.setState({ errorExists: false, errorMessage: '' });
	};

	createUserUpdateData({ username, email, firstName, lastName, phone }) {
		return { username, email, firstName, lastName, phone };
	}
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		serverSettings: state.serverSettings,
		photoFileExtensions: state.orders.photoFileExtensions
	};
};

const userModalForm = reduxForm({ form: 'userModalForm', validate: formUtils.validateUserModal })(
	UserModal
);

export default connect(mapStateToProps, {
	updateUser,
	showAvatarModal,
	getSettingFromServer,
	getSupportedPhotoExtensions
})(userModalForm);
