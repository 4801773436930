import DefaultButton from '../../../common/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../../i18n';
import React, { Fragment } from 'react';
import {
	change,
	destroy,
	getFormValues,
	reset,
	SubmissionError,
	submit,
	unregisterField
} from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import SelectTemplateForm from './SelectTemplateForm';
import AddTasksForm from './AddTasksForm';
import { parseItemTasks } from '../../../../utils/tableUtils';
import Table from '../../../common/Table';
import {
	addNewTemplate,
	calculateTaskTime,
	clearTaskReducers,
	getAllCalendarOrderItems,
	getOrderDetails,
	listPossibleAssignees,
	loadSelectedAssignee,
	updateLocalTemplates,
	updateOrderItemTasks
} from '../../../../actions';
import { isEmptyObject, isRegisteredFieldValue } from '../../../../utils/appUtils';
import moment from '../../../../utils/moment';
import _ from 'lodash';
import {
	FILTER_FIELDS,
	MULTI_MARK_DAYS_SORT_DIRECTORY,
	MULTI_MARK_DAYS_SORT_KEYS,
	PAGES_PATHS
} from '../../../../utils/constants';
import AddTemplateForm from './AddTemplateForm';
import TestDatesSection from './TestDatesSection';
import navigationUtils from '../../../../utils/navigationUtils';

class AddTasksModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			taskCounter: 0,
			tasks: [],
			currentSort: {
				sortKey: MULTI_MARK_DAYS_SORT_KEYS.START_DATE,
				sortDirection: MULTI_MARK_DAYS_SORT_DIRECTORY.DESC
			},
			isExcludedFromPlanning: false,
			isConflictSectionShown: false,
			saveWithConflict: false,
			newTemplateSectionShown: false,
			conflictSectionMsg: [],
			templateServerError: '',
			serverError: '',
			shownNotes: [],
			taskTemplate: null,
			readOnly: false,
			newTemplateData: {},
			goToCalendar: false,
			revertChanges: false,
			initialValuesTasks: []
		};
	}

	onTemplateChange = (taskTemplate) => {
		const templates = [...this.props.templates];
		for (let index in this.props.templates) {
			if (templates[index].id == taskTemplate) {
				if (templates[index].phases) {
					const tasks = [...templates[index].phases];
					let taskCounter = 0;
					const customTasks = [];
					tasks.forEach((task, index) => {
						const customTask = {};
						customTask.id = index + 1 + 'c';
						taskCounter = index + 1;
						customTask.name_0 = task.name;
						customTask.duration_0 = task.duration;
						customTask.duration = task.duration;
						customTasks.push(customTask);
					});

					this.setState({
						tasks: customTasks,
						taskCounter,
						taskTemplate
					});
				}
			} else if (taskTemplate == 0) {
				this.setState({
					tasks: [],
					taskTemplate
				});
			}
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentTasks !== this.props.currentTasks) {
			let refinedTasks = [];

			for (let task of this.props.currentTasks) {
				let currentTask = {};
				currentTask['id'] = task.id;
				currentTask['name'] = task.title;
				currentTask['startDateTime'] = task.startTime ? new Date(task.startTime) : null;
				currentTask['endDateTime'] = task.endTime ? new Date(task.endTime) : null;
				currentTask['duration'] = task.duration;
				currentTask['availableStaff'] = task.assignee ? [task.assignee.id] : [];
				currentTask['generalNote'] = task.generalNote;

				refinedTasks.push(currentTask);
			}

			refinedTasks.map((task, index) => {
				let assigneesDataProps = {};
				assigneesDataProps['taskHasDuration'] =
					task.duration !== null && task.duration !== '';
				assigneesDataProps['endTime'] = task.endDateTime
					? moment(task.endDateTime).format('YYYY-MM-DD HH:mm')
					: null;
				assigneesDataProps['startTime'] = task.startDateTime
					? moment(task.startDateTime).format('YYYY-MM-DD HH:mm')
					: null;

				this.props
					.listPossibleAssignees(assigneesDataProps, this.setServerError)
					.then((response) => {
						if (response) {
							task['staff'] = this.refineStaff(response);
							if (task.availableStaff) {
								this.props.change(
									'tableForm',
									'availableStaff_' + task.id,
									task.availableStaff
								);
							}
						}
					});
			});

			if (this.state.initialValuesTasks.length === 0) {
				this.setState({
					initialValuesTasks: this.props.currentTasks
				});
			}

			this.setState({
				tasks: refinedTasks
			});

			if (this.state.goToCalendar) {
				navigationUtils.navigate(PAGES_PATHS.CALENDAR_STAFF);
				this.setState({ goToCalendar: false });
			}
		}

		if (
			this.state.currentSort.sortDirection !== prevState.currentSort.sortDirection ||
			this.state.currentSort.sortKey !== prevState.currentSort.sortKey
		) {
			let tasks = [...prevState.tasks];
			const sortKey = this.state.currentSort.sortKey;
			const tableFormValues = this.props.tableFormValues;

			const refinedTasks = [];
			tasks.forEach((task, index) => {
				const customTask = {};
				customTask['id'] = task.id;
				customTask['name'] = tableFormValues['name_' + task.id]
					? tableFormValues['name_' + task.id]
					: null;
				customTask['availableStaff'] = tableFormValues['availableStaff_' + task.id]
					? [tableFormValues['availableStaff_' + task.id]]
					: [];
				customTask['duration'] = tableFormValues['duration_' + task.id]
					? tableFormValues['duration_' + task.id]
					: null;
				customTask['endDateTime'] = tableFormValues['endDateTime_' + task.id]
					? new Date(tableFormValues['endDateTime_' + task.id])
					: null;
				customTask['startDateTime'] = tableFormValues['startDateTime_' + task.id]
					? new Date(tableFormValues['startDateTime_' + task.id])
					: null;
				customTask['generalNote'] = tableFormValues['generalNote_' + task.id]
					? tableFormValues['generalNote_' + task.id]
					: null;
				customTask['staff'] = task.staff;

				refinedTasks.push(customTask);
			});

			const newElementIndex = tasks.findIndex(function (el, index) {
				return el.id.toString().endsWith('c') && el['name_0'] !== undefined;
			});

			if (newElementIndex !== -1) {
				tasks[newElementIndex] = {
					id: tasks[newElementIndex].id,
					name: tasks[newElementIndex]['name_0']
						? tasks[newElementIndex]['name_0']
						: tasks[newElementIndex]['name']
						? tasks[newElementIndex]['name']
						: null,
					availableStaff: tasks[newElementIndex]['availableStaff_0']
						? tasks[newElementIndex]['availableStaff_0']
						: tasks[newElementIndex]['availableStaff']
						? tasks[newElementIndex]['availableStaff']
						: null,
					duration: tasks[newElementIndex]['duration_0']
						? tasks[newElementIndex]['duration_0']
						: tasks[newElementIndex]['duration']
						? tasks[newElementIndex]['duration']
						: null,
					endDateTime: tasks[newElementIndex]['endDateTime_0']
						? moment(tasks[newElementIndex]['endDateTime_0']).format('YYYY-MM-DD HH:mm')
						: tasks[newElementIndex]['endDateTime']
						? moment(tasks[newElementIndex]['endDateTime']).format('YYYY-MM-DD HH:mm')
						: null,
					startDateTime: tasks[newElementIndex]['startDateTime_0']
						? moment(tasks[newElementIndex]['startDateTime_0']).format(
								'YYYY-MM-DD HH:mm'
						  )
						: tasks[newElementIndex]['startDateTime']
						? moment(tasks[newElementIndex]['startDateTime']).format('YYYY-MM-DD HH:mm')
						: null,
					generalNote: tasks[newElementIndex]['generalNote_0']
						? tasks[newElementIndex]['generalNote_0']
						: tasks[newElementIndex]['generalNote']
						? tasks[newElementIndex]['generalNote']
						: null
				};
			}

			refinedTasks.sort((a, b) => {
				const firstParam = new Date(a[sortKey]);
				const secondParam = new Date(b[sortKey]);

				if (this.state.currentSort.sortDirection === MULTI_MARK_DAYS_SORT_DIRECTORY.ASC) {
					return firstParam > secondParam ? -1 : 1;
				} else {
					return firstParam < secondParam ? -1 : 1;
				}
			});

			this.setState({ tasks: refinedTasks });
		}

		if (
			this.props.currentTemplate.id &&
			prevProps.currentTemplate.id !== this.props.currentTemplate.id &&
			!this.state.revertChanges
		) {
			this.props.change(
				'selectTemplateForm',
				'tasksTemplates',
				this.props.currentTemplate.id
			);
		}

		if (prevProps.isExcludedFromPlanning !== this.props.isExcludedFromPlanning) {
			this.setState({
				isExcludedFromPlanning: this.props.isExcludedFromPlanning
			});
		}

		if (prevState.tasks.length !== 0 && prevState.tasks.length !== this.state.tasks.length) {
			this.state.tasks.map((task, index) => {
				let assigneesDataProps = {};
				assigneesDataProps['taskHasDuration'] =
					task.duration !== null && task.duration !== '';
				assigneesDataProps['endTime'] = task.endDateTime
					? moment(task.endDateTime).format('YYYY-MM-DD HH:mm')
					: null;
				assigneesDataProps['startTime'] = task.startDateTime
					? moment(task.startDateTime).format('YYYY-MM-DD HH:mm')
					: null;

				if (prevState.tasks[index] !== this.state.tasks[index]) {
					this.props
						.listPossibleAssignees(assigneesDataProps, this.setServerError)
						.then((response) => {
							if (response) {
								task['staff'] = this.refineStaff(response);
								if (task.availableStaff) {
									this.props.change(
										'tableForm',
										'availableStaff_' + task.id,
										task.availableStaff
									);
								}
							}
						});
				}
			});
		}
	}

	render() {
		const { initialValues, isModalOpen, staff } = this.props;
		const { isExcludedFromPlanning } = this.state;

		const modalTitle = i18n.t('translation:taskManager.orders.planningTasks');

		return (
			<Modal
				isOpen={isModalOpen}
				className="custom-modal item-tasks-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				<SelectTemplateForm
					initialValues={initialValues}
					excludeFromPlanning={this.excludeFromPlanning}
					isExcludedFromPlanning={isExcludedFromPlanning}
					onTemplateChange={this.onTemplateChange}
					readOnly={this.state.readOnly}
				/>
				<TestDatesSection
					initialValues={initialValues}
					isExcludedFromPlanning={isExcludedFromPlanning}
				/>
				<AddTasksForm
					onSubmit={this.onAddTasks}
					isExcludedFromPlanning={isExcludedFromPlanning}
					refineStaff={this.refineStaff}
					readOnly={this.state.readOnly}
					listPossibleAssignees={this.props.listPossibleAssignees}
					setServerError={this.setServerError}
				/>
				<label className="blue-label mt-s" hidden={isExcludedFromPlanning}>
					{i18n.t('translation:taskManager.orders.tasks')}
				</label>
				{this.renderItemTasksTable()}
				<span className="align-items-start text-danger">{this.state.serverError}</span>
				<br />
				{this.renderNewTemplateForm()}
				<span className="align-items-start text-danger">
					{this.state.templateServerError}
				</span>
				{this.conflictSection()}
				{this.renderButtons()}
			</Modal>
		);
	}

	toggleNote = (id) => {
		let shownNotes = [...this.state.shownNotes];
		if (shownNotes.includes(id)) {
			_.remove(shownNotes, (el) => el == id);
			this.setState({ shownNotes });
		} else {
			shownNotes.push(id);
			this.setState({ shownNotes });
		}
	};

	renderButtons = () => {
		const { isExcludedFromPlanning, newTemplateSectionShown, isConflictSectionShown } =
			this.state;

		if (isConflictSectionShown) {
			return null;
		}

		const saveButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				key={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					isExcludedFromPlanning ? this.saveSingleItem() : this.props.submit('tableForm');
				}}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.cancel')}
				key={i18n.t('translation:taskManager.common.buttons.cancel')}
				onClick={this.revertChanges}
				secondary
			/>
		);

		const showNewTemplateButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.saveAsNewTemplate')}
				key={i18n.t('translation:common.buttons.removeFromPlanning')}
				onClick={() => {
					this.showNewTemplateSection();
				}}
				class="save-new-template"
			/>
		);

		const saveNewTemplateButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				key={i18n.t('translation:common.buttons.save')}
				onClick={() => {
					this.props.submit('addTemplateForm');
				}}
				class="save-new-template"
			/>
		);
		const cancelNewTemplateButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.cancel')}
				key={i18n.t('translation:common.buttons.cancel')}
				onClick={() => {
					this.showNewTemplateSection();
				}}
				secondary
			/>
		);

		if (newTemplateSectionShown) {
			return (
				<div className="buttons task-buttons">
					{saveNewTemplateButton} {cancelNewTemplateButton}
				</div>
			);
		} else if (isExcludedFromPlanning) {
			return (
				<div className="buttons task-buttons">
					{saveButton}
					{cancelButton}
				</div>
			);
		} else {
			return (
				<div className="buttons task-buttons">
					{saveButton}
					{showNewTemplateButton}
					{cancelButton}
				</div>
			);
		}
	};

	renderNewTemplateForm = () => {
		if (!this.state.newTemplateSectionShown) {
			return <Fragment />;
		}

		return <AddTemplateForm onSubmit={this.saveAsNewTemplate} />;
	};

	conflictSection = () => {
		if (!this.state.isConflictSectionShown) {
			return <Fragment />;
		}

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.no')}
				key={i18n.t('translation:taskManager.common.buttons.no')}
				onClick={this.setConflictSection}
				secondary
			/>
		);
		const yesButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.yes')}
				key={i18n.t('translation:taskManager.common.buttons.yes')}
				onClick={() => this.saveDataConflictSection(false)}
			/>
		);
		const yesToCalendarButton = (
			<DefaultButton
				title={i18n.t('translation:taskManager.common.buttons.toCalendar')}
				key={i18n.t('translation:taskManager.common.buttons.toCalendar')}
				onClick={() => this.saveDataConflictSection(true)}
			/>
		);

		const conflictsList =
			this.state.conflictSectionMsg.length > 0 ? (
				<ul>
					{this.state.conflictSectionMsg.map((item, index) => {
						return <li key={'conflict' + index}>{item}</li>;
					})}
				</ul>
			) : null;

		return (
			<div className="mb-s">
				<label className="orange-label mt-s">
					{i18n.t('translation:taskManager.orders.attention')}
				</label>
				<div className="conflict-section">
					<p>{i18n.t('translation:taskManager.orders.conflictsAppear')}</p>
					{conflictsList}
					<p>{i18n.t('translation:taskManager.orders.confirmConflicts')}</p>
					<div className="buttons task-buttons pb-0">
						{yesButton} {yesToCalendarButton} {cancelButton}
					</div>
				</div>
			</div>
		);
	};

	excludeFromPlanning = () => {
		this.props.change('selectTemplateForm', 'tasksTemplates', '');

		this.setState({
			taskCounter: 1,
			tasks: this.state.revertChanges ? this.state.initialValuesTasks : [],
			isExcludedFromPlanning: !this.state.isExcludedFromPlanning,
			serverError: ''
		});
	};

	renderItemTasksTable() {
		const { staff } = this.props;
		const { readOnly } = this.state;
		const columns = [
			i18n.t('translation:taskManager.common.table.name'),
			i18n.t('translation:taskManager.common.table.start'),
			i18n.t('translation:taskManager.common.table.end'),
			i18n.t('translation:taskManager.common.table.staff'),
			' '
		];

		const data = parseItemTasks(
			this.state.tasks,
			staff,
			this.removeTask,
			this.onChangeTimeField,
			this.state.shownNotes,
			this.toggleNote,
			readOnly
		);
		const tasks = this.state.tasks;

		let initialValues = {};

		for (let task of tasks) {
			for (let key in task) {
				const id = task.id;
				if (key.length > 2) {
					if (id.toString().endsWith('c')) {
						initialValues[key.replace(0, id)] = task[key];
					} else {
						initialValues[key + '_' + task.id] = task[key];
					}
				}
			}
		}

		const template = this.props.selectFormValues.tasksTemplates;

		initialValues.template = template;

		if (this.state.isExcludedFromPlanning) {
			return <Fragment />;
		}

		const tableFormValues = { ...this.props.tableFormValues };

		for (let field in tableFormValues) {
			if (tableFormValues[field] === null) {
				delete tableFormValues[field];
			}
		}

		const finalInitialValues =
			this.state.taskTemplate !== null &&
			this.props.selectFormValues.tasksTemplates !== this.state.taskTemplate
				? initialValues
				: { ...initialValues, ...tableFormValues };

		return (
			<Table
				columns={columns}
				data={data}
				onSubmit={this.onTableFormSubmit}
				initialValues={finalInitialValues}
				simpleInitialization={true}
				sortKeys={[
					0,
					MULTI_MARK_DAYS_SORT_KEYS.START_DATE,
					MULTI_MARK_DAYS_SORT_KEYS.END_DATE
				]}
				currentSort={this.state.currentSort}
				setCurrentSort={this.setCurrentSort}
				className={'table-tasks'}
			/>
		);
	}

	setCurrentSort = (sort) => {
		this.setState({ currentSort: sort });
		this.setState({ tasks: [] });
	};

	removeTask = (id) => {
		let tasks = this.state.tasks;
		_.remove(tasks, { id });
		this.setState({ tasks });
	};

	onTableFormSubmit = (formValues) => {
		let errors = {};
		for (let value in formValues) {
			const id = value.split('_')[1];
			if (id) {
				if (formValues['name_' + id].toString().length == 0) {
					errors['name_' + id] = ' ';
				}

				const endDateTimeField = formValues['endDateTime_' + id]
					? formValues['endDateTime_' + id]
					: null;
				const startDateTimeField = formValues['startDateTime_' + id]
					? formValues['startDateTime_' + id]
					: null;

				if (moment(endDateTimeField).isBefore(startDateTimeField)) {
					const error = i18n.t('translation:taskManager.errors.startDateErrorMsg');
					errors['startDateTime_' + id] = ' ';
					this.setServerError(error);
				} else if (moment(endDateTimeField).isSame(startDateTimeField)) {
					const error = i18n.t('translation:taskManager.errors.sameDateErrorMsg');
					errors['startDateTime_' + id] = ' ';
					this.setServerError(error);
				}
			}
		}

		for (let index in errors) {
			if (errors[index] == undefined) {
				delete errors[index];
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		let itemTasks = [];
		const tableFormValues = this.props.tableFormValues;

		for (let i in tableFormValues) {
			const currentTask = {};

			if (i.startsWith('name_')) {
				const id = i.split('_')[1];
				if (isRegisteredFieldValue(this.state.tasks, id)) {
					if (!id.endsWith('c')) {
						currentTask.id = id;
					}
					currentTask.title = tableFormValues[i];
					currentTask.startTime = tableFormValues['startDateTime_' + id]
						? moment(tableFormValues['startDateTime_' + id]).format('YYYY-MM-DD HH:mm')
						: null;
					currentTask.endTime = tableFormValues['endDateTime_' + id]
						? moment(tableFormValues['endDateTime_' + id]).format('YYYY-MM-DD HH:mm')
						: null;
					currentTask.duration = tableFormValues['duration_' + id]
						? tableFormValues['duration_' + id]
						: null;
					currentTask.generalNote = tableFormValues['generalNote_' + id];
					currentTask.assignee =
						tableFormValues['availableStaff_' + id] &&
						tableFormValues['availableStaff_' + id].length > 0
							? {
									id: Number(tableFormValues['availableStaff_' + id])
							  }
							: null;
					itemTasks.push(currentTask);
				}
			}
		}

		const templateId = this.props.selectFormValues.tasksTemplates;

		const orderId = this.props.currentOrder.id;
		const orderItemId = this.props.initialValues.id;
		const orderItemProductId = this.props.initialValues.productId;

		const data = {
			template:
				templateId !== 0
					? {
							id: templateId
					  }
					: null,
			tasks: itemTasks,
			isExcludedFromPlanning: false
		};

		const saveWithConflict = this.state.saveWithConflict;
		this.props
			.updateOrderItemTasks(
				orderId,
				orderItemId,
				data,
				this.setServerError,
				this.closeModal,
				saveWithConflict,
				this.updateTemplate,
				this.state.newTemplateData,
				orderItemProductId
			)
			.then((resp) => {
				if (this.props.allowGanttchart) {
					this.props.getAllCalendarOrderItems(
						this.props.account.labId,
						{ [FILTER_FIELDS.LAB_ORDER_ID_LIST]: [this.props.currentOrder.labOrderId] },
						true
					);
				}
			});
	};

	saveSingleItem = () => {
		const orderId = this.props.currentOrder.id;
		const orderItemId = this.props.initialValues.id;

		const data = {
			template: null,
			tasks: [],
			isExcludedFromPlanning: true
		};

		this.props.updateOrderItemTasks(
			orderId,
			orderItemId,
			data,
			this.setServerError,
			this.closeModal,
			false,
			this.updateTemplate
		);
	};

	onAddTasks = () => {
		const formValues = this.props.formValues;

		const id = this.state.taskCounter + 1 + 'c';
		formValues['id'] = id;

		let assigneesDataProps = {};
		assigneesDataProps['taskHasDuration'] = false;
		assigneesDataProps['startTime'] = formValues['startDateTime_0']
			? moment(formValues['startDateTime_0']).format('YYYY-MM-DD HH:mm')
			: null;
		assigneesDataProps['endTime'] = formValues['endDateTime_0']
			? moment(formValues['endDateTime_0']).format('YYYY-MM-DD HH:mm')
			: null;

		this.props.listPossibleAssignees(assigneesDataProps, this.setServerError).then((resp) => {
			if (resp) {
				for (let i in this.state.tasks) {
					const currentTask = this.state.tasks[i];
					if (currentTask.id == id) {
						currentTask['staff'] = this.refineStaff(resp);
					}
				}
			}
		});

		this.setState({
			tasks: [...this.state.tasks, { ...formValues, id: id, ['duration_' + id]: null }],
			taskCounter: this.state.taskCounter + 1,
			serverError: ''
		});

		this.props.reset('addTasksForm');
	};

	onChangeTimeField = (isStartTime, data, id) => {
		const labId = this.props.account.labId;
		const tableFormValues = this.props.tableFormValues;
		this.clearServerErrorMessage();
		// setting up durations from template
		for (let index in this.props.templates) {
			if (this.props.templates[index].id == this.props.selectFormValues.tasksTemplates) {
				const tasks = this.props.templates[index].phases;
				for (let i in tasks) {
					if (tasks[i].name == data.name && tasks[i].duration !== null) {
						data['duration'] = tasks[i].duration;
					}
				}
			}
		}

		delete data['name'];

		if (data['duration'] == null || data['duration'] == '0:00') {
			return;
		}

		let assigneesDataProps = {};
		assigneesDataProps['taskHasDuration'] = true;
		assigneesDataProps['startTime'] = data.startTime
			? moment(data.startTime).format('YYYY-MM-DD HH:mm')
			: tableFormValues['startDateTime_' + id]
			? moment(tableFormValues['startDateTime_' + id]).format('YYYY-MM-DD HH:mm')
			: null;
		assigneesDataProps['endTime'] = data.endTime
			? moment(data.endTime).format('YYYY-MM-DD HH:mm')
			: tableFormValues['endDateTime_' + id]
			? moment(tableFormValues['endDateTime_' + id]).format('YYYY-MM-DD HH:mm')
			: null;

		this.props.calculateTaskTime(
			labId,
			isStartTime,
			data,
			(response) => {
				const startDateTimeField = tableFormValues['startDateTime_' + id]
					? tableFormValues['startDateTime_' + id]
					: null;
				const endDateTimeField = tableFormValues['endDateTime_' + id]
					? tableFormValues['endDateTime_' + id]
					: null;

				if (isStartTime && endDateTimeField == null) {
					this.props.change('tableForm', 'endDateTime_' + id, new Date(response.endTime));
					assigneesDataProps['startTime'] = moment(data.startTime).format(
						'YYYY-MM-DD HH:mm'
					);
					assigneesDataProps['endTime'] = moment(response.endTime).format(
						'YYYY-MM-DD HH:mm'
					);
				} else if (!isStartTime && startDateTimeField == null) {
					this.props.change(
						'tableForm',
						'startDateTime_' + id,
						new Date(response.startTime)
					);
					assigneesDataProps['startTime'] = moment(response.startTime).format(
						'YYYY-MM-DD HH:mm'
					);
					assigneesDataProps['endTime'] = moment(data.endTime).format('YYYY-MM-DD HH:mm');
				}

				this.listPossibleAssignees(assigneesDataProps, id);
			},
			this.setServerError
		);
	};

	listPossibleAssignees = (assigneesDataProps, taskId) => {
		this.props.listPossibleAssignees(assigneesDataProps, this.setServerError).then((resp) => {
			if (resp) {
				this.props.change('tableForm', 'staff_' + taskId, this.refineStaff(resp));
				for (let i in this.state.tasks) {
					const currentTask = this.state.tasks[i];
					if (currentTask.id == taskId) {
						currentTask['staff'] = this.refineStaff(resp);
					}
				}
			}
		});
	};

	updateTemplate = (data) => {
		if (!isEmptyObject(this.state.newTemplateData)) {
			const tasks = [];
			const updatedData = {};

			if (data && data.tasks.length > 0) {
				data.tasks.map((item) => {
					const currentTask = {};
					currentTask['name'] = item.title;
					currentTask['duration'] = item.duration;
					tasks.push(currentTask);
				});
			}

			for (let value in this.state.newTemplateData) {
				if (value == 'phases') {
					updatedData[value] = tasks;
				} else if (value != 'isGlobalTemplate' && value != 'totalDuration') {
					updatedData[value] = this.state.newTemplateData[value];
				}
			}

			for (let i = 0; i < updatedData.phases.length; i++) {
				if (updatedData.phases[i]['name'] == data.tasks[i]['title']) {
					updatedData.phases[i]['id'] = this.state.newTemplateData.phases[i]['id'];
				}
			}

			this.props.updateLocalTemplates(
				updatedData.id,
				updatedData,
				this.props.account.labId,
				this.props.initialValues.productId
			);
		}
	};

	revertChanges = () => {
		const { tableFormValues, currentTemplate, isExcludedFromPlanning, currentTasks } =
			this.props;
		this.props.change('selectTemplateForm', 'tasksTemplates', currentTemplate.id);

		const initialTasks = this.state.initialValuesTasks;
		for (let i in initialTasks) {
			const value = initialTasks[i];
			const id = value.id;
			tableFormValues['name_' + id] = value.title ? value.title : null;
			tableFormValues['startDateTime_' + id] = value.startTime
				? new Date(value.startTime)
				: null;
			tableFormValues['endDateTime_' + id] = value.endTime ? new Date(value.endTime) : null;
			tableFormValues['generalNote_' + id] = value.generalNote ? value.generalNote : null;
			tableFormValues['availableStaff_' + id] = value.assignee ? [value.assignee.id] : [];
		}

		let refinedTasks = [];

		for (let task of currentTasks) {
			const currentTask = {};
			currentTask['id'] = task.id;
			currentTask['name'] = task.title;
			currentTask['startDateTime'] = task.startTime ? new Date(task.startTime) : null;
			currentTask['endDateTime'] = task.endTime ? new Date(task.endTime) : null;
			currentTask['duration'] = task.duration;
			currentTask['availableStaff'] = task.assignee ? [task.assignee.id] : [];
			currentTask['generalNote'] = task.generalNote;

			refinedTasks.push(currentTask);
		}

		refinedTasks.map((task, index) => {
			let assigneesDataProps = {};
			assigneesDataProps['taskHasDuration'] = task.duration !== null && task.duration !== '';
			assigneesDataProps['endTime'] = task.endDateTime
				? moment(task.endDateTime).format('YYYY-MM-DD HH:mm')
				: null;
			assigneesDataProps['startTime'] = task.startDateTime
				? moment(task.startDateTime).format('YYYY-MM-DD HH:mm')
				: null;

			this.props
				.listPossibleAssignees(assigneesDataProps, this.setServerError)
				.then((response) => {
					if (response) {
						task['staff'] = this.refineStaff(response);
						if (task.availableStaff) {
							this.props.change(
								'tableForm',
								'availableStaff_' + task.id,
								task.availableStaff
							);
						}
					}
				});
		});

		this.setState({
			revertChanges: !this.state.revertChanges,
			serverError: '',
			tasks: refinedTasks,
			isExcludedFromPlanning: isExcludedFromPlanning
		});
	};

	refineStaff = (staff) => {
		const filteredData = [];
		staff.map(function (item, key) {
			const filteredItem = {
				id: item.id,
				name: item.fullName
			};
			filteredData.push(filteredItem);
		});

		return filteredData;
	};

	closeModal = () => {
		this.props.closeModal();
		this.resetState();
		this.props.reset('addTasksForm');
		this.props.clearTaskReducers();

		const currentOrderId = this.props.currentOrder.id;
		this.props.getOrderDetails(currentOrderId, true);
	};

	resetState = () => {
		this.setState({
			taskCounter: 1,
			tasks: [],
			currentSort: {
				sortKey: MULTI_MARK_DAYS_SORT_KEYS.START_DATE,
				sortDirection: MULTI_MARK_DAYS_SORT_DIRECTORY.DESC
			},
			isExcludedFromPlanning: false,
			isConflictSectionShown: false,
			saveWithConflict: false,
			newTemplateSectionShown: false,
			conflictSectionMsg: [],
			serverError: '',
			templateServerError: '',
			shownNotes: [],
			taskTemplate: null,
			readOnly: false,
			newTemplateData: {},
			revertChanges: false,
			initialValuesTasks: []
		});
	};

	setServerError = (serverError, isConflict, isTemplateError) => {
		if (isConflict) {
			this.setState({
				conflictSectionMsg: serverError,
				isConflictSectionShown: true,
				saveWithConflict: false,
				readOnly: true
			});
		} else if (isTemplateError) {
			this.setState({
				templateServerError: serverError,
				readOnly: false
			});
		} else {
			this.setState({ serverError });
		}
	};

	clearServerErrorMessage = () => {
		this.setState({ serverError: '' });
	};

	setConflictSection = () => {
		this.setState({
			isConflictSectionShown: !this.state.isConflictSectionShown,
			saveWithConflict: false,
			readOnly: false,
			newTemplateData: {}
		});
	};

	saveDataConflictSection = (goToCalendar) => {
		this.setState({
			saveWithConflict: true,
			readOnly: false,
			goToCalendar: goToCalendar ? goToCalendar : false
		});

		if (!isEmptyObject(this.state.newTemplateData)) {
			const templateFormValues = this.props.templateFormValues;
			this.saveAsNewTemplate(templateFormValues);
		} else {
			this.props.submit('tableForm');
		}
	};

	showNewTemplateSection = () => {
		this.setState({
			newTemplateSectionShown: !this.state.newTemplateSectionShown,
			templateServerError: ''
		});
	};

	saveAsNewTemplate = (formValues) => {
		let errors = {};

		if (isEmptyObject(formValues)) {
			errors['name'] = ' ';
		}

		for (let index in errors) {
			if (errors[index] == undefined) {
				delete errors[index];
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		let itemTasks = [];
		const tableFormValues = this.props.tableFormValues;

		for (let i in tableFormValues) {
			const currentTask = {};

			if (i.startsWith('name_')) {
				const id = i.split('_')[1];
				if (isRegisteredFieldValue(this.state.tasks, id)) {
					currentTask.name = tableFormValues[i];
					currentTask.duration = tableFormValues['duration_' + id]
						? tableFormValues['duration_' + id]
						: '0:00';
					itemTasks.push(currentTask);
				}
			}
		}

		const isTemplateDefault = formValues['isDefault'] ? formValues['isDefault'] : false;

		const data = {
			name: formValues['name'],
			isDefault: isTemplateDefault,
			phases: itemTasks
		};

		const labId = this.props.account.labId;
		const productId = this.props.initialValues.productId;

		this.props.addNewTemplate(
			labId,
			productId,
			data,
			this.submitTableFormData,
			this.setServerError
		);
	};

	submitTableFormData = (data) => {
		this.setState({
			templateServerError: '',
			newTemplateData: data
		});
		this.props.submit('tableForm');
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		staff: state.tasks.labStaff,
		templates: state.tasks.templates,
		currentTemplate: state.tasks.currentTemplate,
		currentTasks: state.tasks.currentTasks,
		isExcludedFromPlanning: state.tasks.isExcludedFromPlanning,
		formValues: getFormValues('addTasksForm')(state) || {},
		tableFormValues: getFormValues('tableForm')(state) || {},
		selectFormValues: getFormValues('selectTemplateForm')(state) || {},
		templateFormValues: getFormValues('addTemplateForm')(state) || {},
		colors: state.orders.colors,
		currentOrder: state.orders.currentOrder
	};
};

export default connect(mapStateToProps, {
	updateOrderItemTasks,
	calculateTaskTime,
	clearTaskReducers,
	addNewTemplate,
	listPossibleAssignees,
	getAllCalendarOrderItems,
	updateLocalTemplates,
	loadSelectedAssignee,
	getOrderDetails,
	reset,
	change,
	submit,
	destroy,
	unregisterField
})(AddTasksModal);
