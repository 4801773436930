import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fields } from '../../../../common/Fields';
import i18n from '../../../../../i18n';
import Modal from 'react-modal';
import { getFormValues, reduxForm, SubmissionError } from 'redux-form';
import DefaultButton from '../../../../common/DefaultButton';
import { isEmptyObject, isGlobalAdmin, isNumeric } from '../../../../../utils/appUtils';
import { removeWorkdays, setWorkdays } from '../../../../../actions';
import { connect } from 'react-redux';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	MULTI_MARK_DAYS_SORT_DIRECTORY,
	MULTI_MARK_DAYS_SORT_KEYS,
	WORKDAY_TYPES
} from '../../../../../utils/constants';
import moment from '../../../../../utils/moment';
import { formUtils } from '../../../../../utils/formUtils';
import Table from '../../../../common/Table';

class MarkDaysModal extends React.Component {
	state = {
		commonError: '',
		currentSort: {
			sortKey: MULTI_MARK_DAYS_SORT_KEYS.USER_NAME,
			sortDirection: MULTI_MARK_DAYS_SORT_DIRECTORY.DESC
		},
		staff: []
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.isModalOpen !== this.props.isModalOpen &&
			this.props.isModalOpen &&
			isEmptyObject(this.props.initialValues.labTechnicianOptions)
		) {
			this.props.initialize({ fields: '' });
		}
		if (prevProps.staff !== this.props.staff) {
			this.setState({ staff: [...this.props.staff].filter((el) => el.isActive) });
		}
		if (this.state.currentSort.sortDirection !== prevState.currentSort.sortDirection) {
			const staff = [...prevState.staff];
			staff.sort((a, b) => {
				if (this.state.currentSort.sortDirection === MULTI_MARK_DAYS_SORT_DIRECTORY.ASC) {
					return a.userName.toLowerCase() > b.userName.toLowerCase() ? -1 : 1;
				} else {
					return a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : 1;
				}
			});
			this.setState({ staff });
		}
	}

	render() {
		const isReadOnly = isGlobalAdmin(this.props.account);
		const readOnlyClass = isReadOnly ? ' read-only-field' : '';

		const editButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				onClick={this.props.handleSubmit(this.onSubmit)}
				isReadOnly={isReadOnly}
			/>
		);

		const deleteButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.delete')}
				key={i18n.t('translation:common.buttons.delete')}
				onClick={this.props.handleSubmit(this.onRemove)}
				danger={isReadOnly ? false : 'danger'}
				isReadOnly={isReadOnly}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.onCloseModal();
				}}
				secondary
			/>
		);

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.onCloseModal();
					}}
				/>
				<h2>{i18n.t('translation:taskManager.common.markDays')}</h2>
				<div className={'row' + readOnlyClass}>
					<div className="col-12 d-flex mt-s">
						{Fields.commonFields.shiftTypeAndAbsence({
							options: this.getOptions(),
							className: 'first'
						})}
						{Fields.commonFields.start({ className: 'w-auto ml-m position-relative' })}
						{Fields.commonFields.end({
							className: 'w-auto ml-s mr-s position-relative'
						})}
					</div>
				</div>
				{this.renderPerformers()}
				{Fields.commonFields.commonNote({ className: 'w-100 mt-s' + readOnlyClass })}
				{this.renderCommonError()}
				<div className="buttons">
					{editButton}
					{deleteButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	setCommonError = (commonError) => {
		this.setState({ commonError });
	};

	onCloseModal = () => {
		this.setState({
			commonError: '',
			currentSort: {
				sortKey: MULTI_MARK_DAYS_SORT_KEYS.USER_NAME,
				sortDirection: MULTI_MARK_DAYS_SORT_DIRECTORY.DESC
			}
		});
		this.props.destroy();
		this.props.closeModal();
	};

	onRemove = () => {
		const formValues = this.props.formValues;

		let errors = formUtils.validateStaffRemoveDays(formValues);

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		const data = { ...formValues };

		if (!isEmptyObject(this.props.initialValues.labTechnicianOptions)) {
			data.userIdList = [this.props.initialValues.performer];
		} else {
			const userIdList = [];
			const tableFormValues = this.props.tableFormValues;
			for (let index in tableFormValues) {
				if (tableFormValues[index]) {
					userIdList.push(index.split('_')[1]);
				}
			}

			if (isEmptyObject(userIdList)) {
				this.setState({
					commonError: i18n.t('translation:taskManager.common.noPerformersError')
				});
				return;
			}
			data.userIdList = userIdList;
		}

		delete data.labTechnicianOptions;
		delete data.performer;
		delete data.shiftAndAbsence;

		data.startDate = moment(data.startDate).format(DEFAULT_BACKEND_DATE_FORMAT);
		data.endDate = moment(data.endDate).format(DEFAULT_BACKEND_DATE_FORMAT);

		this.props.removeWorkdays(data, this.onCloseModal, this.getStaff, this.setCommonError);
	};

	onSubmit = () => {
		const formValues = this.props.formValues;

		let errors = formUtils.validateStaffMarkDays(formValues);

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		const data = { ...formValues };

		if (!isEmptyObject(this.props.initialValues.labTechnicianOptions)) {
			data.userIdList = [this.props.initialValues.performer];
		} else {
			const userIdList = [];
			const tableFormValues = this.props.tableFormValues;
			for (let index in tableFormValues) {
				if (tableFormValues[index]) {
					userIdList.push(index.split('_')[1]);
				}
			}

			if (isEmptyObject(userIdList)) {
				this.setState({
					commonError: i18n.t('translation:taskManager.common.noPerformersError')
				});
				return;
			}
			data.userIdList = userIdList;
		}
		if (!isNumeric(formValues.shiftAndAbsence)) {
			data.workdayType = formValues.shiftAndAbsence;
			data.shiftId = null;
		} else {
			data.workdayType = WORKDAY_TYPES.WORK_SHIFT;
			data.shiftId = formValues.shiftAndAbsence;
		}

		delete data.labTechnicianOptions;
		delete data.performer;
		delete data.shiftAndAbsence;

		data.startDate = moment(data.startDate).format(DEFAULT_BACKEND_DATE_FORMAT);
		data.endDate = moment(data.endDate).format(DEFAULT_BACKEND_DATE_FORMAT);

		this.props.setWorkdays(data, this.onCloseModal, this.getStaff, this.setCommonError);
	};

	renderCommonError = () => {
		return this.state.commonError ? (
			<span className="align-items-start text-danger">{this.state.commonError}</span>
		) : null;
	};

	getStaff = () => {
		this.props.getStaff();
	};

	getOptions = () => {
		const shiftsAndAbsences = this.props.shiftsAndAbsences;
		if (!isEmptyObject(shiftsAndAbsences)) {
			const options = shiftsAndAbsences
				.filter((shiftAndAbsence) => shiftAndAbsence)
				.map((shiftAndAbsence) => {
					return {
						name: shiftAndAbsence.name,
						value: shiftAndAbsence.shiftId || shiftAndAbsence.absenceType
					};
				});
			options.unshift({ name: '', value: '' });
			return options;
		}
	};

	renderPerformers = () => {
		if (!isEmptyObject(this.props.initialValues.labTechnicianOptions)) {
			return Fields.commonFields.performer({
				readableInput: true,
				className: 'w-100 mt-s',
				options: [this.props.initialValues.labTechnicianOptions]
			});
		} else {
			return this.renderUsersTable();
		}
	};

	setCurrentSort = (sort) => {
		this.setState({ currentSort: sort });
		this.setState({ staff: [] });
	};

	renderUsersTable = () => {
		const staff = this.state.staff;
		const data = staff.map((staff) => [
			staff.userName,
			{
				...Fields.tableFields.isUserMarked({
					id: staff.userId,
					value: true
				})
			}
		]);
		const columns = [
			i18n.t('translation:common.table.name'),
			i18n.t('translation:common.table.action')
		];

		return (
			<Fragment>
				<label className="input-label mt-s">
					{i18n.t('translation:taskManager.common.performers')}
				</label>
				<Table
					className={'table-languages mark-days-table'}
					columns={columns}
					data={[...data]}
					sortKeys={[MULTI_MARK_DAYS_SORT_KEYS.USER_NAME]}
					currentSort={this.state.currentSort}
					setCurrentSort={this.setCurrentSort}
				/>
			</Fragment>
		);
	};
}

const markDaysForm = reduxForm({
	form: 'markDaysForm',
	enableReinitialize: true
})(MarkDaysModal);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		tableFormValues: getFormValues('tableForm')(state),
		formValues: getFormValues('markDaysForm')(state)
	};
};

export default connect(mapStateToProps, {
	setWorkdays,
	removeWorkdays
})(markDaysForm);
