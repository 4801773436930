import React from 'react';

class DefaultButton extends React.Component {
	render() {
		let buttonClass = 'default-button';

		buttonClass += this.props.isReadOnly ? ' read-only-field' : '';
		buttonClass += this.props.danger ? ' danger-button' : '';
		buttonClass += this.props.secondary ? ' secondary-button' : '';
		buttonClass += this.props.short ? ' short-default-button' : '';
		buttonClass += this.props.extraWide ? ' extra-wide-default-button' : '';
		buttonClass += this.props.fullWidth ? ' full-width' : '';
		buttonClass += this.props.disabled ? ' disabled-button' : '';
		buttonClass += this.props.secondaryDisabled ? ' disabled-secondary-button' : '';

		if (this.props.class) {
			buttonClass = this.props.class;
		}

		const type = this.props.type ? this.props.type : 'submit';

		return (
			<button
				type={type}
				className={buttonClass}
				onClick={this.props.disabled ? () => {} : this.props.onClick}
				title={this.props.tooltip}>
				<span>{this.props.title}</span>
			</button>
		);
	}
}

export default DefaultButton;
