import React, { Fragment } from 'react';
import i18n from '../../../../../i18n';
import Header from '../../../../common/Header';
import {
	clearReducers,
	getAllCalendarOrderItems,
	getCalendarOrderItems,
	getCalendarOrders,
	getGeneralWorkingHours,
	getLabDetails
} from '../../../../../actions';
import { connect } from 'react-redux';
import { getLabId, isAllNull, isEmptyObject, isGlobalAdmin } from '../../../../../utils/appUtils';
import {
	getDropdownData,
	parseCalendarOrdersChart,
	parseCalendarOrdersNestedTable
} from '../../../../../utils/tableUtils';
import _ from 'lodash';
import moment from '../../../../../utils/moment';
import {
	DEFAULT_BACKEND_DATE_FORMAT,
	FILTER_FIELDS,
	ORDER_STATUS
} from '../../../../../utils/constants';
import { getFormValues, initialize } from 'redux-form';
import Filters from './Filters';
import queryString from 'query-string';
import Ganttchart from './Ganttchart';

class CalendarOrders extends React.Component {
	state = {
		date: moment(),
		dropdown: {},
		filters: {},
		isQuickViewModalOpen: false,
		isEditModalOpen: false,
		order: {},
		currentOrderItem: {},
		currentTask: {},
		limitedOrders: false
	};

	componentDidMount() {
		const labId = getLabId(this.props.location, this.props.account);
		this.props.getLabDetails(labId);
		this.props.getGeneralWorkingHours(labId);

		if (!isEmptyObject(this.props.filters)) {
			this.setFilters(this.props.filters);
			return;
		}

		if (!isGlobalAdmin(this.props.account)) {
			let params = queryString.parse(this.props.location.search);
			if (!isEmptyObject(params)) {
				if (params[FILTER_FIELDS.LAB_ORDER_ID_LIST]) {
					params[FILTER_FIELDS.LAB_ORDER_ID_LIST] = params[
						FILTER_FIELDS.LAB_ORDER_ID_LIST
					].replaceAll(';', ',');
				}
				this.setFilters(params);
				if (params[FILTER_FIELDS.START_DATE]) {
					params[FILTER_FIELDS.START_DATE] = new Date(params[FILTER_FIELDS.START_DATE]);
				}
				if (params[FILTER_FIELDS.END_DATE]) {
					params[FILTER_FIELDS.END_DATE] = new Date(params[FILTER_FIELDS.END_DATE]);
				}
				this.props.initialize('calendarOrdersFiltersForm', params);
				return;
			}
		}
		const orderStatuses = [ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.ACCEPTED];
		const startDate = new Date(moment(this.state.date).startOf('month'));
		const endDate = new Date(moment(this.state.date).endOf('month'));
		this.props.initialize('calendarOrdersFiltersForm', { orderStatuses, startDate, endDate });
		this.setFilters({ orderStatuses, startDate, endDate });
	}

	componentWillUnmount() {
		this.props.clearReducers();
	}

	render() {
		const chartData = parseCalendarOrdersChart(this.props.orders.orders);
		const nestedTableData = parseCalendarOrdersNestedTable(
			this.props.orders.orders,
			this.openQuickViewModal,
			this.openEditModal,
			this.props.account
		);
		const columns = [
			i18n.t('translation:taskManager.calendar.orders.table.orderItemTask'),
			i18n.t('translation:taskManager.common.table.start'),
			i18n.t('translation:taskManager.common.table.end'),
			i18n.t('translation:taskManager.common.table.time'),
			i18n.t('translation:common.table.status'),
			''
		];

		const simpleTitle = i18n.t('translation:taskManager.calendar.orders.title');
		const headerTitle = isGlobalAdmin(this.props.account)
			? this.props.currentLab.name + ' - ' + simpleTitle
			: simpleTitle;
		const backButtonShown = isGlobalAdmin(this.props.account) ? true : false;

		return (
			<Fragment>
				<Header title={headerTitle} backButton={backButtonShown} />
				<Filters setFilters={this.setFilters} location={this.props.location} />
				<div className="position-relative mt-s mb-m">
					<div className="position-absolute gantchart fixed-header-chart gantchart-height d-flex">
						<Ganttchart
							workingHours={this.props.workingHours}
							isEditModalOpen={this.state.isEditModalOpen}
							closeEditModal={this.closeEditModal}
							currentTask={this.state.currentTask}
							currentOrderItem={this.state.currentOrderItem}
							order={this.state.order}
							orders={this.props.orders}
							isQuickViewModalOpen={this.state.isQuickViewModalOpen}
							closeQuickViewModal={this.closeQuickViewModal}
							filters={this.state.filters}
							dropdown={this.state.dropdown}
							chartData={chartData}
							nestedTableData={nestedTableData}
							setDropdown={this.setDropdown}
							onGlobalDropdownClick={this.onGlobalDropdownClick}
							account={this.props.account}
							columns={columns}
							getCalendarOrders={this.getCalendarOrders}
							onAddMonth={this.addMonth}
							onSubtractMonth={this.subtractMonth}
							language={this.props.language}
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	getCalendarOrders = (labId, data) => {
		this.props.getCalendarOrders(labId, data, this.setLimitedOrders);
	};

	setLimitedOrders = (limitedOrders) => {
		this.setState({ limitedOrders });
	};

	closeQuickViewModal = () => {
		this.setState({ isQuickViewModalOpen: false });
	};

	closeEditModal = () => {
		this.setState({ isEditModalOpen: false });
	};

	openQuickViewModal = (order) => {
		this.setState({ isQuickViewModalOpen: true, order });
	};

	openEditModal = (currentTask, currentOrderItem, order) => {
		this.setState({ isEditModalOpen: true, currentTask, order, currentOrderItem });
	};

	setFilters = (filters) => {
		const refinedFilters = { ...filters };
		refinedFilters[FILTER_FIELDS.LAB_ORDER_ID_LIST] = refinedFilters[
			FILTER_FIELDS.LAB_ORDER_ID_LIST
		]
			? refinedFilters[FILTER_FIELDS.LAB_ORDER_ID_LIST].split(',')
			: null;
		const startDate = refinedFilters.startDate
			? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
			: isAllNull(filters)
			? moment(this.state.date).startOf('month').format(DEFAULT_BACKEND_DATE_FORMAT)
			: null;
		const endDate = refinedFilters.endDate
			? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
			: isAllNull(filters)
			? moment(this.state.date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT)
			: null;
		const dueDateFrom = refinedFilters.dueDateFrom
			? moment(refinedFilters.dueDateFrom).format(DEFAULT_BACKEND_DATE_FORMAT)
			: null;
		const dueDateTo = refinedFilters.dueDateTo
			? moment(refinedFilters.dueDateTo).format(DEFAULT_BACKEND_DATE_FORMAT)
			: null;
		const labId = getLabId(this.props.location, this.props.account);

		this.getCalendarOrders(labId, {
			...refinedFilters,
			startDate,
			endDate,
			dueDateFrom,
			dueDateTo
		});
		this.setState({ filters: refinedFilters, dropdown: [] });
	};

	onGlobalDropdownClick = (onDropdownClick) => {
		const refinedFilters = this.state.filters;
		// <-------- If there are any filters, the request will be executed with dates
		// from the filters otherwise it will set dates as null.
		// This is needed in case the order is not in the range of current month - the previous logic
		// was based on that. If filters dates were null we used current month as range
		// ------>
		let startDate = moment(this.state.date)
			.startOf('month')
			.format(DEFAULT_BACKEND_DATE_FORMAT);
		let endDate = moment(this.state.date).endOf('month').format(DEFAULT_BACKEND_DATE_FORMAT);
		let dueDateFrom = refinedFilters.dueDateFrom
			? moment(refinedFilters.dueDateFrom).format(DEFAULT_BACKEND_DATE_FORMAT)
			: null;
		let dueDateTo = refinedFilters.dueDateTo
			? moment(refinedFilters.dueDateTo).format(DEFAULT_BACKEND_DATE_FORMAT)
			: null;

		if (!isEmptyObject(refinedFilters) && !isAllNull(refinedFilters)) {
			startDate = refinedFilters.startDate
				? moment(refinedFilters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
				: null;
			endDate = refinedFilters.endDate
				? moment(refinedFilters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
				: null;
		}

		const labId = getLabId(this.props.location, this.props.account);
		this.props.getAllCalendarOrderItems(
			labId,
			{
				...refinedFilters,
				startDate,
				endDate,
				dueDateFrom,
				dueDateTo
			},
			false,
			onDropdownClick,
			this.state.limitedOrders
		);
	};

	setDropdown = (elementIndex, isExpanded, data) => {
		const orderName = data && data[0] && data[0].props ? data[0].props.children : '';

		if (data && orderName.startsWith(i18n.t('translation:orders.orderNumber')) && isExpanded) {
			const labOrderId = orderName.replace(/\D/g, '');
			const filters = this.props.filters;

			// <-------- If there are any filters, the request will be executed with dates
			// from the filters otherwise it will set dates as null.
			// This is needed in case the order is not in the range of current month - the previous logic
			// was based on that. If filters dates were null we used current month as range
			// ------>
			let startDate = moment(this.state.date)
				.startOf('month')
				.format(DEFAULT_BACKEND_DATE_FORMAT);
			let endDate = moment(this.state.date)
				.endOf('month')
				.format(DEFAULT_BACKEND_DATE_FORMAT);

			if (!isEmptyObject(filters) && !isAllNull(filters)) {
				startDate = filters.startDate
					? moment(filters.startDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: null;
				endDate = filters.endDate
					? moment(filters.endDate).format(DEFAULT_BACKEND_DATE_FORMAT)
					: null;
			}

			const order = _.find(
				this.props.orders.orders,
				(order) => order.labOrderId == labOrderId
			);
			this.props.getCalendarOrderItems(
				order.id,
				startDate,
				endDate,
				filters.taskStatuses,
				filters.taskAssigneeIdList
			);
		}
		this.setState({ dropdown: getDropdownData(elementIndex, this.state.dropdown, isExpanded) });
	};

	addMonth = () => {
		const date = this.state.date.add(1, 'M');
		this.setState({ date });
	};

	subtractMonth = () => {
		const date = this.state.date.subtract(1, 'M');
		this.setState({ date });
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		orders: state.calendar.orders,
		filters: getFormValues('calendarOrdersFiltersForm')(state) || {},
		workingHours: state.workingHours,
		currentLab: state.labs.currentLab,
		language: state.settings.language
	};
};

export default connect(mapStateToProps, {
	getCalendarOrders,
	getCalendarOrderItems,
	getGeneralWorkingHours,
	getAllCalendarOrderItems,
	getLabDetails,
	clearReducers,
	initialize
})(CalendarOrders);
