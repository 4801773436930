import React, { Fragment } from 'react';
import BackButton from './BackButton';

class Header extends React.Component {
	render() {
		return (
			<div className="header col-12 p-0">
				<div className="align-self-center align-items-center d-flex">
					{this.renderBackButton()}
					<h1>{this.props.title}</h1>
					{this.renderTrigger()}
				</div>
				<div className="buttons ml-auto flex-nowrap">
					<div className={'d-flex justify-content-center align-items-center'}>
						{this.renderButtons()}
					</div>
				</div>
			</div>
		);
	}

	renderBackButton = () => {
		if (this.props.backButton) {
			return <BackButton />;
		}
	};

	renderTrigger = () => {
		if (this.props.onTrigger) {
			return this.props.isOpen ? (
				<span className="arrow-up ml-xs" onClick={this.props.onTrigger} />
			) : (
				<span className="arrow-down ml-xs" onClick={this.props.onTrigger} />
			);
		}
	};

	renderButtons = () => {
		if (this.props.buttons) {
			return this.props.buttons.map((button, index) => (
				<div key={index + 'tableButton'} className="button-ml-xs">
					{button}
				</div>
			));
		}
	};
}

export default Header;
