import React from 'react';
import i18n from '../../../../../../../i18n';
import { Fields } from '../../../../../../common/Fields';
import {
	fetchClinicDetails,
	getLinkedClinicsWithoutPagination
} from '../../../../../../../actions';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { BILLING_DOCUMENT_TYPES, INVOICE_FIELD_NAME } from '../../../../../../../utils/constants';
import { getClinicIdByName, getSelectedTaxType } from '../../../../../../../utils/appUtils';
import { BILLING_DOCUMENT_MODE } from '../../BillingDocumentModal';
import {
	getCanEditRecipientSupplierName,
	isReadableInput
} from '../../../../../../../utils/billingUtils';

class RecipientSection extends React.Component {
	componentDidMount() {
		if (this.props.clinicId !== undefined) {
			const documentMode = this.props.mode;
			const documentType = this.props.documentType;

			const isCreateModeAndInvoice =
				documentMode === BILLING_DOCUMENT_MODE.CREATE &&
				(documentType === BILLING_DOCUMENT_TYPES.FREE_INVOICE ||
					documentType === BILLING_DOCUMENT_TYPES.ORDER_INVOICE);

			if (isCreateModeAndInvoice) {
				this.setClinicDetailsForInvoice(this.props.clinicId);
				return;
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.currentDocument !== this.props.currentDocument &&
			this.props.currentDocument
		) {
			this.fillInitialData();
			return;
		}

		const documentMode = this.props.mode;
		const documentType = this.props.documentType;

		const isCreateModeAndCreditOrDebitNote =
			documentMode === BILLING_DOCUMENT_MODE.CREATE &&
			(documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
				documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE);

		if (prevProps.invoiceData !== this.props.invoiceData) {
			if (isCreateModeAndCreditOrDebitNote) {
				this.setClinicDetailsForCreditOrDebitNote();
				return;
			}
		}
	}

	fillInitialData() {
		const clinicId = this.props.currentDocument.clinicId;
		const recipientAddress = this.props.currentDocument.receiver.address;
		const recipientName = this.props.currentDocument.receiver.name;
		const recipientOwner = this.props.currentDocument.receiver.owner;
		const recipientUIC = this.props.currentDocument.receiver.uic;
		const recipientVAT = this.props.currentDocument.receiver.vatNumber;

		this.changeClinicDetails(
			recipientUIC,
			recipientVAT,
			recipientAddress,
			recipientOwner,
			recipientName,
			clinicId
		);
	}

	changeClinicDetails(uic, vatNumber, registeredOffice, owner, legalName, id) {
		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.RECIPIENT_ID, id);

		this.props.change(
			'freeInvoiceForm',
			INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME,
			`${id}_%_${legalName}`
		);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.RECIPIENT_UIC, uic);

		this.props.change('freeInvoiceForm', INVOICE_FIELD_NAME.RECIPIENT_VAT_NUMBER, vatNumber);

		this.props.change(
			'freeInvoiceForm',
			INVOICE_FIELD_NAME.RECIPIENT_REGISTRATION_ADDRESS,
			registeredOffice
		);

		this.props.change(
			'freeInvoiceForm',
			INVOICE_FIELD_NAME.RECIPIENT_RESPONSIBLE_PERSON,
			owner
		);
	}

	renderRecipientCompanyName() {
		const canEditRecipientName = getCanEditRecipientSupplierName(
			this.props.documentType,
			this.props.mode
		);

		if (
			this.props.documentType === BILLING_DOCUMENT_TYPES.CREDIT_NOTE ||
			this.props.documentType === BILLING_DOCUMENT_TYPES.DEBIT_NOTE
		) {
			let recipientCompanyName = '';

			if (this.props.formValues[INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME]) {
				recipientCompanyName =
					this.props.formValues[INVOICE_FIELD_NAME.RECIPIENT_COMPANY_NAME].split(
						'_%_'
					)[1];
			}

			return (
				<div className="d-flex align-items-center pt-1 pb-3 data-row">
					<label className={'left-label col-4 pl-0'}>
						{i18n.t('translation:billingModule.invoicing.common.companyName')}
					</label>
					<div>{recipientCompanyName}</div>
				</div>
			);
		}

		if (this.props.mode !== BILLING_DOCUMENT_MODE.CREATE) {
			return (
				<div className="d-flex align-items-center pt-1 pb-3 data-row">
					<label className={'left-label col-4 pl-0'}>
						{i18n.t('translation:billingModule.invoicing.common.companyName')}
					</label>
					<div>{this.props.currentDocument?.receiver?.name}</div>
				</div>
			);
		}

		const options = [{ name: '', value: '' }];

		this.props.clinics?.forEach((clinic) =>
			options.push({
				name: clinic.legalName,
				value: `${clinic.id}_%_${clinic.legalName}`
			})
		);

		return Fields.billingModule.invoicing.recipientCompanyName({
			additionalLabelClass: 'col-4 pl-0',
			options: options,
			onChange: (event) => {
				this.onRecipientChange(event.target.value);
			},
			readableInput: !canEditRecipientName
		});
	}

	render() {
		return (
			<div className="col-6 i-section">
				<div className="d-flex align-items-center w-100 custom-table-header">
					{i18n.t('translation:billingModule.invoicing.recipient')}
				</div>
				<div className="section mt-0 pt-3 pb-0">
					{this.renderRecipientCompanyName()}
					{Fields.billingModule.invoicing.uic(INVOICE_FIELD_NAME.RECIPIENT_UIC, {
						additionalLabelClass: 'col-4 pl-0',
						readableInput: isReadableInput(this.props.mode)
					})}
					{Fields.billingModule.invoicing.vatNumber(
						INVOICE_FIELD_NAME.RECIPIENT_VAT_NUMBER,
						{
							label: i18n.t('translation:billingModule.invoicing.common.vatNumber', {
								taxType: getSelectedTaxType(this.props.billingSettings.taxType)
							}),
							additionalLabelClass: 'col-4 pl-0',
							readableInput: isReadableInput(this.props.mode)
						}
					)}
					{Fields.billingModule.invoicing.registrationAddress(
						INVOICE_FIELD_NAME.RECIPIENT_REGISTRATION_ADDRESS,
						{
							additionalLabelClass: 'col-4 pl-0',
							readableInput: isReadableInput(this.props.mode)
						}
					)}
					{Fields.billingModule.invoicing.responsiblePerson(
						INVOICE_FIELD_NAME.RECIPIENT_RESPONSIBLE_PERSON,
						{
							additionalLabelClass: 'col-4 pl-0',
							readableInput: isReadableInput(this.props.mode)
						}
					)}
				</div>
			</div>
		);
	}

	setClinicDetailsForInvoice(clinicID) {
		const clinicDetails = fetchClinicDetails(clinicID).then((res) => {
			const clinic = res;
			const { uic, vatNumber, registeredOffice, owner, id, legalName } = clinic;

			this.changeClinicDetails(uic, vatNumber, registeredOffice, owner, legalName, id);
		});
	}

	setClinicDetailsForCreditOrDebitNote() {
		const receiver = this.props.invoiceData[0].receiver;
		const { uic, vatNumber, address, owner, name } = receiver;

		this.changeClinicDetails(uic, vatNumber, address, owner, name, this.props.clinicId);
	}

	onRecipientChange = (value) => {
		if (value === '') {
			this.changeClinicDetails('', '', '', '', '', '');
			return;
		}

		const selectedClinicID = value.split('_%_')[0];

		const documentType = this.props.documentType;
		const { FREE_INVOICE, ORDER_INVOICE } = BILLING_DOCUMENT_TYPES;

		if (documentType === FREE_INVOICE || documentType === ORDER_INVOICE) {
			this.setClinicDetailsForInvoice(selectedClinicID);
		} else {
			this.setClinicDetailsForCreditOrDebitNote();
		}
	};
}

const mapStateToProps = (state) => {
	return {
		account: state.account,
		clinics: state.clinics.clinics,
		billingSettings: state.billingSettings,
		formValues: getFormValues('freeInvoiceForm')(state),
		currentDocument: state.billingDocument.currentDocument,
		invoiceData: state.billingDocument.invoiceData
	};
};

export default connect(mapStateToProps, {
	getLinkedClinicsWithoutPagination,
	change
})(RecipientSection);
