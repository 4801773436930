import React, { Fragment } from 'react';
import Header from '../../../common/Header';
import i18n from '../../../../i18n';
import { connect } from 'react-redux';
import StatementFilters from './StatementFilters';
import DefaultButton from '../../../common/DefaultButton';
import { parseInvoicingCurrency } from '../../../../utils/tableUtils';
import StatementTable from './StatementTable';
import {
	BALANCE_FORM_FIELD,
	PAGES_PATHS,
	PAGINATION_TYPES,
	STATEMENT_BALANCE_TYPE
} from '../../../../utils/constants';
import {
	exportStatementsToExcelOrCsv,
	getBillingSettings,
	searchStatement,
	setPagination
} from '../../../../actions/index';
import { getFormValues } from 'redux-form';
import Pagination from '../../../common/Pagination';
import DropdownButton from '../../../common/DropdownButton';
import navigationUtils from '../../../../utils/navigationUtils';

class Statement extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.setCurrentPage(1);
		this.props.getBillingSettings(this.props.account.labId);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.page !== this.props.page) {
			this.props.searchStatement({
				page: this.props.page,
				filters: { ...this.props.formValues, labId: this.props.account.labId }
			});
		}
	}

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.STATEMENT, page);
	};

	onIncomeOutcomeChange = () => {
		this.props.searchStatement({
			page: this.props.page ?? 1,
			filters: { ...this.props.formValues, labId: this.props.account.labId }
		});
	};

	renderBalanceBlock(
		title,
		value,
		onClick,
		localizedCurrencyAbbreviation,
		currencySymbol,
		second
	) {
		return (
			<div className={'col-6 p-0'}>
				<div
					className="content d-flex col-11 justify-content-between mb-4"
					style={second ? { marginLeft: 'auto' } : {}}>
					<div className="align-items-center justify-content-start">
						<label className={'blue-label'}>{title}</label>
						<h1 style={{ fontSize: '1.2em', textTransform: 'none' }}>
							{parseInvoicingCurrency(
								value,
								localizedCurrencyAbbreviation,
								currencySymbol
							)}
						</h1>
					</div>
					<div className="align-items-center justify-content-end">
						<DefaultButton
							onClick={onClick}
							title={i18n.t('translation:billingModule.balance.buttons.view')}
							secondary
							type={'button'}
							disabled={
								!this.props.formValues ||
								!this.props.formValues[BALANCE_FORM_FIELD.PERIOD_DATE_TO] ||
								!this.props.formValues[BALANCE_FORM_FIELD.PERIOD_DATE_FROM]
							}
						/>
					</div>
				</div>
			</div>
		);
	}

	renderPagination = () => {
		const { content } = this.props.statement;
		if (content && content.length) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={this.props.totalElements}
					setCurrentPage={(page) => this.setCurrentPage(page)}
				/>
			);
		}
	};

	renderExportDocumentButton = () => {
		let buttons = [
			i18n.t('translation:billingModule.common.buttons.exportToExcel'),
			i18n.t('translation:billingModule.common.buttons.exportToCsv')
		];

		if (!this.props.statement || !this.props.statement?.content?.length) {
			buttons = [];
		}

		const title = i18n.t('translation:billingModule.common.buttons.export');

		return (
			<DropdownButton
				title={title}
				buttons={buttons}
				short={true}
				statusButton={false}
				isSecondButton={true}
				onButtonClick={(type) => {
					this.props.exportStatementsToExcelOrCsv(
						type,
						{
							...this.props.formValues
						},
						this.props.account.labId
					);
				}}
			/>
		);
	};

	render() {
		return (
			<Fragment>
				<Header
					title={i18n.t('translation:billingModule.balance.header')}
					buttons={[this.renderExportDocumentButton()]}
				/>
				<StatementFilters setCurrentPage={this.setCurrentPage} />
				<div className="d-flex col-12 p-0 justify-content-between data-section balance">
					{this.renderBalanceBlock(
						i18n.t('translation:billingModule.balance.openingBalance'),
						this.props.statement?.balance?.startBalance,
						() =>
							navigationUtils.navigate(PAGES_PATHS.BILLING_STATEMENT_OPENING, {
								dataType: STATEMENT_BALANCE_TYPE.OPENING
							}),
						this.props.statement?.balance?.localizedCurrencyAbbreviation,
						this.props.statement?.balance?.currencySymbol
					)}
					{this.renderBalanceBlock(
						i18n.t('translation:billingModule.balance.closingBalance'),
						this.props.statement?.balance?.endBalance,
						() =>
							navigationUtils.navigate(PAGES_PATHS.BILLING_STATEMENT_CLOSING, {
								dataType: STATEMENT_BALANCE_TYPE.CLOSING
							}),
						this.props.statement?.balance?.localizedCurrencyAbbreviation,
						this.props.statement?.balance?.currencySymbol,
						true
					)}
				</div>
				<StatementTable
					items={this.props.statement.content}
					isCurrentBalance={false}
					onIncomeOutcomeChange={this.onIncomeOutcomeChange}
				/>
				{this.renderPagination()}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		modules: state.modules,
		account: state.account,
		statement: state.billingStatement.statement,
		formValues: getFormValues('statementForm')(state),
		page: state.pagination[PAGINATION_TYPES.STATEMENT],
		totalElements: state.billingStatement.statement.totalElements
	};
};

export default connect(mapStateToProps, {
	exportStatementsToExcelOrCsv,
	setPagination,
	searchStatement,
	getBillingSettings
})(Statement);
