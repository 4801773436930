import React, { Fragment } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { formUtils } from '../../../../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../../../../common/Fields';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import DataRow from '../../../../common/DataRow';
import { getLabId, getOrdersStatuses, getTasksStatuses } from '../../../../../utils/appUtils';
import {
	getLabApprovedStaff,
	getLinkedClinicsWithoutPagination,
	setPagination
} from '../../../../../actions';
import { ORDER_STATUS } from '../../../../../utils/constants';
import _ from 'lodash';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Filters extends React.Component {
	state = { isFilterCollapsed: true };

	componentDidMount() {
		const labId = getLabId(this.props.location, this.props.account);

		this.props.getLabApprovedStaff(labId);
		this.props.getLinkedClinicsWithoutPagination(labId);
	}

	render() {
		return (
			<div className="col-12 p-0 data-section gantchart-filters">
				<div className="content filters pt-0">
					<form onSubmit={this.onFilterButtonClick}>
						{this.renderFirstFilterRow()}
						{this.renderSecondFilterRow()}
					</form>
				</div>
			</div>
		);
	}

	collapseExpandFilter = () => {
		this.setState({ isFilterCollapsed: !this.state.isFilterCollapsed });
	};

	renderTrigger = () => {
		return !this.state.isFilterCollapsed ? (
			<span
				className="arrow-up arrow-up-sub-header ml-xs"
				onClick={this.collapseExpandFilter}
			/>
		) : (
			<span
				className="arrow-down arrow-down-sub-header ml-xs"
				onClick={this.collapseExpandFilter}
			/>
		);
	};

	renderFirstFilterRow = () => {
		const performers = [];
		const users = this.props.users || [];
		for (let performer of users) {
			performers.push({
				name: performer.firstName + ' ' + performer.lastName,
				id: performer.id
			});
		}

		const options = getOrdersStatuses();
		_.remove(options, function (status) {
			return status.id === ORDER_STATUS.DRAFT;
		});

		return (
			<div className="row m-0 first-row">
				{Fields.filterFields.labTechnicianList({
					className: 'mr-m mt-s',
					options: performers
				})}

				<div className={'mt-s mr-m d-flex align-items-end'}>
					{Fields.filterFields.orderNumber({ className: 'small-input' })}
					<FontAwesomeIcon
						icon={faInfoCircle}
						className={'note-icon mb-xs ml-xs'}
						title={i18n.t(
							'translation:taskManager.calendar.orders.orderFieldDescription'
						)}
					/>
				</div>

				{Fields.filterFields.orderStatus({ className: 'mr-m mt-s', options })}

				{Fields.filterFields.dentist({ className: 'mr-m mt-s' })}
				<div className="filter-button mr-s">
					<DefaultButton
						title={i18n.t('translation:orders.buttons.filter')}
						onClick={this.onFilterButtonClick}
					/>
					{this.renderTrigger()}
				</div>
			</div>
		);
	};

	renderSecondFilterRow = () => {
		if (!this.state.isFilterCollapsed) {
			const clients = [];
			const clinics = this.props.clinics || [];
			for (let clinic of clinics) {
				clients.push({ name: clinic.name, id: clinic.id });
			}

			const assignees = [];
			const users = this.props.users || [];
			for (let performer of users) {
				assignees.push({
					name: performer.firstName + ' ' + performer.lastName,
					id: performer.id
				});
			}

			return (
				<Fragment>
					<div className="row m-0 second-row flex-wrap">
						{Fields.filterFields.clientList({
							className: 'mr-m mt-s',
							options: clients
						})}

						{Fields.filterFields.patientName({ className: 'mr-m mt-s' })}

						{Fields.filterFields.taskStatus({
							className: 'mr-m mt-s',
							options: getTasksStatuses()
						})}

						{Fields.filterFields.taskAssigneeList({
							className: 'mr-m mt-s',
							options: assignees
						})}

						<DataRow
							className={'mt-s'}
							data={[
								{
									title: i18n.t(
										'translation:taskManager.common.fields.filters.taskDate'
									),
									value: this.renderIntervalFields(
										Fields.filterFields.dateFrom({
											className: 'mr-s d-block task-td mr-0',
											timeInput: false,
											dateInput: true,
											onlyTimeInput: false,
											dateTimeFormat: 'YYYY-MM-DD HH:mm'
										}),
										Fields.filterFields.dateTo({
											className: 'mr-s d-block task-td',
											timeInput: false,
											dateInput: true,
											onlyTimeInput: false,
											dateTimeFormat: 'YYYY-MM-DD HH:mm'
										})
									)
								}
							]}
							first
							noColumns
						/>
						<DataRow
							className={'mt-s'}
							data={[
								{
									title: i18n.t('translation:orders.fields.dueDate'),
									value: this.renderIntervalFields(
										Fields.filterFields.dueDateFrom({
											className: 'mr-s d-block task-td mr-0',
											timeInput: false,
											dateInput: true,
											onlyTimeInput: false,
											dateTimeFormat: 'YYYY-MM-DD HH:mm'
										}),
										Fields.filterFields.dueDateTo({
											className: 'mr-s d-block task-td',
											timeInput: false,
											dateInput: true,
											onlyTimeInput: false,
											dateTimeFormat: 'YYYY-MM-DD HH:mm'
										})
									)
								}
							]}
							first
							noColumns
						/>
					</div>
				</Fragment>
			);
		}
	};

	onFilterButtonClick = (e) => {
		e.preventDefault();
		this.props.handleSubmit((formValues) => {
			this.props.setFilters(formValues);
		})();
	};

	renderIntervalFields = (fieldFrom, fieldTo) => {
		return (
			<div className="d-flex">
				{fieldFrom}
				{fieldTo}
			</div>
		);
	};
}

const filtersForm = reduxForm({
	form: 'calendarOrdersFiltersForm',
	validate: formUtils.validateStaffFilters,
	destroyOnUnmount: false
})(Filters);

const mapStateToProps = (state) => {
	return {
		account: state.account,
		staff: state.staff,
		users: state.users.users.content,
		clinics: state.clinics.clinics,
		formValues: getFormValues('staffFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	getLabApprovedStaff,
	getLinkedClinicsWithoutPagination
})(filtersForm);
