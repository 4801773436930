import {
	CLEAR_CALENDAR_STAFF_TASKS_DATES,
	CLEAR_REDUCER,
	GET_CALENDAR_ORDER_ITEMS,
	GET_CALENDAR_ORDERS,
	GET_CALENDAR_STAFF,
	GET_CALENDAR_STAFF_ORDERS,
	GET_CALENDAR_STAFF_TASKS,
	GET_CALENDAR_STAFF_TASKS_LAB_TECHNICIAN
} from '../../actions/types';
import _ from 'lodash';

const initState = { orders: { orders: [] }, staff: [], calendarOrders: [], dates: {} };

const calendarReducer = (state = { ...initState }, action) => {
	if (action.type === GET_CALENDAR_ORDERS) {
		state.orders = { ...action.payload };
		return { ...state };
	}

	if (action.type === GET_CALENDAR_STAFF_ORDERS) {
		state.calendarOrders = action.payload;
		return { ...state };
	}

	if (action.type === GET_CALENDAR_ORDER_ITEMS) {
		const index = state.orders.orders.findIndex((order) => order.id == action.payload.orderId);
		state.orders.orders[index] = action.payload.data;
		state.orders.orders = [...state.orders.orders];
		state.orders = { ...state.orders };
		return { ...state };
	}

	if (action.type === GET_CALENDAR_STAFF) {
		state.staff = [...action.payload];
		return { ...state };
	}

	if (action.type === GET_CALENDAR_STAFF_TASKS) {
		let staff = _.find(state.staff, (staff) => staff.id == action.payload.userId);
		staff.tasks = action.payload.tasks ? [...action.payload.tasks] : [];
		state.staff = [...state.staff];

		return { ...state };
	}

	if (action.type === CLEAR_CALENDAR_STAFF_TASKS_DATES) {
		state.dates = {};
		return { ...state };
	}

	if (action.type === GET_CALENDAR_STAFF_TASKS_LAB_TECHNICIAN) {
		state.staff = [...action.payload];
		return { ...state };
	}

	if (action.type === CLEAR_REDUCER) {
		state = initState;
		return { ...state };
	}

	return state;
};

export default calendarReducer;
