import React, { Component, Fragment } from 'react';
import HeaderBar from '../common/HeaderBar';
import SideMenu from '../common/SideMenu';
import GlobalAdminRoutes from './GlobalAdminRoutes';
import LocalAdminRoutes from './LocalAdminRoutes';
import ClinicAdminRoutes from './ClinicAdminRoutes';
import DentistRoutes from './DentistRoutes';
import DentalTechnicianRoutes from './DentalTechnicianRoutes';
import { connect } from 'react-redux';
import {
	isGlobalAdmin,
	isLabTechnician,
	isLocalAdmin,
	registeredInClinic
} from '../../utils/appUtils';

class Main extends Component {
	renderRoutes = () => {
		if (isGlobalAdmin(this.props.account)) {
			return <GlobalAdminRoutes />;
		} else if (registeredInClinic(this.props.account)) {
			if (isLocalAdmin(this.props.account)) {
				return <ClinicAdminRoutes />;
			} else {
				return <DentistRoutes />;
			}
		} else {
			if (isLabTechnician(this.props.account)) {
				return <DentalTechnicianRoutes />;
			} else {
				return <LocalAdminRoutes />;
			}
		}
	};

	render = () => (
		<Fragment>
			<HeaderBar />
			<div className="container-fluid row flex-nowrap p-0 m-0 full-height">
				<div className="container-fluid p-0">
					<div className="d-flex flex-fill p-0 main-container full-height">
						<SideMenu />
						<div className="flex-column route-container full-height flex-fill">
							<div className="page-container flex-column flex-fill mb-m">
								{this.renderRoutes()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

const mapStateToProps = (state) => {
	return { account: state.account };
};

export default connect(mapStateToProps)(Main);
