import { getAxiosWithToken } from '../../utils/webApi';
import { showSuccessPopup } from '../../utils/appUtils';
import i18n from '../../i18n';
import { GET_SHIFTS } from '../types';

export const getShifts = (labId) => async (dispatch) => {
	const response = await getAxiosWithToken(false).get(`/lab/${labId}/shift/get-all`);
	let returnedData = response.data;

	if (returnedData.success) {
		dispatch({ type: GET_SHIFTS, payload: returnedData.data });
	}
};

export const createShift = (labId, data, setErrorMessage, closeModal) => async (dispatch) => {
	const response = await getAxiosWithToken(false, false).post(`/lab/${labId}/shift`, data);
	let returnedData = response.data;

	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.planner.successfullySavedShift'));
		dispatch(getShifts(labId));
		closeModal();
	} else {
		setErrorMessage(returnedData.userMessage);
	}
};

export const updateShift = (labId, data, setErrorMessage, closeModal) => async (dispatch) => {
	const response = await getAxiosWithToken(false, false).put(`/lab/${labId}/shift`, data);
	let returnedData = response.data;

	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.planner.successfullySavedShift'));
		dispatch(getShifts(labId));
		closeModal();
	} else {
		setErrorMessage(returnedData.userMessage);
	}
};

export const deleteShift = (labId, shiftId) => async (dispatch) => {
	const response = await getAxiosWithToken().delete(`/lab/${labId}/shift/${shiftId}`);
	let returnedData = response.data;

	if (returnedData.success) {
		showSuccessPopup(i18n.t('translation:taskManager.planner.successfullyDeletedShift'));
		dispatch(getShifts(labId));
	}
};
