import React, { Component } from 'react';
import {
	FILTER_FIELDS,
	PAGINATION_TYPES,
	TRANSACTION_FORM_FIELD,
	TRANSACTION_STATUS,
	TRANSACTION_TYPE
} from '../../../../utils/constants';
import i18n from '../../../../i18n';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import {
	customDateInput,
	MultipleSelectInput,
	selectInput,
	textInput
} from '../../../common/Fields';
import { formUtils } from '../../../../utils/formUtils';
import DefaultButton from '../../../common/DefaultButton';
import { connect } from 'react-redux';
import {
	getLinkedClinicsWithoutPagination,
	searchTransactions,
	setPagination
} from '../../../../actions';
import ClientsDropdownField from '../common/UI/ClientsDropdownField';

class IncomesOutcomesFilters extends Component {
	state = {
		isFilterCollapsed: true
	};

	componentDidMount() {
		this.props.getLinkedClinicsWithoutPagination(this.props.account.labId);
	}

	renderFilterInputs = () => {
		return (
			<>
				<div className="row pt-4 col-12 pl-0 pr-0 mr-0 ml-0">
					<div className="col-3 pl-0">{this.renderNumber()}</div>
					<div className="col-3">{this.renderClientsDropdown(this.props.clinics)}</div>
					<div className="col-3">{this.renderDocumentNumber()}</div>
					<div className="d-flex flex-row justify-content-end align-items-end col-3 pr-0">
						{this.renderFilterButton()}
						{this.renderFilterExpandCollapseTrigger()}
					</div>
				</div>
				{!this.state.isFilterCollapsed && (
					<>
						<div className="row pt-4 col-12 p-0">
							<div className="col-3 pr-0 mr-3">{this.renderStatus()}</div>
							{this.renderTypeInput()}
							{this.renderReferenceFilter()}
						</div>
						<div className="row pt-4 p-0">{this.renderDateOfIncomeInputs()}</div>
					</>
				)}
			</>
		);
	};

	renderDocumentNumber() {
		return (
			<>
				{this.renderNumberInput(
					FILTER_FIELDS.BILLING_DOCUMENT_NUMBER,
					i18n.t('translation:billingModule.incomesOutcomes.documentNumber')
				)}
			</>
		);
	}

	renderStatus() {
		const options = [
			{
				name: i18n.t('translation:billingModule.incomesOutcomes.statuses.completed'),
				id: TRANSACTION_STATUS.COMPLETED
			},
			{
				name: i18n.t('translation:billingModule.incomesOutcomes.statuses.canceled'),
				id: TRANSACTION_STATUS.CANCELED
			},
			{
				name: i18n.t('translation:billingModule.incomesOutcomes.statuses.pending'),
				id: TRANSACTION_STATUS.PENDING
			},
			{
				name: i18n.t('translation:billingModule.incomesOutcomes.statuses.partially'),
				id: TRANSACTION_STATUS.PARTIALLY
			}
		];

		return (
			<Field
				name={FILTER_FIELDS.STATUSES}
				label={i18n.t('translation:billingModule.common.table.status')}
				customProps={{
					className: 'mr-0',
					options: options
				}}
				component={MultipleSelectInput}
			/>
		);
	}

	renderClientsDropdown(clinics) {
		return (
			<ClientsDropdownField
				clients={clinics}
				name={'not_for_action_filter'}
				onChange={(event) => this.onClientChange(event.target)}
			/>
		);
	}

	renderNumber() {
		return this.renderNumberInput(
			FILTER_FIELDS.TRANSACTION_NUMBER_LAB,
			i18n.t('translation:billingModule.common.number')
		);
	}

	renderIntervalFields(label, firstField, secondField) {
		return (
			<div className="pl-3 col-12">
				<label>{label}</label>
				<div className="d-flex">
					{firstField}
					{secondField}
				</div>
			</div>
		);
	}

	renderFilterExpandCollapseTrigger = () => {
		const className = this.state.isFilterCollapsed
			? 'arrow-down arrow-down-sub-header'
			: 'arrow-up arrow-up-sub-header';

		return <span className={className} onClick={this.collapseExpandFilter} />;
	};

	collapseExpandFilter = () => {
		this.setState({ isFilterCollapsed: !this.state.isFilterCollapsed });
	};

	renderDateField(name, from) {
		const placeholder = from
			? i18n.t('translation:orders.fields.placeholders.from')
			: i18n.t('translation:orders.fields.placeholders.to');

		return (
			<Field
				name={name}
				customProps={{
					className: 'mr-s d-block',
					placeholder
				}}
				component={customDateInput}
			/>
		);
	}

	renderTypeInput() {
		const options = [
			{ name: '', value: null },
			{
				name: i18n.t('translation:billingModule.incomesOutcomes.income'),
				value: TRANSACTION_TYPE.DEBIT
			},
			{
				name: i18n.t('translation:billingModule.incomesOutcomes.outcome'),
				value: TRANSACTION_TYPE.CREDIT
			}
		];

		return (
			<Field
				name={FILTER_FIELDS.TYPE}
				label={i18n.t('translation:billingModule.common.type')}
				customProps={{
					className: 'mr-0 col-3',
					options: options
				}}
				component={selectInput}
			/>
		);
	}

	renderReferenceFilter() {
		return (
			<Field
				name={TRANSACTION_FORM_FIELD.TRANSACTION_REFERENCES_NOTES}
				label={i18n.t('translation:billingModule.incomeOutcomeDetails.common.reference')}
				customProps={{ className: 'col-3' }}
				component={textInput}
				maxLength={250}
			/>
		);
	}

	renderDateOfIncomeInputs() {
		return this.renderIntervalFields(
			i18n.t('translation:billingModule.incomesOutcomes.date'),
			this.renderDateField(FILTER_FIELDS.TRANSACTION_DATE_FROM, true),
			this.renderDateField(FILTER_FIELDS.TRANSACTION_DATE_TO)
		);
	}

	renderFilterButton() {
		// 'marginRight: 30px' is used because the trigger arrow is styled with
		// 'position: absolute' everywhere in the app
		return (
			<div style={{ marginRight: '30px' }}>
				<DefaultButton title={i18n.t('translation:billingModule.common.filter')} />
			</div>
		);
	}

	onClientChange = (target) => {
		const { options } = target;
		const clinicLegalName = options[options.selectedIndex].text;
		this.props.change(FILTER_FIELDS.CLINIC_LEGAL_NAME, clinicLegalName);
	};

	renderNumberInput(name, label) {
		return (
			<Field
				name={name}
				label={label}
				customProps={{ className: 'col-12 p-0' }}
				normalize={formUtils.normalize.number}
				component={textInput}
			/>
		);
	}

	onFilterButtonClick = (formValues) => {
		this.props.searchTransactions({
			page: 1,
			filters: formValues,
			labId: this.props.account.labId
			// type: undefined //todo
		});
		this.props.setPagination(PAGINATION_TYPES.TRANSACTIONS, 1);
	};

	render() {
		return (
			<div className="col-12 p-0 data-section">
				<div className="content filters pt-0">
					<form
						onSubmit={this.props.handleSubmit((formValues) =>
							this.onFilterButtonClick(formValues)
						)}>
						{this.renderFilterInputs()}
					</form>
				</div>
			</div>
		);
	}
}

const incomesOutcomesFiltersForm = reduxForm({
	form: 'incomesOutcomesFiltersForm',
	validate: validateFilters
})(IncomesOutcomesFilters);

const mapStateToProps = (state) => {
	return {
		clinics: state.clinics.clinics,
		account: state.account,
		settings: state.settings,
		formValues: getFormValues('incomesOutcomesFiltersForm')(state)
	};
};

export default connect(mapStateToProps, {
	setPagination,
	getLinkedClinicsWithoutPagination,
	searchTransactions,
	reset
})(incomesOutcomesFiltersForm);

function validateFilters(formValues) {
	const { ISSUE_DATE_FROM, ISSUE_DATE_TO, TRANSACTION_DATE_FROM, TRANSACTION_DATE_TO } =
		FILTER_FIELDS;
	let errors = {};

	if (
		formValues[ISSUE_DATE_FROM] &&
		formValues[ISSUE_DATE_TO] &&
		formValues[ISSUE_DATE_FROM].getTime() > formValues[ISSUE_DATE_TO].getTime()
	) {
		errors[ISSUE_DATE_FROM] = i18n.t('translation:billingModule.errors.fromToError');
	}

	if (
		formValues[TRANSACTION_DATE_FROM] &&
		formValues[TRANSACTION_DATE_TO] &&
		formValues[TRANSACTION_DATE_FROM].getTime() > formValues[TRANSACTION_DATE_TO].getTime()
	) {
		errors[TRANSACTION_DATE_FROM] = i18n.t('translation:billingModule.errors.fromToError');
	}

	return errors;
}
