import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/min/moment-with-locales';
import { DEFAULT_BACKEND_DATE_FORMAT, WORKDAY_TYPES } from '../../utils/constants';
import { connect } from 'react-redux';
import {
	getAbsenceByType,
	parseColorCodeToBackgroundColor,
	parseFlagToLanguage
} from '../../utils/appUtils';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

require('react-big-calendar/lib/css/react-big-calendar.css');

class CustomCalendar extends React.Component {
	render() {
		moment.locale(parseFlagToLanguage(this.props.language), {
			week: {
				dow: 1,
				doy: 1
			}
		});

		const localizer = momentLocalizer(moment);

		const formats = {
			weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture)
		};

		return (
			<Calendar
				localizer={localizer}
				events={this.props.events}
				absenceEvents={this.props.absenceEvents}
				views={['month']}
				formats={formats}
				// dayPropGetter={ this.getDayProps }
				selectable
				onSelectSlot={this.onSelectSlot}
				startAccessor="start"
				endAccessor="end"
				style={{ height: 350 }}
				date={new Date(this.props.date.format(DEFAULT_BACKEND_DATE_FORMAT))}
				onSelectEvent={this.onSelectEvent}
				components={{
					event: this.Event,
					toolbar: this.CustomToolbar
				}}
			/>
		);
	}

	Event = ({ event }) => {
		const isCurrentMonth = this.props.date.format('M') === moment(event.start).format('M');
		const className = !isCurrentMonth
			? 'd-none'
			: event.type === WORKDAY_TYPES.PUBLIC_HOLIDAY
			? 'holiday-event'
			: '';
		const absence = getAbsenceByType(
			this.props.absenceEvents ? event.type.toUpperCase() : WORKDAY_TYPES.PUBLIC_HOLIDAY,
			this.props.absences
		);
		const style = isCurrentMonth
			? parseColorCodeToBackgroundColor(absence.backgroundColor)
			: {};

		if (this.props.absenceEvents) {
			const isWorkShift = !isCurrentMonth
				? ''
				: event.type.toUpperCase() == WORKDAY_TYPES.WORK_SHIFT;
			const noWorkingDay = !isCurrentMonth
				? ''
				: event.type.toUpperCase() == WORKDAY_TYPES.NO_WORKING_DAY;
			const absenceStyle = isCurrentMonth
				? isWorkShift
					? parseColorCodeToBackgroundColor(absence.backgroundColor, true)
					: parseColorCodeToBackgroundColor(absence.backgroundColor)
				: {};

			const classNameCell = className + ' cell-full-height';
			const classNameDateCell = isWorkShift
				? 'rbc-date-cell abs-cell shift-cell'
				: 'rbc-date-cell abs-cell';

			const shiftCell = isWorkShift ? <span>{event.shiftCode}</span> : null;
			const eventStartDate = !isWorkShift ? (
				<a href="#" role="cell" className="pointer">
					{moment(event.start).format('DD')}
				</a>
			) : null;

			if (noWorkingDay) {
				return (
					<div className="holiday-event cell-full-height no-working-day-cell">
						<div className="rbc-date-cell" role="cell">
							<a href="#" role="cell" className="pointer">
								{moment(event.start).format('DD')}
							</a>
						</div>
					</div>
				);
			} else {
				return (
					<div className={classNameCell} style={absenceStyle}>
						<div className={classNameDateCell} role="cell">
							{shiftCell}
							{eventStartDate}
						</div>
					</div>
				);
			}
		} else if (this.props.dashboard) {
			const classNameDashboardDay = !isCurrentMonth
				? 'd-none'
				: event.overdueOrders
				? 'dashboard-event dashboard-event-overdue'
				: 'dashboard-event';
			const circleCellStyle = function (type) {
				return type == null || type == 0 ? { display: 'none' } : {};
			};

			return (
				<div className={classNameDashboardDay}>
					<div className="rbc-event-date-cell" role="cell">
						<span
							className="circle-cell completed-count pointer"
							style={circleCellStyle(event.completedOrders)}
							onClick={event.onClickCompleted}>
							{event.completedOrders}
						</span>
						<span
							className="circle-cell in-progress-count pointer"
							style={circleCellStyle(event.inProgressOrders)}
							onClick={event.onClickInProgress}>
							{event.inProgressOrders}
						</span>
						<span
							className="circle-cell not-planned-count pointer"
							style={circleCellStyle(event.notPlannedOrders)}
							onClick={event.onClickNotPlanned}>
							{event.notPlannedOrders}
						</span>
						<span
							className="circle-cell to-be-sent-count pointer"
							style={circleCellStyle(event.toBeSentOrders)}
							onClick={event.onClickТoBeSent}>
							{event.toBeSentOrders}
						</span>
					</div>
				</div>
			);
		} else {
			return (
				<div className={className} style={style}>
					<div className="rbc-date-cell" role="cell">
						<a href="#" role="cell" className="pointer">
							{moment(event.start).format('DD')}
						</a>
					</div>
				</div>
			);
		}
	};
	CustomToolbar = (date) => {
		if (this.props.holidays) {
			return (
				<div className="toolbar-container">
					<label className="label-date">{moment(date.date).format('MMMM')}</label>
				</div>
			);
		}

		if (this.props.dashboard) {
			const nextButton = (
				<button onClick={this.props.addMonth} className="icon-button w-auto">
					{<FontAwesomeIcon className="arrow-button" icon={faCaretRight} />}
				</button>
			);
			const backButton = (
				<button onClick={this.props.subtractMonth} className="icon-button w-auto">
					{<FontAwesomeIcon className="arrow-button" icon={faCaretLeft} />}
				</button>
			);

			return (
				<div className="toolbar-container">
					<label className="label-date">
						{backButton}
						{nextButton}
						{moment(date.date).format('MMMM')}
						&nbsp;
						{moment(date.date).format('YYYY')}
					</label>
				</div>
			);
		}
	};

	getHolidaysStyle = (date) => {
		const holidays = this.props.holidays;
		if (holidays) {
			if (
				holidays.some(
					(holiday) => holiday.date === moment(date).format(DEFAULT_BACKEND_DATE_FORMAT)
				)
			) {
				const absence = getAbsenceByType(WORKDAY_TYPES.PUBLIC_HOLIDAY, this.props.absences);
				return parseColorCodeToBackgroundColor(absence.backgroundColor);
			}
		}
	};

	getDayProps = (date) => {
		return { style: { ...this.getHolidaysStyle(date) } };
	};

	onSelectSlot = (slot) => {
		if (
			moment(slot.start).format(DEFAULT_BACKEND_DATE_FORMAT) ===
			moment(slot.end).subtract(1, 'd').format(DEFAULT_BACKEND_DATE_FORMAT)
		) {
			this.props.onSelectDay({ date: slot.start });
		}
	};

	onSelectEvent = (event) => {
		if (this.props.onSelectEvent) {
			this.props.onSelectEvent({ date: event.start, description: event.title, id: event.id });
		}
	};
}

const mapStateToProps = (state) => {
	return {
		absences: state.absences,
		language: state.settings.language
	};
};

export default connect(mapStateToProps)(CustomCalendar);
