import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { getFormValues, reset, SubmissionError, submit } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import DefaultButton from '../../../common/DefaultButton';
import {
	getGlobalProductPhasesTemplates,
	updateGlobalProductPhasesTemplates
} from '../../../../actions';
import {
	addZeroIfOneDigit,
	calculatePhasesTotalTime,
	isEmptyObject,
	isRegisteredFieldValue
} from '../../../../utils/appUtils';
import Table from '../../../common/Table';
import { parseGlobalPhases } from '../../../../utils/tableUtils';
import _ from 'lodash';
import GlobalInventoryAddPhaseForm from './GlobalInventoryAddPhaseForm';
import { commonValidation } from '../../../../utils/formUtils';
import { MAX_TEXT_FIELD_LENGTH } from '../../../../utils/constants';

class GlobalInventoryPhasesModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phaseCounter: 1,
			phases: [],
			serverError: ''
		};
	}

	componentDidMount() {
		Modal.setAppElement('#root');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const formValues = this.props.tableFormValues;

		const totalTime = calculatePhasesTotalTime(formValues, this.state.phases);
		if (this.state.totalTime !== totalTime) {
			this.setState({ totalTime });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isModalOpen !== this.props.isModalOpen) {
			const phases = this.props.phases.globalPhasesTemplates.phases;

			if (this.state.phases.length === 0 && phases && phases.length > 0) {
				const refinedPhases = [];
				for (let phase of phases) {
					const currentPhase = {};
					const index = phase.id;
					for (let i in phase) {
						if (i === 'nameInBulgarian') {
							currentPhase['name_BG_' + index] = phase[i];
							currentPhase['name_DE_' + index] = phase['nameInGerman'];
							currentPhase['name_GB_' + index] = phase['nameInEnglish'];
							currentPhase['name_TR_' + index] = phase['nameInTurkish'];

							if (phase['duration']) {
								currentPhase['minutes_' + index] = phase['duration'].split(':')[1];
								currentPhase['hours_' + index] = phase['duration'].split(':')[0];
							}
							currentPhase.id = index;
							refinedPhases.push(currentPhase);
						}
					}
				}
				this.setState({ phases: refinedPhases });
			}
		}
	}

	render() {
		const editButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.save')}
				type="submit"
				key={i18n.t('translation:common.buttons.save')}
				disabled={this.props.productLoader}
				onClick={() => {
					this.props.submit('tableForm');
				}}
			/>
		);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.closeModal();
				}}
				secondary
			/>
		);

		const modalTitle = i18n.t('translation:taskManager.common.template');

		if (isEmptyObject(this.props.item)) {
			return <Fragment />;
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal teeth-map-modal"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				<label className="blue-label mt-s">{i18n.t('translation:orders.product')}</label>
				<span>{this.props.item.type + ' - ' + this.props.item.material}</span>
				<GlobalInventoryAddPhaseForm onSubmit={this.onAddPhase} />
				<label className="blue-label mt-s">
					{i18n.t('translation:taskManager.inventory.phases')}
				</label>
				{this.renderPhases()}
				<span className="align-items-start text-danger">{this.state.serverError}</span>
				<div className="buttons">
					{editButton}
					{cancelButton}
				</div>
			</Modal>
		);
	}

	onDeletePhase = (id) => {
		let phases = this.state.phases;
		_.remove(phases, { id });
		this.setState({ phases });
	};

	renderPhases = () => {
		const columns = [
			i18n.t('translation:common.languages.BG'),
			i18n.t('translation:common.languages.DE'),
			i18n.t('translation:common.languages.GB'),
			i18n.t('translation:taskManager.inventory.fields.time'),
			''
		];
		const data = parseGlobalPhases(this.state.phases, this.onDeletePhase);
		const phases = this.state.phases;
		let initialValues = {};

		for (let phase of phases) {
			for (let key in phase) {
				const id = phase.id;
				if (key.length > 2) {
					if (id.toString().endsWith('c')) {
						initialValues[key.replace(0, id)] = phase[key];
					} else {
						initialValues[key] = phase[key];
					}
				}
			}
		}

		return (
			<Table
				columns={columns}
				data={data}
				onSubmit={this.onTableFormSubmit}
				className={'table-languages'}
				initialValues={{ ...initialValues, ...this.props.tableFormValues }}
				totalTime={this.state.totalTime}
				totalTimeColumn={3}
			/>
		);
	};

	onAddPhase = () => {
		const formValues = this.props.formValues;
		this.setState({
			phases: [...this.state.phases, { ...formValues, id: this.state.phaseCounter + 'c' }],
			phaseCounter: this.state.phaseCounter + 1
		});
		this.props.reset('globalInventoryPhasesForm');
	};

	setServerError = (serverError) => {
		this.setState({ serverError });
	};

	onTableFormSubmit = (formValues) => {
		let errors = {};
		let lastIndexErrors = '';
		for (let value in formValues) {
			let index = value.split('_');
			index = index[index.length - 1];
			if (lastIndexErrors !== index) {
				lastIndexErrors = index;
				if (isRegisteredFieldValue(this.state.phases, index)) {
					commonValidation(formValues, errors, 'name_BG_' + index)
						.required()
						.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
						.validate();
					commonValidation(formValues, errors, 'name_DE_' + index)
						.required()
						.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
						.validate();
					commonValidation(formValues, errors, 'name_GB_' + index)
						.required()
						.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
						.validate();
					commonValidation(formValues, errors, 'name_TR_' + index)
						.required()
						.maxLength(MAX_TEXT_FIELD_LENGTH.LONG)
						.validate();
				}
			}
		}

		if (!isEmptyObject(errors)) {
			throw new SubmissionError({
				...errors,
				_error: 'error'
			});
		}

		// Submit form after validations
		const tableFormValues = this.props.tableFormValues;
		const phases = [];
		let lastIndex = '';
		for (let i in tableFormValues) {
			const currentPhase = {};
			if (i.startsWith('name_BG')) {
				lastIndex = i.split('_')[2];
				if (isRegisteredFieldValue(this.state.phases, lastIndex)) {
					currentPhase.nameInBulgarian = tableFormValues[i];
					currentPhase.nameInGerman = tableFormValues['name_DE_' + lastIndex];
					currentPhase.nameInEnglish = tableFormValues['name_GB_' + lastIndex];
					currentPhase.nameInTurkish = tableFormValues['name_TR_' + lastIndex];
					const hours = tableFormValues['hours_' + lastIndex];
					const minutes = tableFormValues['minutes_' + lastIndex];
					if (hours || minutes) {
						currentPhase.duration =
							hours && minutes
								? `${hours}:${addZeroIfOneDigit(minutes)}`
								: !minutes
								? `${hours}:00`
								: `00:${addZeroIfOneDigit(minutes)}`;
					}
					if (!lastIndex.endsWith('c')) {
						currentPhase.id = lastIndex;
					}
					phases.push(currentPhase);
				}
			}
		}
		this.props.updateGlobalProductPhasesTemplates(
			this.props.item.id,
			phases,
			this.closeModal,
			this.setServerError,
			this.props.page
		);
	};

	closeModal = () => {
		this.props.closeModal();
		this.setState({ phases: [], serverError: '' });
	};
}

const mapStateToProps = (state) => {
	return {
		formValues: getFormValues('globalInventoryPhasesForm')(state) || {},
		tableFormValues: getFormValues('tableForm')(state) || {}
	};
};

export default connect(mapStateToProps, {
	getGlobalProductPhasesTemplates,
	updateGlobalProductPhasesTemplates,
	submit,
	reset
})(GlobalInventoryPhasesModal);
