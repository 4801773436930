import React, { Fragment } from 'react';
import {
	isEmptyObject,
	isGlobalAdmin,
	isLabAdmin,
	isLocalAdmin,
	registeredInClinic
} from '../../../utils/appUtils';
import {
	ORDER_SORT_DIRECTORY,
	ORDER_SORT_KEYS,
	PAGES_PATHS,
	PAGINATION_TYPES,
	SORT_TYPES
} from '../../../utils/constants';
import { parseOrders } from '../../../utils/tableUtils';
import i18n from '../../../i18n';
import Table from '../../common/Table';
import Pagination from '../../common/Pagination';
import { connect } from 'react-redux';
import { getNotCompletedOrders, getOrders, setPagination, setSort } from '../../../actions';
import { getFormValues } from 'redux-form';
import navigationUtils from '../../../utils/navigationUtils';
import _ from 'lodash';

class Orders extends React.Component {
	state = {
		currentSort: { sortKey: ORDER_SORT_KEYS.STATUS, sortDirection: ORDER_SORT_DIRECTORY.ASC }
	};

	componentDidMount() {
		if (this.props.onSort) {
			this.props.onSort(this.props.sort);
		}
		if (navigationUtils.getRoute() !== PAGES_PATHS.HOME) {
			this.props.getOrders(this.props.page || 1, this.props.sort, this.props.formValues);
		} else {
			this.props.getNotCompletedOrders(this.props.page || 1, this.props.sort);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.page && this.props.page !== 1 && isEmptyObject(this.props.orders.content)) {
			this.props.setPagination(PAGINATION_TYPES.ORDERS, this.props.page - 1);
			return;
		}
		if (
			prevProps.page !== this.props.page ||
			prevProps.sort.sortKey !== this.props.sort.sortKey ||
			prevProps.sort.sortDirection !== this.props.sort.sortDirection ||
			this.props.filters !== prevProps.filters
		) {
			if (navigationUtils.getRoute() !== PAGES_PATHS.HOME) {
				this.props.getOrders(this.props.page || 1, this.props.sort, this.props.formValues);
			} else {
				this.props.getNotCompletedOrders(this.props.page || 1, this.props.sort);
			}
		}
	}

	render() {
		return (
			<Fragment>
				{this.renderOrders()}
				{this.renderPagination()}
			</Fragment>
		);
	}

	renderOrders = () => {
		const isFilterPage = navigationUtils.getRoute() !== PAGES_PATHS.HOME;

		const globalAdminColumns = [
			i18n.t('translation:common.table.number'),
			i18n.t('translation:common.table.practice'),
			i18n.t('translation:common.table.dentist'),
			i18n.t('translation:common.table.laboratory'),
			i18n.t('translation:common.table.dentalTechnician'),
			i18n.t('translation:common.table.createdAt'),
			i18n.t('translation:common.table.status')
		];
		const dentistLocalAdminColumns = [
			i18n.t('translation:common.table.number'),
			i18n.t('translation:common.table.supplier'),
			i18n.t('translation:common.table.dentist'),
			i18n.t('translation:common.table.createdAt'),
			i18n.t('translation:common.table.deliveryDueDate'),
			i18n.t('translation:common.table.paymentDueDate'),
			i18n.t('translation:common.table.status'),
			i18n.t('translation:billingModule.common.taxBase')
		];
		const dentistColumns = [
			i18n.t('translation:common.table.number'),
			i18n.t('translation:common.table.supplier'),
			i18n.t('translation:common.table.dentist'),
			i18n.t('translation:common.table.createdAt'),
			i18n.t('translation:common.table.deliveryDueDate'),
			i18n.t('translation:common.table.status')
		];
		let dentalTechnicianUserColumns = [
			i18n.t('translation:common.table.number'),
			i18n.t('translation:common.table.client'),
			i18n.t('translation:common.table.dentalTechnician'),
			i18n.t('translation:common.table.createdAt'),
			i18n.t('translation:common.table.deliveryDueDate'),
			i18n.t('translation:common.table.status')
		];

		const dentalTechnicianLocalAdminColumns = [
			i18n.t('translation:common.table.number'),
			i18n.t('translation:common.table.client'),
			i18n.t('translation:common.table.dentalTechnician'),
			i18n.t('translation:common.table.createdAt'),
			i18n.t('translation:common.table.deliveryDueDate'),
			i18n.t('translation:common.table.paymentDueDate'),
			i18n.t('translation:common.table.status'),
			i18n.t('translation:billingModule.common.taxBase')
		];

		const globalAdminSortKeys = [
			ORDER_SORT_KEYS.LAB_ORDER_ID,
			ORDER_SORT_KEYS.CLINIC_LEGAL_NAME,
			ORDER_SORT_KEYS.DENTIST_NAME,
			ORDER_SORT_KEYS.LAB_LEGAL_NAME,
			ORDER_SORT_KEYS.LAB_TECHNICIAN_NAME,
			ORDER_SORT_KEYS.CREATE_DATE,
			ORDER_SORT_KEYS.STATUS
		];
		const dentistLocalAdminSortKeys = [
			ORDER_SORT_KEYS.LAB_ORDER_ID,
			ORDER_SORT_KEYS.LAB_NAME,
			ORDER_SORT_KEYS.DENTIST_NAME,
			ORDER_SORT_KEYS.CREATE_DATE,
			ORDER_SORT_KEYS.DELIVERY_DATE,
			ORDER_SORT_KEYS.PAYMENT_DUE_DATE,
			ORDER_SORT_KEYS.STATUS,
			ORDER_SORT_KEYS.PRICE
		];
		const dentistSortKeys = [
			ORDER_SORT_KEYS.LAB_ORDER_ID,
			ORDER_SORT_KEYS.LAB_NAME,
			ORDER_SORT_KEYS.DENTIST_NAME,
			ORDER_SORT_KEYS.CREATE_DATE,
			ORDER_SORT_KEYS.DELIVERY_DATE,
			ORDER_SORT_KEYS.STATUS
		];
		let dentalTechnicianUserSortKeys = [
			ORDER_SORT_KEYS.LAB_ORDER_ID,
			ORDER_SORT_KEYS.CLINIC_NAME,
			ORDER_SORT_KEYS.LAB_TECHNICIAN_NAME,
			ORDER_SORT_KEYS.CREATE_DATE,
			ORDER_SORT_KEYS.DELIVERY_DATE,
			ORDER_SORT_KEYS.STATUS
		];

		const dentalTechnicianLocalAdminSortKeys = [
			ORDER_SORT_KEYS.LAB_ORDER_ID,
			ORDER_SORT_KEYS.CLINIC_NAME,
			ORDER_SORT_KEYS.LAB_TECHNICIAN_NAME,
			ORDER_SORT_KEYS.CREATE_DATE,
			ORDER_SORT_KEYS.DELIVERY_DATE,
			ORDER_SORT_KEYS.PAYMENT_DUE_DATE,
			ORDER_SORT_KEYS.STATUS,
			ORDER_SORT_KEYS.PRICE
		];

		if (!isFilterPage) {
			_.remove(dentalTechnicianUserColumns, function (col) {
				return col === i18n.t('translation:common.table.dentalTechnician');
			});
			_.remove(dentalTechnicianUserSortKeys, function (col) {
				return col === ORDER_SORT_KEYS.LAB_TECHNICIAN_NAME;
			});
		}

		const account = this.props.account;
		const columns = isGlobalAdmin(account)
			? globalAdminColumns
			: isLabAdmin(account)
			? dentalTechnicianLocalAdminColumns
			: registeredInClinic(account)
			? isLocalAdmin(account)
				? dentistLocalAdminColumns
				: dentistColumns
			: dentalTechnicianUserColumns;
		const sortKeys = isGlobalAdmin(account)
			? globalAdminSortKeys
			: isLabAdmin(account)
			? dentalTechnicianLocalAdminSortKeys
			: registeredInClinic(account)
			? isLocalAdmin(account)
				? dentistLocalAdminSortKeys
				: dentistSortKeys
			: dentalTechnicianUserSortKeys;
		const orders = parseOrders(this.props.orders.content, account, isFilterPage);

		return (
			<Fragment>
				<div className="col-12 mt-s p-0">
					<Table
						columns={columns}
						data={orders}
						sortKeys={sortKeys}
						currentSort={this.props.sort}
						setCurrentSort={this.setCurrentSort}
						className={'orders'}
					/>
				</div>
			</Fragment>
		);
	};

	renderPagination = () => {
		const { orders } = this.props;
		if (orders) {
			return (
				<Pagination
					currentPage={this.props.page}
					total={orders.totalElements}
					setCurrentPage={this.setCurrentPage}
				/>
			);
		}
	};

	setCurrentSort = (currentSort) => {
		this.props.setSort(SORT_TYPES.ORDER_SORT, currentSort);
		if (this.props.onSort) {
			this.props.onSort(currentSort);
		}
	};

	setCurrentPage = (page) => {
		this.props.setPagination(PAGINATION_TYPES.ORDERS, page);
	};
}

const mapStateToProps = (state) => {
	return {
		page: state.pagination[PAGINATION_TYPES.ORDERS],
		orders: state.orders.orders,
		settings: state.settings,
		account: state.account,
		sort: state.sort[SORT_TYPES.ORDER_SORT],
		formValues: getFormValues('filtersForm')(state) || {}
	};
};

export default connect(mapStateToProps, {
	getNotCompletedOrders,
	setPagination,
	getOrders,
	setSort
})(Orders);
