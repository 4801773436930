import React from 'react';
import Modal from 'react-modal';
import DefaultButton from '../../../../common/DefaultButton';
import i18n from '../../../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fields } from '../../../../common/Fields';
import { createCustomPropsForCurrency } from '../../../../../utils/formUtils';

class QuickViewModal extends React.Component {
	componentDidMount() {
		Modal.setAppElement('#root');
	}

	render() {
		const customPropsForCurrency = createCustomPropsForCurrency(this.props.lab);

		const cancelButton = (
			<DefaultButton
				title={i18n.t('translation:common.buttons.close')}
				key={i18n.t('translation:common.buttons.close')}
				onClick={() => {
					this.props.closeModal();
				}}
			/>
		);

		const modalTitle = this.props.title;
		const patientName = this.props.initialValues.isPatientNameVisibleToLab
			? Fields.orderFields.patientName({ readableInput: true })
			: null;

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				className="custom-modal modal-with-vertical-scroll"
				overlayClassName="custom-overlay">
				<FontAwesomeIcon
					className="close-icon"
					icon={faTimes}
					onClick={() => {
						this.props.closeModal();
					}}
				/>
				<h2>{modalTitle}</h2>
				{Fields.filterFields.client({ readableInput: true })}
				{Fields.filterFields.dentist({ readableInput: true })}
				{Fields.filterFields.labTechnician({ readableInput: true })}
				{patientName}
				{Fields.orderFields.dueDateWithoutDescription({ readableInput: true })}
				<label className="input-label mt-s">
					{i18n.t('translation:orders.fields.initialPrice')}
				</label>
				{Fields.orderFields.price({ readableInput: true, ...customPropsForCurrency })}
				<div className="buttons">{cancelButton}</div>
			</Modal>
		);
	}
}

const quickViewForm = reduxForm({ form: 'quickViewForm', enableReinitialize: true })(
	QuickViewModal
);

const mapStateToProps = (state) => {
	return { lab: state.labs.currentLab };
};

export default connect(mapStateToProps)(quickViewForm);
